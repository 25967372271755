import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PanelDivider from '../comp/PanelDivider';

const styles = theme => ({
  root: {
    top: 0,
    width: 320,
    paddingTop: theme.spacing(2),
    flexShrink: 0,
    order: 2,
    position: 'sticky',
    wordBreak: 'break-word',
    height: 'calc(100vh)',
    overflowY: 'auto',
    padding: theme.spacing(0, 2, 0, 0),
    // display: 'none',
    // [theme.breakpoints.up('sm')]: {
    //   display: 'block',
    // },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

class AppTableOfContents extends React.Component {
  render() {
    const { classes } = this.props;
    const { contextContent } = this.context;

    return (
      <nav className={classes.root}>
        <div className={classes.drawerHeader} />
        {contextContent && contextContent.view }
        <PanelDivider />
      </nav>
    );
  }
}

AppTableOfContents.propTypes = {
  classes: PropTypes.object.isRequired,
};

AppTableOfContents.contextTypes = {
  contextContent: PropTypes.object,
};

export default withStyles(styles)(AppTableOfContents);
