import PropTypes from 'prop-types';
import React from 'react';
import {
    Button, Grid,
} from '@material-ui/core';
import mainStyles from '../styles/mainStyles';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {FormattedMessage} from "react-intl";
import {PriceIcon} from "../icons";
import Typography from "@material-ui/core/Typography";
import { Card, CardContent, CardActions } from '@material-ui/core';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import {grey} from "@material-ui/core/colors";

const styles = theme => ({
    ...mainStyles(theme),
    content: {
        width: '250px',
        maxWidth: '250px',
    },
    tariffIcon: {
        width: 'auto',
        height: '54px',
        color: '#459220',
        fill: '#459220',
        fillColor: '#459220',
    },
    name: {
        fontWeight: '600',
    },
    oldPrice: {
        fontWeight: '600',
        color: grey[400],
        textDecoration: 'line-through',
    },
    price: {
        fontWeight: '600',
        color: theme.palette.primary.main,
    },
    period: {
        fontWeight: '600',
    },
    payButton: {
        marginTop: 'auto',
        marginBottom: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto'
    },
});

const Tariff = (props) => {
    const {
        classes,
        product,
        onBuy,
    } = props;

    return (
        <Card className={classes.content} variant={"outlined"}>
            <CardContent>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                    <Grid item>
                        <PriceIcon className={classes.tariffIcon} />
                    </Grid>
                    <Grid item>
                        <Typography variant={"h5"} align={"center"} className={classes.name}>
                            {product.name}
                        </Typography>
                    </Grid>
                    {(product.oldPrice > 0) &&
                    <Grid item>
                        <Typography variant={"h5"} align={"center"} className={classes.oldPrice}>
                            &nbsp;{product.oldPrice} руб&nbsp;
                        </Typography>
                    </Grid>
                    }
                    <Grid item>
                        <Typography variant={"h5"} align={"center"} className={classes.price}>
                            {product.price} руб
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"body1"} align={"center"} component={'span'}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={product.descr}/>
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button className={classes.payButton} color="primary" variant="contained" onClick={onBuy}>
                    <FormattedMessage id="label.buy" defaultMessage="Buy" />
                </Button>
            </CardActions>
        </Card>
    );
}

Tariff.propTypes = {
    classes: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    onBuy: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles),
)(Tariff);
