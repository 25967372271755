import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
    Button,
    ButtonGroup,
    TextField,
    MenuItem,
} from "@material-ui/core";
import {ParameterType} from "../../const";
import type {BaselineParameterDto, NumberFormatCustomProps, ValueNumberType, ValueOptionType} from "../../const";
import NumberFormat from 'react-number-format';
// import {NumberFormat, NumberFormatValues} from 'react-number-format';
import type NumberFormatValues from 'react-number-format/typings/number_format';
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
    ...mainStyles(theme),
});

const VarButtonEditor = withStyles(theme => ({
    root: {
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: 'none',
        },
    },
}))(Button);

const VarTextFieldEditor = withStyles(theme => ({
    root: {
        // minWidth: 200,
        width: '100%',
        textAlign: 'left',
    },
}))(TextField);

class VarButtonsEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    onItemClick = (value) => {
        const {onValueChange} = this.props;

        if (Boolean(onValueChange)) {
            onValueChange(value);
        }
    };

    isAllowed = (values: NumberFormatValues, field: BaselineParameterDto, item: ValueNumberType) => {
        const {
            formattedValue,
            // floatValue,
            value,
        } = values;
        if (!value) {
            return formattedValue === ''
        } else {
            return (Number(value) <= item.max && Number(value) >= item.min)
        }
    }

    numberFormatCustom = (props: NumberFormatCustomProps) => {
        const {inputRef, onChange, field, item, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                isAllowed={(values) => {
                    return this.isAllowed(values, field, item);
                }}
                decimalSeparator={field.type === ParameterType.DECIMAL ? '.' : false}
                isNumericString={true}
                allowNegative={false}
                // allowLeadingZeros={false}
                // prefix="$"
            />
        );
    }

    render() {
        const {
            field,
            // value,
        } = this.props;
        let content;

        switch (field.type) {
            case ParameterType.OPTION:
                const items: ValueOptionType[] = field.values.map(value => {
                    return JSON.parse(value);
                }).sort((v1, v2) => v1.ord - v2.ord);
                const maxLength = Math.max.apply(null, items.map(item => {
                    // const item = JSON.parse(it);
                    return (item.title || '').length;
                }));

                let selectedItem = items.find(item => {
                    // const item = JSON.parse(it);
                    return item.selected;
                });
                // selectedItem = selectedItem ? JSON.parse(selectedItem) : undefined;

                if (items.length > 3 || maxLength > 14) {
                    content = <VarTextFieldEditor
                        select
                        fullWidth
                        onChange={e => this.onItemClick(e.target.value)}
                        value={(selectedItem || {}).valueId || ''}
                        variant='outlined'>
                        {items.map(item => {
                            // const item = JSON.parse(it);
                            return (
                                <MenuItem key={item.valueId} value={item.valueId}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </VarTextFieldEditor>;
                } else {
                    content = <ButtonGroup fullWidth color='primary'>
                        {items.map(item => {
                            // const item = JSON.parse(it);
                            return (
                                <VarButtonEditor
                                    key={item.valueId}
                                    variant={item.selected ? 'contained' : 'outlined'}
                                    onClick={e => this.onItemClick(item.valueId)}>
                                    {item.name}
                                </VarButtonEditor>
                            );
                        })}
                    </ButtonGroup>;
                }
                break;

            case ParameterType.INT:
            case ParameterType.DECIMAL:
            case ParameterType.DATE:
                const item: ValueNumberType = field.values.map(value => {
                    return JSON.parse(value);
                })[0];
                content = <VarTextFieldEditor
                    fullWidth
                    name={field.type}
                    // type={'number'}
                    onChange={e => this.onItemClick(e.target.value)}
                    value={item.value}
                    InputProps={{
                        inputComponent: this.numberFormatCustom,
                        inputProps: {
                            field: field,
                            item: item,
                            style: {textAlign: 'right'},
                        },
                        endAdornment: <InputAdornment position="end">{item.units}</InputAdornment>,
                    }}
                    variant='outlined'>
                </VarTextFieldEditor>;
                break;

            default:
                break;
        }
        return content;
    }
}

VarButtonsEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    // field: PropTypes.instanceOf(BaselineParameterDto).isRequired,
    field: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    // value: PropTypes.number,
    onValueChange: PropTypes.func,
};

VarButtonsEditor.defaultProps = {
    // value: -1,
};

export default compose(
    withStyles(styles),
)(VarButtonsEditor);
