import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
    Box,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ListItemText,
    Typography, Grid, TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import {FormattedMessage} from "react-intl";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

const styles = theme => ({
    ...mainStyles(theme),
    headerIcon: {
        // color: theme.palette.primary.main,
    },
});

function CustomSearchDatatable(props) {
    const {
        children,
        title,
        classes,
        onSearch,
    } = props;
    const [searchText, setSearchText] = React.useState('');

    const handleOnSearch = () => {
        onSearch(searchText);
    };

    return (
        <Grid container direction={"row"}>
            <Grid item style={{display: 'flex', flexGrow: 1}}>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    // label={intl.formatMessage({id: 'label.name', defaultMessage: 'Name'})}
                    fullWidth
                    value={searchText}
                    onChange={(event) => setSearchText(event.currentTarget.value)}
                    InputProps={{
                        endAdornment:
                            <InputAdornment
                                position="end"
                                style={{height: '100%'}}
                                // onClick={this.onClickFocus}
                            >
                                <ClearIcon
                                    style={{marginLeft: '8px', cursor: 'pointer'}}
                                    onClick={() => setSearchText('')}
                                    fontSize={'small'}
                                    color={'primary'}
                                />
                                <SearchIcon
                                    style={{marginLeft: '8px', cursor: 'pointer'}}
                                    onClick={handleOnSearch}
                                    fontSize={'small'}
                                    color={'primary'}
                                />
                            </InputAdornment>
                    }}
                />
            </Grid>
        </Grid>
    );
}

CustomSearchDatatable.propTypes = {
    classes: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles),
)(CustomSearchDatatable);

