import React, {createRef, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import {Playlist, goToNextVideo} from "reactjs-video-playlist-player";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    playlistWrapper: {
        display: 'block',
        width: '100%',

        '& .video-container-styles': {
            width: '100%',
            margin: '0 auto',
            height: '314px',
            maxHeight: '314px',
            minHeight: '314px',
        },
        '& .playlist-queue-styles': {
            boxShadow: '0px 0px 1px white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // 'overflow-x': 'auto',
            // 'background-color': '#212836',
            // padding: '4px',
            gap: '4px',
        },
        '& .playlist-queue-item-styles': {
            // 'margin': '4px 2px',
            cursor: 'pointer',
            minWidth: '50px',
            maxWidth: '50px',
            minHeight: '10px',
            maxHeight: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // 'border': '4px solid transparent',
            borderRadius: '2px',
            backgroundColor: '#FFA500',

            '& img': {
                display: 'none',
            },
        },
        '& .current-playing-video-styles': {
            // 'box-shadow': '0px 0px 2px white',
            transition: '0.2s',
            backgroundColor: '#FF5A00'
        },
        '& .thumbnail-styles': {
            width: '100%',
            height: '100%',
        },
        '& .video-styles': {
            width: '100%',
            height: '314px',
            maxHeight: '314px',
            minHeight: '314px',
            objectFit: 'cover',
        },
        '& @media screen and (max-width: 549px)': {
            '& .video-container-styles': {
                width: '100%',
            },
        },
    },
}));

const PlayerView = (props) => {
    const classes = useStyles();

    const [currentVideo, setCurrentVideo] = useState(0);
    const [prevCurrentVideo, setPrevCurrentVideo] = useState(-1);
    let setPrevCurrentVideo2 = -1;
    // const [playingVideo, setPlayingVideo] = useState(false);
    const vidRef = createRef(null);

    const [videoList, setVideoList] = useState([]);

    const {videos} = props;

    useEffect(() => {
        // console.error('+++ useEffect[videoList, vidRef.current] +++ vidRef.current:', vidRef.current);
        // console.error('+++ useEffect[videoList, vidRef.current] +++ videoList:', videoList);
        // if (
        //     !!vidRef.current
        //     &&
        //     !!videoList
        // ) {
        //     vidRef.current.pause();
        // }

        if (
            !!vidRef.current
            &&
            !!videoList
        ) {
            setPrevCurrentVideo(currentVideo - 1);
            setPrevCurrentVideo2 = currentVideo - 1;
            // console.error('+++ useEffect[videoList, vidRef.current] +++ playingVideo:', playingVideo);
            // console.error('+++ useEffect[videoList, vidRef.current] +++ currentVideo:', currentVideo);
            // console.error('+++ useEffect[videoList, vidRef.current] +++ prevCurrentVideo:', prevCurrentVideo);
            // console.error('+++ useEffect[videoList, vidRef.current] +++ videoList.length:', videoList.length);
            if (prevCurrentVideo !== -1 && videoList.length > 1 && currentVideo <= videoList.length) {
            // if (setPrevCurrentVideo2 !== -1 && videoList.length > 1 && currentVideo <= videoList.length) {
                vidRef.current.play();
                // setPlayingVideo(true);
            } else {
                // setPlayingVideo(false);
            }

            // vidRef.current.onplaying = function () {
            //     setPlayingVideo(false);
            //     console.error('+++ useEffect[videoList, vidRef.current] +++ vidRef.current.onplaying => playingVideo:', playingVideo);
            // }

            // vidRef.current.onpause = function () {
            //     playingVideo = false;
            //     console.error('+++ useEffect[videoList, vidRef.current] +++ vidRef.current.onpause => playingVideo:', playingVideo);
            // }
        }
    }, [
        videoList,
        vidRef.current,
        currentVideo,
        prevCurrentVideo,
        // playingVideo,
    ]);

    useEffect(() => {
        // console.error('+++ useEffect[currentVideo] +++ vidRef.current:', vidRef.current);
        // console.error('+++ useEffect[currentVideo] +++ videoList:', videoList);
        // console.error('+++ useEffect[currentVideo] +++ currentVideo:', currentVideo);

        // setTimeout(() => {
        //     prevCurrentVideo = currentVideo - 1;
        //     if (!vidRef.current.paused && prevCurrentVideo !== -1 && videoList.length > 1 && (currentVideo <= videoList.length)) {
        //         if (currentVideo === videoList.length - 1) {
        //             lastVideoStarted = true;
        //         }
        //         vidRef.current.play();
        //     }
        // });
    }, [
        currentVideo
    ]);

    useEffect(() => {
        const _videoList = (videos || []).map((video) => {
            return {
                thumbnail: "",
                url: video,
                imgAlt: "",
            };
        });
        setVideoList(_videoList);
    }, [videos]);

    const videoParams = {
        videos: videoList,
        autoPlay: false,
        showQueue: videos.length > 1,
        playForward: true,
        // defaultQueueItemPlaceholderThumbnail: defaultQueueItemPlaceholderThumbnail,
        currentVideo: currentVideo,
        setCurrentVideo: setCurrentVideo,
        vidRef: vidRef
    };

    return (
        <div className={classes.playlistWrapper}>
            {(currentVideo !== undefined && videoParams && !!videoList.length) && <Playlist playlistParams={videoParams}/>}
        </div>
    );
};

export default PlayerView;
