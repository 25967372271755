import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {Button, Grid, Typography,} from '@material-ui/core';
import mainStyles from '../../styles/mainStyles';
import {articlePubTypesToStr, dateToStr,} from '../../utils';
import {FormattedMessage} from 'react-intl';
import LinkIcon from '@material-ui/icons/OpenInNewOutlined';
import SelTextView from "../SelTextView";

const styles = theme => ({
  ...mainStyles(theme),
  linkButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  articleIcon: {
    marginTop: theme.spacing(2),
  },
  secondInfo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class ArticleTitle extends React.Component {

  constructor(props) {
    super(props);

    this.state = { };
  }

  classColor = (cls) => {
    const { classes } = this.props;

    if (cls === 'A') {
      return { subtitle2: classes.articleClassA };
    } else if (cls === 'B') {
      return { subtitle2: classes.articleClassB };
    }

    return { subtitle2: classes.articleClassC };
  };

  render() {
    const { article, classes, showButtons } = this.props;
    const { locale } = this.context;

    return (<Grid container>
      <Grid item xs={12}>
        <SelTextView color={"primary"}>{article.title}</SelTextView>
      </Grid>
      {article.pubTypes &&
        <Grid item xs={12}>
          <Typography color="textSecondary" component="span" className={classes.secondInfo}>
            {articlePubTypesToStr(article.pubTypes)}
          </Typography>
        </Grid>
      }
      {article.authors &&
        <Grid item xs={12}>
          <Typography color="textSecondary" component="span" className={classes.secondInfo}>
            {article.authors}
          </Typography>
        </Grid>
      }
      {article.pubDate &&
        <Grid item xs={12}>
          <Typography color="textSecondary" component="span" className={classes.secondInfo}>
            {dateToStr(article.pubDate, locale)}
          </Typography>
        </Grid>
      }
      {article.doi &&
        <Grid item xs={12}>
          <Typography color="textPrimary" className={classes.secondInfo}
            component="span">
            <FormattedMessage id='label.doi' defaultMessage='DOI' />: {article.doi}
          </Typography>
        </Grid>
      }
      {article.rate &&
        <Grid item xs={12}>
          <Typography classes={this.classColor(article.rate)} color="textPrimary" variant="subtitle2" component="span">
            <FormattedMessage id='label.article.class' defaultMessage='Article class' />: {article.rate}
          </Typography>
        </Grid>
      }
      {showButtons &&
        <Grid item xs={12}>
          <Grid className={classes.linkButtons} container spacing={2} alignItems="center" component="span">
            {article.doi &&
              <Grid item component="span">
                <Button href={`https://doi.org/${article.doi}`} target="_blank"
                  rel="noopener noreferrer" className={classes.textButton}
                  color="primary" size="small">
                  <LinkIcon className={classes.iconButton} />
                  <FormattedMessage id='label.article.link' defaultMessage='Article link' />
                </Button>
              </Grid>}
            {/*article.pdfUrl &&
              <Grid item component="span">
                <Button href={article.pdfUrl} target="_blank"
                  rel="noopener noreferrer" className={classes.textButton}
                  color="primary" size="small">
                  <ArticleIcon className={classes.iconButton} />
                  <FormattedMessage id='label.pdf.link' defaultMessage='Open pdf-file' />
                </Button>
              </Grid>*/}
          </Grid>
        </Grid>
      }
    </Grid>);
  }
}

ArticleTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  showButtons: PropTypes.bool,
};

ArticleTitle.defaultProps = {
  showButtons: true,
};

ArticleTitle.contextTypes = {
  locale: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
)(ArticleTitle);
