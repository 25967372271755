import React from "react";
import PropTypes from 'prop-types';
import {Box, Container, Grid, Paper, Typography,} from "@material-ui/core";
import PanelDivider from "../PanelDivider";
import {FeatureNames, TypeNames} from "./BaselineVars";
import {withStyles} from "@material-ui/core/styles";
import {getFLResultTexts} from "../../query/baseline";
import PaperColor from "../PaperColor";
import SelTreatView from "./SelTreatView";
import SelTextView from "../SelTextView";
import {
    capitalizeFirstLetter,
    extendParamsDrugByTradeItems, getSelectedTreatmentsList,
    initGroupedBarChart2,
    stringDivider, toFixed
} from "../../utils";
import {FormattedMessage} from "react-intl";
import ReactMarkdown from "react-markdown";
import {BarChartOrientation, GroupedBarChartColors, NosologyType} from "../../const";
import ComparisonsView from "../ComparisonsView";
import {DrugResultRow} from "../drug/DrugResultRow";
import {loadDrugsCompositesByNames} from "../../query/drugs";
import GroupedBarChart from "../charts/GroupedBarChart";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import ExpandChartsPanel from "./ExpandChartsPanel";
import config from "../../config";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";

const TypographyGroupSel = withStyles(_theme => ({
    root: {
        fontWeight: 500,
    },
}))(Typography);

const styles = theme => ({
    ...mainStyles(theme),
});

export class TherapyResult extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            texts: {},
        };
    }

    componentDidMount() {
        const {showComparisons, setDisableResultNext} = this.props;
        if (!showComparisons) {
            this.setTherapyCompare();
        } else {
            if (!!setDisableResultNext) {
                setDisableResultNext(true);
            }
        }
        this.loadTextsAllSections();

        this.viewForestplot();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadTextsAllSections = () => {
        const {getSelThId, getThData, getFeatures, activePatient} = this.props;

        // features
        let features = getFeatures();

        // therapy
        // const therapy = Array.from([0, 1], gId => {
        const therapies = Array.from([0, 1], gId => {
            const selThId = getSelThId(gId);
            const thData = getThData(gId);
            // console.error('+++ loadTextsAllSections() +++ selThId', selThId);
            // console.error('+++ loadTextsAllSections() +++ thData', thData);

            // if (selThId !== undefined && thData !== undefined && selThId < thData.length && selThId >= 0) {
            if (
                selThId !== undefined
                && thData !== undefined
                // && selThId < thData.length
                // && selThId >= 0
                && selThId !== -1
            ) {
                return Object.keys(selThId).flatMap((thGroup) => {
                    const selThIdItem = selThId[thGroup];

                    // return thData[selThId].name;
                    return thData[selThIdItem].name;
                });
            }

            return undefined;
        })
            .filter(it => it !== undefined);
        // console.error('+++ loadTextsAllSections() +++ therapies', therapies);

        // drugs
        const drugs = Array.from([0, 1], gId => this.getDrugsForThGroup(gId))
            .flatMap((it) => it)
            .filter((it) => (!!it && !!Object.values(it).length))
            .map((it) => {
                return {
                    name: it.name,
                    drugCompositeId: it.drugCompositeId,
                };
            });
        // console.error('+++ loadTextsAllSections() +++ drugs', drugs);

        const patientId = Number(activePatient.patient) || 0;

        [1, 2, 3, 4, 5, 6, 7].forEach(secNum => {
            (therapies || []).forEach((therapy) => {
                this.loadTextsWithSection(secNum, features, therapy, drugs, patientId)
            });
        });


        const paramsDrugs = [];
        const drugsNames = Array.from([0, 1], gId => this.getDrugsForThGroup(gId))
            .flatMap((it) => it)
            .map((drug) => drug.name)
            .filter((drugName) => !!drugName);
        const drugsNamesWithTherapy = Array.from([0, 1], gId => this.getDrugsWithTherapyForThGroup(gId))
            .flatMap(it => it);

        (drugsNamesWithTherapy || []).forEach((drugNamesWithTherapy) => {
            paramsDrugs.push(
                {
                    groupName: drugNamesWithTherapy.therapy,
                    drugs: drugNamesWithTherapy.drugs,
                }
            );
        });

        const {client} = this.props;
        const {signal} = this.abortController;
        loadDrugsCompositesByNames(client, signal, drugsNames)
            .then((drugsByDrugDosageNames) => {
                this.setState({
                    paramsDrugs: extendParamsDrugByTradeItems(paramsDrugs, drugsByDrugDosageNames),
                }, () => {
                    // console.error('+++ loadTextsAllSections() +++ this.state.paramsDrugs', this.state.paramsDrugs);
                });
            });
    };

    loadTextsWithSection = (secNum, features, therapy, drugs, patientId) => {
        const {client} = this.props;
        const {signal} = this.abortController;

        client.query({
            query: getFLResultTexts,
            variables: {
                secNum: secNum,
                features: features,
                therapy: therapy,
                drugs: drugs,
                patientId: patientId,
            },
            context: {
                uri: config.options.server.fl_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => (result.data || {}).getFLResultTexts)
            .then(secTexts => {
                const {texts} = this.state;
                texts[secNum] = (secTexts || []).filter(txt => txt.text && txt.text.length > 0);
                this.forceUpdate();
            });
    };

    getTextsViewWithSection = (secNum) => {
        const {texts} = this.state;
        const secTexts = texts[secNum];

        if (!!secTexts && secTexts.length > 0) {
            return (
                <Box>
                    {secTexts.map((txt, id) => (
                        <Box key={id}>
                            {txt.label &&
                            <Box>
                                <PanelDivider/>
                                <SelTextView>{txt.label}</SelTextView>
                            </Box>
                            }
                            <Typography component={'span'}>
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={txt.text}/>
                            </Typography>
                        </Box>
                    ))}
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    getTherapyForThGroup = (gId): any[] => {
        const {getThNames} = this.props;
        const thDatas = this.getDatasForThGroup(gId);
        const thNames = getThNames(gId);

        let result;
        if (thDatas && !!thDatas.length) {
            result = thDatas.map((thData) => {
                return thNames.find(it => it.name === thData.name);
            });
            result = Array.from(new Set(result.filter((thName) => !!thName)));
        }
        return result;

        // return thData && thNames ? thNames.find(it => it.name === thData.name) : undefined;
    };

    getDatasForThGroup = (gId): any[] => {
        const {getThData, getSelThId} = this.props;
        const thData = getThData(gId) || {};
        const selThId = getSelThId(gId) || {};

        const thDatas = Object.keys(selThId).map((thGroup) => {
            const selThIdItem = selThId[thGroup];
            return thData[selThIdItem];
        });

        // return selThId !== undefined ? thData[selThId] : undefined;

        return thDatas;
    };

    getDrugsForThGroup = (gId): {name: string; drugCompositeId: number}[] => {
        const therapies = this.getTherapyForThGroup(gId);
        if (!therapies || !therapies.length) {
            return [];
        }

        const result = [];

        therapies.forEach((therapy) => {
            if (therapy.isSimple) {
                if (!!therapy.treatments) {
                    result.push(Object.keys(therapy.treatments).filter(t => !!therapy.treatments[t]));
                }
            } else {
                const thDatas = this.getDatasForThGroup(gId);

                if (!!thDatas) {
                    thDatas.filter((thData) => thData.name === therapy.name).forEach((thData) => {
                        const {data: {groups} = {}} = thData;
                        const groupTreatments = getSelectedTreatmentsList(groups);
                        if (!!groupTreatments) {
                            groupTreatments.forEach((groupTreatment) => {
                                result.push({
                                    name: groupTreatment.treatment.name,
                                    drugCompositeId: groupTreatment.treatment.drugCompositeId,
                                });
                            });

                            // const referent = treatments.find(ref => ref.isSel);
                            // return Boolean(referent) ? [referent.name] : [];
                        }
                    });
                }
            }
        });

        return result;
    };

    getDrugsWithTherapyForThGroup = (gId) => {
        const {getTherapyFullname} = this.props;

        const therapies = this.getTherapyForThGroup(gId);
        if (!therapies || !therapies.length) {
            return [];
        }

        const result = [];
        therapies.forEach((therapy) => {
            if (therapy.isSimple) {
                if (Boolean(therapy.treatments)) {
                    const drugs = Object.keys(therapy.treatments)
                        .filter(t => therapy.treatments[t]);
                    return {
                        therapy: getTherapyFullname(therapy.name),
                        drugs: drugs.map((drug) => {
                            return {
                                drugName: drug,
                            };
                        }),
                    };
                }
            } else {
                const thDatas = this.getDatasForThGroup(gId);

                if (!!thDatas) {
                    thDatas.filter((thData) => thData.name === therapy.name).forEach((thData) => {
                        const {data: {groups} = {}} = thData;
                        const groupTreatments = getSelectedTreatmentsList(groups);
                        if (!!groupTreatments) {
                            // const result = [{
                            //     therapy: getTherapyFullname(therapy.name),
                            //     drugs: [],
                            // }];
                            // groupTreatments.forEach((groupTreatment) => {
                            //     result[0].drugs.push(
                            //         {
                            //             drugName: groupTreatment.treatment.name,
                            //             drugId: groupTreatment.treatment.drugCompositeId,
                            //         }
                            //     );
                            // });
                            const drugs = [];
                            groupTreatments.forEach((groupTreatment) => {
                                drugs.push(
                                    {
                                        drugName: groupTreatment.treatment.name,
                                        drugId: groupTreatment.treatment.drugCompositeId,
                                    }
                                );
                            });
                            result.push({
                                therapy: getTherapyFullname(therapy.name),
                                drugs: drugs,
                            });
                            // return result;
                            // const referent = treatments.find(ref => ref.isSel);
                            // return Boolean(referent) ? [{
                            //     therapy: getTherapyFullname(therapy.name),
                            //     drugs: [
                            //         {
                            //             drugName: referent.name,
                            //         }
                            //     ],
                            // }] : [];
                        }
                    });
                }
            }
        });

        return result;
    };

    getReferentForThGroup = (gId) => {
        const drugs = this.getDrugsForThGroup(gId).map((drug) => drug.name);

        if (drugs.length === 0) {
            return undefined;
        }

        return drugs
            .map((drug) => capitalizeFirstLetter(drug))
            .join(', ');
    };

    getSubgroupsForThGroup = (gId) => {
        const thDatas = this.getDatasForThGroup(gId);
        const result = [];
        if (!!thDatas) {
            thDatas.forEach((thData) => {
                const {data: {subgroups} = {}} = thData;
                if ((subgroups || {}).subgroups) {
                    result.push(subgroups.subgroups);
                }
            });
        }

        return result;
    };

    getSubgroupsView = (gId) => {
        const {intl, getTherapyFullname, getSelThId, getThData, isTherapyActive} = this.props;
        const selThId = getSelThId(gId);
        const thData = getThData(gId);

        if (selThId === undefined || thData === undefined || selThId >= thData.length || selThId === -1) {
            return undefined;
        }

        const isSomeActive = Object.keys(selThId).some((thGroup) => {
            const selThIdItem = selThId[thGroup];

            const therapyName = thData[selThIdItem].name;
            if (isTherapyActive(therapyName)) {
                return true;
            }
        });
        if (!isSomeActive) {
            return undefined;
        }

        const subgroups = this.getSubgroupsForThGroup(gId);

        if (!!subgroups && !!subgroups.length) {
            return (
                <Box key={gId}>
                    {Object.keys(selThId).map((thGroup) => {
                        const selThIdItem = selThId[thGroup];
                        const therapyName = thData[selThIdItem].name;
                        if (isTherapyActive(therapyName)) {
                            return (
                                <Box key={thGroup}>
                                    <Typography color='primary'>
                                        {getTherapyFullname(therapyName)}
                                    </Typography>
                                    <ul>
                                        {
                                            subgroups
                                                .map((subgroups2) => {
                                                    return subgroups2.map((subgroup, id) => {
                                                        if (!!subgroup.selTypes && !!subgroup.selTypes.length) {
                                                            let selTypeNames = subgroup.selTypes
                                                                // .map(it => TypeNames[it])
                                                                .map(it => intl.formatMessage({
                                                                    id: 'type.names.'+TypeNames[it],
                                                                    defaultMessage: TypeNames[it]
                                                                }))
                                                                .join(", ");
                                                            return (
                                                                <li key={id}>
                                                                    <TypographyGroupSel key={id}>
                                                                        {`${subgroup.name} (${selTypeNames})`}
                                                                    </TypographyGroupSel>
                                                                </li>
                                                            );
                                                        } else {
                                                            return (
                                                                <li key={id}>
                                                                    <Typography>
                                                                        {subgroup.name}
                                                                    </Typography>
                                                                </li>
                                                            );
                                                        }
                                                    });
                                                })
                                        }
                                    </ul>
                                    <PanelDivider/>
                                </Box>
                            );
                        } else {
                            return undefined;
                        }
                    })}
                </Box>
            );
        } else {
            return undefined;
        }
    };

    viewSection1 = () => {
        const {classes, lines, selLineId, fields} = this.props;
        const {paramsDrugs} = this.state;
        const params = {};

        const selLine = selLineId !== undefined ? lines[selLineId] : undefined;
        if (selLine !== undefined) {
            selLine.features
                .filter(it => Boolean(FeatureNames[it.name]))
                .forEach(it => {
                    params[FeatureNames[it.name]] = it.value;
                });
        }

        if (fields !== undefined) {
            fields
                .filter(f => f.isMain && Boolean(f.value) && Boolean(f.vars) && f.vars.length > 0)
                .forEach(f => {
                    let selId = f.vars.findIndex(v => v.id === f.value);
                    if (selId > 0) {
                        params[f.name] = f.vars[selId].name;
                    }
                });
        }

        const subgroupsView = [0, 1]
            .map(gId => this.getSubgroupsView(gId))
            .filter(sgView => sgView !== undefined);

        return (
            <Box>
                <Typography variant={"h6"} align={"center"}>
                    1. <FormattedMessage id={'label.bl.patient_info'} defaultMessage={'Patient info'}/>
                </Typography>
                <PanelDivider/>
                <Container maxWidth={"sm"}>
                    {/*<Table>*/}
                    {/*    <TableBody>*/}
                    {/*        {Object.keys(params).map(key => (*/}
                    {/*            <TableRow key={key}>*/}
                    {/*                <TableCell>{key}</TableCell>*/}
                    {/*                <TableCell align={"right"}>{params[key]}</TableCell>*/}
                    {/*            </TableRow>*/}
                    {/*        ))}*/}
                    {/*        {paramsDrugs && paramsDrugs.map((paramDrug, id) => (*/}
                    {/*            paramDrug.drugs.map((drug) => (*/}
                    {/*                <DrugResultRow key={Object.keys(params).length + id} row={{groupName: paramDrug.groupName, drugName: drug.drugName, tradeItems: drug.tradeItems}} classes={classes}>*/}
                    {/*                </DrugResultRow>*/}
                    {/*            ))*/}
                    {/*        ))}*/}
                    {/*    </TableBody>*/}
                    {/*</Table>*/}
                    {Object.keys(params).map(key => (
                        <Grid container direction={"row"} key={key} className={classes.resultParam}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamName}>{key}</Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamValue}>{params[key]}</Grid>
                        </Grid>
                    ))}
                    {paramsDrugs && paramsDrugs.map((paramDrug, id) => (
                        paramDrug.drugs.map((drug, index2) => (
                            <DrugResultRow key={index2} row={{groupName: paramDrug.groupName, drugName: drug.drugName, tradeItems: drug.tradeItems}} classes={classes}>
                            </DrugResultRow>
                        ))
                    ))}
                    <PanelDivider/>

                    {subgroupsView.length > 0 &&
                    <Box>
                        <PanelDivider/>
                        <SelTextView>
                            <FormattedMessage id={'label.bl.calc_therapy_risk_factors'}
                                              defaultMessage={'Calc therapy risc factors:'}/>
                        </SelTextView>
                        <PanelDivider/>
                        <Grid container>
                            {subgroupsView
                                .map((sgView, id) => (
                                    <Grid item key={id}
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          lg={12}
                                          xl={12}
                                    >
                                        {sgView}
                                    </Grid>
                                ))}
                        </Grid>
                    </Box>
                    }

                </Container>
            </Box>
        );
    };

    viewSection11 = () => {
        const {intl, getThNames, nosologyData, getTherapyFullname} = this.props;

        const thrs = nosologyData.therapyGroups
            .filter(th => th.selThId !== undefined && th.selThId !== -1)
            .map(({selThId, thData}, gId) => {
                return Object.keys(selThId).map((thGroup) => {
                    const selThIdItem = selThId[thGroup];

                    // const {data: {subgroups, groups} = {}, name} = thData[selThId];
                    const {data: {subgroups, groups} = {}, name} = thData[selThIdItem];
                    if (!Boolean(name)) {
                        return (<Box/>);
                    }
                    const thNames = getThNames(gId);
                    const therapy = thNames.find(t => t.name === name);

                    if (!Boolean(therapy)) {
                        return (<Box/>);
                    }

                    // const {data: {subgroups, refers} = {}} = thData.find(d => d.name === therapy.name);
                    let referents;

                    if (therapy.isSimple) {
                        referents = Boolean(therapy.treatments)
                            ? Object.keys(therapy.treatments)
                                .filter(t => therapy.treatments[t])
                                .map(tr => capitalizeFirstLetter(tr))
                                .join(', ')
                            : undefined;
                    } else {
                        referents = getSelectedTreatmentsList(groups).map((groupTreatment) => groupTreatment.treatment.name).join(', ');
                    }

                    // let subgroupsView;
                    if (Boolean(subgroups)) {
                        /*
                                            const chartData = initGroupedBarChart2([
                                                    {
                                                        // name: subgroups.efficacyName, // stringDivider(subgroups.efficacyName, 25, '<br>'),
                                                        name: stringDivider(subgroups.efficacyName, 40, '<br>'),
                                                        color: GroupedBarChartColors.GREEN,
                                                    },
                                                    {
                                                        // name: subgroups.safetyName, // stringDivider(subgroups.safetyName, 25, '<br>'),
                                                        name: stringDivider(subgroups.safetyName, 40, '<br>'),
                                                        color: GroupedBarChartColors.RED,
                                                    },
                                                ],
                                                BarChartOrientation.HORIZONTAL,
                                                true
                                            );
                        */

                        /*
                                            subgroupsView = (
                                                <Grid container>
                                                    {
                                                        subgroups.subgroups
                                                            .sort((subgroup1, subgroup2) => subgroup1.name.localeCompare(subgroup2.name))
                                                            .forEach((subgroup, id) => {
                                                                if (!(subgroup.rate[0] === 0 && subgroup.rate[1] === 0)) {
                                                                    // const tickLabel = '<span style="color: red;">' + stringDivider(subgroup.name, 15, '<br>')+ '</span>';
                                                                    let color = 'black';
                                                                    let fontWeight = 'normal';
                                                                    if ((subgroup.selTypes || []).length > 0) {
                                                                        if ((subgroup.selTypes || []).length === 2) {
                                                                            fontWeight = 'bold';
                                                                        } else {
                                                                            if ((subgroup.selTypes || []).includes(1)) {
                                                                                color = GroupedBarChartColors.GREEN;
                                                                            } else if ((subgroup.selTypes || []).includes(2)) {
                                                                                color = GroupedBarChartColors.RED;
                                                                            }
                                                                        }
                                                                    }
                                                                    const tickLabel = `<span style="color: ${color};font-weight: ${fontWeight};">` + stringDivider(subgroup.name, 55, '<br>') + '</span>';
                                                                    chartData.data[0].x.unshift(round(subgroup.rate[0]));
                                                                    chartData.data[0].y.unshift(tickLabel);
                                                                    chartData.data[1].x.unshift(round(subgroup.rate[1]));
                                                                    chartData.data[1].y.unshift(tickLabel);

                                                                    // chartData.data[0].text = chartData.data[0].x.map(String);
                                                                    // chartData.data[1].text = chartData.data[1].x.map(String);
                                                                    chartData.data[0].text = chartData.data[0].x.map((x) => toFixed(x));
                                                                    chartData.data[1].text = chartData.data[1].x.map((x) => toFixed(x));
                                                                }
                                                            })
                                                    }

                                                    {
                                                        (chartData || {}).data &&
                                                        // (
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <PanelDivider/>
                                                                    <GroupedBarChart chartData={chartData} />
                                                                </Box>
                                                            </Grid>
                                                        // )
                                                    }

                                                </Grid>
                                            );
                        */
                    }

                    let chartDataTherapyTreatment = {};
                    if (!!referents && (((nosologyData.outcomeRiskForTherapies || {})[therapy.name] || {}).data || []).length > 0) {
                        chartDataTherapyTreatment[therapy.name] = initGroupedBarChart2([
                                {
                                    name: ((nosologyData.outcomeRiskForTherapies || {})[therapy.name] || {}).riskThName,
                                    color: GroupedBarChartColors.GREEN,
                                },
                                {
                                    name: ((nosologyData.outcomeRiskForTherapies || {})[therapy.name] || {}).riskPlName,
                                    color: GroupedBarChartColors.BLUE,
                                },
                            ],
                            BarChartOrientation.HORIZONTAL,
                            true
                        );

                        nosologyData.outcomeRiskForTherapies[therapy.name].data
                            .sort((outcomeRiskForTherapy1, outcomeRiskForTherapy2) => outcomeRiskForTherapy1.name.localeCompare(outcomeRiskForTherapy2.name))
                            .forEach((outcomeRiskForTherapy) => {
                                if (!(outcomeRiskForTherapy.riskPl === 0 && outcomeRiskForTherapy.riskTh === 0)) {
                                    const tickLabel = stringDivider(outcomeRiskForTherapy.name, 55, '<br>');
                                    chartDataTherapyTreatment[therapy.name].data[1].x.push(outcomeRiskForTherapy.riskPl);
                                    chartDataTherapyTreatment[therapy.name].data[1].y.push(tickLabel);
                                    chartDataTherapyTreatment[therapy.name].data[0].x.push(outcomeRiskForTherapy.riskTh);
                                    chartDataTherapyTreatment[therapy.name].data[0].y.push(tickLabel);

                                    chartDataTherapyTreatment[therapy.name].data[0].text = chartDataTherapyTreatment[therapy.name].data[0].x.map((x) => toFixed(x));
                                    chartDataTherapyTreatment[therapy.name].data[1].text = chartDataTherapyTreatment[therapy.name].data[1].x.map((x) => toFixed(x));
                                }
                            })
                    }

                    return (
                        <Box key={therapy.name}>
                            {((chartDataTherapyTreatment[therapy.name] || {}).data || (Boolean(subgroups) && subgroups.subgroups.length > 0)) &&
                                <SelTextView align={"center"}>
                                    {getTherapyFullname(therapy.name, therapy.title)}
                                    <br/>
                                    {intl.formatMessage({
                                        id: 'label.bl.risk_factors_prognosis',
                                        defaultMessage: 'Risk factors prognosis'
                                    })}
                                </SelTextView>
                            }
                            {/*
                        {Boolean(subgroups) && subgroups.subgroups.length > 0 &&
                            <Box>
                                {Boolean(subgroupsView) && subgroupsView}
                            </Box>
                        }
*/}
                            {!!referents && !!(chartDataTherapyTreatment[therapy.name] || {}).data &&
                                <Box>
                                    <PanelDivider/>
                                    {!!chartDataTherapyTreatment[therapy.name] &&
                                        <GroupedBarChart chartData={chartDataTherapyTreatment[therapy.name]}/>}
                                </Box>
                            }
                        </Box>
                    );
                });
            });

        if (thrs.length > 0) {
            return (
                <Box>
                    <Container maxWidth={"sm"}>
                        <Box>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    {
                                        thrs.map((thr, id) => (
                                            <Box key={id}>
                                                <PanelDivider/>
                                                <Box>
                                                    {thr}
                                                </Box>
                                            </Box>
                                    ))
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            );
        }
    };

    viewSection111 = () => {
        const therapies = this.getTherapyForThGroup(0);
        const referent = this.getReferentForThGroup(0);
        let content;

        if (!!therapies && !!therapies.length) {
            content = (
                <Box>
                    {referent &&
                    <Box>
                        {/*<SelTreatView*/}
                        {/*    treatName={referent}*/}
                        {/*/>*/}
                        {/*<TypographyGroupSel variant={"body1"}>*/}
                        {/*    {referent}*/}
                        {/*</TypographyGroupSel>*/}
                        <PanelDivider/>
                    </Box>
                    }
                    {this.getTextsViewWithSection(1)}
                </Box>
            );
        }

        return (
            <Box>
                <PanelDivider/>
                {content}
            </Box>
        );
    };

    viewSection2 = () => {
        const therapies = this.getTherapyForThGroup(0);
        const referent = this.getReferentForThGroup(0);
        let content;

        if (!!therapies && !!therapies.length) {
            content = (
                <Box>
                    {referent &&
                    <Box>
                        {/*<SelTreatView*/}
                        {/*    treatName={referent}*/}
                        {/*/>*/}
                        {/*<TypographyGroupSel variant={"body1"}>*/}
                        {/*    {referent}*/}
                        {/*</TypographyGroupSel>*/}
                        <PanelDivider/>
                    </Box>
                    }
                    {this.getTextsViewWithSection(2)}
                </Box>
            );
        } else {
            content = (
                <Box>
                    <Typography>
                        <FormattedMessage id={'label.bl.therapy_not_recoment'}
                                          defaultMessage={'Therapy is not recommend'}/>
                    </Typography>
                    <PanelDivider/>
                </Box>
            );
        }

        return (
            <Box>
                <Typography variant={"h6"} align={"center"}>
                    2. <FormattedMessage id={'label.bl.aad_therapy'} defaultMessage={'AAD therapy'}/>
                </Typography>
                <PanelDivider/>
                {content}
            </Box>
        );
    };

    viewSection3 = () => {
        const therapies = this.getTherapyForThGroup(1);
        const referent = this.getReferentForThGroup(1);
        let content;

        if (!!therapies && !!therapies.length) {
            content = (
                <Box>
                    {/*{referent &&*/}
                    {/*<Box>*/}
                    {/*    <SelTreatView*/}
                    {/*        treatName={referent}*/}
                    {/*    />*/}
                    {/*    <PanelDivider/>*/}
                    {/*</Box>*/}
                    {/*}*/}
                    {this.getTextsViewWithSection(3)}
                </Box>
            );
        } else {
            content = (
                <Box>
                    <FormattedMessage id={'label.bl.therapy_not_recoment'} defaultMessage={'Therapy is not recommend'}/>
                    <PanelDivider/>
                </Box>
            );
        }

        return (
            <Box>
                <Typography variant={"h6"} align={"center"}>
                    3. <FormattedMessage id={'label.bl.noac_therapy'} defaultMessage={'NOAC therapy'}/>
                </Typography>
                <PanelDivider/>
                {content}
            </Box>
        );
    };

    viewSection4 = () => {
        return (
            <Box>
                <Typography variant={"h6"} align={"center"}>
                    4. <FormattedMessage id={'label.bl.user_risk_factors'}
                                         defaultMessage={'Management of risk factors and concomitant cardiovascular diseases'}/>
                </Typography>
                {this.getTextsViewWithSection(4)}
            </Box>
        );
    };

    viewSection5 = () => {
        return (
            <Box>
                <Typography variant={"h6"} align={"center"}>
                    5. <FormattedMessage id={'label.bl.patient_inv'} defaultMessage={'Patient involvement'}/>
                </Typography>
                {this.getTextsViewWithSection(5)}
            </Box>
        );
    };

    viewSection6 = () => {
        return (
            <Box>
                <Typography variant={"h6"} align={"center"}>
                    6. <FormattedMessage id={'label.bl.rec_diagnostic'}
                                         defaultMessage={'Recommendations for diagnostic workup of atrial fibrillation patients'}/>
                </Typography>
                {this.getTextsViewWithSection(6)}
            </Box>
        );
    };

    viewSection7 = () => {
        return (
            <Box>
                <Typography variant={"h6"} align={"center"}>
                    7. <FormattedMessage id={'label.bl.dynamic_subscribe'} defaultMessage={'Dynamic subscribe'}/>
                </Typography>
                {this.getTextsViewWithSection(7)}
            </Box>
        );
    };

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    viewForestplot = (gId) => {
        const {getThNames, getSelThId, forestPlotCharts, intl} = this.props;
        const {forestPlotView} = this.state;
        if (!!forestPlotView) {
            return forestPlotView;
        }
        // const thNames = getThNames(gId);
        // const selThId = getSelThId(gId);
        //
        // if (selThId === undefined || selThId < 0 || selThId >= thNames.length) {
        //     return <Box/>;
        // }

        const _forestPlotCharts = [];
        [0, 1].forEach(gId => {
            const thNames = getThNames(gId) || {};
            const selThId = getSelThId(gId) || {};

            Object.keys(selThId).forEach((thGroup) => {
                const selThIdItem = selThId[thGroup];
                // if (!(selThIdItem === undefined || selThId < 0 || selThId >= thNames.length)) {
                if (!(selThIdItem === undefined || selThIdItem < 0 || selThIdItem >= thNames.length)) {
                    _forestPlotCharts.push(
                        <React.Fragment key={gId}>
                            {forestPlotCharts(thNames[selThIdItem], gId)}
                        </React.Fragment>
                    );
                }
            });
        });

        const _forestPlotView = (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                charts={undefined}
                forestPlots={_forestPlotCharts}
                // loadCharts={this.loadAllBaselines()}
                loadCharts={undefined}
                needUpdate={new Date().getTime()}
                setExpandedCharts={this.setExpandedCharts}
                variant={'outlined'}
            >
            </ExpandChartsPanel>
        );

        this.setState({forestPlotView: _forestPlotView}, () => {
            return forestPlotView;
        });
        // return forestPlotView;

        // return (
        //     <Box>
        //         <PanelDivider/>
        //         <PaperColor className={classes.contentBox}>
        //             {forestPlotCharts(thNames[selThId], gId)}
        //         </PaperColor>
        //         <PanelDivider/>
        //     </Box>
        // );
    };

    setTherapyCompare = (therapyCompare) => {
        const {
            setTherapyCompare,
            autoSave,
        } = this.props;

        setTherapyCompare(therapyCompare, () => {
            if (!!autoSave) {
                autoSave();
            }
        });
    }

    comparisonsView = () => {
        const {
            showComparisons,
            getTherapyPresumably,
            getSelVarIds,
            getThRecommend,
            loadTherapyCompare,
            loadTherapyCompareStatistics,
            getTherapyCompare,
            getTouched,
            activePatient,
        } = this.props;
        const therapyPresumably = getTherapyPresumably();

        return (
            showComparisons && therapyPresumably && therapyPresumably.thData &&
            <ComparisonsView
                getTherapyPresumably={getTherapyPresumably}
                getSelectVars={getSelVarIds}
                nosology={NosologyType.ATRIAL_FIBRILLATION}
                getThRecommend={getThRecommend}
                loadTherapyCompare={loadTherapyCompare}
                loadTherapyCompareStatistics={loadTherapyCompareStatistics}
                getTherapyCompare={getTherapyCompare}
                setTherapyCompare={this.setTherapyCompare}
                getTouched={getTouched}
                activePatient={activePatient}
            />
        );
    };

    render() {
        const {classes} = this.props;
        const {forestPlotView} = this.state;

        return (
            <Paper className={classes.contentBox}>

                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    {this.viewSection1()}
                    {this.viewSection11()}
                    {this.viewSection111()}
                </PaperColor>
                <PanelDivider/>

                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    {this.viewSection2()}
                </PaperColor>
                <PanelDivider/>

                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    {this.viewSection3()}
                </PaperColor>
                <PanelDivider/>

                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    {this.viewSection4()}
                </PaperColor>
                <PanelDivider/>

                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    {this.viewSection5()}
                </PaperColor>
                <PanelDivider/>

                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    {this.viewSection6()}
                </PaperColor>
                <PanelDivider/>

                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    {this.viewSection7()}
                </PaperColor>
                <PanelDivider/>

                {/*{[0, 1].map(gId => (*/}
                {/*    <Box key={gId}>*/}
                {/*        {this.viewForestplot(gId)}*/}
                {/*    </Box>*/}
                {/*))}*/}
                {/*{this.viewForestplot()}*/}
                {forestPlotView && forestPlotView}

                {this.comparisonsView()}
            </Paper>
        );
    }
}

TherapyResult.propTypes = {
    classes: PropTypes.object.isRequired,
    nosologyData: PropTypes.object.isRequired,
    lines: PropTypes.object.isRequired,
    selLineId: PropTypes.number.isRequired,
    fields: PropTypes.arrayOf(PropTypes.object),
    getThNames: PropTypes.func.isRequired,
    getSelThId: PropTypes.func.isRequired,
    getThData: PropTypes.func.isRequired,
    getSelectLine: PropTypes.func.isRequired,
    getFeatures: PropTypes.func.isRequired,
    getSelVarIds: PropTypes.func.isRequired,
    forestPlotCharts: PropTypes.func.isRequired,
    getTherapyFullname: PropTypes.func.isRequired,
    isTherapyActive: PropTypes.func.isRequired,
    getTherapyPresumably: PropTypes.func.isRequired,
    getTherapyCompare: PropTypes.func.isRequired,
    setTherapyCompare: PropTypes.func.isRequired,
    getTouched: PropTypes.func.isRequired,
    loadTherapyCompare: PropTypes.func.isRequired,
    loadTherapyCompareStatistics: PropTypes.func.isRequired,
    getThRecommend: PropTypes.func.isRequired,
    setDisableResultNext: PropTypes.func.isRequired,
    showComparisons: PropTypes.bool,
    autoSave: PropTypes.func.isRequired,
    activePatient: PropTypes.object,
};

export default compose(
    withStyles(styles)
)(TherapyResult);
