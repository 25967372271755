import React from "react";
import PropTypes from 'prop-types';
import {Box, Checkbox, FormControlLabel, Grid, Paper, Typography,} from "@material-ui/core";
import PanelDivider from "../PanelDivider";
import {PaperColorSelect, SelectType} from "../PaperColor";
import SelTextView from "../SelTextView";
import ContainerCenter from "../ContainerCenter";
import { FormattedMessage } from "react-intl";

export default class TherapyLine extends React.Component{

  constructor(props) {
    super(props);

    this.state = { };
  }

  onTherapyChange = (lineId) => (e) => {
    const {isPrescriptionReadonly, setSelectLine} = this.props;
    if (isPrescriptionReadonly) {
      e.preventDefault();
      return;
    }

    if (e.target.checked && setSelectLine) {
      setSelectLine(lineId);
    }
  };

  // onTherapyChangeSkip = (lineId) => (e) => {
  //   const {isPrescriptionReadonly, setSelectLine} = this.props;
  //   if (isPrescriptionReadonly) {
  //     e.preventDefault();
  //     return;
  //   }
  //
  //   if (e.target.checked && setSelectLine) {
  //     setSelectLine(lineId);
  //   }
  // };

  render() {
    const { classes, getRecomentTextsView, selLineId = 0, varLines, isPrescriptionReadonly } = this.props;
    const therapyNotAvailable = (
      <Box className={classes.contentBox}>
        <Paper variant={"outlined"} className={classes.contentBox}>
          <ContainerCenter>
            <Typography variant='body1'>
              <FormattedMessage id={'label.bl.therapy_not_recoment'} defaultMessage={'Therapy is not required'} />
          </Typography>
          </ContainerCenter>
        </Paper>
      </Box>
    );

    if (Boolean(varLines) && Object.keys(varLines).length > 0) {
      const lineItems = Object.keys(varLines)
        .map(lineId => Number(lineId))
        .filter(lineId => {
          const line = varLines[lineId];
          return line.therapies !== undefined && line.therapies.length > 0;
        })
        .map(lineId => {
          const line = varLines[lineId];

          return (
            <Grid item key={lineId}
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
            >
              <PaperColorSelect
                className={classes.contentBox}
                seltype={selLineId === lineId ? SelectType.POSITIVE : SelectType.NONE}>
                <FormControlLabel control={
                  <Checkbox
                    color={"primary"}
                    checked={selLineId === lineId}
                    value={lineId}
                    onChange={this.onTherapyChange(lineId)}
                    // onChange={e => {
                    //   if (isPrescriptionReadonly) {
                    //     e.preventDefault();
                    //     return;
                    //   }
                    //
                    //   if (e.target.checked && setSelectLine) {
                    //     setSelectLine(lineId);
                    //   }
                    // }}
                  />
                } label={<SelTextView>{line.title}</SelTextView>} />
                <ul>
                  {line.therapies.map((therapy, id) => (
                    <li key={id}><Typography>{therapy.title}</Typography></li>
                  ))}
                </ul>
              </PaperColorSelect>
            </Grid>
          );
        });

      let content = therapyNotAvailable;

      if (lineItems.length > 0) {
        content = (
          <Grid container className={classes.contentBox}>
            <Grid item xs={12}>
              <Grid container justifyContent='center' spacing={2}>
                {lineItems}

                {/*skip select*/}
{/*
                <Grid item xs={12}>
                  <Grid container justifyContent='flex-end'>
                    <Grid item>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={selLineId === -1}
                                color='primary'
                            />
                          }
                          onChange={this.onTherapyChange(-1)}
                          label={
                            <Typography color={'primary'}>
                              <FormattedMessage id='label.therapy.select.skip'
                                                defaultMessage='Skip therapy selection'/>
                            </Typography>
                          }
                      />
                    </Grid>
                  </Grid>
                </Grid>
*/}

              </Grid>
            </Grid>
          </Grid>
        );
      }

      return (
        <Paper className={isPrescriptionReadonly ? classes.disabled : ''}>
          {getRecomentTextsView && getRecomentTextsView() }
          <PanelDivider />
          {content}
        </Paper>
      );
    } else {
      return therapyNotAvailable;
    }
  }
}

TherapyLine.propTypes = {
  classes: PropTypes.object.isRequired,
  isPrescriptionReadonly: PropTypes.bool,
  varLines: PropTypes.object.isRequired,
  getRecomentTextsView: PropTypes.func,
  selLineId: PropTypes.number,
  selSelectLine: PropTypes.func,
};

TherapyLine.defaultProps = {
  selLineId: 0,
};

