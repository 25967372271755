import { gql } from '@apollo/client';
import {getPromiseQueryResult} from "../utils";
import {RegProfileDto} from "../models/registration";
import {UserProfile} from "../models/user";
import config from "../config";

const RegProfile = `
        id
        name
        descr
`;

const RegCode = `
        id
        type
        username
        isAccepted
        isActive
        createAt
        endAt
        counter
        regLink
        descr
        regProfile {
            ${RegProfile}
        }
`;

const RegCodeGroup = `
        id
        name
`;

const RegCodeUser = `
        username
        createAt
`;

const Role = `
        id
        name
`;

const User = `
        id
        username
        firstname
        lastname
        enabled
        authorities
`;

const PubInvite = `
        id
        user {
            ${User}
        }
        regCode {
            ${RegCode}
        }
        role {
            ${Role}
        }
        createAt
        availRegs
        usedRegs
        isEditable
        isRemovable
        groupId
`;

const Profile = `
        id
        spec {
          name
        }
        city {
          name
        }
        job
        degree
        experience
        skills {
          name
        }
`;

const UserRegRequestDetail = `
        id
        regCode {
          ${RegCode}
        }
        role {
          ${Role}
        }
        createAt
        profile {
          ${Profile}
        }
`;

export const getRegProfiles = gql`
  {
    getRegProfiles {
      ${RegProfile}
    }
  }
`;

export const loadGetRegProfiles = (client, signal): Promise<RegProfileDto[]> => {
  return client.query({
      query: getRegProfiles,
      context: {
          uri: config.options.server.api_url + config.options.server.api_uri,
          fetchOptions: {
              signal,
          },
      },
  })
      .then(({data, _}) => {
          return getPromiseQueryResult(data, 'getRegProfiles', []);
      });
}

export const getRegistrationRequests = gql`
  {
    getRegistrationRequests {
      id
      regCode {
        ${RegCode}
      }
      role {
        ${Role}
      }
      profile {
        ${Profile}
      }
      createAt
    }
  }
`;

export const getUserRegRequests = gql`
  {
    getUserRegRequests {
      id
      regCodeId
      username
      isAccepted
      createAt
    }
  }
`;

export const getUserRegRequestDetail = gql`
  query ($id: Int!) {
    getUserRegRequestDetail(id: $id) {
      ${UserRegRequestDetail}
    }
  }
`;

export const getInvites = gql`
  {
    getInvites  {
      id
      user {
          ${User}
      }
      regCode {
        ${RegCode}
      }
      role {
        ${Role}
      }
      createAt
    }
  }
`;

export const getPubInvites = gql`
  {
      getPubInvites {
          ${PubInvite}
      }
  }
`;

export const getRegCodeGroups = gql`
  {
    getRegCodeGroups {
          ${RegCodeGroup}
      }
  }
`;

export const resendInvite = gql`
  query ($regCodeId: Int!) {
    resendInvite(regCodeId: $regCodeId)
  }
`;

export const getRegCodeUsers = gql`
  query ($regCodeId: Int!) {
    getRegCodeUsers(regCodeId: $regCodeId) {
      ${RegCodeUser}
    }
  }
`;

export const getUserProfileByUsername = gql`
  query ($username: String!) {
    getUserProfileByUsername(username: $username) {
      ${UserProfile}
    }
  }
`;

export const deleteInvite = gql`
  mutation ($id: Int!) {
    deleteInvite(id: $id)
  }
`;

export const updateUserRegRequests = gql`
  mutation ($requests: [UserRegRequestIn!]!) {
    updateUserRegRequests(requests: $requests)
  }
`;

export const addInvite = gql`
  mutation ($invite: InviteIn!) {
    addInvite(invite: $invite)
  }
`;

export const addPubInvite = gql`
  mutation ($invite: PubInviteIn!) {
      addPubInvite(invite: $invite)
  }
`;

export const updatePubInvite = gql`
  mutation ($invite: UpdatePubInviteIn!) {
    updatePubInvite(invite: $invite)
  }
`;
