import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {FormattedMessage} from "react-intl";

// const useStyles = makeStyles((theme) => ({
//     ...mainStyles(theme),
//     wrapper: {
//         zIndex: 222
//     },
// }));

export default function RepeatVisitOrSaveButton(props) {
    // const classes = useStyles();
    const {
        onSave,
        onRestart,
        readonly,
        disableButtons,
    } = props;
    // const [open, setOpen] = React.useState(false);
    // const anchorRef = React.useRef(null);

    const saveClick = () => {
        // console.info(`You clicked SAVE`);
        // setOpen(false);
        onSave();
    };

    const restartClick = () => {
        // console.info(`You clicked RESTART`);
        // setOpen(false);
        onRestart();
    };

    // const handleToggle = () => {
    //     setOpen((prevOpen) => !prevOpen);
    // };

    // const handleClose = (event) => {
    //     if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //         return;
    //     }
    //
    //     setOpen(false);
    // };

    return (
        <Grid container direction="row" alignItems="center" spacing={2}>
            {!readonly &&
                <Grid item>
                    <Button
                        color={"primary"}
                        variant="outlined"
                        onClick={saveClick}
                        disabled={disableButtons}
                    >
                        <FormattedMessage id='label.patient.parameters.save' defaultMessage='Save data'/>
                    </Button>
                </Grid>
            }
            <Grid item>
                <Button
                    color={"primary"}
                    variant="contained"
                    onClick={restartClick}
                    disabled={disableButtons}
                >
                    <FormattedMessage id='label.patient.parameters.goPatient' defaultMessage='Go patient'/>
                </Button>
            </Grid>

{/*
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" disabled={disableButtons}>
                    <Button onClick={restartClick}
                            disabled={disableButtons}
                    >
                        <FormattedMessage id='label.patient.parameters.goPatient' defaultMessage='Go patient'/>
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        disabled={disableButtons}
                    >
                        <ArrowDropDownIcon/>
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.wrapper}>
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        <MenuItem
                                            onClick={(event) => restartClick()}
                                            disabled={disableButtons}
                                        >
                                            {intl.formatMessage({id: 'label.patient.parameters.goPatient', defaultMessage: 'Go patient'})}
                                        </MenuItem>
                                        {!readonly &&
                                            <MenuItem
                                                onClick={(event) => saveClick()}
                                                disabled={disableButtons}
                                            >
                                                {intl.formatMessage({
                                                    id: 'label.patient.parameters.save',
                                                    defaultMessage: 'Save data'
                                                })}
                                            </MenuItem>
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
*/}
        </Grid>
    );
}
