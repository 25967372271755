export const PScorePoint = `
    name
    x
    y
    scale
`;

export const PScore = `
    points {
        ${PScorePoint}
    }
    xName
    yName
`;

export interface PScorePointDto {
    name: string;
    x: number;
    y: number;
    scale: number;
}

export interface PScoreDto {
    points: PScorePoint[];
    xName: string;
    yName: string;
}
