import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    Container,
    Paper,
    Typography, Grid,
} from "@material-ui/core";
import PanelDivider from "../PanelDivider";
import SelTextView from "../SelTextView";
import ReactMarkdown from "react-markdown";
import {getHPDrugTexts} from '../../query/HPBaseline';
import {FormattedMessage} from "react-intl";
import config from "../../config";
import {getRecommendConclusionTexts} from "../../query/HeartFailureBaseline";
import DeviceView from "../DeviceView";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {
    GroupedBarChartColors,
    MessageType,
    NosologyType,
    RecommendConclusionTextsInputDto,
    riskTextColors
} from "../../const";
import ComparisonsView from "../ComparisonsView";
import {DrugResultRow} from "../drug/DrugResultRow";
import SelTreatView from "../baseline/SelTreatView";
import PaperColor from "../PaperColor";
import {loadCommercialDrugs} from "../../query/drugs";
import {
    capitalizeFirstLetter,
    clearMarkDownText,
    extendParamsDrugByTradeItems,
    getQueryResult, isMultiselect, populateCommonChart,
    populateHPChart, sortByOrder
} from "../../utils";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import GroupedBarChart from "../charts/GroupedBarChart";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";
import {loadGetRecommendConclusionTexts} from "../../query/common";

const styles = theme => ({
    ...mainStyles(theme),
    device: {
        fontSize: '14px',
    },
});

class DyslipidemiaResult extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        updateShowPanel(null);
        setLoadChartsMethod(null);

        if (this.props.activePatient.action === 'RESULT') {
            this.setVars();
            this.setSelected();
        }
        this.loadAll();
    }

    loadAll() {
        const {showComparisons, setDisableResultNext} = this.props;
        if (!showComparisons) {
            this.setTherapyCompare();
        } else {
            if (!!setDisableResultNext) {
                setDisableResultNext(true);
            }
        }
        this.loadResultTexts();
        this.loadDrugs();
    }

    setVars = () => {
/*
        const {
            nosologyData,
            getTherapyGroupDrugs,
        } = this.props;

        // console.error('+++ nosologyData:', nosologyData);
        // console.error('+++ nosologyData.touched:', nosologyData.touched);
        this.drugGroups = getTherapyGroupDrugs();
*/
/*
        this.selStep = this.steps.items.filter(step => step.selected)[0];
        this.steps.selThId = (this.selStep || {}).id;

        this.selType = this.selStep.therapies.filter(type => type.selected)[0];
        this.steps.selTypeId = (this.selType || {}).id;

        this.selDeviceType = this.selStep.supportTherapies.filter((supportTherapy) => supportTherapy.selected)[0];
        this.selDevice = ((this.selDeviceType || {}).devices || []).filter((device) => device.selected)[0];
        this.steps.selDeviceTypeId = (this.selDeviceType || {}).id;
        this.steps.selDeviceId = (this.selDevice || {}).id;

        this.selComb = ((this.selType || {}).sets || []).filter(set => set.selected)[0];
        this.steps.selCombId = (this.selComb || {}).id;

        this.selGroup = ((this.selComb || {}).drugGroups || {}).filter(set => set.selected)[0];

        nosologyData.steps = this.steps;
*/
    }

    setSelected = () => {
        const {
            nosologyData,
        } = this.props;

        let selDrugs = {};

        // console.error('+++++++++ setSelected() +++++++++ this.drugGroups:', this.drugGroups);
        (this.drugGroups || []).forEach((drugGroup) => {
            // console.error('+++ drugGroup:', drugGroup);
            drugGroup.drugs.forEach((drug) => {
                if (drug.selected) {
                    drug.groupName = drugGroup.name;
                    drug.groupShortName = drugGroup.shortName;

                    drug.selectedRegimen = drug.regimens.filter((regimen) => regimen.selected)[0];

                    if (drug.id === -1) {
                        selDrugs[drugGroup.id] = [];
                    }

                    selDrugs[drugGroup.id] = selDrugs[drugGroup.id] ? (isMultiselect(drugGroup) ? [...selDrugs[drugGroup.id], ...[drug]] : [drug]) : [drug];
                }
            });
        });

        nosologyData.selDrugs = selDrugs;
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadDrugs = () => {
        const {client, getSteps, nosologyData} = this.props;
        const {signal} = this.abortController;

        const steps = getSteps();
        // const drugs = Object.values(steps.selDrugs || {}).filter((drug) => drug.id !== -1);
        // const drugs = Object.values(steps.selDrugs || {}).flatMap((drugs) => drugs.filter((drugs) => drugs.id !== -1).map((drug) => drug));
        const drugs = Object.values(nosologyData.selDrugs || {}).flatMap((step) => {
            return Object.values(step);
        }).flatMap((drugs) => drugs.filter((drug) => drug.id !== -1)); // .map((drug) => drug.id));
        // console.error('+++++++++ loadDrugs() +++++++++ drugs:', drugs);

        const drugsIds: number[] = [];
        const paramsDrugs = [];
        if (drugs && drugs.length > 0) {
            drugs
                .forEach(drug => {
                    drugsIds.push(drug.id);
                    paramsDrugs.push(
                        {
                            groupName: drug.groupName,
                            drugs: [
                                {
                                    drugName: drug.name,
                                    drugId: drug.id,
                                }
                            ],
                        }
                    );
                });
        }

        loadCommercialDrugs(client, signal, drugsIds)
            .then((drugsByDrugDosageNames) => {
                this.setState({
                    paramsDrugs: extendParamsDrugByTradeItems(paramsDrugs, drugsByDrugDosageNames),
                });
            });
    }

    loadResultTexts = () => {
        const {client, getConclusionTexts, setConclusionTexts, patientId, nosologyId, getSteps} = this.props;
        const {signal} = this.abortController;

        const conclusionTexts = getConclusionTexts();

        if (!conclusionTexts) {
            // const steps = getSteps();

            const recommendConclusionTextsInputDto: RecommendConclusionTextsInputDto = {
                nosologyId: nosologyId,
                patientId: patientId,
                setId: 1, // steps?.selCombId,
                deviceIds: [],
            };
            loadGetRecommendConclusionTexts(client, signal, recommendConclusionTextsInputDto)
                .then((conclusionTexts) => {
                    this.setState({
                        conclusionTexts: conclusionTexts,
                    });
                    setConclusionTexts(conclusionTexts);
                });
        } else {
            this.setState({
                conclusionTexts: conclusionTexts,
            });
        }
    };

    paramsView = () => {
        const {
            classes,
            intl,
            getSteps,
            getSelectTherapyComb,
            getAbsoluteContrs,
            getRelativeContrs,
            getTherapyInfo,
            nosologyData,
            getTherapyGroupDrugsAll,
        } = this.props;
        const {paramsDrugs} = this.state;

/* +++2022.05.19+++ вернуть!
        const steps = getSteps();
        const selStep = steps.items.filter(step => steps.selThId === step.id)[0];
        const selType = selStep ? selStep.therapies.filter(type => steps.selTypeId === type.id)[0] : undefined;
        const selComb = selType ? selType.sets.filter(set => steps.selCombId === set.id)[0] : undefined;
        // const selDeviceType = selStep ? selStep.therapies.filter(type => steps.selDeviceTypeId === type.id)[0] : undefined;
        const selDeviceType = selStep ? selStep.supportTherapies.filter(supportTherapy => steps.selDeviceTypeId === supportTherapy.id)[0] : undefined;
        const selDevice = selDeviceType ? selDeviceType.devices.filter(device => steps.selDeviceId === device.id)[0] : undefined;

        const absoluteContrs = getAbsoluteContrs();
        const relativeContrs = getRelativeContrs();
        const therapyInfo = getTherapyInfo();
*/

        let params = [];

/* +++2022.05.19+++ вернуть!
        if (Boolean(selStep)) {
            params.push(
                {
                    name: intl.formatMessage({id: 'label.hp.therapy.sel', defaultMessage: 'Select therapy'}),
                    value: selStep.name,
                });
        }
*/

/* +++2022.05.19+++ вернуть!
        if (Boolean(selDeviceType) && Boolean(selDevice)) {
            params.push(
                {
                    name: selDeviceType.name,
                    value: <DeviceView device={selDevice} className={classes.device} positionEnd={true}/>,
                });
        }
*/

/* +++2022.05.19+++ вернуть!
        if (therapyInfo && therapyInfo.length > 0) {
            therapyInfo
                .forEach(ti => {
                    params.push(
                        {
                            name: ti.name,
                            value: ti.info.join(', '),
                        });
                });
        }
*/


        const absoluteContrs = ((nosologyData || {}).getTherapy || []).sort(sortByOrder).flatMap((rootTherapy) => (rootTherapy.absoluteCond || {}).contrGroups) || [];
        if (absoluteContrs && absoluteContrs.length > 0) {
            absoluteContrs
                .forEach(absoluteContr => {
                    params.push(
                        {
                            name: intl.formatMessage({
                                id: 'label.hp.comp_cont',
                                defaultMessage: 'Compelling Contraindications'
                            }),
                            value: absoluteContr.name,
                        });
                });
        }

        const relativeContrs = ((nosologyData || {}).getTherapy || []).sort(sortByOrder).flatMap((rootTherapy) => (rootTherapy.relativeCond || {}).contrGroups) || [];
        if (relativeContrs && relativeContrs.length > 0) {
            relativeContrs
                .forEach(relativeContr => {
                    params[intl.formatMessage({
                        id: 'label.hp.poss_cont',
                        defaultMessage: 'Possible Contraindications'
                    })] = relativeContr.drugGroupShortName;
                    params.push(
                        {
                            name: intl.formatMessage({
                                id: 'label.hp.poss_cont',
                                defaultMessage: 'Possible Contraindications'
                            }),
                            value: relativeContr.name,
                        });
                });
        }

        if (
            !!nosologyData.getTherapy[0].therapyPools[0].therapies[0].name
            &&
            !!nosologyData.getTherapy[0].therapyPools[0].therapies[0].drugGroupCombinations[0].name
        ) {
            params.push(
                {
                    name: nosologyData.getTherapy[0].therapyPools[0].therapies[0].name || 'Название выбранной терапии',
                    value: nosologyData.getTherapy[0].therapyPools[0].therapies[0].drugGroupCombinations[0].name || 'Название выбранной комбинации',
                });
        }

        const comps = (nosologyData.getTherapy[0].therapyPools[0].therapies[0].drugGroupCombinations[0].compCombinations || [])[0] || [];

        const scRiskColor: string = riskTextColors[(comps[0] || {}).scRisk];
        const selThCombChartData = populateHPChart(comps, intl, scRiskColor, GroupedBarChartColors.BLUE);

        const drugs = Object.values(nosologyData.selDrugs || {}).flatMap((step) => {
            return Object.values(step);
        }).flatMap((drugs) => drugs.map((drug) => drug)).filter((drug) => drug.id !== -1);
        // const drugsChartsData = drugs.map((drug) => {
        //     // const scRiskColor: string = riskTextColors[(compCombinations[0] || {}).scRisk];
        //     const chartData = populateCommonChart(drug.chartData, intl, GroupedBarChartColors.GREEN, GroupedBarChartColors.BLUE);
        //     return !!chartData ? {drug: drug, chartData: chartData} : undefined;
        // }).filter((drugChartData) => !!drugChartData);
        const therapyGroupDrugs = getTherapyGroupDrugsAll().filter((drugGroup) => drugGroup.drugs && drugGroup.drugs.length > 0).sort(sortByOrder);
        // console.error('+++++++++ paramsView() +++++++++ drugs:', drugs);
        // console.error('+++++++++ paramsView() +++++++++ therapyGroupDrugs:', therapyGroupDrugs);
        const drugsChartsData = therapyGroupDrugs.flatMap((therapyGroupDrug) => {
            return therapyGroupDrug.drugs.map((therapyGroupDrugDrug) => {
                const selectedDrug = drugs.find((drug) => drug.id === therapyGroupDrugDrug.id);
                if (!!selectedDrug) {
                    const chartData = populateCommonChart(selectedDrug.chartData, intl, GroupedBarChartColors.GREEN, GroupedBarChartColors.BLUE);
                    return !!chartData ? {drug: selectedDrug, chartData: chartData} : undefined;
                }
            });
        }).filter((drugChartData) => !!drugChartData);

        return (
                (
                    (!!params && !!params.length)
                    ||
                    (!!paramsDrugs && paramsDrugs.length)
                    ||
                    (!!comps.length)
                    ||
                    !!selThCombChartData
                )
                &&
                    <Paper variant={"outlined"} className={classes.contentBox}>
                        <Container maxWidth={"sm"}>
                            {params.map((param, id) => (
                                <Grid container direction={"row"} key={id} className={classes.resultParam}>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamName}>{param.name}</Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamValue}>{param.value}</Grid>
                                </Grid>
                            ))}
                            {!!paramsDrugs && paramsDrugs.map((paramDrug, id) => (
                                paramDrug.drugs.map((drug, index2) => (
                                    <DrugResultRow key={index2} row={{groupName: paramDrug.groupName, drugName: drug.drugName, tradeItems: drug.tradeItems}} classes={classes}>
                                    </DrugResultRow>
                                ))
                            ))}

                            {(comps.length > 0) && (
                                <Box>
                                    <PanelDivider/>
                                    <Grid container direction={"row"}>
                                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><FormattedMessage id="label.outcome" defaultMessage="Outcome"/></Grid>
                                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><FormattedMessage id="label.risk" defaultMessage="Initial risk"/></Grid>
                                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><FormattedMessage id="label.risk.therapy" defaultMessage="Risk after therapy"/></Grid>
                                    </Grid>
                                    {comps.map((comp, i) => (
                                        <Grid container direction={"row"} key={i} className={classes.resultParam}>
                                            <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.resultParamName}>{comp.name}</Grid>
                                            <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.resultParamValue}>{comp.riskPl}%</Grid>
                                            <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.resultParamValue}>{comp.riskTh}%</Grid>
                                        </Grid>
                                    ))}
                                </Box>
                            )}

                            {selThCombChartData &&
                            <Box>
                                <PanelDivider/>
                                <SelTextView align={'center'}>
                                    <FormattedMessage id="label.chart.chosen_therapy.header" defaultMessage="Forecast for the chosen therapy" />
                                </SelTextView>
                                <Box>
                                    <GroupedBarChart useResizeHandler={true} responsive={true} chartData={selThCombChartData}/>
                                </Box>
                            </Box>
                            }

                        </Container>


                        {/*Графики выбранных препаратов*/}
                        <Box>
                            <Container maxWidth={"sm"}>
                                <Box>
                                    <Grid container direction={'column'}>
                                        <Grid item>
                                            {(!!drugsChartsData && !!drugsChartsData.length) && drugsChartsData.map((drugChartData, index) =>
                                                <Box key={index}>
                                                    <PanelDivider/>
                                                    <SelTextView align={"center"}>
                                                        {capitalizeFirstLetter(drugChartData.drug.name)}
                                                    </SelTextView>
                                                    <Box>
                                                        <PanelDivider/>
                                                        <GroupedBarChart useResizeHandler={true} responsive={true} chartData={drugChartData.chartData}/>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Container>
                        </Box>

                    </Paper>
        );
    };

    textsView = () => {
        const {
            classes,
        } = this.props;
        const {conclusionTexts} = this.state;

        return (
            <Box>
                {!!conclusionTexts && conclusionTexts
                    .map((secTxt, cId) => (
                        (!!secTxt.descriptions.length && secTxt.descriptions.some((description) => description.text.length > 0)) &&
                        <Box key={cId}>
                            {/*<PanelDivider/>*/}
                            <Paper className={classes.contentBox} variant={"outlined"}>
                                <SelTextView align={"center"}>{secTxt.name}</SelTextView>
                                <PanelDivider/>
                                {secTxt.descriptions.map((description, dsId) => (
                                    <Box key={dsId}>
                                        {description.label && <SelTextView variant={'body1'}>{description.label}</SelTextView>}
                                        {(description.text || []).map((text, dId) => (
                                            <Typography component={"span"} key={dId}>
                                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={text}/>
                                            </Typography>
                                        ))}
                                    </Box>
                                ))}
                            </Paper>
                        </Box>
                    ))
                }
            </Box>
        );
    };

    setTherapyCompare = (therapyCompare) => {
        const {
            setTherapyCompare,
            autoSave,
        } = this.props;

        setTherapyCompare(therapyCompare, () => {
            if (!!autoSave) {
                autoSave();
            }
        });
    }

    comparisonsView = () => {
        const {
            showComparisons,
            getTherapyPresumably,
            getSelectVars,
            getThRecommend,
            loadTherapyCompare,
            getTherapyCompare,
            getTouched,
        } = this.props;
        const therapyPresumably = getTherapyPresumably();

        return (
            showComparisons && therapyPresumably && therapyPresumably.thData &&
            <ComparisonsView
                getTherapyPresumably={getTherapyPresumably}
                getSelectVars={getSelectVars}
                nosology={NosologyType.DYSLIPIDEMIA}
                getThRecommend={getThRecommend}
                loadTherapyCompare={loadTherapyCompare}
                getTherapyCompare={getTherapyCompare}
                setTherapyCompare={this.setTherapyCompare}
                getTouched={getTouched}
            />
        );
    };

    drugsView = () => {
        const {
            classes,
            // getSteps,
            nosologyData,
        } = this.props;

        // const steps = getSteps();
        // const drugs = Object.values(steps.selDrugs || {}).filter((drug) => drug.id !== -1);
        // const drugs = Object.values(steps.selDrugs || {}).flatMap((drugs) => drugs.filter((drug) => drug.id !== -1)); // .map((drug) => drug.id));
        const drugs = Object.values(nosologyData.selDrugs || {}).flatMap((step) => {
            return Object.values(step);
        }).flatMap((drugs) => drugs.filter((drug) => drug.id !== -1)); // .map((drug) => drug.id));
        // console.error('+++++++++ drugsView() +++++++++ drugs:', drugs);

        // const therapy = this.getTherapyForThGroup(0);
        // const referent = this.getReferentForThGroup(0);
        let content;

        if (!!drugs && !!drugs.length) {
            content = (
                <Box>
                    {drugs.map((drug) => {
                        const drugText = (drug.messages.filter((message) => message.type === MessageType.DESCRIPTION)[0] || {}).payload;
                        return (
                            // <Box key={drug.id}>
                            //     <SelTreatView
                            //         treatName={drug.name}
                            //     />
                            //     <PanelDivider/>
                            // </Box>
                            <Box key={drug.id}>
                                {drug.name && <SelTreatView
                                    treatName={capitalizeFirstLetter(drug.name) + (drug.selectedRegimen ? (' - ' + drug.selectedRegimen.name) : '')}/>}
                                <Box key={drug.id}>
                                    {drugText && (
                                        <Typography component={'span'}>
                                            <ReactMarkdown remarkPlugins={[remarkGfm]}
                                                           rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                           children={clearMarkDownText(drugText)}/>
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        )
                    })}
                    {/*{this.getTextsViewWithSection(2)}*/}
                </Box>
            );
        } else {
            content = (
                <Box>
                    <Typography>
                        <FormattedMessage id={'label.bl.therapy_not_recoment'}
                                          defaultMessage={'Therapy is not recommend'}/>
                    </Typography>
                    <PanelDivider/>
                </Box>
            );
        }

        return (
            (!!drugs && !!drugs.length) &&
            <Box>
                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    <Typography variant={"h6"} align={"center"}>
                        <FormattedMessage id={'label.hp.drugs.sel'} defaultMessage={'Selected drugs'}/>
                    </Typography>
                    <PanelDivider/>
                    {content}
                </PaperColor>
                <PanelDivider/>
            </Box>
        );
    };


    render() {
        const {classes} = this.props;
        return (
            <Box className={classes.contentBox}>
                {this.paramsView()}
{/* +++2022.05.19+++ вернуть!
                {this.comparisonsView()}
*/}

                {this.drugsView()}

                {this.textsView()}
            </Box>
        );
    }
}

DyslipidemiaResult.propTypes = {
    classes: PropTypes.object.isRequired,
    getSteps: PropTypes.func.isRequired,
    getAbsoluteContrs: PropTypes.func.isRequired,
    getRelativeContrs: PropTypes.func.isRequired,
    getTherapyInfo: PropTypes.func.isRequired,
    getConclusionTexts: PropTypes.func.isRequired,
    setConclusionTexts: PropTypes.func.isRequired,
    getSelectTherapyType: PropTypes.func.isRequired,
    getSelectTherapyComb: PropTypes.func.isRequired,
    getTherapyGroupDrugsAll: PropTypes.func.isRequired,
    getSubgroups: PropTypes.func.isRequired,
    getCompGroups: PropTypes.func.isRequired,
    getPossGroups: PropTypes.func.isRequired,
    getSelectVars: PropTypes.func.isRequired,
    getTherapyDeviceNameWithDescr: PropTypes.func.isRequired,
    getTherapyPresumably: PropTypes.func.isRequired,
    getTherapyCompare: PropTypes.func.isRequired,
    setTherapyCompare: PropTypes.func.isRequired,
    getTouched: PropTypes.func.isRequired,
    loadTherapyCompare: PropTypes.func.isRequired,
    getThRecommend: PropTypes.func.isRequired,
    setDisableResultNext: PropTypes.func.isRequired,
    showComparisons: PropTypes.bool,
    autoSave: PropTypes.func.isRequired,
    activePatient: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
)(DyslipidemiaResult);
