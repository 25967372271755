import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import { Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { injectIntl } from "react-intl";
import compose from 'recompose/compose';

const styles = theme => ({
  ...mainStyles(theme),
});

class ProfileView extends React.Component{

  constructor(props) {
    super(props);

    this.state = { 
      items: this.createItems(this.props.profile),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      this.setState({
        items: this.createItems(this.props.profile),
      });
    }
  }

  getNames = () => {
    const { intl } = this.props;

    return {
      'spec': intl.formatMessage({id: 'label.speciality', defaultMessage: 'Speciality'}),
      'city': intl.formatMessage({id: 'label.city', defaultMessage: 'City'}),
      'job': intl.formatMessage({id: 'label.job', defaultMessage: 'Job'}),
      'degree': intl.formatMessage({id: 'label.degree', defaultMessage: 'Degree'}),
      'experience': intl.formatMessage({id: 'label.experience', defaultMessage: 'Experience'}),
      'skills': intl.formatMessage({id: 'label.skills', defaultMessage: 'Skills'}),
    };
  };

  createItems = (profile) => {
    const names = this.getNames();

    return Object.keys(profile)
      .filter(name => name !== 'id' && name !== '__typename')
      .map(it => {
        let name = names[it];
        if (!Boolean(name)) {
          name = it;
        }
        let value;

        switch (it) {
          case 'spec':
          case 'city':
            value = Boolean(profile[it]) ? profile[it].name : '';
            break;
          case 'skills':
            value = profile[it].map(it => it.name).join(', ');
            break;
          default:
            value = profile[it];
        }

        return { name: name, value: value };
      });
  };

  render() {
    const { items } = this.state;
    return (
      <Table>
        <TableBody>
          {items && this.state.items.map((item, id) => (
            <TableRow key={id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

ProfileView.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(ProfileView);