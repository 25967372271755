import React from "react";
import {withStyles} from '@material-ui/core/styles';
import {makeStyles} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import {TreeItem, TreeView} from '@material-ui/lab';
import type {ActivePatient, GroupDto} from '../../const';
import {
    clearContextPatient,
    getSelectedExpandedGroupFromGroups,
    getSelectedNode,
    getURLSearchParams
} from '../../utils';
import mainStyles from '../../styles/mainStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PatientsSharedIcon from "@material-ui/icons/FolderShared";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import {blue} from "@material-ui/core/colors";
import {ProfileProfileModeEnum} from "../../const";
import InfoIcon from "@material-ui/icons/Info";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const styles = theme => ({
    ...mainStyles(theme),
});

const useRecursiveTreeViewStyles = makeStyles(theme => ({
    ...mainStyles(theme),
    root: {
        // height: 110,
        flexGrow: 1,
        // maxWidth: '200px',
        paddingTop: theme.spacing(1),
        // paddingRight: theme.spacing(1),
        // paddingRight: theme.spacing(0.5),
        paddingBottom: 0,
        paddingLeft: 0,
    },
    treeItem: {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    customIcoButton: {
        "&.MuiButtonBase-root": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            marginRight: '8px',
        }
    },

    rootNode: {
        // color: theme.palette.text.secondary,
        color: theme.palette.text.primary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        // '&:focus > $content, &$selected > $content': {
        '&$selected > $content': {
            // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            backgroundColor: `var(--tree-view-bg-color, ${blue[50]})`,
            // backgroundColor: 'transparent',
            // color: 'var(--tree-view-color)',
            color: `${theme.palette.primary.main}`,
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent !important',
        },
        '&$selected > $content $label': {
            // paddingLeft: theme.spacing(2),
        },
        '&$selected > $content $labelIcon': {
            color: `${theme.palette.primary.main}`,
        },
    },
    content: {
        // color: theme.palette.text.secondary,
        color: theme.palette.text.primary,
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
        paddingRight: theme.spacing(0),
        // fontWeight: theme.typography.fontWeightMedium,
        fontWeight: theme.typography.fontWeightRegular,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        paddingLeft: theme.spacing(2),
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',

        // '&:focus, &$selected:focus': {
        //     backgroundColor: 'transparent !important',
        // }
    },

    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
        '&:focus, &$selected:focus, &$selected > $content': {
        //     backgroundColor: 'transparent !important',
            color: `${theme.palette.primary.main}`,
        }
    },
    labelIcon: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary,
        '&:focus, &$selected': {
            color: `${theme.palette.primary.main}`,
        },
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        // '&:focus, &$selected:focus': {
        //     backgroundColor: 'transparent !important',
        // }
    },
}));

const RecursiveTreeView = (props) => {
    const {
        patientsGroups,
        history,
        setContextPatient,
        routePath,
        context,
        _this,
    } = props;
    // const [open, setOpen] = React.useState(false);
    const classesRecursiveTreeViewStyles = useRecursiveTreeViewStyles();

    const activePatientRoutePath: ActivePatient = getURLSearchParams(routePath.split('?')[1]);
    const data = patientsGroups[activePatientRoutePath?.patientType ? activePatientRoutePath.patientType : activePatientRoutePath.mode].data;
    // console.error('+++ data:', data);
    // const [expanded, setExpanded] = React.useState([]);
    // const [selected, setSelected] = React.useState([]);
    const {
        setRecursiveTreeViewExpanded,
        recursiveTreeViewExpanded,
        setRecursiveTreeViewSelected,
        recursiveTreeViewSelected,
        isLoading,
    } = _this.context;

    // console.error('+++ isLoading:', isLoading);

    /*
        const onIconClick = (nodeIds) => (event) => {
        };
    */

/*
    const onLabelClick = (selectedNode) => (event) => {
        event.preventDefault();
        setRecursiveTreeViewSelected(activePatientRoutePath.patientType, selectedNode.id, selectedNode);
        clearContextPatient(setContextPatient, () => {
            history.replace(`${routePath}&group=${selectedNode.id}`);
        });
    };
*/

    const getTreeItemIcon = (mode: ProfileProfileModeEnum) => {
        let icon;
        switch (mode) {
            case ProfileProfileModeEnum.PROFILE_INFO:
                icon = <InfoIcon className={classesRecursiveTreeViewStyles.labelIcon} />;
                break;
            case ProfileProfileModeEnum.PROFILE_TARIFFS:
                icon = <MonetizationOnIcon className={classesRecursiveTreeViewStyles.labelIcon} />;
                break;
            default:
                icon = <InfoIcon color="action"/>;
        }
        return icon;
    };

    const handleToggle = (event, nodeIds) => {
        if (isLoading) {
            return;
        }

/*
        // setExpanded(nodeIds);
        if (activePatientRoutePath.group) {
            // nodeIds.push(activePatientRoutePath.group);
        }

        setRecursiveTreeViewExpanded(activePatientRoutePath.patientType, nodeIds, () => {
            _this.forceUpdate();
        });
*/
    };

    const handleSelect = (event, nodeIds) => {
        if (isLoading) {
            return;
        }

        const selectedNode: GroupDto = getSelectedNode(nodeIds, data);
        // console.error('+++ selectedNode:', selectedNode);

        if (!(selectedNode.children && selectedNode.children.length > 0)) {
            setRecursiveTreeViewSelected(activePatientRoutePath.patientType || activePatientRoutePath.mode, nodeIds, selectedNode);

            /*
                    if (activePatientRoutePath.group) {
                        const nodeIdsExpanded: [] = recursiveTreeViewExpanded;
                        if (selectedNode.children && !nodeIdsExpanded.some((node) => node === nodeIds)) {
                            nodeIdsExpanded.push(nodeIds);
                            setRecursiveTreeViewExpanded(nodeIdsExpanded, () => {
                                _this.forceUpdate();
                            });
                        }
                    }
            */

            clearContextPatient(setContextPatient, () => {
                history.replace(`${routePath}&node=${selectedNode.mode}&group=${nodeIds}`);
                // setSelected(nodeIds);
            });
        } else {
            const selectedExpandedGroupFromGroups = getSelectedExpandedGroupFromGroups([selectedNode]);
            const _selectedNode = selectedExpandedGroupFromGroups.selectedGroup;
            setRecursiveTreeViewSelected(activePatientRoutePath.patientType || activePatientRoutePath.mode, _selectedNode.id, _selectedNode);
            clearContextPatient(setContextPatient, () => {
                history.replace(`${routePath}&group=${_selectedNode.id}`);
                // setSelected(nodeIds);
            });

            const _recursiveTreeViewExpanded = context.recursiveTreeViewExpanded[activePatientRoutePath.patientType || activePatientRoutePath.mode];

            if (!_recursiveTreeViewExpanded.some((id) => id === nodeIds)) {
                _recursiveTreeViewExpanded.push(nodeIds);

                setRecursiveTreeViewExpanded(activePatientRoutePath.patientType || activePatientRoutePath.mode, _recursiveTreeViewExpanded, () => {
                    _this.forceUpdate();
                });
            }
        }
    };

/*
    const getSelectedNode = (nodeIds, nodes) => {
        let selectedNode;
        for(let i = 0; i < nodes.length; i++) {
            if(Array.isArray(nodes[i].children)) {
                selectedNode = getSelectedNode(nodeIds, nodes[i].children);
            }
            if (selectedNode) {
                return selectedNode;
            }
            if (nodes[i].id === nodeIds) {
                selectedNode = nodes[i];
                return selectedNode;
                // break;
            }
        }
    }
*/

    const renderTree = (nodes, labelIcon) => {
        return (
            <TreeItem
                key={nodes.id}
                nodeId={nodes.id}
                // label={nodes.name}
                label={
                    <div className={classesRecursiveTreeViewStyles.labelRoot}>
                        {/*<ListItemIcon>*/}
                            {nodes.children && nodes.children.length > 0 ? <PatientsSharedIcon className={classesRecursiveTreeViewStyles.labelIcon} /> : labelIcon}
                        {/*</ListItemIcon>*/}
                        <Typography variant="body2" className={classesRecursiveTreeViewStyles.labelText} component={'span'}>
                            {nodes.name}
                        </Typography>
                    </div>
                }
                className={classesRecursiveTreeViewStyles.treeItem}
                classes={{
                    root: classesRecursiveTreeViewStyles.rootNode,
                    content: classesRecursiveTreeViewStyles.content,
                    expanded: classesRecursiveTreeViewStyles.expanded,
                    selected: classesRecursiveTreeViewStyles.selected,
                    group: classesRecursiveTreeViewStyles.group,
                    label: classesRecursiveTreeViewStyles.label,
                }}
                // onIconClick={onIconClick(nodes.id)}
                // onLabelClick={onLabelClick(nodes)}
            >
                {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node, <SupervisorAccountIcon className={classesRecursiveTreeViewStyles.labelIcon} />)) : null}
            </TreeItem>
        );
    }

    return (
        <React.Fragment>
            {data && data.length > 0 &&
                <TreeView
                    className={`${isLoading ? classesRecursiveTreeViewStyles.disabled : ''} ${classesRecursiveTreeViewStyles.rootNode} ${classesRecursiveTreeViewStyles.root}`}
                    disableSelection={isLoading}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    // defaultExpanded={['root']}
                    defaultExpandIcon={<ArrowRightIcon />}
                    // expanded={expanded}
                    // selected={selected}
                    expanded={recursiveTreeViewExpanded[activePatientRoutePath.patientType || activePatientRoutePath.mode]}
                    selected={recursiveTreeViewSelected[activePatientRoutePath.patientType || activePatientRoutePath.mode]}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                    // onNodeSelect={() => {}}
                >
                    {data.map(treeItem => renderTree(treeItem, (treeItem.mode ? getTreeItemIcon(treeItem.mode) : <PatientsSharedIcon className={classesRecursiveTreeViewStyles.labelIcon} />)))}
                </TreeView>
            }
        </React.Fragment>
    );
};

export default withStyles(styles)(RecursiveTreeView);
