import React, {useEffect} from "react";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import {
    Box,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ListItemText,
    Typography, Paper
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import PanelDivider from "../PanelDivider";
import SelTextView from "../SelTextView";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FormattedMessage} from "react-intl";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
    expanded$,
    getClearCharts,
    getExpanded,
    getLoadCharts, getStartLoadCharts, startLoadCharts$,
    updateStartLoadCharts
} from "../../service/expand-charts-panel-service";
import {Subscription} from 'rxjs';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
// const styles = theme => ({
    ...mainStyles(theme),
    headerText: {
        // color: "white",
        // color: theme.palette.text.secondary,
        // color: theme.palette.primary.main,
    },
    headerIcon: {
        // color: "white",
        color: theme.palette.primary.main,
    },
    chartPaper: {
        padding: theme.spacing(2),
        paddingTop: 0,
        width: '100%',

        // display: 'flex',
        // flexDirection: 'column',
        // flex: 'auto',
        // alignItems: 'center',

        '&$expanded': {
            marginTop: theme.spacing(2),
            '&(:first-child)': {
                marginTop: theme.spacing(2),
            },
        },
    },
    loading: {
        textAlign: 'center',
        padding: '16px',
        paddingBottom: 0,
        minHeight: '62px',
    },
    accordionBorder: {
        '&:not(:last-child)': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
        },
    }
}));

const HelpTextPanel = withStyles(theme => ({
    root: {
        // marginTop: theme.spacing(2),
        // boxShadow: 'none',
        '&:not(:last-child)': {
            // TODO: +++2022.03.07+++ borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            // marginTop: theme.spacing(2),
            // '&(:first-child)': {
            //     marginTop: theme.spacing(2),
            // },
        },
    },
    expanded: {},
}))(Accordion);

const HelpTextPanelSummary = withStyles(theme => ({
    root: {
        // backgroundColor: '#ff814c',
        // backgroundColor: blue[50],
        '&:hover': {
            // backgroundColor: '#FF5722',
            // backgroundColor: blue[70],
        },
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    expanded: {},
    content: {
        margin: '0',
        '&$expanded': {
            margin: '0',
        },
    },
}))(AccordionSummary);

export default function ExpandChartsPanel(props) {
    const classes = useStyles();
    const {
        charts,
        textSummary,
        forestPlots,
        // setExpandedCharts,
        variant,
    } = props;

    // const [expanded, setExpanded] = React.useState(false);
    // const [loading, setLoading] = React.useState(false);
    const [expanded, setExpanded] = useStateWithCallbackLazy(false);
    const [loading, setLoading] = useStateWithCallbackLazy(false);
    const [chartsState, setChartsState] = useStateWithCallbackLazy(charts);
    // const [state, setState] = React.useState({
    //     expanded: false,
    //     loading: false,
    // });

    useEffect(() => {
        // console.error('+++ useEffect() +++ charts:', charts);
        // console.error('+++ useEffect() +++ expanded:', expanded);
        const subscriptions = new Subscription();

        subscriptions.add(getClearCharts()
            .subscribe((clearCharts) => {
                // console.error('+++ subscriptions.add(getClearCharts() +++ clearCharts:', clearCharts);
                if (!!clearCharts) {
                    setLoading(false);
                    setChartsState(null);
                    updateStartLoadCharts(false);
                }
            }));

        subscriptions.add(getStartLoadCharts()
            .subscribe((startLoadCharts) => {
                // console.error('+++ subscriptions.add(getLoadCharts() +++ startLoadCharts:', startLoadCharts);
                if (!startLoadCharts) {
                    setLoading(false);
                    // updateStartLoadCharts(false);
                }
            }));

        subscriptions.add(getLoadCharts()
            .subscribe((charts) => {
                // console.error('+++ subscriptions.add(getLoadCharts() +++ charts:', charts);
                // console.error('+++ subscriptions.add(getLoadCharts() +++ expanded:', expanded);
                setLoading(false);
                const startLoadCharts = startLoadCharts$.getValue();
                // console.error('+++ subscriptions.add(getLoadCharts() +++ startLoadCharts:', startLoadCharts);

                if (!!startLoadCharts) {
                    setChartsState(charts);
                }
                updateStartLoadCharts(false);
            }));

        subscriptions.add(getExpanded()
            .subscribe((expanded) => {
                setExpanded(expanded);
                if (!expanded) {
                    setChartsState(null);
                }
            }));

        return () => {
            // console.error(moment().format('DD.MM.YYYY HH:mm:SS.SSS'), '+++ ECP +++ unmount() +++');
            subscriptions.unsubscribe();
            updateStartLoadCharts(false);
            // setLoadChartsMethod(null);
        }
    }, [
        // charts,
        // expanded
    ]);

    const getCharts = () => {
        return (chartsState || []).filter((chart) => !!chart);
    }

    const isCharts = () => {
        const _isCharts = getCharts().length > 0;
        // console.error('+++ isCharts() +++ chartsState:', chartsState);
        return _isCharts;
    }

    const handleChange = (expanded) => (event, isExpanded) => {
        // console.error(moment().format('DD.MM.YYYY HH:mm:SS.SSS'), '+++ handleChange() +++');
        // console.error('+++ handleChange() +++ chartsState:', chartsState);
        // console.error('+++ handleChange() +++ event:', event);
        // console.error('+++ handleChange() +++ isExpanded:', isExpanded);
        // console.error('+++ handleChange() +++ loading:', loading);
        // console.error('+++ handleChange() +++ expanded:', expanded);
        // console.error('+++ handleChange() +++ forestPlots:', forestPlots);
        // console.error('+++ handleChange() +++ isCharts():', isCharts());
        setExpanded(expanded);
        if (!loading) {
            if (isExpanded && !isCharts() && !forestPlots) {
                // if (!!loadCharts) {
                //     setLoading(true);
                // }

                // setExpandedCharts(isExpanded, () => {
                //     if (!!loadCharts) {
                //         loadCharts(() => {
                //             setLoading(false);
                //         });
                //     }
                // });
                // setExpandedCharts(isExpanded, () => {
                //     if (!!loadCharts) {
                        setLoading(true, () => {
                        // setLoading(true);
                            setTimeout(() => {
                                // console.error(moment().format('DD.MM.YYYY HH:mm:SS.SSS'), '+++ after setLoading() +++');
                                setTimeout(() => {
                                    updateStartLoadCharts(true);
                                });
/*
                                loadCharts(() => {
                                    setLoading(false);
                                })
                                    .then((charts) => {
                                        // console.error('+++ handleChange() +++ result from loadCharts() charts:', charts);
                                        setChartsState(charts, () => {
                                            // setExpandedCharts(isExpanded);
                                        });
                                    });
*/
                            });
                        });
                    // }
                // });
            }
        } else {
            // console.error(moment().format('DD.MM.YYYY HH:mm:SS.SSS'), '+++ after setLoading() +++ setExpandedCharts:', setExpandedCharts);
            // setExpandedCharts(expanded);
        }
        // setExpanded(expanded);
        // setExpandedCharts(expanded);
    };

/*
    if (expanded && !loading && !isCharts() && !forestPlots) {
        // TODO: comment for test
        // setExpanded(false);
        // setExpandedCharts(false);
    }
*/

/*
    if (expanded && needUpdate && isCharts()) {
        // setLoading(true);
        // loadCharts(() => {
        //     setLoading(false);
        // });
    }
*/

    // console.error('+++ render() +++ ECP +++ chartsState:', chartsState);
    // console.error('+++ render() +++ ECP +++ expanded:', expanded);
    // console.error('+++ render() +++ ECP +++ loading:', loading);
    // console.error('+++ ECP +++ loading:', loading);
    // console.error('+++ render() +++ ECP +++ getCharts():', getCharts());
    // console.error('+++ ECP +++ forestPlots:', forestPlots);
    // console.error('+++ ECP +++ (!loading && (getCharts().length === 0 || (!!forestPlots && forestPlots.length === 0))):', (!loading && (getCharts().length === 0 || (!!forestPlots && forestPlots.length === 0))));

    // console.error(moment().format('DD.MM.YYYY HH:mm:SS.SSS'), '+++ render() +++');

    return (
        // <Box className={classes.contentBoxTopSpace}>
        //     <ArticlesPanel>
        //         <ArticlesPanelSummary
        //             expandIcon={<ExpandMoreIcon/>}>
        //             <Typography variant={"h6"}>
        //                 <FormattedMessage id={'label.articles'} defaultMessage={'Articles'}/>
        //             </Typography>
        //         </ArticlesPanelSummary>
        //         <AccordionDetails>
        //             <Box>
        //                 <ArticlesListEx articleIds={nosologyData.articleIds}/>
        //             </Box>
        //         </AccordionDetails>
        //     </ArticlesPanel>
        // </Box>

    // <Box className={classes.contentBoxTopSpace}>
        <HelpTextPanel expanded={expanded} onChange={handleChange(!expanded)} variant={variant || 'elevation'} square={false} className={variant === 'outlined' ? classes.accordionBorder : ''}>
                <HelpTextPanelSummary expandIcon={<ExpandMoreIcon className={classes.headerText}/>}>
                    <ListItem disableGutters>
                        {/*<ListItemIcon>*/}
                        {/*    <ArrowForwardIcon className={classes.headerIcon}/>*/}
                        {/*</ListItemIcon>*/}
                        <ListItemText>
                            <Typography variant={"h6"} className={classes.headerText}>{textSummary}</Typography>
                        </ListItemText>
                    </ListItem>
                </HelpTextPanelSummary>
                <AccordionDetails style={{padding: '0'}}>
                    {/*<Box>*/}
                    {/*<PanelDivider />*/}
                    {/*{children}*/}
                    {/*</Box>*/}
                    {/*<Paper*/}
                    {/*    className={classes.chartPaper}*/}
                    {/*>*/}
                    {(expanded || loading) &&
                        <Box className={classes.chartPaper}>
                            {/*{children}*/}

                            {loading && <Box className={classes.loading}><CircularProgress/></Box>}
                            {(!loading && (getCharts().length === 0 && (!forestPlots || (!!forestPlots && forestPlots.length === 0)))) &&
                            <Typography align={'center'}>
                                <FormattedMessage id={'label.patient.no_data'} defaultMessage={'No data'}/>
                            </Typography>
                            }

                            {getCharts().map((chart, id) => (
                                <Box key={id}>
                                    <PanelDivider/>
                                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                                        <SelTextView align={'center'}>
                                            {chart.title}
                                        </SelTextView>
                                        <img src={chart.url} alt={chart.title} className={classes.imgAutoWidth}/>
                                    </Paper>
                                </Box>
                            ))}
                            {forestPlots}
                        </Box>
                    }
                    {/*</Paper>*/}
                </AccordionDetails>
            </HelpTextPanel>
    // </Box>
    );
}

// ExpandChartsPanel.propTypes = {
//     classes: PropTypes.object.isRequired,
//     textSummary: PropTypes.string.isRequired,
//     textSecondary: PropTypes.string,
// };

// export default compose(
//     withStyles(styles),
// )(ExpandChartsPanel);

