import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import {
    Button,
    Hidden,
    IconButton, Menu, MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import {withRouter} from 'react-router'
import {Link} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import mainStyles from '../styles/mainStyles';
import UserIcon from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LanguageIcon from "@material-ui/icons/Language";
import HowToRegIcon from '@material-ui/icons/HowToReg';
// import {withApollo} from "@apollo/react-hoc";
import {withApollo} from '@apollo/client/react/hoc';
import MenuOpen from "@material-ui/icons/MenuOpen";
import AppTableOfContents from "./AppTableOfContents";
import {isRegCode, removeCookies} from "../utils";
import {ProfileModeEnum, ProfileProfileModeEnum, ProfileTariffsTabEnum} from "../const";
import {withCookies} from "react-cookie";
import config from "../config";
import {parseSearchParams} from "url-search-utils";

const styles = theme => ({
    ...mainStyles(theme),
    menuToolbar: {
        padding: theme.spacing(1),
    },
    toolbarIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbarIconButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            textAlign: 'left',
            width: '100%',
        },
    },
    contextBarIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    contextBarIconButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            textAlign: 'left',
            width: '100%',
        },
    },
    toolBarIconColor: {
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.action.active
        },
    },
});

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

class AppToolbarDrawer extends React.Component {

    state = {
        regOpen: true,
        mobileOpenMoreIcon: false,
        contextContentShow: false,
    };


    handleDrawerOpen = () => {
        this.setState({mobileOpenMoreIcon: true});
    };

    handleDrawerClose = () => {
        this.setState({mobileOpenMoreIcon: false});
    };

    handleContextBarOpen = () => {
        this.setState({contextContentShow: true});
        this.context.setContextContentShow(true);
    };

    handleContextBarClose = () => {
        this.setState({contextContentShow: false});
        this.context.setContextContentShow(false);
    };


    onMenuLocaleShow = (event) => {
        this.setState({
            menuLocaleAnchorEl: event.currentTarget,
        });
    };

    onMenuLocaleClose = () => {
        this.setState({
            menuLocaleAnchorEl: null,
        });
    };

    onMenuLocaleClick = (selLocale) => {
        this.context.setLocale(selLocale);
        this.onMenuLocaleClose();
    };

    onMenuToolbarShow = (event) => {
        this.setState({
            menuToolbarAnchorEl: event.currentTarget,
        });
    };

    onMenuToolbarClose = (reset: boolean = false) => {
        if (reset) {
            this.context.resetRecursiveTreeViewSelected();
        }
        this.setState({
            menuToolbarAnchorEl: null,
        });
    };

    handleLogout = () => {
        const {history, client, cookies} = this.props;
        const {setContextContent, setContextStaff, updateUser} = this.context;
        fetch(config.options.server.auth_url + config.options.server.logout_uri, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        }).then((response) => {
            if (response.ok) {
                setContextContent(null);
                setContextStaff(null);
                this.onMenuToolbarClose();
                client.clearStore()
                    .then((result) => {
                        if (localStorage) {
                            localStorage.removeItem('x_auth_token');
                            localStorage.removeItem('access-token');
                            localStorage.removeItem('x-xsrf-token');
                        }

                        removeCookies(cookies, 'x_auth_token');
                        removeCookies(cookies, 'access-token');
                        removeCookies(cookies, 'x-xsrf-token');

                        history.replace('/');
                        updateUser();
                    });
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    isRegCode = (): boolean => {
        const { location } = this.props;
        return isRegCode(location);
    }

    render() {
        const {classes} = this.props;
        const {userProfile, localesAvailable, contextContent} = this.context;

        const {
            locale,
            contextContentShow,
            recursiveTreeViewSelected,
        } = this.context;
        const {
            menuLocaleAnchorEl,
            menuToolbarAnchorEl,
            // contextContentShow,
        } = this.state;

        let buttonItems = [];
        let toolbarIconClassName = classes.toolbarIconHide;
        let contextBarIconClassName = classes.contextBarIconHide;
        let toolbarIconButtonClassName = classes.toolbarIconButton;
        if (this.context.isAuth()) {
            if (userProfile && userProfile.user) {
                buttonItems.push(
                    <Button className={toolbarIconButtonClassName} key={"profile"} color="inherit" component={Link}
                            to={`/profile?mode=${ProfileModeEnum.PROFILE}&node=${ProfileProfileModeEnum.PROFILE_INFO}&activeTab=${ProfileTariffsTabEnum.TARIFFS}${recursiveTreeViewSelected[ProfileModeEnum.PROFILE] ? `&group=${recursiveTreeViewSelected[ProfileModeEnum.PROFILE]}` : ``}`}
                            onClick={() => this.onMenuToolbarClose(true)}>
                        <UserIcon className={classes.iconButton + ' ' + classes.toolBarIconColor}
                                  style={{verticalAlign: 'middle'}}/>
                        {userProfile.user.firstname ? userProfile.user.firstname : userProfile.user.username}
                    </Button>
                );
            }
            buttonItems.push(
                <Button className={toolbarIconButtonClassName} key={"logout"} color="inherit"
                        onClick={this.handleLogout}>
                    <ExitToAppIcon className={classes.iconButton + ' ' + classes.toolBarIconColor}
                                   style={{verticalAlign: 'middle'}}/>
                    <FormattedMessage id='label.exit' defaultMessage='Exit'/>
                </Button>
            );
        } else {
            buttonItems.push(
                <Button className={toolbarIconButtonClassName} key={"signin"} color="inherit" component={Link}
                        to="/signin" onClick={this.onMenuToolbarClose}>
                    <ExitToAppIcon className={classes.iconButton + ' ' + classes.toolBarIconColor}
                                   style={{verticalAlign: 'middle'}}/>
                    <FormattedMessage id='label.enter' defaultMessage='Enter'/>
                </Button>
            );
            if (!this.isRegCode()) {
                buttonItems.push(
                    <Button className={toolbarIconButtonClassName} key={"signup"} color="inherit" component={Link}
                            to="/signup?type=10" onClick={this.onMenuToolbarClose}>
                        <HowToRegIcon className={classes.iconButton + ' ' + classes.toolBarIconColor}
                                      style={{verticalAlign: 'middle'}}/>
                        <FormattedMessage id='label.registration' defaultMessage='Registration'/>
                    </Button>
                );
            }
        }

        buttonItems.push(
            <Button
                className={toolbarIconButtonClassName}
                key={"locale"}
                color="inherit"
                aria-controls="menu-locale"
                aria-haspopup="true"
                onClick={this.onMenuLocaleShow}>
                <LanguageIcon className={classes.iconButton + ' ' + classes.toolBarIconColor}
                              style={{verticalAlign: 'middle'}}/>
                {locale}
            </Button>,
        );

        const buttons = <div>
            {buttonItems.map(button => button)}
        </div>;

        const drawer = <div>
            {buttons}
        </div>;

        const menuToolbar =
            <Menu
                MenuListProps={{
                    style: {
                        width: 'max-content',
                    },
                    className: classes.menuToolbar,
                }}
                id='menu-toolbar'
                anchorEl={menuToolbarAnchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(menuToolbarAnchorEl)}
                onClose={this.onMenuToolbarClose}
            >
                {buttonItems.map(button => (button))}
            </Menu>
        ;
        const menuLocale =
            <Menu
                style={{zIndex: 9999}}
                id='menu-locale'
                anchorEl={menuLocaleAnchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(menuLocaleAnchorEl)}
                onClose={this.onMenuLocaleClose}>
                {localesAvailable.map(l => (
                    <MenuItem key={l.locale} onClick={e => this.onMenuLocaleClick(l.locale)}>{l.name}</MenuItem>
                ))}
            </Menu>
        ;

        return (
            <div style={{display: 'flex'}}>
                {contextContent && contextContentShow &&
                <Hidden mdUp implementation="js">
                    <SwipeableDrawer
                        anchor="right"
                        classes={{
                            paper: classNames(classes.paper, 'algolia-drawer'),
                        }}
                        disableBackdropTransition={!iOS}
                        disableDiscovery={iOS}
                        variant="temporary"
                        open={contextContentShow}
                        onOpen={this.handleContextBarOpen}
                        onClose={this.handleContextBarClose}
                        // onClick={this.handleContextBarClose}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <nav style={{paddingLeft: "16px"}}>
                            <AppTableOfContents/>
                        </nav>
                    </SwipeableDrawer>
                </Hidden>
                }
                <Hidden mdUp={true} implementation="js">
                    {menuToolbar}
                </Hidden>
                <Hidden smDown implementation="js">
                    {drawer}
                </Hidden>

                {contextContent &&
                <IconButton
                    edge={"end"}
                    color="inherit"
                    aria-label="Display context bar"
                    onClick={this.handleContextBarOpen}
                    className={contextBarIconClassName}>
                    <MenuOpen/>
                </IconButton>
                }
                <IconButton
                    edge={"end"}
                    color="inherit"
                    aria-label="Display toolbar actions"
                    onClick={this.onMenuToolbarShow}
                    className={toolbarIconClassName}>
                    <MoreIcon/>
                </IconButton>

                {menuLocale}

            </div>
        );
    }
}

AppToolbarDrawer.propTypes = {};

AppToolbarDrawer.contextTypes = {
    isAuth: PropTypes.func,
    userProfile: PropTypes.object,
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    localesAvailable: PropTypes.array.isRequired,
    contextContent: PropTypes.object,
    setContextContent: PropTypes.func,
    contextContentShow: PropTypes.bool,
    setContextContentShow: PropTypes.func,
    setContextStaff: PropTypes.func,
    updateUser: PropTypes.func,
    recursiveTreeViewSelected: PropTypes.object,
    resetRecursiveTreeViewSelected: PropTypes.func,
    // cookies: PropTypes.object,
};

export default compose(
    withRouter,
    withStyles(styles, {withTheme: true}),
    injectIntl,
    withApollo,
    withCookies,
)(AppToolbarDrawer);
