import PropTypes from 'prop-types';
import React from 'react';
import {
    Box,
    Grid,
} from '@material-ui/core';
import mainStyles from '../styles/mainStyles';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Typography from "@material-ui/core/Typography";
import Tariff from "./Tariff";
import {callCreatePurchase, loadGetActiveProducts} from "../query/billing";
import type {ActivePatient, Product} from "../const";
import {withApollo} from "@apollo/client/react/hoc";
import {getURLSearchParams, isAuth} from "../utils";
import {ProfileModeEnum, ProfileProfileModeEnum, ProfileTariffsTabEnum} from "../const";
import {FormattedMessage, injectIntl} from "react-intl";
import {withCookies} from "react-cookie";
import ConfirmProductPurchaseDialog from "./patient/ConfirmPurchaseDialog";

const styles = theme => ({
    ...mainStyles(theme),
    contentBox: {
        // paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    content: {
        width: '250px',
        maxWidth: '250px',
        marginRight: '20px',
        height: '100%',
    },
    nodata: {
        padding: '20px',
    },
    tariff: {
        display: 'flex',
    },
    tariffsHead: {
        fontWeight: '600',
        marginBottom: '30px'
    },
    tariffIcon: {
        width: 'auto',
        height: '40px',
        color: '#459220',
        fill: '#459220',
        fillColor: '#459220',
    },
    name: {
        fontWeight: '600',
        color: theme.palette.primary.main,
    },
    period: {
        fontWeight: '600',
    },
});

const Tariffs = (props) => {
    const {
        classes,
        showNotify,
        client,
        patientsGroups,
        intl,
        cookies,
    } = props;

    const {signal} = new AbortController();
    const [products, setProducts] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = React.useState();
    const [openConfirmProductPurchaseDialog, setOpenConfirmProductPurchaseDialog] = React.useState(false);

    React.useEffect(() => {
        loadGetActiveProducts(client, signal)
            .then((products) => {
                // FIXME: замоканные соглашения
                // products.map((product) => {
                //     product.agreements = [
                //         {
                //             content: 'Текст соглашения 1. Текст соглашения 1. Текст соглашения 1. Текст соглашения 1. Текст соглашения 1. Текст соглашения 1. Текст соглашения 1. Текст соглашения 1. Текст соглашения 1. Текст соглашения 1. ',
                //             accept: 'Название соглашения 1',
                //         },
                //         {
                //             content: 'Текст соглашения 2. Текст соглашения 2. Текст соглашения 2. Текст соглашения 2. Текст соглашения 2. ',
                //             accept: 'Название соглашения 2',
                //         },
                //         {
                //             content: 'Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. Текст соглашения 3. ',
                //         },
                //     ];
                // });
                setProducts(products.sort((product1, product2) => product1.ord - product2.ord));
            });
    }, []);

    const hasProductAgreements = (product: Product): boolean => {
        return !!product.agreements.length;
    }

    const proceedBuy = (product: Product) => {
        const hostUrl = window.location.origin;
        const search = window.location.href.split('?')[1];
        const params = new URLSearchParams(search);
        const activePatient: ActivePatient = getURLSearchParams(params);
        let returnUrl: string = '';
        if (!search || !(activePatient.mode && activePatient.node && activePatient.group)) {
            let group;
            if (patientsGroups) {
                group = patientsGroups[ProfileModeEnum.PROFILE].data.find((patientsGroupsData) => patientsGroupsData.mode === ProfileProfileModeEnum.PROFILE_TARIFFS);
            }
            returnUrl = `${hostUrl}/profile?mode=${ProfileModeEnum.PROFILE}&node=${ProfileProfileModeEnum.PROFILE_TARIFFS}&activeTab=${ProfileTariffsTabEnum.PURCHASES}${group ? `&group=${group.id}` : ``}`;
        } else {
            returnUrl = `${hostUrl}/profile?mode=${activePatient.mode}&node=${activePatient.node}&group=${activePatient.group}&activeTab=${ProfileTariffsTabEnum.PURCHASES}`;
        }
        callCreatePurchase(client, signal, product.id, returnUrl)
            .then((purchase) => {
                if (!!purchase?.payment?.confirmationUrl) {
                    window.location.replace(purchase.payment.confirmationUrl);
                } else {
                    if (!!showNotify) {
                        showNotify(intl.formatMessage({
                            id: 'message.service.billing.not_working',
                            defaultMessage: 'Purchase not working'
                        }), 'error');
                    }
                }
            });
    }

    const onBuy = (product: Product) => {
        if (isAuth(cookies)) {
            if (hasProductAgreements(product)) {
                setSelectedProduct(product);
                setOpenConfirmProductPurchaseDialog(true);
            } else {
                proceedBuy(product);
            }
        } else {
            window.location.replace('/signin');
        }
    }

    if (!products.length) {
        return <Box />;
    }

    return (
        <Box
            // className={classes.contentBox}
        >
            {/*<Typography variant={"h5"} className={classes.tariffsHead}>*/}
            {/*    Тарифы*/}
            {/*</Typography>*/}
            <Grid container direction={'row'} alignItems={"stretch"} justifyContent={"center"} spacing={4}>
                {!products.length &&
                    <Typography align={'center'} className={classes.nodata}>
                        <FormattedMessage id={'label.products.no_data'} defaultMessage={'No products'}/>
                    </Typography>
                }
                {!!products.length && products.map((product: Product) =>
                    <Grid key={product.id} item className={classes.tariff}>
                        <Tariff
                            product={product}
                            onBuy={() => onBuy(product)} />
                    </Grid>
                )}
            </Grid>
            {(selectedProduct && openConfirmProductPurchaseDialog) &&
                <ConfirmProductPurchaseDialog
                    product={selectedProduct}
                    open={openConfirmProductPurchaseDialog}
                    setOpen={setOpenConfirmProductPurchaseDialog}
                    onConfirm={proceedBuy}
                />
            }
        </Box>
    );
}

Tariffs.propTypes = {
    classes: PropTypes.object.isRequired,
    showNotify: PropTypes.func,
    patientsGroups: PropTypes.object,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
    withCookies,
)(Tariffs);
