import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import UserIcon from '@material-ui/icons/AccountCircle';
import AppDrawer from './AppDrawer';
import {withRouter} from 'react-router'
import {Link} from 'react-router-dom';
import {
    AppBar,
    Button,
    CssBaseline,
    IconButton,
    LinearProgress,
    Snackbar,
    Toolbar,
    Typography,
} from '@material-ui/core';
import compose from 'recompose/compose';
import {withApollo} from '@apollo/client/react/hoc';
import {FormattedMessage, injectIntl} from 'react-intl';
import mainStyles from '../styles/mainStyles';
import SnackbarNotify from '../comp/SnackbarNotify';
import AppToolbarDrawer from "./AppToolbarDrawer";
import {ProfileModeEnum, ProfileProfileModeEnum, ProfileTariffsTabEnum} from "../const";
import {isRegCode} from "../utils";

const styles = theme => ({
    ...mainStyles(theme),
    root: {
        display: 'flex',
    },
    title: {
        marginLeft: 24,
        // TODO: +++2021.05.13+++ flex: '0 1 auto',
    },
    appBar: {
        transition: theme.transitions.create('width'),
        '@media print': {
            position: 'absolute',
        },
    },
    appBarHome: {
        boxShadow: 'none',
    },
    appBarShift: {
        [theme.breakpoints.up('lg')]: {
            width: 'calc(100% - 240px)',
        },
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: 240,
        },
    },
    navIconHide: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    moreIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    snackbar: {
        margin: theme.spacing(1),
    },
});

const ColorLinearProgressIsLoading = withStyles({
    colorPrimary: {
        backgroundColor: '#FFCCBC',
    },
    barColorPrimary: {
        backgroundColor: '#FF5722',
    },
})(LinearProgress);

// const ColorLinearProgress = (props) => {
//   const status = props.useApolloNetworkStatus ? props.useApolloNetworkStatus() : undefined;
//
//   return (((status || {}).numPendingQueries > 0 || (status || {}).numPendingMutations > 0) && <LinearProgress classes={props.classes} />);
// };

// const ColorLinearProgressWithStyles = withStyles({
//   colorPrimary: {
//     backgroundColor: '#FFCCBC',
//   },
//   barColorPrimary: {
//     backgroundColor: '#FF5722',
//   },
// })(ColorLinearProgress);

class AppFrame extends React.Component {

    state = {
        mobileOpen: false,
    };

    currentLocation;

    componentDidMount() {
        const {setOpenManual} = this.context;
        this.props.history.listen((location) => {
            if (
                (this.currentLocation || {}).pathname !== location.pathname
                // &&
                // this.currentLocation.search !== location.search
            ) {
                setOpenManual(false, () => {
                    this.currentLocation = location;
                });
            }
        });
    }

    componentWillUnmount() {
    }

    handleDrawerOpen = () => {
        this.setState({mobileOpen: true});
    };

    handleDrawerClose = () => {
        this.setState({mobileOpen: false});
    };

    onSnackClose = () => {
        const {hideNotify} = this.context;
        if (hideNotify) {
            hideNotify();
        }
    };

    onMenuLocaleShow = (event) => {
        this.setState({
            menuLocaleAnchorEl: event.currentTarget,
        });
    };

    onMenuLocaleClose = () => {
        this.setState({
            menuLocaleAnchorEl: null,
        });
    };

    openProfile = () => {
        const {history} = this.props;
        history.replace('/profile');
    };

    onMenuLocaleClick = (selLocale) => {
        this.context.setLocale(selLocale);
        this.onMenuLocaleClose();
    };

    isRegCode = (): boolean => {
        const { location } = this.props;
        return isRegCode(location);
    }

    render() {
        const {children, classes, intl} = this.props;
        const {
            userProfile,
            notifyMessage,
            notifyOpen,
            notifyType,
            isLoading,
            locale,
            recursiveTreeViewSelected,
        } = this.context;

        const page = this.context.getActivePage();
        const title = page
            ? page.name
            : intl.formatMessage({id: 'app.name', defaultMessage: 'Medicbk'});

        let disablePermanent = false;
        let navIconClassName = '';
        // let moreIconClassName = '';
        let appBarClassName = classes.appBar;
        /*
        if (title === null) {
          disablePermanent = true;
          appBarClassName += ` ${classes.appBarHome}`;
        } else {
          navIconClassName = classes.navIconHide;
          appBarClassName += ` ${classes.appBarShift}`;
        }
        */
        let buttonItems = [];
        // moreIconClassName = classes.moreIconHide;
        if (this.context.isAuth() || this.context.isAnonymous()) {
            navIconClassName = classes.navIconHide;
            appBarClassName += ` ${classes.appBarShift}`;
            if (userProfile && userProfile.user) {
                buttonItems.push(
                    <Button
                        key={"profile"}
                        color="inherit"
                        component={Link}
                        to={`/profile?mode=${ProfileModeEnum.PROFILE}&node=${ProfileProfileModeEnum.PROFILE_INFO}&activeTab=${ProfileTariffsTabEnum.TARIFFS}${recursiveTreeViewSelected[ProfileModeEnum.PROFILE] ? `&group=${recursiveTreeViewSelected[ProfileModeEnum.PROFILE]}` : ``}`}
                    >
                        <UserIcon className={classes.iconButton}/>
                        {userProfile.user.firstname ? userProfile.user.firstname : userProfile.user.username}
                    </Button>
                );
            }
            buttonItems.push(<Button key={"logout"} color="inherit" onClick={this.handleLogout}>
                <FormattedMessage id='label.exit' defaultMessage='Exit'/>
            </Button>);
        } else {
            disablePermanent = true;
            appBarClassName += ` ${classes.appBarHome}`;
            buttonItems.push(<Button key={"signin"} color="inherit" component={Link} to="/signin">
                <FormattedMessage id='label.enter' defaultMessage='Enter'/>
            </Button>);
            if (!this.isRegCode()) {
                buttonItems.push(<Button key={"signup"} color="inherit" component={Link} to="/signup?type=10">
                    <FormattedMessage id='label.registration' defaultMessage='Registration'/>
                </Button>);
            }
        }

        buttonItems.push(<Button
            key={"locale"}
            color="inherit"
            aria-controls="menu-locale"
            aria-haspopup="true"
            onClick={this.onMenuLocaleShow}>
            {locale}
        </Button>);
        /*
        const buttons = <div>
          {buttonItems.map(button => button)}
        </div>;
        */
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar className={appBarClassName}>
                    <Toolbar>
                        {(this.context.isAuth() || this.context.isAnonymous()) &&
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={navIconClassName}>
                            <MenuIcon/>
                        </IconButton>}
                        {title !== null && (
                            // <Box className={classes.title}>
                                <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                                    {title}
                                </Typography>
                            // </Box>
                        )}

                        {/*TODO +++2021.05.13+++ */}
                        <div className={classes.grow}/>

                        {/*TODO*/}
                        {/*{buttons}*/}
                        {/*<IconButton*/}
                        {/*    color="inherit"*/}
                        {/*    aria-label="Display more actions"*/}
                        {/*    onClick={this.handleDrawerOpen}*/}
                        {/*    className={moreIconClassName}>*/}
                        {/*  <MoreIcon />*/}
                        {/*</IconButton>*/}
                        <AppToolbarDrawer/>
                    </Toolbar>
                    {/*TODO: +++comment for test+++ */}
                    {isLoading && <ColorLinearProgressIsLoading/>}
                    {/*{ <ColorLinearProgressWithStyles useApolloNetworkStatus={this.context.useApolloNetworkStatus} /> }*/}
                </AppBar>
                {(this.context.isAuth() || this.context.isAnonymous()) &&
                <AppDrawer
                    className={classes.drawer}
                    disablePermanent={disablePermanent}
                    onClose={this.handleDrawerClose}
                    onOpen={this.handleDrawerOpen}
                    mobileOpen={this.state.mobileOpen}
                />
                }

                {children}

                <Snackbar
                    open={notifyMessage != null && notifyMessage.length > 0 && notifyOpen}
                    className={classes.snackbar}
                    autoHideDuration={3000}
                    onClose={this.onSnackClose}>
                    <SnackbarNotify
                        message={notifyMessage}
                        variant={notifyType}
                    />
                </Snackbar>

                {/*<Menu*/}
                {/*  id='menu-locale'*/}
                {/*  anchorEl={menuLocaleAnchorEl}*/}
                {/*  anchorOrigin={{*/}
                {/*    vertical: 'top',*/}
                {/*    horizontal: 'right',*/}
                {/*  }}*/}
                {/*  keepMounted*/}
                {/*  transformOrigin={{*/}
                {/*    vertical: 'top',*/}
                {/*    horizontal: 'right',*/}
                {/*  }}*/}
                {/*  open={Boolean(menuLocaleAnchorEl)}*/}
                {/*  onClose={this.onMenuLocaleClose}>*/}
                {/*    {localesAvailable.map(l => (*/}
                {/*      <MenuItem key={l.locale} onClick={e => this.onMenuLocaleClick(l.locale)}>{l.name}</MenuItem>*/}
                {/*    ))}*/}
                {/*</Menu>*/}

            </div>
        );
    }
}

AppFrame.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

AppFrame.contextTypes = {
    isAuth: PropTypes.func,
    isAnonymous: PropTypes.func,
    userProfile: PropTypes.object,
    getActivePage: PropTypes.func,
    notifyMessage: PropTypes.string,
    notifyOpen: PropTypes.bool,
    notifyType: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
    hideNotify: PropTypes.func,
    isLoading: PropTypes.bool,
    useApolloNetworkStatus: PropTypes.func,
    locale: PropTypes.string.isRequired,
    setLocale: PropTypes.func.isRequired,
    setOpenManual: PropTypes.func.isRequired,
    localesAvailable: PropTypes.array.isRequired,
    recursiveTreeViewSelected: PropTypes.object,
};

export default compose(
    withStyles(styles),
    withApollo,
    withRouter,
    injectIntl,
)(AppFrame);
