import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import Link from "@material-ui/core/Link";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import RefreshIcon from "@material-ui/icons/Refresh";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    wrapper: {
        // alignItems: 'center',
        display: 'flex',
    },
    typography: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        maxWidth: '380px',
        maxHeight: '280px',
        overflow: 'auto',
        boxShadow: '3px 3px 5px rgb(0 0 0 / 0.5)',
        backgroundColor: '#f3faff',
    },
    infoText: {
        '& p': {
            margin: 0,
        }
    },
    helpIcon: {
        cursor: 'pointer',
        // marginTop: '10px'
    },
    buttons: {
        marginTop: '10px'
    },
    nosology: {
        paddingLeft: 0,
        paddingRight: 0,
        alignItems: 'center',
        '@media (max-width:499.95px)': {
            paddingTop: 0,
            // alignItems: 'flex-start',
        },
    },
    nosologyText: {
        overflowWrap: 'anywhere',
        '& > *' : {
            fontSize: '14px !important',
        },
        '@media (max-width:499.95px)': {
            marginTop: 0,
        },
    },
}));

export default function NosologyNameRenderer(props) {
    const [open, setOpen] = React.useState(false);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const anchorRef = React.useRef(null);
    const classes = useStyles();
    const {
        intl,
        nosologyName,
        onOpenNosology,
        onDeleteNosology,
        className,
        nosologyIcon,
        nosology,
    } = props;

    const handleToggle = (event) => {
        if (Boolean(nosology.history)) {
            setOpen((prevOpen) => !prevOpen);
        } else {
            onOpenNosology();
        }
        // setAnchorEl(event.currentTarget);
    };

    const deleteNosology = (event) => {
        setOpen(false);
        onDeleteNosology();
    };

    const openNosology = (event) => {
        setOpen(false);
        onOpenNosology();
    };

    const handleClickAway = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Box
            // component={'span'}
            className={classes.wrapper}
        >
            <Link
                component="button"
                align={"left"}
                variant="body2"
                className={className}
                onClick={handleToggle}
                ref={anchorRef}
                aria-controls={open ? 'split-button-menu' : undefined}
            >
                <ListItem
                    // alignItems="flex-start"
                    className={classes.nosology}>
                    <ListItemIcon style={{minWidth: '32px', verticalAlign: 'middle'}}>
                        <SvgIcon color="primary">
                            {nosologyIcon}
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                        className={classes.nosologyText}
                        // className={`${classes.linkHead} ${classes.contentBoxProfileText}`}
                        primary={nosologyName}
                    />
                    {/*{nosologyName}*/}
                </ListItem>
            </Link>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                // container={'body'}
                style={{zIndex: '9999'}}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <MenuList id="split-button-menu">
                                    <MenuItem
                                        onClick={openNosology}
                                    >
                                        <ListItemIcon style={{minWidth: '32px'}}>
                                            <SvgIcon color="primary">
                                                {nosologyIcon}
                                            </SvgIcon>
                                        </ListItemIcon>
                                        <ListItemText
                                            // className={`${classes.linkHead} ${classes.contentBoxProfileText}`}
                                            primary={intl.formatMessage({
                                                id: 'label.patient.nosology.repeat_visit',
                                                defaultMessage: 'Repeat visit'
                                            })}
                                        />
                                    </MenuItem>
                                    {(nosology && nosology.history) &&
                                        <MenuItem
                                            onClick={deleteNosology}
                                        >
                                            <ListItemIcon style={{minWidth: '32px'}}>
                                                <RefreshIcon color="primary"/>
                                            </ListItemIcon>
                                            <ListItemText
                                                // className={`${classes.linkHead} ${classes.contentBoxProfileText}`}
                                                primary={intl.formatMessage({
                                                    id: 'label.reset',
                                                    defaultMessage: 'Reset'
                                                })}
                                            />
                                        </MenuItem>
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}
