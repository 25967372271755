import React from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import validator from 'validator';
import {ProfileType} from '../../const';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Autocomplete} from "@material-ui/lab";

class InviteAddDialog extends React.Component {

    constructor(props) {
        super(props);
        const {intl} = props;

        this.state = {
            fields: new Map([
                ['email', ''],
                ['notifyByEmail', true],
                // ['role', ''],
                ['regProfileId', null],
            ]),
            errors: new Map(),
        };

        // let $this = this;
        this.validate = {
            'email': (value) => {
                let errors = this.state.errors;


                if (value.length < 5 || !validator.isEmail(value)) {
                    errors.set('email', intl.formatMessage({
                        id: 'err.form.email.invalid',
                        defaultMessage: 'Invalid email'
                    }));
                } else {
                    errors.delete('email');
                }

                return errors;
            },
            'notifyByEmail': (value) => {
                let errors = this.state.errors;

                errors.delete('notifyByEmail');

                return errors;
            },
            'regProfileId': (value) => {
                let errors = this.state.errors;

                if (!value) {
                    errors.set('regProfileId', intl.formatMessage({
                        id: 'err.form.regProfileId.invalid',
                        defaultMessage: 'Invalid reg Profile'
                    }));
                } else {
                    errors.delete('regProfileId');
                }

                return errors;
            },
            // 'role': (value) => {
            //   let errors = $this.state.errors;

            //   if (isNumber(value)) {
            //     errors.delete('role');
            //   } else {
            //     errors.set('role', intl.formatMessage({id: 'err.role.not_set', defaultMessage: 'Role not set'}));
            //   }

            //   return errors;
            // },
        };
    }

    onValueChange = (event) => {
        let target = event.target;

        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            fields: this.state.fields.set(target.name, value),
            errors: this.validate[target.name](value),
        });
    };

    onFormSubmit = (event) => {
        const {fields, errors} = this.state;
        const {onSubmit} = this.props;

        for (let [name, value] of fields) {
            this.validate[name](value);
        }

        if (errors.size === 0) {
            onSubmit({
                email: fields.get('email'),
                role: ProfileType.EXPERT, // fields.get('role'),
                notifyByEmail: fields.get('notifyByEmail'),
                regProfileId: fields.get('regProfileId').id,
            });

            this.clearState();
        } else {
            this.forceUpdate();
        }

        event.preventDefault();
    };

    onClose = () => {
        const {onDialogClose} = this.props;
        this.clearState();
        onDialogClose();
    };

    clearState = () => {
        this.setState({
            fields: new Map([
                ['email', ''],
                ['notifyByEmail', true],
                ['regProfileId', null],
                // ['role', ''],
            ]),
            errors: new Map(),
        });
    };

    render() {
        const {isShow, intl, regProfiles} = this.props;
        const {fields, errors} = this.state;

        return (
            <Dialog
                fullWidth
                // onEscapeKeyDown={this.onClose}
                // onBackdropClick={this.onClose}
                onClose={this.onClose}
                open={isShow}
                aria-labelledby="param-dialog-title">
                <DialogTitle id="param-dialog-title">
                    <FormattedMessage id='label.invite.new_user' defaultMessage='Invite new user'/>
                </DialogTitle>
                <form onSubmit={this.onFormSubmit}>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            fullWidth
                            margin="dense"
                            id="email"
                            name="email"
                            label={intl.formatMessage({id: 'label.email', defaultMessage: 'Email'})}
                            value={fields.get('email')}
                            onChange={this.onValueChange}
                            error={errors.get('email') != null}
                            helperText={errors.get('email')}
                        />
                        <FormControlLabel
                            control={<Checkbox
                                checked={fields.get('notifyByEmail')}
                                onChange={this.onValueChange}
                                name={'notifyByEmail'}
                                color="primary"
                            />}
                            label={intl.formatMessage({
                                id: 'label.send_notification',
                                defaultMessage: 'Send notification'
                            })}
                        />
                        <Autocomplete
                            // className={classes.formItem}
                            options={regProfiles}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label={intl.formatMessage({id: 'label.invite.regProfileId', defaultMessage: 'regProfileId'})}
                                           variant="standard"
                                           error={errors.get('regProfileId') != null}
                                           helperText={errors.get('regProfileId')}
                                />}
                            onChange={(event, value) => this.onValueChange({target: {name: 'regProfileId', value: value}})}
                            value={fields.get('regProfileId')}
                        />
                        {!!fields.get('regProfileId') &&
                            <Typography variant={"body2"}>
                                {fields.get('regProfileId').descr}
                            </Typography>
                        }
                        {/* <TextField
              id="role"
              name="role"
              value={fields.get('role')}
              onChange={this.onValueChange}
              margin="normal"
              required
              fullWidth
              select
              label="Статус"
              error={this.state.errors.get('role') != null}
              helperText={this.state.errors.get('role')}>
              {ProfileTypeNames.map(([roleId, roleName]) => (
                <MenuItem key={roleId} value={roleId}>
                  {roleName}
                </MenuItem>
              ))}
            </TextField> */}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.onClose}>
                            <FormattedMessage id='label.cancel' defaultMessage='Cancel'/>
                        </Button>
                        <Button color="primary" type="submit">
                            <FormattedMessage id='label.invite' defaultMessage='Invite'/>
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

InviteAddDialog.propTypes = {
    intl: PropTypes.object.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isShow: PropTypes.bool.isRequired,
    regProfiles: PropTypes.array.isRequired,
};

export default injectIntl(InviteAddDialog);
