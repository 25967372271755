import React from "react";
import PropTypes from 'prop-types';
import {Box, Checkbox, FormControl, FormControlLabel, Grid, Paper, Typography,} from "@material-ui/core";
import SelTextView from "../SelTextView";
import PanelDivider from "../PanelDivider";
import EffSafChart from "./EffSafChart";
import {getTherapyCombinations} from '../../query/HPBaseline';
import {FormattedMessage, injectIntl} from "react-intl";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import {getHPCombCharts, getHPCombPscore} from "../../query/HPBaseline";
import {getQueryResult} from "../../utils";
import HPCombinationView from "./HPCombinationView";
import {HelpDrugsTextsView} from "../common/HelpDrugsTextsView";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";
import config from "../../config";

const styles = theme => ({
    ...mainStyles(theme),
});

class HPSelectComb extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.forestPlotView();
        this.loadCombinations();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadCombinations = () => {
        const {
            client,
            activePatient,
            setTherapyComb,
            getTherapyComb,
            getSelectTherapyType,
            getSubgroupSelect,
            getTouched,
            getParams
        } = this.props;
        const {signal} = this.abortController;
        const selThType = getSelectTherapyType();
        const sgSel = getSubgroupSelect();
        const touched = getTouched();

        if (touched) {
            if (Boolean(selThType)) {
                client.query({
                    query: getTherapyCombinations,
                    variables: {
                        patientId: Number(activePatient.patient) || 0,
                        thTypeId: selThType.id,
                        inSubgroupId: sgSel ? sgSel.id : 0,
                    },
                    context: {
                        uri: config.options.server.hp_url + config.options.server.api_uri,
                        fetchOptions: {
                            signal,
                        },
                    },
                })
                    // .then(result => (result.data || {}).getTherapyCombinations)
                    // .then(result => JSON.parse(JSON.stringify(result)))
                    .then(result => getQueryResult(result?.data, 'getTherapyCombinations'))
                    .then(comb => {
                        this.setState({
                            params: (comb || {}).params,
                        }, () => {
                            setTherapyComb(comb.combinations, () => {
                                // if (Boolean(comb) && comb.combinations.length > 0) {
                                //   this.loadCharts();
                                //   this.loadPscore();
                                // } // 2020.06.04
                                this.loadAll(comb);
                            });
                        });
                    });
            }
        } else {
            const comb = {
                combinations: getTherapyComb().items
            }
            this.setState({
                params: getParams(),
            }, () => {
                this.loadAll(comb);
            });
        }
    };

    loadAll = (comb) => {
        if (Boolean(comb) && comb.combinations.length > 0) {
            // this.loadCharts();
            this.loadPscore();
        }
    }

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    loadCharts = () => (callback) => {
        const {client, activePatient, getTherapyComb} = this.props;
        const {signal} = this.abortController;
        const thComb = getTherapyComb();

        if (Boolean(thComb) && thComb.items.length > 0) {
            return client.query({
                query: getHPCombCharts,
                variables: {
                    patientId: Number(activePatient.patient) || 0,
                    combIds: thComb.items.map(c => c.id)
                },
                context: {
                    uri: config.options.server.hp_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                .then(result => getQueryResult(result?.data, 'getHPCombCharts'))
                .then(charts => {
                    this.setState({
                        charts: charts,
                    }, () => {
                        // TODO: +++2021.12.03+++
                        //  this.forestPlotView();
                        if (!!callback) {
                            callback();
                        }
                    });
                    return new Promise((resolve, reject) => {
                        resolve(charts);
                    });
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
    };

    loadPscore = () => {
        const {client, activePatient, getTherapyComb} = this.props;
        const {signal} = this.abortController;
        const thComb = getTherapyComb();

        client.query({
            query: getHPCombPscore,
            variables: {
                patientId: Number(activePatient.patient) || 0,
                combIds: thComb.items.map(c => c.id)
            },
            context: {
                uri: config.options.server.hp_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            // .then(result => (result.data || {}).getHPCombPscore)
            // .then(result => JSON.parse(JSON.stringify(result)))
            .then(result => getQueryResult(result?.data, 'getHPCombPscore'))
            .then(pscore => {
                this.setState({
                    pscore: (pscore || []).map(ps => ({
                        x: ps.efficacy,
                        y: ps.safety,
                        name: ps.name,
                    }))
                });
            });
    };

    onTherapyCombChange = (thCombId) => (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        if (e.target.checked) {
            const {setSelectTherapyComb} = this.props;
            setSelectTherapyComb(thCombId);
        }
    };

    combView = () => {
        const {
            classes,
            getTherapyComb,
            isPrescriptionReadonly,
        } = this.props;
        const thComb = getTherapyComb();

        if (thComb === undefined) {
            return (<Box/>);
        }

        const priorComb = [];
        const notPriorComb = [];
        // const gNames = thComb.items.map(it => getTherapyCombNamesWithItem(it));

        thComb.items.forEach((it, id) => {
            if (it.isPriority) {
                priorComb.push(<FormControlLabel key={id}
                                                 label={
                                                     // <Typography color={it.isAvailable === undefined || it.isAvailable ? 'textPrimary' : 'error'}>
                                                     //   {gNames[id]}
                                                     // </Typography>
                                                     <HPCombinationView combination={it}/>
                                                 }
                                                 onChange={this.onTherapyCombChange(id)}
                                                 control={<Checkbox checked={id === thComb.selCombId} value={id}
                                                                    color={"primary"}/>}
                />);
            } else {
                notPriorComb.push(<FormControlLabel key={id}
                                                    label={
                                                        // <Typography color={it.isAvailable === undefined || it.isAvailable ? 'textPrimary' : 'error'}>
                                                        //   {gNames[id]}
                                                        // </Typography>
                                                        <HPCombinationView combination={it}/>
                                                    }
                                                    onChange={this.onTherapyCombChange(id)}
                                                    control={<Checkbox checked={id === thComb.selCombId} value={id}
                                                                       color={"primary"}/>}
                />);
            }
        });

        return (
            <Grid container spacing={2} className={isPrescriptionReadonly ? classes.disabled : ''}>

                <Grid item
                    // xs={GRIG_TWO_COL.XS}
                    // sm={GRIG_TWO_COL.SM}
                    // md={GRIG_TWO_COL.MD}
                      xl={6} lg={6} md={6} sm={12} xs={12}
                >
                    <Paper className={classes.contentBox}
                           variant={"outlined"}>
                        <SelTextView>
                            <FormattedMessage id='label.hp.therapy.recommend' defaultMessage='Recommended Therapy'/>
                        </SelTextView>
                        <PanelDivider/>
                        {priorComb.length > 0
                            ?
                            <FormControl component='fieldset'>
                                {priorComb}
                            </FormControl>
                            :
                            <Typography>
                                <FormattedMessage id='label.bl.therapy_not_recoment'
                                                  defaultMessage='Therapy is not required'/>
                            </Typography>
                        }
                    </Paper>
                </Grid>

                <Grid item
                    // xs={GRIG_TWO_COL.XS}
                    // sm={GRIG_TWO_COL.SM}
                    // md={GRIG_TWO_COL.MD}
                      xl={6} lg={6} md={6} sm={12} xs={12}
                >
                    <Paper className={classes.contentBox}
                           variant={"outlined"}>
                        <SelTextView>
                            <FormattedMessage id='label.hp.therapy.alternative' defaultMessage='Alternative therapy'/>
                        </SelTextView>
                        <PanelDivider/>
                        {notPriorComb.length > 0
                            ?
                            <FormControl component='fieldset'>
                                {notPriorComb}
                            </FormControl>
                            :
                            <Typography>
                                <FormattedMessage id='label.bl.therapy_not_recoment'
                                                  defaultMessage='Therapy is not required'/>
                            </Typography>
                        }
                    </Paper>
                </Grid>

                {/*{getHelpDrugsTextsView &&*/}
                <Grid item
                      xl={12} lg={12} md={12} sm={12} xs={12}
                >
                    <Paper className={classes.contentBox}
                           variant={"outlined"}>
                        {/*{getHelpDrugsTextsView()}*/}
                        <HelpDrugsTextsView/>
                    </Paper>
                </Grid>
                {/*}*/}

            </Grid>
        );
    };

    forestPlotView = () => {
        updateShowPanel(true);
        setLoadChartsMethod(this.loadCharts());
/*
        const forestPlotView = (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                charts={charts}
                loadCharts={this.loadCharts()}
                needUpdate={new Date().getTime()}
                setExpandedCharts={this.setExpandedCharts}
            >
            </ExpandChartsPanel>
        );

        // return forestPlotView;
        setForestPlotView(forestPlotView);
*/
    };

    effSafView = () => {
        const {classes, intl} = this.props;
        const {pscore} = this.state;

        if (Boolean(pscore) && pscore.length > 0) {
            return (
                <Box>
                    <PanelDivider/>
                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                        <EffSafChart
                            points={pscore}
                            title={intl.formatMessage({id: "label.effsaf", defaultMessage: "Efficacy/Safety"})}
                            xaxis={intl.formatMessage({id: "label.efficacy", defaultMessage: "Efficacy"})}
                            yaxis={intl.formatMessage({id: "label.safety", defaultMessage: "Safety"})}
                        />
                    </Paper>
                </Box>
            );
        } else {
            return (
                <Box>
                    <PanelDivider/>
                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                        <Typography>
                            <FormattedMessage id="message.data.empty" defaultMessage="Data not available"/>
                        </Typography>
                    </Paper>
                </Box>
            );
        }
    };

    statisticsView = () => {
        const {classes} = this.props;
        const {params} = this.state;

        if (Boolean(params)) {
            return (
                <Box>
                    <PanelDivider/>
                    <Paper className={classes.contentBox} variant={"outlined"}>
                        {params.map((param, num) => (
                            <Typography key={num}>{`${param.key}: ${param.value}`}</Typography>
                        ))}
                    </Paper>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    render() {
        const {classes, getRecomentTextsView} = this.props;

        return (
            <Box>
                {getRecomentTextsView && getRecomentTextsView()}
                <Box className={classes.contentBox}>
                    {this.combView()}
                    {this.statisticsView()}
                    {this.effSafView()}
                    {/*{this.forestPlotView()}*/}
                </Box>
            </Box>
        );
    }
}

HPSelectComb.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    getTouched: PropTypes.func.isRequired,
    getParams: PropTypes.func.isRequired,
    getRecomentTextsView: PropTypes.func,
    getSelectVars: PropTypes.func.isRequired,
    getTherapyComb: PropTypes.func.isRequired,
    setTherapyComb: PropTypes.func.isRequired,
    setSelectTherapyComb: PropTypes.func.isRequired,
    getSelectTherapyType: PropTypes.func.isRequired,
    getTherapyCombNamesWithItem: PropTypes.func.isRequired,
    getSubgroupSelect: PropTypes.func.isRequired,
    setForestPlotView: PropTypes.func.isRequired,
    getHelpDrugsTextsView: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(HPSelectComb);
