import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import {withApollo} from "@apollo/client/react/hoc";
import {injectIntl} from "react-intl";
import {Box} from "@material-ui/core";

const styles = theme => ({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    ...mainStyles(theme),
    contentBox: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
});

class Glossary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // const $this = this;
        const {locale} = this.context;

        fetch(`/md/glossary_${locale}.md`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.text())
            .then((content) => {
                this.setState({
                    content: content,
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    render() {
        const {classes} = this.props;
        const {content} = this.state;

        return (
            // <RootContainer>
                // <ContentPanel>
                    <Box className={classes.contentBox}>
                        {/*<Typography variant={"h6"} color={"inherit"}>*/}
                        {/*    <FormattedMessage id='page.glossary' defaultMessage='Glossary' />*/}
                        {/*</Typography>*/}
                        <Typography variant="body1" component="span">
                            {content &&
                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                           children={content}/>}
                        </Typography>
                    </Box>
                // </ContentPanel>
            // </RootContainer>
        );
    }
}

Glossary.propTypes = {
    classes: PropTypes.object.isRequired,
};

Glossary.contextTypes = {
    isAuth: PropTypes.func,
    locale: PropTypes.string.isRequired,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(Glossary);
