import config from './config';
import moment from "moment";
import {
    ChartMarkerColors,
    GroupedBarChartColors,
    NosologyStatus,
    NosologyType,
    PatientType,
    RoleType,
    BarChartOrientation,
    PatientTypeToGroupType,
    ParameterType,
    MessageType,
    ProfileModeEnum,
    PurchaseStatus,
    ProfileTariffsTabEnum,
    ProfileProfileModeEnum,
    UserPermissions,
    ParameterDto, DrugAvailStatus, DrugCompStatus, BarChartResponse, StrictIndicators, BaselineDetect,
} from "./const";
import type {
    GroupDto,
    ActivePatient, BaselineParameterDto,
    DrugsByDrugDosageNamesResult as DrugsByDrugDosageNamesResultModel, GroupedBarChartConfig,
    GroupedBarChartInitParams, Message,
    PatientNosologyState, ParameterOptDto,
    ParamsDrugs,
    PatientAccess,
    PatientDto, PatientExtDto, PatientHistoryDto, PolarChartConfig, ServiceMessage,
    StaffDto,
    StaffRole,
    TherapyDataIn, ValueNumberType, BaselineParametrSectionDto, BaselineParameterStrictDto, ParametersForBaselineDto,
} from "./const";
import {loadGetListHiLevelGroup, loadGetPatient, loadGetStaticBaseline} from "./query/patient";
import {
    Box,
    Grid, Link,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography
} from "@material-ui/core";
import React from "react";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";
import SvgIcon from "@material-ui/core/SvgIcon";
import {DyslipidemiaIcon, FibrillationIcon, HeartFailureIcon, IbsIcon} from "./icons";
import HyperIcon from "@material-ui/icons/TrendingDown";
import {INSTRUCTIONS} from "./instructions";
import PanelDivider from "./comp/PanelDivider";
import SelTextView from "./comp/SelTextView";
import List from "@material-ui/core/List";
import CheckIcon from "@material-ui/icons/Check";
import {InfoHelp} from "./comp/common/InfoHelp";
import grey from "@material-ui/core/colors/grey";
import PatientDrugsView from "./comp/baseline/PatientDrugsView";
import {FormattedMessage} from "react-intl";
import ListEmpty from "./comp/ListEmpty";
import userPermissionsStore from "./service/user-permissons";
import {parseSearchParams} from "url-search-utils";
import { v4 as uuidv4 } from 'uuid';
import ru from "hyphenated-ru";
import enUS from 'hyphenated-en-us';
import { enUS as enUSDateLocale, ru as ruDateLocale } from 'date-fns/locale';

export const getCookies = (cookies, name: string) => {
    return cookies.get(name);
};

export const setCookies = (cookies, name: string, value) => {
    cookies.set(
        name,
        value,
        {path: '/'}
    );
};

export const removeCookies = (cookies, name: string) => {
    if (name) {
        cookies.remove(
            name
            // {path: '/'}
        );
    }
};

export const isAuth = (cookies) => {
    return Boolean(getAuthToken(cookies));
};

export const getAuthToken = (cookies) => {
    // let authToken = this.cookies.get('access-token');
    let authToken = getCookies(cookies, 'access-token');
    // let authToken = this.getCookies('x_auth_token');

    if (!Boolean(authToken) && localStorage) {
        authToken = localStorage.getItem('x_auth_token');
    }

    return authToken;
};

export function intersect(arr1, arr2) {
    const setB = new Set(arr2);
    return [...new Set(arr1)].filter(x => setB.has(x));
}

export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function buildAbstractAsText(abstract) {
    let result = "";
    for (let i in abstract) {
        result += abstract[i].category + '\n' + abstract[i].text + '\n';
    }
    return result;
}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function getQuestionText(question, locale) {
    if (question.translate) {
        const lang = question.translate.filter(t => t.lang === locale).map(t => t.text);
        if (lang.length > 0) {
            return lang[0];
        } else {
            return question.text;
        }
    } else {
        return question.text;
    }
}

export function articlePubTypesToStr(pubTypes) {
    if (!Boolean(pubTypes) || pubTypes.length === 0) {
        return '';
    }

    return pubTypes.map(it => it.name).join(', ');
}

export function dateToStr(date, locale) {
    if (Boolean(date) && isNumber(date)) {
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return new Date(date).toLocaleString(locale, options);
    } else {
        return '';
    }
}

export function round(x, p = 2) {
    return Math.round(x * Math.pow(10, p)) / Math.pow(10, p);
}

export function toFixed(num: number | string, fixed = 2): string {
    num = Number(num);
    if (!isNaN(num)) {
        return num.toFixed(fixed);
    } else {
        return '';
    }
}

export function capitalizeFirstLetter(string) {
    return (!!string && (string.charAt(0).toUpperCase() + string.slice(1))) || '';
}

export function isSameRemoteServer(uri = null) {
    /*
    let apiUrl;

    if (!uri) {
        apiUrl = new URL(config.options.server.api_url);
    } else {
        apiUrl = new URL(uri);
    }

    return window.location.hostname === apiUrl.hostname;
        // && window.location.port === apiUrl.port;
        */
    return false;
}

export function isSameAuthServer() {
    /*
    let authUrl = new URL(config.options.server.auth_url);

    return window.location.hostname === authUrl.hostname;
        // && window.location.port === authUrl.port;
        */
    return false;
}

export const returnToPatient = (activePatient, history) => {
    history.replace(`/patients?patientType=${activePatient.patientType}&patient=${activePatient.patient}&patientUuid=${activePatient.patientUuid}&activeTab=nosology${activePatient.group ? `&group=${activePatient.group}` : ``}`);
}

export const datatableDateRenderer = (value, tableMeta, updateValue) => {
    return !!value ? moment(value).format('DD.MM.YYYY HH:mm') : '';
}

export const getNextDateToStringFormat = (value) => {
    return value ? moment(value).endOf('day').format('YYYY-MM-DD') : '';
}

export const getStringDateFromString = (value) => {
    return value ? moment(value).format('YYYY-MM-DD') : '';
}

export const getLocalStringDateFromString = (value) => {
    return !!value ? moment.utc(value).local().format('YYYY-MM-DD HH:mm') : value;
}

export const isPastDate = (value) => {
    const now = moment();
    const date = moment(value ? value : undefined);
    return date.isBefore(now);
}

export const datatableStaffRenderer = (value: StaffDto, tableMeta, updateValue) => {
    const values = [
        value.lastName || '',
        value.firstName || '',
        value.middleName || '',
    ].filter(value => value);
    return values.join(' ');
}

export const arrayDateSort = (data, colIndex, order, fieldName) => {
    const iOrder = order === 'desc' ? -1 : 1;
    const field = [colIndex === 2 ? fieldName : ''];
    return data.sort((a, b) => {
        const a_ = (a.data[colIndex] || [])[field];
        const b_ = (b.data[colIndex] || [])[field];
        if (!a_ || !b_) {
            return (!a_ ? -1 : 1) * iOrder;
        }
        // return (a_ < b_ ? -1 : 1) * iOrder;
        return (moment(a_) < moment(b_) ? -1 : 1) * iOrder;
    });
}

export const dateCompare = (dateA, dateB, order) => {
    const iOrder = order === 'desc' ? -1 : 1;
    if (!dateA || !dateB) {
        return (!dateA ? -1 : 1) * iOrder;
    }
    return (moment(dateA).isBefore(moment(dateB)) ? -1 : 1) * iOrder;
}

export const stringCompare = (strA, strB, order) => {
    const iOrder = order === 'desc' ? -1 : 1;
    if (!strA || !strB) {
        return (!strA ? -1 : 1) * iOrder;
    }
    return (strA < strB ? -1 : 1) * iOrder;
}

export const sortString = (order, getValueFunc) => (obj1, obj2) => {
    const a_ = getValueFunc(obj1.data, obj1);
    const b_ = getValueFunc(obj2.data, obj2);
    return stringCompare(a_, b_, order);
}

export const sortDate = (order, field) => (obj1, obj2) => {
    return dateCompare(field ? (obj1.data || {})[field] : obj1.data, field ? (obj2.data || {})[field] : obj2.data, order);
}

export const sortDateStringEpoch = (order, field) => (obj1, obj2) => {
    const date1 = field ? (obj1.data || {})[field] : obj1.data;
    const date2 = field ? (obj2.data || {})[field] : obj2.data;
    return dateCompare(!!date1 ? Number(date1) * 1000 : date1, !!date2 ? Number(date2) * 1000 : date2, order);
}

export const isPatientExist = (patientsData, name) => {
    return (patientsData || []).some(patient => patient.name === name);
}

export const getURLSearchParams = (search): ActivePatient => {
    const params = new URLSearchParams(search);
    const patient = params.get('patient');
    const patientUuid = params.get('patientUuid');
    const group = params.get('group');
    const patientType = params.get('patientType');
    const patientName = params.get('patientName');
    const nosology = params.get('nosology');
    const history = params.get('history');
    const action = params.get('action');
    const getData = params.get('getData');
    const activeTab = params.get('activeTab');
    const mode = params.get('mode');
    const node = params.get('node');
    const activePatient: ActivePatient = {
        patient: patient,
        patientUuid: patientUuid,
        group: group,
        patientType: patientType,
        patientName: patientName,
        nosology: nosology,
        history: history,
        action: action,
        getData: getData,
        activeTab: activeTab,
        mode: mode,
        node: node,
    };

    return activePatient;
}

export const getUniqueKey = () => {

}

export const getAccess = (role: StaffRole, patientType: PatientType, staff: StaffDto = null, patient: PatientDto = null): PatientAccess => {
    const owner = staff && patient ? (staff || {}).id === ((patient || {}).staff || {}).id : true;
    return {
        owner: owner,
        patient: {
            create: ((staff || {}).role || {}).access_patient === RoleType.SUPERVISOR || (role || {}).access_patient === RoleType.ALL || (role || {}).access_patient === patientType,
            update: ((staff || {}).role || {}).access_patient === RoleType.SUPERVISOR || (staff || {}).id === ((patient || {}).staff || {}).id,
        },
        baseline: {
            update: ((staff || {}).role || {}).access_patient === RoleType.SUPERVISOR || ((!patientType || (role || {}).access_baseline === RoleType.ALL || (role || {}).access_baseline === patientType) && owner),
        },
        prescription: {
            update: ((staff || {}).role || {}).access_patient === RoleType.SUPERVISOR || !patientType || (role || {}).access_prescription === RoleType.ALL || (role || {}).access_prescription === patientType,
            view: ((staff || {}).role || {}).access_patient === RoleType.SUPERVISOR || !patientType || (role || {}).access_prescription !== RoleType.NOT,
        },
    };
}

export const getAccessByUserPermission = (userPermission: UserPermissions): boolean => {
    return ((userPermissionsStore || {}).userPermissions || []).some((userPerm) => userPerm === userPermission);
}

export const onlySaveBaseline = (activePatient: ActivePatient, access: PatientAccess): boolean => {
    return (
        activePatient.patient
        && activePatient.patientType === PatientType.PUBLIC
        // && access.updateBaseline
        // && !access.updatePrescription
        && !access.prescription.view
    );
}

// export const isBaselineReadonly = (role: StaffRole, patientType: PatientType): boolean => {
export const isBaselineReadonly = (staff: StaffDto, patientType: PatientType, patient: PatientDto): boolean => {
    const access: PatientAccess = getAccess((staff || {}).role, patientType, staff, patientType ? patient : undefined);

    return (
        // true ||
        // this.activePatient.patientType === PatientType.PUBLIC
        // &&
        !access.baseline.update
    );
}

export const isPrescriptionReadonly = (role: StaffRole, patientType: PatientType): boolean => {
    const activePatient: ActivePatient = getURLSearchParams(window.location.search);
    const access = getAccess(role, patientType);

    return (
        // true ||
        // this.activePatient.patientType === PatientType.PUBLIC
        // &&
        !access.prescription.update
        ||
        Boolean(activePatient.history)
    );
}

export const isPatientPublic = (patient): boolean => {
    return (patient || {}).type === PatientType.PUBLIC;
}

export const isShowComparisons = (patientType: PatientType): boolean => {
    return (patientType === PatientType.PUBLIC || patientType === PatientType.EDUCATION);
}

export const getCurrentTime = (): string => {
    return moment().format('HH:mm:ss.SSS');
}

export const getDateForDatePicker = (value: number | string): string => {
    // console.log('+++ value:', value);
    // console.log('+++ !!value ? (Number(value) * 1000) : undefined:', !!value ? (Number(value) * 1000) : undefined);
    // console.log('+++ moment(!!value ? (Number(value) * 1000) : undefined).utc(true).startOf(\'day\').format(\'YYYY.MM.DD\'):', moment(!!value ? (Number(value) * 1000) : undefined).utc(true).startOf('day').format('YYYY.MM.DD'));
    return moment(!!value ? (Number(value) * 1000) : undefined).utc().startOf('day').format(); // ('DD.MM.YYYY');
}

export const getDateForTooltip = (value: number | string): string => {
    return moment(!!value ? (Number(value) * 1000) : undefined).utc().startOf('day').format('YYYY.MM.DD');
}

export const getDateFromDatePicker = (value: string): number => {
    return moment(value).utc().startOf('day').valueOf() / 1000;
}

export const getCurrentUpdateDate = (): number => {
    return moment().startOf('day').valueOf() / 1000;
}

export const isDrugPayAvailable = (drug) => {
    const drugNorm = drug.trim().toLowerCase();

    return config.params.drugsNotPayAvailable
        .map(it => it.trim().toLowerCase())
        .findIndex(it => {
            return drugNorm.startsWith(it); // drugNorm.includes(it);
        }) === -1;
}

export const generatePatients = (): any[] => {
    const ps = [];
    for (let i = 1; i < 200; i++) {
        ps.push(
            {
                id: i,
                name: i.toString(),
                updateNosology: new Date().getTime(),
                create: new Date().getTime(),
                nosologies: [
                    {"id":"1","name":[{"key":"RU","value":"Гипертензия"},{"key":"EN","value":"Hypertension"}],"type":"HYPERTENSION","passed":true,"historyId":"6","updateDate":"2020-09-02T09:28:44.336507Z"},
                    {"id":"2","name":[{"key":"RU","value":"Фибрилляция предсердий"},{"key":"EN","value":"Atrial fibrillation"}],"type":"ATRIAL_FIBRILLATION","passed":false,"historyId":null,"updateDate":null},
                    {"id":"3","name":[{"key":"RU","value":"Сердечная недостаточность"},{"key":"EN","value":"Heart failure"}],"type":"HEART_FAILURE","passed":true,"historyId":"7","updateDate":"2020-09-02T09:35:22.050272Z"}
                ],
            }
        );
    }
    return ps;
}

export const getNextNosology = (patientData, useNosologiesWithHistory = false): PatientNosologyState => {
    let nextNosology: PatientNosologyState;
    const filteredNextNosologies: PatientNosologyState[] = (patientData.nosologyStates || [])
        .filter((nosology) => nosology.status === NosologyStatus.AVAILABLE)
        .filter((nosology) => !nosology.history);

    const filteredNextNosologiesFromAll: PatientNosologyState[] = useNosologiesWithHistory ? (patientData.nosologyStates || [])
        .filter((nosology) => nosology.status === NosologyStatus.AVAILABLE) : [];

    if (filteredNextNosologies.length > 0 || filteredNextNosologiesFromAll.length > 0) {
        const filteredNextNosologiesActual = filteredNextNosologies.length > 0 ? filteredNextNosologies : filteredNextNosologiesFromAll;
        nextNosology = filteredNextNosologiesActual.sort((nosology1, nosology2) => nosology2.id - nosology1.id)[0];
    }

    return nextNosology;
}

export const goNextNosology = (nextNosology: PatientNosologyState, history, activePatient: ActivePatient, intl, openDialog): void => {
    // console.error('+++ goNextNosology() +++ nextNosology:', nextNosology);
    if (!!nextNosology) {
        let query = `?patient=${activePatient.patient}&patientUuid=${activePatient.patientUuid}&patientType=${activePatient.patientType}&patientName=${activePatient.patientName}&nosology=${nextNosology.id}${activePatient.group ? `&group=${activePatient.group}` : ``}`;
        // console.error('+++ goNextNosology() +++ 1 query:', query);
        let route = '';
        switch (nextNosology.id) {
            case NosologyType.HYPERTENSION:
                route = '/hyper';
                break;
            case NosologyType.ATRIAL_FIBRILLATION:
                route = '/baseline';
                break;
            case NosologyType.HEART_FAILURE:
                route = '/heartfailure';
                break;
            case NosologyType.BREAST_CANCER:
                route = '/breastcancer';
                break;
            case NosologyType.DYSLIPIDEMIA:
                route = '/dyslipidemia';
                break;
            case NosologyType.IBS:
                route = '/ibs';
                break;
            default:
                break;
        }
        // console.error('+++ goNextNosology() +++ route:', route);
        // console.error('+++ goNextNosology() +++ 2 query:', query);
        if (route) {
            history.push(route + query);
        }
    } else {
        if (!!openDialog) {
            openDialog();
        }
        // if (!!intl && !!showNotify) {
        //     showNotifyGeneral(intl.formatMessage({
        //         id: 'label.patient.nosology.some_error',
        //         defaultMessage: 'Not all data for nosology is complete'
        //     }), 'error', showNotify);
        // }

        history.replace(`/patients?patientType=${activePatient.patientType}&patient=${activePatient.patient}&patientUuid=${activePatient.patientUuid}&activeTab=nosology${activePatient.group ? `&group=${activePatient.group}` : ``}`);
    }
}

export const finalStepNosologyRedirect = (signal, client, setContextPatient, history, activePatient: ActivePatient, setPatientDataContext, needGoNextNosology = true): void => {
// TODO: +++2021.12.06+++
    /*
        loadGetPatient(client, signal, activePatient.patient, setPatientDataContext)
            .then((patientData) => {
                if (setContextPatient !== undefined) {
                    setContextPatient(Boolean(patientData) ?
                        {
                            patient: patientData,
                        }
                        :
                        null,
                        () => {
                            if (needGoNextNosology) {
                                const nextNosology = activePatient.action === 'RESULT' ? null : getNextNosology(patientData, false);
                                goNextNosology(nextNosology, history, activePatient);
                            } else {
                                goNextNosology(undefined, history, activePatient, undefined, undefined);
                            }
                        }
                    );
                }
            });
    */

    if (needGoNextNosology) {
        loadGetPatient(client, signal, activePatient.patient, activePatient.patientUuid, setPatientDataContext)
            .then((patientData) => {
                if (setContextPatient !== undefined) {
                    setContextPatient(Boolean(patientData) ?
                            {
                                patient: patientData,
                            }
                            :
                            null,
                        () => {
                            const nextNosology = activePatient.action === 'RESULT' ? null : getNextNosology(patientData, false);
                            goNextNosology(nextNosology, history, activePatient);
                        }
                    );
                }
            });
    } else {
        goNextNosology(undefined, history, activePatient, undefined, undefined);
    }

}

export function abortApolloPending(abortController: AbortController, useApolloNetworkStatus) {
    // const status = useApolloNetworkStatus ? useApolloNetworkStatus() : undefined;
    // const isPending = ((status || {}).numPendingQueries > 0 || (status || {}).numPendingMutations > 0);
    abortController.abort();
}

export const clearContextPatient = (setContextPatient, callback) => {
    if (setContextPatient !== undefined) {
        setContextPatient(null, () => {
            if (callback) {
                callback();
            }
        });
    }
}

export const showNotifyGeneral = (message: string, type: string, showNotify) => {
    if (showNotify) {
        showNotify(message, type);
    }
}

export const getColorByScoreAndSelectType = (score: number, selectType: number, statistic: boolean = false): string => {
    if (selectType === 1 && statistic) {
        return GroupedBarChartColors.DARK_GREEN;
    }
    if (selectType === 2) {
        return statistic ? GroupedBarChartColors.DARK_BLUE : GroupedBarChartColors.BLUE;
    }
    if (selectType === 3) {
        return statistic ? GroupedBarChartColors.DARK_GREEN : GroupedBarChartColors.GREEN;
    }
    if (score === 0) {
        return red[500];
    }
    if (0 < score && score < 100) {
        return orange[500];
    }
    if (score === 100) {
        return green[500];
    }
}

export const getColorByPurchaseStatus = (status: PurchaseStatus): string => {
    let color: string = grey[500];
    switch (status) {
        case PurchaseStatus.SUCCESS:
            color = green[500];
            break;
        case PurchaseStatus.ERROR:
            color = red[500];
            break;
        case PurchaseStatus.WAITING_FOR_PAYMENT:
            color = grey[500];
            break;
        default:
            color = grey[500];
            break;
    }
    return color;
}

export const getTextByPurchaseStatus = (status: PurchaseStatus, intl): string => {
    return intl.formatMessage({
        id: 'label.purchase.status_' + status,
        defaultMessage: 'Status'
    });
}

export const isTherapyPresumably = (therapyPresumably: TherapyDataIn[]): boolean => {
    return (therapyPresumably || []).every((item) => item.selectIds.length > 0);
    // return (Boolean(therapyPresumably) && therapyPresumably.length  > 0);
}

export const stringDivider = (str: string, width: number, spaceReplacer: string): string => {
    if ((str || '').length > width) {
        let p = width;
        for (; p>0 && str[p] !== ' '; p--) {
        }
        if (p>0) {
            const left = str.substring(0, p);
            const right = str.substring(p + 1);
            return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
        }
    }
    return str;
}

// export const initGroupedBarChart = (name1: string, name2: string): any => {
export const initGroupedBarChart = (bar1: GroupedBarChartInitParams, bar2: GroupedBarChartInitParams): GroupedBarChartConfig => {
    return {
        title: '',
        data: [
            {
                x: [],
                y: [],
                name: (bar1 || {}).name || '',
                // legendgroup: (bar1 || {}).name || '',
                orientation: 'h',
                type: 'bar',
                hoverinfo: 'none',
                textposition: 'auto',
                textfont: {
                    size: 14,
                    family: 'Roboto, Helvetica, Arial, sans-serif',
                },
                insidetextfont: {
                    color: 'rgb(255, 255, 255)',
                },
                marker: {
                    // color: 'rgb(63, 81, 181)',
                    color: (bar1 || {}).color || '',
                },
            },
            {
                x: [],
                y: [],
                name: (bar2 || {}).name || '',
                // legendgroup: (bar2 || {}).name || '',
                orientation: 'h',
                type: 'bar',
                hoverinfo: 'none',
                textposition: 'auto',
                textfont: {
                    size: 14,
                    family: 'Roboto, Helvetica, Arial, sans-serif',
                },
                insidetextfont: {
                    color: 'rgb(255, 255, 255)',
                },
                marker: {
                    // color: 'rgb(76, 175, 80)',
                    color: (bar2 || {}).color || '',
                },
            },
        ],

    };
}

export const initGroupedBarChart2 = (bars: GroupedBarChartInitParams[], orientation: BarChartOrientation = BarChartOrientation.HORIZONTAL, legendgroup: boolean = false): GroupedBarChartConfig => {
    return {
        title: '',
        data: bars.map((bar) => {
            return (
                {
                    x: [],
                    y: [],
                    name: (bar || {}).name || '',
                    legendgroup: legendgroup ? (bar || {}).name || '' : undefined,
                    orientation: orientation,
                    textangle: 0,
                    type: 'bar',
                    hoverinfo: 'none',
                    textposition: 'auto',
                    textfont: {
                        size: 14,
                        family: 'Roboto, Helvetica, Arial, sans-serif',
                    },
                    insidetextfont: {
                        color: 'rgb(255, 255, 255)',
                    },
                    marker: {
                        // color: 'rgb(63, 81, 181)',
                        color: (bar || {}).color || '',
                    },
                }
            );
        }),
    };
}

export const initPolarChart = (polars: GroupedBarChartInitParams[]): PolarChartConfig => {
    return {
        title: '',
        data: polars.map((polar) => {
            return (
                {
                    type: 'scatterpolar',
                    r: [],
                    theta: [],
                    // fill: 'toself',
                    fill: 'none',
                    name: (polar || {}).name || '',
                    meta: (polar || {}).name || '',
                    // hoverinfo: 'r+theta+meta',
                    hovertemplate:
                        '<br>%{theta}: <b>%{r}</b><br>'
                        // +
                        // '<b>Value</b>: %{r}' +
                        // '<br><b>Parameter</b>: %{theta}<br>'
                        +
                        '<b>%{meta}</b>',
                }
            );
        }),
    };
}

export const extendParamsDrugByTradeItems = (paramsDrugs: ParamsDrugs[], drugsByDrugDosageNames: DrugsByDrugDosageNamesResultModel[]): ParamsDrugs[] => {
    paramsDrugs.forEach((paramsDrug, index) => {
        for (let i=0; i<drugsByDrugDosageNames.length; i++) {
            for (let j=0; j<paramsDrug.drugs.length; j++) {
                // if (paramsDrug.drugs[j].drugName === drugsByDrugDosageNames[i].name) {
                if (paramsDrug.drugs[j].drugId === drugsByDrugDosageNames[i].id) {
                    paramsDrug.drugs[j].tradeItems = drugsByDrugDosageNames[i].tradeItems;
                }
            }
        }
    });

    return paramsDrugs;
}

export const populateHPChart = (comps: { name: string; riskPl: number; riskTh: number }[], intl, riskColor: string, riskTherapyColor: string) => {
    let chartData;
    if (comps && comps.length > 0) {
        chartData = initGroupedBarChart2(
            [
                {
                    name: intl.formatMessage({
                        id: 'label.risk.therapy',
                        defaultMessage: 'Risk after therapy'
                    }),
                    // color: 'rgb(76, 175, 80)',
                    // color: 'rgb(63, 81, 181)',
                    color: riskTherapyColor,
                },
                {
                    name: intl.formatMessage({
                        id: 'label.risk',
                        defaultMessage: 'Initial risk'
                    }),
                    color: riskColor,
                },
            ]
        );

        comps.forEach((comp) => {
            if (!((comp.riskPl === 0 && comp.riskTh === 0) || (comp.riskPl === null && comp.riskTh === null))) {
                const tickLabel = stringDivider(comp.name || ('' + new Date().getTime()).substring(0, 5), 15, '<br>');
                chartData.data[1].x.unshift(comp.riskPl || 0);
                chartData.data[1].y.unshift(tickLabel);
                chartData.data[0].x.unshift(comp.riskTh || 0);
                chartData.data[0].y.unshift(tickLabel);
                // chartData.data[1].y.unshift(comp.riskPl !== null ? tickLabel : intl.formatMessage({id: 'message.data.no_data', defaultMessage: 'No data'}));

                // chartData.data[0].text = chartData.data[0].x.map((value) => toFixed(value) + '%');
                // chartData.data[1].text = chartData.data[1].x.map((value) => toFixed(value) + '%');
                chartData.data[0].text = chartData.data[0].x.map((value) => value !== null ? (toFixed(value) + '%') : intl.formatMessage({id: 'message.data.no_data', defaultMessage: 'No data'}));
                chartData.data[1].text = chartData.data[1].x.map((value) => value !== null ? (toFixed(value) + '%') : intl.formatMessage({id: 'message.data.no_data', defaultMessage: 'No data'}));
            }
        });
    }

    return chartData;
}

export const populateCommonChart = (barCharts: BarChartResponse[], intl, riskColor: string, riskReferentColor: string) => {
    let chartData;
    if (!!barCharts && !!barCharts.length) {
        chartData = initGroupedBarChart2(
            [
                {
                    name: barCharts[0].riskName,
                    color: riskColor,
                },
                {
                    name: barCharts[0].riskReferentName,
                    // color: 'rgb(76, 175, 80)',
                    // color: 'rgb(63, 81, 181)',
                    color: riskReferentColor,
                },
            ]
        );

        barCharts.forEach((barChart) => {
            if (!((barChart.risk === 0 && barChart.riskReferent === 0) || (barChart.risk === null && barChart.riskReferent === null))) {
                const tickLabel = stringDivider(barChart.outcomeName || ('' + new Date().getTime()).substring(0, 5), 15, '<br>');
                chartData.data[0].x.unshift(barChart.risk || 0);
                chartData.data[0].y.unshift(tickLabel);
                chartData.data[1].x.unshift(barChart.riskReferent || 0);
                chartData.data[1].y.unshift(tickLabel);
                // chartData.data[1].y.unshift(comp.riskPl !== null ? tickLabel : intl.formatMessage({id: 'message.data.no_data', defaultMessage: 'No data'}));

                // chartData.data[0].text = chartData.data[0].x.map((value) => toFixed(value) + '%');
                // chartData.data[1].text = chartData.data[1].x.map((value) => toFixed(value) + '%');
                chartData.data[0].text = chartData.data[0].x.map((value) => value !== null ? (toFixed(value) + '%') : intl.formatMessage({id: 'message.data.no_data', defaultMessage: 'No data'}));
                chartData.data[1].text = chartData.data[1].x.map((value) => value !== null ? (toFixed(value) + '%') : intl.formatMessage({id: 'message.data.no_data', defaultMessage: 'No data'}));
            }
        });
    }

    return chartData;
}

export const getQueryResult = (data: any, queryName: string): any => {
    const result = (data || {})[queryName];
    return result ? JSON.parse(JSON.stringify(result)) : result;
}

export const getPromiseQueryResult = (data: any, queryName: string, defaultResponse?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        const result = queryName ? getQueryResult(data, queryName) : data;
        if (!!defaultResponse && (result === null || result === undefined)) {
            resolve(defaultResponse);
        } else {
            resolve(result);
            // if (result !== false && !result) {
            //     // window.location.replace('/');
            //     resolve(result);
            // } else {
            //     resolve(result);
            // }
        }
    });
}

export const getPromiseResult = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        resolve(data);
    });
}

export const isShowGroups = (routePath: string): boolean => {
    const activePatient: ActivePatient = getURLSearchParams(routePath);
    return activePatient.patientType === PatientType.PUBLIC || activePatient.patientType === PatientType.EDUCATION;
}

export const getPatientTypeFromPath = (path: string): PatientType => {
    const routePath = path.split('?')[1];
    const activePatient: ActivePatient = getURLSearchParams(routePath);
    return activePatient.patientType;
}

export const isPrivate = (routePath: string): boolean => {
    const activePatient: ActivePatient = getURLSearchParams(routePath);
    return activePatient.patientType === PatientType.PRIVATE;
}

export const patientsRouteCompare = (routePath: string, locationPathname: string, locationSearch: string): boolean => {
    const activePatient: ActivePatient = getURLSearchParams(locationSearch);
    return (
        // !activePatient.group
        // &&
        (routePath || '').indexOf(`${locationPathname}`) !== -1
        &&
        (
            (routePath || '').indexOf(`patientType=${activePatient.patientType}`) !== -1
            ||
            (routePath || '').indexOf(`mode=${activePatient.mode}`) !== -1
        )
    )
}

export const getSelectedNode = (nodeIds, nodes) => {
    let selectedNode;
    for(let i = 0; i < nodes.length; i++) {
        if(Array.isArray(nodes[i].children)) {
            selectedNode = getSelectedNode(nodeIds, nodes[i].children);
        }
        if (selectedNode) {
            return selectedNode;
        }
        if (nodes[i].id === nodeIds) {
            selectedNode = nodes[i];
            return selectedNode;
        }
    }
}

export const getAllParentNodeIds = (groupId, nodes) => {
    let result;
    if (nodes) {
        nodes.some(o => {
            let temp;
            if (o.id === groupId) {
                result = [groupId];
                return true;
            }
            temp = getAllParentNodeIds(groupId, o.children || []);
            if (temp) {
                result = [o.id, ...temp];
                return true;
            }
            return false;
        });
    }
    return result;
}

export const getSelectedExpandedGroupFromGroups = (groups: GroupDto[], expandedGroups: GroupDto[] = []): {selectedGroup: GroupDto, expandedGroups: number[]} => {
    for (let group of groups) {
        if (!group.children || group.children.length === 0) {
            return {
                selectedGroup: group,
                expandedGroups: expandedGroups.map((expandedGroup) => '' + expandedGroup.id),
            };
        } else {
            expandedGroups.push(group);
            return getSelectedExpandedGroupFromGroups(group.children, expandedGroups);
        }
    }
}

export const getAllExpandedGroupFromGroups = (groups: GroupDto[], expandedGroups: GroupDto[] = []): [] => {
    return (groups || []).reduce((done, group) => {
        if (group.children && group.children.length > 0) {
            expandedGroups.push('' + group.id);
            return getAllExpandedGroupFromGroups(group.children, expandedGroups);
        } else {
            return expandedGroups;
        }
    }, []);
}

export const patientsGroupsOpen = (
    client,
    signal,
    routePath: string,
    _this,
    same,
    history,
    callback
) => {
    const activePatient: ActivePatient = getURLSearchParams(routePath.split('?')[1]);
    // console.error('+++ patientsGroupsOpen() +++ activePatient:', activePatient);
    // console.error('+++ patientsGroupsOpen() +++ _this.context.patientsGroups:', _this.context.patientsGroups);
    // console.error('+++ patientsGroupsOpen() +++ _this.context.patientsGroups[activePatient.patientType || activePatient.mode]:', _this.context.patientsGroups[activePatient.patientType || activePatient.mode]);

    _this.context.setPatientsGroups({
        ..._this.context.patientsGroups,
        [activePatient.patientType || activePatient.mode]: {
            open: (!same || !_this.context.patientsGroups[activePatient.patientType || activePatient.mode].open),
            data: _this.context.patientsGroups[activePatient.patientType || activePatient.mode].data,
        }
    }, () => {
        if (
            _this.context.patientsGroups[activePatient.patientType || activePatient.mode].open
            &&
            (
                !_this.context.patientsGroups[activePatient.patientType || activePatient.mode].data
                ||
                _this.context.patientsGroups[activePatient.patientType || activePatient.mode].data.length === 0
            )
        ) {
            loadGetListHiLevelGroup(client, signal)
                .then((listHiLevelGroup) => {
                    listHiLevelGroup = listHiLevelGroup.filter((hiLevelGroup) => hiLevelGroup.type === PatientTypeToGroupType[activePatient.patientType]);
                    _this.context.setPatientsGroups({
                        ..._this.context.patientsGroups,
                        [activePatient.patientType]: {
                            open: true,
                            data: listHiLevelGroup,
                        }
                    }, () => {
                        let groupId;
                        let groupIds: [] = [];
                        let group;
                        const allExpandedGroupFromGroups = getAllExpandedGroupFromGroups(listHiLevelGroup);
                        if (activePatient.group) {
                            groupId = activePatient.group;
                            group = getSelectedNode(groupId, listHiLevelGroup);
                            groupIds = allExpandedGroupFromGroups;
                        } else {
                            const selectedExpandedGroupFromGroups = getSelectedExpandedGroupFromGroups(listHiLevelGroup);
                            group = (selectedExpandedGroupFromGroups || {}).selectedGroup;
                            groupId = ((group || {}).id || '').toString();
                            groupIds = allExpandedGroupFromGroups;
                        }

                        _this.context.setRecursiveTreeViewExpanded(activePatient.patientType, groupIds, () => {
                            _this.context.setRecursiveTreeViewSelected(activePatient.patientType, groupId, group, () => {
                                clearContextPatient(_this.context.setContextPatient, () => {
                                    let newRoutePath = routePath;
                                    if (activePatient.group === null) {
                                        newRoutePath = `${newRoutePath}${!activePatient.group ? `&group=${groupId}` : ``}`;
                                    }
                                    history.replace(newRoutePath);
                                });

                                if (callback) {
                                    callback();
                                }
                            });
                        });
                    });
                });
        } else {
            // console.error('+++ patientsGroupsOpen() +++ _this.context.recursiveTreeViewSelected:', _this.context.recursiveTreeViewSelected);
            clearContextPatient(_this.context.setContextPatient, () => {
                let groupId;
                let group;
                if (activePatient.group) {
                    groupId = activePatient.group;

                    // TODO: добавил, но может вызывать зацикливание добавления к URL
                    if (!activePatient.node && activePatient.mode) {
                        group = getSelectedNode(groupId, _this.context.patientsGroups[activePatient.mode].data);
                    }

                    if (groupId !== (_this.context.patientsGroups[activePatient.patientType || activePatient.mode].data[0] || {}).id) {
                    }
                } else {
                    if (activePatient.mode && activePatient.node) {
                        group = _this.context.patientsGroups[activePatient.patientType || activePatient.mode].data.find((patientsGroupsData) => patientsGroupsData.mode === activePatient.node);
                    }
                    if (!group) {
                        group = _this.context.patientsGroups[activePatient.patientType || activePatient.mode].data[0] || {};
                    }
                    groupId = (group.id || '').toString();
                }
                // console.error('+++ patientsGroupsOpen() +++ 2 group:', group);
                // console.error('+++ patientsGroupsOpen() +++ routePath:', routePath);
                // console.error('+++ patientsGroupsOpen() +++ routePath.indexOf(\'node=\'):', routePath.indexOf('node='));

                let profileNode;
                if ((routePath || '').indexOf('node=') === -1) {
                    profileNode = group?.mode;
                }
                // console.error('+++ patientsGroupsOpen() +++ profileNode:', profileNode);

                let newRoutePath = routePath;
                if (activePatient.mode) {
                    if (activePatient.group !== null) {
                        newRoutePath = `${newRoutePath}${!!profileNode ? `&node=${profileNode}` : ``}${!activePatient.group ? `&group=${groupId}` : ``}`;
                    }
                    if (!activePatient.activeTab) {
                        newRoutePath = newRoutePath + `&activeTab=${ProfileTariffsTabEnum.TARIFFS}`;
                    }
                }
                // console.error('+++ patientsGroupsOpen() +++ newRoutePath:', newRoutePath);

                if (activePatient.mode && !_this.context.recursiveTreeViewSelected[activePatient.mode]) {
                    // _this.context.setRecursiveTreeViewExpanded(activePatient.mode, groupIds, () => {
                    _this.context.setRecursiveTreeViewSelected(activePatient.mode, groupId, group, () => {
                    });
                    // });
                }

                // console.error('+++ _this.context.recursiveTreeViewSelected:', _this.context.recursiveTreeViewSelected);

                history.replace(newRoutePath);
            });

            if (callback) {
                callback();
            }
        }
    });
}

export const setContextPanelView = (setContextContent, view) => {
    if (setContextContent !== undefined) {
        if (Boolean(view)) {
            setContextContent({view: view});
        } else {
            setContextContent(null);
        }
    }
};

export const getViewPatientsGroups = (patientsGroups, history, setContextPatient, locationSearch, routePath: string): any => {
    const activePatientRoutePath: ActivePatient = getURLSearchParams(routePath.split('?')[1]);
    const activePatientCurrent: ActivePatient = getURLSearchParams(locationSearch);

    return (
        patientsGroups[activePatientRoutePath.patientType].data
            .map(item => (
                <ListItem
                    button
                    selected={item.id === activePatientCurrent.group}
                    key={item.id}
                    onClick={event => {
                        clearContextPatient(setContextPatient, () => {
                            history.replace(`${routePath}&group=${item.id}`);
                        });
                    }}
                >
                    <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{ style: { whiteSpace: 'normal', wordWrap: 'break-word' } }}
                    />
                </ListItem>
            ))
    );
}

export const getRandomChartMarkerColors = (index: number, selectType: number = 0, statistic: boolean = false) => {
    if (selectType !== 1 && selectType !== 2 && selectType !== 3) {
        index = index !== undefined ? index % ChartMarkerColors.length : Math.floor(Math.random() * ChartMarkerColors.length);
        return ChartMarkerColors[index];
    } else {
        if (selectType === 1 || selectType === 3) {
            if (selectType === 1 && statistic) {
                return GroupedBarChartColors.DARK_GREEN;
            }
            return GroupedBarChartColors.GREEN;
        } else {
            if (selectType === 2 && statistic) {
                return GroupedBarChartColors.DARK_BLUE;
            }
            return GroupedBarChartColors.BLUE;
        }
    }
}

export const newAnyDataRef = (data: any): any => {
    return JSON.parse(JSON.stringify(data));
}

export const setPatientDataContextHelper = (setPatientDataContext, resolve, patientData: PatientExtDto, callback): Promise<PatientExtDto> => {
    if (setPatientDataContext !== undefined) {
        setPatientDataContext({
            patientDataContext: patientData
        }, () => {
            if (!!callback) {
                callback();
            }
            return resolve(patientData);
        });
    } else {
        return resolve(patientData);
    }
}

export const isPrivatePatientsEnable = (): boolean => {
    return config.options.private_patients.enable;
}

export const isEducationalPatientsEnable = (): boolean => {
    return config.options.educational_patients.enable;
}

export const isBackDisable = (activeStep, steps, disableResultNext): boolean => {
    return activeStep === 0 || ((activeStep === (steps.length - 1)) && disableResultNext);
}

export const isDisableResultNext = (action: string, touched: boolean, disableResultNext: boolean): boolean => {
    return (
        touched
        &&
        (
            disableResultNext
            ||
            action === 'RESULT'
        )
    );
}

export const getNosologyIcon = (nosology: PatientNosologyState, average: number, classes): SvgIcon => {
    const className = getNosologyClassName(nosology.history, average, classes);
    switch (nosology.id) {
        case NosologyType.ATRIAL_FIBRILLATION:
            return <FibrillationIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
        case NosologyType.HYPERTENSION:
            return <HyperIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
        case NosologyType.HEART_FAILURE:
            return <HeartFailureIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
        case NosologyType.DYSLIPIDEMIA:
            return <DyslipidemiaIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
        case NosologyType.IBS:
            return <IbsIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
        default: return undefined;
    }
}

export const getNosologyClassName = (history: PatientHistoryDto, average: number, classes): Object => {
    if (average === undefined) {
        return history ? classes.historyNosologyIconClassName : classes.nosologyIconClassName;
    } else {
        if (average === 0) {
            return classes.colorByScoreRed;
        } else if (average === 100) {
            return classes.colorByScoreGreen;
        } else {
            return classes.colorByScoreOrange;
        }
    }
}

export const getEvidenceLevelClassName = (evidenceLevelColorType: number, classes): Object => {
    let resultClassName = classes.evidenceLevelColorType0;
    switch (evidenceLevelColorType) {
        case 0:
            resultClassName = classes.evidenceLevelColorType0;
            break;
        case 1:
            resultClassName = classes.evidenceLevelColorType1;
            break;
        case 2:
            resultClassName = classes.evidenceLevelColorType2;
            break;
        default:
            resultClassName = classes.evidenceLevelColorType0;
            break;
    }

    return resultClassName;
}

// export const getServiceMessage = (messages: Message[] = [], type: MessageType): ServiceMessage => {
//     const message: Message = (messages || []).filter((message) => message.type === type)[0];
//     return {
//         showService: Boolean((message || {}).payload),
//         ...message,
//     }
// }
export const getServiceMessages = (messages: Message[] = [], type: MessageType): ServiceMessage => {
    const messagesArray: Message[] = (messages || []).filter((message) => !!message && message.type === type && !!message.payload);
    return {
        showService: !!messagesArray.length,
        messages: messagesArray,
        type: type,
    }
}

export const getServiceMessagesDangerWarning = (messages: Message[] = []): ServiceMessage => {
    const messagesDANGER_WARNING: ServiceMessage = {
        type: MessageType.WARNING,
        messages: [],
        showService: false,
    };
    const serviceDangerCheckBox: ServiceMessage = getServiceMessages(messages, MessageType.DANGER);
    const serviceWarningCheckBox: ServiceMessage = getServiceMessages(messages, MessageType.WARNING);
    messagesDANGER_WARNING.messages = [...serviceDangerCheckBox.messages, ...serviceWarningCheckBox.messages];
    messagesDANGER_WARNING.showService = messagesDANGER_WARNING.messages.length > 0;
    return messagesDANGER_WARNING;
}

export const getServiceMessageDrug = (drugs) => (e): ServiceMessage => {
    const drugId = e.target.value;

    const drug = drugs.find(d => (d.id || 0).toString() === drugId);

    /* FIXME: удалить, т.к. ниже получаем объединенные сервисные сообщения препарата для типов DANGER и WARNING
        let msg = getServiceMessages(drug.messages, MessageType.DANGER);
        if (!msg?.showService) {
            msg = getServiceMessages(drug.messages, MessageType.WARNING);
        }
        return msg;
    */
    return getServiceMessagesDangerWarning(drug.messages);
}

export const joiner = (ar: any[], joinTag: string): string => {
    return ar.join(joinTag);
}

export const joinerBR = (ar: any[]): string => {
    return joiner(ar, '<br>');
}

export const joinerNN = (ar: any[]): string => {
    return joiner(ar, '\n\n');
}

export const clearMarkDownText = (text: string = ''): string => {
    return text.replace(/ {2}/g, ' ').replace(/\n {2}/g, '\n');
}

export const notEmptyValue = (value: any): boolean => {
    return (value !== undefined && value !== null && value !== '');
}

export const getParameterOpt = (parameter: BaselineParameterDto): ParameterOptDto => {
    const parameterOpt: ParameterOptDto = JSON.parse(parameter.opt || {});
    return parameterOpt;
}

export const getParameterValid = (parameter: BaselineParameterDto): boolean => {
    const parameterOpt: ParameterOptDto = getParameterOpt(parameter);
    const required: boolean = parameterOpt.required;
    const selectedItem = getSelected(parameter);
    let parameterValid = (!required || (required && ((!parameter.visible || !parameter.enable) || (parameter.type === ParameterType.OPTION ? Boolean((selectedItem || {}).valueId) : notEmptyValue((selectedItem || {}).value)))));

    if (parameter.visible && selectedItem) {
        if ([ParameterType.INT, ParameterType.DECIMAL, ParameterType.DATE].includes(parameter.type)) {
            parameterValid = parameterValid && inRange(selectedItem, parameter);
        }
    }

    return parameterValid;
}

export const getBaselinesForAnalyze = (patientData: any): BaselineDetect[] => {
    const baselines: BaselineDetect[] = patientData.parameters.filter((parameter) => !!parameter).map((parameter) => {
        // console.error('+++ getBaselinesForAnalyze() +++ parameter:', parameter);
        let baselineParameter: BaselineParameterDto;
        for (const section of patientData.baseline) {
            baselineParameter = section.parameters.find((baselineParameter) => ('' + baselineParameter.id) === ('' + parameter.fieldId));
            // console.error('+++ getBaselinesForAnalyze() +++ baselineParameter:', baselineParameter);
            if (!!baselineParameter) {
                break;
            }
        }
        if (!!baselineParameter) {
            const baselineParameterOpt = getParameterOpt(baselineParameter);
            const baseline: BaselineDetect = {
                id: Number(parameter.fieldId),
                type: parameter.type,
                defaultValue: !!baselineParameterOpt.def ? '' + baselineParameterOpt.def : '',
                minValue: notEmptyValue(baselineParameterOpt.min) ? '' + baselineParameterOpt.min : '',
                maxValue: notEmptyValue(baselineParameterOpt.max) ? '' + baselineParameterOpt.max : '',
            };
            // console.error('+++ getBaselinesForAnalyze() +++ baseline:', baseline);

            return baseline;
        } else {
            return undefined;
        }
    }).filter((baseline) => !!baseline);

    return baselines;
}

export const updatePatientDataBaselineAndParameters = (baseline, patientData: any): void => {
    patientData.baseline = baseline;

    // console.error('+++ updatePatientDataBaselineAndParameters() patientData.baseline:', newAnyDataRef(patientData.baseline));
    // console.error('+++ updatePatientDataBaselineAndParameters() patientData.parameters:', newAnyDataRef(patientData.parameters));
    if (!!patientData.parameters.length) {
        patientData.baseline.map((section) => {
            section.parameters.map((parameterBaseline) => {
                const findParameter = patientData.parameters.find((parameter) => !!parameter && Number(parameterBaseline.id) === Number(parameter.fieldId));
                // console.error('+++ 0 findParameter:', findParameter);
                if (!!findParameter) {
                    parameterBaseline.updateDate = findParameter.updateDate;
                    parameterBaseline.noData = findParameter.noData;
                    parameterBaseline.sourceDetection = findParameter.sourceDetection;
                    parameterBaseline.valueDefault = findParameter.valueDefault;

                    // console.error('+++ 1 findParameter.valueDefault:', newAnyDataRef(findParameter.valueDefault));

                    // if (!!findParameter.valueDefault) {
                    //     console.error('+++ 1 findParameter:', newAnyDataRef(findParameter));
                    //     console.error('+++ 1 parameterBaseline:', newAnyDataRef(parameterBaseline));
                    // }
                }
                return parameterBaseline;
            });
            return section;
        });
    } else {
        // patientData.parameters = newAnyDataRef(patientData.baseline);
        // patientData.parameters = (patientData.baseline.flatMap((section) => section.parameters));
        patientData.parameters = getParametersFromBaseline(patientData.baseline);
        // console.error('+++ 2 patientData.parameters:', newAnyDataRef(patientData.parameters));
        // console.error('+++ from baseline  patientData.parameters:', patientData.parameters);
    }
    // console.error('+++ 3 patientData.parameters:', newAnyDataRef(patientData.parameters));
    // console.error('+++ from baseline  patientData.baseline:', patientData.baseline);
}

export const isNoDataAndAllowedNoData = (parameter: BaselineParameterDto): boolean => {
    const parameterOpt: ParameterOptDto = getParameterOpt(parameter);
    return !!parameterOpt.allowedNoData && !!parameter.noData;
}

export const inRange = (item: string | ValueNumberType, parameter: BaselineParameterDto): boolean => {
    if (!item) {
        return true;
    } else {
        const resultItem: ValueNumberType = (typeof item === 'string') ? JSON.parse(item) : item;

        if (parameter.type === ParameterType.DATE) {
            // console.error('+++ inRange() +++ item:', item);
            // console.error('+++ inRange() +++ resultItem?.value:', resultItem?.value);
            // console.error('+++ inRange() +++++++++ !resultItem?.value:', !resultItem?.value);
            // console.error('+++ inRange() +++++++++ (resultItem?.value || \'\').split(\'.\').length === 3:', (resultItem?.value || '').split('.').length === 3);
            // console.error('+++ inRange() +++++++++ moment(resultItem?.value, \'DD.MM.YYYY\').isValid():', moment(resultItem?.value, 'DD.MM.YYYY').isValid());
            // console.error('+++ inRange() +++++++++ moment(resultItem?.value, \'DD.MM.YYYY\'):', moment(resultItem?.value, 'DD.MM.YYYY'));
            const result = (
                !resultItem?.value
                ||
                (
                    (
                        (resultItem?.value || '').split('.').length === 3
                        ||
                        ((resultItem?.value || '').length === 4 && (resultItem?.value || '').indexOf('.') === -1)
                    )
                    &&
                    (
                        moment(resultItem?.value, 'DD.MM.YYYY').isValid()
                        ||
                        moment(resultItem?.value, 'YYYY').isValid()
                    )
                    &&
                    (
                        moment(resultItem?.value, 'DD.MM.YYYY').format('DD.MM.YYYY') === resultItem?.value
                        ||
                        moment(resultItem?.value, 'YYYY').format('YYYY') === resultItem?.value
                    )
                )
            );
            // console.error('+++ inRange() +++ result:', result);
            return result;
            // const dateParts: string[] = (resultItem?.value || '').split('.');
            // const allPartsIsNumber: boolean = dateParts.every((datePart) => isNumber(datePart));
            // let monthValid: boolean = false;
            // let yearValid: boolean = false;
            // if (allPartsIsNumber) {
            //     const date: number = Number(dateParts[0]);
            //     const month: number = Number(dateParts[1]);
            //     const year: number = Number(dateParts[2]);
            //     monthValid = month > 0 && month <= 12;
            //     yearValid = year > 0;
            // }
        }

        const parameterOpt: ParameterOptDto = getParameterOpt(parameter);
        const result = (Number(resultItem.value) <= Number((parameterOpt || {}).max) && Number(resultItem.value || 0) >= Number((parameterOpt || {}).min));
        return result;
    }
}

export const getNextLabel = (steps, activeStep, intl, contextStaff, activePatient, nosologyData, action): string => {
    const access = getAccess((contextStaff || {}).role, activePatient.patientType);

    if (
        activeStep === 0
        && onlySaveBaseline(activePatient, access)
    ) {
        return intl.formatMessage({id: 'label.save', defaultMessage: 'Save'});
    }

    if (steps.length > 1 && activeStep === (steps.length - 1) && activePatient.patient) {
        return intl.formatMessage({
            id: !nosologyData.touched && activePatient.action === 'RESULT' ? 'label.close' : 'label.next',
            defaultMessage: 'Next'
        });
    }

    if (Boolean(steps[activeStep].nextLabel)) {
        return steps[activeStep].nextLabel;
    }

    return intl.formatMessage({id: 'label.next', defaultMessage: 'Next'});
}

export const closePublicPatientsGroups = (patientType: PatientType, patientsGroups, setPatientsGroups, callback) => {
    patientsGroups[PatientType.PUBLIC].open = false;
    patientsGroups[PatientType.EDUCATION].open = false;
    patientsGroups[ProfileModeEnum.PROFILE].open = false;
    setPatientsGroups(patientsGroups, () => callback());
}

export const getContentsManual = (): string[] => {
    const path = window.location.pathname;
    return (INSTRUCTIONS[path] || {}).pages;
}

export const getNosologyDataHistory = (patientDataContext: PatientExtDto, nosologyId: number): any => {
    let nosologyData;
    const nosology = (patientDataContext.nosologyStates || []).find((nosology) => nosology.id === nosologyId) || {};
    const history = nosology.history || {};
    const response = history.response;
    const therapyCompare = (history.data || {}).therapyCompare;

    if (response) {
        try {
            nosologyData = JSON.parse(response);
            nosologyData.touched = false;
            if (therapyCompare) {
                nosologyData.therapyCompare = JSON.parse(therapyCompare);
            }
        } catch (error) {
            console.error('+++ Не удалось распарсить историю +++ error:', error);
        }
    }

    return nosologyData;
}

export const getSelected = (parameter: BaselineParameterDto) => {
    let selectedItem;
    switch (parameter.type) {
        case ParameterType.OPTION:
            selectedItem = parameter.values
                .filter(value => value)
                .find(it => {
                    const item = JSON.parse(it);
                    return item.selected;
                });
            break;
        case ParameterType.INT:
        case ParameterType.DECIMAL:
        case ParameterType.DATE:
            selectedItem = parameter.values
                .filter(value => value)
                .find((it) => {
                    const item = JSON.parse(it);
                    return notEmptyValue(item.value);
                });
            break;
        default:
            break;
    }
    selectedItem = selectedItem ? JSON.parse(selectedItem) : undefined;
    return selectedItem;
}

export const getSelectedValue = (value, parameterType: ParameterType) => {
    if (value && parameterType === ParameterType.DECIMAL) {
        value = round(value, 1);
    }

    return value;
}

export const getDevicePixelRatio = () => {
    let mediaQuery;
    let is_firefox = ((navigator || {}).userAgent || '').toLowerCase().indexOf('firefox') > -1;
    if (window.devicePixelRatio !== undefined && !is_firefox) {
        return window.devicePixelRatio;
    } else if (window.matchMedia) {
        mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5),(min--moz-device-pixel-ratio: 1.5),(-o-min-device-pixel-ratio: 3/2),(min-resolution: 1.5dppx)";
        if (window.matchMedia(mediaQuery).matches) {
            return 1.5;
        }
        mediaQuery = "(-webkit-min-device-pixel-ratio: 2),(min--moz-device-pixel-ratio: 2),(-o-min-device-pixel-ratio: 2/1),(min-resolution: 2dppx)";
        if (window.matchMedia(mediaQuery).matches) {
            return 2;
        }
        mediaQuery = "(-webkit-min-device-pixel-ratio: 0.75),(min--moz-device-pixel-ratio: 0.75),(-o-min-device-pixel-ratio: 3/4),(min-resolution: 0.75dppx)";
        if (window.matchMedia(mediaQuery).matches) {
            return 0.7;
        }
    } else {
        return 1;
    }
}

export const isDatatableStacked = () => {
    if (window.matchMedia) {
        return window.matchMedia('(max-width: 499.95px)').matches;
    } else {
        return false;
    }
}

export const loadStaticBaseline = (client, signal, nosology, patientDataContext, setPatientDataContext, callback): void => {
    const parameters = patientDataContext.patientDataContext.parameters.filter((parameter) => !!parameter).map((parameter) => (
        {
            value: parameter.value,
            fieldId: Number(parameter.fieldId),
            noData: parameter.noData,
        }
    ));
    const nosologyIds = (patientDataContext.patientDataContext.nosologyStates || []).map((nosology) => nosology.id);
    loadGetStaticBaseline(client, signal, nosologyIds, parameters)
        .then((staticBaseline) => {
            patientDataContext.patientDataContext.staticBaseline = staticBaseline;
            setPatientDataContext(patientDataContext, () => {
                callback();
            });
        });
}

export const getStaticBaselineView = (staticBaseline: BaselineParametrSectionDto[], classes): any[] => {
    const staticBaselineView = [];
    (staticBaseline || []).sort(sortByOrd).forEach((section) => {
        section.parameters.sort(sortByOrd).forEach((parameter) => {
            if (parameter.main) {
                const selectedValue = getSelected(parameter);
                if (selectedValue) {
                    staticBaselineView.push(
                        <Grid container spacing={2} key={parameter.id}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Typography className={classes?.staticBaselineContextPanel}>
                                    {parameter.name}:
                                </Typography>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes?.staticBaselineWrapperValueContextPanel}>
                                <Typography className={`${classes?.staticBaselineContextPanel} ${classes?.staticBaselineValueContextPanel}`} color={"primary"} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    {parameter.type === ParameterType.OPTION ? selectedValue.name : getSelectedValue(selectedValue.value, parameter.type)} {parameter.units ? parameter.units : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }
            }
        })
    });

    return staticBaselineView;
}

export const getExpandedBaselineParameterStrict = (baselineParameterStrict: StrictIndicators[] = []): boolean => {
    return baselineParameterStrict.length <= 10;
}
export const getBaselineParameterStrictView = (baselineParameterStrict: BaselineParameterStrictDto | StrictIndicators[] = [], classes): any[] => {
    const baselineParameterStrictView = [];
    (baselineParameterStrict).forEach((bms) => {
        const valueColorClass = getContStatusStyle((bms: StrictIndicators).colorValue, classes, NosologyType.IBS);

        baselineParameterStrictView.push(
            <Grid container
                // spacing={2}
                  key={bms.id}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes?.baselineParameterStrictContextPanel}>
                    <Typography className={classes?.baselineParameterStrictNameContextPanel}>
                        {bms.name}
                    </Typography>
                    {bms.value &&
                        <Typography
                            className={`${classes?.baselineParameterStrictValueContextPanel} ${valueColorClass}`}
                            color={"primary"} xl={12} lg={12} md={12} sm={12} xs={12}>
                            {bms.value} {bms.units ? bms.units : ''}
                            {/*{bms.value}*/}
                        </Typography>
                    }
                </Grid>
                {/*
                {bms.value &&
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                          className={classes?.staticBaselineWrapperValueContextPanel}>
                        <Typography
                            className={`${classes?.staticBaselineContextPanel} ${classes?.staticBaselineValueContextPanel}`}
                            color={"primary"} xl={12} lg={12} md={12} sm={12} xs={12}>
                            {bms.value} {bms.units ? bms.units : ''}
                        </Typography>
                    </Grid>
                }
*/}
            </Grid>
        );
    });

    return baselineParameterStrictView;
}

export const getContStatusStyle = (contStatus: number, classes, nosologyType: NosologyType) => {
    let contStatusStyle = '';
    switch (contStatus) {
        case 0:
            break;
        case 1:
            contStatusStyle = classes.contStatusStyle1;
            break;
        case 2:
            contStatusStyle = classes.contStatusStyle2;
            break;
        case 3:
            contStatusStyle = classes.contStatusStyle3;
            break;
        case 4:
            contStatusStyle = nosologyType !== NosologyType.HEART_FAILURE ? classes.contStatusStyle4 : '';
            break;
        default:
            break;
    }
    return contStatusStyle;
}

export const getAbsoluteRelativeContrs = (conds): { title: string; contrs: any[]; } => {
    const contrs = (conds || []).reduce((contrs, cond) => {
        contrs = [...contrs, ...(cond.contrGroups || [])];
        return contrs;
    }, []);
    return {
        title: conds[0].title,
        contrs: contrs,
    };
}

export const getAbsoluteRelativeCondsView = (title, groups, needPanelDivider, color: 'RED' | 'ORANGE', classes): any[] => {
    const listItemAvatarClass = color === "RED" ? classes.listItemAvatarRed : classes.listItemAvatarOrange;
    const listItemTextClass = color === "RED" ? classes.listItemTextRed : classes.listItemTextOrange;
    const conds =
        <Box>
            {needPanelDivider && <PanelDivider/>}
            <Paper className={classes.contentBoxPanel}>
                <SelTextView align={"center"}>
                    {title}
                </SelTextView>
                <List dense={true}>
                    {groups.map((group, index) => {
                        let serviceInfoHelp: ServiceMessage = getServiceMessages(group.messages, MessageType.INFO);
                        return (
                            <ListItem key={index} className={listItemAvatarClass}>
                                <Grid container direction={'row'} spacing={2} justifyContent={"space-between"}>
                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                        <ListItemIcon className={listItemAvatarClass}>
                                            <CheckIcon />
                                        </ListItemIcon>
                                    </Grid>
                                    <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                        <ListItemText
                                            className={listItemTextClass}
                                            primary={group.name}
                                            secondary={null}
                                        />
                                    </Grid>
                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                        {serviceInfoHelp?.showService &&
                                            <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                                                <InfoHelp texts={serviceInfoHelp.messages} />
                                            </ListItemSecondaryAction>
                                        }
                                    </Grid>
                                </Grid>
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
        </Box>;

    return conds;
}

export const getSelectedTreatmentsList = (groups: any[]): {group: any, treatment: any}[] => {
    const treatments = [];
    (groups || []).forEach((group) => {
        const treatment = !!group.treatments ? group.treatments.find(treatment => treatment.isSel) : undefined;
        if (treatment) {
            // treatments.push(treatment);
            treatments.push(
                {
                    group: group,
                    treatment: treatment,
                }
            );
        }
    });

    return treatments;
}

export const recursiveSortGroups = (groups: GroupDto[] = []): GroupDto[] => {
    groups.sort((group1, group2) => group1.ord - group2.ord);
    groups.forEach((group) => {
        if (Array.isArray(group.children)) {
            recursiveSortGroups(group.children);
        }
    });
    return groups;
}

export const sortByOrder = (itemWithOrder1, itemWithOrder2): number => {
    return itemWithOrder1.order - itemWithOrder2.order;
}

export const sortByOrd = (itemWithOrd1, itemWithOrd2): number => {
    return itemWithOrd1.ord - itemWithOrd2.ord;
}

export const filterDrugsByRegimens = (drug): boolean => {
    return !!drug.regimens && !!drug.regimens.length;
}

export const filterDrugsNotSkiped = (drug): boolean => {
    return drug.id !== -1;
}

export const filterDrugSelectedRegimen = (drug): boolean => {
    return !!drug.selectedRegimen;
}

export const getActiveTab = (location) => {
    const params = new URLSearchParams(location.search);
    const activePatient: ActivePatient = getURLSearchParams(params);
    return activePatient.activeTab || ProfileTariffsTabEnum.TARIFFS;
}

export const isRegCode = (location): boolean => {
    let searchParams = parseSearchParams({
        type: 'number',
    });

    const regCode = searchParams.code;
    // const regType = searchParams.type;

    return location.pathname === '/signup' && !!regCode;
}

export const patientDrugs = (patientData, patient, contextStaff, readonly = false, showTitle) => {
    // console.error('+++===+++ patientDrugs() +++===+++ patientData:', patientData);
    // console.error('+++===+++ patientDrugs() +++===+++ patient:', patient);
    return (
        <PatientDrugsView
            drugs={(patientData || {}).drugs}
            // patient={patient}
            patient={patientData}
            // activePatient={getURLSearchParams(this.props.location.search)}
            isPrescriptionReadonly={readonly || isBaselineReadonly(contextStaff, patient.type, patient)}
            showTitle={showTitle}
        />
    )
}

export const getTariffsLinkIntl = (intl, history): string => {
    const tariffs_link_text = intl.formatMessage({id: 'label.tariffs'}, {defaultMessage: 'Tariffs'});
    const tariffs_link = `/profile?mode=${ProfileModeEnum.PROFILE}&node=${ProfileProfileModeEnum.PROFILE_TARIFFS}&activeTab=${ProfileTariffsTabEnum.TARIFFS}`;
    // const tariffs_link_full = <a href={tariffs_link}>{tariffs_link_text}</a>;
    const tariffs_link_full = <Link style={{cursor: 'pointer'}} onClick={() => history.replace(tariffs_link)}>{tariffs_link_text}</Link>;
    // return intl.formatMessage({id: 'message.datatable.no_nosologies'}, {tariffs_link: `${tariffs_link_full}`}, {defaultMessage: 'No nosologies'}) + ' ' + tariffs_link_full;
    return (
        <React.Fragment>
            <FormattedMessage id='message.datatable.no_nosologies_staff' defaultMessage='No nosologies'/>
            {tariffs_link_full}
        </React.Fragment>
    );
    // return intl.formatMessage({id: 'message.datatable.no_nosologies'}, {defaultMessage: 'No nosologies'}) + ' ' + tariffs_link_full;
    // const tariffs_link_full = `${tariffs_link_text}`;
    // return intl.formatMessage({id: 'message.datatable.no_nosologies'}, {tariffs_link: `${tariffs_link_full}`}, {defaultMessage: 'No nosologies'});
}

export const getNoDataView = (text: string): any => {
    return <ListEmpty text={text}/>;
};

export const showShort = (patient: any): string => {
    return config.params.showShortForPatients[patient.id]
};

export const waitUntil = (condition) => {
    return new Promise((resolve) => {
        let interval = setInterval(() => {
            // console.error('+++ condition():', condition());
            if (!condition()) {
                return;
            }

            clearInterval(interval);
            resolve(true);
        }, 5555);
    });
}

export const getStepsLength = (steps: []): number => {
    return steps.filter((step) => step.enabled).length;
}

export const getStepNumberToShow = (activeStep: number, steps: []): number => {
    const resultSteps = steps.map((step, index) => {
        step.active = activeStep === index;
        return step;
    }).filter((step, index) => step.enabled);
    for (let i = 0; i < resultSteps.length; i++) {
        if (resultSteps[i].active) {
            return i + 1;
        }
    }
}

export const getNextStepNumber = (activeStep: number, steps: []): number => {
    activeStep++;
    while (activeStep < steps.length - 1 && !steps[activeStep].enabled) {
        activeStep++;
    }
    return activeStep;
}

export const getPrevStepNumber = (activeStep: number, steps: []): number => {
    activeStep--;
    while (activeStep > 0 && !steps[activeStep].enabled) {
        activeStep--;
    }
    return activeStep;
}

export const enableAllStepsFromActiveStep = (activeStep: number, steps: []) => {
    for (let i = activeStep + 1; i < steps.length - 1; i++) {
        steps[i].enabled = true;
    }
}

export const disableAllStepsFromActiveStepToResult = (activeStep: number, steps: []) => {
    for (let i = activeStep + 1; i < steps.length - 1; i++) {
        steps[i].enabled = false;
    }
}


export const getParametersForBaselineDto = (parameters: ParameterDto[]): ParametersForBaselineDto[] => {
    return parameters.map((parameter) => {
        const parameterReturn: ParametersForBaselineDto = {
            fieldId: parameter.fieldId,
            value: parameter.value,
            modified: parameter.modified,
            updateDate: parameter.updateDate,
        };
        return parameterReturn;
    });
}

export const getRandomZeroOrOne = (): number => {
    return Math.round(Math.random());
}

export const getDrugStyleObject = (drug, classes) => {
    const isDrugNotAvailable = (drug.availStatus !== undefined && (drug.availStatus === DrugAvailStatus.NOT_ON_SALE || drug.availStatus === DrugAvailStatus.NOT_RECOMMENDED));
    let drugStyle;
    const isMatch = !!drug.isMatch;
    const compStatus = !!drug.compStatus ? drug.compStatus : 0;

    if (isDrugNotAvailable) {
        drugStyle = classes.drugNotAvailable;
    } else if (isMatch) {
        drugStyle = classes.drugRecommend;
    } else {
        switch (compStatus) {
            case DrugCompStatus.WARNING:
                drugStyle = classes.drugWarning;
                break;
            case DrugCompStatus.DANGER:
                drugStyle = classes.drugDanger;
                break;
            case DrugCompStatus.DRUG_CURRENTLY:
                drugStyle = classes.drugCurrently;
                break;
            default:
                drugStyle = classes.drugNormal;
        }
    }

    return {
        isDrugNotAvailable: isDrugNotAvailable,
        drugStyle: drugStyle,
        isMatch: isMatch,
        compStatus: compStatus,
    };
}

export const staticBaselineHasMain = (staticBaseline: BaselineParametrSectionDto[]): boolean => {
    return (staticBaseline || []).some((section) => section.parameters.some((parameter) => parameter.main));
}

export const getUuid = (): string => {
    const _uuidv4 = uuidv4();
    return _uuidv4;
}

export const getHyphenedLang = (locale: string): any => {
    return locale === 'ru' ? ru : enUS;
}

export const getDateLocale = (locale: string): any => {
    let dateLocale = enUSDateLocale;
    switch (locale) {
        case 'en':
            dateLocale = enUSDateLocale;
            break;
        case 'ru':
            dateLocale = ruDateLocale;
            break;
        default:
            dateLocale = enUSDateLocale;
            break;
    }
    return dateLocale;
}

export const getValueDateToStringFormat = (value): string => {
    return value ? moment(value, 'DD.MM.YYYY').startOf('day').format('DD.MM.YYYY') : '';
}

export const getValueDateToInput = (value): Date => {
    return !!value ? moment(value, 'DD.MM.YYYY').startOf('day').toDate() : '';
}

export const updateParameterValues = (parameter, value): void => {
    const values = !!parameter.values.length ? parameter.values
        .map((it) => {
            const item = !!it ? JSON.parse(it) : {value: null};
            item.value = value;
            return JSON.stringify(item);
        }) : [JSON.stringify({value: value})];
    parameter.values = values;

}

export const getParameterValueAsString = (selectedItem, parameterBaselineType: ParameterType): string => {
    const selectedItemValue = (selectedItem?.value !== undefined) ? (('' + selectedItem?.value) || '') : null;
    const selectedItemValueId = (selectedItem?.valueId !== undefined) ? (('' + selectedItem?.valueId) || '') : '';
    const value = (parameterBaselineType === ParameterType.OPTION ? selectedItemValueId : selectedItemValue) || (parameterBaselineType !== ParameterType.DATE ? selectedItemValue : '');
    return value;
}

export const getParametersFromBaseline = (baseline: BaselineParametrSectionDto[]): ParameterDto[] => {
    const parameters: ParameterDto[] = [];

    baseline.forEach((section) => {
        section.parameters.forEach((parameterBaseline) => {
            const selectedItem = getSelected(parameterBaseline);
            // TODO: +++2023.05.02+++ всегда добавляем параметры, даже не выбранные
            if ((true || selectedItem) || [ParameterType.INT, ParameterType.DECIMAL, ParameterType.DATE].includes(parameterBaseline.type)) {
                // console.error('+++ onParametersSave() +++ parameter.noData:', parameter.noData);
                // const value = ('' + (parameterBaseline.type === ParameterType.OPTION ? (('' + selectedItem?.valueId) || '') : (('' + selectedItem?.value) || ''))) || (parameterBaseline.type !== ParameterType.DATE ? null : '');
                const value = getParameterValueAsString(selectedItem, parameterBaseline.type);
                // if (!!parameterBaseline.valueDefault) {
                //     console.error('+++ getParametersFromBaseline() +++ parameterBaseline:', parameterBaseline);
                // }
                parameters.push({
                    fieldId: '' + parameterBaseline.id,
                    type: parameterBaseline.type,
                    value: value,
                    modified: parameterBaseline.modified,
                    updateDate: !!parameterBaseline.updateDate ? ('' + parameterBaseline.updateDate) : parameterBaseline.updateDate,
                    noData: !!parameterBaseline.noData,
                    sourceDetection: parameterBaseline.sourceDetection,
                    valueDefault: parameterBaseline.valueDefault,
                });
            }
        });
    });


    return parameters;
}

export const getNotesDateByIndex = (notesDateList: string[] = [], index?: number): string => {
    if (index === undefined) {
        index = notesDateList.length - 1;
    }
    return notesDateList[index] || '';
}

export const isMultiselect = (drugGroup: any): boolean => {
    // FIXME: +++2023.02.04+++ для тестирования, убрать true
    return drugGroup.multiselectMax > 1;
}

export const getRandomKey = (): number => {
    return Math.round(Math.random() * new Date().getTime());
}
