import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    Container,
    Paper,
    Breadcrumbs,
    Typography, Grid,
} from "@material-ui/core";
import PanelDivider from "../PanelDivider";
import SelTextView from "../SelTextView";
import ReactMarkdown from "react-markdown";
import SelTreatView from "../baseline/SelTreatView";
import {getHPDrugTexts, getHPResultTexts} from '../../query/HPBaseline';
import {FormattedMessage} from "react-intl";
import config from "../../config";
import {NosologyType} from "../../const";
import ComparisonsView from "../ComparisonsView";
import {DrugResultRow} from "../drug/DrugResultRow";
import {loadCommercialDrugs} from "../../query/drugs";
import {
    capitalizeFirstLetter,
    extendParamsDrugByTradeItems,
    getQueryResult,
} from "../../utils";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import GroupedBarChartExt from "../charts/GroupedBarChartExt";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";

export default class HPResult extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        updateShowPanel(null);
        setLoadChartsMethod(null);
        const {showComparisons, setDisableResultNext} = this.props;
        if (!showComparisons) {
            this.setTherapyCompare();
        } else {
            if (!!setDisableResultNext) {
                setDisableResultNext(true);
            }
        }
        this.loadResultTexts();
        this.loadDrugTexts();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadResultTexts = () => {
        const {client, activePatient, getSelectTherapyType} = this.props;
        const {signal} = this.abortController;
        const selTherapy = getSelectTherapyType();

        client.query({
            query: getHPResultTexts,
            variables: {
                patientId: Number(activePatient.patient) || 0,
                selThIds: selTherapy ? [selTherapy.id] : [],
            },
            context: {
                uri: config.options.server.hp_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            // .then(result => (result.data || {}).getHPResultTexts)
            // .then(result => JSON.parse(JSON.stringify(result)))
            .then(result => getQueryResult(result?.data, 'getHPResultTexts'))
            .then(resultTexts => {
                this.setState({
                    resultTexts: resultTexts,
                });
            });
    };

    loadDrugTexts = () => {
        const {client, getTherapyGroupDrugs} = this.props;
        const {signal} = this.abortController;
        const selThDrugs = getTherapyGroupDrugs();
        const drugIds = ((selThDrugs || {}).items || [])
            .filter(it => it.selDrugId !== undefined && it.selDrugId > 0)
            .map(it => it.selDrugId);

        if (drugIds.length === 0) {
            return;
        }

        if (!!selThDrugs && ((selThDrugs || {}).items || []).length > 0) {
            const drugsIds: number[] = [];
            const paramsDrugs = [];
            ((selThDrugs || {}).items || [])
                .filter(group => Boolean(group.selDrugId) && group.selDrugId > 0)
                .forEach(group => {
                    let selDrug = group.takeGroups
                        .flatMap(tg => tg)
                        .flatMap(tg => tg.drugs)
                        .find(d => d.id === group.selDrugId);
                    // params[group.name] = selDrug.name;
                    drugsIds.push(selDrug.drugId);
                    paramsDrugs.push(
                        {
                            groupName: group.name,
                            drugs: [
                                {
                                    drugName: selDrug.name,
                                    drugId: selDrug.drugId,
                                }
                            ],
                        }
                    );
                });

            loadCommercialDrugs(client, signal, drugsIds)
                .then((drugsByDrugDosageNames) => {
                    this.setState({
                        paramsDrugs: extendParamsDrugByTradeItems(paramsDrugs, drugsByDrugDosageNames),
                    });
                });
        }

        if (!!selThDrugs && !!((selThDrugs || {}).items || []).length) {
            client.query({
                query: getHPDrugTexts,
                variables: {
                    drugIds: ((selThDrugs || {}).items || [])
                        .filter(it => it.selDrugId !== undefined && it.selDrugId > 0)
                        .map(it => it.selDrugId),
                },
                context: {
                    uri: config.options.server.hp_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                // .then(result => (result.data || {}).getHPDrugTexts)
                // .then(result => JSON.parse(JSON.stringify(result)))
                .then(result => getQueryResult(result?.data, 'getHPDrugTexts'))
                .then(drugTexts => {
                    this.setState({
                        drugTexts: drugTexts,
                    });
                });
        }
    };

    setTherapyCompare = (therapyCompare) => {
        const {
            setTherapyCompare,
            autoSave,
        } = this.props;

        setTherapyCompare(therapyCompare, () => {
            if (!!autoSave) {
                autoSave();
            }
        });
    }

    comparisonsView = () => {
        const {
            showComparisons,
            getTherapyPresumably,
            getSelectVars,
            getThRecommend,
            loadTherapyCompare,
            loadTherapyCompareStatistics,
            getTherapyCompare,
            getTouched,
        } = this.props;
        const therapyPresumably = getTherapyPresumably();

        return (
            showComparisons && therapyPresumably && therapyPresumably.thData &&
            <ComparisonsView
                getTherapyPresumably={getTherapyPresumably}
                getSelectVars={getSelectVars}
                nosology={NosologyType.HYPERTENSION}
                getThRecommend={getThRecommend}
                loadTherapyCompare={loadTherapyCompare}
                loadTherapyCompareStatistics={loadTherapyCompareStatistics}
                getTherapyCompare={getTherapyCompare}
                setTherapyCompare={this.setTherapyCompare}
                getTouched={getTouched}
            />
        );

        /*
            return (
                <Box>
                  <PanelDivider />
                  <Paper variant={"outlined"} className={classes.contentBox}>
                    <SelTextView align={"center"}><FormattedMessage id="label.comparisons.comparison" defaultMessage="Comparison" /></SelTextView>
                    <PanelDivider />
                    <Container maxWidth={"sm"}>
                      {therapyCompare && therapyCompare.length > 0 && (
                          <Box>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <FormattedMessage id="label.comparisons.name" defaultMessage="Name" />
                                  </TableCell>
                                  <TableCell align="right">
                                    <FormattedMessage id="label.comparisons.score" defaultMessage="Score" />
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {therapyCompare.map((compare, i) => (
                                    <TableRow key={i}>
                                      <TableCell>{compare.name}</TableCell>
                                      <TableCell align="right">{Number(compare.score) * 100}%</TableCell>
                                    </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                      )}
                    </Container>
                  </Paper>
                </Box>
            );
        */
    }

    paramsView = () => {
        const {
            classes, getScRisk, getScore, getScoreView, getGradeView, getScRiskView, getScRiskColor, getSubgroups, getCompGroups,
            getPossGroups, intl,
        } = this.props;
        const {paramsDrugs} = this.state;
        let params = {};

        const subgroups = getSubgroups();

        params[intl.formatMessage({id: 'label.hp.grade', defaultMessage: 'Grade'})] = getGradeView(false);
        /*
        const risk = getScRisk();
        const score = getScore();
        if (!((risk === 3 && score < 5) || (risk === 4 && score < 10) || score === 0)) {
            params[intl.formatMessage({id: 'label.hp.score', defaultMessage: 'Score'})] = getScoreView(false);
        }
        */
        const scRiskView = getScRiskView(false);
        if (scRiskView != null) {
            params[intl.formatMessage({id: 'label.hp.risk', defaultMessage: 'Systematic coronary risk'})] = scRiskView;
        }

        if (subgroups.items.length > 0) {
            params[intl.formatMessage({
                id: 'label.hp.risc_factors',
                defaultMessage: 'Risc factors'
            })] = subgroups.items.map(it => it.name).join(', ');
        }
        params[intl.formatMessage({
            id: 'label.hp.comp_cont',
            defaultMessage: 'Compelling Contraindications'
        })] = getCompGroups().map(it => it.name).join(', ') || intl.formatMessage({id: 'label.absent', defaultMessage: 'absent'});
        params[intl.formatMessage({
            id: 'label.hp.poss_cont',
            defaultMessage: 'Possible Contraindications'
        })] = getPossGroups().map(it => it.name).join(', ') || intl.formatMessage({id: 'label.absent', defaultMessage: 'absent'});

        const {getSelectTherapyType} = this.props;
        const selThType = getSelectTherapyType();
        if (Boolean(selThType)) {
            params[intl.formatMessage({id: 'label.hp.therapy.selected', defaultMessage: 'Therapy selected'})] = selThType.name;
        }

        // const selThComb = getSelectTherapyComb();
        // const selThCombChartData = populateHPChart((selThComb || {}).comps, intl, getScRiskColor(), GroupedBarChartColors.BLUE/*theme.palette.primary.main*/);

        const {getFinalComb, getThRecommend} = this.props;
        let thRecomm = getThRecommend();

        return (
            <Paper variant={"outlined"} className={classes.contentBox}>
                <Container maxWidth={"sm"}>
                    {/*<Table>*/}
                    {/*    <TableBody>*/}
                    {/*        {Object.keys(params).map((name, id) => (*/}
                    {/*            <TableRow key={id}>*/}
                    {/*                <TableCell style={{width: '10%'}}>{name}</TableCell>*/}
                    {/*                <TableCell style={{width: '10%'}} align={"right"}>{params[name]}</TableCell>*/}
                    {/*            </TableRow>*/}
                    {/*        ))}*/}
                    {/*        {paramsDrugs && paramsDrugs.map((paramDrug, id) => (*/}
                    {/*            paramDrug.drugs.map((drug) => (*/}
                    {/*                <DrugResultRow key={Object.keys(params).length + id} row={{groupName: paramDrug.groupName, drugName: drug.drugName, tradeItems: drug.tradeItems}} classes={classes}>*/}
                    {/*                </DrugResultRow>*/}
                    {/*            ))*/}
                    {/*        ))}*/}
                    {/*    </TableBody>*/}
                    {/*</Table>*/}
                    {Object.keys(params).map((name, id) => (
                        <Grid container direction={"row"} key={id} className={classes.resultParam}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamName}>{name}</Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamValue}>{params[name]}</Grid>
                        </Grid>
                    ))}
                    {paramsDrugs && paramsDrugs.map((paramDrug, id) => (
                        paramDrug.drugs.map((drug, index2) => (
                            <DrugResultRow key={index2} row={{groupName: paramDrug.groupName, drugName: drug.drugName, tradeItems: drug.tradeItems}} classes={classes}>
                            </DrugResultRow>
                        ))
                    ))}

                    {!!((thRecomm || {}).groupDrugs || []).length &&
                        <GroupedBarChartExt
                        dataIn={thRecomm}
                        getFinalComb={getFinalComb}
                        useResizeHandler={true}
                        classes={classes}
                        intl={intl}
                        getScRiskColor={getScRiskColor}
                        />
                    }

                    {/*{selThComb && selThComb.comps.length > 0 && (*/}
                    {/*    <Box>*/}
                    {/*        <PanelDivider/>*/}
                    {/*        {!selThCombChartData && (*/}
                    {/*        // <Table>*/}
                    {/*        //     <TableHead>*/}
                    {/*        //         <TableRow>*/}
                    {/*        //             <TableCell>*/}
                    {/*        //                 <FormattedMessage id="label.outcome" defaultMessage="Outcome"/>*/}
                    {/*        //             </TableCell>*/}
                    {/*        //             <TableCell align="right">*/}
                    {/*        //                 <FormattedMessage id="label.risk" defaultMessage="Initial risk"/>*/}
                    {/*        //             </TableCell>*/}
                    {/*        //             <TableCell align="right">*/}
                    {/*        //                 <FormattedMessage id="label.risk.therapy"*/}
                    {/*        //                                   defaultMessage="Risk after therapy"/>*/}
                    {/*        //             </TableCell>*/}
                    {/*        //         </TableRow>*/}
                    {/*        //     </TableHead>*/}
                    {/*        //     <TableBody>*/}
                    {/*        //         {selThComb.comps.map((comp, i) => (*/}
                    {/*        //             <TableRow key={i}>*/}
                    {/*        //                 <TableCell>{comp.name}</TableCell>*/}
                    {/*        //                 <TableCell align="right">{comp.riskPl}%</TableCell>*/}
                    {/*        //                 <TableCell align="right">{comp.riskTh}%</TableCell>*/}
                    {/*        //             </TableRow>*/}
                    {/*        //         ))}*/}
                    {/*        //     </TableBody>*/}
                    {/*        // </Table>*/}
                    {/*        <React.Fragment>*/}
                    {/*            <Grid container direction={"row"}>*/}
                    {/*            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><FormattedMessage id="label.outcome" defaultMessage="Outcome"/></Grid>*/}
                    {/*            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><FormattedMessage id="label.risk" defaultMessage="Initial risk"/></Grid>*/}
                    {/*            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><FormattedMessage id="label.risk.therapy" defaultMessage="Risk after therapy"/></Grid>*/}
                    {/*            </Grid>*/}
                    {/*            {selThComb.comps.map((comp, i) => (*/}
                    {/*                <Grid container direction={"row"} key={i} className={classes.resultParam}>*/}
                    {/*                <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.resultParamName}>{comp.name}</Grid>*/}
                    {/*                <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.resultParamValue}>{comp.riskPl}%</Grid>*/}
                    {/*                <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.resultParamValue}>{comp.riskTh}%</Grid>*/}
                    {/*                </Grid>*/}
                    {/*            ))}*/}
                    {/*        </React.Fragment>*/}
                    {/*        )}*/}
                    {/*        {selThCombChartData &&*/}
                    {/*        <Grid item xs={12}>*/}
                    {/*            <SelTextView align={'center'}>*/}
                    {/*                <FormattedMessage id="label.chart.chosen_therapy.header" defaultMessage="Forecast for the chosen therapy" />*/}
                    {/*            </SelTextView>*/}
                    {/*            <Box>*/}
                    {/*                <GroupedBarChart chartData={selThCombChartData} useResizeHandler={true} classes={classes}/>*/}
                    {/*            </Box>*/}
                    {/*        </Grid>*/}
                    {/*        }*/}
                    {/*    </Box>*/}
                    {/*)}*/}
                </Container>
            </Paper>
        );
    };

    combinationsView = () => {
        const {classes, getCombDrugs, getTherapyGroupDrugs} = this.props;
        const thGroupDrugs = getTherapyGroupDrugs();
        const selDrugIds = ((thGroupDrugs || {}).items || [])
            .filter(it => it.selDrugId)
            .map(it => it.selDrugId);
        const drugIds = ((thGroupDrugs || {}).items || [])
            .filter(it => it.selDrugId)
            .flatMap(it => it.takeGroups)
            .flatMap(it => it.drugs)
            .filter(it => selDrugIds.includes(it.id))
            .map(it => it.drugId);
        const combDrugs = (getCombDrugs() || [])
            .filter(comb => {
                return comb.drugs.filter(drug => drugIds.includes(drug.id)).length > 1;
            });

        if (combDrugs.length > 0) {
            return (
                <Box>
                    <PanelDivider/>
                    <Paper className={classes.contentBox} variant={"outlined"}>
                        <SelTextView align={"center"}>
                            <FormattedMessage id='label.hp.drugs.recommend.combs'
                                              defaultMessage='Recommended combinations'/>
                        </SelTextView>
                        <ul>
                            {combDrugs.map(combDrug => (
                                <li key={combDrug.combId}>
                                    <Breadcrumbs>
                                        {combDrug.drugs.map(drug =>
                                            <Typography
                                                color="textPrimary"
                                                key={drug.id}>
                                                {drug.name}
                                            </Typography>
                                        )}
                                    </Breadcrumbs>
                                </li>
                            ))}
                        </ul>
                    </Paper>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    textsView = () => {
        const {classes} = this.props;
        const {resultTexts, drugTexts} = this.state;

        return (
            <Box>

                {resultTexts &&
                [1, 2]
                    .map(secNum => resultTexts.find(it => it.id === secNum))
                    .filter(secTxt => secTxt !== undefined)
                    .map(secTxt => (
                        <Box key={secTxt.id}>
                            {(!!(secTxt.texts || []).length) &&
                                <>
                                    <PanelDivider/>
                                    <Paper className={classes.contentBox} variant={"outlined"}>
                                        <SelTextView align={"center"}>{secTxt.name}</SelTextView>
                                        <PanelDivider/>
                                        {secTxt.texts.map((txt, tid) => (
                                            <Box key={tid}>
                                                {txt.label && <SelTextView>{txt.label}</SelTextView>}
                                                {txt.text && (
                                                    <Typography component={'span'}>
                                                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={txt.text}/>
                                                    </Typography>
                                                )}
                                            </Box>
                                        ))}
                                    </Paper>
                                </>
                            }
                        </Box>
                    ))
                }

                {drugTexts && (
                    <Box>
                        <PanelDivider/>
                        <Paper className={classes.contentBox} variant={"outlined"}>
                            <SelTextView align={"center"}>
                                <FormattedMessage id='label.drugs' defaultMessage='Drugs'/>
                            </SelTextView>
                            <PanelDivider/>
                            {drugTexts.map(drugText => (
                                <Box key={drugText.id}>
                                    <SelTreatView treatName={capitalizeFirstLetter(drugText.name)}/>
                                    {drugText.texts.map((txt, tid) => (
                                        <Box key={tid}>
                                            {txt.label && <SelTextView>{txt.label}</SelTextView>}
                                            {txt.text && (
                                                <Typography component={'span'}>
                                                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={txt.text}/>
                                                </Typography>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </Paper>
                    </Box>
                )}

                {this.combinationsView()}

                {resultTexts &&
                [4, 5, 6, 7]
                    .map(secNum => resultTexts.find(it => it.id === secNum))
                    .filter(secTxt => secTxt !== undefined && secTxt.texts.length > 0)
                    .map(secTxt => (
                        <Box key={secTxt.id}>
                            {(!!(secTxt.texts || []).length) &&
                            <>
                                <PanelDivider/>
                                <Paper className={classes.contentBox} variant={"outlined"}>
                                    <SelTextView align={"center"}>{secTxt.name}</SelTextView>
                                    <PanelDivider/>
                                    {secTxt.texts.map((txt, tid) => (
                                        <Box key={tid}>
                                            {txt.label && <SelTextView>{txt.label}</SelTextView>}
                                            {txt.text && (
                                                <Typography component={'span'}>
                                                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={txt.text}/>
                                                </Typography>
                                            )}
                                        </Box>
                                    ))}
                                </Paper>
                            </>
                            }
                        </Box>
                    ))
                }

            </Box>
        );
    };

    render() {
        const {classes} = this.props;

        return (
            <Box className={classes.contentBox + ' ' + classes.overflowWrapBreakWord}>
                {this.paramsView()}
                {this.textsView()}
                {this.comparisonsView()}
            </Box>
        );
    }
}

HPResult.propTypes = {
    classes: PropTypes.object.isRequired,
    getSelectTherapyType: PropTypes.func.isRequired,
    getSelectTherapyComb: PropTypes.func.isRequired,
    getTherapyGroupDrugs: PropTypes.func.isRequired,
    getGrade: PropTypes.func.isRequired,
    getScore: PropTypes.func.isRequired,
    getScRisk: PropTypes.func.isRequired,
    getScRiskColor: PropTypes.func.isRequired,
    getSubgroups: PropTypes.func.isRequired,
    getCompGroups: PropTypes.func.isRequired,
    getPossGroups: PropTypes.func.isRequired,
    getSelectVars: PropTypes.func.isRequired,
    getScoreView: PropTypes.func.isRequired,
    getGradeView: PropTypes.func.isRequired,
    getScRiskView: PropTypes.func.isRequired,
    getCombDrugs: PropTypes.func.isRequired,
    getTherapyPresumably: PropTypes.func.isRequired,
    getTherapyCompare: PropTypes.func.isRequired,
    setTherapyCompare: PropTypes.func.isRequired,
    getTouched: PropTypes.func.isRequired,
    loadTherapyCompare: PropTypes.func.isRequired,
    loadTherapyCompareStatistics: PropTypes.func,
    getThRecommend: PropTypes.func.isRequired,
    getFinalComb: PropTypes.func.isRequired,
    setDisableResultNext: PropTypes.func.isRequired,
    showComparisons: PropTypes.bool,
    autoSave: PropTypes.func.isRequired,
};

