import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {pages} from "../../pages/tileData";
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

const SelectNosologyDialog = (props) => {
    const {
        open,
        title,
        text,
        setOpen,
        onSelect,
        buttonCancel,
        nosologies,
        // history,
    } = props;

    return (
        <Dialog
            fullWidth
            // onEscapeKeyDown={() => setOpen(false)}
            // onBackdropClick={() => setOpen(false)}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>
                {
                    !nosologies ? text
                        : (
                            // <nav className={''}>
                                            <List>
                                                {/*<ListItem*/}
                                                {/*    button*/}
                                                {/*    component="a"*/}
                                                {/*    href={'about'}*/}
                                                {/*    target="_blank"*/}
                                                {/*    krel="noopener noreferrer">*/}
                                                {/*    <ListItemIcon>*/}
                                                {/*        <InfoIcon color="primary" />*/}
                                                {/*    </ListItemIcon>*/}
                                                {/*    <ListItemText*/}
                                                {/*        primary={'About project'}*/}
                                                {/*    />*/}
                                                {/*</ListItem>*/}
                                                {pages
                                                    // .filter(item => intersect([item.nosology], nosologies).length > 0)
                                                    .filter(item => nosologies && item.nosology && nosologies.findIndex(nosology => nosology === item.nosology) !== -1)
                                                    .map(item => (
                                                        <ListItem
                                                            button
                                                            key={item.path}
                                                            onClick={event => {
                                                                // history.replace(item.path);
                                                                onSelect(item)
                                                            }}>
                                                            <ListItemIcon>
                                                                {item.icon}
                                                            </ListItemIcon>
                                                            <ListItemText primary={item.name} />
                                                        </ListItem>
                                                    ))}
                                            </List>
                            // </nav>
                        )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        // setOpen(false);
                        onSelect(null);
                    }}
                    color="primary"
                >
                    {buttonCancel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SelectNosologyDialog;
