import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ListItem from "@material-ui/core/ListItem";
import PanelDivider from "../PanelDivider";
import {blue} from "@material-ui/core/colors";


const styles = theme => ({
  ...mainStyles(theme),
  headerText: {
    // color: "white",
    // color: theme.palette.text.secondary,
    color: theme.palette.primary.main,
  },
  headerIcon: {
    // color: "white",
    color: theme.palette.primary.main,
  }
});

const HelpTextPanel = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(Accordion);

const HelpTextPanelSummary = withStyles(theme => ({
  root: {
    // backgroundColor: '#ff814c',
    backgroundColor: blue[50],
    '&:hover': {
      // backgroundColor: '#FF5722',
      backgroundColor: blue[70],
    },
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  expanded: {},
  content: {
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
}))(AccordionSummary);

function ExpandTextPanel (props) {
  const { children, textSummary, textSecondary, classes } = props;

  return (
    <HelpTextPanel>
      <HelpTextPanelSummary expandIcon={<ExpandMoreIcon className={classes.headerText} />}>
        <ListItem disableGutters>
          <ListItemIcon>
            <ArrowForwardIcon className={classes.headerIcon} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant={"h6"} className={classes.headerText}>{textSummary}</Typography>
          </ListItemText>
        </ListItem>
      </HelpTextPanelSummary>
      <AccordionDetails>
        <Box>
          <PanelDivider />
          {children}
          {textSecondary &&
            <Typography color={'textSecondary'} variant={'body2'}>
              {textSecondary}
            </Typography>
          }
        </Box>
      </AccordionDetails>
    </HelpTextPanel>
  );
}

ExpandTextPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  textSummary: PropTypes.string.isRequired,
  textSecondary: PropTypes.string,
};

export default compose(
  withStyles(styles),
)(ExpandTextPanel);

