import React from "react";
import mainStyles from "../../styles/mainStyles";
import compose from 'recompose/compose';
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import {FormattedMessage, injectIntl} from "react-intl";
import {
    Box, Grid,
    Typography,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import ExpandTextPanel from "../baseline/ExpandTextPanel";
import {loadGetRecommendTexts} from '../../query/common';
import PropTypes from "prop-types";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import IbsSelectAll from "./IbsSelectAll";
import IbsResult from "./IbsResult";
import {RecommendGuidelinesTextsInputDto, RecommendType} from "../../const";
import {HPSelectTherapyView} from "../hp/HPBComp";
import IbsSelectTherapy from "./IbsSelectTherapy";
import IbsSelectDrugs from "./IbsSelectDrugs";

const styles = theme => ({
    ...mainStyles(theme),
});

function createDyslipidemiaBaselineComp(WrapComp) {
    return class extends React.Component {

        static propTypes = {
            nosologyData: PropTypes.object.isRequired,
            activePatient: PropTypes.object,
            getRecomendTexts: PropTypes.func, // .isRequired,
            setRecomendTexts: PropTypes.func, // .isRequired,
            getSelectVars: PropTypes.func, // .isRequired,
            getSelectFields: PropTypes.func, // .isRequired,
            getSelectTherapyType: PropTypes.func, // .isRequired,
            patientId: PropTypes.string, // .isRequired,
            nosologyId: PropTypes.string, // .isRequired,
            updateContextPanel: PropTypes.func,
        };

        abortController = new AbortController();

        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            window.scrollTo(0, 0);
            // this.loadRecommendTexts();
        }

        componentWillUnmount() {
            this.abortController.abort();
        }

        loadRecommendTexts = () => {
            const {client, getRecomendTexts, setRecomendTexts, patientId, nosologyId} = this.props;
            const {signal} = this.abortController;

            if (getRecomendTexts && setRecomendTexts) {
                const recommendTexts = getRecomendTexts();

                if (
                    !recommendTexts
                    // || (nosologyData && nosologyData.touched)
                ) {
                    const recommendGuidelinesTextsInputDto: RecommendGuidelinesTextsInputDto = {
                        nosologyId: nosologyId,
                        patientId: patientId,
                    };
                    loadGetRecommendTexts(client, signal, recommendGuidelinesTextsInputDto)
                        .then(recommendTexts => {
                            setRecomendTexts(recommendTexts);
                            this.setState({
                                recommendTexts: recommendTexts,
                            })
                        });
                } else {
                    this.setState({
                        recommendTexts: recommendTexts,
                    })
                }
            }
        };

        getRecomentTextsView = () => {
            const {intl} = this.props;
            const {recommendTexts} = this.state;
            let texts = [];

            if (Boolean(recommendTexts)) {
                recommendTexts.forEach((txt, index) => {
                    if (Boolean(txt.label)) {
                        texts.push(<Typography color={"primary"} key={index}>
                            {txt.label}
                        </Typography>);
                    }
                    texts.push(
                        txt.text.map((text, index) =>
                            <Typography component={"span"} key={index}>
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                               children={text}/>
                            </Typography>
                        )
                    );
                });
            }

            if (texts.length > 0) {
                return (<ExpandTextPanel
                    textSummary={intl.formatMessage({
                        id: 'label.hp.clinical_recommendations',
                        defaultMessage: 'Clinical recommendations(AHA/ACC и ESC Guidelines)'
                    })}
                    textSecondary={intl.formatMessage({
                        id: 'label.hp.origin',
                        defaultMessage: 'Origin: 2018 ESC/ESH Clinical Practice Guidelines for the Management of Arterial Hypertension; 2017 "ACC/AHA/AAPA/ABC/ACPM/AGS/APhA/ASH/ASPC/NMA/PCNA Guideline for the Prevention, Detection, Evaluation and Management of High Blood Pressure in Adults"'
                    })}>
                    {texts.map((txt, id) => (
                        <Box key={id}>
                            {txt}
                        </Box>
                    ))}
                </ExpandTextPanel>);
            } else {
                return (<Box/>);
            }
        };

        getHelpDrugsTextsView = () => {
            const { classes } = this.props;
            return (
                <Grid item xs={12}>
                    <div>
                        <Typography className={classes.drugRecommend} variant='caption'>
                            <FormattedMessage id="label.drug.recommend_short" defaultMessage="Drug recommend"/>
                        </Typography>
                    </div>
                    <div>
                        <Typography className={classes.drugWarning} variant='caption'>
                            <FormattedMessage id="label.drug.warning" defaultMessage="Drug warning"/>
                        </Typography>
                    </div>
                    <div>
                        <Typography className={classes.drugDanger} variant='caption'>
                            <FormattedMessage id="label.drug.danger" defaultMessage="Drug danger"/>
                        </Typography>
                    </div>
                </Grid>
            );
        };

        render() {
            return (<WrapComp
                {...this.props}
                getRecomentTextsView={this.getRecomentTextsView}
                getHelpDrugsTextsView={this.getHelpDrugsTextsView}
            />)
        }

    }
}

export const IbsResultView = compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(createDyslipidemiaBaselineComp(IbsResult));

export const IbsSelectTherapyView = compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(createDyslipidemiaBaselineComp(IbsSelectTherapy));

export const IbsSelectDrugsView = compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(createDyslipidemiaBaselineComp(IbsSelectDrugs));

export const IbsSelectAllView = compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(createDyslipidemiaBaselineComp(IbsSelectAll));
