import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import mainStyles from "../../styles/mainStyles";
import {getContStatusStyle} from "../../utils";
import {NosologyType} from "../../const";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    priority: {
        fontWeight: 500,
    },
}));

const HPCombinationView = (props) => {
    const classes = useStyles();
    const combination = props.combination;
    const subCombinations = combination.combination;

    return (
        subCombinations
            .sort((subCombination1, subCombination2) => subCombination1.shortName.localeCompare(subCombination2.shortName))
            .map((subCombination, index) => {
                return (
                    <Typography key={index} component={'span'}
                                className={`${combination.isPriority ? classes.priority : ''} ${getContStatusStyle(subCombination.contStatus, classes, NosologyType.HYPERTENSION)}`}>
                        {subCombination.shortName}
                    </Typography>
                )
            })
            // .reduce( ( prev, curr ) => [ prev, ' + ', curr ] )
            .reduce((prev, curr) => [
                prev,
                <Typography key={new Date().getTime()} component={'span'} className={classes.plus}>
                    {' + '}
                </Typography>,
                curr
            ])
    );
}

export default HPCombinationView;
