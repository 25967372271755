import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import { withApollo } from '@apollo/react-hoc';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import mainStyles from "../../styles/mainStyles";
import {
  getArticlesWithIds,
} from '../../query/article';
import ArticleItem from '../article/ArticleItem';
import {getQueryResult} from "../../utils";
import config from "../../config";

const styles = theme => ({
  ...mainStyles(theme),
});

class ArticlesListEx extends React.Component {

  abortController = new AbortController();

  constructor(props) {
    super(props);

    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    this.loadArticles();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.articleIds) !== JSON.stringify(this.props.articleIds)) {
      this.loadArticles();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  loadArticles = () => {
    const { articleIds, client } = this.props;
    const { signal } = this.abortController;

    if (articleIds.length === 0) {
      return;
    }

    client.query({
      query: getArticlesWithIds,
      variables: {
        ids: articleIds,
      },
      context: {
        uri: config.options.server.api_url + config.options.server.api_uri,
        fetchOptions: {
          signal,
        },
      },
    })
        // .then(result => (result.data || {}).getArticlesWithIds)
        .then(result => getQueryResult(result?.data, 'getArticlesWithIds'))
        .then(articles => {
          this.setState({
            articles: articles,
          });
        });
  };

  render() {
    const { articles } = this.state;

    return (
      <List component="nav">
        {articles && articles.map((article) => {
          return (
            <ArticleItem key={article.id} article={article} />
          );
        })}
      </List>
    );
  }
}

ArticlesListEx.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  articleIds: PropTypes.arrayOf(PropTypes.number),
};

ArticlesListEx.defaultProps = {
  articleIds: [],
};

export default compose(
  withStyles(styles),
  withApollo,
)(ArticlesListEx);
