import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import RootContainer from '../comp/RootContainer';
import mainStyles from '../styles/mainStyles';
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListEmpty from "../comp/ListEmpty";
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import InviteStateIcon from '@material-ui/icons/CheckCircle';
import { withApollo } from '@apollo/client/react/hoc';
import {
    addInvite,
    deleteInvite,
    getInvites, loadGetRegProfiles,
    resendInvite,
} from '../query/registration';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InviteAddDialog from "../comp/invite/InviteAddDialog";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FormattedMessage, injectIntl } from 'react-intl';
import {getQueryResult} from "../utils";
import {Grid, IconButton, Tooltip} from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import red from "@material-ui/core/colors/red";
import ConfirmDialog from "../comp/patient/ConfirmDialog";
import config from "../config";

const styles = theme => ({
    ...mainStyles(theme),
    deleteButton: {
        color: red[700],
        borderColor: red[700],
        marginLeft: '8px',

        '&:hover': {
            backgroundColor: red[50],
        }
    },
    actionsRow: {
        marginTop: '8px'
    },
    actionRowItem: {
        marginRight: '8px',
    },
});

class Invite extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            invites: [],
            isShowDialogInvite: false,

            openConfirmDialog: false,
            titleConfirmDialog: 'Заголовок диалога',
            textConfirmDialog: 'Текст диалога',
            buttonYes: 'Да',
            buttonNo: 'Нет',
            onConfirmDialog: () => {
                console.error('onConfirmDialog')
            },
        };
    }

    componentDidMount() {
        this.loadInvites();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadInvites = () => {
        const { client } = this.props;
        const { signal } = this.abortController;

        client.query({
            query: getInvites,
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'getInvites'))
            .then(invites => {
                (invites || []).forEach((invite) => {
                    invite.resendInvite = true;
                    invite.deleteInvite = true;
                });

                loadGetRegProfiles(client, signal)
                    .then((regProfiles) => {
                        this.setState({
                            invites: invites,
                            regProfiles: regProfiles || [],
                        });
                    });
            });
    };

    onDialogClose = () => {
        this.setState({
            isShowDialogInvite: false,
        });
    };

    onInviteAdd = () => {
        this.setState({
            isShowDialogInvite: true,
        });
    };

    copyToClipboard = (link) => {
        const { intl } = this.props;
        const tempInput = document.createElement('input');
        tempInput.value = link;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.showNotify(intl.formatMessage({id: 'message.invite.coped_to_clipboard', defaultMessage: 'Coped to clipboard'}, 'success'));
    };

    resendInvite = (invite) => {
        const { client } = this.props;
        const { signal } = this.abortController;

        invite.resendInvite = false;

        client.query({
            query: resendInvite,
            variables: {
                regCodeId: invite.regCode.id,
            },
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'resendInvite'))
            .then(resendInviteResponse => {
                invite.resendInvite = resendInviteResponse;
            });
    };

    setConfirmOpen = (open: boolean, titleConfirmDialog: string, textConfirmDialog: string, onConfirmDialog, invite) => {
        const {intl} = this.props;
        let sTitleConfirmDialog = '';
        let sTextConfirmDialog = '';
        if (open) {
            sTitleConfirmDialog = intl.formatMessage({id: titleConfirmDialog, defaultMessage: 'Title'});
            sTextConfirmDialog = intl.formatMessage({id: textConfirmDialog}, {invite: invite.regCode.username}, {defaultMessage: 'Text'});
        }
        this.setState({
            openConfirmDialog: open,
            titleConfirmDialog: sTitleConfirmDialog,
            textConfirmDialog: sTextConfirmDialog,
            buttonYes: intl.formatMessage({id: 'label.dialog.confirm.button.yes', defaultMessage: 'Yes'}),
            buttonNo: intl.formatMessage({id: 'label.dialog.confirm.button.no', defaultMessage: 'No'}),
            onConfirmDialog: onConfirmDialog,
        });
    }

    deleteInvite = (invite) => {
        const { client, intl } = this.props;
        const { signal } = this.abortController;

        invite.deleteInvite = false;

        client.mutate({
            mutation: deleteInvite,
            errorPolicy: 'all',
            variables: {
                id: invite.id,
            },
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        }).then(({ data, _ }) => {
            if (!!(data || {}).deleteInvite) {
                this.showNotify(intl.formatMessage({id: 'message.invite.deleted'}, {email: invite.regCode.username}), 'success');
                this.loadInvites();
            } else {
                this.showNotify(intl.formatMessage({id: 'message.invite.deleting_error'}, {email: invite.regCode.username}), 'error');
                invite.deleteInvite = true;
            }
        });
    };

    onDeleteInvite = (invite) => {
        this.setConfirmOpen(
            true,
            'label.dialog.confirm.invite.delete.title',
            'label.dialog.confirm.invite.delete.text',
            () => {this.deleteInvite(invite);},
            invite
        );
    }

    onInviteSubmit = ({ email, role, notifyByEmail, regProfileId }) => {
        const { client, intl } = this.props;
        const { signal } = this.abortController;

        client.mutate({
            mutation: addInvite,
            errorPolicy: 'all',
            variables: {
                invite: {
                    email: email,
                    role: role,
                    notifyByEmail: notifyByEmail,
                    regProfileId: regProfileId,
                }
            },
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        }).then(({ data, _ }) => {
            if (data && data.addInvite) {
                this.showNotify(intl.formatMessage({id: 'message.invite.sended'}, {email: email}), 'success');
                this.loadInvites();
            }
        });

        this.setState({
            isShowDialogInvite: false,
        });
    };

    inviteIconStatus = (invite) => {
        const { classes } = this.props;

        if (invite.regCode.isActive) {
            return <InviteStateIcon classes={{ colorPrimary: classes.iconSilver }} color="primary" />;
        } else {
            return <InviteStateIcon classes={{ colorPrimary: classes.iconGreen }} color="primary" />;
        }
    };

    showNotify = (message, type) => {
        const { showNotify } = this.context;
        if (showNotify) {
            showNotify(message, type);
        }
    };

    render() {
        const { classes, intl } = this.props;
        const {
            invites,
            isShowDialogInvite,
            regProfiles,

            openConfirmDialog,
            titleConfirmDialog,
            textConfirmDialog,
            buttonYes,
            buttonNo,
            onConfirmDialog,
        } = this.state;

        return (
            <RootContainer>
                <Paper>
                    <Toolbar>
                        <Typography variant={"h6"} color={"inherit"}>
                            <FormattedMessage id='label.invites' defaultMessage='Invites' />
                        </Typography>
                        <div className={classes.grow} />
                        <Button color={"primary"} onClick={this.loadInvites}>
                            <RefreshIcon className={classes.iconButton} />
                            <FormattedMessage id='label.refresh' defaultMessage='Refresh' />
                        </Button>
                        <Button color={"primary"} onClick={this.onInviteAdd}>
                            <AddIcon className={classes.iconButton} />
                            <FormattedMessage id='label.invite' defaultMessage='Invite' />
                        </Button>
                    </Toolbar>
                    {invites && invites.length > 0
                        ?
                        <List>
                            {invites.map(invite => (
                                <ListItem key={invite.id}>
                                    <ListItemIcon>
                                        {this.inviteIconStatus(invite)}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography component="span" variant="h6">{invite.regCode.username}</Typography>}
                                        secondary={
                                            <React.Fragment>
                                                <Typography component="span" variant="body1">{invite.role.name}</Typography>
                                                &nbsp;
                                                <Typography component="span" variant="body2" color="textSecondary">{invite.createAt}</Typography>
                                                {invite.regCode.isActive &&
                                                    <React.Fragment>
                                                        <Grid component={'span'} container direction={"row"} alignItems={"center"} className={classes.actionsRow}>
                                                            <Grid component={'span'} item className={classes.actionRowItem}>
                                                                {/*<Typography component="span" variant="body1"><FormattedMessage id='label.invite_link' defaultMessage='Invite link' /></Typography>*/}
                                                                <Typography component="span" variant="body2" color="textSecondary">{invite.regCode.regLink || ''}</Typography>
                                                            </Grid>
                                                            <Grid component={'span'} item className={classes.actionRowItem}>
                                                                <Tooltip title={intl.formatMessage({id: 'message.invite.copy_to_clipboard', defaultMessage: 'Copy to clipboard'})}>
                                                                    <IconButton aria-label="copy" color={"primary"} onClick={() => this.copyToClipboard(invite.regCode.regLink)} disableRipple={true} style={{padding: 0}}>
                                                                        <FileCopyIcon fontSize="small" />
                                                                        {/*<FormattedMessage id='label.copy_to_clipboard' defaultMessage='Copy to clipboard' />*/}
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid component={'span'} container direction={"row"} alignItems={"center"} className={classes.actionsRow}>
                                                            <Grid component={'span'} item>
                                                                <Button size={"small"} color={"primary"} onClick={() => this.resendInvite(invite)} disabled={!invite.resendInvite} variant={"outlined"}>
                                                                    <FormattedMessage id='label.resend' defaultMessage='Send invite' />
                                                                </Button>
                                                            </Grid>
                                                            <Grid component={'span'} item>
                                                                <Button size={"small"} className={classes.deleteButton} onClick={() => this.onDeleteInvite(invite)} disabled={!invite.deleteInvite} variant={"outlined"}>
                                                                    <FormattedMessage id='label.delete' defaultMessage='Delete' />
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                        :
                        <ListEmpty text={intl.formatMessage({id: 'label.invites.empty', defaultMessage: 'Not invites'})} />
                    }
                </Paper>
                <InviteAddDialog
                    onDialogClose={this.onDialogClose}
                    onSubmit={this.onInviteSubmit}
                    isShow={isShowDialogInvite}
                    regProfiles={regProfiles || []}
                />
                <ConfirmDialog
                    title={titleConfirmDialog}
                    text={textConfirmDialog}
                    buttonYes={buttonYes}
                    buttonNo={buttonNo}
                    open={openConfirmDialog}
                    setOpen={this.setConfirmOpen}
                    onConfirm={onConfirmDialog}
                />
            </RootContainer>
        );
    }
}

Invite.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

Invite.contextTypes = {
    showNotify: PropTypes.func,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(Invite);
