import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {
  Typography, Box, Grid,
} from '@material-ui/core';
import mainStyles from '../../styles/mainStyles';
import LangTabs from '../LangTabs';

const styles = theme => ({
  ...mainStyles(theme),
  abstractText: {
    marginTop: theme.spacing(2),
  },
});

class ArticleAbstract extends React.Component {

  constructor(props) {
    super(props);

    this.state = { };
  }
  
  componentDidMount() {
    this.setState({
      abstract: this.buildAbstract(this.props.abstracts),
    });
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.abstracts !== prevProps.abstracts) {
      this.setState({
        abstract: this.buildAbstract(this.props.abstracts),
      });
    }
  }

  buildAbstract = (abstract) => {
    const trans = abstract
      .map(it => it.translate)
      .flatMap(it => it)
      .reduce((acc, value) => {
        if (acc[value.lang]) {
          acc[value.lang].push(value.text);
        } else {
          acc[value.lang] = [value.text];
        }
        return acc;
      }, {})

    const langs = [];

    Object.keys(trans).forEach(lang => {
      langs.push({
        lang: lang,
        abstracts: trans[lang],
      });
    });

    return langs;
  }

  abstractsAscomp = (abstracts) => {
    const { classes } = this.props;

    return <Grid container className={classes.abstractText} spacing={2}>
      {abstracts.map(((abtr, i) => (
        <Grid item xs={12} key={i}>
          <Typography
            variant="body2"
            color='textPrimary'
            className={classes.abstractText}
            component='span'>
            {abtr}
          </Typography>
        </Grid>
      )))}
    </Grid>;
  };

  onLangChange = (selLang) => {
    this.setState({
      selLang: selLang,
    })
  };

  render() {
    const { abstract, selLang } = this.state;

    return (<Grid container spacing={2}>
      {Boolean(abstract) && abstract.length > 1 && (
        <Box>
          <Grid item xs={12}>
            <LangTabs
              tabs={abstract.map(a => a.lang)}
              onChange={this.onLangChange}
            />
          </Grid>
          <Grid item xs={12}>
            {this.abstractsAscomp(abstract[Boolean(selLang) ? selLang : 0].abstracts)}
          </Grid>
        </Box>
      )}
    </Grid>);
  }
}

ArticleAbstract.propTypes = {
  classes: PropTypes.object.isRequired,
  abstracts: PropTypes.array.isRequired,
};

export default compose(
  withStyles(styles),
)(ArticleAbstract);
