import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    Container,
    Paper,
    Typography,
    Grid,
} from "@material-ui/core";
import PanelDivider from "../PanelDivider";
import SelTextView from "../SelTextView";
import ReactMarkdown from "react-markdown";
import {getHPDrugTexts} from '../../query/HPBaseline';
import {FormattedMessage} from "react-intl";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {NosologyType, RecommendType} from "../../const";
import ComparisonsView from "../ComparisonsView";
import {DrugResultRow} from "../drug/DrugResultRow";
import SelTreatView from "../baseline/SelTreatView";
import PaperColor from "../PaperColor";
import {capitalizeFirstLetter, clearMarkDownText, getQueryResult} from "../../utils";
import type {TherapyCompareDto} from "../../const";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import config from "../../config";

const styles = theme => ({
    ...mainStyles(theme),
    device: {
        fontSize: '14px',
    },
});

class BreastCancerResult extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const {showComparisons} = this.props;
        if (!showComparisons) {
            this.setTherapyCompare();
        }
        this.loadResultTexts();
        this.loadDrugs();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadDrugs = () => {
/*
        const {client, getSteps} = this.props;
        const {signal} = this.abortController;

        const steps = getSteps();
        const drugs = Object.values(steps.selDrugs || {}).filter((drug) => drug.id !== -1);


        const drugsNames: string[] = [];
        const paramsDrugs = [];
        if (drugs && drugs.length > 0) {
            drugs
                .forEach(drug => {
                    drugsNames.push(drug.name);
                    paramsDrugs.push(
                        {
                            groupName: drug.groupName,
                            drugs: [
                                {
                                    drugName: drug.name,
                                }
                            ],
                        }
                    );
                });
        }

        loadDrugsByDrugDosageNames(client, signal, drugsNames)
            .then((drugsByDrugDosageNames) => {
                this.setState({
                    paramsDrugs: extendParamsDrugByTradeItems(paramsDrugs, drugsByDrugDosageNames),
                });
            });
*/
    }

    loadResultTexts = () => {
/*
        const {client, getSelectVars, getConclusionTexts, setConclusionTexts} = this.props;
        const {signal} = this.abortController;

        const conclusionTexts = getConclusionTexts();

        if (!conclusionTexts) {
            client.query({
                query: getRecommendConclusionTexts,
                variables: {
                    ids: getSelectVars(),
                },
                context: {
                    uri: options.server.heart_failure_url + options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                .then(({data, _}) => {
                    const conclusionTexts = getQueryResult(data, 'getRecommendConclusionTexts');
                    // if (data && data.getRecommendConclusionTexts) {
                    //     conclusionTexts = JSON.parse(JSON.stringify(data.getRecommendConclusionTexts));
                    // }
                    this.setState({
                        conclusionTexts: conclusionTexts,
                    });
                    setConclusionTexts(conclusionTexts);
                });
        } else {
            this.setState({
                conclusionTexts: conclusionTexts,
            });
        }
*/
    };

    loadDrugTexts = () => {
        const {client, getTherapyGroupDrugs} = this.props;
        const {signal} = this.abortController;
        const selThDrugs = getTherapyGroupDrugs();

        if (selThDrugs) {
            client.query({
                query: getHPDrugTexts,
                variables: {
                    drugIds: selThDrugs.items.map(it => it.selDrugId),
                },
                context: {
                    uri: config.options.server.api_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                // .then(result => (result.data || {}).getHPDrugTexts)
                // .then(result => JSON.parse(JSON.stringify(result)))
                .then(result => getQueryResult(result?.data, 'getHPDrugTexts'))
                .then(drugTexts => {
                    this.setState({
                        drugTexts: drugTexts,
                    });
                });
        }
    };

    paramsView = () => {
        const {
            classes,
            nosologyData,
        } = this.props;
        const {paramsDrugs} = this.state;

        const scales = nosologyData?.subgroups[0]?.scales;

        let params = [];

        if (scales && scales.length > 0) {
            scales
                .forEach(scale => {
                    // params[ti.name] = ti.info.join(', ');
                    params.push(
                        {
                            name: scale.name,
                            value: scale.value,
                        });
                });
        }

        return (
            <Paper variant={"outlined"} className={classes.contentBox}>
                <Container maxWidth={"sm"}>
                    {params.map((param, id) => (
                        <Grid container direction={"row"} key={id} className={classes.resultParam}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamName}>{param.name}</Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamValue}>{param.value}</Grid>
                        </Grid>
                    ))}
                    {paramsDrugs && paramsDrugs.map((paramDrug, id) => (
                        paramDrug.drugs.map((drug, index2) => (
                            <DrugResultRow key={index2} row={{groupName: paramDrug.groupName, drugName: drug.drugName, tradeItems: drug.tradeItems}} classes={classes}>
                            </DrugResultRow>
                        ))
                    ))}

                    {this.textsView(RecommendType.GUIDELINES)}
                </Container>
            </Paper>
        );
    };

    textsView = (type: RecommendType) => {
        const {
            classes,
            getRecomendTexts,
        } = this.props;
        const recommendTexts = (getRecomendTexts() || []).filter((item) => item.type === type);

        return (
            <Box>
                {recommendTexts && recommendTexts
                    .map((recommendText, index) => (
                        <Box key={index}>
                            <PanelDivider/>
                            <Paper className={classes.contentBox} variant={"outlined"}>
                                <SelTextView align={"center"}>{recommendText.label}</SelTextView>
                                {/*<PanelDivider/>*/}
                                <Box>
                                    {/*<Typography color={"primary"}>*/}
                                    {/*{txt.label && <SelTextView variant={'body1'}>{txt.label}</SelTextView>}*/}
                                    {/*</Typography>*/}
                                    {recommendText.text && (
                                        <Typography component={"span"}>
                                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={clearMarkDownText(recommendText.text)}/>
                                        </Typography>
                                    )}
                                </Box>
                            </Paper>
                        </Box>
                    ))
                }

{/*
          <Box>
            <PanelDivider />
            <Paper className={classes.contentBox} variant={"outlined"}>
              <SelTextView align={"center"}>
                <FormattedMessage id='label.drugs' defaultMessage='Drugs' />
              </SelTextView>
              <PanelDivider />
                {Boolean(drugs) ?
                    drugs.map(drug => (
                <Box key={drug.id}>
                  <SelTreatView treatName={drug.name} />
                    <Box key={drug.id + drug.id}>
                      {drug.descr && (
                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={drug.descr} />
                      )}
                    </Box>
                </Box>
              ))
                :
                    <Box>
                        <Typography>
                            <FormattedMessage id={'label.bl.therapy_not_recoment'}
                                              defaultMessage={'Therapy is not recommend'}/>
                        </Typography>
                        <PanelDivider/>
                    </Box>
                }
            </Paper>
          </Box>
*/}




                {/*
        {resultTexts &&
        [4, 5, 6, 7]
          .map(secNum => resultTexts.find(it => it.id === secNum))
          .filter(secTxt => secTxt !== undefined && secTxt.texts.length > 0)
          .map(secTxt => (
            <Box key={secTxt.id}>
              <PanelDivider />
              <Paper className={classes.contentBox} variant={"outlined"}>
                <SelTextView align={"center"}>{secTxt.name}</SelTextView>
                <PanelDivider />
                {secTxt.texts.map((txt, tid) => (
                  <Box key={tid}>
                    {txt.label && <SelTextView>{txt.label}</SelTextView>}
                    {txt.text && (
                      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={txt.text} />
                    )}
                  </Box>
                ))}
              </Paper>
            </Box>
          ))
        }
*/}

            </Box>
        );
    };

    setTherapyCompare = (therapyCompare: TherapyCompareDto[]) => {
        const {
            setTherapyCompare,
            autoSave,
        } = this.props;

        setTherapyCompare(therapyCompare, () => {
            if (!!autoSave) {
                autoSave();
            }
        });
    }

    comparisonsView = () => {
        const {
            showComparisons,
            getTherapyPresumably,
            getSelectVars,
            getThRecommend,
            loadTherapyCompare,
            getTherapyCompare,
            getTouched,
        } = this.props;
        const therapyPresumably = getTherapyPresumably();

        return (
            showComparisons && therapyPresumably && therapyPresumably.thData &&
            <ComparisonsView
                getTherapyPresumably={getTherapyPresumably}
                getSelectVars={getSelectVars}
                nosology={NosologyType.HEART_FAILURE}
                getThRecommend={getThRecommend}
                loadTherapyCompare={loadTherapyCompare}
                getTherapyCompare={getTherapyCompare}
                setTherapyCompare={this.setTherapyCompare}
                getTouched={getTouched}
            />
        );
    };

    drugsView = () => {
        const {
            classes,
            nosologyData,
        } = this.props;

        let content;

        if (Boolean(nosologyData.selDrugGroup)) {
            content = (
                <Box>
                    <Typography variant={"h6"} align={"center"}>
                        {nosologyData.selDrugGroup.name}
                    </Typography>

                    {nosologyData.selDrugGroup.drugs.map((drug) => (
                        <Box key={drug.id}>
                            {drug.name && <SelTreatView treatName={capitalizeFirstLetter(drug.name)} />}
                        </Box>
                    ))}

                    {nosologyData.selDrugGroup.receptions.map((reception) => (
                        <Box key={reception.number}>
                            {reception.descr && (
                                <Typography component={'span'}>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={'' + clearMarkDownText(reception.descr)} />
                                </Typography>
                            )}
                        </Box>
                    ))}
                </Box>
            );
        }

        return (
            <Box>
                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    <Typography variant={"h6"} align={"center"}>
                        <FormattedMessage id={'label.hp.drugs.sel'} defaultMessage={'Selected drugs'}/>
                    </Typography>
                    <PanelDivider/>
                    {content}
                </PaperColor>
            </Box>
        );
    };


    render() {
        const {classes} = this.props;
        return (
            <Paper>
                <Box className={classes.contentBox}>
                    {this.paramsView()}
                    {this.comparisonsView()}

                    {this.drugsView()}

                    {this.textsView(RecommendType.CONCLUSION)}
                </Box>
            </Paper>
        );
    }
}

BreastCancerResult.propTypes = {
    classes: PropTypes.object.isRequired,
    getTherapyPresumably: PropTypes.func.isRequired,
    getTherapyCompare: PropTypes.func.isRequired,
    setTherapyCompare: PropTypes.func.isRequired,
    getTouched: PropTypes.func.isRequired,
    loadTherapyCompare: PropTypes.func.isRequired,
    showComparisons: PropTypes.bool,
    autoSave: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles),
)(BreastCancerResult);
