import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardActions, Grid, Hidden, ListItem, ListItemIcon, ListItemText, Menu} from "@material-ui/core";
import {FormattedMessage, injectIntl} from 'react-intl';
import CardContent from "@material-ui/core/CardContent";
import ReportIcon from '@material-ui/icons/Report';
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import { withApollo } from '@apollo/client/react/hoc';
import mainStyles from "../../styles/mainStyles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PatientDialog from "./PatientDialog";
import Box from "@material-ui/core/Box";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import {MUIDataTableOptions} from "mui-datatables";
import {
    savePatient,
    deleteAllPatientHistory,
    removePatientFromStaff,
    loadGetPatient,
    callAddNosologyToPatient,
    callRemoveNosologyFromPatient, callSavePatient
} from "../../query/patient";
import config from "../../config";
import {withRouter} from "react-router";
import ConfirmDialog from "./ConfirmDialog";
import {
    MessageType,
    NosologyInputSourceEnum,
    NosologyStatus,
    NosologyType,
    PatientInputDto,
    TableNameType
} from "../../const";
import {
    datatableDateRenderer,
    getAccess, getQueryResult, getURLSearchParams, getNextNosology,
    isBaselineReadonly,
    sortDate,
    sortString, getNosologyIcon, clearMarkDownText, patientDrugs, getTariffsLinkIntl, sortDateStringEpoch,
} from "../../utils";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import type {PatientExtDto, BaselineParametrSectionDto, StaffDto, PatientNosologyState} from "../../const";
import DatatableComponent from "../datatable/DatatableComponent";
import {grey} from "@material-ui/core/colors";
import BaselineEditorView from "../common/BaselineEditorView";
import Typography from "@material-ui/core/Typography";
import NosologyNameRenderer from "../button/NosologyNameRenderer";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import MoreIcon from "@material-ui/icons/MoreVert";
import EventIcon from '@material-ui/icons/Event';
import userStore from "../../service/user-service";

const PatientTabEnum = {
    DATA: 'DATA',
    NOSOLOGY: 'NOSOLOGY',
}

const styles = theme => ({
    ...mainStyles(theme),
    outdated: {
        // color: red[700],
    },
    outdatedDescription: {
        marginLeft: theme.spacing(1),
        fontSize: "0.8em",
        color: grey[700],
    },
    disabledLink: {
        pointerEvents: 'none',
        color: grey[700],
    },
    toolbarIconHide: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbarIconButton: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'inline-flex',
            // textAlign: 'left',
            // width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            textAlign: 'left',
            width: '100%',
        },
    },
    nosologyAction: {
        '@media (max-width:499.95px)': {
            justifyContent: 'flex-start !important',
            width: 'fit-content',
        },
    },
});

class Patient extends React.Component {

    state = {
        allDataLoaded: false,
        openConfirmDialog: false,
        titleConfirmDialog: 'Заголовок диалога',
        textConfirmDialog: 'Текст диалога',
        buttonYes: 'Да',
        buttonNo: 'Нет',
        onConfirmDialog: () => {
            console.error('onConfirmDialog')
        },
        openPatientDialog: false,
        openAddNosologiesDialog: false,
        patientTab: PatientTabEnum.NOSOLOGY,
        patientData: {},
        patientNosologyState: undefined,
    };

    abortController = new AbortController();

    staff: StaffDto;

    setOpenAddNosologiesDialog = (open: boolean) => {
        this.setState({openAddNosologiesDialog: open});
    }

    setOpenPatient = (open: boolean) => {
        this.onMenuToolbarClose();
        this.setState({openPatientDialog: open});
    }

    setConfirmOpen = (open: boolean, titleConfirmDialog: string, textConfirmDialog: string, onConfirmDialog, buttonOk = false) => {
        const {intl} = this.props;
        let sTitleConfirmDialog = '';
        let sTextConfirmDialog = '';
        if (open) {
            sTitleConfirmDialog = intl.formatMessage({id: titleConfirmDialog, defaultMessage: 'Title'});
            sTextConfirmDialog = intl.formatMessage({id: textConfirmDialog, defaultMessage: 'Text'});
        }
        this.setState({
            openConfirmDialog: open,
            titleConfirmDialog: sTitleConfirmDialog,
            textConfirmDialog: sTextConfirmDialog,
            buttonYes: buttonOk ? intl.formatMessage({id: 'label.dialog.confirm.button.ok', defaultMessage: 'Ok'}) : intl.formatMessage({id: 'label.dialog.confirm.button.yes', defaultMessage: 'Yes'}),
            buttonNo: !buttonOk ? intl.formatMessage({id: 'label.dialog.confirm.button.no', defaultMessage: 'No'}) : undefined,
            onConfirmDialog: onConfirmDialog,
        });
    }

    confirmDeletePatient = () => {
        const {client, patient} = this.props;
        const {signal} = this.abortController;

        client.mutate({
            mutation: removePatientFromStaff,
            errorPolicy: 'all',
            context: {
                uri: config.options.server.patient_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
            variables: {
                patientId: patient.id,
            },
        }).then(({data, _}) => {
            if (data && data.removePatientFromStaff) {
                this.onClose(true);
            }
        });
    }

    onPatientDelete = () => {
        this.onMenuToolbarClose();
        this.setConfirmOpen(true, 'label.dialog.confirm.patient.delete.title', 'label.dialog.confirm.patient.delete.text', this.confirmDeletePatient);
    }

    onPatientNoNextNosology = () => {
        this.setConfirmOpen(true, 'label.dialog.confirm.patient.no_nosologies.title', 'label.dialog.confirm.patient.no_nosologies.text', null, true);
    }

    constructor(props) {
        super(props);
        const {intl} = props;

        this.state = {
            allDataLoaded: false,
            // fields: new Map([
            //     ['name', ''],
            //     // ['role', ''],
            // ]),
            errors: new Map(),
            openConfirmDialog: false,
            titleConfirmDialog: 'Заголовок диалога',
            textConfirmDialog: 'Текст диалога',
            buttonYes: 'Да',
            buttonNo: 'Нет',
            onConfirmDialog: () => {
                console.error('onConfirmDialog')
            },
            openPatientDialog: false,
            openAddNosologiesDialog: false,
            patientTab: PatientTabEnum.NOSOLOGY,
            patientData: {},
            patientNosologyState: undefined,
        };

        // let $this = this;
        this.validate = {
            'name': (value) => {
                let errors = this.state.errors;


                if (value.length < 5/* || !validator.isEmail(value)*/) {
                    errors.set('name', intl.formatMessage({
                        id: 'err.form.email.invalid',
                        defaultMessage: 'Invalid email'
                    }));
                } else {
                    errors.delete('name');
                }

                return errors;
            },
            // 'role': (value) => {
            //   let errors = $this.state.errors;

            //   if (isNumber(value)) {
            //     errors.delete('role');
            //   } else {
            //     errors.set('role', intl.formatMessage({id: 'err.role.not_set', defaultMessage: 'Role not set'}));
            //   }

            //   return errors;
            // },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadPatientData();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    getPatientTab = (patientData: PatientExtDto): PatientTabEnum => {
        const {history} = this.props;
        const queryParams = new URLSearchParams(history.location.search);
        if (queryParams.get('activeTab')) {
            queryParams.delete('activeTab');
            history.replace({
                search: queryParams.toString()
            });
            return PatientTabEnum.NOSOLOGY;
        }

        if ((patientData.nosologyStates  || []).length > 0) {
            return PatientTabEnum.DATA;
        }
        return PatientTabEnum.DATA;
    }

    loadPatientData = (patientData = undefined, openNosologyId = null) => {
        const {patient} = this.props;
        const {setPatientDataContext} = this.context;

        setPatientDataContext({
            patientDataContext: null
        }, () => {
            setPatientDataContext({
                patientDataContext: patientData || patient
            }, () => {
                this.setState({
                        errors: new Map(),
                        patientData: patientData || patient,
                        rerenderPatientData: new Date().getTime(),
                    }, () => {
                        const {patientData} = this.state;
                        this.setState({
                            allDataLoaded: true,
                            patientTab: this.getPatientTab(patientData),
                        }, () => {
                            if (openNosologyId) {
                                const openNosology = ((patientData || {}).nosologyStates || []).find((nosology) => nosology.id === openNosologyId);
                                this.onOpenNosology(openNosology);
                            }
                        });
                    }
                );
            });
        });
    };

    onClose = (reload: boolean = false) => {
        const {onClose} = this.props;
        // this.clearState();
        onClose(reload);
    };

    clearState = () => {
        this.setState({
            // fields: new Map([
            //     ['name', ''],
            //     // ['role', ''],
            // ]),
            errors: new Map(),
        });
    };

    onPatientSubmit = ({name}, selectedNosologies, callback) => {
        const {client} = this.props;
        const {contextPatient, patientDataContext} = this.context;
        const {signal} = this.abortController;
        // console.error('+++ onPatientSubmit() +++ name:', name);
        // console.error('+++ onPatientSubmit() +++ selectedNosologies:', selectedNosologies);
        // console.error('+++ onPatientSubmit() +++ callback:', callback);
        // console.error('+++ contextPatient:', contextPatient);
        // console.error('+++ patientDataContext:', patientDataContext);


        const patientOld = (contextPatient || {}).patient || (patientDataContext || {}).patientDataContext;
        const patientInputDto: PatientInputDto = {
            name: name,
            uuid: (patientOld || {}).uuid,
            type: (patientOld || {}).type,
            parameters: patientOld.parameters.map((parameter) => {
                return {
                    fieldId: parameter.fieldId,
                    value: parameter.value,
                    type: parameter.type,
                    noData: parameter.noData,
                    valueDefault: parameter.valueDefault,
                    modified: parameter.modified,
                    updateDate: parameter.updateDate,
                };
            }),
            nosologies: patientOld.nosologies,
            tag: (patientOld.tags[patientOld.tags.length - 1] || {}).name || '',
            notes: patientOld.notes.map((note) => {
                return {
                    id: note.id,
                    type: note.type,
                    payload: note.payload,
                };
            }),
            compositeIds: patientOld.compositeIds,
        };

        selectedNosologies.forEach((selectedNosology) => {
            if (!patientInputDto.nosologies.find((nosology) => nosology.id === selectedNosology.id)) {
                patientInputDto.nosologies.push({
                    id: selectedNosology.id,
                    sourceDetection: [NosologyInputSourceEnum.MANUAL],
                });
            }
        });

        const {setContextPatient} = this.context;

        callSavePatient(client, signal, patientInputDto).then((patientResponse) => {
            let response = true;
            if (!!patientResponse) {
                if (setContextPatient !== undefined) {
                    if (Boolean(name)) {
                        this.loadPatient(client, signal, patientResponse.id, patientResponse.uuid, null, () => callback(response));
                    } else {
                        setContextPatient(null);
                    }
                }
            } else {
                response = false;
            }

            if (!!callback) {
                callback(response);
            }
        });
    };

    onOpenNosology = (value, tableMeta, openHistory = false) => {
        const {patientData} = this.state;
        const {history, patient} = this.props;
        const activePatientNosology = tableMeta ? (patientData.nosologyStates || []).find((nosology) => nosology.id === tableMeta.rowData[0]) : value;

        const activePatient = getURLSearchParams(this.props.location.search);

        if (activePatientNosology) {
            let query = `?patient=${patient.id}&patientType=${patient.type}&patientUuid=${patient.uuid}&patientName=${patient.visualName}&nosology=${activePatientNosology.id}${openHistory && activePatientNosology.history ? `&history=${activePatientNosology.history.id}` : ``}${value === 'RESULT' ? `&action=RESULT` : ``}${activePatient.group ? `&group=${activePatient.group}` : ``}`;
            let route = '';
            switch (activePatientNosology.id) {
                case NosologyType.HYPERTENSION:
                    route = '/hyper';
                    break;
                case NosologyType.ATRIAL_FIBRILLATION:
                    route = '/baseline';
                    break;
                case NosologyType.HEART_FAILURE:
                    route = '/heartfailure';
                    break;
                case NosologyType.BREAST_CANCER:
                    route = '/breastcancer';
                    break;
                case NosologyType.DYSLIPIDEMIA:
                    route = '/dyslipidemia';
                    break;
                case NosologyType.IBS:
                    route = '/ibs';
                    break;
                default:
                    break;
            }
            if (route) {
                history.push(route + query);
            }
        }
    }

    onDeleteNosology = (value, tableMeta) => {
        const {patientData} = this.state;
        const activePatientNosology = tableMeta ? (patientData.nosologyStates || []).find((nosology) => nosology.id === tableMeta.rowData[0]) : value;

        this.setConfirmOpen(
            true,
            'label.dialog.confirm.nosology.delete.title',
            'label.dialog.confirm.nosology.delete.text',
            () => {
                this.confirmDeleteNosologyHistory(activePatientNosology.id);
            }
        );
    }

    confirmDeleteNosologyHistory = (nosologyId) => {
        const {client, patient} = this.props;
        const {signal} = this.abortController;

        client.mutate({
            mutation: deleteAllPatientHistory,
            errorPolicy: 'all',
            context: {
                uri: config.options.server.patient_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
            variables: {
                patientId: patient.id,
                nosologyId: nosologyId,
            },
        }).then(({data, _}) => {
            if (data && data.deleteAllPatientHistory !== null && data.deleteAllPatientHistory !== undefined) {
                this.loadPatient(client, signal, patient.id, patient.uuid, nosologyId);
            }
        });
    }

    loadPatient = (client, signal, patientId, patientUuid, openNosologyId = null, callback) => {
        const {
            setContextPatient,
        } = this.context;
        loadGetPatient(client, signal, patientId, patientUuid)
            .then((patient) => {
                setContextPatient(
                    {
                        patient: patient,
                    }
                );

                this.loadPatientData(patient, openNosologyId);

                if (callback) {
                    callback(patient);
                }
            });
    }

    getNosologyName = (value, tableMeta, updateValue) => {
        const {patientData} = this.state;
        const activePatientNosology = tableMeta ? (patientData.nosologyStates || []).find((nosology) => nosology.id === tableMeta.rowData[0]) : value;

        let name = '';
        if (activePatientNosology) {
            const {contextStaff} = this.context;
            for (let contextStaffNosology of contextStaff.nosologies) {
                if (contextStaffNosology.id === activePatientNosology.id) {
                    name = contextStaffNosology.name;
                    break;
                }
            }
        }
        return name;
    }

    nosologyNameRenderer = (value, tableMeta, updateValue) => {
        const {classes, intl} = this.props;
        const {patientData} = this.state;
        const nosology: PatientNosologyState = tableMeta ? (patientData.nosologyStates || []).find((nosology) => nosology.id === tableMeta.rowData[0]) : value;
        const nosologyName = this.getNosologyName(nosology, undefined, updateValue);

        const link =
            <NosologyNameRenderer
                className={`${nosology.outdated ? classes.outdated : ''} ${nosology.status === NosologyStatus.NOT_AVAILABLE ? classes.disabledLink : ''}`}
                onOpenNosology={() => this.onOpenNosology(value, tableMeta, false)}
                nosologyName={nosologyName}
                nosologyIcon={getNosologyIcon(nosology, undefined, classes)}
                nosology={nosology}
                onDeleteNosology={() => this.onDeleteNosology(value, tableMeta)}
                intl={intl}
            />
        ;

        return (
            <React.Fragment>
                {/*<Tooltip title={"Перейти"}>*/}
                {/*  <IconButton style={{padding: 0}} size="small" color="primary" aria-label="open user" component="span" onClick={() => this.onOpenNosology(value, tableMeta)}>*/}
                {/*    <ChevronRightRoundedIcon />*/}
                {/*  </IconButton>*/}
                {/*</Tooltip>*/}
                {/*{value}*/}

                {nosology.status === NosologyStatus.NOT_AVAILABLE ?
                    // <Tooltip title={nosology.statusText || 'Текст ошибки'}>
                        <Grid container direction={'row'} spacing={1}>
                            <Grid item>
                                <ReportIcon color={'error'} />
                            </Grid>
                            <Grid item>
                                <Grid container direction={'column'} style={{fontSize: '14px'}}>
                                    <Grid item >
                                        {link}
                                    </Grid>
                                    {nosology.messages && nosology.messages.filter((message) => message.type === MessageType.DANGER).map((message, index) =>
                                        <Grid item key={index} style={{maxWidth: '400px'}}>
                                            <Typography color={"textSecondary"} variant="body2" component={"span"}>
                                                {message.payload ?
                                                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={clearMarkDownText(message.payload)}/>
                                                    :
                                                    <FormattedMessage
                                                        id='label.patient.nosology.some_error'
                                                        defaultMessage='Not all data for nosology is complete'
                                                    />
                                                }
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    // </Tooltip>
                    :
                    link
                }
                {/* TODO: temporary
                {nosology.outdated &&
                    <Box component={'span'} className={classes.outdatedDescription}>
                        {intl.formatMessage({id: 'label.patient.nosology.outdated', defaultMessage: 'Data for nosology updated'})}
                    </Box>
                }
                */}
            </React.Fragment>
        );
    }

    nosologyIdRenderer = (value, tableMeta, updateValue) => {
        return (value || {}).id;
    }

    nosologyModifyRenderer = (value, tableMeta, updateValue) => {
        const {classes} = this.props;
        const {patientData} = this.state;
        const modifyNumber = !!(value || {}).modify ? Number((value || {}).modify) * 1000 : undefined;
        const modify = datatableDateRenderer(modifyNumber, tableMeta, updateValue);

        const nosology = tableMeta ? (patientData.nosologyStates || []).find((nosology) => nosology.id === tableMeta.rowData[0]) : value;

        return (
            <React.Fragment>
                {/*<Link*/}
                {/*    component="button"*/}
                {/*    variant="body2"*/}
                {/*    onClick={() => this.onOpenNosology(value, tableMeta, true)}*/}
                {/*>*/}
                    <ListItem
                        // alignItems="flex-start"
                        // className={classes.nosology}
                        onClick={() => this.onOpenNosology(value, tableMeta, ((((config.options.PATIENT || {}).NOSOLOGIES || {}).COLUMNS || {}).HISTORY || {}).enable)}
                        className={`${classes.primaryText} ${classes.pl0} ${(false && nosology.id === NosologyType.IBS) && classes.disabled}`}
                    >
                        {modify &&
                        <ListItemIcon style={{minWidth: '32px', verticalAlign: 'middle'}}>
                            <EventIcon
                                color={'primary'}
                            />
                        </ListItemIcon>
                        }
                        <ListItemText
                            className={classes.textSize14px}
                            // className={`${classes.linkHead} ${classes.contentBoxProfileText}`}
                            primary={modify}
                        />
                    </ListItem>
                    {/*{modify}*/}
                {/*</Link>*/}
            </React.Fragment>
        );
    }

    getBaselineDataAndOutdated = (value, tableMeta): { baselineData: BaselineParametrSectionDto; outdated: boolean } => {
        const {patientData} = this.state;
        const baselineData = tableMeta ? patientData.baseline.find((item) => item.id === tableMeta.rowData[0]) : value;
        const outdated = (patientData.nosologyStates || []).some((nosology) => (nosology.fieldValues || []).some((fieldValue) => fieldValue.idField === baselineData.id));

        return {
            baselineData: baselineData,
            outdated: outdated,
        }
    }

    getPatientDataName = (value, tableMeta, updateValue) => {
        return value ? value : '';
    }

    patientDataNameRenderer = (value, tableMeta, updateValue) => {
        const {classes} = this.props;
        const data = this.getBaselineDataAndOutdated(value, tableMeta);
        return (
            <Box component={'span'} className={data.outdated ? classes.outdated : ''}>
                {this.getPatientDataName(value, tableMeta, updateValue)}
            </Box>
        );
    }

    getPatientDataValue = (value, tableMeta, updateValue) => {
        const data = this.getBaselineDataAndOutdated(value, tableMeta);
        const baselineDataValue = data.baselineData.values.filter(value => value.id === data.baselineData.value)[0];
        return (
            baselineDataValue ? ((baselineDataValue || {}).name || '') : ''
        );
    }

    patientDataValueRenderer = (value, tableMeta, updateValue) => {
        const {classes} = this.props;
        const data = this.getBaselineDataAndOutdated(value, tableMeta);
        return (
            <Box component={'span'} className={data.outdated ? classes.outdated : ''}>
                {this.getPatientDataValue(value, tableMeta, updateValue)}
            </Box>
        );
    }

    patientDataUpdateDateRenderer = (value, tableMeta, updateValue) => {
        const {classes} = this.props;
        const data = this.getBaselineDataAndOutdated(value, tableMeta);
        return (
            <Box component={'span'} className={data.outdated ? classes.outdated : ''}>
                {datatableDateRenderer(data.baselineData.updateDate, tableMeta, updateValue)}
            </Box>
        );
    }

    nozologyActionRenderer = (value, tableMeta, updateValue) => {
        const {patientData} = this.state;
        const {classes, intl} = this.props;

        const nosology = tableMeta ? (patientData.nosologyStates || []).find((nosology) => nosology.id === tableMeta.rowData[0]) : value;

        return (
            nosology && nosology.history ?
                <React.Fragment>
                    <Grid container direction={"row"}
                          spacing={2}
                          justifyContent={'flex-end'} className={classes.nosologyAction}>
                        <Grid item>
                            <Tooltip
                                title={intl.formatMessage({id: 'label.information', defaultMessage: 'Information'})}
                            >
                                {/*<IconButton style={{padding: 0}} size="small" color="primary" aria-label="open nosology"*/}
                                {/*            component="span"*/}
                                {/*            onClick={() => this.onOpenNosology('RESULT', tableMeta, true)}>*/}
                                {/*    <InfoRoundedIcon/>*/}
                                {/*</IconButton>*/}
                                <ListItem
                                    // alignItems="flex-start"
                                    // className={classes.nosology}
                                    onClick={() => this.onOpenNosology('RESULT', tableMeta, true)}
                                    className={`${classes.primaryText} ${classes.pl0} ${nosology.id === NosologyType.IBS && classes.disabled}`}
                                >
                                    <ListItemIcon style={{minWidth: '32px', verticalAlign: 'middle'}}>
                                        <InfoRoundedIcon
                                            // onClick={() => this.onOpenNosology('RESULT', tableMeta, true)}
                                            color={'primary'}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        className={classes.textSize14px}
                                        // className={classes.primaryText}
                                        // className={`${classes.linkHead} ${classes.contentBoxProfileText}`}
                                        primary={intl.formatMessage({id: 'label.result', defaultMessage: 'Result'})}
                                    />
                                    {/*{nosologyName}*/}
                                </ListItem>
                            </Tooltip>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Tooltip title={intl.formatMessage({id: 'label.reset', defaultMessage: 'Reset'})}>*/}
                        {/*        <IconButton style={{padding: 0}} size="small" color="primary"*/}
                        {/*                    aria-label="delete nosology"*/}
                        {/*                    component="span" onClick={() => this.onDeleteNosology(value, tableMeta)}>*/}
                        {/*            <RefreshIcon/>*/}
                        {/*        </IconButton>*/}
                        {/*    </Tooltip>*/}
                        {/*</Grid>*/}
                    </Grid>
                </React.Fragment>
                : undefined
        );
    }

    nosologiesColumns = (patient) => {
        const {intl} = this.props;
        const columns = [
            {
                name: "id",
                label: "ID",
                options: {
                    display: 'false',
                    // customBodyRender: (value, tableMeta, updateValue) => this.nosologyIdRenderer(value, tableMeta, updateValue),
                }
            },
            {
                name: "",
                label: intl.formatMessage({id: 'label.patient.table.nosology', defaultMessage: 'Nosology'}),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: this.nosologyNameRenderer,
                    sortCompare: (order) => sortString(order, this.getNosologyName),
                }
            },
            {
                name: "history",
                label: intl.formatMessage({
                    id: 'label.patient.table.date_of_calculation_' + patient.type,
                    defaultMessage: 'Date of calculation'
                }),
                options: {
                    filter: true,
                    sort: true,
                    // customBodyRender: (value, tableMeta, updateValue) => datatableDateRenderer((value || {}).modify, tableMeta, updateValue),
                    customBodyRender: (value, tableMeta, updateValue) => this.nosologyModifyRenderer(value, tableMeta, updateValue),
                    sortCompare: (order) => sortDateStringEpoch(order, 'modify'),
                }
            },
            {
                name: "",
                label: "",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: this.nozologyActionRenderer,
                    setCellHeaderProps: (value) => ({style: {textDecoration: 'underline', width: '100px'}}),
                    display: ((((config.options.PATIENT || {}).NOSOLOGIES || {}).COLUMNS || {}).RESULT || {}).enable ? 'true' : 'false',
                }
            },
        ];

        return columns;
    }

    patientDataColumns = () => {
        const {intl} = this.props;
        const columns = [
            {
                name: "id",
                label: "ID",
                options: {
                    display: 'false',
                }
            },
            {
                name: "name",
                label: intl.formatMessage({id: 'label.patient.table.parameter', defaultMessage: 'Parameter'}),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: this.patientDataNameRenderer,
                    sortCompare: (order) => sortString(order, this.getPatientDataName),
                }
            },
            {
                name: "value",
                label: intl.formatMessage({id: 'label.patient.table.value', defaultMessage: 'Value'}),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: this.patientDataValueRenderer,
                    sortCompare: (order) => sortString(order, this.getPatientDataValue),
                }
            },
            {
                name: "updateDate",
                label: intl.formatMessage({id: 'label.patient.table.updateDate', defaultMessage: 'Update date'}),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: this.patientDataUpdateDateRenderer,
                    setCellHeaderProps: (value) => ({style: {minWidth: '180px'}}),
                    sortCompare: (order) => sortDate(order),
                }
            },
        ];

        return columns;
    }

    /*
        getSortOrder = (tableName): { name: string, direction: 'asc' | 'desc' } => {
            const {patientNosologyState = {}} = this.state;
            return (patientNosologyState[tableName] || {}).sortOrder || {name: 'history', direction: 'asc'};
        }
    */

    /*
        onChangeSortDirection = (tableState, tableName) => {
            const {setPatientNosologyStateContext, patientNosologyStateContext = {}} = this.context;
            if (setPatientNosologyStateContext !== undefined) {

                patientNosologyStateContext[tableName] = tableState;
                setPatientNosologyStateContext(
                    {
                        patientNosologyStateContext: patientNosologyStateContext,
                    });
            }

            this.setState({patientNosologyState: patientNosologyStateContext});
        }
    */

    nosologiesOptions = (): MUIDataTableOptions => {
        const options: MUIDataTableOptions = {
            // filterType: 'checkbox',
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            pagination: false,
            search: false,
            selectableRowsHeader: false,
            selectableRows: 'single',
            selectToolbarPlacement: 'none',
            selectableRowsOnClick: true,
            // responsive: 'scroll',
            // searchText: this.state.searchText,

            // customToolbar: () => {
            //   return (
            //       <React.Fragment>
            //         <Tooltip title={"Добавить пациента"}>
            //           <Button color={"primary"} onClick={this.onAddPatient}>
            //             <InviteIcon className={this.props.classes.iconButton} />
            //             <FormattedMessage id='label.patients.add' defaultMessage='Add' />
            //           </Button>
            //         </Tooltip>
            //       </React.Fragment>
            //   );
            // },

            // setRowProps: (item: any, rowIndex: number) => ({
            //     onDoubleClick: (row, dataIndex) => {
            //         const {patientNosology} = this.state;
            //         this.onOpenNosology(patientNosology[rowIndex], undefined);
            //     }
            // }),

            // onTableChange: (action, tableState) => {
            //     if (action === 'sort') {
            //         this.onChangeSortDirection(tableState, 'patientNosologies');
            //     }
            // },
            // sortOrder: this.getSortOrder('patientNosologies'),
        };

        return options;
    }

    patientDataOptions = (): MUIDataTableOptions => {
        const options: MUIDataTableOptions = {
            // filterType: 'checkbox',
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            pagination: false,
            search: false,
            selectableRowsHeader: false,
            selectableRows: 'single',
            selectToolbarPlacement: 'none',
            selectableRowsOnClick: true,
            // responsive: 'scroll',
            // searchText: this.state.searchText,

            // customToolbar: () => {
            //   return (
            //       <React.Fragment>
            //         <Tooltip title={"Добавить пациента"}>
            //           <Button color={"primary"} onClick={this.onAddPatient}>
            //             <InviteIcon className={this.props.classes.iconButton} />
            //             <FormattedMessage id='label.patients.add' defaultMessage='Add' />
            //           </Button>
            //         </Tooltip>
            //       </React.Fragment>
            //   );
            // },

            // setRowProps: (item: any, rowIndex: number) => ({
            //     onDoubleClick: (row, dataIndex) => {
            //     }
            // }),

            // onTableChange: (action, tableState) => {
            //     if (action === 'sort') {
            //         this.onChangeSortDirection(tableState, 'patientData');
            //     }
            // },
            // sortOrder: this.getSortOrder('patientData'),
        };

        return options;
    }

    patientNosology = () => {
        const {patientData, rerenderPatientData} = this.state;
        const {intl, history, classes} = this.props;
        return (
            patientData?.id ?
                <React.Fragment>
                    {!!((patientData || {}).nosologyStates || []).length &&
                        <Card className={classes.contentBoxBottomSpace}>
                            <CardContent>
                                <DatatableComponent
                                    intl={intl}
                                    tableName={TableNameType.PATIENT_NOSOLOGIES}
                                    title={''}
                                    // data={patientNosology}
                                    data={(patientData || {}).nosologyStates || []}
                                    columns={this.nosologiesColumns(patientData)}
                                    options={this.nosologiesOptions()}
                                    // rerender={rerenderPatientNosology}
                                    rerender={rerenderPatientData}
                                    style={{}}
                                    elevation={0}
                                    // noDataText={getTariffsLinkIntl(intl, history)}
                                />
                            </CardContent>
                        </Card>
                    // this.patientDrugs()
                    }
                    {/*{!((patientData || {}).nosologyStates || []).length &&*/}
                    {!(((userStore || {}).user || {}).nosologies || []).length &&
                        <Card className={classes.contentBoxBottomSpace}>
                            <CardContent>
                                <Box className={classes.contentBox} textAlign={'center'}>
                                    {getTariffsLinkIntl(intl, history)}
                                </Box>
                            </CardContent>
                        </Card>
                    }
                    {(!!(((userStore || {}).user || {}).nosologies || []).length && !((patientData || {}).nosologies || []).length) &&
                        <Card className={classes.contentBoxBottomSpace}>
                            <CardContent>
                                <Box className={classes.contentBox} textAlign={'center'}>
                                    <FormattedMessage id='message.datatable.no_nosologies' defaultMessage='No nosologies'/>
                                </Box>
                            </CardContent>
                        </Card>
                    }
                </React.Fragment>
            : undefined
        )
    }

    patientData = () => {
        const {patientData} = this.state;
        const {patient, history} = this.props;
        const {contextStaff} = this.context;

        return (
            <BaselineEditorView
                baseline={(patientData || {}).baseline}
                patient={patient || {}}
                patientData={patientData || {}}
                activePatient={getURLSearchParams(this.props.location.search)}
                readonly={isBaselineReadonly(contextStaff, patient.type, patient)}
                showButtons={true}
                nextNosology={getNextNosology(patientData, true)}
                getNextNosology={getNextNosology}
                history={history}
                loadPatient={this.loadPatient}
                openDialog={this.onPatientNoNextNosology}
                patientDrugs={() => patientDrugs(patientData, patient, contextStaff, false)}
            />
        )
    }

    isNosologyAdded = (nosology): boolean => {
        const {patientData} = this.state;
        return Boolean(((patientData || {}).nosologyStates || []).find((patientNosology) => patientNosology.id === nosology.id));
    }

/* +++ remove add remove nosologies to patient
    onAddNosologiesSubmit = (selectedNosologies, callback) => {
        const {patientDataContext} = this.context;

        const nosologiesToDelete = [];
        (patientDataContext.patientDataContext.nosologyStates || []).forEach((patientNosology) => {
            if (!selectedNosologies.some((selectedNosology) => selectedNosology.id === patientNosology.id)) {
                nosologiesToDelete.push({
                    id: patientNosology.id
                });
            }
        });

        const nosologiesToAdd = [];
        selectedNosologies.forEach((selectedNosology) => {
            if (!(patientDataContext.patientDataContext.nosologyStates || []).some((patientNosology) => selectedNosology.id === patientNosology.id)) {
                nosologiesToAdd.push(selectedNosology);
            }
        });

        this.addRemoveNosologiesFromPatient(nosologiesToAdd, nosologiesToDelete, callback);
    }
*/

/* +++ remove add remove nosologies to patient
    addRemoveNosologiesFromPatient = (nosologiesToAdd: [], nosologiesToDelete: [], callback) => {
        const {client, patient} = this.props;
        const {signal} = this.abortController;
        if (nosologiesToAdd.length) {
            Promise.all(nosologiesToAdd.map((toAddNosology) => {
                const data = {
                    id: patient.id,
                    nosologyId: toAddNosology.id,
                };
                return callAddNosologyToPatient(client, signal, data)
                    .then((addNosologyResponse) => {
                        return {
                            [toAddNosology.id]: addNosologyResponse,
                        };
                    });
            }))
                .then((addAllNosologyResponse) => {
                    this.removeNosologiesFromPatient(nosologiesToDelete, callback);
                });
        } else {
            this.removeNosologiesFromPatient(nosologiesToDelete, callback);
        }
    }
*/

/* +++ remove add remove nosologies to patient
    removeNosologiesFromPatient = (nosologiesToDelete: [], callback) => {
        const {client, patient} = this.props;
        const {signal} = this.abortController;

        if (nosologiesToDelete.length) {
            Promise.all(nosologiesToDelete.map((toDeleteNosology) => {
                const data = {
                    id: patient.id,
                    nosologyId: toDeleteNosology.id,
                };
                return callRemoveNosologyFromPatient(client, signal, data)
                    .then((removeNosologyResponse) => {
                        return {
                            [toDeleteNosology.id]: removeNosologyResponse,
                        };
                    });
            }))
                .then((deleteAllNosologyResponse) => {
                    if (callback) {
                        callback();
                    }
                    this.loadPatient(client, signal, patient.id);
                });
        } else {
            if (callback) {
                callback();
            }
            this.loadPatient(client, signal, patient.id);
        }
    }
*/

    onMenuToolbarShow = (event) => {
        this.setState({
            menuToolbarAnchorEl: event.currentTarget,
        });
    };

    onMenuToolbarClose = () => {
        this.setState({
            menuToolbarAnchorEl: null,
        });
    };

    render() {
        const {classes, patient, patientsData, intl} = this.props;
        const {
            allDataLoaded,
            openPatientDialog,
            openConfirmDialog,
            titleConfirmDialog,
            textConfirmDialog,
            buttonYes,
            buttonNo,
            onConfirmDialog,
            patientTab,
            menuToolbarAnchorEl,
        } = this.state;
        const {contextPatient, contextStaff} = this.context;
        const access = getAccess((contextStaff || {}).role, patient.type, contextStaff, patient);

        let content;

        switch (patientTab) {
            case PatientTabEnum.DATA: // Данные
                content = this.patientData();
                break;
            case PatientTabEnum.NOSOLOGY:
                content = this.patientNosology();
                break;
            default:
                content = <Box/>;
        }

        if (!allDataLoaded) {
            return (
                <Box/>
            );
        }

        const menuToolbarButtonItems = [];
        if (contextStaff) {
            menuToolbarButtonItems.push(
                <Button color={"primary"} onClick={() => this.setOpenPatient(true)}
                        className={classes.toolbarIconButton}
                        key={1}
                >
                    <EditIcon className={this.props.classes.iconButton}/>
                    <FormattedMessage id='label.edit' defaultMessage='Edit'/>
                </Button>
            );
        }
        menuToolbarButtonItems.push(
            <Button color={"primary"} onClick={() => this.onPatientDelete()}
                    className={classes.toolbarIconButton}
                    key={2}
            >
                <DeleteIcon className={this.props.classes.iconButton}/>
                <FormattedMessage id='label.delete' defaultMessage='Delete'/>
            </Button>
        );

        const menuToolbar =
            <Menu
                MenuListProps={{
                    style: {
                        width: 'max-content',
                    },
                    className: classes.menuToolbar,
                }}
                id='menu-toolbar'
                anchorEl={menuToolbarAnchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(menuToolbarAnchorEl)}
                onClose={this.onMenuToolbarClose}
            >
                {menuToolbarButtonItems.map(button => (button))}
            </Menu>
        ;


        return (
            <Box>
                <Card className={classes.contentBoxBottomSpace}>
                    {/*<CardHeader title={intl.formatMessage({id: 'label.information', defaultMessage: 'Information'})} />*/}
                    <CardActions className={classes.cardActions} style={{paddingBottom: 0}}>
                        {/*<Button onClick={this.onClose} color="primary">*/}
                        {/*  <FormattedMessage id='label.save' defaultMessage='Save' />*/}
                        {/*</Button>*/}
                        <IconButton
                            style={{padding: '12px', marginRight: '4px'}}
                            size="small"
                            color="primary"
                            aria-label="close patient"
                            // component="span"
                            onClick={() => this.onClose(true)}
                        >
                            <KeyboardBackspaceRoundedIcon/>
                        </IconButton>
                        <Typography noWrap>
                            {(contextPatient && contextPatient.patient) ? contextPatient.patient.visualName : patient.visualName}
                        </Typography>
                        <div className={classes.grow}/>
                        {/*<Button color={"primary"} onClick={() => this.onSavePatientNosology()}>*/}
                        {/*  <SaveIcon className={this.props.classes.iconButton} />*/}
                        {/*  <FormattedMessage id='label.save' defaultMessage='Save' />*/}
                        {/*</Button>*/}
                        {(
                            // !isPatientPublic((contextPatient || {}).patient) &&
                            access.patient.update) ?
                            <Box>
                                <Hidden xsDown implementation="js">
                                    {menuToolbarButtonItems.map(button => (button))}
                                </Hidden>
{/*
                                {contextStaff &&
                                <Button color={"primary"} onClick={() => this.setOpenAddNosologiesDialog(true)}>
                                    <AddIcon className={this.props.classes.iconButton}/>
                                    <FormattedMessage id='label.patient.nosology.add' defaultMessage='Add nosology'/>
                                </Button>
                                }
*/}
{/*
                                {contextStaff &&
                                <Button color={"primary"} onClick={() => this.setOpenPatient(true)} className={classes.toolbarIconButton}>
                                    <EditIcon className={this.props.classes.iconButton}/>
                                    <FormattedMessage id='label.edit' defaultMessage='Edit'/>
                                </Button>
                                }
                                <Button color={"primary"} onClick={() => this.onPatientDelete()} className={classes.toolbarIconButton}>
                                    <DeleteIcon className={this.props.classes.iconButton}/>
                                    <FormattedMessage id='label.delete' defaultMessage='Delete'/>
                                </Button>
*/}

                                <Hidden smUp implementation="js">
                                        {menuToolbar}
                                </Hidden>
                                <IconButton
                                    edge={"end"}
                                    color="inherit"
                                    aria-label="Display toolbar actions"
                                    onClick={this.onMenuToolbarShow}
                                    className={classes.toolbarIconHide}>
                                    <MoreIcon />
                                </IconButton>
                            </Box>
                            : undefined
                        }
                    </CardActions>
                    <CardContent className={classes.pb0}>
                        <TabContext value={patientTab}>
                            <TabList
                                onChange={(event, tab) => {
                                    this.setState({
                                        patientTab: tab,
                                        rerenderPatientData: new Date().getTime(),
                                    });
                                }}
                                aria-label="simple tabs example"
                                centered
                                indicatorColor={"primary"}
                                textColor='primary'
                                style={{paddingBottom: '16px'}}
                            >
                                <Tab label={intl.formatMessage({
                                    id: 'label.patient.data',
                                    defaultMessage: 'Data'
                                })}
                                     value={PatientTabEnum.DATA} style={{padding: 0}}
                                />
                                <Tab label={intl.formatMessage({
                                    id: 'label.patient.nosologies',
                                    defaultMessage: 'Nosologies'
                                })}
                                     value={PatientTabEnum.NOSOLOGY}
                                />
                            </TabList>
                            {/*{content}*/}
                        </TabContext>
                    </CardContent>
                </Card>
                {content}
                {openPatientDialog &&
                <PatientDialog
                    onDialogClose={() => this.setOpenPatient(false)}
                    onSubmit={this.onPatientSubmit}
                    isShow={openPatientDialog}
                    patient={(contextPatient && contextPatient.patient) ? contextPatient.patient : patient}
                    patientsData={patientsData}
                    isNosologyAdded={this.isNosologyAdded}
                    submitButtonLabel={'label.save'}
                />
                }
{/*
                {openAddNosologiesDialog &&
                <AddNosologyDialog
                    onDialogClose={() => this.setOpenAddNosologiesDialog(false)}
                    onSubmit={this.onAddNosologiesSubmit}
                    isNosologyAdded={this.isNosologyAdded}
                    isShow={openAddNosologiesDialog}
                    submitButtonLabel={'label.apply'}
                    // client={client}
                    // classes={classes}
                />
                }
*/}
                {openConfirmDialog &&
                <ConfirmDialog
                    title={titleConfirmDialog}
                    text={textConfirmDialog}
                    buttonYes={buttonYes}
                    buttonNo={buttonNo}
                    open={openConfirmDialog}
                    setOpen={this.setConfirmOpen}
                    onConfirm={onConfirmDialog}
                >
                    {/*Действительно хотите удалить пациента?*/}
                </ConfirmDialog>
                }

            </Box>
        );
    }
}

Patient.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    patientsData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Patient.contextTypes = {
    patientDataContext: PropTypes.object,
    contextPatient: PropTypes.object,
    contextStaff: PropTypes.object,
    setContextPatient: PropTypes.func,
    setContextStaff: PropTypes.func,
    patientNosologyStateContext: PropTypes.object,
    setPatientNosologyStateContext: PropTypes.func,
    setPatientDataContext: PropTypes.func,
};


export default compose(
    withRouter,
    withStyles(styles),
    withApollo,
    injectIntl,
)(Patient);
