import React from 'react';
import PropTypes from 'prop-types';
import {Button, FormControlLabel, Grid, RadioGroup} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {FormattedMessage, injectIntl} from 'react-intl';
import {MessageType} from "../../const";
import Radio from "@material-ui/core/Radio";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import Box from "@material-ui/core/Box";
import {InfoHelp} from "../common/InfoHelp";
import type {ServiceMessage} from "../../const";
import {getServiceMessages, sortByOrder} from "../../utils";
import grey from "@material-ui/core/colors/grey";

const styles = theme => ({
    ...mainStyles(theme),
    searchBox: {
        marginTop: theme.spacing(3),
    },
    dialogFixedSize: {
        // height: '100vh',
        // maxHeight: 500,
        // maxHeight: 'calc(100vh-250px)',
        // maxHeight: 'fitContent',
        // height: 'calc(100vh-260px)',
        // maxHeight: 'calc(100vh-260px)',
        // alignItems: 'baseline'  // default center
    },
    scrollPaper: {
        alignItems: 'baseline'  // default center
    },
    drugName: {
        marginLeft: theme.spacing(1),
    },
    regimenDescr: {
        color: grey[500],
        marginLeft: theme.spacing(1),
    },
});

class SelectDrugRegimenDialog extends React.Component {

    state = {
        fields: new Map(),
        errors: new Map(),
    };

    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            addingDrugsData: [],
            rerender: undefined,
            selectedDrug: undefined,
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    onFormSubmit = (drugCompositeId: number) => {
        const {onSubmit, drug} = this.props;

        if (!!drugCompositeId) {
            const regimen = drug.regimens.find((drugRegimen) => '' + drugRegimen.drugCompositeId === '' + drugCompositeId);

            if (!!regimen) {
                onSubmit(drug, regimen);
            }
        } else {
            onSubmit(drug, null);
        }
        this.onClose();
    };

    onClose = () => {
        // this.clearState(true);
    };

    selectRegimen = (event) => {
        this.onFormSubmit(Number(event.target.value));
    }

    render() {
        const {
            isShow,
            title,
            drug,
            selectedRegimen,
            classes,
        } = this.props;

        return (
            <Dialog
                fullWidth
                onClose={this.onClose}
                open={isShow}
                // scroll={'paper'}
                classes={{
                    paper: classes.dialogFixedSize,
                    scrollPaper: classes.scrollPaper,
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    {title}
                </DialogTitle>
                    <DialogContent dividers={true}>
                        <Box id="scroll-dialog-description">
                                    <RadioGroup
                                        onChange={this.selectRegimen}
                                        value={selectedRegimen && selectedRegimen.drugCompositeId ? selectedRegimen.drugCompositeId : 0}>
                                        {drug.regimens.sort(sortByOrder)
                                            .map((regimen) => {
                                                let serviceMessageHelp: ServiceMessage = getServiceMessages(regimen.messages, MessageType.DESCRIPTION);
                                                if (!serviceMessageHelp.showService) {
                                                    serviceMessageHelp = getServiceMessages(regimen.messages, MessageType.INFO);
                                                }

                                                return (<FormControlLabel
                                                    key={regimen.id}
                                                    value={regimen.drugCompositeId}
                                                    label={
                                                        // regimen.name
                                                        <Grid container direction={"row"}>
                                                            <Grid item>
                                                                {regimen.name}
                                                            </Grid>
                                                            {serviceMessageHelp?.showService &&
                                                                <Grid item style={{margin: '-10px 0 0 10px'}}>
                                                                    <InfoHelp texts={serviceMessageHelp.messages}/>
                                                                </Grid>
                                                            }
                                                            <Grid item>
                                                                {!!regimen.descr &&
                                                                    <span className={classes.regimenDescr}>{regimen.descr}</span>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    control={
                                                        <Radio color={'primary'}/>
                                                    }
                                                />);
                                            })}
                                    </RadioGroup>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => this.onFormSubmit(null)}>
                            <FormattedMessage id='label.cancel' defaultMessage='Cancel' />
                        </Button>
                    </DialogActions>
            </Dialog>
        );
    }
}

SelectDrugRegimenDialog.propTypes = {
    intl: PropTypes.object.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    drug: PropTypes.object.isRequired,
    isShow: PropTypes.bool.isRequired,
    title: PropTypes.string,
    // client: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    injectIntl,
)(SelectDrugRegimenDialog);
