import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactHtmlParser from 'react-html-parser';
// import parser from 'html-react-parser';
// import ReactHtmlParser from 'html-react-parser';

const ConfirmDialog = (props) => {
    const { title, text, open, setOpen, onConfirm, buttonYes, buttonNo, children, buttonYesDisabled } = props;
    // console.error('+++ ConfirmDialog() +++ confirmDialogTag:', confirmDialogTag);

    const buttonYesDisableStatus = (tag: string): boolean => {
        // console.error('+++ ConfirmDialog() buttonYesDisableStatus() +++ tag:', tag);
        return false;
        return tag !== undefined && tag !== null && tag.length < 3;
    }

    // const [buttonYesDisableState, setButtonYesDisableState] = React.useState(buttonYesDisableStatus(confirmDialogTag));

    // React.useEffect(() => {
    //     setButtonYesDisableState(buttonYesDisableStatus(confirmDialogTag));
    // }, [confirmDialogTag, buttonYesDisabled]);


    const onClose = (event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setOpen(false);
        }
    }

    return (
        <Dialog
            fullWidth
            // onEscapeKeyDown={() => setOpen(false)}
            // onBackdropClick={() => setOpen(false)}
            open={open}
            onClose={(event, reason) => onClose(event, reason)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            {/*<DialogContent>{children}</DialogContent>*/}
            <DialogContent>
                {text && ReactHtmlParser(text)}
                {children && children}
            </DialogContent>
            <DialogActions>
                {buttonNo &&
                    <Button
                        onClick={() => setOpen(false)}
                        color="primary"
                    >
                        {buttonNo}
                    </Button>
                }
                {buttonYes &&
                    <Button
                        disabled={buttonYesDisabled}
                        onClick={() => {
                            setOpen(false);
                            if (!!onConfirm) {
                                onConfirm();
                            }
                        }}
                        color="primary"
                    >
                        {buttonYes}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
