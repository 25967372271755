import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import type {ServiceMessage} from "../const";
import {getServiceMessages} from "../utils";
import {MessageType} from "../const";
import {InfoHelp} from "./common/InfoHelp";

const SetDescrTypography = withStyles(_theme => ({
    root: {
        marginLeft: '0.5rem',
    },
}))(Typography);

function TherapyCombView(props) {
    const {
        set,
        therapy,
        messageType,
        iconType,
    } = props;

    const serviceMessage: ServiceMessage = getServiceMessages(set.messages, messageType || MessageType.DESCRIPTION);

    return (
        <Box>
            <Grid container direction={"row"} alignItems={"center"}>
                <Grid item>
                    {therapy}
                </Grid>
                {serviceMessage?.showService &&
                    <Grid item style={{margin: '-4px 0 0 10px'}}>
                        <InfoHelp texts={serviceMessage.messages} type={iconType || messageType || MessageType.DESCRIPTION}/>
                    </Grid>
                }
            </Grid>
            {set.descr &&
                <SetDescrTypography component={'span'} color={'textSecondary'} display="inline">
                    ({set.descr})
                </SetDescrTypography>
            }
        </Box>
    );
}

TherapyCombView.propTypes = {
    set: PropTypes.object.isRequired,
    therapy: PropTypes.any.isRequired,
    messageType: PropTypes.any,
    iconType: PropTypes.any,
};

export default TherapyCombView;
