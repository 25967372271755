import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import RootContainer from '../comp/RootContainer';
import ContentPanel from '../comp/ContentPanel';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

const styles = theme => ({
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  ...mainStyles(theme),
  contentBox: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
});

class Methodology extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // const $this = this;
    const { locale } = this.context;

    fetch(`/md/methodology_${locale}.md`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => response.text())
      .then((content) => {
        this.setState({
          content: content,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    const { classes } = this.props;
    const { content } = this.state;

    return (
      <RootContainer>
        <ContentPanel>
          <div className={classes.contentBox}>
            <Typography variant="body1" component="span">
              {content && <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={content} />}
            </Typography>
          </div>
        </ContentPanel>
      </RootContainer>
    );
  }
}

Methodology.propTypes = {
  classes: PropTypes.object.isRequired,
};

Methodology.contextTypes = {
  isAuth: PropTypes.func,
  locale: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
)(Methodology);
