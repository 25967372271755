import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import {Box, Button, Grid} from "@material-ui/core";

import Plot from 'react-plotly.js';

const styles = theme => ({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    ...mainStyles(theme),
    contentBox: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
});

class Charts extends React.Component {

    plotContainer;

    layoutForestPlot = {
        title: 'Forest Plot Chart',
        autosize: true,
    };

    layoutForestPlot2 = {
        backgroundcolor: 'transparent',
        title: 'Global Users Forest Plot Chart',
        autosize: true,
        titlefont: {
            size: 19,
            color: 'gray',
        },
        xaxis: {
            side: 'bottom',
            type: 'linear',
            range: [-1, 3],
            title: { text: 'Convertion Rate' },
            showline: false,
            autorange: false,
            automargin: true,
            fixedrange: true,
        },
        yaxis: {
            side: 'left',
            type: 'linear',
            range: [79.7677261613692, 320.2322738386308],
            title: { text: '' },
            tickmode: 'enumerated',
            autorange: true,
            tickangle: 45,
            automargin: true,
            tickformat: '',
            exponentformat: 'B',
            showticklabels: false,
            fixedrange: true,
        },
        legend: {
            x: 0.5,
            y: -0.35,
            xanchor: 'auto',
            orientation: 'h',
        },
        modebar: {
            orientation: 'h',
        },
        template: {
            // title: {
            //   // x: 0.05,
            //   color: 'gray',
            // },
            data: {
                bar: [
                    {
                        type: 'bar',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                table: [
                    {
                        type: 'table',
                        cells: {
                            fill: { color: '#EBF0F8' },
                            line: { color: 'white' },
                        },
                        header: {
                            fill: { color: '#C8D4E3' },
                            line: { color: 'white' },
                        },
                    },
                ],
                // carpet: [
                //   {
                //     type: 'carpet',
                //     aaxis: {
                //       gridcolor: '#C8D4E3',
                //       linecolor: '#C8D4E3',
                //       endlinecolor: '#2a3f5f',
                //       minorgridcolor: '#C8D4E3',
                //       startlinecolor: '#2a3f5f',
                //     },
                //     baxis: {
                //       gridcolor: '#C8D4E3',
                //       linecolor: '#C8D4E3',
                //       endlinecolor: '#2a3f5f',
                //       minorgridcolor: '#C8D4E3',
                //       startlinecolor: '#2a3f5f',
                //     },
                //   },
                // ],
                mesh3d: [
                    {
                        type: 'mesh3d',
                        colorbar: {
                            ticks: '',
                            outlinewidth: 0,
                        },
                    },
                ],
                contour: [
                    {
                        type: 'contour',
                        colorbar: {
                            ticks: '',
                            outlinewidth: 0,
                        },
                        autocolorscale: true,
                    },
                ],
                heatmap: [
                    {
                        type: 'heatmap',
                        colorbar: {
                            ticks: '',
                            outlinewidth: 0,
                        },
                        autocolorscale: true,
                    },
                ],
                scatter: [
                    {
                        type: 'scatter',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                surface: [
                    {
                        type: 'surface',
                        colorbar: {
                            ticks: '',
                            outlinewidth: 0,
                        },
                    },
                ],
                heatmapgl: [
                    {
                        type: 'heatmapgl',
                        colorbar: {
                            ticks: '',
                            outlinewidth: 0,
                        },
                    },
                ],
                histogram: [
                    {
                        type: 'histogram',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                parcoords: [
                    {
                        line: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                        type: 'parcoords',
                    },
                ],
                scatter3d: [
                    {
                        type: 'scatter3d',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                scattergl: [
                    {
                        type: 'scattergl',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                choropleth: [
                    {
                        type: 'choropleth',
                        colorbar: {
                            ticks: '',
                            outlinewidth: 0,
                        },
                    },
                ],
                scattergeo: [
                    {
                        type: 'scattergeo',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                histogram2d: [
                    {
                        type: 'histogram2d',
                        colorbar: {
                            ticks: '',
                            outlinewidth: 0,
                        },
                        autocolorscale: true,
                    },
                ],
                scatterpolar: [
                    {
                        type: 'scatterpolar',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                contourcarpet: [
                    {
                        type: 'contourcarpet',
                        colorbar: {
                            ticks: '',
                            outlinewidth: 0,
                        },
                    },
                ],
                scattercarpet: [
                    {
                        type: 'scattercarpet',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                scattermapbox: [
                    {
                        type: 'scattermapbox',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                scatterpolargl: [
                    {
                        type: 'scatterpolargl',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                scatterternary: [
                    {
                        type: 'scatterternary',
                        marker: {
                            colorbar: {
                                ticks: '',
                                outlinewidth: 0,
                            },
                        },
                    },
                ],
                histogram2dcontour: [
                    {
                        type: 'histogram2dcontour',
                        colorbar: {
                            ticks: '',
                            outlinewidth: 0,
                        },
                        autocolorscale: true,
                    },
                ],
            },
            layout: {
                geo: {
                    bgcolor: 'white',
                    showland: true,
                    lakecolor: 'white',
                    landcolor: 'white',
                    showlakes: true,
                    subunitcolor: '#C8D4E3',
                },
                font: {
                    // color: '#2a3f5f',
                    size: 16,
                    color: '#333',
                },
                polar: {
                    bgcolor: 'white',
                    radialaxis: {
                        ticks: '',
                        gridcolor: '#EBF0F8',
                        linecolor: '#EBF0F8',
                    },
                    angularaxis: {
                        ticks: '',
                        gridcolor: '#EBF0F8',
                        linecolor: '#EBF0F8',
                    },
                },
                scene: {
                    xaxis: {
                        // ticks: '',
                        gridcolor: '#DFE8F3',
                        gridwidth: 2,
                        linecolor: '#EBF0F8',
                        zerolinecolor: '#EBF0F8',
                        showbackground: true,
                        backgroundcolor: 'white',
                        ticklen: 10,
                        tickcolor: 'white',
                    },
                    yaxis: {
                        ticks: '',
                        gridcolor: '#DFE8F3',
                        gridwidth: 2,
                        linecolor: '#EBF0F8',
                        zerolinecolor: '#EBF0F8',
                        showbackground: true,
                        backgroundcolor: 'white',
                    },
                    zaxis: {
                        ticks: '',
                        gridcolor: '#DFE8F3',
                        gridwidth: 2,
                        linecolor: '#EBF0F8',
                        zerolinecolor: '#EBF0F8',
                        showbackground: true,
                        backgroundcolor: 'white',
                    },
                },

                xaxis: {
                    // ticks: '',
                    gridcolor: '#EBF0F8',
                    linecolor: '#EBF0F8',
                    automargin: true,
                    zerolinecolor: '#EBF0F8',
                    zerolinewidth: 2,
                    ticklen: 10,
                    tickcolor: 'white',
                },
                yaxis: {
                    ticks: '',
                    gridcolor: '#EBF0F8',
                    linecolor: '#EBF0F8',
                    automargin: true,
                    zerolinecolor: '#EBF0F8',
                    zerolinewidth: 2,
                },
                ternary: {
                    aaxis: {
                        ticks: '',
                        gridcolor: '#DFE8F3',
                        linecolor: '#A2B1C6',
                    },
                    baxis: {
                        ticks: '',
                        gridcolor: '#DFE8F3',
                        linecolor: '#A2B1C6',
                    },
                    caxis: {
                        ticks: '',
                        gridcolor: '#DFE8F3',
                        linecolor: '#A2B1C6',
                    },
                    bgcolor: 'white',
                },
                colorway: ['#636efa', '#EF553B', '#00cc96', '#ab63fa', '#19d3f3', '#e763fa', '#fecb52', '#ffa15a', '#ff6692', '#b6e880'],
                hovermode: 'closest',
                colorscale: {
                    diverging: [
                        ['0', '#8e0152'],
                        ['0.1', '#c51b7d'],
                        ['0.2', '#de77ae'],
                        ['0.3', '#f1b6da'],
                        ['0.4', '#fde0ef'],
                        ['0.5', '#f7f7f7'],
                        ['0.6', '#e6f5d0'],
                        ['0.7', '#b8e186'],
                        ['0.8', '#7fbc41'],
                        ['0.9', '#4d9221'],
                        ['1', '#276419'],
                    ],
                    sequential: [
                        ['0', '#0508b8'],
                        ['0.0893854748603352', '#1910d8'],
                        ['0.1787709497206704', '#3c19f0'],
                        ['0.2681564245810056', '#6b1cfb'],
                        ['0.3575418994413408', '#981cfd'],
                        ['0.44692737430167595', '#bf1cfd'],
                        ['0.5363128491620112', '#dd2bfd'],
                        ['0.6256983240223464', '#f246fe'],
                        ['0.7150837988826816', '#fc67fd'],
                        ['0.8044692737430168', '#fe88fc'],
                        ['0.8938547486033519', '#fea5fd'],
                        ['0.9832402234636871', '#febefe'],
                        ['1', '#fec3fe'],
                    ],
                    sequentialminus: [
                        ['0', '#0508b8'],
                        ['0.0893854748603352', '#1910d8'],
                        ['0.1787709497206704', '#3c19f0'],
                        ['0.2681564245810056', '#6b1cfb'],
                        ['0.3575418994413408', '#981cfd'],
                        ['0.44692737430167595', '#bf1cfd'],
                        ['0.5363128491620112', '#dd2bfd'],
                        ['0.6256983240223464', '#f246fe'],
                        ['0.7150837988826816', '#fc67fd'],
                        ['0.8044692737430168', '#fe88fc'],
                        ['0.8938547486033519', '#fea5fd'],
                        ['0.9832402234636871', '#febefe'],
                        ['1', '#fec3fe'],
                    ],
                },
                plot_bgcolor: 'transparent',
                paper_bgcolor: 'transparent',
                shapedefaults: {
                    line: { width: 0 },
                    opacity: 0.4,
                    fillcolor: '#506784',
                },
                annotationdefaults: {
                    arrowhead: 0,
                    arrowcolor: '#506784',
                    arrowwidth: 1,
                },
            },
            themeRef: 'PLOTLY_WHITE',
        },
        colorscale: {
            sequential: [
                ['0', '#0508b8'],
                ['0.08333333333333333', '#1910d8'],
                ['0.16666666666666666', '#3c19f0'],
                ['0.25', '#6b1cfb'],
                ['0.3333333333333333', '#981cfd'],
                ['0.4166666666666667', '#bf1cfd'],
                ['0.5', '#dd2bfd'],
                ['0.5833333333333334', '#f246fe'],
                ['0.6666666666666666', '#fc67fd'],
                ['0.75', '#fe88fc'],
                ['0.8333333333333334', '#fea5fd'],
                ['0.9166666666666666', '#febefe'],
                ['1', '#fec3fe'],
            ],
        },
        // annotations: [
        //   {
        //     x: -0.54,
        //     y: 99.02200488997552,
        //     ax: -22,
        //     ay: -6,
        //     text: 'China',
        //     showarrow: false,
        //   },
        //   {
        //     x: -0.7,
        //     y: 200,
        //     text: 'North America',
        //     showarrow: false,
        //   },
        //   {
        //     x: -0.7,
        //     y: 300.12224938875306,
        //     text: 'Latin America',
        //     showarrow: false,
        //   },
        //   {
        //     x: -0.7,
        //     y: 400,
        //     text: 'Central America',
        //     showarrow: false,
        //   },
        // ],
    };

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataForestPlot: [],
            revision: 0,
        };
    }

    componentDidMount() {
        this.refreshChartData();
    }

    refreshChartData = () => {
        const {revision} = this.state;

        const data = [];
        const dataForestPlot = [];

        dataForestPlot.push({
            mode: 'markers',
            type: 'scatter',
            name: 'Forest Plot 1',
            // xsrc: 'gzork:14:3ab366',
            x: ['Convertion Rate', '0.9'],
            // ysrc: 'gzork:14:6fdcaf',
            y: [null, '120'],
            marker: {
                line: { width: 0 },
                size: 6,
                symbol: 'circle',
            },
            error_x: {
                type: 'data',
                visible: true,
                // arraysrc: 'gzork:14:74ae28',
                array: ['Upper CI (95%)', '.28'],
                symmetric: false,
                // arrayminussrc: 'gzork:14:d485ba',
                arrayminus: ['Lower CI (95%)', '0.68'],
            },
            stackgroup: null,
        });

        dataForestPlot.push({
            mode: 'markers',
            name: 'Forest Plot 2',
            type: 'scatter',
            xsrc: 'gzork:14:8ab23f',
            x: ['Convertion Rate', '0.2'],
            ysrc: 'gzork:14:4621f9',
            y: [null, '75'],
            marker: {
                line: { width: 0 },
                color: '#636efa',
            },
            error_x: { // the error line
                type: 'data',
                visible: true,
                arraysrc: 'gzork:14:c09590',
                array: ['Upper CI (95%)', '.7'],
                symmetric: false,
                arrayminus: ['Lower CI (95%)', '0.57'],
            },
            stackgroup: '',
        });

        dataForestPlot.push(
            {
                mode: 'markers',
                name: 'Forest Plot 3',
                type: 'scatter',
                xsrc: 'gzork:14:c3ff56',
                x: ['Convertion Rate', '1.2'],
                ysrc: 'gzork:14:77b53f',
                y: [null, '55'],
                marker: {
                    symbol: 'circle',
                    opacity: 1,
                },
                error_x: {
                    type: 'data',
                    visible: true,
                    arraysrc: 'gzork:14:05a8c6',
                    array: ['Upper CI (95%)', '.29'],
                    symmetric: false,
                    arrayminussrc: 'gzork:14:45eb59',
                    arrayminus: ['Lower CI (95%)', '0.6'],
                },
                opacity: 1,
                stackgroup: null,
            }
        );

        dataForestPlot.push(
            {
                mode: 'markers',
                name: 'Forest Plot 4',
                type: 'scatter',
                xsrc: 'gzork:14:c3ff56',
                x: ['Convertion Rate', '1'],
                // ysrc: 'gzork:14:77b53f',
                y: ['25', '85'],
                marker: {
                    symbol: 'circle',
                    opacity: 1,
                },
                error_x: {
                    type: 'data',
                    visible: true,
                    // arraysrc: 'gzork:14:05a8c6',
                    array: ['Upper CI (95%)', '0.2'],
                    symmetric: false,
                    arrayminussrc: 'gzork:14:45eb59',
                    arrayminus: ['Lower CI (95%)', '0.4'],
                },
                opacity: 1,
                stackgroup: null,
            }
        );


        data.push({
            x: [
                Math.random(),
                Math.random(),
            ],
            y: [
                Math.random(),
                Math.random(),
            ],
            mode: 'lines',
            type: 'scatter',
            name: 'Повышенный риск',
            stackgroup: 'one',
            line: {
                color: '#cccccc',
                width: 3,
            },
        });

        data.push({
            x: [
                Math.random(),
                Math.random(),
            ],
            y: [
                Math.random(),
                Math.random(),
            ],
            mode: 'lines',
            type: 'scatter',
            name: 'Средний риск',
            stackgroup: 'one',
            line: {
                color: '#eeeeee',
                width: 3,
            },
        });

        data.push({
            x: [Math.random()],
            y: [Math.random()],
            type: 'scatter',
            // mode: 'markers+text',
            mode: 'markers',
            marker: {
                size: 14,
            },
            // text: [key],
            name: 'Name1',
            // textposition: 'bottom center',
        });

        data.push({
            x: [Math.random()],
            y: [Math.random()],
            type: 'scatter',
            // mode: 'markers+text',
            mode: 'markers',
            marker: {
                size: 14,
            },
            // text: [key],
            name: 'Name2',
            // textposition: 'bottom center',
        });

        data.push({
            x: [Math.random()],
            y: [Math.random()],
            type: 'scatter',
            // mode: 'markers+text',
            mode: 'markers',
            marker: {
                size: 14,
            },
            // text: [key],
            name: 'Name3',
            // textposition: 'bottom center',
        });

        data.push({
            x: [Math.random()],
            y: [Math.random()],
            type: 'scatter',
            // mode: 'markers+text',
            mode: 'markers',
            marker: {
                size: 14,
            },
            // text: [key],
            name: 'Name4',
            // textposition: 'bottom center',
        });

        data.push({
            // x: [3, 5, 7],
            x: [1 + Math.random() * 2, 3 + Math.random() * 2, 5 + Math.random() * 2],
            y: [1, 3, 5],
            // y: ['1 График 1', '2 График 2', '3 График 3'],
            type: 'bar',
            marker: {
                color: '#3377FF',
                line: {
                    width: 0.5
                }
            },
            orientation: 'h',
            // mode: 'markers+text',
            // mode: 'markers',
            // marker: {
            //     size: 14,
            // },
            // text: [key],
            name: 'Name5',
            // textposition: 'bottom center',
        });


        this.setState({
            test: 'TEST',
            data: data,
            dataForestPlot: dataForestPlot,
            revision: revision + 1,
        });
    }

    render() {
        const {classes} = this.props;
        const {
            data,
            revision,
            dataForestPlot,
            test,
        } = this.state;

        return (
            <Box className={classes.content}
                 style={{
                     width: "100%",
                 }}
            >
                {test}
                <Box>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <Plot
                                useResizeHandler={false}
                                style={
                                    {
                                        width: "100%",
                                        // height: "100%",
                                    }
                                }
                                config={{
                                    // responsive: true, // TODO: removed because buggy
                                    responsive: false,
                                    displayModeBar: false,
                                    editable: false,
                                    scrollZoom: false,
                                }}
                                data={
                                    [
                                        {
                                            name: 'NAME 2',
                                            legendgroup: 'NAME 2',
                                            type: 'bar',
                                            x: [30, 24, 33],
                                            y: ['giraffes', 'orangutans', 'monkeys'],
                                            orientation: 'h'
                                        },
                                        {
                                            name: 'NAME 1',
                                            legendgroup: 'NAME 1',
                                            type: 'bar',
                                            x: [20, 14, 23],
                                            y: ['giraffes', 'orangutans', 'monkeys'],
                                            orientation: 'h'
                                        },
                                    ]
                                }
                                revision={revision}
                                // graphDiv={'graph'}
                                layout={
                                    {
                                        barmode: 'group',
                                        autoSize: true,
                                        staticPlot: true,
                                        hovermode: false,
                                        bargroupgap: 0,
                                        bargap: 0.1,
                                        xaxis: {
                                            fixedrange: true,
                                            // range: this.getRange((props.chartData || {}).data || []),
                                            showgrid: false,
                                            zeroline: false,
                                            showline: false,
                                            showticklabels: false,
                                            ticks: '',
                                        },
                                        yaxis: {
                                            automargin: true,
                                            zeroline: false,
                                            fixedrange: true,
                                            tickfont: {
                                                // size: '1rem',
                                                size: 16,
                                                family: 'Roboto, Helvetica, Arial, sans-serif',
                                            },
                                        },
                                        showlegend: true,
                                        legend: {
                                            traceorder: 'reversed',
                                            // legendorder: 'normal',
                                            // order: 'normal',
                                            orientation: 'h',
                                            xanchor: 'left',
                                            x: 0,
                                            y: 0, // -0.01,
                                            font: {
                                                size: 16,
                                            },
                                            margin: {
                                                t: 0,
                                                l: 0,
                                            }
                                        },
                                        transition: {
                                            duration: 500,
                                            easing: 'circle-in-out'
                                        },
                                        margin: {
                                            t: 0, //top margin
                                            l: 0, //left margin
                                            r: 0, //right margin
                                            b: 0, //bottom margin
                                            pad: 8,
                                        }
                                    }
                                }
                                staticPlot={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Button color={"primary"} onClick={this.refreshChartData}>Обновить данные графика</Button>
                    <Typography variant='h6' align='center'>
                        Efficacy/Safety (P-Score)
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container justifyContent='center'>
                                <Grid item
                                      // style={{width: "100%", height: "300px"}}
                                >

                                    <Plot
                                        ref={(el) => {
                                            this.plotContainer = el;
                                        }}
                                        useResizeHandler
                                        style={{width: "100%", height: "400px"}}
                                        config={{
                                            displayModeBar: false,
                                            editable: false,
                                            scrollZoom: false,
                                            // responsive: true, // TODO: removed because buggy
                                        }}
                                        data={data}
                                        revision={revision}
                                        graphDiv={'graph'}
                                        layout={{
                                            autosize: true,
                                            xaxis: {
                                                range: [-0.1, 7],
                                                title: {
                                                    text: 'Efficacy',
                                                },
                                            },
                                            yaxis: {
                                                range: [-0.1, 7],
                                                title: {
                                                    text: 'Safety',
                                                },
                                            },
                                            showlegend: true,
                                            legend: {
                                                orientation: 'h',
                                                y: -0.3,
                                            },
                                            transition: {
                                                duration: 2000,
                                                easing: 'circle-in-out'
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Button color={"primary"} onClick={this.refreshChartData}>Обновить данные графика</Button>
                    <Typography variant='h6' align='center'>
                        Efficacy/Safety (P-Score)
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container justifyContent='center'>
                                <Grid item
                                      style={{width: "100%", height: "100%"}}
                                >

                                    <Plot
                                        useResizeHandler
                                        style={{width: "100%", height: "400px"}}
                                        config={{
                                            displayModeBar: false,
                                            editable: false,
                                            scrollZoom: false,
                                        }}
                                        data={dataForestPlot}
                                        revision={revision}
                                        layout={this.layoutForestPlot}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }
}

Charts.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
)(Charts);
