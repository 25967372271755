import React from "react";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {getExpandedBaselineParameterStrict} from "../../utils";
import BaselineParameterStrict from "../BaselineParameterStrict";
import ExpandableContextPanel from "./ExpandableContextPanel";
import {injectIntl} from "react-intl";
import PanelDivider from "../PanelDivider";

function ExpandableBaselineParameterStrictContextPanel(props) {
    const {
        intl,
        baselineParameterStrict,
        needTopSpace
    } = props;

    if (!!baselineParameterStrict?.length) {
        return (
            <React.Fragment>
                <ExpandableContextPanel
                    needTopSpace={needTopSpace}
                    title={intl.formatMessage({
                        id: 'label.contextPanel.baseline_parameters',
                        defaultMessage: 'Baseline parameters'
                    })}
                    expanded={getExpandedBaselineParameterStrict(baselineParameterStrict)}>
                    <BaselineParameterStrict
                        baselineParameterStrict={baselineParameterStrict}
                    />
                </ExpandableContextPanel>
                <PanelDivider/>
            </React.Fragment>
        );
    } else {
        return <React.Fragment/>;
    }
}

ExpandableBaselineParameterStrictContextPanel.propTypes = {
    baselineParameterStrict: PropTypes.array.isRequired,
};

export default compose(injectIntl)(ExpandableBaselineParameterStrictContextPanel);

