import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import RootContainer from '../comp/RootContainer';
import {
    Badge,
    Button, Menu, MenuItem, Typography,
} from "@material-ui/core";
import {updateUserProfile} from "../query/user";
import {
    callAddNosologyToPatient, callSavePatient,
    loadGetListPatient,
    loadGetListPatientByGroup,
    loadGetPatient, loadGetPatientCascade,
    savePatient
} from "../query/patient";
import { withApollo } from '@apollo/client/react/hoc';
import {FormattedMessage, injectIntl} from 'react-intl';
import config from "../config";
import {MUIDataTableOptions} from 'mui-datatables';
import grey from '@material-ui/core/colors/grey';
import Tooltip from "@material-ui/core/Tooltip";
import InviteIcon from '@material-ui/icons/PersonAdd';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PatientDialog from "../comp/patient/PatientDialog";
import Patient from "../comp/patient/Patient";
import {
    datatableDateRenderer,
    generatePatients,
    getAccess,
    getQueryResult,
    getURLSearchParams,
    isDatatableStacked,
    isShowGroups,
    patientsGroupsOpen,
    sortDate,
} from "../utils";
import Link from "@material-ui/core/Link";
import {
    NosologyInputSourceEnum,
    NosologyType, PatientInputDto,
    PatientListOptions,
    PatientType,
    SortTypeEnum,
    TableNameType
} from "../const";
import type {ActivePatient, NosologyDto, PatientNosologyState, PatientHistoryDto} from "../const";
import {green} from "@material-ui/core/colors";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {DyslipidemiaIcon, FibrillationIcon, HeartFailureIcon, IbsIcon} from "../icons";
import HyperIcon from '@material-ui/icons/TrendingDown';
import SvgIcon from "@material-ui/core/SvgIcon";
import DatatableComponent from "../comp/datatable/DatatableComponent";
import Box from "@material-ui/core/Box";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";

const styles = theme => ({
    ...mainStyles(theme),
    nosologyIconsWrapperClassName: {
        width: '35px', // TODO: было для %% '55px',
        display: 'inline-block',
    },
    nosologyIconAnyClassName: {
        // marginRight: 14,
    },
    historyNosologyIconClassName: {
        color: green[700],
    },
    nosologyIconClassName: {
        color: grey[700],
    },
    colorByScoreRed: {
        color: red[500],
    },
    colorByScoreGreen: {
        color: green[500],
    },
    colorByScoreOrange: {
        color: orange[500],
    },
});

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -8, // 2,
        top: -8, // 8,
        // left: -4,
        border: `2px solid ${theme.palette.background.paper}`,
        // padding: '0 4px',
        opacity: 0.5,
    },
}))(Badge);

class Patients extends React.Component {

    patientsParams: ActivePatient = {};
    // +++ 2020.10.27+++ anchorRef = React.useRef<HTMLDivElement>(null);
    // needRerender = false;
    needReload = false;

    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            patientsLoading: false,
            patientsData: {
                [PatientType.PRIVATE]: [],
                [PatientType.PUBLIC]: [],
                [PatientType.EDUCATION]: [],
            },
            patientsPagingData: {
                [PatientType.PRIVATE]: {size: 10, page: 0, totalCount: 0},
                [PatientType.PUBLIC]: {size: 10, page: 0, totalCount: 0},
                [PatientType.EDUCATION]: {size: 10, page: 0, totalCount: 0},
            },
            profileFields: {},
            openPatientDialog: false,
            patient: undefined,
            patientsState: undefined,
            historyNosologies: [],
        };
    }

    state = {
        searchText: '',
        patientsData: {
            [PatientType.PRIVATE]: [],
            [PatientType.PUBLIC]: [],
            [PatientType.EDUCATION]: [],
        },
        patientsPagingData: {
            [PatientType.PRIVATE]: {size: 10, page: 0, totalCount: 0},
            [PatientType.PUBLIC]: {size: 10, page: 0, totalCount: 0},
            [PatientType.EDUCATION]: {size: 10, page: 0, totalCount: 0},
        },
        profileFields: {},
        openPatientDialog: false,
        patient: undefined,
        patientsState: undefined,
        historyNosologies: [],
    };

    abortController = new AbortController();

    setOpenPatient = (open: boolean) => {
        this.setState({openPatientDialog: open});
    }

    setPatient = (patient, nosology: PatientNosologyState) => {
        const {history} = this.props;
        // const { location } = this.props;

        let newLocation = '';
        if (!!nosology) {
            newLocation = this.openPatientNosology(patient, nosology);
        } else {
            newLocation = `/patients?patientType=${this.patientsParams.patientType}${patient ? `&patient=${patient.id}&patientUuid=${patient.uuid}` : ``}${patient && this.patientsParams.activeTab ? `&activeTab=${this.patientsParams.activeTab}` : ``}${this.patientsParams.group ? `&group=${this.patientsParams.group}` : ``}`;
        }
        // const currentLocation = location.pathname + location.search;
        // TODO: +++ commented for test +++ if (currentLocation !== newLocation) {
        //     history.replace(newLocation);
        // }

        const {setContextPatient} = this.context;
        if (setContextPatient !== undefined) {
            if (Boolean(patient)) {
                setContextPatient(
                    {
                        patient: patient,
                    },
                    () => {
                        this.setStatePatientAndLocation(patient, newLocation, history);
                    }
                );
            } else {
                setContextPatient(null,
                    () => {
                        this.setStatePatientAndLocation(patient, newLocation, history);
                    }
                );
            }
        }
    }

    setStatePatientAndLocation = (patient, location, history) => {
        // if (patient) {
            this.setState({
                rerender: new Date().getTime(),
                patient: patient,
                patientsLoading: false,
            }, () => {
                history.push(location);
            });
        // }
    }

    patientNameRenderer = (value, tableMeta, updateValue) => {
        return (
            // <React.Fragment>
            //     <Tooltip title={"Перейти"}>
            //       <IconButton style={{padding: 0}} size="small" color="primary" aria-label="open user" component="span" onClick={() => this.onOpenPatient(value, tableMeta)}>
            //         <ChevronRightRoundedIcon />
            //       </IconButton>
            //     </Tooltip>
            //     {value}

                <Link
                    component="button"
                    variant="body2"
                    onClick={(event) => {
                        if (!isDatatableStacked()) {
                            this.onOpenPatient(true, value, tableMeta);
                        }
                    }}
                    className={this.props.classes.stackedCell + ' ' + this.props.classes.stackedCellTextLeft}
                >
                    {value}
                </Link>
            // </React.Fragment>
        );
    }

    datatableDateRenderer = (value, tableMeta, updateValue) => {
        return (
            <Typography
                component={'span'}
                display="inline"
                variant={'body2'}
                className={this.props.classes.stackedCell}
            >
                    {datatableDateRenderer(value, tableMeta, updateValue)}
            </Typography>
        );
    }

    handleNosologyOpen = (event, historyNosologies, tableMeta) => {
        const {patientsData} = this.state;

        const patient = tableMeta ? patientsData[this.patientsParams.patientType].find((item) => item.id === tableMeta.rowData[0]) : undefined;

        this.setState({
            nosologyAnchorEl: event.currentTarget,
            historyNosologies: historyNosologies,
            historyNosologiesPatient: patient,
        });
    };

    onMenuNosologyClose = () => {
        this.setState({
            nosologyAnchorEl: null,
            historyNosologies: [],
            historyNosologiesPatient: undefined,
        });
    };

    openPatientNosology = (patient, nosology: PatientNosologyState): string => {
        // console.error('+++ nosology:', nosology);
        // const {history} = this.props;
        // const {historyNosologiesPatient} = this.state;
        // const {setPatientDataContext} = this.context;
        // if (setPatientDataContext !== undefined) {
        //     setPatientDataContext({
        //         patientDataContext: historyNosologiesPatient
        //     }, () => {
        //         this.onMenuNosologyClose();
                // let query = `?patient=${historyNosologiesPatient.id}&patientType=${historyNosologiesPatient.type}&patientName=${historyNosologiesPatient.visualName}&nosology=${nosology.id}${(nosology.history || {}).id ? `&history=${nosology.history.id}` : ``}`;
                // let query = `?patient=${patient.id}&patientType=${patient.type}&patientName=${patient.visualName}&nosology=${nosology.id}${this.patientsParams.group ? `&group=${this.patientsParams.group}` : ``}`;
                let query = `?patient=${patient.id}&patientUuid=${patient.uuid}&patientType=${patient.type}&patientName=${patient.visualName}&nosology=${nosology.id}${this.patientsParams.group ? `&group=${this.patientsParams.group}` : ``}${nosology.history ? `&history=${nosology.history.id}` : ``}${nosology.history ? `&action=RESULT` : ``}`;
                let route = '';
                switch (nosology.id) {
                    case NosologyType.HYPERTENSION:
                        route = '/hyper';
                        break;
                    case NosologyType.ATRIAL_FIBRILLATION:
                        route = '/baseline';
                        break;
                    case NosologyType.HEART_FAILURE:
                        route = '/heartfailure';
                        break;
                    case NosologyType.BREAST_CANCER:
                        route = '/breastcancer';
                        break;
                    case NosologyType.DYSLIPIDEMIA:
                        route = '/dyslipidemia';
                        break;
                    case NosologyType.IBS:
                        route = '/ibs';
                        break;
                    default:
                        break;
                }

                return route + query;

                // if (route) {
                //     history.push(route + query);
                // }
            // });
        // }
    }

    onMenuNosologyClick = (nosology: PatientNosologyState) => {
        const {historyNosologiesPatient} = this.state;
        this.onOpenPatient(false, historyNosologiesPatient, undefined, nosology);
    };

    datatableNosologyRenderer = (value, tableMeta, updateValue) => {
        const {classes} = this.props;
        return (
            // <React.Fragment>
            //     <Box>
                value && value.map((nosology) => {
                    let average = this.getAverage(nosology);
                    return (
                        <Box className={classes.nosologyIconsWrapperClassName} component={'span'} key={nosology.id}>
{/*
                            <IconButton
                                // key={nosology.nosology.id}
                                aria-haspopup="true"
                                color="inherit"
                                aria-label="Display nosology"
                                size="small"
                                onClick={event => {
                                    // TODO: отключил popup для выбора нозологии
                                    // if (!isDatatableStacked()) {
                                    //     this.handleNosologyOpen(event, value, tableMeta);
                                    // }
                                }}
                                className={`${this.getNosologyClassName(nosology.history)} ${classes.nosologyIconAnyClassName}`}
                            >
*/}
                                {/*{nosology.history?.data?.therapyCompare ? this.getNosologyBadge(nosology, average) : this.getNosologyIcon(nosology)}*/}
                                {this.getNosologyIcon(nosology, average)}
{/*
                            </IconButton>
*/}
                            {/*TODO: всегда не выводим %%*/}
                            {(false && nosology.history?.data?.therapyCompare) ? this.getNosologyBadge(nosology, average) : undefined}
                        </Box>
                    )
                })
                // </Box>
            // </React.Fragment>
        );
    }

    getAverage = (nosology) => {
        if (!(nosology || {}).history) {
            return undefined;
        }
        try {
            const therapyCompare = JSON.parse((((nosology || {}).history || {}).data || {}).therapyCompare || null);
            let average = 0;
            if (therapyCompare) {
                average = (therapyCompare || []).reduce((accumulator, currentValue) => {
                    accumulator.score = ((accumulator.score || 0) + (currentValue.score || 0));
                    return accumulator;
                }).score / therapyCompare.length;
            } else {
                return undefined
            }
            return Math.floor(Number(average.toFixed(2)));
        } catch (e) {
            return undefined;
        }
    }

    patientsColumns = (patientsType) => {
        const {intl} = this.props;
        const columns = [
            {
                name: "id",
                label: "ID",
                options: {
                    useAsKey: true,
                    display: 'false',
                    searchable: false,
                }
            },
            {
                name: "visualName",
                label: intl.formatMessage({id: 'label.patients.name', defaultMessage: 'Name'}),
                options: {
                    // filter: true,
                    sort: true,
                    // sort: false,
                    customBodyRender: this.patientNameRenderer,
                }
            },
            {
                name: "updateNosology",
                label: intl.formatMessage({id: 'label.patients.date.date_of_calculation_' + patientsType, defaultMessage: 'Date of calculation'}),
                options: {
                    // filter: true,
                    sort: true,
                    // sort: false,
                    customBodyRender: this.datatableDateRenderer,
                    sortCompare: (order) => sortDate(order),
                    searchable: false,
                }
            },
            // {
            //     name: "create",
            //     label: intl.formatMessage({id: 'label.patients.date.create', defaultMessage: 'Create date'}),
            //     options: {
            //         // filter: true,
            //         sort: true,
            //         // sort: false,
            //         customBodyRender: datatableDateRenderer,
            //         sortCompare: (order) => sortDate(order),
            //     }
            // },
            {
                name: "nosologies",
                label: intl.formatMessage({id: 'label.patients.nosology', defaultMessage: 'Nosology'}),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: this.datatableNosologyRenderer,
                    searchable: false,
                }
            },
            // {
            //     name: "staff",
            //     label: intl.formatMessage({id: 'label.patients.owner', defaultMessage: 'Owner'}),
            //     options: {
            //         filter: true,
            //         sort: true,
            //         customBodyRender: datatableStaffRenderer,
            //         sortCompare: (order) => {
            //             return (obj1, obj2) => {
            //                 const a_ = datatableStaffRenderer(obj1.data, obj1);
            //                 const b_ = datatableStaffRenderer(obj2.data, obj2);
            //                 return stringCompare(a_, b_, order);
            //             };
            //         }
            //     }
            // },
        ];

        return columns;
    }

/*
    getSortOrder = (): { name: string, direction: 'asc' | 'desc' } => {
        const {patientsStateContext} = this.context;
        const {patientsState = {}} = this.state;
        // return patientsState.sortOrder || {name: 'updateNosology', direction: 'desc'};
        // return patientsState.sortOrder;
        return ((patientsStateContext || {}).patientsStateContext || {}).sortOrder;
    }
*/

/*
    getSearchText = (): string => {
        const {patientsStateContext} = this.context;
        const {patientsState = {}} = this.state;
        // return patientsState.page;
        return ((patientsStateContext || {}).patientsStateContext || {}).searchText || '';
    }
*/

/*
    getPage = (): number => {
        const {patientsStateContext} = this.context;
        const {patientsState = {}} = this.state;
        // return patientsState.page;
        return ((patientsStateContext || {}).patientsStateContext || {}).page;
    }
*/

/*
    getRowsPerPage = (): number => {
        const {patientsStateContext} = this.context;
        const {patientsState = {}} = this.state;
        // return patientsState.rowsPerPage || 10;
        return ((patientsStateContext || {}).patientsStateContext || {}).rowsPerPage || 10;
    }
*/

/*
    onChangeSortDirection = (tableState) => {
        const {setPatientsStateContext} = this.context;
        const patientsState = tableState;
        if (setPatientsStateContext !== undefined) {
            setPatientsStateContext(
                {
                    patientsStateContext: patientsState,
                });
        }

        this.setState({patientsState: patientsState});
    }
*/

    isDatatableStacked = () => {
        return window.matchMedia('(max-width: 499.95px)').matches;
    }

    onRowClick = (rowData, rowMeta) => {
        if (isDatatableStacked()) {
            this.onOpenPatient(true, {id: rowData[0]}, {rowData: rowData});
        }
    }

    onChangePage = (currentPage: number, pageSize: number, changedColumn, direction, searchText: string) => {
        const patientListOptions: PatientListOptions = {
            size: pageSize,
            page: currentPage,
            sort: this.getSort(changedColumn, direction),
            search: searchText,
        }
        this.loadPatients(true, undefined, false, patientListOptions);
    }

    onChangeRowsPerPage = (rowsPerPage: number, changedColumn, direction, searchText: string) => {
        const patientListOptions: PatientListOptions = {
            size: rowsPerPage,
            page: 0,
            sort: this.getSort(changedColumn, direction),
            search: searchText,
        }
        this.loadPatients(true, undefined, false, patientListOptions);
    }

    getSort = (changedColumn, direction): typeof SortTypeEnum => {
        let sort: typeof SortTypeEnum;
        if (!!changedColumn && !!direction) {
            if (changedColumn === 'updateNosology') {
                sort = direction === 'asc' ? SortTypeEnum.DATA : SortTypeEnum.DATA_DESC;
            } else {
                sort = direction === 'asc' ? SortTypeEnum.NAME : SortTypeEnum.NAME_DESC;
            }
        }
        // console.error('+++ getSort() +++ sort:', sort);
        return sort;
    }

    onColumnSortChange = (changedColumn, direction, rowsPerPage: number, searchText: string) => {
        // console.error('+++ onColumnSortChange() +++ changedColumn:', changedColumn);
        // console.error('+++ onColumnSortChange() +++ direction:', direction);
        // console.error('+++ onColumnSortChange() +++ rowsPerPage:', rowsPerPage);
        const patientListOptions: PatientListOptions = {
            size: rowsPerPage,
            page: 0,
            sort: this.getSort(changedColumn, direction),
            search: searchText,
        }
        // console.error('+++ onColumnSortChange() +++ patientListOptions:', patientListOptions);
        this.loadPatients(true, undefined, false, patientListOptions);
    }

    onServersideSearch = (page: number, rowsPerPage: number, changedColumn, direction, searchText: string) => {
        const patientListOptions: PatientListOptions = {
            size: rowsPerPage,
            page: page,
            sort: this.getSort(changedColumn, direction),
            search: searchText,
        }
        this.loadPatients(true, undefined, false, patientListOptions);
    }

    patientsOptions = (): MUIDataTableOptions => {
        const {intl} = this.props;
        const {contextStaff} = this.context;
        const access = getAccess((contextStaff || {}).role, this.patientsParams.patientType, contextStaff);

        const options: MUIDataTableOptions = {
            usePropAsKey: 'id',
            // filterType: 'checkbox',
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            pagination: true,
            jumpToPage: true,
            rowsPerPageOptions: [10, 20, 100],
            // rowsPerPage: 10,
            selectableRowsHeader: false,
            selectableRows: 'single',
            selectToolbarPlacement: 'none',
            selectableRowsOnClick: false,
            serverSide: true,
            // count: 100,
            onRowClick: (rowData, rowMeta) => this.onRowClick(rowData, rowMeta),
            // responsive: 'scroll',
            customToolbar: () => {
                return (
                    <React.Fragment>
                        {access.patient.create && this.patientsParams.patientType === PatientType.PRIVATE &&
                        <React.Fragment>
                            <Tooltip title={intl.formatMessage({id: 'label.create', defaultMessage: 'Create'})}>
                                <Button color={"primary"} onClick={this.onAddPatient}>
                                    <InviteIcon className={this.props.classes.iconButton}/>
                                    <FormattedMessage id='label.create' defaultMessage='Create'/>
                                </Button>
                            </Tooltip>
                        </React.Fragment>}
                        <React.Fragment>
                            <Tooltip
                                title={intl.formatMessage({id: 'label.patients.reload', defaultMessage: 'Reload'})}>
                                <Button color={"primary"} onClick={this.loadPatients}>
                                    <AutorenewIcon className={this.props.classes.iconButton}/>
                                    <FormattedMessage id='label.patients.reload' defaultMessage='Reload'/>
                                </Button>
                            </Tooltip>
                        </React.Fragment>
                    </React.Fragment>
                );
            },
            // setRowProps: (item: any, rowIndex: number) => ({
            //     onDoubleClick: (row, dataIndex) => {
            //         const {patientsData} = this.state;
            //         this.onOpenPatient(patientsData[this.patientsParams.patientType][rowIndex], undefined);
            //     }
            // }),

/* TODO: commented for test */
            // onTableChange: (action, tableState) => {
            //     if (
            //         action === 'sort'
            //         || action === 'changePage'
            //         || action === 'search'
            //     ) {
            //         const state = {
            //             activeColumn: tableState.activeColumn,
            //             announceText: tableState.announceText,
            //             columnOrder: tableState.columnOrder,
            //             columns: tableState.columns,
            //             count: tableState.count,
            //             expandedRows: tableState.expandedRows,
            //             filterData: tableState.filterData,
            //             filterList: tableState.filterList,
            //             grouping: tableState.grouping,
            //             groupingData: tableState.groupingData,
            //             page: tableState.page,
            //             previousSelectedRow: tableState.previousSelectedRow,
            //             rowsPerPage: tableState.rowsPerPage,
            //             rowsPerPageOptions: tableState.rowsPerPageOptions,
            //             searchProps: tableState.searchProps,
            //             searchText: tableState.searchText,
            //             selectedRows: tableState.selectedRows,
            //             showResponsive: tableState.showResponsive,
            //             sortOrder: tableState.sortOrder,
            //         };
            //         this.onChangeSortDirection(state);
            //     }
            // },
            // searchText: this.getSearchText(),
            // // searchText: this.state.searchText || '',
            // sortOrder: this.getSortOrder(),
            // page: this.getPage() || 0,
            // rowsPerPage: this.getRowsPerPage() || [10, 25, 50],
/* */
        };

        return options;
    }

    onAddPatient = () => {
        this.setOpenPatient(true);
    }

    onOpenPatient = (needLoad: boolean, value, tableMeta, nosology: PatientNosologyState) => {
        const {patientsData} = this.state;
        const {signal} = this.abortController;
        const {client} = this.props;

        const patient = tableMeta ? patientsData[this.patientsParams.patientType].find((item) => item.id === tableMeta.rowData[0]) : value;
        // console.error('+++ onOpenPatient() +++ patient:', patient);

        if (needLoad) {
            loadGetPatient(client, signal, patient.id, patient.uuid)
                .then((patientData) => {
                    // this.setState({patient: undefined}, () => {
                        this.setPatient(patientData, nosology);
                    // });
                });
        } else {
            this.setPatient(value, nosology);
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        // console.error('+++ componentDidUpdate() +++');
        if (prevProps.location.search !== prevProps.history.location.search) {
            // this.needRerender = true;

            // const patientsParamsPREV = getURLSearchParams(prevProps.location.search);
            // const patientsParamsNEW = getURLSearchParams(prevProps.history.location.search);
            // console.error('+++ componentDidUpdate() +++ prevProps.location.pathname === prevProps.history.location:', prevProps.location.pathname === prevProps.history.location);
            // console.error('+++ componentDidUpdate() +++ prevProps.location.pathname:', prevProps.location.pathname);
            // console.error('+++ componentDidUpdate() +++ prevProps.history.location.pathname:', prevProps.history.location.pathname);
            // console.error('+++ componentDidUpdate() +++ prevProps.location.search:', prevProps.location.search);
            // console.error('+++ componentDidUpdate() +++ prevProps.history.location.search:', prevProps.history.location.search);
            // TODO: при смене урла все запросы дропаются!?!
            // this.abortController.abort();

            this.needReload = true;
            this.props.location.search = prevProps.history.location.search;
            this.componentDidMount();
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.patientsParams = getURLSearchParams(this.props.location.search);
        // console.error('+++ patientsParams:', this.patientsParams);

        const {contextPatient} = this.context;
        if (this.patientsParams.patient || (contextPatient || {}).patient) {
            if ((contextPatient || {}).patient) {
                this.onOpenPatient(false, contextPatient.patient, undefined);
            } else {
                this.onOpenPatient(true, {id: this.patientsParams.patient, uuid: this.patientsParams.patientUuid}, undefined);
            }
        } else {
            const {setContextPatient} = this.context;
            if (setContextPatient !== undefined) {
                setContextPatient(null, () => {
                    this.setState({patient: undefined}, () => {
                        this.loadPatients(this.needReload);
                    });
                });
            } else {
                this.loadPatients(this.needReload);
            }
        }

    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    mockPatients = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            resolve(generatePatients());
        });
    }

    patientsGroupsOpen = (routePath: string, same: boolean, callback) => {
        const {signal} = this.abortController;
        const {client} = this.props;

        patientsGroupsOpen(
            client,
            signal,
            routePath,
            this,
            same,
            this.props.history,
            () => {
                if (!!callback) {
                    callback();
                }
                this.forceUpdate();
            }
        );
    }

    openGroups = (callback) => {
        const {recursiveTreeViewSelected} = this.context;

        if (this.props.location.pathname === '/patients') {
            if (isShowGroups(this.props.location.search)) {
                this.patientsGroupsOpen(`/patients?patientType=${this.patientsParams.patientType}${recursiveTreeViewSelected && recursiveTreeViewSelected[this.patientsParams.patientType] ? `&group=${recursiveTreeViewSelected[this.patientsParams.patientType]}` : ``}`, false, callback);
            } else {
                if (!!callback) {
                    callback();
                }
            }
        }
    }

    loadPatients = (reload: boolean = false, patient = undefined, setPatient = true, patientListOptions: PatientListOptions) => {
        const {
            tableStateContext,
        } = this.context;
        // console.error('+++ loadPatients() +++ 1 tableStateContext:', tableStateContext);
        const currentTableStateContext = ((tableStateContext || {}).tableStateContext || {})[TableNameType.PATIENTS + '_' + this.patientsParams.patientType];

        this.openGroups(() => {
            if (reload || (this.state.patientsData[this.patientsParams.patientType] || []).length === 0) {
                this.setState({
                    patientsLoading: true,
                }, () => {
                    const {client} = this.props;
                    const {signal} = this.abortController;

                    // console.error('+++ loadPatients() +++ 2 tableStateContext:', tableStateContext);
                    // console.error('+++ loadPatients() +++ 2 currentTableStateContext:', currentTableStateContext);

                    let _patientListOptions: PatientListOptions = {};
                    if (!!patientListOptions) {
                        _patientListOptions = patientListOptions;
                    } else {
                        _patientListOptions = {
                            page: currentTableStateContext?.page || 0,
                            size: currentTableStateContext?.rowsPerPage || 10,
                            sort: this.getSort(currentTableStateContext?.sortOrder?.name, currentTableStateContext?.sortOrder?.direction),
                            search: undefined,
                        };
                    }
                    // console.error('+++ loadPatients() +++ 2 patientListOptions:', patientListOptions);
                    // console.error('+++ loadPatients() +++ 2 _patientListOptions:', _patientListOptions);

                    let getListQuery;
                    if (this.patientsParams.group) {
                        if (this.patientsParams.patientType === PatientType.PUBLIC || this.patientsParams.patientType === PatientType.PRIVATE) {
                            getListQuery = loadGetListPatientByGroup(client, signal, this.patientsParams.group, _patientListOptions);
                        }
                        if (this.patientsParams.patientType === PatientType.EDUCATION) {
                            getListQuery = loadGetPatientCascade(client, signal, this.patientsParams.group, _patientListOptions);
                        }
                    } else {
                        getListQuery = loadGetListPatient(client, signal, this.patientsParams.patientType, _patientListOptions);
                    }

                    getListQuery
                        .then((patients) => {
                            // const group = this.patientsParams.group || -1;
                            // const stateData = {
                            //     [group]: patients || [],
                            // };
                            this.needReload = false;
                            this.setState(prevState => ({
                                patientsData: {
                                    ...prevState.patientsData,
                                    [this.patientsParams.patientType]: patients?.payload || [],
                                    // [this.patientsParams.patientType]: stateData,
                                },
                                patientsPagingData: {
                                    ...prevState.patientsPagingData,
                                    [this.patientsParams.patientType]: {
                                        size: _patientListOptions?.size,
                                        page: _patientListOptions?.page,
                                        totalCount: patients?.totalCount,
                                    },
                                },
                                rerender: new Date().getTime(),
                                patient: setPatient ? patient : prevState.patient,
                                patientsLoading: false,
                            }), () => {
                            });
                        });
                });
            } else {
                this.setState(prevState => ({
                    rerender: new Date().getTime(),
                    // patient: patient,
                    patient: setPatient ? patient : prevState.patient,
                    patientsLoading: false,
                }), () => {
                });
            }
        });
    };

    onProfileChange = (name, value) => {
        const {profileFields} = this.state;

        if (value && value.length > 0) {
            profileFields[name] = value;
        } else {
            delete profileFields[name];
        }

        this.setState({
            profileFields: profileFields,
        })
    };

    onProfileSave = (e) => {
        const {profileFields} = this.state;
        const {client, intl} = this.props;

        if (Object.keys(profileFields).length === 0) {
            return;
        }

        client.mutate({
            mutation: updateUserProfile,
            errorPolicy: 'all',
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
            },
            variables: {
                profile: profileFields,
            },
        }).then(({data, _}) => {
            if (data && data.updateUserProfile) {
                this.showNotify(intl.formatMessage({
                    id: 'message.data.save.success',
                    defaultMessage: 'Save success.',
                }), 'success');
                this.loadPatients();
            }
        });
    };

    showNotify = (message, type) => {
        const {showNotify} = this.context;
        if (showNotify) {
            showNotify(message, type);
        }
    };

    onPatientSubmit = ({name}, selectedNosologies, callback) => {
        const {client, intl} = this.props;
        const {signal} = this.abortController;

        const patientInputDto: PatientInputDto = {
            uuid: null,
            name: name,
            type: this.patientsParams.patientType,
            nosologies: selectedNosologies.map((selectedNosology) => {
                return {
                    id: selectedNosology.id,
                    sourceDetection: [NosologyInputSourceEnum.MANUAL],
                }
            }),
        };
        callSavePatient(client, signal, patientInputDto).then((patientResponse) => {
            let response = true;
            if (!!patientResponse) {
                this.showNotify(intl.formatMessage({id: 'message.patient.added'}, {patientName: name}), 'success');
                this.setOpenPatient(false);

                this.onOpenPatient(true, patientResponse, undefined);
                // this.loadPatients(true, patientData); // TODO: может быть загружать в фоне, т.к. сейчас сразу переход на пациента, и список не загрузится!!!
                this.loadPatients(true, undefined, false); // TODO: может быть загружать в фоне, т.к. сейчас сразу переход на пациента, и список не загрузится!!!
            } else {
                response = false;
            }

            if (!!callback) {
                callback(response);
            }
        });
    };

    onPatientClose = (reload: boolean = false) => {
        this.setPatient(undefined);
        this.loadPatients();
    }

    getNosologyName(nosology: NosologyDto): string {
        let name = '';
        if (nosology) {
            const {contextStaff} = this.context;
            for (let contextStaffNosology of contextStaff.nosologies) {
                if (contextStaffNosology.id === nosology.id) {
                    name = contextStaffNosology.name;
                    break;
                }
            }
        }
        return name;
    }

    getNosologyIcon(nosology: PatientNosologyState, average: number): SvgIcon {
        const {classes} = this.props;
        const className = this.getNosologyClassName(nosology.history, average);
        switch (nosology.id) {
            case NosologyType.ATRIAL_FIBRILLATION:
                return <FibrillationIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
            case NosologyType.HYPERTENSION:
                return <HyperIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
            case NosologyType.HEART_FAILURE:
                return <HeartFailureIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
            case NosologyType.DYSLIPIDEMIA:
                return <DyslipidemiaIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
            case NosologyType.IBS:
                return <IbsIcon fontSize="small" className={`${className} ${classes.nosologyIconAnyClassName}`} />;
            default: return undefined;
        }
    }

    getNosologyBadge(nosology: PatientNosologyState, average: number): any {
        return (
            <StyledBadge
                showZero={true}
                max={100}
                badgeContent={average + '%'}
                color="primary"
            >
            </StyledBadge>
        );
    }

    getNosologyClassName(history: PatientHistoryDto/*, average: number*/): Object {
        const {classes} = this.props;
        return history ? classes.historyNosologyIconClassName : classes.nosologyIconClassName;
        // TODO: %% цвет иконки серый или зеленый, зависит только от истории, оставил, если потребуется
/*
        if (average === undefined) {
            return history ? classes.historyNosologyIconClassName : classes.nosologyIconClassName;
        } else {
            if (average === 0) {
                return classes.colorByScoreRed;
            } else if (average === 100) {
                return classes.colorByScoreGreen;
            } else {
                return classes.colorByScoreOrange;
            }
        }
*/
    }

    getDatatableTitle = () => {
        const { patientGroupSelected } = this.context;
        const {intl} = this.props;
        if (this.patientsParams.patientType === PatientType.PRIVATE) {
            return intl.formatMessage({
                id: 'label.patients',
                defaultMessage: 'Patients'
            });
        } else {
            return (
                <React.Fragment>
                    <Typography variant={"h6"}>
                        {
                            this.patientsParams.patientType === PatientType.PUBLIC ?
                            intl.formatMessage({
                                id: 'page.patients.public',
                                defaultMessage: 'Patients'
                            })
                            :
                            intl.formatMessage({
                                id: 'page.patients.education',
                                defaultMessage: 'Education'
                            })
                        }
                    </Typography>
                    {
                        (patientGroupSelected && patientGroupSelected[this.patientsParams.patientType] && patientGroupSelected[this.patientsParams.patientType].name) &&
                        <Typography variant={"body2"}>
                            {patientGroupSelected[this.patientsParams.patientType].name}
                        </Typography>
                    }
                </React.Fragment>
            );
        }
    }

    render() {
        const {intl, classes} = this.props;
        const {patientsData, patientsPagingData, rerender, historyNosologies, patientsLoading} = this.state;
        const {openPatientDialog, patient, nosologyAnchorEl} = this.state;
        const { contextStaff, contextPatient } = this.context;

        return (
            <RootContainer style={{minWidth: 0}}>
                {
                    contextStaff &&
                    !patient && !((contextPatient ||{}).patient) ?
                        (!this.patientsParams.patient && this.patientsParams.patientType ?
                            <Box className={patientsLoading ? classes.disabled : ''}>
                                    <DatatableComponent
                                        intl={intl}
                                        tableName={TableNameType.PATIENTS + '_' + this.patientsParams.patientType}
                                        title={this.getDatatableTitle()}
                                        data={patientsData[this.patientsParams.patientType] || []}
                                        pagingData={patientsPagingData[this.patientsParams.patientType] || {}}
                                        columns={this.patientsColumns(this.patientsParams.patientType)}
                                        options={this.patientsOptions()}
                                        rerender={rerender}
                                        disableShadow={false}
                                        disableBorderRadius={false}
                                        onChangePage={this.onChangePage}
                                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                                        onColumnSortChange={this.onColumnSortChange}
                                        onServersideSearch={this.onServersideSearch}
                                    />
                                    <Menu
                                        id='menu-nosology'
                                        anchorEl={nosologyAnchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        onKeyDown={this.onMenuNosologyClose}
                                        open={Boolean(nosologyAnchorEl)}
                                        onClose={this.onMenuNosologyClose}
                                    >
                                        {historyNosologies.map(nosology => (
                                            <MenuItem key={nosology.id} onClick={event => this.onMenuNosologyClick(nosology)}>
                                                <ListItemIcon>
                                                    {this.getNosologyIcon(nosology, this.getAverage(nosology))}
                                                </ListItemIcon>
                                                <ListItemText primary={this.getNosologyName(nosology)} className={this.getNosologyClassName(nosology.history, this.getAverage(nosology))} />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                            </Box>
                            : undefined)
                        : (patient && contextStaff && this.patientsParams.patientType ?
                        <Patient
                            onClose={this.onPatientClose}
                            patient={patient}
                            patientsData={patientsData[this.patientsParams.patientType]}
                        /> : undefined)
                }
                {contextStaff && openPatientDialog &&
                    <PatientDialog
                        onDialogClose={() => this.setOpenPatient(false)}
                        onSubmit={this.onPatientSubmit}
                        patientsData={patientsData[this.patientsParams.patientType] || []}
                        isShow={openPatientDialog}
                        submitButtonLabel={'label.add'}
                        isNosologyAdded={() => false}
                    />
                }

            </RootContainer>
        );
    }
}

Patients.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

Patients.contextTypes = {
    showNotify: PropTypes.func,
    contextPatient: PropTypes.object,
    contextStaff: PropTypes.object,
    setContextPatient: PropTypes.func,
    patientsStateContext: PropTypes.object,
    setPatientsStateContext: PropTypes.func,
    setPatientDataContext: PropTypes.func,
    setRecursiveTreeViewSelected: PropTypes.func,
    patientGroupSelected: PropTypes.object,
    recursiveTreeViewSelected: PropTypes.object,
    patientsGroups: PropTypes.object,
    setPatientsGroups: PropTypes.func,
    setRecursiveTreeViewExpanded: PropTypes.func,
    recursiveTreeViewExpanded: PropTypes.object,
    tableStateContext: PropTypes.object,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(Patients);
