import React from "react";
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";

// import Plotly from "plotly.js-basic-dist-min";
// import createPlotlyComponent from "react-plotly.js/factory";
// const Plot2 = createPlotlyComponent(Plotly);

// import plotComponentFactory from "react-plotly.js/factory";
// const Plotly = require('plotly.js/lib/core');
// Plotly.register([
//     require('plotly.js/lib/heatmap'),
//     require('plotly.js/lib/choropleth'),
//     require('plotly.js/lib/scattergeo'),
//     require('plotly.js/lib/bar'),
// ]);
// const Plot = plotComponentFactory(Plotly);
import Plot from 'react-plotly.js';
import {newAnyDataRef} from "../../utils";

const styles = theme => ({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    ...mainStyles(theme),
    contentBox: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
});

class GroupedBarChart extends React.Component {

    yLabelOffset: number = 0;

    chartConfig = {
        barHeight: 30,
    }

    constructor(props) {
        super(props);
        this.state = this.initState(props);
    }

    initState = (props) => {
        const height = this.getChartHeight((props.chartData || {}).data || []);
        const state = {
            height: height,
            data: this.getZeroData((props.chartData || {}).data || []),
            revision: 0,
            showChart: false,
            layout: {
                autoSize: true,
                staticPlot: true,
                hovermode: false,
                barmode: 'group',
                bargroupgap: 0,
                bargap: 0.1,
                xaxis: {
                    fixedrange: true,
                    range: this.getRange((props.chartData || {}).data || []),
                    showgrid: false,
                    zeroline: false,
                    showline: false,
                    showticklabels: false,
                    ticks: '',
                },
                yaxis: {
                    automargin: true,
                    zeroline: false,
                    fixedrange: true,
                    tickfont: {
                        // size: '1rem',
                        size: 16,
                        family: 'Roboto, Helvetica, Arial, sans-serif',
                    },
                },
                showlegend: true,
                legend: {
                    traceorder: 'reversed',
                    orientation: 'h',
                    xanchor: 'left',
                    x: 0,
                    y: 0, // -0.01,
                    font: {
                        size: 16,
                    },
                    margin: {
                        t: 0,
                        l: 0,
                    }
                },
                transition: {
                    duration: 500,
                    easing: 'circle-in-out'
                },
                margin: {
                    t: 0, //top margin
                    l: 0, //left margin
                    r: 0, //right margin
                    b: 0, //bottom margin
                    pad: 8,
                }
            },
        };

        return state;
    }

    getMaxXValue = (data): number => {
        let maxXValue = 0;
        data.forEach((dataItem, indexDataItem) => {
            dataItem.x.forEach((xItems, indexXItems) => {
                if (maxXValue < xItems) {
                    maxXValue = xItems;
                }
            });
        });

        return maxXValue;
    }

    getChartHeight = (data): string => {
        return data.length * data[0].x.length * this.chartConfig.barHeight + 50 + 'px';
    }

    componentDidMount() {
        this.refreshChartData(false);
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        const isDataChanged = this.isDataChanged(this.props.chartData.data, nextProps.chartData.data);
        return isDataChanged || this.state.revision === 0 || this.state.refreshChartData;
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const isDataChanged = this.isDataChanged(prevProps.chartData.data, this.props.chartData.data);
        if (isDataChanged) {
            this.refreshChartData(false);
        }
    }

    isDataChanged = (prevData: [{ x: [], y: [] }], nextData: [{ x: [], y: [] }], ignoringZeros: boolean = false): boolean => {
        if (prevData.length !== nextData.length) {
            return true;
        }

        for (let i = 0; i < prevData.length; i++) {
            if (
                prevData[i].x.length !== nextData[i].x.length
                ||
                prevData[i].y.length !== nextData[i].y.length
            ) {
                return true;
            }

            for (let j = 0; j < prevData[i].x.length; j++) {
                if (
                    (
                        !ignoringZeros
                        &&
                        prevData[i].x[j] !== nextData[i].x[j]
                    )
                    ||
                    (
                        ignoringZeros
                        &&
                        prevData[i].x[j] !== nextData[i].x[j]
                        &&
                        (prevData[i].x[j] !== 0 && nextData[i].x[j] !== 0)
                    )
                ) {
                    return true;
                }
            }

            for (let j = 0; j < prevData[i].y.length; j++) {
                if (
                    (
                        !ignoringZeros
                        &&
                        prevData[i].y[j] !== nextData[i].y[j]
                    )
                    ||
                    (
                        ignoringZeros
                        &&
                        prevData[i].y[j] !== nextData[i].y[j]
                        &&
                        (prevData[i].y[j] !== 0 && nextData[i].y[j] !== 0)
                    )
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    refreshChartData = (showChart: boolean = true) => {
        this.setState({
            showChart: showChart,
            refreshChartData: true,
        }, () => {
            const {
                layout,
                data,
                revision,
            } = this.state;
            layout.xaxis.range = this.getRange(showChart ? data : this.getActualData());
            setTimeout(() => {
                this.setState({
                    height: this.getChartHeight(showChart ? data : this.getActualData()),
                    data: this.getZeroData(showChart ? data : this.getActualData()),
                    revision: revision + 1,
                    showChart: true,
                    layout: newAnyDataRef(layout),
                }, () => {
                    const {
                        revision,
                        layout,
                    } = this.state;
                    setTimeout(() => {
                        const actualData = this.getActualData();
                        layout.xaxis.range = this.getRange(actualData);
                        const height = this.getChartHeight(actualData);
                        this.setState({
                            height: height,
                            layout: newAnyDataRef(layout),
                            data: actualData,
                            revision: revision + 1,
                            showChart: true,
                        }, () => {
                            this.setState({
                                refreshChartData: false,
                            });
                        });
                    });
                });
            });
        });
    }

    getRange = (data) => {
        return [this.yLabelOffset, Math.ceil(this.getMaxXValue(data))];
    }

    getZeroData = (data) => {
        const zeroData = newAnyDataRef(data);
        data.forEach((dataItem, indexDataItem) => {
            dataItem.x.forEach((xItems, indexXItems) => {
                zeroData[indexDataItem].x[indexXItems] = 0;
            });
        });
        return zeroData;
    }

    getActualData = () => {
        const {
            chartData,
        } = this.props;
        return newAnyDataRef((chartData || {}).data || []);
    }

    render() {
        const {
            chartData,
            useResizeHandler,
            responsive,
        } = this.props;

        const {
            data,
            revision,
            layout,
            height,
            showChart,
        } = this.state;
        const isDataChanged = this.isDataChanged(data, chartData.data, true);

        if (
            !chartData
            ||
            !showChart
            ||
            isDataChanged
        ) {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    {/*<Button color={"primary"} onClick={this.refreshChartData}>REFRESH</Button>*/}
                    {(showChart && !isDataChanged) &&
                    <Plot
                        useResizeHandler={useResizeHandler || false}
                        style={
                            {
                                width: "100%",
                                // height: "100%",
                                height: height,
                            }
                        }
                        config={{
                            // responsive: true, // TODO: removed because buggy
                            responsive: responsive || false,
                            displayModeBar: false,
                            editable: false,
                            scrollZoom: false,
                        }}
                        data={data}
                        revision={revision}
                        graphDiv={'graph'}
                        layout={layout}
                        staticPlot={true}
                    />
                    }
                </Grid>
            </Grid>
        );
    }
}

GroupedBarChart.propTypes = {
    classes: PropTypes.object.isRequired,
    chartData: PropTypes.object.isRequired,
    useResizeHandler: PropTypes.bool,
    responsive: PropTypes.bool,
};

export default compose(
    withStyles(styles),
)(GroupedBarChart);
