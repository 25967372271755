import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Ads from '../comp/Ads';
import Divider from '../comp/PanelDivider';
import config from '../config';

const styles = {
  root: {
    // minWidth: 275,
    minWidth: 0,
  },
};

function RootContainer(props) {
  const { classes, children } = props;

  return (
    <div className={classes.root}>
      {config.options.ads.enable && (
        <div>
          <Ads />
          <Divider />
        </div>
      )}
      {children}
    </div>
  );
}

RootContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RootContainer);
