import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
    Box,
} from "@material-ui/core";
import {withApollo} from '@apollo/client/react/hoc';
import {DrugDosageJoinedDrug} from "../../const";
import {FormattedMessage, injectIntl} from "react-intl";
import grey from "@material-ui/core/colors/grey";
import Typography from "@material-ui/core/Typography";
import Autocomplete2 from "../drug/Autocomplete2";

const styles = theme => ({
    ...mainStyles(theme),
    drugIcon: {
        verticalAlign: 'middle',
        color: grey[500],
    },
    drugName: {
        marginLeft: theme.spacing(2),
    },
    therapyWrapper: {
        marginTop: theme.spacing(2),
    },
});

class PatientDrugsView extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            addedDrugsData: [],
        };
    }

    // shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        const {patient} = this.props;
        // console.error('+++ PatientDrugsView() +++ patient:', patient);
        // console.error('+++ PatientDrugsView() +++ nextProps.patient?.drugs:', nextProps.patient?.drugs);
        // console.error('+++ PatientDrugsView() +++ nextProps.drugs:', nextProps.drugs);
        // console.error('+++ PatientDrugsView() +++ nextProps:', nextProps);
        // console.error('+++ PatientDrugsView() +++ nextContext:', nextContext);
        // console.error('+++ PatientDrugsView() +++ this.state.addedDrugsData:', this.state.addedDrugsData);
        if (JSON.stringify(this.state.addedDrugsData || []) !== JSON.stringify(nextProps.patient?.drugs || [])) {
            // console.error('+++ PatientDrugsView() +++ this.setState -> this.state.addedDrugsData:', this.state.addedDrugsData);
            this.setState({
                addedDrugsData: nextProps.patient?.drugs || [],
            });
        }
    }

    componentDidMount() {
        const {patient} = this.props;
        this.setState({
            addedDrugsData: patient.drugs,
        });
    }

    onAddDrugSubmit = () => (drugs: DrugDosageJoinedDrug[]) => {
        this.setState({
            addedDrugsData: drugs,
        });
        this.savePatientDrugs(drugs);
    }

    savePatientDrugs = (drugs: DrugDosageJoinedDrug[]) => {
        const {patient} = this.props;

        patient.drugs = drugs;
        // const drugDosageIds: number[] = drugs.map((drug) => drug.id);
        // callSavePatientDrugs(client, signal, patient.id, drugDosageIds)
        //     .then((result) => {
        //         patient.drugs = drugs;
        //     });
    }

    render() {
        const {
            addedDrugsData,
        } = this.state;

        const {classes, isPrescriptionReadonly, showTitle} = this.props;
        return (
            <Box>
            {/*<Paper*/}
            {/*    className={`${classes.contentBox} ${classes.therapyWrapper} ${isPrescriptionReadonly ? classes.disabled : ''}`}*/}
            {/*    variant={"outlined"}*/}
            {/*>*/}
                {showTitle &&
                    <Typography variant={"h6"} color={"primary"} className={classes.contentBoxTopSpace}>
                        <FormattedMessage id='label.patient.drugs' defaultMessage='Drugs'/>
                    </Typography>
                }
                <Autocomplete2
                    values={addedDrugsData || []}
                    onChange={this.onAddDrugSubmit()}
                    isPrescriptionReadonly={!!isPrescriptionReadonly}
                />
            {/*</Paper>*/}
            </Box>
        );
    }
}

PatientDrugsView.propTypes = {
    intl: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    // drugs: PropTypes.object,
    // savePatientDrugs: PropTypes.func.isRequired,
    // getPatientDrugs: PropTypes.func.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    showTitle: PropTypes.bool,
};

PatientDrugsView.defaultProps = {
    showTitle: true,
    // baseline: [],
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(PatientDrugsView);
