import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import Typography from "@material-ui/core/Typography";
import {BaselineParametrSectionDto} from "../../const";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import type {BaselineParameterDto} from "../../const";
import CardContent from "@material-ui/core/CardContent";

const SectionPanel = withStyles((theme) => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            border: 'none',
        },
        // '&:not(:last-child)': {
        //     borderBottom: 0,
        // },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: '0 !important',
        },
        '&$expanded': {
            // margin: 'auto',
        },

        '& .MuiAccordionSummary-root.Mui-focused': {
            backgroundColor: 'unset !important',
        },
        '& .MuiAccordionSummary-root.Mui-focusVisible': {
            backgroundColor: 'unset !important',
        },
    },
    // expanded: {},
}))(Accordion);

const SectionPanelSummary = withStyles((theme) => ({
    root: {
        padding: 0,
        minHeight: 56,
        // height: 56,
        '&$expanded': {
            minHeight: 56,
            // height: 56,
        },
        '&.Mui-expanded': {
            margin: '0 !important',
            minHeight: '56px !important',
        },
        content: {
            margin: '0 !important',
            minHeight: '56px !important',
            '&$expanded': {
                minHeight: '56px !important',
                margin: '0 !important',
            },
        },
    },
    // expanded: {},
    content: {
        margin: '0 !important',
        minHeight: '56px !important',
        '&$expanded': {
            margin: '0 !important',
            minHeight: '56px !important',
        },
    },
}))(AccordionSummary);

const SectionPanelDetails = withStyles((theme) => ({
    root: {
        padding: 0,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    // expanded: {},
    // content: {
    //     margin: '0',
    //     '&$expanded': {
    //         margin: '0',
    //     },
    // },
}))(AccordionDetails);

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    wrapper: {
        alignItems: 'center',
        display: 'inline-flex',
    },
    popperMenu: {
        zIndex: 22222,
        // left: '-12px !important',
    },
    toolbarIconButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            textAlign: 'left',
            width: '100%',
        },
    },
    fullWidth: {
        width: '100%',
    },
    noPadding: {
        padding: '0 !important',
    },
    flexGrow: {
        flexGrow: 1,
    },
}));

interface SectionParametersViewProps {
    section: BaselineParametrSectionDto,
    parametersView?: (number, BaselineParameterDto[]) => any,
    patientDrugs?: () => any,
    // readonly: boolean,
}

export default function SectionParametersView(props: SectionParametersViewProps) {
    const classes = useStyles();

    const {
        section,
        parametersView,
        patientDrugs,
        // readonly,
    } = props;

    const [expanded, setExpanded] = React.useState(section.expanded);

    React.useEffect(() => {
        setExpanded(section?.expanded);
    }, [section?.expanded]);
    // React.useEffect(() => {
    //     setNotesState(orderingNotes(notes));
    // }, [notes]);

    const toggleAcordion = (needOpen: boolean) => {
        if (needOpen !== undefined) {
            setExpanded(needOpen);
        } else {
            setExpanded((prev) => !prev);
        }
    };

    // console.error('+++ render anchorRefSummary:', anchorRefSummary);
    // console.error('+++ render anchorRefDetails:', anchorRefDetails);
    // console.error('+++ render notes:', notes);
    // console.error('+++ render notesState:', notesState);

    return (
        // <Card className={classes.contentBoxBottomSpace}>
        //     <CardContent className={classes.cardContentAccordion}>
        //         <Box className={classes.contentBoxCard}>
                    <SectionPanel
                        square={true}
                        expanded={expanded}
                    >
                        <SectionPanelSummary expandIcon={<ExpandMoreIcon onClick={() => toggleAcordion()}/>}>
                            <Grid container
                                  spacing={1}
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                  // className={classes.contentBoxTopSpace}
                                  className={classes.noPadding}
                            >
                                <Grid item className={classes.noPadding + ' ' + classes.flexGrow}>
                                    <Typography color={"primary"} variant={"h6"} onClick={() => toggleAcordion()}>
                                        {section.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </SectionPanelSummary>
                        <SectionPanelDetails>
                            <Grid container
                                  // spacing={1}
                                  direction={"column"}
                                  className={classes.noPadding}
                            >
                                <Grid item className={classes.noPadding}>
                                    <Box className={classes.fullWidth}>
                                        <Box key={section.id}>
                                            <Box key={section.id}>
                                                {(!!parametersView && !!section.parameters) && parametersView(section.id, section.parameters)}
                                                {(!!patientDrugs) && patientDrugs()}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </SectionPanelDetails>
                    </SectionPanel>
        //         </Box>
        //     </CardContent>
        // </Card>
    );
}
