
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Tabs, Tab } from "@material-ui/core";

const styles = theme => ({
});

const LangTab = withStyles(theme => ({
  root: {
    minWidth: 48,
  }
}))(props => <Tab component='span' {...props} />);

class LangTabs extends React.Component {

  constructor(props) {
    super(props);

    this.state = { };
  }

  onTabChange = (event, newValue) => {
    this.setState({
      selTab: newValue,
    });

    const { onChange } = this.props;
    if (onChange) {
      onChange(newValue);
    }
  };

  render() {
    const { selTab } = this.state;
    const { tabs } = this.props;

    return (
      <Tabs
        indicatorColor='primary'
        textColor='primary'
        value={Boolean(selTab) ? selTab : 0}
        onChange={this.onTabChange}>
        {tabs.map((tab, i) => (
          <LangTab key={i} label={tab} />
        ))}
      </Tabs>
    );
  }
}

LangTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func,
};

export default compose(
  withStyles(styles),
)(LangTabs);
