import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Typography,
    FormControl,
    RadioGroup,
    Radio,
} from "@material-ui/core";
import {PaperColorSelect, SelectType} from "../PaperColor";
import SelTextView from "../SelTextView";
import PanelDivider from "../PanelDivider";
import {getHPThTypeCharts, getHPTherapyPscore} from "../../query/HPBaseline";
import {FormattedMessage, injectIntl} from "react-intl";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import mainStyles from "../../styles/mainStyles";
import EffSafChart from "./EffSafChart";
import DeviceView from "../DeviceView";
import TherapyCombView from "../TherapyCombView";
import {getQueryResult, getServiceMessages} from "../../utils";
import ExpandChartsPanel from "../baseline/ExpandChartsPanel";
import {HelpDrugsTextsView} from "../common/HelpDrugsTextsView";
import ServiceCheckbox from "../button/ServiceCheckbox";
import {MessageType} from "../../const";
import {TypographySubtitle} from "../../pages/CommonElements";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import type {ServiceMessage} from "../../const";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";
import config from "../../config";
import TherapyView from "../TherapyView";

const styles = theme => ({
    ...mainStyles(theme),
    deviceDescr: {
        marginLeft: '0.5rem',
    },
});

class HeartFailureSelectTherapy extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);
        const {intl} = this.props;

        this.thHeads = [
            intl.formatMessage({id: 'label.hp.therapy.type.1', defaultMessage: 'Initial therapy'}),
            intl.formatMessage({id: 'label.hp.therapy.type.2', defaultMessage: 'Step 2'}),
            intl.formatMessage({id: 'label.hp.therapy.type.3', defaultMessage: 'Step 3'}),
            intl.formatMessage({id: 'label.hp.therapy.type.4', defaultMessage: 'Resistant hypertension'}),
        ];

        this.state = {};
    }

    componentDidMount() {
        updateShowPanel(null);
        setLoadChartsMethod(null);
        // this.loadTherapyInfo();

        this.setSelected();
    }

    setSelected = () => {
        const {
            getSteps,
            nosologyData,
            updateContextPanel,
        } = this.props;
        if (!nosologyData.touched) {
            const steps = getSteps();
            ((steps || []).items || []).forEach((step) => {
                if (step.selected) {
                    nosologyData.steps.selThId = step.id;
                }
            });
            updateContextPanel();
        }

        // console.error('+++ nosologyData.steps:', nosologyData.steps);
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    // loadTherapyInfo = () => {
    //     const {client, getTherapyInfo, setTherapyInfo, nosologyData, patientId} = this.props;
    //     const {signal} = this.abortController;
    //     const therapyInfo = getTherapyInfo();
    //
    //     if (!Boolean(therapyInfo) || (nosologyData && nosologyData.touched)) {
    //         nosologyData.therapyInfo = undefined;
    //         loadGetTherapyInfo(client, signal, patientId)
    //             .then(therapyInfo => {
    //                 setTherapyInfo(therapyInfo);
    //             });
    //     }
    // };

    loadCharts = (callback) => {
        const {client, getSelectVars, getReferents, getSubgroups} = this.props;
        const {signal} = this.abortController;
        const referents = getReferents();
        const subgroups = getSubgroups();

        if (Boolean(referents) && referents.items.length > 0 && referents.selId > 0) {
            client.query({
                query: getHPThTypeCharts,
                variables: {
                    selVarIds: getSelectVars(),
                    refId: referents.selId,
                    inSubgroupId: subgroups ? subgroups.selId : undefined,
                },
                context: {
                    uri: config.options.server.api_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                // .then(result => (result.data || {}).getHPThTypeCharts)
                // .then(result => JSON.parse(JSON.stringify(result)))
                .then(result => getQueryResult(result?.data, 'getHPThTypeCharts'))
                .then(charts => {
                    this.setState({
                        charts: charts,
                    }, () => {
                        if (!!callback) {
                            callback();
                        }
                    });
                });
        }
    };

    loadPscore = () => {
        const {client, getSelectVars, getSubgroups} = this.props;
        const {signal} = this.abortController;
        const subgroups = getSubgroups();

        client.query({
            query: getHPTherapyPscore,
            variables: {
                selVarIds: getSelectVars(),
                inSubgroupId: subgroups ? subgroups.selId : undefined,
            },
            context: {
                fetchOptions: {
                    signal,
                },
            },
        })
            // .then(result => (result.data || {}).getHPTherapyPscore)
            // .then(result => JSON.parse(JSON.stringify(result)))
            .then(result => getQueryResult(result?.data, 'getHPTherapyPscore'))
            .then(pscore => {
                this.setState({
                    pscore: (pscore || []).map(ps => ({
                        x: ps.efficacy,
                        y: ps.safety,
                        name: ps.name,
                    }))
                });
            });
    };

    therapyView = () => {
        const {
            classes,
            setTherapyType,
            getTherapyCombNamesWithItem,
            getSteps,
            isPrescriptionReadonly,
            isNoStepTherapies,
        } = this.props;
        const steps = getSteps();

        // console.error(' +++---+++ steps.selThId:', steps.selThId);

        if (steps && steps.items && steps.items.length > 0/* && steps.items.some((step) => !!(step.therapies || []).length || !!(step.supportTherapies || []).length)*/) {
            return (
                <Grid container spacing={2} className={isPrescriptionReadonly ? classes.disabled : ''}>
                    {steps.items
                        .map(step => {
                            const serviceWarningCheckBox = getServiceMessages(step.messages, MessageType.WARNING);

                            return (
                                <Grid key={step.id} item
                                      xl={6}
                                      lg={6}
                                      xs={12}
                                      sm={12}
                                      md={12}>
                                    <PaperColorSelect
                                        className={classes.contentBox}
                                        seltype={steps.selThId === step.id ? SelectType.POSITIVE : SelectType.NONE}>
                                        {!isNoStepTherapies(step) &&
                                            <FormControlLabel
                                                control={
                                                    <ServiceCheckbox
                                                        color={"primary"}
                                                        checked={steps.selThId === step.id}
                                                        value={step.id}
                                                        onChange={e => {
                                                            if (isPrescriptionReadonly) {
                                                                e.preventDefault();
                                                                return;
                                                            }

                                                            if (e.target.checked) {
                                                                setTherapyType(step.id);
                                                            }
                                                        }}
                                                        showService={serviceWarningCheckBox.showService}
                                                        text={serviceWarningCheckBox.messages}
                                                        type={serviceWarningCheckBox.type}
                                                    />
                                                }
                                                label={<SelTextView>{step.name}</SelTextView>}
                                            />
                                        }
                                        {isNoStepTherapies(step) &&
                                            <SelTextView>{step.name}</SelTextView>
                                        }
                                        {this.getServiceMessagesSubtitle(step.messages)}
                                        {step.therapies.map((therapy, tid) => {
                                            return (
                                                <Box key={therapy.id}>
                                                    <TherapyView therapy={therapy} />
                                                    <ul>
                                                        {therapy.sets && therapy.sets.map((set, cid) => {
                                                            return (
                                                                set.drugGroups && set.drugGroups.length > 0 ? (
                                                                        <li key={set.id}>
                                                                            <TherapyCombView set={set}
                                                                                             therapy={getTherapyCombNamesWithItem(set)}
                                                                            />
                                                                        </li>
                                                                    ) :
                                                                    <Typography key={set.id}>
                                                                        <FormattedMessage
                                                                            id="label.heart_failure.therapy.no_combs"
                                                                            defaultMessage="No combs"/>
                                                                    </Typography>
                                                            )
                                                        })}
                                                        {/*{*/}
                                                        {/*    type.devices && type.devices.map((device, cid) => (*/}
                                                        {/*        <li key={cid}>*/}
                                                        {/*            <DeviceView device={device}/>*/}
                                                        {/*        </li>*/}
                                                        {/*    ))*/}
                                                        {/*}*/}
                                                    </ul>
                                                </Box>
                                            )
                                        })}
                                        {step.supportTherapies.map((supportTherapy, tid) => (
                                            <Box key={supportTherapy.id}>
                                                <Typography>{supportTherapy.name}</Typography>
                                                <ul>
                                                    {
                                                        supportTherapy.devices && supportTherapy.devices.map((device, cid) => (
                                                            <li key={device.id}>
                                                                <DeviceView device={device}/>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </Box>
                                        ))}
                                    </PaperColorSelect>
                                </Grid>
                            )
                        })}
                </Grid>)
        }
    };

    getServiceMessagesSubtitle = (stepMessages) => {
        const serviceSubtitle: ServiceMessage = getServiceMessages(stepMessages, MessageType.SUBTITLE);
        return (
            <Box>
                {serviceSubtitle.showService &&
                    <TypographySubtitle component={'span'}>
                        {serviceSubtitle.messages.map((message, index) =>
                            <ReactMarkdown key={index} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={message.payload}/>
                        )}
                    </TypographySubtitle>
                }
            </Box>
        );
    }

    pscoreView = () => {
        const {classes} = this.props;
        const {pscore} = this.state;

        if (Boolean(pscore) && pscore.length > 0) {
            return (<Box>
                <PanelDivider/>
                <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                    <EffSafChart points={pscore}/>
                </Paper>
            </Box>);
        } else {
            return (
                <Box>
                    <PanelDivider/>
                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                        <Typography>
                            <FormattedMessage id="message.data.empty" defaultMessage="Data not available"/>
                        </Typography>
                    </Paper>
                </Box>
            );
        }
    };

    forestPlotsView = () => {
        const {intl} = this.props;
        const {charts} = this.state;

        return (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                charts={charts}
                loadCharts={this.loadCharts}
                needUpdate={new Date().getTime()}
            >
            </ExpandChartsPanel>
        );
    };

    statisticsView = () => {
        const {classes, getTherapyParams} = this.props;
        const params = getTherapyParams();

        if (Boolean(params)) {
            return (
                <Box>
                    <PanelDivider/>
                    <Paper className={classes.contentBox} variant={"outlined"}>
                        {params.map((param, i) => (
                            <Typography key={i}>{`${param.key}: ${param.value}`}</Typography>
                        ))}
                    </Paper>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    referentsView = () => {
        const {classes, getReferents, isPrescriptionReadonly} = this.props;
        const referents = getReferents();

        if (Boolean(referents) && referents.items.length > 0) {
            return (
                <Box className={isPrescriptionReadonly ? classes.disabled : ''}>
                    <PanelDivider/>
                    <Paper className={classes.contentBox} variant={"outlined"}>
                        <SelTextView>
                            <FormattedMessage id='label.hp.referent' defaultMessage='Referent'/>
                        </SelTextView>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={referents.selId > 0 ? referents.selId : 0}
                                onChange={this.onReferentChange}
                            >
                                {referents.items.map(item => (
                                    <FormControlLabel
                                        key={item.id}
                                        value={item.id}
                                        label={item.name}
                                        control={<Radio color={'primary'}/>}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    onReferentChange = (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        const {setSelectReferent} = this.props;
        setSelectReferent(Number(e.target.value), () => {
            this.loadCharts();
        });
    };

    render() {
        const {
            classes,
            getRecomentTextsView,
            getSteps,
            isNoStepTherapies,
        } = this.props;
        const steps = getSteps();
        const isNoStepTherapiesAllSteps = steps.items.every((step) => isNoStepTherapies(step));

        return (
            <Box>
                {getRecomentTextsView && getRecomentTextsView()}
                <Box className={classes.contentBox}>
                    {/*{this.riskFactorsView()}*/}
                    {this.therapyView()}

                    {!isNoStepTherapiesAllSteps &&
                        <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                <Paper className={classes.contentBox} variant={"outlined"}>
                                    <HelpDrugsTextsView/>
                                </Paper>
                            </Grid>
                        </Grid>
                    }

                    {/*<Grid container spacing={2}>*/}
                    {/*  <Grid item*/}
                    {/*        xl={6} lg={6} md={6} sm={12} xs={12}*/}
                    {/*  >*/}
                    {/*{this.referentsView()}*/}
                    {/*</Grid>*/}
                    {/*<Grid item*/}
                    {/*      xl={6} lg={6} md={6} sm={12} xs={12}*/}
                    {/*>*/}
                    {/*{this.statisticsView()}*/}
                    {/*  </Grid>*/}
                    {/*</Grid>*/}
                    {/*{this.pscoreView()}*/}
                    {/*{this.forestPlotsView()}*/}
                </Box>
            </Box>
        );
    }
}

HeartFailureSelectTherapy.propTypes = {
    isPrescriptionReadonly: PropTypes.bool,
    nosologyData: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    getSteps: PropTypes.func.isRequired,
    getRecomentTextsView: PropTypes.func,
    setTherapyType: PropTypes.func.isRequired,
    getAbsoluteContrs: PropTypes.func.isRequired,
    setAbsoluteContrs: PropTypes.func.isRequired,
    getRelativeContrs: PropTypes.func.isRequired,
    setRelativeContrs: PropTypes.func.isRequired,
    getTherapyInfo: PropTypes.func.isRequired,
    setTherapyInfo: PropTypes.func.isRequired,
    getTherapyCombNamesWithItem: PropTypes.func.isRequired,
    getTherapyDeviceNameWithDescr: PropTypes.func.isRequired,
    setForestPlotView: PropTypes.func.isRequired,
    isNoStepTherapies: PropTypes.func.isRequired,
    patientId: PropTypes.string,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(HeartFailureSelectTherapy);
