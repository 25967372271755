import React from "react";
import PropTypes from 'prop-types';
import {Box, FormControlLabel, Grid, Paper, Typography, FormControl, RadioGroup, Radio,} from "@material-ui/core";
import {PaperColorSelect, SelectType} from "../PaperColor";
import SelTextView from "../SelTextView";
import PanelDivider from "../PanelDivider";
import {getHPThTypeCharts, getHPTherapyPscore} from "../../query/HPBaseline";
import {FormattedMessage, injectIntl} from "react-intl";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import mainStyles from "../../styles/mainStyles";
import EffSafChart from "./EffSafChart";
import {getQueryResult} from "../../utils";
import HPCombinationView from "./HPCombinationView";
import ServiceCheckbox from "../button/ServiceCheckbox";
import {HelpDrugsTextsView} from "../common/HelpDrugsTextsView";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";
import config from "../../config";

const styles = theme => ({
    ...mainStyles(theme),
});

class HPSelectTherapy extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);
        const {intl} = this.props;

        this.thHeads = [
            intl.formatMessage({id: 'label.hp.therapy.type.1', defaultMessage: 'Initial therapy'}),
            intl.formatMessage({id: 'label.hp.therapy.type.2', defaultMessage: 'Step 2'}),
            intl.formatMessage({id: 'label.hp.therapy.type.3', defaultMessage: 'Step 3'}),
            intl.formatMessage({id: 'label.hp.therapy.type.4', defaultMessage: 'Resistant hypertension'}),
        ];

        this.state = {};
    }

    componentDidMount() {
        // this.loadCharts();
        // TODO: +++2021.12.03+++
        this.forestPlotView();
        this.loadPscore();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadCharts = () => (callback) => {
        const {client, activePatient, getReferents, getSubgroups} = this.props;
        const {signal} = this.abortController;
        const referents = getReferents();
        const subgroups = getSubgroups();

        if (Boolean(referents) && referents.items.length > 0 && referents.selId > 0) {
            return client.query({
                query: getHPThTypeCharts,
                variables: {
                    patientId: Number(activePatient.patient) || 0,
                    refId: referents.selId,
                    inSubgroupId: subgroups ? subgroups.selId : undefined,
                },
                context: {
                    uri: config.options.server.hp_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                .then(result => getQueryResult(result?.data, 'getHPThTypeCharts'))
                .then(charts => {
                    this.setState({
                        charts: charts,
                    }, () => {
                        // TODO: +++2021.12.03+++
                        //  this.forestPlotView();
                        if (!!callback) {
                            callback();
                        }
                        // console.error('+++ HPSelectTherapy +++ loadCharts() +++ 1 charts:', charts);
                    });
                    // console.error('+++ HPSelectTherapy +++ loadCharts() +++ 2 charts:', charts);
                    return new Promise((resolve, reject) => {
                        resolve(charts);
                    });
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
    };

    loadPscore = () => {
        const {client, activePatient, getSubgroups} = this.props;
        const {signal} = this.abortController;
        const subgroups = getSubgroups();

        client.query({
            query: getHPTherapyPscore,
            variables: {
                patientId: Number(activePatient.patient) || 0,
                inSubgroupId: subgroups ? subgroups.selId : undefined,
            },
            context: {
                uri: config.options.server.hp_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            // .then(result => (result.data || {}).getHPTherapyPscore)
            // .then(result => JSON.parse(JSON.stringify(result)))
            .then(result => getQueryResult(result?.data, 'getHPTherapyPscore'))
            .then(pscore => {
                this.setState({
                    pscore: (pscore || []).map(ps => ({
                        x: ps.efficacy,
                        y: ps.safety,
                        name: ps.name,
                    }))
                });
            });
    };

    onChangeSubgroup = (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        const {setSubgroupSelect} = this.props;
        setSubgroupSelect(e, () => {
            this.loadPscore();
            this.loadCharts()();
        });
    };

    riskFactorsView = () => {
        const {classes, getSubgroups, isPrescriptionReadonly} = this.props;
        const subgroups = getSubgroups();

        if (Boolean(subgroups) && subgroups.items.length > 0) {
            return (<Box className={isPrescriptionReadonly ? classes.disabled : ''}>
                <Paper className={classes.contentBox} variant={"outlined"}>
                    <SelTextView>
                        <FormattedMessage id='label.hp.risc_factors' defaultMessage='Risc factors'/>
                    </SelTextView>
                    <RadioGroup
                        onChange={this.onChangeSubgroup}
                        value={subgroups.selId ? subgroups.selId : 0}>
                        {subgroups.items
                            .map(sg => (
                                <FormControlLabel
                                    key={sg.id}
                                    value={sg.id}
                                    label={sg.name}
                                    control={<Radio color={'primary'}/>}
                                />
                            ))}
                    </RadioGroup>
                </Paper>
                <PanelDivider/>
            </Box>);
        } else {
            return (<Box/>);
        }
    };

    therapyView = () => {
        const {classes, getTherapyTypes, setTherapyType, isPrescriptionReadonly} = this.props;
        const thTypes = getTherapyTypes();

        if (thTypes && thTypes.items.length > 0) {
            return (
                <Grid container spacing={2} className={isPrescriptionReadonly ? classes.disabled : ''}>
                    {thTypes.items
                        .filter(thType => thType.combinations.length > 0 || thType.id === 1)
                        .map((thType, thNum) => (
                            <Grid key={thType.id} item
                                  xl={6}
                                  lg={6}
                                  xs={12}
                                  sm={12}
                                  md={12}>
                                <PaperColorSelect
                                    className={classes.contentBox}
                                    seltype={thTypes.selThId === thType.id ? SelectType.POSITIVE : SelectType.NONE}>
                                    <FormControlLabel control={
                                        // <Checkbox
                                        //   color={"primary"}
                                        //   checked={thTypes.selThId === thType.id}
                                        //   value={thType.id}
                                        //   onChange={e => {
                                        //     if (isPrescriptionReadonly) {
                                        //       e.preventDefault();
                                        //       return;
                                        //     }
                                        //
                                        //     if (e.target.checked) {
                                        //       setTherapyType(thType.id);
                                        //     }
                                        //   }}
                                        // />
                                        <ServiceCheckbox
                                            color={"primary"}
                                            checked={thTypes.selThId === thType.id}
                                            value={thType.id}
                                            onChange={e => {
                                                if (isPrescriptionReadonly) {
                                                    e.preventDefault();
                                                    return;
                                                }

                                                if (e.target.checked) {
                                                    setTherapyType(thType.id);
                                                }
                                            }}
                                            showService={false}
                                        />
                                    } label={
                                        <SelTextView>{thType.title ? thType.title : this.thHeads[thNum]}</SelTextView>}/>
                                    <Typography>{thType.title !== thType.name && thType.name}</Typography>
                                    <ul>
                                        {thType.combinations.map((comb, cid) => (
                                            <li key={cid}>
                                                <HPCombinationView combination={comb}/>
                                                {/*{comb.isPriority ? (*/}
                                                {/*  <SelTextView color={comb.isAvailable === undefined || comb.isAvailable ? 'textPrimary' : 'error'}>*/}
                                                {/*    {getTherapyCombNamesWithItem(comb)}*/}
                                                {/*  </SelTextView>*/}
                                                {/*) : (*/}
                                                {/*    <Typography color={comb.isAvailable === undefined || comb.isAvailable ? 'textPrimary' : 'error'}>*/}
                                                {/*      {getTherapyCombNamesWithItem(comb)}*/}
                                                {/*    </Typography>*/}
                                                {/*  )}*/}
                                            </li>
                                        ))}
                                    </ul>
                                    {thType.comment && (
                                        <Typography>{thType.comment}</Typography>
                                    )}
                                </PaperColorSelect>
                            </Grid>
                        ))}
                </Grid>)
        } else {
            return (<Box/>);
        }
    };

    pscoreView = () => {
        const {classes, intl} = this.props;
        const {pscore} = this.state;

        if (Boolean(pscore) && pscore.length > 0) {
            return (<Box>
                <PanelDivider/>
                <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                    <EffSafChart
                        points={pscore}
                        title={intl.formatMessage({id: "label.effsaf", defaultMessage: "Efficacy/Safety"})}
                        xaxis={intl.formatMessage({id: "label.efficacy", defaultMessage: "Efficacy"})}
                        yaxis={intl.formatMessage({id: "label.safety", defaultMessage: "Safety"})}
                    />
                </Paper>
            </Box>);
        } else {
            return (
                <Box>
                    <PanelDivider/>
                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                        <Typography>
                            <FormattedMessage id="message.data.empty" defaultMessage="Data not available"/>
                        </Typography>
                    </Paper>
                </Box>
            );
        }
    };

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    forestPlotView = () => {
        updateShowPanel(true);
        setLoadChartsMethod(this.loadCharts());

/*
        const forestPlotView = (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                charts={charts}
                loadCharts={this.loadCharts()}
                needUpdate={new Date().getTime()}
                setExpandedCharts={this.setExpandedCharts}
            >
            </ExpandChartsPanel>
        );

        // return forestPlotView;
        // TODO: +++2021.12.03+++
        setForestPlotView(forestPlotView);
*/
    };

    statisticsView = () => {
        const {classes, getTherapyParams} = this.props;
        const params = getTherapyParams();

        if (Boolean(params)) {
            return (
                <Box>
                    <PanelDivider/>
                    <Paper className={classes.contentBox} variant={"outlined"}>
                        {params.map((param, i) => (
                            <Typography key={i}>{`${param.key}: ${param.value}`}</Typography>
                        ))}
                    </Paper>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    referentsView = () => {
        const {classes, getReferents, isPrescriptionReadonly} = this.props;
        const referents = getReferents();

        if (Boolean(referents) && referents.items.length > 0) {
            return (
                <Box className={isPrescriptionReadonly ? classes.disabled : ''}>
                    <PanelDivider/>
                    <Paper className={classes.contentBox} variant={"outlined"}>
                        <SelTextView>
                            <FormattedMessage id='label.hp.referent' defaultMessage='Referent'/>
                        </SelTextView>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={referents.selId > 0 ? referents.selId : 0}
                                onChange={this.onReferentChange}
                            >
                                {referents.items.map(item => (
                                    <FormControlLabel
                                        key={item.id}
                                        value={item.id}
                                        label={item.name}
                                        control={<Radio color={'primary'}/>}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    onReferentChange = (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        const {setSelectReferent} = this.props;
        setSelectReferent(Number(e.target.value), () => {
            this.loadCharts()();
        });
    };

    render() {
        const {classes, getRecomentTextsView, getTherapyTypes} = this.props;
        const {items = []} = getTherapyTypes();

        if (items.length > 0) {
            return (
                <React.Fragment>
                <Box>
                    {getRecomentTextsView && getRecomentTextsView()}
                    <Box className={classes.contentBox}>
                        {this.riskFactorsView()}
                        {this.therapyView()}

                        {/*{getHelpDrugsTextsView &&*/}
                        <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                <Paper className={classes.contentBox} variant={"outlined"}>
                                    {/*{getHelpDrugsTextsView()}*/}
                                    <HelpDrugsTextsView/>
                                </Paper>
                            </Grid>
                        </Grid>
                        {/*}*/}

                        <Grid container spacing={2}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                {this.referentsView()}
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                {this.statisticsView()}
                            </Grid>
                        </Grid>
                        {this.pscoreView()}
                        {/*{this.forestPlotView()}*/}
                    </Box>
                </Box>
                {/*{this.forestPlotView()}*/}
                </React.Fragment>
            );
        } else {
            return (
                <Box className={classes.contentBox}>
                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                        <Typography>
                            <FormattedMessage id="label.therapy.not_available"
                                              defaultMessage="Therapy is not indicated"/>
                        </Typography>
                    </Paper>
                </Box>
            );
        }
    }
}

HPSelectTherapy.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    getRecomentTextsView: PropTypes.func,
    getTherapyTypes: PropTypes.func.isRequired,
    setTherapyType: PropTypes.func.isRequired,
    getSelectVars: PropTypes.func.isRequired,
    getReferents: PropTypes.func.isRequired,
    setSelectReferent: PropTypes.func.isRequired,
    getTherapyCombNamesWithItem: PropTypes.func.isRequired,
    getSubgroups: PropTypes.func.isRequired,
    setSubgroupSelect: PropTypes.func.isRequired,
    getTherapyParams: PropTypes.func.isRequired,
    setForestPlotView: PropTypes.func.isRequired,
    getHelpDrugsTextsView: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(HPSelectTherapy);
