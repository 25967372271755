import { gql } from '@apollo/client';
import {getPromiseQueryResult, getQueryResult} from "../utils";
import config from "../config";
import {
    Chart,
    ChartsInputDto,
    GetTherapyResponse,
    RecommendConclusionTextsInputDto,
    RecommendGuidelinesTextsInputDto
} from "../const";
import type {StrictIndicators, StrictIndicatorsInputDto} from "../const";

const Message = `
        type
        payload
`;

const Regimen = `
        id
        drugCompositeId
        duration
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
`;

const BarChart = `
        order
        outcomeName
        riskReferent
        risk
        riskReferentName
        riskName
`;

const Drug = `
        id
#        drugCompositeId
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
        dataAvailable
        availStatus
        regimens {
            ${Regimen}
        }
        chartData {
            ${BarChart}
        }
        or
`;

const DrugGroup = `
        id
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
        shortName
        blocked
        multiselectMin
        multiselectMax
        drugs {
            ${Drug}
        }
`;

const CompCombinations = `
        riskPl
        riskTh
        scRisk
        type
`;

const Surgeries = `
        id
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
`;

const DrugGroupCombinations = `
        id
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
        noRecommend
        drugGroups {
            ${DrugGroup}
        }
        compCombinations {
            ${CompCombinations}
        }
`;

const AdditionalInfo = `
        id
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
`;

const EvidenceLevel = `
        id
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
        colorType
`;

const TherapyTherapy = `
        id
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
        drugGroupCombinations {
            ${DrugGroupCombinations}
        }
        additionalInfo {
            ${AdditionalInfo}
        }
        evidenceLevels {
            ${EvidenceLevel}
        }
        surgeries {
            ${Surgeries}
        }
        devices {
          id
          name
          order
          messages {
            ${Message}
          }
          selected
          compStatus
        }
`;

const ContrGroup = `
        id
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
`;

const Scale = `
        title
        contrGroups {
            ${ContrGroup}
        }
`;

const TherapyPools = `
        id
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
        therapies {
            ${TherapyTherapy}
        }
`;

const RootDto = `
        id
        name
        order
        messages {
            ${Message}
        }
        selected
        compStatus
        therapyPools {
            ${TherapyPools}
        }
        absoluteCond {
            ${Scale}
        }
        relativeCond {
            ${Scale}
        }
`;

const RecommendTextDto = `
        label
        text
`;

const RecommendSectionDto = `
        name
        descriptions {
            ${RecommendTextDto}
        }
`;

const StrictIndicatorDto = `
        id
        name
        value
        messages {
            ${Message}
        }
        colorValue
`;

const ChartDto = `
        title
        url
`;

export const getTherapy = gql`
    query ($therapyInputDto: TherapyInputDto!) {
        getTherapy (therapyInputDto: $therapyInputDto) {
            ${RootDto}
        }
    }
`;

export const loadGetTherapy = (client, signal, nosologyId, patientId): Promise<GetTherapyResponse[]> => {
    return client.query({
        query: getTherapy,
        variables: {
            therapyInputDto: {
                nosologyId: Number(nosologyId),
                patientId: Number(patientId),
            },
        },
        context: {
            uri: config.options.server.gql_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getTherapy');
        });
}

export const getRecommendGuidelinesTexts = gql`
    query ($recommendGuidelinesTextsInputDto: RecommendGuidelinesTextsInputDto) {
        getRecommendGuidelinesTexts (recommendGuidelinesTextsInputDto: $recommendGuidelinesTextsInputDto) {
            ${RecommendTextDto}
        }
    }
`;

export const loadGetRecommendTexts = (client, signal, recommendGuidelinesTextsInputDto: RecommendGuidelinesTextsInputDto): Promise<any> => {
    return client.query({
        query: getRecommendGuidelinesTexts,
        variables: {
            recommendGuidelinesTextsInputDto: recommendGuidelinesTextsInputDto,
        },
        context: {
            uri: config.options.server.gql_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getRecommendGuidelinesTexts');
        });
}

export const getRecommendConclusionTexts = gql`
    query ($recommendConclusionTextsInputDto: RecommendConclusionTextsInputDto) {
        getRecommendConclusionTexts (recommendConclusionTextsInputDto: $recommendConclusionTextsInputDto) {
            ${RecommendSectionDto}
        }
    }
`;

export const loadGetRecommendConclusionTexts = (client, signal, recommendConclusionTextsInputDto: RecommendConclusionTextsInputDto): Promise<any> => {
    return client.query({
        query: getRecommendConclusionTexts,
        variables: {
            recommendConclusionTextsInputDto: recommendConclusionTextsInputDto,
        },
        context: {
            uri: config.options.server.gql_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getRecommendConclusionTexts');
        });
}

export const getStrictIndicators = gql`
    query ($strictIndicatorsInputDto: StrictIndicatorsInputDto) {
        getStrictIndicators (strictIndicatorsInputDto: $strictIndicatorsInputDto) {
            ${StrictIndicatorDto}
        }
    }
`;

export const loadGetStrictIndicators = (client, signal, strictIndicatorsInputDto: StrictIndicatorsInputDto): Promise<StrictIndicators[]> => {
    return client.query({
        query: getStrictIndicators,
        variables: {
            strictIndicatorsInputDto: strictIndicatorsInputDto,
        },
        context: {
            uri: config.options.server.gql_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getStrictIndicators');
        });
}

export const getCharts = gql`
    query ($chartsInputDto: ChartsInputDto) {
        getCharts(chartsInputDto: $chartsInputDto) {
            ${ChartDto}
        }
    }
`;

export const loadGetCharts = (client, signal, chartsInputDto: ChartsInputDto): Promise<{id: number, charts: Chart[]}> => {
    return client.query({
        query: getCharts,
        variables: {
            chartsInputDto: chartsInputDto,
        },
        context: {
            uri: config.options.server.gql_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            // const charts: Chart[] = getPromiseQueryResult(data, 'getCharts');
            const charts: Chart[] = getQueryResult(data, 'getCharts');
            // return {
            //     id: chartsInputDto.ids[0],
            //     charts: charts,
            // };
            return new Promise((resolve, reject) => {
                resolve({
                    id: chartsInputDto.ids[0],
                    charts: charts,
                });
            });
        });
}
