import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import PersonIcon from '@material-ui/icons/PersonAddOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom'
import config from '../config';
import mainStyles from "../styles/mainStyles";
import {parseSearchParams} from 'url-search-utils';
import RegForm from "../comp/reg/RegForm";
import RegRequestForm from "../comp/reg/RegRequestForm";
import { FormattedMessage, injectIntl } from 'react-intl';
import compose from 'recompose/compose';
import {isSameAuthServer} from "../utils";
import {RegType} from "../const";
import { green } from '@material-ui/core/colors';

const styles = theme => ({
  ...mainStyles(theme),
  root: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(700 + theme.spacing(3) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 3),
  },
  infoPanel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderColor: green[300],
    backgroundColor: green[50],
  },
  avatar: {
    margin: theme.spacing(1),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
});

class SignUp extends React.Component {

  showReset = false;

  constructor(props) {
    super(props);
    let searchParams = parseSearchParams({
      type: 'number',
    });

    this.regCode = searchParams.code;
    this.regType = searchParams.type;

    const { location } = props;
    if (location.pathname === '/password-reset') {
      this.showReset = true;
    }


    this.state = {
      registerSuccess: false,
      showReset: this.showReset,
    };
  }

  componentDidMount() {
    if (this.regCode) {
      if (this.regType === RegType.DIRECT_REG) {
        this.checkCode();
      }
      this.getCode(this.regCode);
    }
  }

  checkCode = () => {
    // const $this = this;

    const { intl } = this.props;

    fetch(config.options.server.auth_url + config.options.server.check_code, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'code': this.regCode,
      })
    })
      .then(response => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState({
            notify: {
              text: response.data,
              color: 'secondary',
            },
            registerSuccess: true,
          });
        } else {
          this.setState({
            notify: {
              text: response.data,
              color: 'error',
            },
            registerSuccess: false,
          });
        }
      }).catch((err) => {
        this.setState({
          notify: {
            text: intl.formatMessage({id: 'err.request', defaultMessage: 'Error request'}),
            color: 'error',
          }
        });
      });
  };

  getCode = (code) => {
    // const $this = this;
    const { intl } = this.props;

    fetch(`${config.options.server.auth_url}${config.options.server.get_code}/${code}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState({
            username: response.data.username,
          });
        } else {
          this.setState({
            notify: {
              text: response.data,
              color: 'error',
            }
          });
        }
      }).catch((err) => {
        this.setState({
          notify: {
            text: intl.formatMessage({id: 'err.request', defaultMessage: 'Error request'}),
            color: 'error',
          }
        });
      });
  };

  setLoading = (loading) => {
    const {showLoading, hideLoading} = this.context;
    if (loading) {
      showLoading();
    } else {
      hideLoading();
    }
    this.setState({
      loading: loading,
    });
  }

  onRegSubmit = ({username, password, first_name, reg_code}) => {
    const { history, intl } = this.props;

    this.setState({
      notify: null,
    });

    const code = reg_code || this.regCode;
    const uri = config.options.server.auth_url + (this.regType !== RegType.RESET_PASSWORD ? config.options.server.register_uri : config.options.server.set_password);

    const body = this.regType === RegType.DIRECT_REG ?
        JSON.stringify({
          'username': username,
          'password': password,
          'first_name': first_name,
          'client': isSameAuthServer() ? 'web' : 'app',
        }) :
        (this.regType !== RegType.RESET_PASSWORD ?
            JSON.stringify({
              'username': username,
              'password': password,
              'first_name': first_name,
              'reg_code': code,
              // 'client': 'web',
              'client': isSameAuthServer() ? 'web' : 'app',
            }) :
            JSON.stringify({
              'newPassword': password,
              'code': this.regCode,
              'client': isSameAuthServer() ? 'web' : 'app',
            }));

    this.setLoading(true);

    fetch(uri, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body
    })
      .then(response => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState({
            notify: {
              text: response.data,
              color: 'secondary',
            },
            registerSuccess: true,
          });

          // if (this.regCode) {
            // if (this.regType === RegType.RESET_PASSWORD) {
              if (!isSameAuthServer() && localStorage && response.data) {
                localStorage.setItem('x_auth_token', response.data);
              }
            // }

            this.context.updateUser(() => {
              history.replace('/');
            });
          // }
        } else {
          this.setState({
            notify: {
              text: response.data,
              color: 'error',
            },
            registerSuccess: false,
          });
        }
        this.setLoading(false);
      }).catch((err) => {
        console.log(err);
        this.setState({
          notify: {
            text: intl.formatMessage({id: 'err.request', defaultMessage: 'Error request'}),
            color: 'error',
          }
        });
      this.setLoading(false);
    });
  };

  onRegRequestSubmit = ({email, role, profile}) => {
    // const $this = this;
    const { intl } = this.props;

    this.setState({
      notify: null,
    });

    fetch(config.options.server.auth_url + config.options.server.request_reglink, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'email': email,
        'role': role,
        'profile': profile,
      })
    })
      .then(response => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState({
            notify: {
              text: response.data,
              color: 'secondary',
            },
            registerSuccess: true,
          });
        } else {
          this.setState({
            notify: {
              text: response.data,
              color: 'error',
            },
            registerSuccess: false,
          });
        }
        this.setLoading(false);
      }).catch((err) => {
      console.log(err);
      this.setState({
        notify: {
          text: intl.formatMessage({id: 'err.request', defaultMessage: 'Error request'}),
          color: 'error',
        }
      });
      this.setLoading(false);
    });
  };

  showRegForm = (): boolean => {
    return (this.regCode || (!this.regCode && this.regType === RegType.INVITE_PUBLIC) || (!this.regCode && this.regType === RegType.DIRECT_REG));
  }

  render() {
    const { classes } = this.props;
    const { notify, registerSuccess, username, showReset, loading } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.root}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <PersonIcon />
            </Avatar>
            <Typography variant="h5">
              {!showReset ?
                  <FormattedMessage id='message.account.create' defaultMessage='Create account' />
                  :
                  <FormattedMessage id='message.account.reset_password' defaultMessage='Reset password' />
              }
            </Typography>
            {/*this.regType === RegType.INVITE_PUBLIC &&
            <Paper variant="outlined" className={classes.infoPanel}>
              <Typography align="center">
                Для получения доступа в СППВР MedicBK, в рамках образовательного проекта Российского Кардиологического Общества, создайте свой аккаунт в MedicBK и получите бесплатный доступ в СППВР до конца декабря.
              </Typography>
            </Paper>
            */}

            {/*{(!registerSuccess && this.showRegForm()) &&*/}
            {!registerSuccess &&
              this.showRegForm() ?
                <RegForm
                  onSubmit={this.onRegSubmit}
                  username={username}
                  userReadonly={this.regType !== RegType.INVITE_PUBLIC && this.regType !== RegType.DIRECT_REG}
                  showReset={showReset}
                  loading={loading}
                  regType={Number(this.regType)}
                  regCode={this.regCode}
                  hideRegCode={this.regType === RegType.DIRECT_REG}
                />
                :
                <RegRequestForm onSubmit={this.onRegRequestSubmit} loading={loading} />
            }
            {/*{(!registerSuccess && !this.regCode && this.regType !== RegType.INVITE_PUBLIC && this.regType !== RegType.SIMPLE_REG) &&*/}
            {/*  <RegRequestForm onSubmit={this.onRegRequestSubmit} loading={loading} />*/}
            {/*}*/}

            {notify &&
            <Typography className={classes.contentBoxCenter} variant="body1" color={notify.color}>
              {notify.text}
            </Typography>
            }

            {(registerSuccess && this.showRegForm()) &&
            <div className={classes.contentBoxCenter}>
              <Button variant={"contained"} color={"primary"} component={Link} to="/signin">
                <FormattedMessage id='label.enter' defaultMessage='Enter' />
              </Button>
            </div>
            }
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

SignUp.contextTypes = {
  updateUser: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(SignUp);
