import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {TextField} from "@material-ui/core";
import {Note} from "../../const";

const styles = theme => ({
    ...mainStyles(theme),
    fullWidth: {
        width: '100%',
    },
    textareaWrapper: {
        borderRadius: '4px',
        borderColor: 'rgba(0, 0, 0, 0.87)',
        borderStyle: 'solid',
        borderWidth: '1px',
        padding: '18.5px 14px',
    },
    textarea: {
        width: '100%',
        resize: 'none',
        border: 'none',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontFeatureSettings: 'normal',
        fontKerning: 'auto',
        fontOpticalSizing: 'auto',
        fontSize: '16px',

        '-webkit-appearance': 'none',
        '-moz-apperarance': 'none',
        '-ms-appearance': 'none',
        '-o-appearance': 'none',
        appearance: 'none',
        outline: 'none',
        '-webkit-box-shadow': 'none',
        '-moz-box-shadow': 'none',
        '-ms-box-shadow': 'none',
        '-o-box-shadow': 'none',
        'box-shadow': 'none',

        '&:focus': {
            border: 'none !important',
            '-webkit-appearance': 'none',
            '-moz-apperarance': 'none',
            '-ms-appearance': 'none',
            '-o-appearance': 'none',
            appearance: 'none',
            outline: 'none',
            '-webkit-box-shadow': 'none',
            '-moz-box-shadow': 'none',
            '-ms-box-shadow': 'none',
            '-o-box-shadow': 'none',
            'box-shadow': 'none',
        }
    },
    focused: {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
        padding: '17.5px 13px',
    }
});

class NoteTextFieldView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: props.note.payload,
            focused: false,
        };
    }

    shouldComponentUpdate(nextProps: Readonly<{note: Note; needRerender: string}>, nextState: Readonly<any>, nextContext: any): boolean {
        const {
            needRerender,
            note,
        } = this.props;
        // console.error('+++ NoteTextFieldView() +++ patient:', patient);
        // console.error('+++ NoteTextFieldView() +++ nextProps.patient?.drugs:', nextProps.patient?.drugs);
        // console.error('+++ NoteTextFieldView() +++ nextProps.drugs:', nextProps.drugs);
        // console.error('+++ NoteTextFieldView() +++ nextProps:', nextProps);
        // console.error('+++ NoteTextFieldView() +++ nextContext:', nextContext);
        // console.error('+++ NoteTextFieldView() +++ this.state.addedDrugsData:', this.state.addedDrugsData);
        // return needRerender !== nextProps.needRerender;
        return (
            note.payload !== nextProps.note.payload
            ||
            this.state.text !== nextState.text
            ||
            this.state.focused !== nextState.focused
        );
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        // console.error('+++ render NoteTextFieldView() componentDidUpdate() this.props.notes:', this.props.notes);
        this.setState({
            text: this.props.note.payload,
        });
    }

    componentDidMount() {
        this.setState({
            text: this.props.note.payload,
        });
    }

    onChange = (event) => {
        this.props.note.payload = event.target.value;
        this.setState({
            text: event.target.value,
        });
    }

    onFocus = (focus: boolean) => (event) => {
        const {
            notesRefs,
            note,
        } = this.props;
        this.setState({
            focused: focus,
        });
    }

    render() {
        const {
            classes,
            readonly,
            notesRefs,
            note,
            notes,
            handleChangeNotes,
        } = this.props;
        const {text, focused} = this.state;
        // console.error('+++ render NoteTextFieldView() notes:', notes);
        // console.error('+++ render NoteTextFieldView() text:', text);

        return (
/*
            <TextField
                // xl={12} lg={12} md={12} sm={12} xs={12}
                className={classes.textarea}
                style={{width: '100%'}}
                multiline
                minRows={10}
                maxRows={20}
                // value={note.payload}
                value={text}
                // onChange={(e) => handleChangeNotes(e, notes)}
                onChange={this.onChange}
                variant="outlined"
                disabled={readonly}
                // ref={notesRefs[note.id]}
                ref={el => notesRefs.current[note.id] = el}
            />
*/
            <div className={'MuiFormControl-root MuiTextField-root ' + classes.fullWidth}
                 ref={el => notesRefs.current[note.id] = el}
            >
                <div className={'MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-multiline MuiOutlinedInput-multiline ' + classes.fullWidth + ' ' + classes.textareaWrapper + (focused ? (' Mui-focused ' + classes.focused) : '')}>
                    <textarea
                        // xl={12} lg={12} md={12} sm={12} xs={12}
                        className={'MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline ' + classes.textarea}
                        style={{width: '100%'}}
                        rows={10}
                        // value={note.payload}
                        value={text}
                        defaultValue={text}
                        // onChange={(e) => handleChangeNotes(e, notes)}
                        onChange={this.onChange}
                        // variant="outlined"
                        disabled={readonly}
                        // ref={notesRefs[note.id]}
                        onBlur={this.onFocus(false)}
                        onFocus={this.onFocus(true)}
                    />
                </div>
            </div>
/*
            <div
                className={classes.textarea}
                style={{width: '100%'}}
                onChange={this.onChange}
                ref={el => notesRefs.current[note.id] = el}
            >{text}</div>
*/
        );
    }
}

NoteTextFieldView.propTypes = {
    // classes: PropTypes.object.isRequired,
    readonly: PropTypes.bool,
    needRerender: PropTypes.string,
    notesRefs: PropTypes.object.isRequired,
    note: PropTypes.object.isRequired,
    notes: PropTypes.array,
    handleChangeNotes: PropTypes.func.isRequired,
};

NoteTextFieldView.defaultProps = {
    // showTitle: true,
};

export default compose(
    withStyles(styles),
)(NoteTextFieldView);
