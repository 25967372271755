import {withStyles} from '@material-ui/core/styles';
import {
    Accordion,
    AccordionSummary, Typography,
} from "@material-ui/core";

export const ArticlesPanel = withStyles((theme) => ({
    root: {
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(Accordion);

export const ArticlesPanelSummary = withStyles((theme) => ({
    root: {
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    expanded: {},
    content: {
        margin: '0',
        '&$expanded': {
            margin: '0',
        },
    },
}))(AccordionSummary);

export const TypographyFieldVarName = withStyles(_theme => ({
    root: {
        fontWeight: 500,
        fontStyle: 'italic',
    },
}))(Typography);

export const TypographySubtitle = withStyles(_theme => ({
    root: {
        // color: red[400],
        marginLeft: '32px',
        marginTop: '-8px',
    },
}))(Typography);

