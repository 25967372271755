import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
    Grid,
    Checkbox, Link, CardHeader, CardActions, Card
} from "@material-ui/core";
import SelTextView from "../SelTextView";
import PanelDivider from "../PanelDivider";
import {FormattedMessage, injectIntl} from "react-intl";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import config from "../../config";
import {getChartA, getKeyValue} from "../../query/HeartFailureBaseline";
import {
    filterDrugsByRegimens, getDrugStyleObject, getEvidenceLevelClassName, getNosologyClassName,
    getQueryResult,
    getServiceMessageDrug,
    getServiceMessages, isMultiselect, isPrescriptionReadonly,
    sortByOrder
} from "../../utils";
import {ChartsInputDto, ChartType, DrugAvailStatus, DrugCompStatus, MessageType} from "../../const";
import type {ServiceMessage} from "../../const";
import {InfoHelp} from "../common/InfoHelp";
import ServiceRadioGroup from "../button/ServiceRadioGroup";
import {HelpDrugsTextsView} from "../common/HelpDrugsTextsView";
import ServiceCheckbox from "../button/ServiceCheckbox";
import SelectDrugRegimenDialog from "../drug/SelectDrugRegimenDialog";
import {setLoadChartsMethod, updateExpanded, updateShowPanel} from "../../service/expand-charts-panel-service";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import Markdown from "../common/Markdown";
import Button from "@material-ui/core/Button";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import {blue, grey, red} from "@material-ui/core/colors";
import IbsSelectTherapyView from "./IbsSelectTherapyView";
import {loadGetCharts} from "../../query/common";

const styles = theme => ({
    ...mainStyles(theme),
    paddingBottom0: {
        paddingBottom: 0,
    },
    therapyName: {
        textAlign: 'center',
        backgroundColor: grey[200],
        padding: '10px',
    },
    drugGroupName: {
        backgroundColor: blue[100],
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '10px',
    },
    cellItem: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '10px',
    },
    cellItemDrugs: {
        display: 'flex',
        alignItems: 'center',
    },
    cellItemDrug: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '10px',
    },
    additionalInfo: {
        backgroundColor: red[200],
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '10px',
    },
    fullHeight: {
        height: '100%',
    },
    fullWidthHeight: {
        width: '100%',
        height: '100%',
    },
    drugLabel: {
        display: 'flex',
        flexDirection: 'row',
    },
    drugRegimens: {
        marginLeft: '4px',
    },
});

class IbsSelectAll extends React.Component {

    abortController = new AbortController();
    abortChartController = new AbortController();

    steps;
    selGroup;

    constructor(props) {
        super(props);

        this.state = {
            selGroupId: 0,
            charts: {},
            statistics: [],
            openSelectDrugRegimenDialog: false,
            selectDrugRegimenDialog: undefined,
            selectGroupDrugRegimenDialog: undefined,
            skippedState: false,
        };
    }

    componentDidMount() {
        // this.loadDrugs();
        this.setVars();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.abortChartController.abort();
    }

    setVars = () => {
        const {
            getTherapyGroupDrugs,
        } = this.props;
        const {selGroupId} = this.state;

        this.drugGroups = getTherapyGroupDrugs();
        this.selGroup = (this.drugGroups || {})[selGroupId];

        this.setSelected();
        // this.loadStatistics();
        this.forestPlotView();
    }

    setSelected = () => {
        const {
            nosologyData,
            updateContextPanel,
        } = this.props;

        if (
            !nosologyData.touched
        ) {
            let selDrugs = {};

            (this.drugGroups || []).forEach((drugGroup) => {
                // console.error('+++ drugGroup:', drugGroup);
                drugGroup.drugs.forEach((drug) => {
                    if (drug.selected) {
                        drug.groupName = drugGroup.name;
                        drug.groupShortName = drugGroup.shortName;

                        drug.selectedRegimen = drug.regimens.filter((regimen) => regimen.selected)[0];

                        if (drug.id === -1) {
                            selDrugs[drugGroup.id] = [];
                        }

                        selDrugs[drugGroup.id] = selDrugs[drugGroup.id] ? (isMultiselect(drugGroup) ? [...selDrugs[drugGroup.id], ...[drug]] : [drug]) : [drug];
                    }
                });
            });

            nosologyData.selDrugs = selDrugs;

            this.setState(prevState => ({
                nosologyData: {
                    ...prevState.nosologyData,
                    selDrugs: nosologyData.selDrugs,
                },
            }), () => {
                updateContextPanel();
            });
        }
    }

    loadChart = (callback) => {
        // TODO: всегда нет графиков, пока метод не работает
        return new Promise((resolve, reject) => {
            resolve(null);
        });

        const {client, nosologyId, patientId} = this.props;
        const {charts} = this.state;

        // console.error('+++ loadChart() +++ this.selGroup:', this.selGroup);
        // console.error('+++ loadChart() +++ charts[(this.selGroup || {}).id]:', charts[(this.selGroup || {}).id]);
        // if (expandedCharts && !Boolean(charts[(this.selGroup || {}).id])) {
        if (!Boolean(charts[(this.selGroup || {}).id])) {
            const {signal} = this.abortChartController;
            const chartsInputDto: ChartsInputDto = {
                ids: [(this.selGroup || {}).id],
                nosologyId: Number(nosologyId),
                patientId: Number(patientId),
                type: ChartType.FOREST_GROUP,
            };
            // console.error('+++ loadChart() +++ chartsAInputDto:', chartsAInputDto);
            return loadGetCharts(client, signal, chartsInputDto)
                .then((chartsResponse) => {
                    if (!!chartsResponse) {
                        charts[(this.selGroup || {}).id] = chartsResponse;

                        this.setState({
                            charts: charts,
                        }, () => {
                            if (!!callback) {
                                callback();
                            } else {
                            }
                        });
                        return new Promise((resolve, reject) => {
                            resolve(chartsResponse);
                        });
                    } else {
                        return new Promise((resolve, reject) => {
                            resolve(null);
                        });
                    }
                });
        } else {
            if (!!callback) {
                callback();
            }
            return new Promise((resolve, reject) => {
                resolve(charts[(this.selGroup || {}).id]);
            });
        }
    };

    onChangeSkip = () => {
        this.setState({
            skippedState: true,
        });

        const {setSelectTherapy2} = this.props;
        // this.selTherapyId = therapy.id;
        // this.selDrugGroupId = drugGroup.id;
        // console.error('+++ therapy:', therapy);
        // console.error('+++ drugGroup:', drugGroup);

        // console.error('+++ therapy:', therapy);
        setSelectTherapy2();

        // this.setState({
        //     selTherapyId: this.selTherapyId,
        //     selDrugGroupId: this.selDrugGroupId,
        // });
    };

    onDrugGroupSelect = (therapyPool, therapy, drugGroupCombination, drugGroup) => {
        const {setSelectTherapy2} = this.props;
        setSelectTherapy2(therapyPool, therapy, drugGroupCombination, drugGroup);

        this.setState({
            skippedState: false,
        });
    };

    selectAllView = () => {
        const {
            classes,
            isPrescriptionReadonly,
            nosologyData,
            updateContextPanel,
            getSteps,
            getRecomendTexts,
            setRecomendTexts,
            getTouched,
            getTherapyGroupDrugs,
            setTherapyGroupDrugs,
            setDrugForTherapyGroup,
            setStatistics,
            getStatistics,
            getSelectVars,
            getSelectFields,
            getSelectTherapyComb,
            setForestPlotView,
            patientId,
            nosologyId,

            setSelectTherapy2,
            selectedTherapy2,
        } = this.props;

        const {
            openSelectDrugRegimenDialog,
            selectDrugRegimenDialog,
            skippedState,
        } = this.state;

        const therapyPools = nosologyData.getTherapy[1].therapyPools || [];

        return (
            <Box>
                {/*<Typography variant={"h6"} align={"center"}>*/}
                {/*    {(((therapyPools[0] || {}).therapies || [])[0] || {}).name}*/}
                {/*</Typography>*/}
                {therapyPools.sort(sortByOrder).map((therapyPool) => (
                    <IbsSelectTherapyView
                        key={therapyPool.id}
                        isPrescriptionReadonly={isPrescriptionReadonly}
                        nosologyData={nosologyData}
                        updateContextPanel={updateContextPanel}
                        getSteps={getSteps}
                        getRecomendTexts={getRecomendTexts}
                        setRecomendTexts={setRecomendTexts}
                        getTouched={getTouched}
                        getTherapyGroupDrugs={getTherapyGroupDrugs}
                        setTherapyGroupDrugs={setTherapyGroupDrugs}
                        setDrugForTherapyGroup={setDrugForTherapyGroup}
                        setStatistics={setStatistics}
                        getStatistics={getStatistics}
                        getSelectVars={getSelectVars}
                        getSelectFields={getSelectFields}
                        getSelectTherapyComb={getSelectTherapyComb}
                        setForestPlotView={setForestPlotView}
                        patientId={patientId}
                        nosologyId={nosologyId}

                        therapyPool={therapyPool}
                        setSelectTherapy2={this.onDrugGroupSelect}
                        selectedTherapy2={selectedTherapy2}
                    />
                ))}

                <Grid item xs={12}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={skippedState}
                                        color='primary'
                                    />
                                }
                                onChange={() => this.onChangeSkip()}
                                label={
                                    <Typography
                                        color={skippedState ? 'primary' : 'textPrimary'}>
                                        <FormattedMessage id='label.drug.select.skip'
                                                          defaultMessage='Skip drug selection'/>
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Box>
        );
    };

    statisticsView = () => {
        const {classes} = this.props;
        const {statistics} = this.state;
        const statisticsT: [] = (statistics || []).filter(statistic => statistic.groupId === (this.selGroup || {}).id);

        return (
            statisticsT.length > 0 &&
            <Box>
                <PanelDivider/>
                <Paper className={classes.contentBox} variant={"outlined"}>
                    {
                        statisticsT.map(statistic => (
                            <Typography key={statistic.id}>
                                {statistic.key} {statistic.value}
                            </Typography>
                        ))
                    }
                </Paper>
            </Box>
        );
    };

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    forestPlotView = () => {
        updateShowPanel(true);
        setLoadChartsMethod(this.loadChart);
    };

    render() {
        const {
            classes,
            getRecomentTextsView,
        } = this.props;
        const {selGroupId} = this.state;

        return (
            <Box>
                {getRecomentTextsView && getRecomentTextsView()}
                <Box className={classes.contentBox + ' ' + classes.paddingBottom0}>
                    {this.selectAllView()}

                    {/*<PanelDivider/>*/}

                    {this.statisticsView()}
                </Box>
            </Box>
        );
    }
}

IbsSelectAll.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    getSteps: PropTypes.func.isRequired,
    getTouched: PropTypes.func.isRequired,
    getRecomentTextsView: PropTypes.func,
    getTherapyGroupDrugs: PropTypes.func.isRequired,
    setTherapyGroupDrugs: PropTypes.func.isRequired,
    getStatistics: PropTypes.func.isRequired,
    setStatistics: PropTypes.func.isRequired,
    setDrugForTherapyGroup: PropTypes.func.isRequired,
    getSelectTherapyComb: PropTypes.func.isRequired,
    // getSelectTherapyType: PropTypes.func.isRequired,
    setForestPlotView: PropTypes.func.isRequired,

    setSelectTherapy2: PropTypes.func.isRequired,
    selectedTherapy2: PropTypes.object,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(IbsSelectAll);
