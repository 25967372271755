import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
    Grid, Link, Popover, Box, Checkbox,
} from "@material-ui/core";
import type {
    BaselineParameterDto,
    ParameterOptDto,
    ServiceMessage,
    ValueOptionType
} from "../../const";
import Typography from "@material-ui/core/Typography";
import ParameterValueView from "./ParameterValueView";
import {TypographyFieldVarName} from "../../pages/CommonElements";
import {MessageType, ParameterType, ServiceMessageColors} from "../../const";
import {getParameterOpt, getParameterValid, getSelected, getServiceMessages, inRange, notEmptyValue} from "../../utils";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

const styles = theme => ({
    ...mainStyles(theme),
    parameterMessages: {

    },
    disabled: {
        pointerEvents: "none",
        opacity: '1.0 !important',
    },
});

class ParameterView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentParameter: JSON.parse(JSON.stringify(props.parameter)),
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        this.setState({
            currentParameter: JSON.parse(JSON.stringify(this.props.parameter)),
        });
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        // if (nextProps.parameter.id === '2') {
        //     console.error('+++ JSON.stringify(this.state.currentParameter) !== JSON.stringify(nextProps.parameter):', JSON.stringify(this.state.currentParameter) !== JSON.stringify(nextProps.parameter));
        //     console.error('+++ nextProps.parameter:', nextProps.parameter);
        //     console.error('+++ this.state.currentParameter:', this.state.currentParameter);
        //     console.error('+++ this.props.parameter:', this.props.parameter);
        //     console.error('+++ this.parameter:', this.parameter);
        // }
        return (
            nextProps.readonly !== this.props.readonly
            ||
            JSON.stringify(this.state.currentParameter) !== JSON.stringify(nextProps.parameter)
            ||
            JSON.stringify(this.props) !== JSON.stringify(nextProps)
            ||
            JSON.stringify(this.state.currentParameter || {}) !== JSON.stringify(nextState.currentParameter || {})
            ||
            JSON.stringify(this.state.selField || {}) !== JSON.stringify(nextState.selField || {})
            ||
            (this.state.anchorFieldDescr || '') !== (nextState.anchorFieldDescr || '')
        );
    }

    onValueChange = (parameter) => (value, onClear, changeModified) => {
        const {onValueChange} = this.props;

        if (Boolean(onValueChange)) {
            onValueChange(value, onClear, changeModified);
        }
    };

    onUpdateDateChange = (parameter, updateDate: number) => {
        const {onUpdateDateChange} = this.props;

        if (Boolean(onUpdateDateChange)) {
            onUpdateDateChange(parameter, updateDate);
        }
    };

    getParameterSelectedValueMessages = (parameter, messageType: MessageType) => {
        let parameterSelectedValueMessage;
        if (parameter.type === ParameterType.OPTION) {
            const items: ValueOptionType[] = parameter.values
                .filter(value => value)
                .map(value => {
                    return JSON.parse(value);
                });
            let selectedItem = items.find(item => {
                return item.selected;
            });
            if (selectedItem) {
                const serviceMessage: ServiceMessage = getServiceMessages(selectedItem.messages, messageType);
                if (serviceMessage.showService) {
                    parameterSelectedValueMessage = serviceMessage.messages.map((message, index) =>
                        <Typography key={index} color={ServiceMessageColors[messageType]} component="span">
                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={message.payload}/>
                        </Typography>
                    );
                }
            }

        }

        return parameterSelectedValueMessage;
    };

    getParameterMessages = (parameter, messageType: MessageType) => {
        let parameterMessage;

        const serviceMessage: ServiceMessage = getServiceMessages(parameter.messages, messageType);
        if (serviceMessage.showService) {
            parameterMessage = serviceMessage.messages.map((message, index) =>
                <Typography
                    key={index}
                    color={ServiceMessageColors[messageType]}
                    // variant={"subtitle2"}
                    component="span"
                >
                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={message.payload}/>
                </Typography>
            );
        }

        return parameterMessage;
    };

    isFieldDescr = (parameter: BaselineParameterDto) => {
        return Boolean(parameter)
            &&
            Boolean(parameter.values)
            &&
            parameter.values
                .filter(value => value)
                .findIndex(value => {
                    const messages = JSON.parse(value).messages;
                    const serviceMessageDESCRIPTION: ServiceMessage = getServiceMessages(messages, MessageType.DESCRIPTION);
                    if (serviceMessageDESCRIPTION.showService) {
                        return true;
                    }
                    return false;
                    // Boolean(JSON.parse(value).description)
                }) !== -1;
    };

    onFieldNameClick = (parameter) => (e) => {
        e.persist();

        if (this.isFieldDescr(parameter)) {
            this.setState({
                selField: parameter,
                anchorFieldDescr: e.target,
            });
        }

        e.preventDefault();
    };

    onFieldDescrClose = () => {
        this.setState({
            anchorFieldDescr: undefined,
            selField: undefined,
        });
    };

    buidViewFieldDescr = (parameter: BaselineParameterDto) => {
        if (!Boolean(parameter) || !Boolean(parameter.values)) {
            return <Box/>;
        }

        const {classes} = this.props;

        const values: ValueOptionType[] = parameter.values
            .filter(value => value)
            .map(value => {
                return JSON.parse(value);
            });

        return (
            <Box className={classes.contentBox}>
                {values.filter(v => {
                    const messages = v.messages;
                    const serviceMessageDESCRIPTION: ServiceMessage = getServiceMessages(messages, MessageType.DESCRIPTION);
                    if (serviceMessageDESCRIPTION.showService) {
                        return true;
                    }
                    return false;
                }).map((v, id) => {
                    const messages = v.messages;
                    const serviceMessageDESCRIPTION: ServiceMessage = getServiceMessages(messages, MessageType.DESCRIPTION);
                    return (
                    <Box key={id} className={classes.fieldVarNameDescr}>
                        <TypographyFieldVarName>{v.name}</TypographyFieldVarName>
                        {serviceMessageDESCRIPTION.messages.map((message, index) =>
                        <Typography key={index} component="span">
                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={message.payload}/>
                        </Typography>
                        )}
                    </Box>);
                })}
            </Box>
        );
    };

    render() {
        const {
            classes,
            parameter,
            readonly,
            showError,
        } = this.props;
        const {anchorFieldDescr, selField} = this.state;

        const parameterOpt: ParameterOptDto = getParameterOpt(parameter);
        const required: boolean =  parameterOpt.required;

        // const required: boolean = parameter.values
        //     .filter(value => value)
        //     .map(value => {
        //         return JSON.parse(value);
        //     })
        //     .some(value => value.required);

        // const selected: boolean = parameter.values
        //     .filter(value => value)
        //     .map(value => {
        //         return JSON.parse(value);
        //     })
        //     .some(value => value.selected || value.value);

        // let selectedItem;
        // if ([ParameterType.INT, ParameterType.DECIMAL, ParameterType.DATE].includes(parameter.type)) {
        //     selectedItem = parameter.values
        //         .filter(value => value)
        //         .find(it => {
        //             const item = JSON.parse(it);
        //             return notEmptyValue(item.value);
        //         });
        // }

        const selectedItem = getSelected(parameter);
        const parameterValid = getParameterValid(parameter);

        // if (parameter.id === '2') {
        //     console.error('+++ ParameterView render() +++ parameter:', parameter);
        //     console.error('+++ ParameterView render() +++ selected:', selected);
        //     console.error('+++ ParameterView render() +++ selectedItem:', selectedItem);
        // }

        const parameterSelectedValueINFO = this.getParameterSelectedValueMessages(parameter, MessageType.INFO);
        const parameterSelectedValueWARNING = this.getParameterSelectedValueMessages(parameter, MessageType.WARNING);
        const parameterINFO = this.getParameterMessages(parameter, MessageType.INFO);
        const parameterWARNING = this.getParameterMessages(parameter, MessageType.WARNING);

        // console.error('+++ ParameterView +++ 1 showError:', showError);
        // console.error('+++ ParameterView +++ 1 parameter:', parameter);
        // console.error('+++ ParameterView +++ 1 selected:', selected);
        // console.error('+++ ParameterView +++ 1 selectedItem:', selectedItem);

        return (
            <React.Fragment>
                <Grid key={parameter.id} container
                      spacing={1}
                      direction={"row"}
                      alignItems={"center"}
                      className={classes.contentVerticalPadding}>
                    <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                        {
                            this.isFieldDescr(parameter)
                                ?
                                <Link href="#"
                                      // color={(showError && required && (!selectedItem || !inRange(selectedItem, parameter))) ? 'error' : 'primary'}
                                      color={showError && !parameterValid ? 'error' : 'primary'}
                                      onClick={this.onFieldNameClick(parameter)}>
                                    <Typography>
                                        {parameter.name + (required ? ' *' : '')}
                                    </Typography>
                                    {/*{parameterWARNING && parameterWARNING}*/}
                                </Link>
                                :
                                <Box>
                                    {/*<Typography color={(showError && required && (!selectedItem || !inRange(selectedItem, parameter))) ? 'error' : 'inherit'}>*/}
                                    <Typography color={showError && !parameterValid ? 'error' : 'inherit'}>
                                        {parameter.name + (required ? ' *' : '')}
                                    </Typography>
                                    {/*{parameterWARNING && parameterWARNING}*/}
                                </Box>
                        }
                    </Grid>
                    <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                        <Grid container
                              className={classes.buttonsContainer + (readonly || !parameter.enable ? (' ' + classes.disabled) : '')}>
                            <ParameterValueView
                                onValueChange={this.onValueChange(parameter)}
                                onUpdateDateChange={(updateDate) => this.onUpdateDateChange(parameter, updateDate)}
                                parameter={parameter}
                                showError={showError}
                                required={required}
                                selected={!!selectedItem}
                                readonly={readonly}
                            />
                        </Grid>
                    </Grid>

                    {/*сообщения выбранного значения*/}
                    {(parameterINFO || parameterWARNING || parameterSelectedValueINFO || parameterSelectedValueWARNING) &&
                        <Grid container
                              spacing={1}
                              direction={"row"}
                              alignItems={"center"}
                              className={classes.contentVerticalPadding}>
                            <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                            </Grid>
                            <Grid item xl={6} lg={6} md={8} sm={12} xs={12} style={{paddingLeft: '42px', paddingRight: '40px'}}>
                                {parameterINFO &&
                                    <Grid container className={classes.buttonsContainer}>
                                        {parameterINFO}
                                    </Grid>
                                }
                                {parameterWARNING &&
                                    <Grid container className={classes.buttonsContainer}>
                                        {parameterWARNING}
                                    </Grid>
                                }
                                {parameterSelectedValueINFO &&
                                    <Grid container className={classes.buttonsContainer}>
                                        {parameterSelectedValueINFO}
                                    </Grid>
                                }
                                {parameterSelectedValueWARNING &&
                                    <Grid container className={classes.buttonsContainer}>
                                        {parameterSelectedValueWARNING}
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    }

                    <Popover
                        open={Boolean(anchorFieldDescr) && Boolean(selField)}
                        anchorEl={anchorFieldDescr}
                        onClose={this.onFieldDescrClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        {this.buidViewFieldDescr(selField)}
                    </Popover>

                </Grid>

                {/*{parameterWARNING &&*/}
                {/*    <Grid container*/}
                {/*          // spacing={1}*/}
                {/*          direction={"row"}*/}
                {/*          className={`${classes.pb2} ${classes.parameterMessages}`}*/}
                {/*    >*/}
                {/*        <Grid item>*/}
                {/*            {parameterWARNING && parameterWARNING}*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*}*/}
            </React.Fragment>
        )
    }
}

ParameterView.propTypes = {
    classes: PropTypes.object.isRequired,
    parameter: PropTypes.object.isRequired,
    readonly: PropTypes.bool.isRequired,
    showError: PropTypes.bool.isRequired,
    onValueChange: PropTypes.func,
    onUpdateDateChange: PropTypes.func,
};

ParameterView.defaultProps = {
    // value: -1,
};

export default compose(
    withStyles(styles),
)(ParameterView);
