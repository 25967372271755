import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {Checkbox} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import ServiceConfirmPopper from "./ServiceConfirmPopper";
import {joinerBR, joinerNN} from "../../utils";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    wrapper: {
        alignItems: 'center',
        display: 'inline-flex',
    },
    typography: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        maxWidth: '380px',
        minWidth: '300px',
        maxHeight: '280px',
        overflow: 'auto',
        boxShadow: '3px 3px 5px rgb(0 0 0 / 0.5)',
        backgroundColor: '#f3faff',
    },
    infoText: {
        '& p': {
            margin: 0,
        }
    },
    helpIcon: {
        cursor: 'pointer',
        // marginTop: '10px'
    },
    buttons: {
        marginTop: '10px'
    },
}));

export default function ServiceCheckbox(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    // const anchorRef = React.useRef(null);
    const classes = useStyles();
    const {
        onChange,
        title,
        text,
        type,
        color,
        checked,
        value,
        disabled,
        showService,
    } = props;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClickAway = (event) => {
        // if (anchorRef.current && (event || {}).target && anchorRef.current.contains(event.target)) {
        //     return;
        // }
        setOpen(false);
    };

    const handleConfirm = (confirm: boolean) => (e) => {
        if (confirm) {
            // e.target.checked = true;
            if (!!e) {
                if (!!e.target) {
                    e.target.checked = !checked;
                    e.target.value = value;
                } else {
                    e.target = {
                        checked: !checked,
                        value: value
                    };
                }
            } else {
                e = {
                    target: {
                        checked: !checked,
                        value: value
                    }
                };
            }
            // e.target.checked = !checked;
            onChange(e);
        }
        handleClickAway();
    };

    const getServiceText = (): string => {
        const resultServiceText = [];
        if (showService) {
            text.forEach((sm) => {
                resultServiceText.push(sm.payload);
            });
        }

        return joinerNN(resultServiceText);
    };

    // console.error('+++ ServiceCheckbox +++ checked:', checked);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box component={'span'} className={classes.wrapper}>
                <Checkbox
                    color={color}
                    checked={checked}
                    value={value}
                    disabled={disabled}
                    onChange={(e) => {
                        // console.error('+++ Checkbox +++ onChange() e:', e);
                        // console.error('+++ Checkbox +++ onChange() e.target.checked:', e.target.checked);
                        // console.error('+++ Checkbox +++ onChange() showService:', showService);
                        if (!checked) {
                            if (showService) {
                                e.preventDefault();
                                handleClick(e);
                            } else {
                                onChange(e);
                            }
                        } else {
                            e.preventDefault();
                            handleConfirm(true)(e);
                        }
                    }}
                />
                <ServiceConfirmPopper
                    open={open}
                    anchorEl={anchorEl}
                    title={title}
                    text={getServiceText()}
                    type={type}
                    handleConfirm={handleConfirm}
                />
            </Box>
        </ClickAwayListener>
    );
}
