import React, {Fragment, useEffect, useRef, useState} from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {alpha, makeStyles, withStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import Typography from "@material-ui/core/Typography";
import {FormattedMessage, useIntl} from "react-intl";
import {getNotesDateByIndex, getUuid, newAnyDataRef, sortByOrder} from "../../utils";
import {
    MessageType,
    Note,
    NotesTypes,
    ProcessingStatus,
    ProcessPatientByNotesStatus,
    ServiceMessage
} from "../../const";
import ConfirmDialog from "../patient/ConfirmDialog";
import {callRemovePatientNotes} from "../../query/patient";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import CloseIcon from '@material-ui/icons/Close';
import {InfoHelp} from "./InfoHelp";
import AddIcon from "@material-ui/icons/Add";
// import config from "../../config";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UpdateIcon from "@material-ui/icons/Update";
// import NoteTextFieldViewFunctionalComponent from "./NoteTextFieldViewFunctionalComponent";
import NoteTextFieldView from "./NoteTextFieldView";

const NotesPanel = withStyles((theme) => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            border: 'none',
        },
        // '&:not(:last-child)': {
        //     borderBottom: 0,
        // },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },

        '& .MuiAccordionSummary-root.Mui-focused': {
            backgroundColor: 'unset !important',
        },
        '& .MuiAccordionSummary-root.Mui-focusVisible': {
            backgroundColor: 'unset !important',
        },
    },
    // === expanded: {},
}))(Accordion);

const NotesPanelSummary = withStyles((theme) => ({
    root: {
        padding: 0,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    // === expanded: {},
    // === content: {
    // ===     margin: '0',
    // ===     '&$expanded': {
    // ===         margin: '0',
    // ===     },
    // === },
}))(AccordionSummary);

const NotesPanelDetails = withStyles((theme) => ({
    root: {
        padding: 0,
        // minHeight: 56,
        '&$expanded': {
            // minHeight: 56,
        },
    },
    // === expanded: {},
    // === content: {
    // ===     margin: '0',
    // ===     '&$expanded': {
    // ===         margin: '0',
    // ===     },
    // === },
}))(AccordionDetails);


const SelectVisitDate = withStyles(theme => ({
    root: {
        paddingTop: '7px',
        paddingBottom: '4px',
    },
}))(Select);

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    wrapper: {
        alignItems: 'center',
        display: 'inline-flex',
    },
    popperMenu: {
        zIndex: 22222,
        // left: '-12px !important',
    },
    fullWidth: {
        width: '100%',
    },
    noPadding: {
        padding: '0 !important',
    },
    flexGrow: {
        flexGrow: 1,
    },
    selectBorder: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${alpha(theme.palette.primary.main, 0.5)}`,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
            backgroundColor: `${alpha(theme.palette.primary.main, 0.03)} !important`,
        },
    },
}));

interface PatientParameterNotesViewProps {
    readonly: boolean,
    notes: Note[],
    notesDateList: string[],
    onVisitDateChange: (visitDate: string) => void,
    onNotesChange: (notes: Note[]) => void,
    onNotesSave: (notes: Note[]) => void,
    notesAnalyze: () => void,
    client: any,
    signal: any,
}

const useFocus = () => {
    const htmlElRef = {
        1: useRef(null),
        2: useRef(null),
        3: useRef(null),
        4: useRef(null),
        5: useRef(null),
        6: useRef(null),
        7: useRef(null),
    };
    const setFocus = (noteId: string) => {
        // console.error('+++ notesRefs:', notesRefs);
        // console.error('+++ htmlElRef:', htmlElRef);
        // console.error('+++ noteId:', noteId);
        // console.error('+++ htmlElRef[noteId]:', htmlElRef[noteId]);
        if (
            !!htmlElRef[noteId].current
            &&
            !! htmlElRef[noteId].current.childNodes[0]
            &&
            !! htmlElRef[noteId].current.childNodes[0].childNodes[0]
        ) {
            htmlElRef[noteId].current.childNodes[0].childNodes[0].focus();
        }
    };

    return [ htmlElRef, setFocus ];
}

export default function PatientParameterNotesView(props: PatientParameterNotesViewProps) {
    // const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [needRerenders, setNeedRerenders] = useState({});
    // const [eventValue, setEventValue] = useState(false);
    // const anchorRef = useRef(null);
    const anchorRefSummary = useRef(null);
    const anchorRefDetails = useRef(null);
    const classes = useStyles();
    const intl = useIntl();

    const {
        readonly,
        notes,
        notesDateList,
        notesDateSelected,
        onVisitDateChange,
        onNotesChange,
        // onNotesSave,
        notesAnalyze,
        client,
        signal,
        processPatientByNotesStatus,
    } = props;

    const [selectedVisitDate, setSelectedVisitDate] = useState(!!notesDateSelected ? notesDateSelected : getNotesDateByIndex(notesDateList));

    const notesTypes: NotesTypes[] = [
        {
            type: 1,
            order: 1,
            name: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.complaints', defaultMessage: 'Жалобы'}),
            description: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.complaints.description', defaultMessage: 'жалобы пациента'}),
            disabled: false,
        },
        {
            type: 2,
            order: 2,
            name: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.diagnosis', defaultMessage: 'Диагноз'}),
            description: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.diagnosis.description', defaultMessage: 'основной, сопутствующий, осложнения основного заболевания'}),
            disabled: false,
        },
        {
            type: 3,
            order: 3,
            name: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.anamnesis', defaultMessage: 'Анамнез'}),
            description: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.anamnesis.description', defaultMessage: 'анамнез заболевания, анамнез жизни, перенесенные операции и заболевания, аллергологический анамнез, сведения о принимаемых препаратах'}),
            disabled: false,
        },
        {
            type: 4,
            order: 4,
            name: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.general_inspection', defaultMessage: 'Общий осмотр'}),
            description: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.general_inspection.description', defaultMessage: 'артериальное давление, пульс, рост, вес и т.д.'}),
            disabled: false,
        },
        {
            type: 5,
            order: 5,
            name: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.data_of_instrumental_methods', defaultMessage: 'Данные инструментальных методов'}),
            description: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.data_of_instrumental_methods.description', defaultMessage: 'электрокардиография, компьютерная томогафия и т.д.'}),
            disabled: false,
        },
        {
            type: 6,
            order: 6,
            name: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.data_from_laboratory_methods', defaultMessage: 'Данные лабораторных методов'}),
            description: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.data_from_laboratory_methods.description', defaultMessage: 'общий анализ крови, биохимический анализ т.д.'}),
            disabled: false,
        },
        {
            type: 7,
            order: 7,
            name: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.expert_advice', defaultMessage: 'Консультации специалистов'}),
            description: intl.formatMessage({id: 'label.patient.parameters.add_notes.subitem.expert_advice.description', defaultMessage: 'заключения \'узких\' специалистов'}),
            disabled: false,
        },
    ];

    const getNoteTypeOrder = (noteType: number): number => {
        return notesTypes.find((notesType) => notesType.type === noteType).order;
    };

    const orderingNotes = (notes_: Note[]): Note[] => {
        (notes_ || []).map((note) => {
            if (!note.order) {
                note.order = getNoteTypeOrder(note.type);
            }
            return note;
        });

        return notes_;
    }

    const [notesState, setNotesState] = useState(orderingNotes(notes));
    // const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        // titleConfirmDialog: '',
        // textConfirmDialog: '',
        titleConfirmDialog: intl.formatMessage({id: 'label.patient.parameters.delete_notes.subitem.confirm.title', defaultMessage: 'Title'}),
        textConfirmDialog: intl.formatMessage({id: 'label.patient.parameters.delete_notes.subitem.confirm.text', defaultMessage: 'Text'}),
        // textConfirmDialog: 'label.patient.parameters.delete_notes.subitem.confirm.text',
        buttonYes: intl.formatMessage({id: 'label.dialog.confirm.button.yes', defaultMessage: 'Yes'}),
        buttonNo: intl.formatMessage({id: 'label.dialog.confirm.button.no', defaultMessage: 'No'}),
        onConfirmDialog: () => {
            console.error('onConfirmDialog')
        },
    });
    const [expandNotes, setExpandNotes] = useState(false);

    // const [notesRefs, setInputFocus] = useFocus();
    const notesRefs = useRef([]);

    useEffect(() => {
        // console.error('+++ useEffect() +++ notesDateSelected:', notesDateSelected);
        // console.error('+++ useEffect() +++ notes:', notes);
        setNotesState(orderingNotes(notes));
        refreshNotesTypes(notes);
    }, [notes]);

    useEffect(() => {
        // +++2023.06.22+++ console.error('+++ useEffect() +++ notesDateSelected:', notesDateSelected);
        setSelectedVisitDate(!!notesDateSelected ? notesDateSelected : getNotesDateByIndex(notesDateList));
        // +++2023.06.22+++ console.error('+++ useEffect() +++ selectedVisitDate:', selectedVisitDate);
    }, [notesDateList, notesDateSelected]);

    const toggleNotesAcordion = (needOpen: boolean) => {
        if (needOpen !== undefined) {
            setExpandNotes(needOpen);
        } else {
            if (!!notesState.length) {
                setExpandNotes((prev) => !prev);
            }
        }
    };

    const refreshNotesTypes = (notes: Note[]) => {
        // useEffect(() => {
        notesTypes.forEach((noteType) => {
            noteType.disabled = false;
            const existingNote = notes.find((noteState) => noteState.type === noteType.type);
            if (existingNote) {
                noteType.disabled = true;
            }
        });
        // }, []);
    }
    // +++2023.06.29+++
    refreshNotesTypes(notesState);

    // const handleClickAway = (event) => {
    //     onMenuClose();
    // };

    // const onMenuShow = (event) => {
    //     // setMenuAnchorEl(event.currentTarget);
    // };

    const onMenuClose = () => {
        // setMenuAnchorEl(null);
        setOpen(false);
    };

    const setConfirmOpen = (open: boolean, titleConfirmDialog: string, textConfirmDialog: string, onConfirmDialog) => {
        let sTitleConfirmDialog = confirmDialog.titleConfirmDialog;
        let sTextConfirmDialog = confirmDialog.textConfirmDialog;
        if (open) {
            if (titleConfirmDialog && textConfirmDialog) {
                sTitleConfirmDialog = intl.formatMessage({id: titleConfirmDialog, defaultMessage: 'Title'});
                sTextConfirmDialog = intl.formatMessage({id: textConfirmDialog}, {defaultMessage: 'Text'});
            }
        }
        setConfirmDialog({
            open: open,
            titleConfirmDialog: sTitleConfirmDialog,
            textConfirmDialog: sTextConfirmDialog,
            buttonYes: confirmDialog.buttonYes,
            buttonNo: confirmDialog.buttonNo,
            onConfirmDialog: onConfirmDialog || confirmDialog.onConfirmDialog,
        });
    }

    const updateNotes = (notes_: Note[], noteId: string) => {
        // console.error('+++ updateNotes() +++ notes:', newAnyDataRef(notes_ || []));
        setNotesState(notes_);
        onNotesChange(notes_);
        // if (!!noteId) {
        //     setNeedRerenders({...needRerenders, [noteId]: getUuid()});
        // }
        refreshNotesTypes(notes_);
    }

    function updateNotes2(noteId: string)  {
        // console.error('+++ updateNotes2() +++ noteId:', noteId);
        // console.error('+++ updateNotes2() +++ notesState:', notesState);
    }

    const onMenuAddClick = (notesType: NotesTypes) => {
        if ( !expandNotes ) {
            toggleNotesAcordion(true);
        }
        const _notesState = notesState;
        const noteId = 'new:' + getUuid();
        _notesState.push(
            {
                id: noteId,
                type: notesType.type,
                order: notesType.order,
                payload: '',
            }
        );
        updateNotes(_notesState, noteId);
        onMenuClose();
        setTimeout(() => {
            // setInputFocus(noteId);
            if (
                !!notesRefs.current[noteId]
                &&
                !! notesRefs.current[noteId].childNodes[0]
                &&
                !! notesRefs.current[noteId].childNodes[0].childNodes[0]
            ) {
                notesRefs.current[noteId].childNodes[0].childNodes[0].focus();
            }
        });
    };

    const onDeleteNote = (noteForDelete: Note) => {
        if (!readonly) {
            if (!!(noteForDelete.payload || '').trim()) {
                setConfirmOpen(
                    true,
                    undefined,
                    undefined,
                    () => deleteNote(noteForDelete)
                );
            } else {
                deleteNote(noteForDelete);
            }
        }
    };

    const deleteNote = (noteForDelete: Note) => {
        if (!noteForDelete.id.startsWith('new:')) {
            callRemovePatientNotes(client, signal, noteForDelete)
                .then((removePatientNotesResponse) => {
                    makeDeleteNote(noteForDelete);
                });
        } else {
            makeDeleteNote(noteForDelete);
        }
    };

    const makeDeleteNote = (noteForDelete: Note) => {
        const _notesState = newAnyDataRef(notesState || []).filter((note) => note.id !== noteForDelete.id);
        updateNotes(_notesState, noteForDelete.id);
        if ( !_notesState.length ) {
            toggleNotesAcordion(false);
        }
    };

    // const isNotesTypeAdded = (notesType: number): boolean => {
    //     return notesState.some((note) => note.type === notesType);
    // };

    const getNoteTypeName = (noteType: number): string => {
        return notesTypes.find((notesType) => notesType.type === noteType).name;
    };

    const handleChangeNotes = (note: Note, notesState_: Note[], event: any) => {
        if (!readonly) {
            const noteText = event.target.value;
            // console.error('+++ handleChangeNotes() +++ noteText:', noteText);
            // console.error('+++ handleChangeNotes() +++ notesState:', newAnyDataRef(notesState || []));
            // console.error('+++ handleChangeNotes() +++ notesState_:', newAnyDataRef(notesState_ || []));
            // console.error('+++ handleChangeNotes() +++ notes:', newAnyDataRef(notes || []));
            // let _notesState = newAnyDataRef(notesState || []);
            const _notesState = newAnyDataRef(notesState || []).map((noteState) => {
                if (noteState.id === note.id) {
                    noteState.payload = noteText;
                }
                return noteState;
            });
            // console.error('+++ handleChangeNotes() +++ _notesState:', _notesState);

            // +++2023.06.28+++ для тестирования поднял сюда, но есть в updateNotes(_notesState, note.id);
            // updateNotes2(note.id);
            // +++2023.06.28+++setNotesState(_notesState);
            // if (!!note.id) {
            //     setNeedRerenders({...needRerenders, [note.id]: getUuid()});
            // }
            // +++2023.06.28+++
            updateNotes(_notesState, note.id);
        }
    }

    const analyzeButton = () => {
        const processing = [ProcessPatientByNotesStatus.PROCESSING].includes(processPatientByNotesStatus);
        if (
            !readonly
            &&
            !!notes && !!notes.length && notes.some((note) => !!note.payload)) {
            return <Grid item>
                <Button color="primary"
                        variant="outlined"
                        size={"small"}
                        onClick={(event) => {
                            notesAnalyze();
                        }}
                        disabled={!notes || processing}
                        startIcon={processing ? <UpdateIcon /> : undefined}
                >
                    <FormattedMessage id={'label.patient.parameters.analyze_notes'}
                                      defaultMessage={'Analyze'}/>
                </Button>
            </Grid>
        }
        return <></>
    };

    const onChangeVisitDate = (visitDate: string) => {
        // console.error('+++ onChangeVisitDate() +++ visitDate:', visitDate);
        if (!readonly) {
            setSelectedVisitDate(visitDate);
            onVisitDateChange(visitDate);
        }
    };

    const renderVisitDate = (visitDate: string, color: string) => {
        const splitedVisitDate = visitDate.split(' ');
        const style = {
            fontFamily: 'monospace',
        };
        const styleDate = {
            fontWeight: '500',
        };
        const styleTime = {
            opacity: '0.7',
            fontSize: '0.8rem',
        };
        return <Typography variant={"body2"} color={color} style={style} ><span style={styleDate}>{splitedVisitDate[0]}</span> <span style={styleTime}>{splitedVisitDate[1]}</span></Typography>;
    };

    const selectNotesByDate = () => {
        if (!!(notesDateList || []).length) {
            return <Grid item>
                <SelectVisitDate
                    color={'primary'}
                    className={classes.selectBorder}
                    // fullWidth
                    // onChange={this.onValueChange()}
                    // onChange={(e) => onChangeVisitDate(e.value)}
                    // onChange={(e) => {
                    //     e.preventDefault();
                    //     this.handleClick(null, null)(e);
                    // }}
                    // onChange={(e) => {
                    //     e.preventDefault();
                    // onChangeVisitDate(e.value);
                    // }}
                    value={selectedVisitDate}
                    variant='outlined'
                    size={'small'}
                    // renderValue={(selected) => <span className={isDefault ? classes.isDefault : classes.isNonDefaultSelect}>{(selectedItem || {}).name || ''}</span>}
                    renderValue={(selected) => renderVisitDate(selected, 'primary')}
                    // MenuProps={{
                    //     anchorOrigin: {
                    //         vertical: "bottom",
                    //         horizontal: "left"
                    //     },
                    //     getContentAnchorEl: null
                    // }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                >
                    {notesDateList.map((visitDate) => {
                        return (
                            <MenuItem key={visitDate}
                                      value={visitDate}
                                      onClick={() => onChangeVisitDate(visitDate)}
                            >
                                {/*<Typography variant={"body2"} >{visitDate}</Typography>*/}
                                {renderVisitDate(visitDate, 'inherit')}
                            </MenuItem>
                        );
                    })}
                </SelectVisitDate>
            </Grid>
        }
        return <></>
    };

    /*
        const saveNotes = () => {
            onNotesSave(notesState);
        };
    */

    /*
        const saveNotesButton = () => {
            if (
                (config.options.NOTES.SAVE || {}).enable
                &&
                !!notesState
                &&
                !!notesState.length
                // &&
                // notesState.some((note) => !!note.payload)
            ) {
                return <Grid item>
                    <Button color="primary"
                            variant="outlined"
                            size={"small"}
                            onClick={(event) => {
                                saveNotes();
                            }}
                            disabled={!notesState}
                    >
                        <FormattedMessage id={'label.save'}
                                          defaultMessage={'Save'}/>
                    </Button>
                </Grid>
            }
            return <></>
        };
    */

    const handleToggle = (event) => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        // if (
        //     (expandNotes && anchorRefDetails.current && anchorRefDetails.current.contains(event.target))
        //     ||
        //     (!expandNotes && anchorRefSummary.current && anchorRefSummary.current.contains(event.target))
        // ) {
        //     return;
        // }

        setOpen(false);
    };

    const addSubItemButton = () => {
        return (
            !!notesTypes.filter((notesType) => !notesType.disabled).length ?
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Grid item xs={12}>
                            <ButtonGroup variant="outlined" size={"small"} color="primary" ref={!!expandNotes ? anchorRefDetails : anchorRefSummary} aria-label="split button">
                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={(event) => {
                                        onMenuAddClick(notesTypes.filter((notesType) => !notesType.disabled)[0]);
                                    }}
                                >
                                    {notesTypes.filter((notesType) => !notesType.disabled)[0].name}
                                    {/*<FormattedMessage id='label.patient.parameters.goPatient' defaultMessage='Go patient'/>*/}
                                </Button>
                                <Button
                                    color="primary"
                                    size="small"
                                    // aria-controls={open ? 'split-button-menu' : undefined}
                                    aria-controls={'split-button-menu'}
                                    // aria-expanded={open ? 'true' : undefined}
                                    aria-expanded={'true'}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    onClick={handleToggle}
                                    // disabled={disableButtons}
                                >
                                    <ArrowDropDownIcon/>
                                </Button>
                            </ButtonGroup>
                            <Popper
                                anchorEl={expandNotes ? anchorRefDetails.current : anchorRefSummary.current}
                                open={open}
                                role={undefined}
                                transition={true}
                                disablePortal={false}
                                className={classes.wrapper + ' ' + classes.popperMenu}
                                placement={'bottom-start'}
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom-start' ? 'bottom-end' : 'bottom-start',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList id="split-button-menu">
                                                    {notesTypes.filter((notesType) => !notesType.disabled).map((notesType) =>
                                                        <MenuItem
                                                            key={notesType.type}
                                                            onClick={(e) => onMenuAddClick(notesType)}
                                                        >
                                                            {notesType.name}
                                                        </MenuItem>
                                                    )}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Grid>
                    </Grid>
                </Grid>
                : undefined
        );
    };

    const getServiceInfoText = (note: Note): ServiceMessage => {
        const notesType = notesTypes.find((noteType) => noteType.type === note.type);
        const newLine = '\n\n';
        const serviceInfoText: ServiceMessage = {
            showService: !!notesType,
            type: MessageType.INFO,
            messages: [
                {
                    type: MessageType.INFO,
                    payload: !!notesType ? ('**' + notesType.name + '**' + newLine + '*' + notesType.description + '*') : '',
                }
            ],
        };

        return serviceInfoText;
    }

    // console.error('+++ render anchorRefSummary:', anchorRefSummary);
    // console.error('+++ render anchorRefDetails:', anchorRefDetails);
    // console.error('+++ render notes:', newAnyDataRef(notes || []));
    // console.error('+++ render notesState:', newAnyDataRef(notesState || []));
    // console.error('+++ render notesDateList:', notesDateList);
    // +++2023.06.22+++ console.error('+++ render selectedVisitDate:', selectedVisitDate);
    // console.error('+++ render notesDateSelected:', notesDateSelected);
    // console.error('+++ render +++ notesRefs:', notesRefs);

    return (
        // <ClickAwayListener onClickAway={handleClickAway}>
        <NotesPanel
            square={true}
            expanded={expandNotes}
        >
            <NotesPanelSummary
                expandIcon={(!!notesState && !!notesState.length) ? <ExpandMoreIcon onClick={() => toggleNotesAcordion()}/> : undefined}>
                {/*IconButtonProps={{*/}
                {/*    onClick: toggleNotesAcordion*/}
                {/*}}*/}
                <Grid container
                      spacing={1}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    // className={classes.contentBoxTopSpace}
                      className={classes.noPadding}
                >
                    <Grid item className={classes.noPadding + ' ' + classes.flexGrow}>
                        <Typography color={"primary"} variant={"h6"} onClick={() => toggleNotesAcordion()}>
                            <FormattedMessage id={'label.patient.parameters.text_data'} defaultMessage={'Text data'}/>
                        </Typography>
                    </Grid>
                    <Grid item className={classes.noPadding}>
                        <Grid container
                              spacing={1}
                              direction={"row"}
                              alignItems={"center"}
                        >
                            {/*{saveNotesButton()}*/}
                            {selectNotesByDate()}
                            {analyzeButton()}
                            {(!readonly && !notesState.length) &&
                                addSubItemButton()
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </NotesPanelSummary>
            <NotesPanelDetails>
                <Grid container
                      spacing={1}
                      direction={"column"}
                      className={classes.noPadding}
                >
                    <Grid item className={classes.noPadding}>
                        <Box className={classes.fullWidth}>
                            {(notesState || [])
                                .sort(sortByOrder)
                                .map((note) =>
                                    <Fragment key={note.id}>
                                        <Box key={note.id}>
                                            <Grid container
                                                  spacing={1}
                                                  direction={"row"}
                                                  alignItems={"center"}
                                                  className={classes.contentVerticalPadding}>
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Grid container
                                                          spacing={1}
                                                          direction={"row"}
                                                          alignItems={"center"}
                                                          justifyContent={"space-between"}
                                                          className={classes.contentBoxTopSpace}
                                                    >
                                                        <Grid item>
                                                            <Grid container
                                                                  spacing={1}
                                                                  direction={"row"}
                                                                  alignItems={"flex-end"}
                                                            >
                                                                <Grid item>
                                                                    <Typography color={"primary"} variant={"subtitle1"}>
                                                                        {getNoteTypeName(note.type)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <InfoHelp texts={getServiceInfoText(note).messages}/>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {!readonly &&
                                                            <Grid item style={{display: 'flex', marginTop: '6px'}}>
                                                                <CloseIcon fontSize={'small'}
                                                                           onClick={(e) => onDeleteNote(note)}
                                                                           style={{color: 'red', cursor: 'pointer'}}/>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Grid container className={readonly ? classes.disabled : ''}>
                                                        <form noValidate autoComplete="off" style={{width: '100%'}}>
                                                            {/*
                                                        <TextField
                                                            // xl={12} lg={12} md={12} sm={12} xs={12}
                                                            className={classes.textarea}
                                                            style={{width: '100%'}}
                                                            multiline
                                                            minRows={10}
                                                            maxRows={20}
                                                            value={note.payload}
                                                            onChange={handleChangeNotes(note)}
                                                            variant="outlined"
                                                            disabled={readonly}
                                                            // ref={notesRefs[note.id]}
                                                            ref={el => notesRefs.current[note.id] = el}
                                                        />
*/}
{/*
                                                            <NoteTextFieldViewMemoized
                                                                classes={classes}
                                                                note={note}
                                                                // handleChangeNotes={handleChangeNotes}
                                                                handleChangeNotes={handleChangeNotes(note, notesState)}
                                                                readonly={readonly}
                                                                notesRefs={notesRefs}
                                                                needRerender={needRerenders[note.id]}
                                                            />
*/}
                                                            <NoteTextFieldView
                                                                // classes={classes}
                                                                note={note}
                                                                notes={notesState}
                                                                // handleChangeNotes={handleChangeNotes}
                                                                handleChangeNotes={(e, notes_) => handleChangeNotes(note, notes_, e)}
                                                                readonly={readonly}
                                                                notesRefs={notesRefs}
                                                                needRerender={needRerenders[note.id]}
                                                            />
                                                        </form>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {/*
                                    <NoteTextFieldView
                                        ref={el => notesRefs.current[note.id] = el}

                                        readonly={readonly}
                                        note={note}
                                        noteTypeName={getNoteTypeName(note.type)}
                                        infoHelpTexts={getServiceInfoText(note).messages}
                                        handleChangeNotes={handleChangeNotes}
                                        onDeleteNote={onDeleteNote}
                                    />
*/}
                                    </Fragment>
                                )}
                        </Box>
                    </Grid>
                    {(!readonly && !!notesState.length && !!notesTypes.filter((notesType) => !notesType.disabled).length) &&
                        <Grid item className={classes.noPadding}>
                            <Grid container
                                  spacing={1}
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"flex-end"}
                                  className={classes.contentBoxTopSpace}
                                  style={{paddingBottom: '8px'}}
                            >
                                <Grid item>
                                    <Grid container
                                          spacing={1}
                                          direction={"row"}
                                          alignItems={"center"}
                                    >
                                        {addSubItemButton()}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {confirmDialog.open &&
                        <ConfirmDialog
                            title={confirmDialog.titleConfirmDialog}
                            text={confirmDialog.textConfirmDialog}
                            buttonYes={confirmDialog.buttonYes}
                            buttonNo={confirmDialog.buttonNo}
                            open={confirmDialog.open}
                            setOpen={setConfirmOpen}
                            onConfirm={confirmDialog.onConfirmDialog}
                        >
                        </ConfirmDialog>
                    }
                </Grid>
            </NotesPanelDetails>
        </NotesPanel>
        // </ClickAwayListener>
    );
}

/*
function noteTextFieldPropsAreEqual(prevProps, nextProps) {
    // return false;
    // return (!!prevProps.needRerender || !!nextProps.needRerender) && prevProps.needRerender === nextProps.needRerender;
    if ((!!prevProps.needRerender || !!nextProps.needRerender) && prevProps.needRerender !== nextProps.needRerender) {
        // console.log('+++ prevProps.note:', prevProps.note);
        // console.log('+++ nextProps.note:', nextProps.note);
        // console.log('+++++++++ prevProps.needRerender:', prevProps.needRerender);
        // console.log('+++++++++ nextProps.needRerender:', nextProps.needRerender);
        return false;
    } else {
        return true;
    }
}
const NoteTextFieldViewMemoized0 =  memo(({classes, note, readonly, notesRefs, handleChangeNotes, needRerender}) => {
    return <TextField
        // xl={12} lg={12} md={12} sm={12} xs={12}
        className={classes.textarea}
        style={{width: '100%'}}
        multiline
        minRows={10}
        maxRows={20}
        value={note.payload}
        onChange={handleChangeNotes(note)}
        variant="outlined"
        disabled={readonly}
        // ref={notesRefs[note.id]}
        ref={el => notesRefs.current[note.id] = el}
    />
}, noteTextFieldPropsAreEqual);
const NoteTextFieldViewMemoized =  memo(({classes, note, readonly, notesRefs, handleChangeNotes, needRerender}) => {
    return <TextField
        // xl={12} lg={12} md={12} sm={12} xs={12}
        className={classes.textarea}
        style={{width: '100%'}}
        multiline
        minRows={10}
        maxRows={20}
        value={note.payload}
        onChange={handleChangeNotes}
        variant="outlined"
        disabled={readonly}
        // ref={notesRefs[note.id]}
        ref={el => notesRefs.current[note.id] = el}
    />
}, noteTextFieldPropsAreEqual);
const NoteTextFieldViewMemoized1 = ({classes, note, readonly, notesRefs, handleChangeNotes, needRerender}) => {
    return <TextField
        // xl={12} lg={12} md={12} sm={12} xs={12}
        className={classes.textarea}
        style={{width: '100%'}}
        multiline
        minRows={10}
        maxRows={20}
        value={note.payload}
        onChange={handleChangeNotes(note)}
        variant="outlined"
        disabled={readonly}
        // ref={notesRefs[note.id]}
        ref={el => notesRefs.current[note.id] = el}
    />
};
*/
