import React from 'react';
import PropTypes from 'prop-types';
import {Paper} from "@material-ui/core";
import {grey, red} from "@material-ui/core/colors";

export default function PaperColor(props) {
  const { color, bordersize } = props;

  return (
    <Paper
      variant={"outlined"}
      style={{ borderColor: color, borderWidth: bordersize }}
      {...props}>
      {props.children}
    </Paper>
  );
}

PaperColor.propTypes = {
  color: PropTypes.string,
  borderWidth: PropTypes.number,
};

PaperColor.defaultProps = {
  color: grey[300],
  bordersize: 1,
};

export const SelectType = {
  NONE: 0,
  POSITIVE: 1,
  NEGATIVE: 2,
};

export function PaperColorSelect(props) {
  switch (props.seltype) {
    case SelectType.POSITIVE:
      return (
        <PaperColor
          bordersize ={3}
          // color={green[500]}
          color={'#0f7fa5'}
          {...props}>
          {props.children}
        </PaperColor>
      );

    case SelectType.NEGATIVE:
      return (
        <PaperColor
          bordersize ={3}
          color={red[500]}
          {...props}>
          {props.children}
        </PaperColor>
      );

    default:
      return (
        <PaperColor {...props}>
          {props.children}
        </PaperColor>
      );
  }
}

PaperColorSelect.propTypes = {
  seltype: PropTypes.number,
};

PaperColorSelect.defaultProps = {
  seltype: SelectType.NONE,
};
