import { gql } from '@apollo/client';
import config from "../config";
import type {
    Product as ProductModel,
    Purchase as PurchaseModel,
} from '../const';
import {getPromiseQueryResult} from "../utils";

const Agreement = `
    content
    accept
`;

const Product = `
    id
    name
    price
    oldPrice
    agreements {
        ${Agreement}
    }
    descr
    ord
`;

const Payment = `
    id
    providerId
    idempotenceKey
    confirmationUrl
    status
    createAt
    updateAt
`;

const Purchase = `
    id
    username
    product {
        ${Product}
    }
    payment {
        ${Payment}
    }
    status
    info
    createAt
    updateAt
`;

export const getActiveProducts = gql`
    {
        getActiveProducts {
            ${Product}
        }
    }
`;

export const getPurchases = gql`
    {
        getPurchases {
            ${Purchase}
        }
    }
`;

export const createPurchase = gql`
    mutation ($productId: Int!, $returnUrl: String!) {
        createPurchase(productId: $productId, , returnUrl: $returnUrl) {
            ${Purchase}
        }
    }
`;

export const loadGetActiveProducts = (client, signal): Promise<ProductModel[]> => {
    return client.query({
        query: getActiveProducts,
        context: {
            uri: config.options.server.billing + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getActiveProducts', []);
        });
}

export const loadGetPurchases = (client, signal): Promise<PurchaseModel[]> => {
    return client.query({
        query: getPurchases,
        context: {
            uri: config.options.server.billing + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getPurchases', []);
        });
}

export const callCreatePurchase = (client, signal, productId, returnUrl): Promise<PurchaseModel> => {
    return client.mutate({
        mutation: createPurchase,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.billing + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            productId: productId,
            returnUrl: returnUrl,
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'createPurchase');
    });
}
