import TextField from "@material-ui/core/TextField";
import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import validator from 'validator';
import {Grid} from "@material-ui/core";
import {ProfileType} from '../../const';
import {isNumber} from '../../utils';
import ProfileEdit from "../profile/ProfileEdit";
import {FormattedMessage, injectIntl} from "react-intl";
import compose from 'recompose/compose';

const styles = theme => ({
  ...mainStyles(theme),
  submit: {
    marginTop: theme.spacing(3),
  },
  info_head: {
    marginTop: theme.spacing(4),
  },
});

class RegRequestForm extends React.Component{

  constructor(props) {
    super(props);
    const { intl } = props;

    this.state = {
      fields: new Map([
        ['email', ''],
        ['role', ProfileType.EXPERT],
      ]),
      profile: {
        spec: intl.formatMessage({id: 'label.speciality.default'}),
        city: '',
        job: '',
        degree: '',
        skills: '',
        experience: '',
      },
      errors: new Map(),
    };

    this.validate = {
      'email': (value) => {
        let errors = this.state.errors;

        if (value.length < 5 || !validator.isEmail(value)) {
          errors.set('email', intl.formatMessage({id: 'err.form.email.invalid', defaultMessage: 'Email invalid'}));
        } else {
          errors.delete('email');
        }

        return errors;
      },
      'role': (value) => {
        let errors = this.state.errors;

        if (isNumber(value)) {
          errors.delete('role');
        } else {
          errors.set('role', intl.formatMessage({id: 'err.role.not_set', defaultMessage: 'Role is not set'}));
        }

        return errors;
      },
    };
  }

  onValueChange = (event) => {
    let target = event.target;

    this.setState({
      fields: this.state.fields.set(target.name, target.value),
      errors: this.validate[target.name](target.value),
    });
  };

  onProfileChange = (name, value) => {
    const { profile } = this.state;
    profile[name] = value;
    this.setState({
      profile: profile,
    })
  };

  onSubmit = (event) => {
    const { fields, profile } = this.state;

    for (let [name, value] of fields) {
      this.validate[name](value);
    }

    if (this.state.errors.size > 0) {
      this.forceUpdate();
    } else {
      this.props.onSubmit({
        email: fields.get('email'),
        role: fields.get('role'),
        profile: profile,
      });
    }

    event.preventDefault();
  };

  render() {
    const { classes, intl, loading } = this.props;
    const { profile } = this.state;
    const xs = 12;

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>

        <Grid container spacing={4}>
          <Grid item xs={xs}>
            <TextField
              id="email"
              name="email"
              autoComplete="email"
              value={this.state.fields.get('email')}
              onChange={this.onValueChange}
              autoFocus
              margin="normal"
              required
              fullWidth
              label={intl.formatMessage({id: 'label.email', defaultMessage: 'Email'})}
              error={this.state.errors.get('email') != null}
              helperText={this.state.errors.get('email')}
            />
          </Grid>
        </Grid>

        <ProfileEdit
          fields={profile}
          onFieldChange={this.onProfileChange}
        />

        <div className={classes.contentBoxCenter}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || this.state.errors.size > 0}
            className={classes.submit}>
            <FormattedMessage id='label.access.request' defaultMessage='Request access' />
          </Button>
        </div>

        <Typography className={classes.info_head} variant="body2">
          <FormattedMessage id='message.account.already' />
        </Typography>
        <Link className={classes.link} to="/signin">
          <FormattedMessage id='message.account.login' />
        </Link>
      </form>
    );
  }
}

RegRequestForm.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(RegRequestForm);
