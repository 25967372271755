import React from 'react';
import {
  IntlProvider,
} from 'react-intl'
import messageRu from './messages-ru.json';
import messageEn from './messages-en.json';
import PropTypes from 'prop-types';

class LocaleProvider extends React.Component {

  render() {
    const { children } = this.props;
    const { locale } = this.context;
    const message = {
      ru: messageRu,
      en: messageEn,
    };

    return (
      <IntlProvider
        key={locale}
        locale={locale}
        messages={message[locale]}>
        {children}
      </IntlProvider>
    );
  }
}

LocaleProvider.contextTypes = { 
  locale: PropTypes.string.isRequired,
};

export default LocaleProvider;
