import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ErrorPage from "./ErrorPage";
import compose from "recompose/compose";
import {injectIntl} from "react-intl";

class ErrorBoundary extends Component {
    state = {
        error: '',
        eventId: '',
        errorInfo: '',
        hasError: false,
    };

    static getDerivedStateFromError(error) {
        return {hasError: true, error};
    }

    componentDidCatch(error, errorInfo) {
        this.setState({errorInfo});
    }

    render() {
        const {hasError} = this.state;
        const {intl} = this.props;
        if (hasError) {
            return (
                <ErrorPage
                    // image={<ErrorIllustration />}
                    title={intl.formatMessage({id: 'label.error.title', defaultMessage: 'Something went wrong'})}
                    description={intl.formatMessage({id: 'label.error.description', defaultMessage: 'An error occurred while rendering the page'})}
                />
                // <div className="card my-5">
                //     <div className="card-header">
                //         <p>
                //             There was an error in loading this page.{' '}
                //             <span
                //                 style={{cursor: 'pointer', color: '#0077FF'}}
                //                 onClick={() => {
                //                     window.location.reload();
                //                 }}
                //             >
                //                 Reload this page
                //             </span>{' '}
                //         </p>
                //     </div>
                //     <div className="card-body">
                //         <details className="error-details">
                //             <summary>Click for error details</summary>
                //             {errorInfo && errorInfo.componentStack.toString()}
                //         </details>
                //     </div>
                // </div>
            );
        }

        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    intl: PropTypes.object.isRequired,
};

export default compose(
    injectIntl,
)(ErrorBoundary);
