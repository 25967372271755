import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import { withApollo } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import React from 'react';
import {Component} from 'react';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import {calcFLBaseline, loadGetOutcomeRiskForTherapy} from '../query/baseline';
import PanelDivider from '../comp/PanelDivider';
import {FormattedMessage, injectIntl} from 'react-intl';
import {SelectTherapyLine, SelectTherapyTreats, TherapyResultView} from "../comp/baseline/BComp";
import {FeatureNames, ThNames1, ThNames2} from "../comp/baseline/BaselineVars";
import {
    capitalizeFirstLetter,
    finalStepNosologyRedirect,
    getAccess,
    getNextLabel,
    getNosologyDataHistory, getParametersForBaselineDto,
    getQueryResult,
    getSelected, getSelectedTreatmentsList,
    getURLSearchParams,
    initGroupedBarChart2,
    isBackDisable,
    isDisableResultNext, isEmpty,
    isPrescriptionReadonly,
    isShowComparisons,
    isTherapyPresumably, loadStaticBaseline,
    onlySaveBaseline, patientDrugs,
    stringDivider,
    toFixed
} from "../utils";
import SelTreatView from "../comp/baseline/SelTreatView";
import SelTextView from "../comp/SelTextView";
import {
    // callSavePatient,
    loadGetPatient,
    loadGetSelectedValuesNosology,
    savePatientNosology
} from "../query/patient";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import {
    globalTheme,
    GroupedBarChartColors,
    NosologyType, OutcomeRiskForTherapyDto,
    PatientType,
    PrescriptionType,
} from "../const";
import type {
    BaselineParametrSectionDto,
    ActivePatient, BaselineParameterDto,
    StatDataInInputDto,
    TherapyCompareDto, TherapyCompareStatisticsDto,
    TherapyRecommendIn, ParametersForBaselineDto, StrictIndicatorsInputDto
} from "../const";
import SelectTherapyPresumablyView from "../comp/baseline/SelectTherapyPresumablyView";
import {loadGetTherapyCompare, loadGetTherapyCompareStatistics} from "../query/therapy";
import GroupedBarChart from "../comp/charts/GroupedBarChart";
import BaselineDialog from "../comp/patient/BaselineDialog";
import SaveOrRestartButton from "../comp/button/SaveOrRestartButton";
import StaticBaseline from "../comp/StaticBaseline";
import ExpandableBaselineParameterStrictContextPanel from "../comp/common/ExpandableBaselineParameterStrictContextPanel";
import config from "../config";
import {loadGetStrictIndicators} from "../query/common";

const styles = theme => ({
    ...mainStyles(theme),
    fieldVarNameDescr: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    returnPatientButton: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
});

const ThNames = [ThNames1, ThNames2];

function createSelectTherapyComp(groupId) {
    return class extends Component {
        render() {
            return (<SelectTherapyTreats
                groupId={groupId}
                {...this.props}
            />)
        }
    }
}

const SelectTherapyAAD = createSelectTherapyComp(0);
const SelectTherapyNOAC = createSelectTherapyComp(1);

class Baseline extends Component {

    abortController = new AbortController();
    activePatient: ActivePatient = {};
    chadsVasc: number = null;

    constructor(props) {
        super(props);
        const {intl} = props;

        const steps = [
            /*
            {
                title: intl.formatMessage({
                    id: 'label.bl.select_therapy_line1',
                    defaultMessage: 'Select line of therapy'
                }),
                descr: (<Typography>
                    <FormattedMessage
                        id={'label.bl.select_therapy_line2'}
                        defaultMessage={'Select line of therapy'}/>
                </Typography>),
                contentFunc: this.getTherapySelectLineView,
                isNextFunc: this.isLineSelect,
                prevFunc: this.onSelectLinePrev,
            },
            */
            {
                title: intl.formatMessage({
                    id: 'label.bl.referent_guidelines',
                    defaultMessage: 'Select drug(from ACC/AHA/HRS and ESC Guidelines)'
                }),
                descr: (
                    <Box>
                        {[
                            intl.formatMessage({id: 'label.bl.select_therapy_descr1', defaultMessage: ''}),
                            intl.formatMessage({id: 'label.bl.select_therapy_descr2', defaultMessage: ''}),
                            intl.formatMessage({id: 'label.bl.select_therapy_descr3', defaultMessage: ''}),
                            intl.formatMessage({id: 'label.bl.select_therapy_descr4', defaultMessage: ''}),
                        ]
                            .filter(desc => desc.trim().length > 0)
                            .map((desc, i) => (<Box key={i}>
                                <PanelDivider/>
                                <Typography>
                                    {desc}
                                </Typography>
                            </Box>))}
                        <PanelDivider/>
                        <Typography>
                            <FormattedMessage id={'label.bl.next_noac_select_descr'}/>
                        </Typography>
                    </Box>
                ),
                contentFunc: this.getAADView,
                isNextFunc: this.isAADSelect,
                prevFunc: this.onTherapyTreatsPrev(0),
            },
            {
                title: intl.formatMessage({
                    id: 'label.bl.referent_guidelines',
                    defaultMessage: 'Select drug(from ACC/AHA/HRS and ESC Guidelines)'
                }),
                descr: (
                    <Box>
                        {[
                            intl.formatMessage({id: 'label.bl.select_therapy_descr1', defaultMessage: ''}),
                            intl.formatMessage({id: 'label.bl.select_therapy_descr2', defaultMessage: ''}),
                            intl.formatMessage({id: 'label.bl.select_therapy_descr3', defaultMessage: ''}),
                            intl.formatMessage({id: 'label.bl.select_therapy_descr4', defaultMessage: ''}),
                        ]
                            .filter(desc => desc.trim().length > 0)
                            .map((desc, i) => (<Box key={i}>
                                <PanelDivider/>
                                <Typography>
                                    {desc}
                                </Typography>
                            </Box>))}
                        <PanelDivider/>
                        <Typography>
                            <FormattedMessage id={'label.bl.next_step_descr'}/>
                        </Typography>
                    </Box>
                ),
                contentFunc: this.getNOACView,
                isNextFunc: this.isNOACSelect,
                prevFunc: this.onTherapyTreatsPrev(1),
            },
            {
                title: intl.formatMessage({id: 'label.bl.medical_report', defaultMessage: 'Report'}),
                contentFunc: this.getTherapyResultView,
                nextFunc: this.onNextResultStep,
                isNextFunc: () => true,
                nextLabel: intl.formatMessage({id: 'label.bl.new_patient', defaultMessage: 'New patient'})
            },
        ];

        this.activePatient = getURLSearchParams(props.location.search);
        if (this.activePatient.patientType === PatientType.PUBLIC || this.activePatient.patientType === PatientType.EDUCATION) {
            const stepSelectTherapyPresumably = {
                title: intl.formatMessage({
                    id: 'label.nosology.add_drugs',
                    defaultMessage: 'Adding drugs'
                }),
                // descr: intl.formatMessage({
                //   id: 'label.hp.therapy.type.sel.desc',
                //   defaultMessage: 'Aim for BP control within 3 months'
                // }),
                contentFunc: this.getSelectTherapyPresumablyView,
                isNextFunc: this.isTherapyPresumably,
                backFunc: this.backSelectTherapyPresumably,
            };
            steps.unshift(stepSelectTherapyPresumably);
        }

        this.state = {
            disableResultNext: true,
            nosologyData: {
                touched: true, // FIXME: почему начальное значение TRUE?
                lines: {},
                varLines: {},
                therapyGroups: this.initTherapyGroups(),
                compStat: this.getCompStat(),
                selLineId: 1
            },
            activeStep: 0,
            steps: steps,

            baselineDialog: {
                open: false,
            },

            allDataLoaded: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.history.location.search !== prevProps.location.search) {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        this.chadsVasc = null;
        this.setState({
            activeStep: 0,
            baseline: [],
        });
        this.setContextPanelView(null);
        this.activePatient = getURLSearchParams(this.props.location.search);
        this.loadPatientNosology(this.activePatient);
    }

    loadPatientNosology = (activePatient) => {
        const {patientDataContext, setPatientDataContext} = this.context;
        const {signal} = this.abortController;
        const {client} = this.props;

        // TODO: переходим на новую логику
        if (activePatient.patient) {
            if (!(patientDataContext || {}).patientDataContext) {
                loadGetPatient(client, signal, activePatient.patient, activePatient.patientUuid, setPatientDataContext)
                    .then((patientData) => {
                        this.proceedPatientNosology(client, signal, activePatient.action, activePatient.patient, activePatient.nosology, activePatient.history);
                    });
            } else {
                this.proceedPatientNosology(client, signal, activePatient.action, activePatient.patient, activePatient.nosology, activePatient.history);
            }
        } else {
            // this.loadBaseline();
        }

    }

    proceedPatientNosology = (client, signal, action, patientId, nosologyId, patientHistory) => {
        // TODO: здесь вызов нового сервиса, историю не надо передавать, только пациента и нозологию
        const {patientDataContext, setPatientDataContext} = this.context;

        patientDataContext.patientDataContext.actualBaselineParameterStrict = patientDataContext.patientDataContext.baselineParameterStrict;

        loadStaticBaseline(client, signal, nosologyId, patientDataContext, setPatientDataContext, () => {
/*
            const strictIndicatorsInputDto: StrictIndicatorsInputDto = {
                nosologyId: Number(nosologyId),
                patientId: Number(patientId),
            };
            loadGetStrictIndicators(client, signal, strictIndicatorsInputDto)
                .then((strictIndicators) => {
                    patientDataContext.patientDataContext.baselineParameterStrict = strictIndicators;
                    setPatientDataContext(patientDataContext, () => {
                        if (!!patientHistory) {
                            const _nosologyData = getNosologyDataHistory(patientDataContext.patientDataContext, nosologyId);

                            this.setState(prevState => ({
                                nosologyData: _nosologyData,
                            }), () => {
                                const {nosologyData} = this.state;
                                this.onStep0Proceed(nosologyData, patientId, nosologyData.selectedValuesNosology, action, false);
                            });
                        } else {
                            loadGetSelectedValuesNosology(client, signal, patientId, nosologyId)
                                .then((selectedValuesNosology) => {
                                    this.setState(prevState => ({
                                        nosologyData: {
                                            ...prevState.nosologyData,
                                            selectedValuesNosology: selectedValuesNosology,
                                        }
                                    }), () => {
                                        const {nosologyData} = this.state;
                                        this.onStep0Proceed(nosologyData, patientId, nosologyData.selectedValuesNosology, action, false);
                                    });
                                });
                        }
                    });
                });
*/

/**/
/* +++2022.06.02+++ отказываемся от парсинга полей в истории response
            if (!!patientHistory) {
                const _nosologyData = getNosologyDataHistory(patientDataContext.patientDataContext, nosologyId);

                this.setState(prevState => ({
                    nosologyData: _nosologyData,
                }), () => {
                    const {nosologyData} = this.state;
                    console.error('+++ patientDataContext:', patientDataContext);
                    console.error('+++ nosologyData:', nosologyData);
                    console.error('+++ _nosologyData:', _nosologyData);
                    this.onStep0Proceed(nosologyData, patientId, nosologyData.selectedValuesNosology, action, false);
                });
            } else {
*/
                loadGetSelectedValuesNosology(client, signal, patientId, nosologyId)
                    .then((selectedValuesNosology) => {
                        this.setState(prevState => ({
                            nosologyData: {
                                ...prevState.nosologyData,
                                selectedValuesNosology: selectedValuesNosology,
                            }
                        }), () => {
                            const {nosologyData} = this.state;
                            this.onStep0Proceed(nosologyData, patientId, nosologyData.selectedValuesNosology, action, false);
                        });
                    });
/* +++2022.06.02+++ отказываемся от парсинга полей в истории response
            }
*/
/**/
        });
    }

    componentWillUnmount() {
        this.abortController.abort();

        this.setContextPanelView(null);
    }

    initTherapyGroups = () => {
        return ThNames.map(it => ({
            thNames: it,
            thData: it.map(th => ({name: th.name})),
        }));
    };

    cleanTherapyGroup = (gId) => {
        const {nosologyData} = this.state;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyGroups: nosologyData.therapyGroups
                    .map((th, i) => {
                        if (i === gId) {
                            const thName = ThNames[gId];
                            return {
                                thNames: thName,
                                thData: thName.map(th => ({name: th.name})),
                            };
                        } else {
                            return th;
                        }
                    }),
            },
        }));
    };

    getSelVarIds = () => {
        const {nosologyData} = this.state;
        return nosologyData.selectedValuesNosology;
    };

    getThNames = (groupId) => {
        const {nosologyData} = this.state;
        // console.error('+++ getThNames() +++ nosologyData:', nosologyData);
        // console.error('+++ getThNames() +++ groupId:', groupId);
        // console.error('+++ getThNames() +++ nosologyData.therapyGroups:', nosologyData.therapyGroups);

        if (nosologyData.selLineId === -1) {
            return [];
        }

        switch (groupId) {
            case 0: {
                if (!!nosologyData.selLineId && !!nosologyData.varLines /*&& !isEmpty(nosologyData.varLines)*/) {
                    return nosologyData.varLines[nosologyData.selLineId].therapies;
                } else {
                    return nosologyData.therapyGroups[groupId].thNames;
                }
            }
            case 1:
                return nosologyData.therapyGroups[groupId].thNames;
            default:
                return [];
        }
    };

    getTherapyIdWithName = (thName) => {
        const {sgTreatments} = this.getSelectLine() || [];

        if (Boolean(sgTreatments) && !!sgTreatments.length) {
            const sgTr = sgTreatments
                .find(tr => tr.active === 1 && tr.name === thName);
            return Boolean(sgTr) ? sgTr.therapyId : undefined;
        }

        return undefined;
    };

    getVarLines = () => {
        const {nosologyData} = this.state;

        return nosologyData.varLines;
    };

    getLines = () => {
        const {nosologyData} = this.state;

        return nosologyData.lines;
    };

    getThData = (groupId) => {
        const {nosologyData} = this.state;

        if (groupId >= 0 && groupId < nosologyData.therapyGroups.length) {
            return nosologyData.therapyGroups[groupId].thData;
        }

        return undefined;
    };

    setThData = (groupId, thData) => {
        const {nosologyData} = this.state;

        if (groupId >= 0 && groupId < nosologyData.therapyGroups.length) {
            nosologyData.therapyGroups[groupId]['thData'] = thData;
            nosologyData.thouched = true;

            this.forceUpdate();
            this.updateContextPanel();
        }
    };

    getIsSelThId = (groupId, thId, therapyName, therapyWithName): boolean => {
        const {nosologyData} = this.state;

        // console.error('+++ getIsSelThId() +++ groupId:', groupId);
        // console.error('+++ getIsSelThId() +++ thId:', thId);
        // console.error('+++ getIsSelThId() +++ therapyName:', therapyName);
        // console.error('+++ getIsSelThId() +++ therapyWithName:', therapyWithName);
        // console.error('+++ getIsSelThId() +++ nosologyData.therapyGroups:', nosologyData.therapyGroups);
        // console.error('+++ getIsSelThId() +++ nosologyData.therapyGroups[groupId]:', nosologyData.therapyGroups[groupId]);
        if (groupId >= 0 && groupId < nosologyData.therapyGroups.length) {
            // const result = ((nosologyData.therapyGroups[groupId].selThId || {})[therapyName] || {}).some((selThId) => '' + selThId === '' + thId);
            // const result = ('' + (nosologyData.therapyGroups[groupId].selThId || {})[therapyName]) === ('' + thId);
            const result = ('' + (nosologyData.therapyGroups[groupId].selThId || {})[therapyWithName.thGroup]) === ('' + thId);
            // console.error('+++ getIsSelThId() +++ result:', result);
            return result;
        }

        return false;
    };

    getSelThId = (groupId) => {
        const {nosologyData} = this.state;

        if (groupId >= 0 && groupId < nosologyData.therapyGroups.length) {
            return nosologyData.therapyGroups[groupId].selThId;
        }

        return -1;
    };

    setSelThId = (groupId, selThId, therapyWithName, checked) => {
        const {nosologyData} = this.state;
        const {client} = this.props;
        const {signal} = this.abortController;

        // console.error('+++ setSelThId() +++ groupId:', groupId);
        // console.error('+++ setSelThId() +++ selThId:', selThId);
        // console.error('+++ setSelThId() +++ therapyWithName:', therapyWithName);
        // console.error('+++ setSelThId() +++ checked:', checked);
        // console.error('+++ setSelThId() +++ nosologyData.therapyGroups:', nosologyData.therapyGroups);
        // console.error('+++ setSelThId() +++ nosologyData.therapyGroups[groupId]:', nosologyData.therapyGroups[groupId]);

        const thData = this.getThData(groupId);
        // console.error('+++ setSelThId() +++ thData:', thData);

        if (groupId >= 0 && groupId < nosologyData.therapyGroups.length) {
            // nosologyData.therapyGroups[groupId]['selThId'] = selThId;
            // nosologyData.therapyGroups[groupId]['selThId'] = checked ? selThId : undefined;
            if (!nosologyData.therapyGroups[groupId]['selThId']) {
                nosologyData.therapyGroups[groupId]['selThId'] = {};
            }
            // if (!nosologyData.therapyGroups[groupId]['selThId'][therapyWithName.name]) {
            //     nosologyData.therapyGroups[groupId]['selThId'][therapyWithName.name] = [];
            // }

            if (checked) {
                // nosologyData.therapyGroups[groupId]['selThId'].push(selThId);
                // nosologyData.therapyGroups[groupId]['selThId'][therapyWithName.name].push(selThId);
                nosologyData.therapyGroups[groupId]['selThId'][therapyWithName.thGroup] = selThId;
            } else {
                // nosologyData.therapyGroups[groupId]['selThId'][therapyWithName.name] = nosologyData.therapyGroups[groupId]['selThId'][therapyWithName.name].filter((_selThId) => _selThId !== selThId);
                delete nosologyData.therapyGroups[groupId]['selThId'][therapyWithName.thGroup];
            }

            // console.error('+++ setSelThId() +++ 2 nosologyData.therapyGroups:', nosologyData.therapyGroups);
            // console.error('+++ setSelThId() +++ 2 nosologyData.therapyGroups[groupId]:', nosologyData.therapyGroups[groupId]);
            // console.error('+++ setSelThId() +++ 2 nosologyData.therapyGroups[groupId][therapyWithName.thGroup]:', nosologyData.therapyGroups[groupId][therapyWithName.thGroup]);

            // TODO: получить терапию и трейтмент для загрузки данных для графика
            if (selThId >= 0) {
            // if (false && selThId >= 0) { // FIXME: вернуть строчку выше!!!
                const params = nosologyData.therapyGroups
                    .filter((th, index) => th.selThId !== undefined && index === groupId)
                    .map(({selThId, thData, thNames}) => {
                        // console.error('+++ setSelThId() +++ 3 thData', thData);
                        // console.error('+++ setSelThId() +++ 3 thNames', thNames);
                        // console.error('+++ setSelThId() +++ 3 selThId', selThId);
                        // console.error('+++ setSelThId() +++ 3 nosologyData.therapyGroups', nosologyData.therapyGroups);
                        // console.error('+++ setSelThId() +++ 3 nosologyData.therapyGroups[groupId]:', nosologyData.therapyGroups[groupId]);
                        // console.error('+++ setSelThId() +++ 3 nosologyData.therapyGroups[groupId][\'selThId\']:', nosologyData.therapyGroups[groupId]['selThId']);

                        return Object.keys(selThId).map((thGroup) => {
                            // console.error('+++++++++------------------ selThId[therapyName]:', selThId[thGroup]);
                            // console.error('+++++++++------------------ therapyName:', therapyName);
                            // return selThId[therapyName].map((selThIdItem) => {
                                const selThIdItem = selThId[thGroup];
                                const {data: {groups} = {}, name} = thData[selThIdItem];
                                if (!Boolean(name)) {
                                    return (<Box/>);
                                }
                                const therapy = thNames.find(t => t.name === name);
                                // console.error('+++++++++------------------ setSelThId() +++ therapy:', therapy);
                                // console.error('+++++++++------------------ setSelThId() +++ groups:', groups);

                                if (!Boolean(therapy)) {
                                    return {};
                                }

                                // let referent;
                                let treatments;

                                if (therapy.isSimple) {
                                    treatments = Boolean(therapy.treatments) ? Object.keys(therapy.treatments)
                                            .filter(t => therapy.treatments[t])
                                            .map(tr => capitalizeFirstLetter(tr))
                                        // .join(', ')
                                        : undefined;
                                } else {
                                    if (!!groups) {
                                        treatments = getSelectedTreatmentsList(groups).map((groupTreatment) => groupTreatment.treatment.id);
                                    }
                                    // const ref = Boolean(treatments) ? treatments.find(treatment => treatment.isSel) : undefined;
                                    // if (Boolean(ref)) {
                                    //     referent = ref.name;
                                    // }
                                }

                                return (
                                    {
                                        therapy: therapy.name,
                                        treatments: treatments,
                                    }
                                );
                            // });
                        })
                    })
                    .reduce((finalItem, item) => {
                        if (item)
                            finalItem = item;
                        return finalItem;
                    }, {});

                // console.error('+++++++++ setSelThId() +++++++++ 3 params', params);

                // return; // FIXME: убрать
                params.forEach((param) => {
                    loadGetOutcomeRiskForTherapy(client, signal, param.therapy, param.treatments, this.activePatient.patient)
                        .then((outcomeRiskForTherapies) => {
                            // console.error('+++++++++ setSelThId() +++++++++ 3a outcomeRiskForTherapies', outcomeRiskForTherapies);
                            outcomeRiskForTherapies.data = ((outcomeRiskForTherapies || {}).data || []).flatMap((outcomeRiskForTherapy) => {
                                return ((outcomeRiskForTherapy || {}).subgroups || []).map((subgroup) => {
                                    return subgroup;
                                })
                            });
                            // console.error('+++++++++ setSelThId() +++++++++ 3b outcomeRiskForTherapies', outcomeRiskForTherapies);
                            this.setTherapyTreatmentForGroupedBarChart(param.therapy, (true || checked) ? outcomeRiskForTherapies || {} : undefined);

                            this.forceUpdate();
                            this.updateContextPanel();
                        });
                });
            } else {
                this.forceUpdate();
                this.updateContextPanel();
            }
        }
    };

    setTherapyTreatmentForGroupedBarChart = (therapy: string, outcomeRiskForTherapies: OutcomeRiskForTherapyDto) => {
        // this.forceUpdate();
        const prevOutcomeRiskForTherapies = this.state.nosologyData.outcomeRiskForTherapies || {};
        prevOutcomeRiskForTherapies[therapy] = outcomeRiskForTherapies;
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                outcomeRiskForTherapies: prevOutcomeRiskForTherapies,
                // outcomeRiskForTherapies: {
                //     ...prevState.nosologyData.outcomeRiskForTherapies,
                //     [therapy]: outcomeRiskForTherapies,
                // },
                touched: true,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    stepContextPanel = (stepContextView) => {
        if (Boolean(stepContextView)) {
            this.context.setContextContent({
                view: stepContextView,
            });
        } else {
            this.context.setContextContent(null);
        }
    };

    setContextPanelView = (view) => {
        const {setContextContent} = this.context;

        if (setContextContent !== undefined) {
            if (Boolean(view)) {
                setContextContent({view: view});
            } else {
                setContextContent(null);
            }
        }
    };

    getSelectLine = () => {
        const {nosologyData} = this.state;

        if ((nosologyData.selLineId || 1) !== undefined && nosologyData.lines !== undefined) { // протестировать (... || 1)
            return nosologyData.lines[nosologyData.selLineId];
        }

        return undefined;
    };

    setSelectLine = (lineId) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                selLineId: lineId,
                touched: true,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    getTherapyWithName = (therapyName) => {
        const selLine = this.getSelectLine();
        return Boolean(selLine) ? (selLine.sgTreatments || []).find(it => it.name === therapyName) : undefined;
    };

    getTherapyFullname = (therapyName, defaultName) => {
        const therapy = this.getTherapyWithName(therapyName);

        if (!!therapy && !!therapy.fullName) {
            return therapy.fullName;
        }

        return defaultName;
    };

    getTherapyTreatments = (therapyName) => {
        const therapy = this.getTherapyWithName(therapyName);

        if (Boolean(therapy) && Boolean(therapy.treatments)) {
            return therapy.treatments;
        }

        return [];
    };

    getTherapyActiveTexts = (therapyName) => {
        const therapy = this.getTherapyWithName(therapyName);

        if (Boolean(therapy) && Boolean(therapy.activeTexts)) {
            return therapy.activeTexts;
        }

        return [];
    };

    isTherapyActive = (therapyName) => {
        const therapy = this.getTherapyWithName(therapyName);
        return !!therapy && therapy.active !== -1;
    };

    isTherapyRequired = (therapyName) => {
        const therapy = this.getTherapyWithName(therapyName);
        return !!therapy && therapy.required;
    };

    isTherapyDatasetAvailable = (therapyName) => {
        const therapy = this.getTherapyWithName(therapyName);

        if (Boolean(therapy) && therapy.datasetAvailable !== undefined) {
            return therapy.datasetAvailable;
        }

        return true;
    };

    getLineNames = (_lines) => {
        const {intl} = this.props;
        /*
        const selLine = lines[1];

        if (Boolean(selLine)) {
          const therapy = selLine.sgTreatments.find(it => it.name === 'aad');
          if (Boolean(therapy)) {
            return therapy.lines.reduce((acc, cur) => {
              acc[cur.num] = cur.label;
              return acc;
            }, {});
          }
        }
        */
        return {
            1: intl.formatMessage({id: 'label.bl.first_therapy', defaultMessage: 'First therapy'}),
            2: intl.formatMessage({id: 'label.bl.second_therapy', defaultMessage: 'Second therapy'}),
        };
    };

    getLineLabel = (lineId) => {
        const names = this.getLineNames(this.state.nosologyData.lines);
        const label = names[lineId];

        return Boolean(label) ? label : lineId.toString();
    };

    updateContextPanel = () => {
        const {steps, activeStep} = this.state;
        const {patientDataContext} = this.context;
        if ((this.activePatient.patientType === PatientType.PUBLIC && activeStep === 0) || activeStep === (steps.length - 1)) {
            this.setContextPanelView(null);
            return;
        }

        const {classes, intl} = this.props;
        let mainParams = {};

        this.getAllSelectDrugs();

        // build lines
        const {nosologyData} = this.state;
        // console.error('+++ updateContextPanel() +++ nosologyData:', nosologyData);
        // console.error('+++ updateContextPanel() +++ nosologyData.selLineId:', nosologyData.selLineId);
        // console.error('+++ updateContextPanel() +++ nosologyData.lines:', nosologyData.lines);
        // const selLine = nosologyData.selLineId !== undefined ? nosologyData.lines[nosologyData.selLineId] : undefined;
        const selLine = !!nosologyData.selLineId ? nosologyData.lines[nosologyData.selLineId] : nosologyData.lines[1];
        // console.error('+++ updateContextPanel() +++ selLine:', selLine);
        if (selLine !== undefined) {
            // build features
            selLine.features
                .filter(it => Boolean(FeatureNames[it.name]))
                .forEach(it => {
                    mainParams[FeatureNames[it.name]] = it.value;
                    if (it.name === 'chadsVasc') {
                        this.chadsVasc = it.value;
                    }
                });
        } else {
            // this.setContextPanelView(null);
            // return;
        }

        patientDataContext.patientDataContext.baseline
            .forEach((baseline: BaselineParametrSectionDto) => {
                baseline.parameters
                    .filter((parameter: BaselineParameterDto) => parameter.main && parameter.visible)
                    .forEach((parameter: BaselineParameterDto) => {
                        const selectedItem = getSelected(parameter);

                        if (selectedItem) {
                            mainParams[parameter.name] = selectedItem.name;
                        }
                    });
            });

        // console.error('+++ updateContextPanel() +++ nosologyData.therapyGroups:', nosologyData.therapyGroups);

        const thrs = nosologyData.therapyGroups
            // .filter(th => th.selThId !== undefined)
            .map(({selThId, thData}, gId) => {
                if (!!selThId && selThId !== -1) {
                    // let chartDataTherapyTreatment = {};
                    return Object.keys(selThId || {}).map((therapyName, index) => {
                        const selThIdItem = selThId[therapyName];
                        // console.error('+++ selThIdItem:', selThIdItem);
                        // return (selThId || {})[therapyName].map((selThIdItem) => {
                        if (selThIdItem !== undefined && selThIdItem >= 0) {
                            let chartDataTherapyTreatment = {};
                            // const {data: {subgroups, treatments} = {}, name} = thData[selThId];
                            const {data: {subgroups, groups} = {}, name} = thData[selThIdItem];
                            if (!Boolean(name)) {
                                return (<Box/>);
                            }
                            const thNames = this.getThNames(gId);
                            const therapy = thNames.find(t => t.name === name);
                            // console.error('+++ thNames:', thNames);
                            // console.error('+++ therapy:', therapy);
                            const therapyWithName = this.getTherapyWithName(therapy.name);
                            // console.error('+++ therapyWithName:', therapyWithName);

                            if (!Boolean(therapy)) {
                                return (<Box/>);
                            }

                            // const {data: {subgroups, refers} = {}} = thData.find(d => d.name === therapy.name);
                            let referent;

                            if (therapy.isSimple) {
                                referent = Boolean(therapy.treatments)
                                    ? Object.keys(therapy.treatments)
                                        .filter(t => therapy.treatments[t])
                                        .map(tr => capitalizeFirstLetter(tr))
                                        .join(', ')
                                    : undefined;
                            } else {
                                referent = getSelectedTreatmentsList(groups).map((groupTreatment) => groupTreatment.treatment.name).join(', ');
                            }

                            let subgroupsView;
                            // Возможно в будущем снова потребуется отображать этот график
                            /*
                            if (Boolean(subgroups)) {
                                const chartData = initGroupedBarChart2([
                                        {
                                            name: stringDivider(subgroups.efficacyName, 25, '<br>'),
                                            color: GroupedBarChartColors.GREEN,
                                        },
                                        {
                                            name: stringDivider(subgroups.safetyName, 25, '<br>'),
                                            color: GroupedBarChartColors.RED,
                                        },
                                    ]
                                );

                                subgroupsView = (
                                    <Grid container>
                                        {
                                            subgroups.subgroups
                                                .sort((subgroup1, subgroup2) => subgroup1.name.localeCompare(subgroup2.name))
                                                .forEach((subgroup, id) => {
                                                    if (!(subgroup.rate[0] === 0 && subgroup.rate[1] === 0)) {
                                                        // const tickLabel = '<span style="color: red;">' + stringDivider(subgroup.name, 15, '<br>')+ '</span>';
                                                        let color = 'black';
                                                        let fontWeight = 'normal';
                                                        if ((subgroup.selTypes || []).length > 0) {
                                                            if ((subgroup.selTypes || []).length === 2) {
                                                                fontWeight = 'bold';
                                                            } else {
                                                                if ((subgroup.selTypes || []).includes(1)) {
                                                                    color = GroupedBarChartColors.GREEN;
                                                                } else if ((subgroup.selTypes || []).includes(2)) {
                                                                    color = GroupedBarChartColors.RED;
                                                                }
                                                            }
                                                        }
                                                        const tickLabel = `<span style="color: ${color};font-weight: ${fontWeight};">` + stringDivider(subgroup.name, 15, '<br>') + '</span>';
                                                        chartData.data[0].x.unshift(round(subgroup.rate[0]));
                                                        chartData.data[0].y.unshift(tickLabel);
                                                        chartData.data[1].x.unshift(round(subgroup.rate[1]));
                                                        chartData.data[1].y.unshift(tickLabel);

                                                        chartData.data[0].text = chartData.data[0].x.map((x) => toFixed(x));
                                                        chartData.data[1].text = chartData.data[1].x.map((x) => toFixed(x));
                                                    }
                                                })
                                        }

                                        {
                                            (
                                                <Grid item xs={12}>
                                                    <Box>
                                                        <PanelDivider/>
                                                        <GroupedBarChart chartData={chartData}/>
                                                    </Box>
                                                </Grid>
                                            )
                                        }

                                    </Grid>
                                );
                            }
                            */

                            // console.error('+++ updateContextPanel() +++ chartDataTherapyTreatment:', chartDataTherapyTreatment);
                            // console.error('+++ updateContextPanel() +++ nosologyData.outcomeRiskForTherapies:', nosologyData.outcomeRiskForTherapies);
                            if ((nosologyData.outcomeRiskForTherapies || {})[therapy.name]) {
                                // console.error('+++ updateContextPanel() +++ (nosologyData.outcomeRiskForTherapies || {})[therapy.name]:', (nosologyData.outcomeRiskForTherapies || {})[therapy.name]);
                                // console.error('+++ updateContextPanel() +++ (((nosologyData.outcomeRiskForTherapies || {})[therapy.name]) || {}).data:', (((nosologyData.outcomeRiskForTherapies || {})[therapy.name]) || {}).data);
                            }

                            if (!!getSelectedTreatmentsList(groups).length && ((((nosologyData.outcomeRiskForTherapies || {})[therapy.name]) || {}).data || []).length > 0) {
                                chartDataTherapyTreatment[therapy.name] = initGroupedBarChart2([
                                        {
                                            name: ((nosologyData.outcomeRiskForTherapies || {})[therapy.name] || {}).riskThName,
                                            color: GroupedBarChartColors.GREEN,
                                        },
                                        {
                                            name: ((nosologyData.outcomeRiskForTherapies || {})[therapy.name] || {}).riskPlName,
                                            color: GroupedBarChartColors.BLUE,
                                        },
                                    ]
                                );

                                nosologyData.outcomeRiskForTherapies[therapy.name].data
                                    .sort((outcomeRiskForTherapy1, outcomeRiskForTherapy2) => outcomeRiskForTherapy1.name.localeCompare(outcomeRiskForTherapy2.name))
                                    .forEach((outcomeRiskForTherapy) => {
                                        if (!(outcomeRiskForTherapy.riskPl === 0 && outcomeRiskForTherapy.riskTh === 0)) {
                                            const tickLabel = stringDivider(outcomeRiskForTherapy.name, 15, '<br>');
                                            chartDataTherapyTreatment[therapy.name].data[1].x.push(outcomeRiskForTherapy.riskPl);
                                            chartDataTherapyTreatment[therapy.name].data[1].y.push(tickLabel);
                                            chartDataTherapyTreatment[therapy.name].data[0].x.push(outcomeRiskForTherapy.riskTh);
                                            chartDataTherapyTreatment[therapy.name].data[0].y.push(tickLabel);

                                            chartDataTherapyTreatment[therapy.name].data[0].text = chartDataTherapyTreatment[therapy.name].data[0].x.map((x) => toFixed(x));
                                            chartDataTherapyTreatment[therapy.name].data[1].text = chartDataTherapyTreatment[therapy.name].data[1].x.map((x) => toFixed(x));
                                        }
                                    })
                            }

                            return (
                                <Box key={selThIdItem}>
                                    {index > 0 && <PanelDivider/>}

                                    <SelTextView align={"center"}>
                                        {this.getTherapyFullname(therapy.name, therapy.title)}
                                        <br/>
                                        {intl.formatMessage({
                                            id: 'label.bl.risk_factors_prognosis',
                                            defaultMessage: 'Risk factors prognosis'
                                        })}
                                    </SelTextView>

                                    {((Boolean(subgroups) && subgroups.subgroups.length > 0) && Boolean(subgroupsView)) && subgroupsView}

                                    {(!(Boolean(subgroups) && subgroups.subgroups.length > 0) && !Boolean(chartDataTherapyTreatment[therapy.name])) &&
                                        <Box>
                                            <PanelDivider/>
                                            <Typography align={'center'}>
                                                <FormattedMessage id={'message.data.no_data'}
                                                                  defaultMessage={'No data'}/>
                                            </Typography>
                                        </Box>
                                    }
                                    {/*{Boolean(referent) &&*/}
                                    {!!groups &&
                                        <Box>
                                            <PanelDivider/>
                                            {chartDataTherapyTreatment[therapy.name] &&
                                                <GroupedBarChart chartData={chartDataTherapyTreatment[therapy.name]}/>}
                                            {referent &&
                                                <SelTreatView
                                                    treatName={referent}
                                                    prefix={intl.formatMessage({
                                                        id: 'label.bl.select_drug',
                                                        defaultMessage: 'Drug select: '
                                                    })}
                                                />
                                            }
                                        </Box>
                                    }
                                </Box>
                            );
                        } else {
                            return undefined;
                        }
                        // });
                    });
                } else {
                    return undefined;
                }
            });
        // console.error('+++ updateContextPanel() +++ thrs:', thrs);

        this.setContextPanelView(
            <Box>
                {(Object.keys(mainParams).length > 0 || nosologyData.selLineId) &&
                    <Paper className={classes.contentBoxPanel}>
                        {Object.keys(mainParams).length > 0 &&
                            <Box>
                                <SelTextView align={"center"}>
                                    <FormattedMessage id={'label.bl.baseline'} defaultMessage={'Baseline'}/>
                                </SelTextView>
                                <Box className={classes.contentBoxTopSpace}>
                                    {Object.keys(mainParams).map((key, id) => {
                                        const style = {
                                            fontWeight: '500',
                                            color: globalTheme.palette.primary.main,
                                        };
                                        if (key === 'HAS-BLED' && mainParams[key] > 2) {
                                            style.color = 'red';
                                        }
                                        return (
                                            <Typography key={id}>
                                                {/*{`${key}: ${mainParams[key]}`}*/}
                                                {key}: <Typography component={'span'}
                                                                   style={style}>{mainParams[key]}</Typography>
                                                {/*{`${key}: <span style="font-weight: bold">${mainParams[key]}</span>`}*/}
                                            </Typography>
                                        );
                                    })}
                                </Box>
                            </Box>
                        }
                        {nosologyData.selLineId &&
                            <Box>
                                {Object.keys(mainParams).length > 0 &&
                                    <PanelDivider/>
                                }
                                <SelTreatView
                                    treatName={this.getLineLabel(nosologyData.selLineId)}/>
                            </Box>
                        }
                    </Paper>
                }
                <StaticBaseline staticBaseline={patientDataContext.patientDataContext.staticBaseline} hideDivider={!(Object.keys(mainParams).length > 0 || nosologyData.selLineId)} />
                <ExpandableBaselineParameterStrictContextPanel needTopSpace={(Object.keys(mainParams).length > 0 || !!nosologyData.selLineId)} baselineParameterStrict={patientDataContext.patientDataContext.actualBaselineParameterStrict} />

                {thrs.length > 0 &&
                    thrs.filter(thr => thr !== undefined).map((thr, index) => (
                        <Box key={index}>
                            {index > 0 && <PanelDivider/>}
                            <Paper className={classes.contentBoxPanel}>
                                {thr}
                            </Paper>
                        </Box>
                    ))
                }
            </Box>
        );
    };

    getSelectTherapyDrugs = () => {
        const {nosologyData} = this.state;
        const result = {};

        if (Boolean(nosologyData)) {
            nosologyData.therapyGroups
                .filter(th => th.selThId !== undefined && th.selThId >= 0)
                .forEach(({selThId, thData}, gId) => {
                    return Object.keys(selThId).map((thGroup) => {
                        const selThIdItem = selThId[thGroup];
                        const {data: {treatments} = {}, name} = thData[selThIdItem];


                        // const {data: {treatments} = {}, name} = thData[selThId];
                        const thNames = this.getThNames(gId);
                        const therapy = thNames.find(t => t.name === name);
                        const drugs = [];

                        if (therapy && therapy.isSimple && Boolean(therapy.treatments)) {
                            Object.keys(therapy.treatments)
                                .filter(t => therapy.treatments[t])
                                .map(tr => capitalizeFirstLetter(tr))
                                .forEach(treat => drugs.push(treat));
                        } else {
                            const ref = Boolean(treatments) ? treatments.find(treatment => treatment.isSel) : undefined;
                            if (Boolean(ref)) {
                                drugs.push(ref.name);
                            }
                        }

                        result[this.getTherapyIdWithName(name)] = drugs;
                    });
                });
        }

        return result;
    }

    getAllSelectDrugs = () => {
        return Object.values(this.getSelectTherapyDrugs())
            .flatMap(it => it);
    };

    getStepContent = () => {
        const {steps, activeStep} = this.state;

        if (activeStep < steps.length && Boolean(steps[activeStep].contentFunc)) {
            return steps[activeStep].contentFunc();
        } else {
            return (<Box/>);
        }
    };

    getAADView = () => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;

        // console.error('+++ getAADView() +++ nosologyData:', nosologyData);

        return (
            <SelectTherapyAAD
                isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
                lines={nosologyData.lines}
                touched={nosologyData.touched}
                selLineId={nosologyData.selLineId}
                setViewContextPanel={this.stepContextPanel}
                getThNames={this.getThNames}
                getThData={this.getThData}
                setThData={this.setThData}
                getSelThId={this.getSelThId}
                setSelThId={this.setSelThId}
                getIsSelThId={this.getIsSelThId}
                updateContextPanel={this.updateContextPanel}
                getTherapyWithName={this.getTherapyWithName}
                getTherapyFullname={this.getTherapyFullname}
                isTherapyActive={this.isTherapyActive}
                isTherapyRequired={this.isTherapyRequired}
                isTherapyDatasetAvailable={this.isTherapyDatasetAvailable}
                getTherapyActiveTexts={this.getTherapyActiveTexts}
                setTherapyTreatmentForGroupedBarChart={this.setTherapyTreatmentForGroupedBarChart}
                getSelVarIds={this.getSelVarIds}
                // setForestPlotView={this.setForestPlotView}
                activePatient={this.activePatient}
            />
        );
    };

    getNOACView = () => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;

        return (
            <SelectTherapyNOAC
                isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
                lines={nosologyData.lines}
                touched={nosologyData.touched}
                selLineId={nosologyData.selLineId}
                setViewContextPanel={this.stepContextPanel}
                getThNames={this.getThNames}
                getThData={this.getThData}
                setThData={this.setThData}
                getSelThId={this.getSelThId}
                setSelThId={this.setSelThId}
                getIsSelThId={this.getIsSelThId}
                updateContextPanel={this.updateContextPanel}
                getTherapyWithName={this.getTherapyWithName}
                getTherapyFullname={this.getTherapyFullname}
                isTherapyActive={this.isTherapyActive}
                isTherapyRequired={this.isTherapyRequired}
                isTherapyDatasetAvailable={this.isTherapyDatasetAvailable}
                getTherapyActiveTexts={this.getTherapyActiveTexts}
                setTherapyTreatmentForGroupedBarChart={this.setTherapyTreatmentForGroupedBarChart}
                getSelVarIds={this.getSelVarIds}
                // setForestPlotView={this.setForestPlotView}
                activePatient={this.activePatient}
            />
        );
    };

    getThRecommend = (): TherapyRecommendIn[] => {
        const selectTherapyDrugs = this.getSelectTherapyDrugs();
        const thRecommend: TherapyRecommendIn[] = [];

        Object.keys(selectTherapyDrugs)
            .forEach(therapyDrugKey => thRecommend.push({
                therapyId: therapyDrugKey,
                selectDrugs: selectTherapyDrugs[therapyDrugKey],
            }));

        return thRecommend;
    };

    loadTherapyCompare = (signal): Promise<TherapyCompareDto[]> => {
        const {client} = this.props;
        const therapyPresumably = this.getTherapyPresumably();
        const patientId = Number(this.activePatient.patient) || 0;

        if (therapyPresumably && therapyPresumably.thData) {
            return loadGetTherapyCompare(client, signal, NosologyType.ATRIAL_FIBRILLATION, patientId, therapyPresumably.thData, this.getThRecommend())
                .then((data) => {
                    return new Promise((resolve, reject) => {
                        resolve(data);
                    });
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve([]);
            });
        }
    };

    loadTherapyCompareStatistics = (signal): Promise<TherapyCompareStatisticsDto[]> => {
        const {client} = this.props;
        const {contextStaff} = this.context;

        if (contextStaff) {
            const statDataInInput: StatDataInInputDto = {
                excludePatientIds: [],
                excludeStaffIds: [],
                includePatientIds: [Number(this.activePatient.patient)],
                includeStaffIds: [Number(contextStaff.id)],
            }

            return loadGetTherapyCompareStatistics(client, signal, NosologyType.ATRIAL_FIBRILLATION, statDataInInput)
                .then((data) => {
                    return new Promise((resolve, reject) => {
                        resolve(data);
                    });
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve([]);
            });
        }
    };

    getTouched = () => {
        return this.state.nosologyData.touched;
    };

    setTouched = (touched) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                touched: touched,
            },
        }), () => {
        });
    };

    getTherapyCompare = () => {
        const {nosologyData} = this.state;
        return nosologyData.therapyCompare;
    };

    setTherapyCompare = (therapyCompare, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyCompare: therapyCompare,
                touched: true,
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    getTherapyPresumably = () => {
        const {nosologyData} = this.state;
        return nosologyData.therapyPresumably;
    };

    setTherapyPresumably = (therapyPresumably, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyPresumably: therapyPresumably,
                touched: true,
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    isTherapyPresumably = () => {
        const {nosologyData} = this.state;
        return isTherapyPresumably((nosologyData.therapyPresumably || {}).thData);
    };

    backSelectTherapyPresumably = () => {
        const {nosologyData, activeStep} = this.state;

        delete nosologyData.therapyPresumably;

        if (activeStep > 0) {
            this.setState(prevState => ({
                activeStep: activeStep - 1,
            }), () => {
                this.updateContextPanel();
            });
        }
    };

    getSelectTherapyPresumablyView = () => {
        const {contextStaff} = this.context;
        const {nosologyData} = this.state;

        return (<SelectTherapyPresumablyView
            nosologyData={nosologyData}
            nosology={NosologyType.ATRIAL_FIBRILLATION}
            setTherapyPresumably={this.setTherapyPresumably}
            getTherapyPresumably={this.getTherapyPresumably}
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
        />);
    };

    getTherapySelectLineView = () => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;

        return (
            <SelectTherapyLine
                isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
                varLines={nosologyData.varLines}
                // varLines={this.getVarLines}
                lines={nosologyData.lines}
                // lines={this.getLines}
                selLineId={nosologyData.selLineId}
                setSelectLine={this.setSelectLine}
                groupId={0}
                getThNames={this.getThNames}
                getThData={this.getThData}
                setThData={this.setThData}
                getSelThId={this.getSelThId}
                setSelThId={this.setSelThId}
                updateContextPanel={this.updateContextPanel}
                getTherapyWithName={this.getTherapyWithName}
                getTherapyFullname={this.getTherapyFullname}
                isTherapyActive={this.isTherapyActive}
                activePatient={this.activePatient}
            />
        );
    };

    getTherapyResultView = () => {
        const {nosologyData} = this.state;

        return (
            <TherapyResultView
                nosologyData={nosologyData}
                lines={nosologyData.lines}
                selLineId={nosologyData.selLineId}
                fields={nosologyData.fields}
                getThNames={this.getThNames}
                getThData={this.getThData}
                setThData={this.setThData}
                getSelThId={this.getSelThId}
                setSelThId={this.setSelThId}
                getSelVarIds={this.getSelVarIds}
                getTherapyWithName={this.getTherapyWithName}
                getTherapyFullname={this.getTherapyFullname}
                getLineLabel={this.getLineLabel}
                isTherapyActive={this.isTherapyActive}
                getTherapyActiveTexts={this.getTherapyActiveTexts}
                getTherapyPresumably={this.getTherapyPresumably}
                loadTherapyCompare={this.loadTherapyCompare}
                loadTherapyCompareStatistics={this.loadTherapyCompareStatistics}
                getTherapyCompare={this.getTherapyCompare}
                setTherapyCompare={this.setTherapyCompare}
                getTouched={this.getTouched}
                getThRecommend={this.getThRecommend}
                setDisableResultNext={this.setDisableResultNext}
                showComparisons={isShowComparisons(this.activePatient.patientType)}
                autoSave={this.autoSave}
                activePatient={this.activePatient}
            />
        );
    };

    prepareTherapyLines = (lines, thNames) => {
        const lineNames = this.getLineNames(lines);

        return Object.keys(lines).reduce((acc, lineId) => {
            const {sgTreatments} = lines[lineId];
            const thActive = sgTreatments
                .reduce((acc, cur) => {
                        acc[cur.name] = {
                            'active': cur.active !== -1,
                            'treatments': cur.treatments,
                        };
                        return acc;
                    },
                    {});
            const thAvailable = thNames
                .filter(th => Boolean(thActive[th.name]) && thActive[th.name].active)
                .map(th => {
                    let title;
                    const isTreatments = Boolean(thActive[th.name].treatments) && thActive[th.name].treatments.length > 0;
                    let thTitle = th.title;
                    const therapy = sgTreatments.find(it => it.name === th.name);
                    if (Boolean(therapy)) {
                        thTitle = therapy.fullName;
                    }

                    if (isTreatments) {
                        title = `${thTitle} (${thActive[th.name].treatments
                            .map(tr => capitalizeFirstLetter(tr))
                            .join(', ')})`;
                    } else {
                        title = thTitle;
                    }

                    return {
                        'name': th.name,
                        'title': title,
                        'isSimple': th.isSimple,
                        'treatments': isTreatments
                            ? thActive[th.name].treatments
                                .reduce((acc, cur) => {
                                    acc[cur] = false;
                                    return acc;
                                }, {})
                            : [],
                    };
                });

            acc[lineId] = {
                'title': lineNames[lineId],
                'therapies': thAvailable,
            };

            return acc;
        }, {});
    };

    getCompStat = () => {
        return Math.round(Math.random() * 100000);
    };

    onStep0Proceed = (nosologyData, patientId, selectedValuesNosology, action, showNextPage: boolean = true) => {
        const {client} = this.props;
        const {steps} = this.state;
        const {signal} = this.abortController;

        // console.error('+++ onStep0Proceed() +++ 1 this.activePatient:', this.activePatient);
        if (!this.activePatient.history) {
            for (let line of [1, 2]) {
                if (nosologyData && !nosologyData.touched) {
                    this.onBaselineReady(line, nosologyData.lines[line], selectedValuesNosology, action, showNextPage);
                } else {
                    client.query({
                        query: calcFLBaseline,
                        variables: {
                            patientId: patientId,
                            line: line,
                        },
                        context: {
                            uri: config.options.server.fl_url + config.options.server.api_uri,
                            fetchOptions: {
                                signal,
                            },
                        },
                    })
                        // .then(result => (result.data || {}).calcFLBaseline)
                        // .then(result => JSON.parse(JSON.stringify(result)))
                        .then(result => getQueryResult(result?.data, 'calcFLBaseline'))
                        .then(calcFLBaseline => {
/* FIXME: удалить, нужно было для проверки *
                            calcFLBaseline.sgTreatments.map((sgTreatment) => {
                                if (sgTreatment.fullName === 'Оральные антикоагулянты') {
                                    sgTreatment.active = -1;
                                }
                                return sgTreatment;
                            });
/**/
                            this.onBaselineReady(line, calcFLBaseline, selectedValuesNosology, action, showNextPage);
                        });
                }
            }
        } else {
            // console.error('+++ onStep0Proceed() +++ 2 this.activePatient:', this.activePatient);
            // console.error('+++ onStep0Proceed() +++ 2 nosologyData:', nosologyData);
            this.setState(prevState => ({
                activeStep: !nosologyData?.touched && action === 'RESULT' ? steps.length - 1 : (showNextPage ? 1 : 0),
                allDataLoaded: true,
            }), () => {
                // console.error('+++ onStep0Proceed() +++ activeStep:', this.state.activeStep);
                this.updateContextPanel();
            });
        }
    }

/* unused method
    onStep0 = (action, showNextPage: boolean = true) => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;

            const access = getAccess((contextStaff || {}).role, this.activePatient.patientType);
            if (onlySaveBaseline(this.activePatient, access)) {
                const {client} = this.props;
                const {signal} = this.abortController;
                callSavePatient(client, signal, this.getSelectVars(), this.activePatient.patient, this.activePatient.patientName, this.activePatient.patientType)
                    .then(saveResponse => {
                        this.finalStepRedirect(this.activePatient);
                    });
            } else {
                // request calculate baseline
                this.onStep0Proceed(nosologyData, this.activePatient.patient, this.getSelectVars(), action, showNextPage);
            }
        // } else {
        //     this.showError(intl.formatMessage({
        //         id: 'err.fields.fill.is_required',
        //         defaultMessage: 'Fill all fields is required'
        //     }));
        //     this.setState(prevState => ({
        //         nosologyData: {
        //             ...prevState.nosologyData,
        //             fields: nosologyData.fields,
        //         },
        //         allDataLoaded: true,
        //     }));
        // }
    };
*/

    onBaselineReady = (line, calcResult, selFields, action, showNextPage: boolean = true) => {
        const {nosologyData} = this.state;
        const {steps} = this.state;
        const lines = JSON.parse(JSON.stringify(nosologyData.lines));
        lines[line.toString()] = calcResult;
        nosologyData.lines = lines;

        // console.error('+++ onBaselineReady() +++ nosologyData:', nosologyData);
        if (Object.keys(nosologyData.lines).length === 2) {
            const varLines = (nosologyData && !nosologyData.touched) ? nosologyData.varLines : this.prepareTherapyLines(lines, ThNames1);

            this.setState(prevState => ({
                nosologyData: {
                    ...prevState.nosologyData,
                    selFields: selFields,
                    lines: lines,
                    varLines: varLines,
                },
                activeStep: !nosologyData.touched && action === 'RESULT' ? steps.length - 1 : (showNextPage ? 1 : 0),
                allDataLoaded: true,
            }), () => {
                this.updateContextPanel();
            });
        }
    };

    onNextResultStep = () => {
        this.onStepFinal();
    };

    onStepFinal = () => {
        const {contextStaff} = this.context;

        if (this.activePatient.patient && !isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)) {
            const {nosologyData} = this.state;

            if (nosologyData.touched) {
                this.autoSave()
                    .then(saveResponse => {
                        this.finalStepRedirect(this.activePatient);
                    });
            } else {
                this.finalStepRedirect(this.activePatient);
            }
        } else {
            this.finalStepRedirect(this.activePatient);
        }
    };

    autoSave = (): Promise<any> => {
        const {nosologyData} = this.state;
        const {patientDataContext} = this.context;
        if (this.activePatient.action !== 'RESULT' || nosologyData.touched) {
            const {client} = this.props;
            const {signal} = this.abortController;
            const prescriptions: [] = [];
            prescriptions.push({
                type: PrescriptionType.DRUGS,
                names: this.getAllSelectDrugs(),
            });
            prescriptions.push({
                type: PrescriptionType.DEVICES,
                ids: [],
            });
            nosologyData.prescriptions = prescriptions;
            const parameters: ParametersForBaselineDto[] = getParametersForBaselineDto(patientDataContext.patientDataContext.parameters);

            return savePatientNosology(client, signal, nosologyData, parameters, this.activePatient.patient, this.activePatient.nosology)
                .then((saveResponse) => {
                    this.setDisableResultNext(false);
                    return saveResponse;
                    // return new Promise((resolve, reject) => {
                    //     resolve(saveResponse);
                    // });
                });
        } else {
            return new Promise((resolve, reject) => {
                this.setDisableResultNext(false);
                resolve();
            });
        }
    };

    setDisableResultNext = (disableResultNext: boolean = false) => {
        this.setState({disableResultNext: disableResultNext});
    }

    finalStepRedirect = (activePatient, needGoNextNosology = true) => {
        this.setContextPanelView(null);
        if (this.activePatient.patient) {
            const {signal} = this.abortController;
            const {client, history} = this.props;
            const {setContextPatient, setPatientDataContext} = this.context;
            finalStepNosologyRedirect(signal, client, setContextPatient, history, this.activePatient, setPatientDataContext, needGoNextNosology);
        } else {
            // const {steps} = this.state;
            // let newSteps = [];
            // newSteps.push(steps[0]);
            this.setState({
                nosologyData: {
                    touched: true,
                    selLineId: undefined,
                    therapyGroups: this.initTherapyGroups(),
                    compStat: this.getCompStat(),
                    lines: {},
                    varLines: {},
                    fields: undefined,
                },
                // steps: newSteps,
                activeStep: 0,
            }, () => {
                window.scrollTo(0, 0);
                // TODO: раньше загружали, а сейчас мы сюда вовсе не попадаем
                // this.loadBaseline();
            });
        }
    }

    isLineSelect = () => {
        const {nosologyData} = this.state;
        return (
            (
                nosologyData.selLineId !== undefined
                &&
                (nosologyData.selLineId > 0 || nosologyData.selLineId === -1)
            )
            ||
            !(
                nosologyData.varLines
                &&
                nosologyData.varLines.length
                &&
                !!Object.keys(nosologyData.varLines)
                    .map(lineId => Number(lineId))
                    .filter(lineId => {
                        const line = nosologyData.varLines[lineId];
                        return line.therapies !== undefined && line.therapies.length > 0;
                    }).length
            )
        );
    };

    isTherapySelect = (groupId) => {
        const thNames = this.getThNames(groupId).map(th => th.name);
        const noActive = thNames.every((thName) => !this.isTherapyActive(thName));
        // console.error('+++===+++===+++ isTherapySelect() +++===+++===+++ thNames:', thNames);

        // Если нет ни одной доступной терапии, то разрешаем переход на следующий шаг
        if (thNames.length === 0) {
            return true;
        }
        if (noActive) {
            return true;
        }

        const { sgTreatments } = this.getSelectLine() || [];
        const therapies = sgTreatments
            .filter(tr => thNames.includes(tr.name));

        const selThId = this.getSelThId(groupId);
        // console.error('+++===+++===+++ isTherapySelect() +++===+++===+++ selThId:', selThId);
        const isSelThId = Boolean(selThId);
        const reqCount = therapies
            .filter(tr => tr.required)
            .length;

        // Есть ли среди доступных терапий обязательные для выбора? Если нет, то разрешаем переход на следующий шаг.
        if (reqCount === 0 && !isSelThId) {
            return true;
        }

        // Если нет ни одной выбранной терапии, запрещаем переход на следующий шаг.
        if (selThId === undefined || selThId === null) {
            return false;
        }

        if (selThId === -1) {
            return true;
        }

        const thData = this.getThData(groupId);

        return Object.keys(selThId).every((thGroup) => {
            const selectedThData = thData[selThId[thGroup]];

            if (!!selectedThData) {
                const groupTreatments = ((selectedThData.data || {}).groups || []).flatMap(group => (group.treatments || []));
                // Если в терапии отсутсвуют третменты или выбран хотя бы один из доступных
                return groupTreatments.length === 0 || groupTreatments.some((tr) => tr.isSel);
            } else {
                return false;
            }
        });

        // const selectedThData = thData[selThId];
        //
        // if (selectedThData) {
        //     const groupTreatments = ((selectedThData.data || {}).groups || []).flatMap(group => (group.treatments || []));
        //     // Если в терапии отсутсвуют третменты или выбран хотя бы один из доступных
        //     return groupTreatments.length === 0 || groupTreatments.some((tr) => tr.isSel);
        // }

        return false;
    }

    isAADSelect = () => {
        return this.isTherapySelect(0);
    };

    isNOACSelect = () => {
        return (
            this.isTherapySelect(1)
            // &&
            // (
            //     this.chadsVasc === null
            //     ||
            //     this.chadsVasc !== null && this.chadsVasc <= 1
            // )
        );
    };

    onNext = () => {
        const {activeStep, steps} = this.state;

        // this.setForestPlotView();

        if (Boolean(steps[activeStep].nextFunc)) {
            steps[activeStep].nextFunc();
        } else if (activeStep < steps.length - 1) {
            this.setState({
                activeStep: activeStep + 1,
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    isNextAvailable = () => {
        const {steps, activeStep} = this.state;

        if (Boolean(steps[activeStep].isNextFunc)) {
            return steps[activeStep].isNextFunc();
        }

        if (activeStep === (steps.length - 1)) {
            return false;
        }

        return true;
    };

    getNextLabel = () => {
        const {steps, activeStep, nosologyData} = this.state;
        const {intl} = this.props;
        const {contextStaff} = this.context;

        return getNextLabel(steps, activeStep, intl, contextStaff, this.activePatient, nosologyData);
    };

    onRestart = () => {
        this.setState({
            allDataLoaded: false,
            activeStep: 0,
            baseline: [],

            nosologyData: {
                touched: true,
                lines: {},
                varLines: {},
                therapyGroups: this.initTherapyGroups(),
                compStat: this.getCompStat(),
            },
        }, () => {
            const {setNosologyDataContext} = this.context;
            const {nosologyData} = this.state;
            setNosologyDataContext({
                    nosologyData: nosologyData
                },
                () => {
                    const {history} = this.props;
                    const queryParams = new URLSearchParams(history.location.search);
                    if (queryParams.get('action')) {
                        queryParams.delete('action');
                        history.replace({
                            search: queryParams.toString()
                        });
                    } else {
                        this.componentDidMount();
                    }
                });
        });
    };

    onPrev = () => {
        const {activeStep, steps} = this.state;

        // this.setForestPlotView();

        if (Boolean(steps[activeStep].prevFunc)) {
            steps[activeStep].prevFunc();
        } else if (activeStep > 0) {
            this.setState({
                activeStep: activeStep - 1,
                baselineTreat: undefined,
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    onSelectLinePrev = () => {
        const {activeStep} = this.state;

        if (activeStep > 0) {
            this.setState(prevState => ({
                activeStep: activeStep - 1,
                nosologyData: {
                    ...prevState.nosologyData,
                    selLineId: prevState.nosologyData.touched ? undefined : prevState.nosologyData.selLineId,
                    therapyGroups: prevState.nosologyData.touched ? this.initTherapyGroups() : prevState.nosologyData.therapyGroups,
                },
            }), () => {
                this.updateContextPanel();
            });
        }
    };

    onTherapyTreatsPrev = (gId) => () => {
        const {activeStep, nosologyData} = this.state;

        if (activeStep > 0) {
            // remove data for therapy groups
            if (nosologyData.thouched) {
                this.cleanTherapyGroup(gId);
            }

            this.setState({
                activeStep: activeStep - 1,
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    showError = (message) => {
        const {showNotify} = this.context;
        if (showNotify) {
            showNotify(message, 'error');
        }
    };

    setBaselineDialogOpen = (open: boolean) => {
        this.setState({
            baselineDialog: {
                open: open,
            },
        });
    }

    getSelectVars = () => {
        const {
            nosologyData,
        } = this.state;

        return nosologyData.selectedValuesNosology;
    };

    isFieldShow = (field) => {
        const selVars = this.getSelectVars();

        if (Boolean(field.opt) && Boolean(field.opt.isShow) && Boolean(field.opt.isShow.selVars)) {
            const intr = selVars.filter(v => field.opt.isShow.selVars.includes(v));
            return intr.length === field.opt.isShow.selVars.length;
        }

        return true;
    };

    // setForestPlotView = (forestPlotView, callback) => {
    //     this.setState(prevState => ({
    //         forestPlotView: forestPlotView,
    //     }), () => {
    //         if (Boolean(callback)) {
    //             callback();
    //         }
    //
    //         this.forceUpdate();
    //     });
    // };

    // forestPlotPanel = () => {
    //     const {classes} = this.props;
    //     const {forestPlotView, activeStep, steps} = this.state;
    //     if (forestPlotView && (activeStep + 1 < steps.length)) {
    //         return (
    //             <Box className={classes.contentBoxTopSpace}>
    //                 {forestPlotView}
    //             </Box>
    //         );
    //     } else {
    //         return (<Box/>);
    //     }
    // };

    render() {
        const {classes, intl} = this.props;
        const {allDataLoaded, activeStep, steps, baseline, nosologyData, baselineDialog, disableResultNext} = this.state;
        const {
            patientDataContext,
            contextContent,
            contextStaff,
        } = this.context;
        const step = steps[activeStep];

        if (!allDataLoaded) {
            return <Box />;
        }

        return (
            <Box>

                <Paper className={classes.contentBox}>
                    {Boolean(this.activePatient.patientName) &&
                    <React.Fragment>
                        <Button color={"primary"} variant={"outlined"} className={classes.returnPatientButton}
                                // onClick={() => returnToPatient(this.activePatient, this.props.history)}>
                                onClick={() => this.finalStepRedirect(this.activePatient, false)}>
                            <KeyboardBackspaceRoundedIcon className={this.props.classes.iconButton}/>
                            {this.activePatient.patientName}
                        </Button>
                        <Button color={"primary"} variant={"outlined"} className={classes.returnPatientButton}
                                onClick={() => this.setBaselineDialogOpen(true)}>
                            {intl.formatMessage({
                                id: 'label.patient.baseline',
                                defaultMessage: 'Baseline data'
                            })}
                        </Button>
                        {contextContent &&
                        <Button color={"primary"} variant={"outlined"} className={`${classes.returnPatientButton} ${classes.contextBarButtonHide}`}
                                onClick={() => this.context.setContextContentShow(true)}>
                            {intl.formatMessage({
                                id: 'label.nosology.context_panel',
                                defaultMessage: 'Информационная панель'
                            })}
                        </Button>
                        }
                    </React.Fragment>
                    }
                    <Typography variant='h6'>
                        {`${intl.formatMessage({
                            id: 'label.step',
                            defaultMessage: 'Step'
                        })} ${activeStep + 1}/${steps.length}: ${step.title}`}
                    </Typography>
                    {Boolean(step.descr) && step.descr}
                </Paper>

                <PanelDivider/>

                {this.getStepContent()}

                {/*{this.forestPlotPanel()}*/}

                <PanelDivider/>

                <Paper>
                    <Grid container className={classes.contentBox}>
                        <Grid item xs={12}>
                            <Grid container spacing={3} justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        onClick={this.onPrev}
                                        disabled={isBackDisable(activeStep, steps, disableResultNext)}>
                                        <FormattedMessage id={'label.back'} defaultMessage={'Back'}/>
                                    </Button>
                                </Grid>
                                {activeStep === (steps.length - 1) && this.activePatient.patient &&
                                <Grid item>
                                    <SaveOrRestartButton
                                        onSave={this.onNext}
                                        saveLabel={this.getNextLabel()}
                                        onRestart={this.onRestart}
                                        disableResultNext={isDisableResultNext(this.activePatient.action, nosologyData.touched, disableResultNext)}
                                        intl={intl}
                                    />
                                </Grid>
                                }
                                {(activeStep !== (steps.length - 1) || !this.activePatient.patient) &&
                                <Grid item>
                                    <Button
                                        onClick={this.onNext}
                                        disabled={!this.isNextAvailable()}
                                        color='primary'
                                        variant='contained'>
                                        {this.getNextLabel()}
                                    </Button>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                {
                    baselineDialog.open &&
                    <BaselineDialog
                        open={baselineDialog.open}
                        setOpen={this.setBaselineDialogOpen}
                        classes={classes}
                        intl={intl}
                        patientData={patientDataContext.patientDataContext}
                        contextStaff={contextStaff}
                        baseline={baseline}
                        nosologyData={nosologyData}
                        isFieldShow={this.isFieldShow}
                        patientDrugs={patientDrugs}
                    />
                }

            </Box>
        );
    }
}

Baseline.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

Baseline.contextTypes = {
    setContextContent: PropTypes.func,
    showNotify: PropTypes.func,
    nosologyDataContext: PropTypes.object,
    patientDataContext: PropTypes.object,
    setPatientDataContext: PropTypes.func,
    setNosologyDataContext: PropTypes.func,
    setContextPatient: PropTypes.func,
    contextStaff: PropTypes.object,
    contextContent: PropTypes.object,
    setContextContentShow: PropTypes.func,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl
)(Baseline);
