import React from "react";
import mainStyles from "../../styles/mainStyles";
import compose from 'recompose/compose';
import {withStyles} from "@material-ui/core/styles";
import { withApollo } from '@apollo/client/react/hoc';
import {injectIntl} from "react-intl";
import HeartFailureSelectTherapy from "../heartFailure/HeartFailureSelectTherapy";
import {
  Box,
  Typography,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import ExpandTextPanel from "../baseline/ExpandTextPanel";
import PropTypes from "prop-types";
import BreastCancerSelectSubgroups from "./BreastCancerSelectSubgroups";
import BreastCancerSelectDrugs from "./BreastCancerSelectDrugs";
import BreastCancerResult from "./BreastCancerResult";
import {RecommendType} from "../../const";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

const styles = theme => ({
  ...mainStyles(theme),
});

function createBreastCancerComp(WrapComp) {
  return class extends React.Component {

    static propTypes = {
      nosologyData: PropTypes.object.isRequired,
      getRecomendTexts: PropTypes.func, // .isRequired,
      setRecomendTexts: PropTypes.func, // .isRequired,
      getSelectVars: PropTypes.func, // .isRequired,
      getSelectFields: PropTypes.func, // .isRequired,
      getSelectTherapyType: PropTypes.func, // .isRequired,
    };

    abortController = new AbortController();

    constructor(props) {
      super(props);
      this.state = { };
    }

    componentDidMount() {
      window.scrollTo(0, 0);
      this.loadRecommendTexts();
    }

    componentWillUnmount() {
      this.abortController.abort();
    }

    loadRecommendTexts = () => {
      const { getRecomendTexts } = this.props;

      this.setState({
        recommendTexts: getRecomendTexts ? getRecomendTexts() : undefined,
      })
    };

    getRecomentTextsView = (type: RecommendType) => {
      const { intl } = this.props;
      const { recommendTexts } = this.state;
      let texts = [];

      if (Boolean(recommendTexts)) {
        recommendTexts
            .filter((txt) => txt.type === type)
            .forEach(txt => {
          if (Boolean(txt.label)) {
            texts.push(<Typography color={"primary"}>
              {txt.label}
            </Typography>);
          }
          texts.push(
            <Typography component={"span"}>
              <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={txt.text} />
            </Typography>
          );
        });
      }

      if (texts.length > 0) {
        return (<ExpandTextPanel
          textSummary={intl.formatMessage({
            id: 'label.hp.clinical_recommendations',
            defaultMessage: 'Clinical recommendations(AHA/ACC и ESC Guidelines)'
          })}
          textSecondary={intl.formatMessage({
            id: 'label.hp.origin',
            defaultMessage: 'Origin: 2018 ESC/ESH Clinical Practice Guidelines for the Management of Arterial Hypertension; 2017 "ACC/AHA/AAPA/ABC/ACPM/AGS/APhA/ASH/ASPC/NMA/PCNA Guideline for the Prevention, Detection, Evaluation and Management of High Blood Pressure in Adults"'
          })}>
          {texts.map((txt, id) => (
            <Box key={id}>
              {txt}
            </Box>
          ))}
        </ExpandTextPanel>);
      } else {
        return (<Box />);
      }
    };

    render() {
      return (<WrapComp
        {...this.props}
        getRecomentTextsView={this.getRecomentTextsView}
      />)
    }

  }
}

export const BreastCancerResultView = compose(
  withStyles(styles),
  withApollo,
  injectIntl,
)(createBreastCancerComp(BreastCancerResult));

export const BreastCancerSelectSubgroupsView = compose(
  withStyles(styles),
  withApollo,
  injectIntl,
)(createBreastCancerComp(BreastCancerSelectSubgroups));

export const BreastCancerSelectDrugsView = compose(
  withStyles(styles),
  withApollo,
  injectIntl,
)(createBreastCancerComp(BreastCancerSelectDrugs));

export const BreastCancerSelectTherapyView = compose(
  withStyles(styles),
  withApollo,
  injectIntl,
)(createBreastCancerComp(HeartFailureSelectTherapy));

