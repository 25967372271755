import PropTypes from 'prop-types';
import React from 'react';
import {
    Box,
    Typography,
} from '@material-ui/core';
import mainStyles from '../styles/mainStyles';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {getBaselineParameterStrictView} from '../utils';
import {FormattedMessage} from "react-intl";

const styles = theme => ({
    ...mainStyles(theme),
});

const BaselineParameterStrict = (props) => {
    const {classes, baselineParameterStrict} = props;
    const baselineParameterStrictView = getBaselineParameterStrictView(baselineParameterStrict, classes);

    return (
        baselineParameterStrictView && baselineParameterStrictView.length ?
        <Box>
            {/*{!hideDivider && <PanelDivider/>}*/}
            {/*<Paper className={classes.contentBoxPanel}>*/}
                {baselineParameterStrictView}
            {/*</Paper>*/}
        </Box>
        :
        <Box>
            <Typography align={'center'}>
                <FormattedMessage id={'label.patient.no_data'} defaultMessage={'No data'}/>
            </Typography>
        </Box>
    );
}

BaselineParameterStrict.propTypes = {
    baselineParameterStrict: PropTypes.array,
    hideDivider: PropTypes.bool,
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
)(BaselineParameterStrict);
