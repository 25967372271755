import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import ReactMarkdown from "react-markdown";
import {FormattedMessage} from "react-intl";
import Popper from "@material-ui/core/Popper";
import grey from "@material-ui/core/colors/grey";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {MessageType} from "../../const";
import red from "@material-ui/core/colors/red";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import {Card, CardActions} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    root: {
        maxWidth: '480px',
        minWidth: '420px',
        // backgroundColor: '#f6fcff',
    },
    content: {
        overflow: 'auto',
        maxHeight: '240px',
        paddingBottom: 0,
    },
    markdown: {
        '& *:first-child': {
            marginTop: 0,
        }
    },
    actions: {
        padding: theme.spacing(1),
    },
    typography: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        maxWidth: '480px',
        minWidth: '420px',
        maxHeight: '280px',
        overflow: 'auto',
        boxShadow: '3px 3px 5px rgb(0 0 0 / 0.5)',
        // backgroundColor: '#f6fcff',
    },
    buttons: {
        marginTop: '10px'
    },
    buttonCancel: {
        color: grey[700]
    },
    iconINFO: {
        color: theme.palette.primary.main
    },
    iconWARNING: {
        color: red[500]
    },
}));

export default function ServiceConfirmPopper(props) {
    const classes = useStyles();
    const {
        open,
        anchorEl,
        title,
        text,
        type,
        handleConfirm,
    } = props;

    const getIconClassName = (type: MessageType): Object => {
        switch (type) {
            case MessageType.INFO:
                return classes.iconINFO;
            case MessageType.WARNING:
                return classes.iconWARNING;
            default:
                return classes.iconINFO
        }
    }

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement={'bottom-start'}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'window',
                },
                arrow: {
                    enabled: false,
                    //     element: arrowRef,
                },
            }}
        >
            <Card elevation={6} className={classes.root}>
                {/*<CardHeader*/}
                {/*    avatar={*/}
                {/*        <Avatar aria-label="recipe"*/}
                {/*                // className={classes.avatar}*/}
                {/*        >*/}
                {/*            <InfoOutlinedIcon fontSize="large"*/}
                {/*                              // style={{margin: '0 16px 0 0'}}*/}
                {/*                              className={getIconClassName(type)} />*/}
                {/*        </Avatar>*/}
                {/*    }*/}
                {/*    title={title}*/}
                {/*    // subheader="September 14, 2016"*/}
                {/*/>*/}
                <CardContent className={classes.content}>
                    <Grid container direction={'row'}>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <InfoOutlinedIcon fontSize="large"
                                // style={{margin: '0 16px 0 0'}}
                                              className={getIconClassName(type)} />
                        </Grid>

                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                            <Grid container direction={'column'}>
                                {!!title &&
                                <Grid item>
                                    <Typography variant={'h6'}>
                                        {title}
                                    </Typography>
                                </Grid>
                                }
                                {!!text &&
                                    <Grid item className={classes.markdown}>
                                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} children={text}/>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} children={text}/>*/}
                </CardContent>
                <CardActions className={classes.cardActions + ' ' + classes.actions}
                             // disableSpacing
                >
                    <Button color={"primary"} className={classes.buttonCancel} onClick={handleConfirm(false)}>
                        <FormattedMessage id='label.cancel' defaultMessage='Cancel' />
                    </Button>
                    <Button color={"primary"} onClick={handleConfirm(true)}>
                        <FormattedMessage id='label.select' defaultMessage='Select' />
                    </Button>
                </CardActions>
            </Card>

{/*
            <Paper className={classes.typography}>
                <Grid container direction={'row'}>
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <InfoOutlinedIcon fontSize="large"
                                          // style={{margin: '0 16px 0 0'}}
                                          className={getIconClassName(type)} />
                    </Grid>

                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                        <Grid container direction={'column'}>
                            {title &&
                            <Grid item>
                                <Typography variant={'h6'}>
                                    {title}
                                </Typography>
                            </Grid>
                            }
                            <Grid item>
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} children={text}/>
                            </Grid>
                            <Grid item className={classes.buttons}>
                                <Grid container direction={'row'} spacing={2} justifyContent={"flex-end"}>
                                    <Grid item>
                                        <Button color={"primary"} className={classes.buttonCancel} onClick={handleConfirm(false)}>
                                            <FormattedMessage id='label.cancel' defaultMessage='Cancel' />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color={"primary"} onClick={handleConfirm(true)}>
                                            <FormattedMessage id='label.select' defaultMessage='Select' />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
*/}
        </Popper>
    );
}
