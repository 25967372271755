import React from "react";
import mainStyles from "../../styles/mainStyles";
import compose from 'recompose/compose';
import {withStyles} from "@material-ui/core/styles";
import { withApollo } from '@apollo/client/react/hoc';
import {FormattedMessage, injectIntl} from "react-intl";
import HPResult from "./HPResult";
import HPSelectComb from "./HPSelectComb";
import HPSelectDrugs from "./HPSelectDrugs";
import HPSelectTherapy from "./HPSelectTherapy";
import {
  Box, Grid,
  Typography,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import ExpandTextPanel from "../baseline/ExpandTextPanel";
import {getRecommendTexts} from '../../query/HPBaseline';
import PropTypes from "prop-types";
import {getQueryResult} from "../../utils";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import config from "../../config";

const styles = theme => ({
  ...mainStyles(theme),
});

function createHPBaselineComp(WrapComp) {
  return class extends React.Component {

    static propTypes = {
      getSelectVars: PropTypes.func.isRequired,
      getSelectTherapyType: PropTypes.func.isRequired,
    };

    abortController = new AbortController();

    constructor(props) {
      super(props);
      this.state = { };
    }

    componentDidMount() {
      window.scrollTo(0, 0);
      this.loadRecommendTexts();
    }

    componentWillUnmount() {
      this.abortController.abort();
    }

    loadRecommendTexts = () => {
      const { client, activePatient, getSelectTherapyType } = this.props;
      const { signal } = this.abortController;
      const selTherapy = getSelectTherapyType();

      client.query({
        query: getRecommendTexts,
        variables: {
          patientId: Number(activePatient.patient) || 0,
          selThIds: selTherapy ? [selTherapy.id] : [],
        },
        context: {
          uri: config.options.server.hp_url + config.options.server.api_uri,
          fetchOptions: {
            signal,
          },
        },
      })
          // .then(result => (result.data || {}).getRecommendTexts)
          // .then(result => JSON.parse(JSON.stringify(result)))
          .then(result => getQueryResult(result?.data, 'getRecommendTexts'))
          .then(recommendTexts => {
            this.setState({
              recommendTexts: recommendTexts,
            })
          });
    };

    getRecomentTextsView = () => {
      const { intl } = this.props;
      const { recommendTexts } = this.state;
      let texts = [];

      if (Boolean(recommendTexts)) {
        recommendTexts.forEach(txt => {
          if (Boolean(txt.label)) {
            texts.push(<Typography color={"primary"}>
              {txt.label}
            </Typography>);
          }
          texts.push(
            <Typography component={"span"}>
              <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={txt.text} />
            </Typography>
          );
        });
      }

      if (texts.length > 0) {
        return (<ExpandTextPanel
          textSummary={intl.formatMessage({
            id: 'label.hp.clinical_recommendations',
            defaultMessage: 'Clinical recommendations(AHA/ACC и ESC Guidelines)'
          })}
          textSecondary={intl.formatMessage({
            id: 'label.hp2.origin',
            defaultMessage: 'Origin: 2018 ESC/ESH Clinical Practice Guidelines for the Management of Arterial Hypertension; 2017 "ACC/AHA/AAPA/ABC/ACPM/AGS/APhA/ASH/ASPC/NMA/PCNA Guideline for the Prevention, Detection, Evaluation and Management of High Blood Pressure in Adults"'
          })}>
          {texts.map((txt, id) => (
            <Box key={id}>
              {txt}
            </Box>
          ))}
        </ExpandTextPanel>);
      } else {
        return (<Box />);
      }
    };

    getHelpDrugsTextsView = () => {
      const { classes } = this.props;
      return (
          <Grid item xs={12}>
            <div>
              <Typography className={classes.drugRecommend} variant='caption'>
                <FormattedMessage id="label.drug.recommend_short" defaultMessage="Drug recommend"/>
              </Typography>
            </div>
            <div>
              <Typography className={classes.drugWarning} variant='caption'>
                <FormattedMessage id="label.drug.warning" defaultMessage="Drug warning"/>
              </Typography>
            </div>
            <div>
              <Typography className={classes.drugDanger} variant='caption'>
                <FormattedMessage id="label.drug.danger" defaultMessage="Drug danger"/>
              </Typography>
            </div>
          </Grid>
      );
    };

    render() {
      // const $this = this;
      return (<WrapComp
        {...this.props}
        getRecomentTextsView={this.getRecomentTextsView}
        getHelpDrugsTextsView={this.getHelpDrugsTextsView}
      />)
    }

  }
}

export const HPResultView = compose(
  withStyles(styles, { withTheme: true }),
  withApollo,
  injectIntl,
)(createHPBaselineComp(HPResult));

export const HPSelectCombView = compose(
  withStyles(styles),
  withApollo,
  injectIntl,
)(createHPBaselineComp(HPSelectComb));

export const HPSelectDrugsView = compose(
  withStyles(styles),
  withApollo,
  injectIntl,
)(createHPBaselineComp(HPSelectDrugs));

export const HPSelectTherapyView = compose(
  withStyles(styles),
  withApollo,
  injectIntl,
)(createHPBaselineComp(HPSelectTherapy));

