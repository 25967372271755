import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BaselineEditorView from "../common/BaselineEditorView";

const BaselineDialog = (props) => {
    const {intl, open, setOpen, patientData, patientDrugs, contextStaff} = props;

    return (
        <Dialog
            fullWidth
            maxWidth={'lg'}
            // fullScreen
            // onEscapeKeyDown={() => setOpen(false)}
            // onBackdropClick={() => setOpen(false)}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="baseline-dialog"
        >
            <DialogTitle id="baseline-dialog">{
                intl.formatMessage({
                    id: 'label.patient.baseline',
                    defaultMessage: 'Baseline data'
                })}
            </DialogTitle>
            <DialogContent>
                {/*TODO: закоментировал, т.к. не работает с новой моделью данных*/}
                {/*<BaselineView*/}
                {/*    baseline={baseline}*/}
                {/*    nosologyData={nosologyData}*/}
                {/*    getSelectVars={() => {}}*/}
                {/*    isFieldShow={isFieldShow}*/}
                {/*    readonly={true}*/}
                {/*/>*/}
                <BaselineEditorView
                    baseline={(patientData || {}).baseline}
                    patient={(patientData || {})}
                    patientData={patientData || {}}
                    activePatient={null}
                    readonly={true}
                    showButtons={false}
                    nextNosology={null}
                    getNextNosology={() => {}}
                    history={null}
                    loadPatient={() => {}}
                    openDialog={() => {}}
                    patientDrugs={() => patientDrugs(patientData, patientData, contextStaff, true)}
                />
                {/*<BaselineEditorView*/}
                {/*    baseline={(patientData || {}).baseline}*/}
                {/*    patient={null}*/}
                {/*    readonly={true}*/}
                {/*/>*/}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    {
                        intl.formatMessage({
                            id: 'label.close',
                            defaultMessage: 'Close'
                        })
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BaselineDialog;
