import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import mainStyles from '../styles/mainStyles';
import * as PropTypes from "prop-types";

const styles = theme => ({
  ...mainStyles(theme),
});

function ListEmpty(props) {
  const { classes, text } = props;

  return (
    <div className={classes.contentBoxCenter}>
      <Typography color={"textSecondary"} component="p" variant="body1">
        {text ? text : "Список пуст"}
      </Typography>
    </div>
  );
}

ListEmpty.propTypes = {
  text: PropTypes.string,
};

export default withStyles(styles)(ListEmpty);
