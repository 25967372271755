import React from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import { FormattedMessage, injectIntl } from 'react-intl';
import {isPatientExist} from "../../utils";
import DatatableComponent from "../datatable/DatatableComponent";
import {TableNameType} from "../../const";
import {MUIDataTableOptions} from "mui-datatables";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    ...mainStyles(theme),
    searchBox: {
        marginTop: theme.spacing(3),
    },
    dialogFixedSize: {
        // height: '100vh',
        // maxHeight: 500,
        // maxHeight: 'calc(100vh-250px)',
        // maxHeight: 'fitContent',
        // height: 'calc(100vh-260px)',
        // maxHeight: 'calc(100vh-260px)',
        // alignItems: 'baseline'  // default center
    },
    scrollPaper: {
        alignItems: 'baseline'  // default center
    },
    nosologyName: {
        // marginLeft: theme.spacing(1),
        // color: theme.palette.primary.main,
        overflowWrap: 'anywhere',
    },
});

class PatientDialog extends React.Component {

    state = {
        fields: new Map(),
        errors: new Map(),
        nosologiesData: [],
        rerender: undefined,
        selectedNosologies: [],
        disableButtons: false,
    };

    constructor(props) {
        super(props);
        const { intl, patient = {} } = props;

        this.state = {
            fields: new Map([
                ['name', patient.name || ''],
            ]),
            errors: new Map(),
            nosologiesData: [],
            rerender: undefined,
            selectedNosologies: [],
            disableButtons: false,
        };

        // let $this = this;

        this.validate = {
            'name': (value) => {
                let errors = this.state.errors;

                if (value.length < 6) {
                    errors.set('name', intl.formatMessage({id: 'err.form.patient.short', defaultMessage: 'Short patient name'}));
                } else if (patient.name !== value && isPatientExist(this.props.patientsData, value)) {
                    errors.set('name', intl.formatMessage({id: 'err.form.patient.dupe', defaultMessage: 'Dupe patient name'}));
                } else {
                    errors.delete('name');
                }

                return errors;
            },
        };
    }

    componentDidMount() {
        this.loadNosologies();
    }

    loadNosologies = () => {
        const {contextStaff} = this.context;
        const {isNosologyAdded} = this.props;
        const selectedNosologies = [];

        contextStaff.nosologies.forEach((nosology) => {
            if (isNosologyAdded(nosology)) {
                selectedNosologies.push(nosology);
            }
        });

        this.setState({
            selectedNosologies: selectedNosologies,
            nosologiesData: contextStaff.nosologies,
            rerender: new Date().getTime(),
        });
    }

    nosologiesOptions = (): MUIDataTableOptions => {
        const options: MUIDataTableOptions = {
            usePropAsKey: 'id',
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            pagination: false,
            search: false,
            selectableRowsHeader: false,
            selectableRows: 'single',
            selectToolbarPlacement: 'none',
            selectableRowsOnClick: false,
            fixedHeader: true,
            fixedSelectColumn: true,
        };

        return options;
    }

    selectNosology = (nosology) => {
        const {selectedNosologies} = this.state;
        const nosologyForDeleteIndex = selectedNosologies.findIndex((selectedNosology) => selectedNosology.id === nosology.id);
        if (nosologyForDeleteIndex >= 0) {
            selectedNosologies.splice(nosologyForDeleteIndex, 1);
        } else {
            selectedNosologies.push(nosology);
        }
        this.setState({
            selectedNosologies: selectedNosologies,
            rerender: new Date().getTime(),
        });
    }

    isNosologySelected = (nosology): boolean => {
        const {selectedNosologies} = this.state;
        // const {isNosologyAdded} = this.props;

        return (
            // isNosologyAdded(nosology)
            // ||
            (selectedNosologies || []).some((selectedNosology) => selectedNosology.id === nosology.id)
        );
    }

    selectRenderer = (value, tableMeta, updateValue) => {
        const {nosologiesData} = this.state;
        const {classes} = this.props;

        const nosology = tableMeta ? nosologiesData.find((nosology) => nosology.id === tableMeta.rowData[0]) : value;
        const nosologyName = (nosology || {}).name;

        return (
            <ListItem role={undefined} dense button onClick={() => this.selectNosology(nosology)}>
                <ListItemIcon style={{minWidth: 'unset'}}>
                    <Checkbox
                        color={'primary'}
                        edge="start"
                        checked={this.isNosologySelected(nosology)}
                        value={nosology}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-label': 'radio-button-select-nosology'}}
                    />
                </ListItemIcon>
                <ListItemText primary={nosologyName} className={classes.nosologyName} />
            </ListItem>
            // <React.Fragment>
            //     <Box>
            //         <Checkbox
            //             // checked={selectedDrug === value}
            //             color={"primary"}
            //             checked={this.isNosologySelected(nosology)}
            //             // disabled={isNosologyAdded(nosology)}
            //             onChange={() => this.selectNosology(nosology)}
            //             value={nosology}
            //             name="radio-button-select-nosology"
            //             inputProps={{'aria-label': 'radio-button-select-nosology'}}
            //             // classes={classes.PrivateSwitchBase}
            //         />
            //         <Box component={'span'} className={classes.nosologyName}>
            //             {nosologyName}
            //         </Box>
            //     </Box>
            // </React.Fragment>
        );
    }

    nosologiesColumns = () => {
        const {intl} = this.props;
        const columns = [
            {
                name: "id",
                label: "",
                options: {
                    useAsKey: true,
                    display: 'false',
                }
            },
            {
                name: "name",
                label: intl.formatMessage({id: 'label.patient.table.nosologies', defaultMessage: 'Nosologies'}),
                options: {
                    useAsKey: true,
                    sort: false,
                    // display: 'false',
                    // setCellHeaderProps: (value) => ({style: {textDecoration: 'underline', width: '100px'}}),
                    customBodyRender: (value, tableMeta, updateValue) => this.selectRenderer(value, tableMeta, updateValue),
                }
            },
        ];

        return columns;
    }

    onValueChange = (event) => {
        let target = event.target;

        this.setState({
            fields: this.state.fields.set(target.name, target.value),
            errors: this.validate[target.name](target.value),
        });
    }

    onFormSubmit = (event) => {
        const { fields, errors, selectedNosologies } = this.state;
        const { onSubmit } = this.props;

        for (let [name, value] of fields) {
            this.validate[name](value);
        }

        if (errors.size === 0) {
            this.setState({
                disableButtons: true,
            });
            onSubmit({
                name: fields.get('name'),
            }, selectedNosologies, (response) => {
                if (!!response) {
                    this.onClose();
                }
                this.setState({
                    disableButtons: false,
                });
            });
            // this.clearState();
        } else {
            this.forceUpdate();
        }

        event.preventDefault();
    }

    onClose = (event = null, reason = null) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            const {onDialogClose} = this.props;
            this.clearState(onDialogClose);
        }
    }

    clearState = (callback) => {
        const { patient = {} } = this.props;
        this.setState({
            fields: new Map([
                ['name', patient.name || ''],
            ]),
            errors: new Map(),
            nosologiesData: [],
            rerender: undefined,
            selectedNosologies: undefined,
            disableButtons: false,
        }, () => {
            if (!!callback) {
                callback();
            }
        });
    }

    render() {
        const { patient, isShow, intl, submitButtonLabel = 'label.add', classes } = this.props;
        const { fields, errors, nosologiesData, rerender, disableButtons } = this.state;

        return (
            <Dialog
                classes={{
                    paper: classes.dialogFixedSize,
                    scrollPaper: classes.scrollPaper,
                }}
                fullWidth
                // onEscapeKeyDown={this.onClose}
                // onBackdropClick={this.onClose}
                onClose={(event, reason) => this.onClose(event, reason)}
                open={isShow}
                aria-labelledby="param-dialog-title">
                <DialogTitle id="param-dialog-title">
                    <FormattedMessage id={patient?.name ? 'label.patients.edit' : 'label.patients.new'} defaultMessage='New patient' />
                </DialogTitle>
                <form onSubmit={this.onFormSubmit}>
                    <DialogContent className={disableButtons ? classes.disabled : ''}>
                        <TextField
                            autoFocus
                            required
                            fullWidth
                            margin="dense"
                            id="name"
                            name="name"
                            label={intl.formatMessage({id: 'label.patients.name', defaultMessage: 'Name',})}
                            value={fields.get('name')}
                            onChange={this.onValueChange}
                            error={errors.get('name') != null}
                            helperText={errors.get('name')}
                            disabled={disableButtons}
                        />

                        <DatatableComponent
                            intl={intl}
                            tableName={TableNameType.ADDING_PATIENT_NOSOLOGIES}
                            title={''}
                            data={nosologiesData || []}
                            columns={this.nosologiesColumns()}
                            options={this.nosologiesOptions()}
                            rerender={rerender}
                            cellPadding={'4px'}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => this.onClose()} disabled={disableButtons}>
                            <FormattedMessage id='label.cancel' defaultMessage='Cancel' />
                        </Button>
                        <Button color="primary" type="submit" disabled={errors.size !== 0} disabled={disableButtons}>
                            <FormattedMessage id={submitButtonLabel} defaultMessage='Add' />
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

PatientDialog.propTypes = {
    intl: PropTypes.object.isRequired,
    // isPatientExist: PropTypes.func.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isShow: PropTypes.bool.isRequired,
    patient: PropTypes.object,
    patientsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isNosologyAdded: PropTypes.func.isRequired,
    submitButtonLabel: PropTypes.string,
};

PatientDialog.contextTypes = {
    contextStaff: PropTypes.object,
};

// export default injectIntl(PatientDialog);
export default compose(
    withStyles(styles),
    // withApollo,
    injectIntl,
)(PatientDialog);
