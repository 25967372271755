import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    Container,
    Paper,
    Grid,
} from "@material-ui/core";
import PanelDivider from "./PanelDivider";
import {FormattedMessage, injectIntl} from "react-intl";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import mainStyles from "../styles/mainStyles";
import {withApollo} from '@apollo/client/react/hoc';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import FBPScoreChart from "./research/FBPScoreChart";
import CircularProgress from '@material-ui/core/CircularProgress';
import SelTextView from "./SelTextView";
import {
    getColorByScoreAndSelectType,
    initGroupedBarChart2,
    newAnyDataRef,
    stringDivider, toFixed
} from "../utils";
import type {TherapyCompareDto, TherapyCompareStatisticsDto} from "../const";
import {BarChartOrientation, GroupedBarChartConfig, PScoreChartType} from "../const";
import GroupedBarChart from "./charts/GroupedBarChart";
import {InfoHelp} from "./common/InfoHelp";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

const styles = theme => ({
    ...mainStyles(theme),
    wrapperComparisions: {
        margin: -theme.spacing(2),
    },
    colorByScoreRed: {
        color: red[500],
    },
    colorByScoreGreen: {
        color: green[500],
    },
    colorByScoreOrange: {
        color: orange[500],
    },
    contentWrapperComparisions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0px',
        paddingRight: '0px',
    },
    contentComparisions: {
        width: '100%',
    },
    customIcoButton: {
        "&.MuiButtonBase-root": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            marginRight: '0px',
        }
    },

    comparisonScore: {
        display: 'inline-flex',
        "& span": {
            marginLeft: '4px',
        }
    },

    wrapper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        // borderBottom: '1px solid rgba(224, 224, 224, 1);',
        alignItems: 'center',
        flexWrap: 'unset',

        [theme.breakpoints.down(360)]: {
            alignItems: 'flex-start',
        },
    },
    group: {
        alignItems: 'center',
    },
    groupName: {
        overflowWrap: 'anywhere',
        marginLeft: '8px',
    },
    groupNameScore: {
        textAlign: 'right',
        flex: 'auto',
        overflowWrap: 'break-word',
    },
    collapseRowWrapper: {
        borderBottom: '1px solid rgba(224, 224, 224, 1);',
    },
    collapseRow: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    headName: {
        fontWeight: 500,
    },
    headScore: {
        fontWeight: 500,
        textAlign: 'right',
    },
});

const getCombRiskChart = (row: TherapyCompareDto): GroupedBarChartConfig => {
    if (row.combRisk && row.combRisk.length > 0) {
        // const combRiskChartData = initGroupedBarChart();
        const combRiskChartData = initGroupedBarChart2(
            row.combRisk.map(() => ({})),
            BarChartOrientation.HORIZONTAL,
            true
        );

        row.combRisk.forEach((cr, index) => {
            combRiskChartData.data[index].name = cr.name;
            combRiskChartData.data[index].legendgroup = cr.name;
            // combRiskChartData.data[index].marker.color = cr.selectType === 2 ? GroupedBarChartColorsBySelectType[cr.selectType] : getColorByScoreAndSelectType(row.score);
            combRiskChartData.data[index].marker.color = getColorByScoreAndSelectType(row.score, cr.selectType, cr.statistic);
            cr.comps.forEach((comp) => {
                const tickLabel = stringDivider(comp.name, 15, '<br>');
                combRiskChartData.data[index].x.push(comp.riskTh);
                combRiskChartData.data[index].y.push(tickLabel);

                // combRiskChartData.data[index].text = combRiskChartData.data[index].x.map(String);
                combRiskChartData.data[index].text = combRiskChartData.data[index].x.map((x) => toFixed(x));
            });
        });
        return combRiskChartData;
    }
}

/*
const getVerticalCombRiskChart = (row: TherapyCompareDto): GroupedBarChartConfig => {
    if (row.combRisk && row.combRisk.length > 0) {
        // const combRiskChartData = initGroupedBarChart();
        const combRiskChartData = initGroupedBarChart2(
            row.combRisk.map(() => ({})),
            BarChartOrientation.VERTICAL,
            true
        );

        row.combRisk.map((cr, index) => {
            combRiskChartData.data[index].name = cr.name;
            // combRiskChartData.data[index].marker.color = cr.selectType === 2 ? GroupedBarChartColorsBySelectType[cr.selectType] : getColorByScoreAndSelectType(row.score);
            combRiskChartData.data[index].marker.color = getColorByScoreAndSelectType(row.score, cr.selectType, cr.statistic);
            cr.comps.map((comp) => {
                const tickLabel = stringDivider(comp.name, 15, '<br>');
                combRiskChartData.data[index].y.push(comp.riskTh);
                combRiskChartData.data[index].x.push(tickLabel);

                combRiskChartData.data[index].text = combRiskChartData.data[index].y.map(String);
            });
        });
        return combRiskChartData;
    }
}
*/

/*
const getPolarChartData = (row: TherapyCompareDto): PolarChartConfig => {
    if (row.combRisk && row.combRisk.length > 0) {
        // const combRiskChartData = initGroupedBarChart();
        const polarChartData: PolarChartConfig = initPolarChart(
            row.combRisk.map(() => ({}))
        );

        row.combRisk.map((cr, index) => {
            polarChartData.data[index].name = cr.name;
            polarChartData.data[index].meta = cr.name;
            polarChartData.data[index].line = {
                color: getColorByScoreAndSelectType(row.score, cr.selectType, cr.statistic)
            };
            cr.comps.map((comp) => {
                // const tickLabel = stringDivider(comp.name, 15, '<br>');
                const tickLabel = comp.name;
                polarChartData.data[index].r.push(Number(comp.riskTh));
                polarChartData.data[index].theta.push(tickLabel);
            });
            polarChartData.data[index].r.push(polarChartData.data[index].r[0]);
            polarChartData.data[index].theta.push(polarChartData.data[index].theta[0]);
        });
        return polarChartData;
    }
}
*/

const Row = (props) => {
    const {row, classes} = props;

    row.comp_cont = row.compDrugs || row.compGroups;

    const allowOpen = (row: TherapyCompareDto): boolean => {
        return (
            // Boolean((row.pscore || {}).data)
            // ||
            // Boolean(row.combRiskChartData)
            // ||

            (row.forestPlots || []).length > 0
            ||
            (row.comp_cont || []).length > 0
            ||
            row.descr
        );
    }

    const [open, setOpen] = React.useState(
        false
        // () => allowOpen(row)
    );
    const [preventAutoOpen, setPreventAutoOpen] = React.useState(
        false
    );
    // const classes = useRowStyles();

    const getClassNameByScore = (score: number) => {
        if (score === 0) {
            return classes.colorByScoreRed;
        }
        if (0 < score && score < 100) {
            return classes.colorByScoreOrange;
        }
        if (score === 100) {
            return classes.colorByScoreGreen;
        }
    }

    // TODO: перенес в загрузку
    // row.combRiskChartData = getCombRiskChart(row);

    if (!preventAutoOpen) {
        setTimeout(() => {
            // setOpen(allowOpen(row))
            setOpen(false)
        }, 0);
    }

    return (
        <React.Fragment>
            {/*<TableRow*/}
            {/*    className={classes.cellNoBottomBorder}*/}
            {/*    style={{width: '100%'}}*/}
            {/*>*/}
            {/*    <TableCell className={`${classes.cellNoBottomBorder} ${classes.pl8px} ${classes.pr0px}`}>*/}
            {/*        <IconButton*/}
            {/*            disabled={!allowOpen(row)}*/}
            {/*            aria-label="expand row"*/}
            {/*            size="small"*/}
            {/*            onClick={() => {*/}
            {/*                if (allowOpen(row)) {*/}
            {/*                    setPreventAutoOpen(true);*/}
            {/*                    setOpen(!open);*/}
            {/*                }*/}
            {/*            }}*/}
            {/*            className={classes.customIcoButton}*/}
            {/*        >*/}
            {/*            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}*/}
            {/*        </IconButton>*/}
            {/*    </TableCell>*/}
            {/*    <TableCell component="th" scope="row"*/}
            {/*               className={`${classes.cellNoBottomBorder} ${getClassNameByScore(row.score)}`}>*/}
            {/*        {row.name}*/}
            {/*    </TableCell>*/}
            {/*    <TableCell align="right" className={`${classes.cellNoBottomBorder} ${getClassNameByScore(row.score)}`}>*/}
            {/*        <Box className={classes.comparisonScore}>*/}
            {/*            {Number(row.score)}%*/}
            {/*            {row.scoreDescr && <InfoHelp text={row.scoreDescr}/>}*/}
            {/*        </Box>*/}
            {/*    </TableCell>*/}
            {/*</TableRow>*/}
            {/*<TableRow style={{width: '100%'}}>*/}
            {/*    <TableCell style={{paddingLeft: '52px', paddingBottom: 0, paddingTop: 0}} colSpan={3}>*/}
            {/*        <Collapse in={open} timeout="auto" unmountOnExit>*/}
            {/*            <Box margin={1}>*/}
            {/*                {row.descr && (*/}
            {/*                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={row.descr}/>*/}
            {/*                )}*/}
            {/*                {*/}
            {/*                    (row.forestPlots || []).length > 0 &&*/}
            {/*                    <Box>*/}
            {/*                        {*/}
            {/*                            (row.forestPlots || []).map((forestPlot, id) => (*/}
            {/*                                <Box key={id}>*/}
            {/*                                    <PanelDivider/>*/}
            {/*                                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>*/}
            {/*                                        <SelTextView align={'center'}>*/}
            {/*                                            {forestPlot.title}*/}
            {/*                                        </SelTextView>*/}
            {/*                                        <img src={forestPlot.path} alt={forestPlot.title}*/}
            {/*                                             className={classes.imgAutoWidth}*/}
            {/*                                        />*/}
            {/*                                    </Paper>*/}
            {/*                                </Box>*/}
            {/*                            ))*/}
            {/*                        }*/}
            {/*                    </Box>*/}
            {/*                }*/}
            {/*            </Box>*/}
            {/*        </Collapse>*/}
            {/*    </TableCell>*/}
            {/*</TableRow>*/}
            <Grid container direction={"row"} className={classes.wrapper}>
                {/*<Grid item xl={6} lg={6} md={6} sm={6} xs={6}*/}
                {/*    // className={`${classes.pl8px}`}*/}
                {/*>*/}
                {/*    <Grid container className={classes.group} spacing={2}>*/}
                        <Grid item
                              // xs={3} sm={3}
                        >
                            <IconButton
                                disabled={!allowOpen(row)}
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                    if (allowOpen(row)) {
                                        setPreventAutoOpen(true);
                                        setOpen(!open);
                                    }
                                }}
                                className={classes.customIcoButton}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Grid>
                        <Grid item
                              // xs={9} sm={9}
                              className={`${classes.groupName} ${getClassNameByScore(row.score)}`}>
                            {row.name}
                        </Grid>
                {/*    </Grid>*/}
                {/*</Grid>*/}
                <Grid item
                      // xl={6} lg={6} md={6} sm={6} xs={6}
                      className={`${classes.groupNameScore} ${getClassNameByScore(row.score)}`}
                >
                    <Box className={classes.comparisonScore}>
                        {Number(row.score)}%
                        {row.scoreDescr && <Box style={{marginTop: '-10px'}}><InfoHelp texts={[{payload: row.scoreDescr}]}/></Box>}
                    </Box>
                </Grid>
            </Grid>
            <Grid container className={classes.collapseRowWrapper}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.collapseRow}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}
                             marginTop={-1}
                        >
                            {row.descr && (
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={row.descr}/>
                            )}
                            {
                                (row.forestPlots || []).length > 0 &&
                                <Box>
                                    {
                                        (row.forestPlots || []).map((forestPlot, id) => (
                                            <Box key={id}>
                                                <PanelDivider/>
                                                <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                                                    <SelTextView align={'center'}>
                                                        {forestPlot.title}
                                                    </SelTextView>
                                                    <img src={forestPlot.url} alt={forestPlot.title}
                                                         className={classes.imgAutoWidth}
                                                    />
                                                </Paper>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            }
                        </Box>
                    </Collapse>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

class ComparisonsView extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.loadTherapyCompare();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    mergeTherapyCompare = (therapyCompareStatistics: TherapyCompareStatisticsDto[] = null, therapyCompare: TherapyCompareDto[] = null) => {
        if (therapyCompareStatistics && therapyCompare) {
            let _therapyCompareStatistics = newAnyDataRef(therapyCompareStatistics);
            const _therapyCompare = newAnyDataRef(therapyCompare);
            _therapyCompareStatistics = _therapyCompareStatistics.map((therapyCompareStatistic) => {
                let filteredTherapyCompare;
                if ((therapyCompareStatistic || {}).pscore) {
                    filteredTherapyCompare = newAnyDataRef(_therapyCompare.filter((tc) => tc.id === therapyCompareStatistic.id)[0] || null);
                    if ((filteredTherapyCompare || {}).pscore) {
                        filteredTherapyCompare.pscore.data.efficacy = filteredTherapyCompare.pscore.data.efficacy.filter((efficacy) => efficacy.selectType !== 0);
                        filteredTherapyCompare.pscore.data.safety = filteredTherapyCompare.pscore.data.safety.filter((safety) => safety.selectType !== 0);
                    }
                    if ((therapyCompareStatistic || {}).pscore) {
                        therapyCompareStatistic.pscore.data.efficacy = therapyCompareStatistic.pscore.data.efficacy.map((efficacy) => {
                            efficacy.statistic = true;
                            return efficacy;
                        });
                        therapyCompareStatistic.pscore.data.safety = therapyCompareStatistic.pscore.data.safety.map((safety) => {
                            safety.statistic = true;
                            return safety;
                        });
                        if (filteredTherapyCompare) {
                            if (filteredTherapyCompare.pscore) {
                                filteredTherapyCompare.pscore.data.efficacy = filteredTherapyCompare.pscore.data.efficacy.concat(therapyCompareStatistic.pscore.data.efficacy);
                                filteredTherapyCompare.pscore.data.safety = filteredTherapyCompare.pscore.data.safety.concat(therapyCompareStatistic.pscore.data.safety);
                            } else {
                                filteredTherapyCompare.pscore = therapyCompareStatistic.pscore;
                                filteredTherapyCompare.pscore = therapyCompareStatistic.pscore;
                            }
                        } else {
                            filteredTherapyCompare = therapyCompareStatistic;
                        }
                    }
                }

                if (((therapyCompareStatistic || {}).combRisk || []).length > 0) {
                    filteredTherapyCompare = newAnyDataRef(_therapyCompare[0] || null);

                    if ((filteredTherapyCompare || {}).combRisk) {
                        filteredTherapyCompare.combRisk = filteredTherapyCompare.combRisk.filter((combRisk) => combRisk.selectType !== 0);
                    }
                    if ((therapyCompareStatistic || {}).combRisk) {
                        therapyCompareStatistic.combRisk = therapyCompareStatistic.combRisk.map((combRisk) => {
                            combRisk.statistic = true;
                            return combRisk;
                        });
                        if (filteredTherapyCompare) {
                            if (filteredTherapyCompare.combRisk) {
                                filteredTherapyCompare.combRisk = filteredTherapyCompare.combRisk.concat(therapyCompareStatistic.combRisk);
                            } else {
                                filteredTherapyCompare.combRisk = therapyCompareStatistic.combRisk;
                            }
                        } else {
                            filteredTherapyCompare = therapyCompareStatistic;
                        }
                    }
                }
                // const mergedTherapyCompareStatistic = Object.assign({}, therapyCompareStatistic, filteredTherapyCompare);
                // const mergedTherapyCompareStatistic = _.merge(therapyCompareStatistic, filteredTherapyCompare);
                // const mergedTherapyCompareStatistic = {...therapyCompareStatistic, ...(filteredTherapyCompare || {})};
                // therapyCompareStatistic = filteredTherapyCompare;
                // return therapyCompareStatistic;
                return newAnyDataRef(filteredTherapyCompare || null);
            });

            this.setState({
                therapyCompareStatistics: _therapyCompareStatistics,
                showTherapyCompareStatistics: true,
                loading: false,
            });
        }
    }

    mergeTherapyCompare2 = (therapyCompareStatistics: TherapyCompareStatisticsDto[] = null, therapyCompare: TherapyCompareDto[] = null) => {
        if (therapyCompareStatistics && therapyCompareStatistics.length > 0 && therapyCompare && therapyCompare.length > 0) {
            let _therapyCompareStatistics = newAnyDataRef(therapyCompareStatistics);
            const _therapyCompare = newAnyDataRef(therapyCompare);
            _therapyCompareStatistics = _therapyCompareStatistics.map((therapyCompareStatistic) => {
                let filteredTherapyCompare;
                filteredTherapyCompare = newAnyDataRef(_therapyCompare.filter((tc) => tc.id === therapyCompareStatistic.id)[0] || null);
                if ((therapyCompareStatistic || {}).pscore) {
                    if ((filteredTherapyCompare || {}).pscore) {
                        if ((therapyCompareStatistic || {}).pscore) {
                            therapyCompareStatistic.pscore.data.efficacy = therapyCompareStatistic.pscore.data.efficacy.map((efficacy) => {
                                efficacy.statistic = true;
                                return efficacy;
                            });
                            therapyCompareStatistic.pscore.data.safety = therapyCompareStatistic.pscore.data.safety.map((safety) => {
                                safety.statistic = true;
                                return safety;
                            });
                            filteredTherapyCompare.pscore.data.efficacy = filteredTherapyCompare.pscore.data.efficacy.concat(therapyCompareStatistic.pscore.data.efficacy);
                            filteredTherapyCompare.pscore.data.safety = filteredTherapyCompare.pscore.data.safety.concat(therapyCompareStatistic.pscore.data.safety);
                        }
                    }
                }

                if ((filteredTherapyCompare || {}).combRisk) {
                    if (((therapyCompareStatistic || {}).combRisk || []).length > 0) {
                        if ((filteredTherapyCompare || {}).combRisk) {
                            filteredTherapyCompare.combRisk = filteredTherapyCompare.combRisk.filter((combRisk) => combRisk.selectType !== 0);
                        }
                        therapyCompareStatistic.combRisk = therapyCompareStatistic.combRisk.map((combRisk) => {
                            combRisk.statistic = true;
                            return combRisk;
                        });
                        filteredTherapyCompare.combRisk = filteredTherapyCompare.combRisk.concat(therapyCompareStatistic.combRisk);
                    }
                }
                return newAnyDataRef(filteredTherapyCompare || null);
            });

            this.setState({
                therapyCompare: _therapyCompareStatistics,
                showTherapyCompareStatistics: true,
                loading: false,
            });
        }
    }

    mergeTherapyCompare3 = (therapyCompareStatistics: TherapyCompareStatisticsDto[] = null, therapyCompare: TherapyCompareDto[] = null) => {
        // if (therapyCompareStatistics && therapyCompareStatistics.length > 0 && therapyCompare && therapyCompare.length > 0) {
        if (therapyCompare && therapyCompare.length > 0) {
            let _therapyCompareStatistics = newAnyDataRef(therapyCompareStatistics);
            let _therapyCompare = newAnyDataRef(therapyCompare);
            _therapyCompare = _therapyCompare.map((therapyCompareItem) => {
                let filteredTherapyCompareStatistic;
                if (_therapyCompareStatistics && _therapyCompareStatistics.length > 0)
                    filteredTherapyCompareStatistic = newAnyDataRef(_therapyCompareStatistics.filter((tcs) => tcs.id === therapyCompareItem.id)[0] || null);
                if ((filteredTherapyCompareStatistic || {}).pscore) {
                    if ((therapyCompareItem || {}).pscore) {
                        filteredTherapyCompareStatistic.pscore.data.efficacy = filteredTherapyCompareStatistic.pscore.data.efficacy
                            .filter((efficacy) => efficacy.selectType === 2)
                            .map((efficacy) => {
                                efficacy.statistic = true;
                                return efficacy;
                            });
                        filteredTherapyCompareStatistic.pscore.data.safety = filteredTherapyCompareStatistic.pscore.data.safety
                            .filter((safety) => safety.selectType === 2)
                            .map((safety) => {
                                safety.statistic = true;
                                return safety;
                            });
                        therapyCompareItem.pscore.data.efficacy = therapyCompareItem.pscore.data.efficacy.concat(filteredTherapyCompareStatistic.pscore.data.efficacy);
                        therapyCompareItem.pscore.data.safety = therapyCompareItem.pscore.data.safety.concat(filteredTherapyCompareStatistic.pscore.data.safety);
                    }
                }

                if ((filteredTherapyCompareStatistic || {}).combRisk) {
                    if (((therapyCompareItem || {}).combRisk || []).length > 0) {
                        filteredTherapyCompareStatistic.combRisk = filteredTherapyCompareStatistic.combRisk
                            .filter((combRisk) => combRisk.selectType === 3);
                        filteredTherapyCompareStatistic.combRisk = filteredTherapyCompareStatistic.combRisk.map((combRisk) => {
                            combRisk.statistic = true;
                            return combRisk;
                        });
                        therapyCompareItem.combRisk = therapyCompareItem.combRisk.concat(filteredTherapyCompareStatistic.combRisk);
                    }
                }
                // const mergedTherapyCompareStatistic = Object.assign({}, therapyCompareStatistic, filteredTherapyCompare);
                // const mergedTherapyCompareStatistic = _.merge(therapyCompareStatistic, filteredTherapyCompare);
                // const mergedTherapyCompareStatistic = {...therapyCompareStatistic, ...(filteredTherapyCompare || {})};
                // therapyCompareStatistic = filteredTherapyCompare;
                // return therapyCompareStatistic;
                return newAnyDataRef(therapyCompareItem);
            });

            this.setState({
                therapyCompare: _therapyCompare,
                showTherapyCompareStatistics: true,
                loading: false,
            });
        }
    }

    fillCombRiskChart = (therapyCompare: TherapyCompareDto[]): TherapyCompareDto[] => {
        if (therapyCompare) {
            therapyCompare = therapyCompare.map((tc) => {
                tc.combRiskChartData = getCombRiskChart(tc);
                return tc;
            });
        }

        return therapyCompare;
    }

    loadTherapyCompare = () => {
        const {
            loadTherapyCompare,
            loadTherapyCompareStatistics,
            getTherapyCompare,
            setTherapyCompare,
            getTouched,
        } = this.props;
        const {signal} = this.abortController;

        let therapyCompare = getTouched() ? undefined : getTherapyCompare();
        therapyCompare = this.fillCombRiskChart(therapyCompare);

        this.setState({
            loading: !therapyCompare || therapyCompare.length === 0 ? true : false,
            therapyCompare: therapyCompare,
        }, () => {
            if (!therapyCompare || therapyCompare.length === 0) {
                loadTherapyCompare(signal)
                    .then((_therapyCompare) => {
                        setTherapyCompare(_therapyCompare);
                        this.setState({
                            therapyCompare: this.fillCombRiskChart(_therapyCompare), // .filter((tc) => tc.combRisk),
                            // loading: false,
                        }, () => {
                            const {therapyCompare, therapyCompareStatistics} = this.state;
                            this.mergeTherapyCompare3(therapyCompareStatistics, newAnyDataRef(therapyCompare));
                        });
                    });
            }
            if (loadTherapyCompareStatistics) {
                loadTherapyCompareStatistics(signal)
                    .then((therapyCompareStatistics) => {
                        this.setState({
                            therapyCompareStatistics: therapyCompareStatistics,
                            // loading: false,
                        }, () => {
                            const {therapyCompare} = this.state;
                            this.mergeTherapyCompare3(therapyCompareStatistics, therapyCompare);
                        });
                    });
            }
        });
    };

    getChart = (therapyCompareStatistic: TherapyCompareStatisticsDto, index: number) => {
        const {
            activePatient,
        } = this.props;

        if (((therapyCompareStatistic || {}).pscore || {}).data) {
            return (<Box
                key={index}
                style={{width: '100%'}}
            >
                <FBPScoreChart
                    style={{width: '100%'}}
                    pscoreData={therapyCompareStatistic.pscore}
                    compStat={Math.round(Math.random() * 100000)}
                    treatments={[]}
                    subgroups={[]}
                    therapyName={''}
                    title={therapyCompareStatistic.name}
                    hasBled={0}
                    isCalcLevel={false}
                    useResizeHandler={true}
                    responsive={false}
                    chartType={PScoreChartType.STATISTICS}
                    patientId={Number(activePatient.patient)}
                />
            </Box>)
        }

        if ((therapyCompareStatistic || {}).combRisk) {
            return (<Box
                key={index}
                style={{width: '100%'}}
            >
                <GroupedBarChart chartData={getCombRiskChart(therapyCompareStatistic)} useResizeHandler={true}/>
                {/*<VerticalGroupedBarChart chartData={getVerticalCombRiskChart(therapyCompareStatistic)} useResizeHandler={true}/>*/}
                {/*<RadarChart chartData={getPolarChartData(therapyCompareStatistic)} useResizeHandler={true}/>*/}
            </Box>)
        }
    }

    comparisonsView = () => {
        const {classes} = this.props;
        const {
            therapyCompare,
            loading,
        } = this.state;

        return (
            <Box className={classes.wrapperComparisions}>
                <PanelDivider/>
                <Paper variant={"outlined"} className={classes.contentBox}>
                    {/*<SelTextView align={"center"}><FormattedMessage id="label.comparisons.comparison" defaultMessage="Comparison" /></SelTextView>*/}
                    <Typography variant={"h6"} align={"center"}>
                        <FormattedMessage id={'label.comparisons.comparison'} defaultMessage={'Comparison'}/>
                    </Typography>
                    <PanelDivider/>
                    <Container
                        // maxWidth={"sm"}
                        className={classes.contentWrapperComparisions}
                    >
                        {loading && <CircularProgress/>}
                        {!loading && therapyCompare && therapyCompare.length > 0 ? (
                                <Box
                                    className={classes.contentComparisions}
                                >
                                    {/*<TableContainer component={Paper} style={{width: '100%'}}>*/}
                                    {/*<Table>*/}
                                    {/*    <TableHead>*/}
                                    {/*        <TableRow>*/}
                                    {/*            <TableCell style={{width: '10px'}}/>*/}
                                    {/*            <TableCell>*/}
                                    {/*                <FormattedMessage id="label.comparisons.name" defaultMessage="Name"/>*/}
                                    {/*            </TableCell>*/}
                                    {/*            <TableCell align="right">*/}
                                    {/*                <FormattedMessage id="label.comparisons.score" defaultMessage="Score"/>*/}
                                    {/*            </TableCell>*/}
                                    {/*        </TableRow>*/}
                                    {/*    </TableHead>*/}
                                    {/*    <TableBody>*/}
                                    {/*        {therapyCompare.map((compare, i) => (*/}
                                    {/*            <Row key={compare.id || i} row={compare} classes={classes}/>*/}
                                    {/*        ))}*/}
                                    {/*    </TableBody>*/}
                                    {/*</Table>*/}
                                    <Grid container direction={"row"} style={{borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.headName}>
                                            <FormattedMessage id="label.comparisons.name" defaultMessage="Name"/>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.headScore}>
                                            <FormattedMessage id="label.comparisons.score" defaultMessage="Score"/>
                                        </Grid>
                                    </Grid>
                                    {therapyCompare.map((compare, i) => (
                                        <Row key={compare.id || i} row={compare} classes={classes}/>
                                    ))}
                                    {/*</TableContainer>*/}
                                </Box>
                            )
                            :
                            !loading && (!therapyCompare || therapyCompare.length === 0) && (
                                <Typography component="span" variant="body1">
                                    <FormattedMessage id={'label.comparisons.no_data'}
                                                      defaultMessage={'No data to display'}/>
                                </Typography>
                            )
                        }
                    </Container>
                </Paper>
                {/* // TODO: old statistics
                {
                        !loading
                        &&
                        showTherapyCompareStatistics
                        &&
                        (therapyCompareStatistics || []).length > 0
                        &&
                        (therapyCompareStatistics || [])
                            .some((therapyCompareStatistic, index) =>
                                (
                                    ((therapyCompareStatistic || {}).pscore || {}).data
                                    // TODO: убрал график статистики Гипертензии
                                    // ||
                                    // (((therapyCompareStatistic || {}).combRisk || []).length > 0)
                                )
                            )
                            &&
                    <React.Fragment>
                        <PanelDivider />
                        <Paper variant={"outlined"} className={classes.contentBox}>
                            <Typography variant={"h6"} align={"center"}>
                                <FormattedMessage id={'label.comparisons.comparison.statistics'} defaultMessage={'Statistics'}/>
                            </Typography>
                            <PanelDivider />
                            <Container
                                className={classes.contentWrapperComparisions}
                            >
                                {
                                    (therapyCompareStatistics || []).map((therapyCompareStatistic, index) =>
                                        this.getChart(therapyCompareStatistic, index)
                                    )
                                }
                            </Container>
                        </Paper>
                    </React.Fragment>
                }
*/}
                {
                    !loading && therapyCompare && therapyCompare.length > 0 && therapyCompare.some((compare) => (compare.pscore || {}).data || compare.combRiskChartData) && (
                        <React.Fragment>
                            <PanelDivider/>
                            <Paper variant={"outlined"} className={classes.contentBox}>
                                <Container className={classes.contentWrapperComparisions}>
                                    <Typography variant={"h6"} align={"center"}>
                                        <FormattedMessage id={'label.comparisons.charts_title'}
                                                          defaultMessage={'Сравнение изменений в тактике назначений (Тест/MedicBK)'}/>
                                    </Typography>
                                    {
                                        therapyCompare.map((compare, i) => (
                                            <React.Fragment key={i}>
                                                {/*{((compare.pscore || {}).data || compare.combRiskChartData) &&*/}
                                                {/*    <Typography variant={"h6"} align={"center"}>*/}
                                                {/*        {compare.name}*/}
                                                {/*    </Typography>*/}
                                                {/*}*/}
                                                {(compare.pscore || {}).data &&
                                                <Box
                                                    style={{width: '100%'}}
                                                >
                                                    <PanelDivider/>
                                                    <Typography variant={"h6"} align={"center"}>
                                                        {compare.name}
                                                    </Typography>
                                                    <FBPScoreChart
                                                        style={{width: '100%'}}
                                                        title={''}
                                                        pscoreData={compare.pscore}
                                                        compStat={Math.round(Math.random() * 100000)}
                                                        treatments={[]}
                                                        subgroups={[]}
                                                        therapyName={''}
                                                        hasBled={0}
                                                        isCalcLevel={false}
                                                        useResizeHandler={true}
                                                        responsive={false}
                                                        chartType={PScoreChartType.COMPARISONS_STATISTICS}
                                                    />
                                                </Box>
                                                }
                                                {
                                                    compare.combRiskChartData &&
                                                    <Grid item xs={12}>
                                                        <PanelDivider/>
                                                        {/*<Paper className={classes.contentBoxCenter}*/}
                                                        {/*       variant={"outlined"}>*/}
                                                        {/*    <SelTextView align={'center'}>*/}
                                                        {/*        <FormattedMessage id="label.comparisons.combRisk"*/}
                                                        {/*                          defaultMessage="Percentage of event occurrence"/>*/}
                                                        {/*    </SelTextView>*/}
                                                        <Box>
                                                            <GroupedBarChart chartData={compare.combRiskChartData}
                                                                             useResizeHandler={true}
                                                                             responsive={false}/>
                                                        </Box>
                                                        {/*</Paper>*/}
                                                    </Grid>
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </Container>
                            </Paper>
                        </React.Fragment>
                    )
                }
            </Box>
        );
    }

    render() {
        const {classes} = this.props;

        return (
            <Box className={classes.contentBox}>
                {this.comparisonsView()}
            </Box>
        );
    }
}

ComparisonsView.propTypes = {
    classes: PropTypes.object.isRequired,
    activePatient: PropTypes.object,
    nosology: PropTypes.string.isRequired,
    getSelectVars: PropTypes.func.isRequired,
    getTherapyPresumably: PropTypes.func.isRequired,
    getThRecommend: PropTypes.func.isRequired,
    loadTherapyCompare: PropTypes.func.isRequired,
    loadTherapyCompareStatistics: PropTypes.func,
    getTherapyCompare: PropTypes.func.isRequired,
    setTherapyCompare: PropTypes.func.isRequired,
    getTouched: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles, {withTheme: true}),
    withApollo,
    injectIntl,
)(ComparisonsView);
