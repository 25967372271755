export const UserProfile = `
    id
    user {
        id
        username
        firstname
        lastname
        enabled
        authorities
    }
    role {
        id
        name
    }
    profile {
        id
        spec {
            name
        }
        country
        city {
            name
        }
        job
        degree
        experience
        skills {
            name
        }
    }
`;
