// This file is shared across the demos.

import React from 'react';
import HyperIcon from '@material-ui/icons/TrendingDown';
import RegRequestIcon from '@material-ui/icons/HowToReg';
import PatientsIcon from '@material-ui/icons/People';
import InviteIcon from '@material-ui/icons/PersonAdd';
import {matchPath} from 'react-router';
import {FormattedMessage} from 'react-intl';
import RegRequestions from './RegRequestions';
import Invite from './Invite';
import {NosologyType, PatientType, Role} from '../const';
import Baseline from './Baseline';
import Hyper from './Hyper';
import Methodology from "./Methodology";
import MethodologyIcon from '@material-ui/icons/Build';
import HeartFailure from "./HeartFailure";
import { FibrillationIcon, HeartFailureIcon } from '../icons';
import Profile from "./Profile";
import PatientsSharedIcon from '@material-ui/icons/FolderShared';
import InvitePublic from "./InvitePublic";
// import Glossary from "./Glossary";
// import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import GroupAddIcon from "@material-ui/icons/GroupAdd";

class Page {

  constructor(name, icon, path, component, authorities, nosology?, onClick?) {
    this.name = name;
    this.icon = icon;
    this.path = path;
    this.component = component;
    this.authorities = authorities;
    this.nosology = nosology;
    this.onClick = onClick;
  }

  isPathMatch = (path, strict = false, exact = true) => {
    return this.getMatchFromPath(path, strict, exact) !== null;
  }

  isExact = () => {
    return this.path === '/';
  }

  getMatchFromPath = (path, strict = false, exact = true) => {
    return matchPath(path, {
      path: this.path,
      strict: strict,
      exact: exact,
    });
  }
}

export const pages = [
  new Page(
    <FormattedMessage id="page.baseline.name" defaultMessage="AF, oral anticoagulants" />,
    <FibrillationIcon color="error" />,
    '/baseline',
    Baseline,
    [Role.ADMIN, Role.EXPERT],
    NosologyType.ATRIAL_FIBRILLATION
  ),
  new Page(
    <FormattedMessage id="page.hyper" defaultMessage="Hypertension" />,
    <HyperIcon color="error" />,
    '/hyper',
    Hyper,
    [Role.ADMIN, Role.EXPERT],
    NosologyType.HYPERTENSION
  ),
  new Page(
    <FormattedMessage id="page.heartfailure" defaultMessage="Heart Failure" />,
    <HeartFailureIcon color="error" />,
    '/heartfailure',
    HeartFailure,
    [Role.ADMIN, Role.DEMO],
    NosologyType.HEART_FAILURE
  ),
  new Page(
    <FormattedMessage id="page.breastcancer" defaultMessage="Breast cancer" />,
    <HeartFailureIcon color="error" />,
    '/breastcancer',
    HeartFailure,
    [Role.ADMIN, Role.DEMO],
    NosologyType.BREAST_CANCER
  ),
];

// const onClickFunc = (context) => {
//   console.error('+++ GLOSSARY onClick() +++ context:', context);
// }

export const pages2 = [
  new Page(
    <FormattedMessage id="page.reg_request.name" defaultMessage="Registration request" />,
    <RegRequestIcon color="action" />,
    '/reg_request',
    RegRequestions,
    [Role.ADMIN]),
  new Page(
    <FormattedMessage id="page.invite.name" defaultMessage="Invite" />,
    <InviteIcon color="action" />,
    '/invite',
    Invite,
    [Role.ADMIN]),

  new Page(
    <FormattedMessage id="page.public_invites.name" defaultMessage="Public invites" />,
    <GroupAddIcon color="action" />,
    '/public-invites',
    InvitePublic,
    [Role.ADMIN]),

  // new Page(
  //   <FormattedMessage id="page.questions.help" defaultMessage="Home" />,
  //   <HelpIcon color="action" />,
  //   '/help',
  //   Home,
  //   [Role.ADMIN, Role.EXPERT]),
  new Page(
    <FormattedMessage id="page.methodology" defaultMessage="Methodology" />,
    <MethodologyIcon color="action" />,
    '/methodology',
    Methodology,
    [Role.ADMIN, Role.EXPERT, Role.ANONYMOUS, Role.EDUCATION]),

  // new Page(
  //   <FormattedMessage id="page.glossary" defaultMessage="Glossary" />,
  //   <LiveHelpIcon color="action" />,
  //   '/glossary',
  //   Glossary,
  //   [Role.ADMIN, Role.EXPERT, Role.ANONYMOUS, Role.EDUCATION]),
  //   // undefined,
  //   // onClickFunc,
  //   // () => {
  //   //   console.error('+++ GLOSSARY onClick() +++ context:', 'context');
  //   // }
];

export const pagePatients = [
  new Page(
    <FormattedMessage id="page.patients.public" defaultMessage="Public patients" />,
    <PatientsSharedIcon  color="primary" />,
      `/patients?patientType=${PatientType.PUBLIC}`,
    Profile,
    [Role.ADMIN, Role.EXPERT]),
  new Page(
    <FormattedMessage id="page.patients.education" defaultMessage="Education" />,
    <PatientsSharedIcon  color="primary" />,
      `/patients?patientType=${PatientType.EDUCATION}`,
    Profile,
    [Role.ADMIN, Role.EDUCATION]),
  new Page(
      <FormattedMessage id="page.patients.private" defaultMessage="Patients" />,
      <PatientsIcon color="primary" />,
      `/patients?patientType=${PatientType.PRIVATE}`,
      Profile,
      [Role.ADMIN, Role.EXPERT, Role.EDUCATION, Role.DEMO, Role.USER, Role.RESEARCH_SAMARA]),
];
