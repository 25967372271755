import React from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import {injectIntl} from 'react-intl';
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import { withApollo } from '@apollo/client/react/hoc';
import {loadSearch} from "../../query/drugs";
import mainStyles from "../../styles/mainStyles";
import debounce from "@material-ui/core/utils/debounce";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import grey from "@material-ui/core/colors/grey";
import {blue} from "@material-ui/core/colors";
import {newAnyDataRef} from "../../utils";
import type {Tag} from "../../const";

const styles = theme => ({
    ...mainStyles(theme),
    searchBox: {
        marginTop: theme.spacing(2),
    },
    popper: {
        boxShadow: theme.shadows[2],
    },
    tag: {
        // marginTop: 3,
        // height: 20,
        // padding: '.15em 4px',
        fontWeight: 500,
        // lineHeight: '15px',
        // borderRadius: 2,
        // backgroundColor: green[500],
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: blue[50],
        // color: '#FFFFFF',
        color: theme.palette.primary.main,

        '& .MuiChip-deleteIcon': {
            color: theme.palette.primary.main,
            '&:hover': {
                opacity: 0.7,
            },
        }
    }
});

const filter = createFilterOptions();

class PatientTags extends React.Component {

    allTags = [
        {
            id: 1,
            name: 'name-1',
        },
        {
            id: 2,
            name: 'name-2',
        },
        {
            id: 3,
            name: 'name-3',
        },
    ];

    abortController = new AbortController();

    state = {
        showHint: true,
        searchText: '',
        autocompleteData: this.allTags,
        searchAutocompleteData: [],
        loading: false,
        selectedTags: [],
        selectDosage: false,
        isOpen: false,
        selectedDrugsValues: [],
        selectedDrugsSearchText: '',
    };

    autocompleteRef;

    constructor(props) {
        super(props);

        this.state = {
            showHint: true,
            searchText: '',
            autocompleteData: this.allTags,
            searchAutocompleteData: [],
            loading: false,
            selectedTags: props.tags,
            selectDosage: false,
            isOpen: false,
            selectedDrugsValues: [],
            selectedDrugsSearchText: '',
        };

        this.autocompleteRef = React.createRef();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
/*
        if (
            (this.props.values && prevState.values && this.props.values.length !== prevState.values.length)
            || (this.props.values && !prevState.values)
            || (!this.props.values && prevState.values)
        ) {
            this.componentDidMount();
        }
*/
    }

    componentDidMount() {
        const {tags} = this.props;
        this.setState({
            showHint: true,
            searchText: '',
            // autocompleteData: [],
            searchAutocompleteData: [],
            loading: false,
            isOpen: false,
            selectedTags: tags,
            selectedDrugsValues: [],
            selectedDrugsSearchText: '',
        });
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    debouncedSearch = debounce((searchString) => {
        this.makeSearch(searchString);
    }, 500);

    getOptionSelected = (option, value): boolean => {
        return (value || {}).id === (option || {}).id;
    };

    onInputChange = (event, searchString) => {
        // console.error('+++ onInputChange() +++ event:', event);
        // console.error('+++ onInputChange() +++ searchString:', searchString);
        const {
            autocompleteData,
            selectDosage,
        } = this.state;
        if (
            // true || (!selectDosage && event.type === 'change')
            (event || {}).type === 'change'
        ) {
            this.setState({
                // autocompleteData: searchString && searchString.length >= 3 ? (!selectDosage ? autocompleteData : []) : [],
                searchText: searchString,
                showHint: searchString.length < 3,
                selectDosage: !selectDosage ? selectDosage : !selectDosage,
                isOpen: true,
            }, () => {
                const {searchText} = this.state;
                // if (searchText.length >= 3) {
                //     this.debouncedSearch(searchText);
                // } else {
                // }
            });
        } else {
            this.setState({
                // autocompleteData: [],
                searchText: searchString,
                showHint: true,
                isOpen: true,
            });
        }
    };

    makeSearch = (value) => {
        const {client} = this.props;
        const {selectDosage} = this.state;
        const {signal} = this.abortController;

        if (!selectDosage) {
            this.setState({
                loading: true,
            }, () => {
                loadSearch(client, signal, value)
                    .then((data) => {
                        const {searchText} = this.state;
                        if (searchText === data.query) {
                            this.setState({
                                // autocompleteData: searchText ? (data.drugs || []).map((drug) => {
                                //     const hasAvailComposites = !!(drug.availComposites || []).length;
                                //     drug.name = drug.drugName;
                                //     drug.id = hasAvailComposites ? drug.drugId : drug.normalCompositeId;
                                //     return drug;
                                // }) : [],
                                isOpen: true,
                                loading: false,
                            });
                        }
                    });
            });
        }
    }

    getOptionLabel = (option: Tag) => {
        let result = option.name;
        return result;
    }

    onChange = (event, value, reason) => {
        // console.error('+++ onChange() +++ 0 reason:', reason);
            const {
                onChange,
            } = this.props;

            const {
                searchText,
                selectDosage,
                autocompleteData,
                selectedTags,
            } = this.state;

            // console.error('+++ onChange() +++ 1 reason:', reason);
            // console.error('+++ onChange() +++ 1 value:', value);
            // console.error('+++ onChange() +++ 1 values:', values);
            // console.error('+++ onChange() +++ 1 selectedDrugsValues:', selectedDrugsValues);
            // console.error('+++ onChange() +++ 1 selectDosage:', selectDosage);

        if (reason === 'select-option') {
            let newValues;
            let findedNewValue;
            let hasAvailComposites = false;
            if (!selectDosage) {
                newValues = value;
                findedNewValue = newValues.find((newValue) => (selectedTags || []).every((value) => value.id !== newValue.id));
                // console.error('++++++++++++++++++++++++++++++ onChange() +++ findedNewValue:', findedNewValue);
                hasAvailComposites = !!((findedNewValue || {}).availComposites || []).length;
                // this.dosages.map((dosage) => dosage.drug = findedNewValue.drugName);
                // console.error('++++++++++++++++++++++++++++++ onChange() +++ hasAvailComposites:', hasAvailComposites);
                // console.error('++++++++++++++++++++++++++++++ onChange() +++ this.dosages:', this.dosages);
            }

            this.setState({
                // values: value,
                // values: selectDosage ? newAnyDataRef(selectedDrugsValues) : values,
                // selectedTags: hasAvailComposites ? selectedTags : value,
                selectedDrugsValues: selectDosage ? [] : newAnyDataRef(value),
                // +++2021.10.20+++
                selectedDrugsSearchText: searchText,
                searchText: findedNewValue ? findedNewValue.drugName : searchText, // +++2021.10.20+++
                // searchText: selectDosage ? '' : searchText,
                // +++2021.10.20+++ searchText: '',
                // autocompleteData: [],
                // autocompleteData: !selectDosage ? this.dosages : [],
                // autocompleteData: !selectDosage ? ((findedNewValue || {}).availComposites || []) : [],
                searchAutocompleteData: !selectDosage ? autocompleteData : [],
                // selectDosage: !selectDosage,
                selectDosage: hasAvailComposites,
                // isOpen: !selectDosage,
            }, () => {
                const {
                    selectDosage,
                } = this.state;
                // console.error('+++ onChange() +++ 2 value:', value);
                // console.error('+++ onChange() +++ 2 values:', values);
                // console.error('+++ onChange() +++ 2 selectedDrugsValues:', selectedDrugsValues);
                // console.error('+++ onChange() +++ 2 selectDosage:', selectDosage);

                if (!selectDosage || !hasAvailComposites) {
                    !!onChange && onChange(value);
                }

            });
        } else {
            // console.error('+++ NOT select-option +++');
            this.setState({
                // values: value,
                selectedDrugsValues: [],
                selectedDrugsSearchText: '',
                searchText: '',
                // autocompleteData: [],
                searchAutocompleteData: [],
                selectDosage: false,
                // isOpen: false,
            }, () => {
                !!onChange && onChange(value);
            });
        }
    }

    filterOptions2 = createFilterOptions({
        matchFrom: 'any',
        stringify: option => this.getOptionLabel(option),
    });

    filterOptions = (options, { inputValue }) => options;

    render() {
        const {
            classes,
            intl,
            // values,
            onChange,
            isPrescriptionReadonly,
        } = this.props;
        const {
            selectedTags,
            autocompleteData,
            searchAutocompleteData,
            showHint,
            searchText,
            selectedDrugsSearchText,
            loading,
            isOpen,
        } = this.state;

        // console.error('+++ render() +++ searchText:', searchText);
        // console.error('+++ render() +++ selectDosage:', selectDosage);
        // console.error('+++ render() +++ autocompleteData:', autocompleteData);
        // console.error('+++ render() +++ values:', values);
        // console.error('+++ render() +++ selectedDrugsValues:', selectedDrugsValues);
        // console.error('+++ render() +++ selectedTags:', selectedTags);


        return (
            <Autocomplete
                ref={this.autocompleteRef}
                disabled={isPrescriptionReadonly}
                className={classes.searchBox}
                classes={{
                    // paper: classes.popper,
                    tag: classes.tag,
                }}
                multiple
                id="tags-standard"
                options={autocompleteData}
                // defaultValue={[top100Films[13]]}
                onChange={(event, newValues) => {
                    // console.error('+++ onChange() +++ newValues:', newAnyDataRef(newValues || []));
                    // const findedNewValue = (newValues || []).find((newValue) => (selectedTags || []).every((value) => value.id !== newValue.inputValue));
                    const findedNewValue = (newValues || []).find((newValue) => !!newValue.inputValue);
                    // console.error('+++ onChange() +++ findedNewValue:', findedNewValue);

                    let newSelectedTags;

                    // this.onChange
                    if (typeof newValues === 'string') {
                        newSelectedTags = [...selectedTags, ...[{id: newValues, name: newValues}]];
                        this.setState({
                            selectedTags: newSelectedTags,
                        });
                    // } else if (newValue && newValue.inputValue) {
                    } else if (!!findedNewValue) {
                        // Create a new value from the user input
                        newSelectedTags = [...selectedTags, ...[{id: findedNewValue.inputValue, name: findedNewValue.inputValue}]];
                        this.setState({
                            selectedTags: newSelectedTags,
                        });
                    } else {
                        newSelectedTags = newValues;
                        this.setState({
                            selectedTags: newValues,
                        });
                    }

                    onChange(newSelectedTags);
                }}
                getOptionSelected={(option, value) => this.getOptionSelected(option, value)}
                filterSelectedOptions
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `${intl.formatMessage({id: 'label.add', defaultMessage: 'Add'})} "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                // getOptionLabel={(option) => this.getOptionLabel(option)}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="standard"
                        label={intl.formatMessage({
                            id: 'message.autocomplete.label.patient_tags',
                            defaultMessage: 'Patient tags',
                        })
                        }
                    />
                )}
                // renderOption={(option) => option.name}
                renderOption={(option, {inputValue}) => {
                    const name = this.getOptionLabel(option);
                    const matches = match(name, inputValue);
                    const parts = parse(name, matches);

                    return (
                        matches && matches.length > 0 && parts && parts.length > 0 ?
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={part.highlight ? {fontWeight: 600, color: grey[800]} : {}}>
                                    {part.text}
                                </span>
                                ))}
                            </div>
                            :
                            <div>
                                <span>
                                    {name}
                                </span>
                            </div>
                    );
                }}
                value={selectedTags}
            />
/*
            <Autocomplete
                ref={this.autocompleteRef}
                disabled={isPrescriptionReadonly}
                className={classes.searchBox}
                classes={{
                    // paper: classes.popper,
                    tag: classes.tag,
                }}
                multiple
                // loading={loading}
                // noOptionsText={
                //     showHint ? intl.formatMessage({
                //         id: 'message.autocomplete.tags.text_field_hint',
                //         defaultMessage: 'Enter the name of the tag',
                //     })
                //     :
                //     intl.formatMessage({
                //         id: 'message.autocomplete.tags.no_options',
                //         defaultMessage: 'No tags',
                //     })
                // }
                clearText={
                    intl.formatMessage({
                        id: 'message.autocomplete.clear',
                        defaultMessage: 'Clear',
                    })
                }
                closeText={
                    intl.formatMessage({
                        id: 'message.autocomplete.close',
                        defaultMessage: 'Close',
                    })
                }
                openText={
                    intl.formatMessage({
                        id: 'message.autocomplete.open',
                        defaultMessage: 'Open',
                    })
                }
                // loadingText={
                //     intl.formatMessage({
                //         id: 'message.autocomplete.loading',
                //         defaultMessage: 'Loading...',
                //     })
                // }
                options={autocompleteData}
                getOptionLabel={(option) => this.getOptionLabel(option)}
                renderOption={(option, {inputValue}) => {
                    const name = this.getOptionLabel(option);
                    const matches = match(name, inputValue);
                    const parts = parse(name, matches);

                    return (
                        matches && matches.length > 0 && parts && parts.length > 0 ?
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={part.highlight ? {fontWeight: 600, color: grey[800]} : {}}>
                                    {part.text}
                                </span>
                                ))}
                            </div>
                            :
                            <div>
                                <span>
                                    {name}
                                </span>
                            </div>
                    );
                }}
                filterSelectedOptions
                filterOptions={this.filterOptions}
                getOptionSelected={(option, value) => this.getOptionSelected(option, value)}
                value={values}
                open={isOpen}
                // disableCloseOnSelect={!selectDosage}
                openOnFocus={true}
                onFocus={() => {
                    this.setState({
                        isOpen: true,
                    });
                }}
                onBlur={() => {
                    this.setState({
                        isOpen: false,
                    });
                }}
                onChange={(event, newValue, reason) => this.onChange(event, newValue, reason)}
                onClose={(event, reason: string) => {
                    // const {searchText} = this.state;
                    // console.error('+++ onClose() +++ 0 reason:', reason);
                    // console.error('+++ onClose() +++ searchText:', searchText);
                    // console.error('+++ onClose() +++ selectDosage:', selectDosage);
                    // console.error('+++ onClose() +++ autocompleteData:', autocompleteData);
                    if (reason === 'toggleInput' || reason === 'blur') {
                        this.setState({
                            selectedDrugsValues: [],
                            selectedDrugsSearchText: '',
                            searchText: '',
                            // autocompleteData: [],
                            selectDosage: false,
                        });
                    }
                    if (reason === 'escape') {
                        event.preventDefault();
                        this.setState({
                            selectedDrugsValues: [],
                            selectedDrugsSearchText: '',
                            searchText: '',
                            // autocompleteData: selectedDrugsSearchText ? searchAutocompleteData : [],
                            selectDosage: false,
                        }, () => {
                            // console.error('=========================== this.autocompleteRef:', this.autocompleteRef);
                            // console.error('=========================== this.autocompleteRef.current:', this.autocompleteRef.current);
                            // this.autocompleteRef.current.open();
                        });
                    }

                    // if (reason === 'toggleInput' && searchText === '') {
                    //     this.setState({
                    //         autocompleteData: [],
                    //     });
                    //     console.error('+++ onClose() +++ 1 reason:', reason);
                    //     // event.preventDefault();
                    //     // return;
                    // }
                    // if (reason === 'select-option' && !selectDosage) {
                    //     this.setState({
                    //         searchText: '',
                    //         autocompleteData: this.dosages,
                    //         selectDosage: true,
                    //         isOpen: !selectDosage,
                    //     });
                    // } else {
                    //     if (reason !== 'select-option') {
                    //         this.setState({
                    //             searchText: '',
                    //             autocompleteData: [],
                    //             selectDosage: false,
                    //             isOpen: !selectDosage,
                    //         });
                    //     }
                    // }
                }}
                inputValue={searchText}
                onInputChange={(event, newInputValue) => this.onInputChange(event, newInputValue)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        // onChange={this.onInputChange}
                        variant="standard"
                        label={intl.formatMessage({
                                id: 'message.autocomplete.label.drugs',
                                defaultMessage: 'Drugs',
                            })
                        }
                    />
                )}
            />
*/
        );
    }
}

PatientTags.propTypes = {
    intl: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    isPrescriptionReadonly: PropTypes.bool,
};

// export default injectIntl(AddDrugDialog);
export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(PatientTags);
