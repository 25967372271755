export const INSTRUCTIONS = {
    '/baseline': {
        pages: [
            '/instructions/help_001.html',
            '/instructions/help_002.html',
            '/instructions/help_003.html',
            '/instructions/help_004.html',
            '/instructions/help_005.html',
            '/instructions/help_006.html',
            '/instructions/help_007.html',
            '/instructions/help_008.html',
            '/instructions/help_009.html',
            '/instructions/help_010.html',
            '/instructions/help_011.html',
            '/instructions/help_012.html',
            '/instructions/help_013.html',
            '/instructions/help_014.html',
            '/instructions/help_015.html',
            '/instructions/help_016.html',
            '/instructions/help_017.html',
            '/instructions/help_018.html',
            '/instructions/help_019.html',
            '/instructions/help_020.html',
            '/instructions/help_021.html',
            '/instructions/help_022.html',
        ],
    },
};
