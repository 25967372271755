import React from "react";
import {alpha, withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
    Button,
    ButtonGroup,
    TextField,
    MenuItem, Select, Tooltip, Checkbox,
} from "@material-ui/core";
import {globalTheme, MessageType, ParameterInputSourceEnum, ParameterType} from "../../const";
import type {
    BaselineParameterDto,
    NumberFormatCustomProps, ParameterOptDto,
    ValueNumberType,
    ValueOptionType
} from "../../const";
import NumberFormat from 'react-number-format';
import type NumberFormatValues from 'react-number-format/typings/number_format';
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    getDateForDatePicker,
    getDateForTooltip,
    getDateFromDatePicker, getDateLocale, getParameterOpt, getValueDateToInput, getValueDateToStringFormat,
    inRange, isNoDataAndAllowedNoData,
    isNumber,
    newAnyDataRef, updateParameterValues
} from "../../utils";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ServiceConfirmPopper from "../button/ServiceConfirmPopper";
import ClearIcon from "@material-ui/icons/Clear";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import EventIcon from '@material-ui/icons/Event';
import {injectIntl} from "react-intl";
import InputBase from '@material-ui/core/InputBase';
import EditIcon from '@material-ui/icons/Edit';
import PanToolIcon from '@material-ui/icons/PanTool';
import DescriptionIcon from '@material-ui/icons/Description';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';

const styles = theme => ({
    ...mainStyles(theme),
    isDefault: {
        // color: grey[500],
        color: 'black',
        opacity: '0.54',
    },
    isNonDefaultSelect: {
        color: theme.palette.primary.main,
    },
    isDefaultButons: {
        backgroundColor: grey[400],
        '&:hover': {
            backgroundColor: grey[600],
        },
    },
    isDefaultSelect: {
        // color: grey[500],
        '&select': {
            color: 'red',
        }
    },
    selectBorder: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        },
        '&:hover': {
            backgroundColor: `${alpha(theme.palette.primary.main, 0.03)} !important`,
        },
    },
    allowedNoDataWrapper: {
        minWidth: '42px',
        minHeight: '42px',
    }
});

const VarButtonEditor = withStyles(theme => ({
    root: {
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: 'none',
        },
        '& > .MuiButton-label': {
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            display: 'inline-block',
        },
    },
}))(Button);

const VarTextFieldEditor = withStyles(theme => ({
    root: {
        // minWidth: 200,
        width: '100%',
        textAlign: 'left',
        // color: theme.palette.primary.main,

        '& fieldset': {
            borderColor: `${theme.palette.primary.main} !important`,
            borderWidth: '1px !important',
            borderStyle: 'solid !important',
        },
        '&:hover': {
            '& fieldset': {
                backgroundColor: `${alpha(theme.palette.primary.main, 0.03)}`,
                borderColor: `${theme.palette.primary.main} !important`,
                borderWidth: '1px !important',
                borderStyle: 'solid !important',
            }
        },
    },
}))(TextField);

const VarTextFieldEditorSelect = withStyles(theme => ({
/*
    root: {
        // minWidth: 200,
        width: '100%',
        textAlign: 'left',

        // '& .MuiOutlinedInput-root': {
        '& fieldset': {
            // borderColor: `${theme.palette.primary.main} !important`,
            borderColor: 'red !important',
            borderWidth: '1px !important',
            borderStyle: 'solid !important',
        },
        '&:hover': {
            '& fieldset': {
                backgroundColor: `${alpha(theme.palette.primary.main, 0.03)}`,
                // borderColor: `${theme.palette.primary.main} !important`,
                borderColor: 'red !important',
                borderWidth: '1px !important',
                borderStyle: 'solid !important',
            }
        },

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },

        "& .MuiInputBase-root": {
            "& fieldset": {
                borderColor: "blue"
            },
            "&.Mui-focused fieldset": {
                borderColor: "red"
            }
        }
    },
    '.MuiOutlinedInput-root': {
        '& fieldset': {
            // borderColor: `${theme.palette.primary.main} !important`,
            borderColor: 'red !important',
            borderWidth: '1px !important',
            borderStyle: 'solid !important',
        },
    },
    'fieldset': {
        // borderColor: `${theme.palette.primary.main} !important`,
        borderColor: 'red !important',
        borderWidth: '1px !important',
        borderStyle: 'solid !important',
    },

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: 'red !important',
    },
*/

}))(Select);

class ParameterValueView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            eventValue: null,
            anchorEl: null,
            serviceMessage: {},
            parameterState: props.parameter,
            showSelectDateDialog: false,
        };

        this.inputRef = React.createRef();
        this.inputRefNumberFormat = React.createRef();
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        // console.error('+++ this.state.parameterState:', this.state.parameterState);
        // console.error('+++ nextProps.parameter:', nextProps.parameter);
        if (JSON.stringify(this.state.parameterState) !== JSON.stringify(nextProps.parameter)) {
            this.setState({parameterState: nextProps.parameter});
        }
    }

    onClickFocus = (event) => {
        if (this.state.parameterState.type !== ParameterType.DATE && !!this.inputRefNumberFormat) {
            this.inputRefNumberFormat.focus();
        }
    };

    onUpdateDateChange = (updateDate) => {
        const {
            onUpdateDateChange,
            readonly,
        } = this.props;
        const {
            parameterState,
        } = this.state;

        if (!!onUpdateDateChange && !readonly && parameterState.enable) {
            parameterState.modified = true;
            onUpdateDateChange(updateDate);
        }
    }

    onValueChange = (valueId, onClear = false, noData: boolean) => (event) => {
        const {
            onValueChange,
            readonly,
            parameter,
        } = this.props;
        const {
            parameterState,
        } = this.state;
        let value = (event?.target?.value === null || event?.target?.value === undefined) ? '' : event?.target?.value;
        const _onClear = (onClear || !!event?.target?.onClear);
        const _changeModified = (!!event?.target?.changeModified || _onClear);

        // console.error('+++ onValueChange() +++ parameterState:', parameterState);
        // console.error('+++ onValueChange() +++ parameterState.type:', parameterState.type);
        // console.error('+++ onValueChange() +++ event:', event);
        // console.error('+++ onValueChange() +++ valueId:', valueId);
        // console.error('+++ onValueChange() +++ value:', value);

        const _noData = noData !== undefined ? noData : (_onClear || !value);
        // const _changeNoData = noData !== undefined;
        // console.error('+++ onValueChange() +++ noData:', noData);
        // console.error('+++ onValueChange() +++ _noData:', _noData);
        parameter.noData = _noData;
        parameterState.noData = _noData;

        if (parameterState.type === ParameterType.DATE) {
            if (_onClear) {
                value = '';
            } else if ((value || '').length === 4 && !(value || '').indexOf('.') !== -1) {
                // console.error('+++ for date BEFORE value:', value);
                const newValue = '01.01.' + value;
                value = newValue;
                // console.error('+++ for date RESULT value:', value);
                // return;
            }
/*
            } else if ((value || '').split('.').length !== 3) {
                // console.error('+++ for date BEFORE value:', value);
                const newValue = value.slice(0, 2).padEnd(2, 'Д') + '.' + value.slice(2, 4).padEnd(2, 'М') + '.' + value.slice(4, 8).padEnd(4, 'Г');
                value = (_onClear || newValue === 'ДД.ММ.ГГГГ') ? '' : newValue;
                // console.error('+++ for date RESULT value:', value);
                // return;
            }
*/
        }

        // console.error('+++ parameterState:', parameterState);
        // console.error('+++ value:', value);
        if (!!onValueChange && !readonly && parameterState.enable) {
            parameterState.modified = (_changeModified || parameterState.modified);
            // onValueChange(valueId ? valueId : value, _onClear, _changeModified, _changeNoData);
            onValueChange(valueId ? valueId : value, _onClear, _changeModified);
        }
    };

    handleClickClearInput = (parameter, inputRef) => (event) => {
        const {readonly} = this.props;
        // console.error('+++ handleClickClearInput() +++ parameter:', newAnyDataRef(parameter));
        // console.error('+++ handleClickClearInput() +++ parameter.noData:', newAnyDataRef(parameter.noData));
        event.preventDefault();
        if (!readonly && !isNoDataAndAllowedNoData(parameter) && parameter.enable) {
            this.inputRefNumberFormat.focus();

            // console.error('+++ handleClickClearInput() +++ this.inputRefNumberFormat.value:', newAnyDataRef(this.inputRefNumberFormat.value));
            // if (this.inputRefNumberFormat.value) {
                this.inputRefNumberFormat.value = '';
                this.onValueChange('', true)(event);
            // }
        }
    };

    isAllowed = (values: NumberFormatValues, field: BaselineParameterDto, item: ValueNumberType) => {
        const {formattedValue, value} = values;
        if (!value) {
            return formattedValue === '';
        } else {
            return field.type !== ParameterType.DATE ? isNumber(value) : true;
        }
    }

    numberFormatCustom = (props: NumberFormatCustomProps) => {
        const {inputRef, onChange, field, item, style, ...other} = props;
        // console.error('+++ numberFormatCustom() +++ field:', field);
        // console.error('+++ numberFormatCustom() +++ JSON.parse((field.values || [])[0] || \'{}\').value:', JSON.parse((field.values || [])[0] || '{}').value);

        const _style = newAnyDataRef(style);
        if (!field.modified) {
            // _style.color = grey[500];
            _style.color = 'black';
            _style.opacity = '0.54';
        }
        if (!inRange(item, field)) {
            _style.color = red[500];
        }

        if (field.type === ParameterType.DATE) {
            // _style.margin = '0';
            _style.paddingTop = '12.5px';
            _style.paddingBottom = '20px';
            _style.textAlign = 'right';
        }

        if (field.type === ParameterType.DATE) {
            return <InputBase
                {...other}
                style={_style}
                // variant="outlined"
                inputRef={(el) => (this.inputRefNumberFormat = el)}
                onChange={(event) => {
                    event.target.value = event.target.value.replace(/[^0-9\.]/gi, '');
                    field.modified = true;
                    // console.error('+++ numberFormatCustom() onChange() +++ event:', event);
                    // console.error('+++ numberFormatCustom() onChange() +++ event?.target?.value:', event?.target?.value);
                    // console.error('+++ numberFormatCustom() onChange() +++ field:', field);
                    onChange({
                        target: {
                            name: props.name,
                            value: event.target.value,
                            onClear: false,
                            changeModified: true,
                        },
                    });
                }}
                inputProps={{
                    style: { textAlign: "right" },
                    maxLength: 10,
                }}
                placeholder={'дд.мм.гггг'}
            />;
        }

        return (
            <NumberFormat
                {...other}
                ref={this.inputRef}
                style={_style}
                getInputRef={(el) => (this.inputRefNumberFormat = el)}
                // TODO: закоментил на время теста, оставил только onBlur
                onValueChange={(values) => {
                    return; // меняем значение только onBlur
/*
                    field.modified = true;
                    if (!field.influence) {
                        onChange({
                            target: {
                                name: props.name,
                                value: (values.value === null || values.value === undefined) ? '' : values.value,
                                onClear: false,
                                changeModified: true,
                            },
                        });
                    } else {
                        onChange({
                            target: {
                                name: props.name,
                                value: (values.value === null || values.value === undefined) ? '' : values.value,
                                onClear: false,
                                changeModified: true,
                            },
                        });
                    }
*/
                }}
                onBlur={(values) => {
                    // console.error('+++ numberFormatCustom() onBlur() +++ field:', field);
                    // console.error('+++ numberFormatCustom() onBlur() +++ values.target.value || \'\':', values.target.value || '');
                    // console.error('+++ numberFormatCustom() onBlur() +++ values.target.value || \'\':', values.target.value || '');
                    // console.error('+++ numberFormatCustom() onBlur() +++ \'\' + JSON.parse((field.values || [])[0] || \'{}\').value || \'\':', '' + JSON.parse((field.values || [])[0] || '{}').value || '');
                    // console.error('+++ numberFormatCustom() onBlur() +++ (\'\' + values.target.value) !== (\'\' + JSON.parse((field.values || [])[0] || \'{}\').value || \'\'):', ('' + values.target.value) !== ('' + JSON.parse((field.values || [])[0] || '{}').value || ''));
                    // this.inputRefNumberFormat?.blur();
                    // this.inputRef?.blur();
                    // field.modified = true;
                    onChange({
                        target: {
                            name: props.name,
                            value: (values.target.value === null || values.target.value === undefined) ? '' : values.target.value,
                            onClear: false,
                            changeModified: ('' + values.target.value) !== ('' + JSON.parse((field.values || [])[0] || '{}').value), // || ''
                        },
                    });
                }}
                isAllowed={(values) => {
                    return this.isAllowed(values, field, item);
                }}
                decimalSeparator={[ParameterType.DECIMAL].includes(field.type) ? '.' : false}
                isNumericString={true}
                // isNumericString={field.type !== ParameterType.DATE ? true : false}
                allowNegative={false}
                // allowLeadingZeros={false}
                // prefix="$"
                // format={field.type === ParameterType.DATE ? '##.##.####' : undefined}
                // removeFormatting={}
                // placeholder={field.type === ParameterType.DATE ? 'дд.мм.гггг' : undefined}
                // displayType={field.type === ParameterType.DATE ? 'text' : undefined}
                // type={field.type === ParameterType.DATE ? 'text' : undefined}

                // mask={field.type === ParameterType.DATE ? ['Д', 'Д', 'М', 'М', 'Г', 'Г', 'Г', 'Г'] : undefined}
            />
        );
    }

    setOpen = (open: boolean, callback) => {
        this.setState({
            open: open
        }, () => {
            if (!!callback) {
                callback();
            }
        });
    }

/*
    handleClick = (valueId, item) => (event) => {
        const {
            parameter,
        } = this.props;

        const eventItemValue = event.target.value;

        if (!item) {
            const items: ValueOptionType[] = parameter.values
                .filter(value => value)
                .map(value => {
                    return JSON.parse(value);
                });
            items.forEach((itemItems) => {
                if (itemItems.valueId === eventItemValue) {
                    item = itemItems;
                }
            });

        }

        const serviceMessageWARNING: ServiceMessage = getServiceMessage((item || {}).messages, MessageType.WARNING);
        // let sm: ServiceMessage;
        this.setState({
            open: false,
            eventValue: valueId || item.valueId,
            anchorEl: event.currentTarget,
            serviceMessage: serviceMessageWARNING,
        }, () => {
            const { serviceMessage } = this.state;
            serviceMessage.showService = true;
            serviceMessage.payload = 'payload';
            serviceMessage.type = MessageType.WARNING;

            if ((serviceMessage || {}).showService) {
                this.setOpen(true);
            } else {
                this.onValueChange(valueId)(event);
            }
        });
    };
*/

    handleConfirm = (confirm: boolean) => (e) => {
        this.handleClickAway(undefined, () => {
            const {eventValue} = this.state;
            if (confirm) {
                this.onValueChange(eventValue)(e);
            }
        });
    };

    handleClickAway = (event, callback) => {
        this.setOpen(false, callback);
    };

    showSelectDate = (value: boolean) => {
        this.setState({
            showSelectDateDialog: value
        });
    };

    setNoData = (event) => {
        // console.error('+++ setNoData() +++ event.target.checked:', event.target.checked);
        // console.error('+++ setNoData() +++ this.state.parameterState:', this.state.parameterState);
        const noData = !event.target.checked;
        const item: ValueNumberType = this.state.parameterState.values
            .filter(value => value)
            .map(value => {
                return JSON.parse(value);
            })[0];
        event.target.value = item.value;
        this.props.parameter.noData = noData;
        this.state.parameterState.noData = noData;

        // this.props.onValueChange(valueId ? valueId : value, _onClear, _changeModified);

        this.onValueChange(undefined, undefined, noData)(event);

        this.setState({
            parameterState: this.state.parameterState
        }, () => {
            // console.error('+++ setNoData() +++ this.state.parameterState:', this.state.parameterState);
            // console.error('+++ setNoData() +++ this.state.parameterState.noData:', this.state.parameterState.noData);
            // this.onValueChange(undefined, undefined, noData)(event);
        });
    };

    hasNoData = (noData: boolean | undefined): boolean => {
        return noData !== undefined;
    };

    getCheckedNoData = (): boolean => {
        const {parameterState, parameterStateOpt} = this.state;
        // console.error('+++ getCheckedNoData() +++ parameterState.noData:', parameterState.noData);
        // console.error('+++ getCheckedNoData() +++ this.hasNoData(parameterState.noData):', this.hasNoData(parameterState.noData));
        let result = false;
        if (this.hasNoData(parameterState.noData)) {
            result = !parameterState.noData;
        } else {
            result = !!parameterStateOpt.defNoData;
        }

        // console.error('+++ getCheckedNoData() +++ result:', result);
        return result;
    };

    getServiceConfirmPopper = (value: ValueOptionType) => {
        const {open, anchorEl, serviceMessage} = this.state;
        return <ServiceConfirmPopper
            open={open}
            anchorEl={anchorEl}
            title={''}
            text={serviceMessage.payload}
            type={MessageType.WARNING}
            handleConfirm={this.handleConfirm}
        />;
    }

    getParameterValue = (item: ValueNumberType, type: ParameterType): string => {
        // return (item?.value === null || item?.value === undefined) ? '' : (type !== ParameterType.DATE ? item?.value : ('' + item?.value).split('.').join(''));
        return (item?.value === null || item?.value === undefined) ? '' : item?.value;
    }

    getSourceDetectionIcon = (sourceDetection: ParameterInputSourceEnum) => {
        let result;
        const style = {marginLeft: '8px'};
        const color = 'primary';
        const fontSize = 'small';

        sourceDetection = sourceDetection || ParameterInputSourceEnum.MANUAL;

        let ICON;

        switch (sourceDetection) {
            case ParameterInputSourceEnum.TEXT:
                // ICON = FindInPageIcon;
                ICON = AssignmentIcon;
                break;
            case ParameterInputSourceEnum.MANUAL:
                // ICON = PanToolIcon;
                ICON = AssignmentIndIcon;
                break;
            case ParameterInputSourceEnum.STRUCTURED:
                // ICON = FlashOnIcon;
                ICON = AssignmentReturnedIcon;
                break;
        }
        result = <ICON style={style} color={color} fontSize={fontSize} />;
        return result;
    }

    getSourceDetectionTooltip = (sourceDetection: ParameterInputSourceEnum): string => {
        const {
            intl,
        } = this.props;
        return intl.formatMessage({id: 'label.ParameterInputSource.' + (sourceDetection || ParameterInputSourceEnum.MANUAL), defaultMessage: '...'});
    }

    render() {
        const { locale } = this.context;
        const {
            // parameter,
            readonly,
            classes,
            intl,
        } = this.props;
        const {
            parameterState,
            showSelectDateDialog,
        } = this.state;
        let content;
        // let contentInfo;
        // const parameterOpt: ParameterOptDto = getParameterOpt(parameterState);
        let isDefault = false;

        // TODO: +++ for testing +++
        if (
            parameterState.id === '295'
            // &&
            // parameterState.type === ParameterType.DATE
        ) {
            // console.error('+++++++++ ParameterValueView render() +++ parameterState:', parameterState);
            // console.error('+++++++++ ParameterValueView render() +++ getDateForDatePicker(parameterState.updateDate):', getDateForDatePicker(parameterState.updateDate));
            // console.error('+++++++++ ParameterValueView render() +++ this.props.parameter:', this.props.parameter);
        }
        // TODO: +++ for testing +++


        let dateLocale = getDateLocale(locale);

        switch (parameterState.type) {
            case ParameterType.OPTION:
                const items: ValueOptionType[] = parameterState.values
                    .filter((value) => value)
                    .map((value) => {
                        return JSON.parse(value);
                    })
                    .sort((v1, v2) => v1.ord - v2.ord);
                const maxLength = Math.max.apply(null, items.map((item) => {
                    // const item = JSON.parse(it);
                    return (item.title || '').length;
                }));

                let selectedItem = items.find(item => {
                    return item.selected;
                });
                // selectedItem = selectedItem ? JSON.parse(selectedItem) : undefined;
                if (!!selectedItem) {
                    isDefault = !(
                        parameterState.modified
                        // ||
                        // ((parameterOpt || {}).def && (('' + (parameterOpt || {}).def) !== ('' + (selectedItem || {}).valueId)))
                    );

                    // const serviceMessageInfo: ServiceMessage = getServiceMessage(selectedItem.messages, MessageType.INFO);
                    // if (serviceMessageInfo.showService) {
                    //     contentInfo = <Typography color={'primary'}>
                    //         {serviceMessageInfo.payload}
                    //     </Typography>;
                    // }
                }

                if (items.length > 3 || maxLength > 14) {
                    content =
                    //     <ClickAwayListener
                    //     onClickAway={this.handleClickAway}
                    // >
                    //     <Box>
                            <VarTextFieldEditorSelect
                                // error={(showError && required && !selected)}
                                color={'primary'}
                                className={classes.selectBorder}
                                fullWidth
                                // onChange={this.onValueChange()}
                                // onChange={(e) => {
                                //     e.preventDefault();
                                //     this.handleClick(null, null)(e);
                                // }}
                                value={(selectedItem || {}).valueId || ''}
                                variant='outlined'
                                renderValue={(selected) => <span className={isDefault ? classes.isDefault : classes.isNonDefaultSelect}>{(selectedItem || {}).name || ''}</span>}
                            >
                                {items.map(item => {
                                    // const item = JSON.parse(it);
                                    return (
                                        <MenuItem key={item.valueId}
                                                  value={item.valueId}
                                                  onClick={() => this.onValueChange()({target: {value: item.valueId}})}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                                {/*<Select*/}
                                {/*></Select>*/}
                            </VarTextFieldEditorSelect>;
                    //         {this.getServiceConfirmPopper()}
                    //     </Box>
                    // </ClickAwayListener>;
                } else {
                    content =
                    //     <ClickAwayListener
                    //     onClickAway={this.handleClickAway}
                    // >
                    //     <Box>
                        <ButtonGroup fullWidth color={'primary'}>
                        {items.map(item => {
                            // const item = JSON.parse(it);
                            return (
                                <VarButtonEditor
                                    key={item.valueId}
                                    variant={item.selected ? 'contained' : 'outlined'}
                                    onClick={this.onValueChange(item.valueId)}
                                    className={(isDefault && item.selected) ? classes.isDefaultButons : ''}
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     this.handleClick(item.valueId, item)(e);
                                    // }}
                                >
                                    {item.name}
                                </VarButtonEditor>
                            );
                        })}
                        </ButtonGroup>;
                    //     {this.getServiceConfirmPopper()}
                    //     </Box>
                    // </ClickAwayListener>;
                }
                break;

            case ParameterType.INT:
            case ParameterType.DECIMAL:
            case ParameterType.DATE:
                const item: ValueNumberType = parameterState.values
                    .filter(value => value)
                    .map(value => {
                        return JSON.parse(value);
                    })[0];

                isDefault = !(
                    parameterState.modified
                    // ||
                    // ((parameterOpt || {}).def && (('' + (parameterOpt || {}).def) !== ('' + (selectedItem || {}).valueId)))
                );

                const style = {
                    textAlign: 'right',
                    width: '90%',
                };
                if (isDefault) {
                    // style.color = grey[500];
                    style.color = 'black';
                    style.opacity = '0.54';
                } else {
                    style.color = globalTheme.palette.primary.main;
                }
                if (!inRange(item, parameterState)) {
                    style.color = red[500];
                }

                if ([ParameterType.INT, ParameterType.DECIMAL, ParameterType.DATE].includes(parameterState.type)) {
                    content = <VarTextFieldEditor
                        onClick={this.onClickFocus}
                        color={'primary'}
                        // error={(showError && required && !selected)}
                        // error={this.inRange(item)}
                        // ref={inputRef => (this.inputRef = inputRef)}
                        // ref={this.inputRef}
                        disabled={readonly || isNoDataAndAllowedNoData(parameterState) || !parameterState.enable}
                        fullWidth
                        name={parameterState.type}
                        // type={'number'}
                        onChange={(e) => {
                            // console.error('+++++++++ ParameterValueView render() onChange() +++ parameterState:', parameterState);
                            // this.onValueChange()(e);
                            if (parameterState.type !== ParameterType.DATE) {
                                this.onValueChange()(e);
                            } else {
                                if (parameterState.type === ParameterType.DATE) {
                                    // console.error('+++++++++ ParameterValueView render() onChange() if (parameterState.type === ParameterType.DATE) +++ parameterState:', parameterState);
                                    // console.error('+++++++++ ParameterValueView render() onChange() if (parameterState.type === ParameterType.DATE) +++ e?.target?.value:', e?.target?.value);
                                    let value = (e?.target?.value === null || e?.target?.value === undefined) ? '' : e?.target?.value;
                                    updateParameterValues(parameterState, value);
                                    // console.error('+++++++++ ParameterValueView render() onChange() if (parameterState.type === ParameterType.DATE) +++ parameterState.values:', parameterState.values);

                                    this.setState({
                                        parameterState: parameterState,
                                    });
                                }
                            }
                        }}
                        onBlur={(e) => {
                            if (parameterState.type === ParameterType.DATE) {
                                // console.error('+++ for date e:', e);
                                if (!!e?.target?.value) {
                                    let value = e?.target?.value;
                                    if ((value || '').length === 4 && !(value || '').indexOf('.') !== -1) {
                                        // console.error('+++ for date BEFORE value:', value);
                                        const newValue = '01.01.' + value;
                                        value = newValue;
                                        e.target.value = newValue;
                                        // console.error('+++ for date RESULT value:', value);
                                        // return;
                                        updateParameterValues(parameterState, value);
                                    }
                                }
                                this.onValueChange()(e);
                            }
                            // e.preventDefault();
                            // this.onValueChange(undefined, true)(e);
                            //     this.inputRefNumberFormat?.blur();
                            //     this.inputRef?.blur();
                        }}
                        // value={(item?.value === null || item?.value === undefined) ? '' : item?.value}
                        value={this.getParameterValue(item, parameterState.type)}
                        InputProps={{
                            // inputComponent: parameterState.type !== ParameterType.DATE ? this.numberFormatCustom : undefined,
                            inputComponent: this.numberFormatCustom,
                            inputProps: {
                                field: parameterState,
                                item: item,
                                style: style,
                            },
                            endAdornment:
                            // parameter?.units ? <InputAdornment position="end">
                                <InputAdornment
                                    position="end"
                                    style={{height: '100%'}}
                                    onClick={this.onClickFocus}
                                >
                                    <Typography
                                        color={"textSecondary"}
                                        onClick={this.onClickFocus}
                                    >
                                        {parameterState?.units || ''}
                                    </Typography>
                                    <ClearIcon
                                        style={{marginLeft: '8px', cursor: 'pointer'}}
                                        onClick={this.handleClickClearInput(parameterState)}
                                        // onMouseDown={this.handleClickClearInput(parameter)}
                                        fontSize={'small'}
                                        color={'primary'}
                                    />
                                </InputAdornment>
                            // : undefined
                            ,
                        }}
                        variant='outlined'
                    >
                    </VarTextFieldEditor>;
                }
/*
                if ([ParameterType.DATE].includes(parameterState.type)) {
                    content = <VarTextFieldEditor
                        onClick={this.onClickFocus}
                        color={'primary'}
                        // error={(showError && required && !selected)}
                        // error={this.inRange(item)}
                        // ref={inputRef => (this.inputRef = inputRef)}
                        // ref={this.inputRef}
                        disabled={readonly}
                        fullWidth
                        name={parameterState.type}
                        // type={'number'}
                        onChange={(e) => {
                            this.onValueChange()(e);
                        }}
                        onBlur={(e) => {
                            // e.preventDefault();
                            // this.onValueChange(undefined, true)(e);
                            //     this.inputRefNumberFormat?.blur();
                            //     this.inputRef?.blur();
                        }}
                        value={(item?.value === null || item?.value === undefined) ? '' : item?.value}
                        InputProps={{
                            inputComponent: this.numberFormatCustom,
                            inputProps: {
                                field: parameterState,
                                item: item,
                                style: style,
                            },
                            endAdornment:
                            // parameter?.units ? <InputAdornment position="end">
                                <InputAdornment
                                    position="end"
                                    style={{height: '100%'}}
                                    onClick={this.onClickFocus}
                                >
                                    <Typography
                                        color={"textSecondary"}
                                        onClick={this.onClickFocus}
                                    >
                                        {parameterState?.units || ''}
                                    </Typography>
                                    <ClearIcon
                                        style={{marginLeft: '8px', cursor: 'pointer'}}
                                        onClick={this.handleClickClearInput(parameterState)}
                                        // onMouseDown={this.handleClickClearInput(parameter)}
                                        fontSize={'small'}
                                        color={'primary'}
                                    />
                                </InputAdornment>
                            // : undefined
                            ,
                        }}
                        variant='outlined'
                    >
                    </VarTextFieldEditor>;
                }
*/
                break;

            default:
                break;
        }

        const parameterStateOpt: ParameterOptDto = getParameterOpt(parameterState);

        // return (content + contentInfo);
        return (
            <Box style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                <Box className={classes.allowedNoDataWrapper}>
                    {parameterStateOpt.allowedNoData &&
                        <Checkbox checked={this.getCheckedNoData()}
                                  onClick={(event) => this.setNoData(event)} color={"primary"} />
                    }
                </Box>
                {content}
                {/*// TODO: убрал вывод уведомления сервисного сообщения выбранного значения*/}
                {/*{contentInfo}*/}

                {/* TODO: отображение иконки источника данных параметра */}
                <Tooltip title={this.getSourceDetectionTooltip(parameterState.sourceDetection)} placement={"right"} arrow={true}>
                    {
                        this.getSourceDetectionIcon(parameterState.sourceDetection)
                    }
                </Tooltip>
                {/* TODO: функционал выбора даты изменения */}
                {/* TODO: убрал, не нужно отображать */}
                {/*<Tooltip title={parameterState.updateDate ? getDateForTooltip(parameterState.updateDate) : 'Не задано'} placement={"right"} arrow={true}>*/}
                {/*    <EventIcon*/}
                {/*        style={{marginLeft: '8px', cursor: 'pointer'}}*/}
                {/*        onClick={() => {*/}
                {/*            if (!isNoDataAndAllowedNoData(parameterState)) {*/}
                {/*                this.showSelectDate(true);*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        color={'primary'}*/}
                {/*    />*/}
                {/*</Tooltip>*/}
                {/*{showSelectDateDialog &&*/}
                {/*    <MuiPickersUtilsProvider*/}
                {/*        utils={DateFnsUtils}*/}
                {/*        locale={dateLocale}*/}
                {/*    >*/}
                {/*        <DatePicker*/}
                {/*            // variant={'inline'}*/}
                {/*            okLabel={intl.formatMessage({id: 'label.confirm', defaultMessage: 'Confirm'})}*/}
                {/*            cancelLabel={intl.formatMessage({id: 'label.cancel', defaultMessage: 'Cancel'})}*/}
                {/*            // clearable={true}*/}
                {/*            TextFieldComponent={() => null}*/}
                {/*            hidden={true}*/}
                {/*            open={true}*/}
                {/*            // readOnly={true}*/}
                {/*            // className={classes.marginNone}*/}
                {/*            // disableToolbar*/}
                {/*            disableFuture={true}*/}
                {/*            // autoOk={true}*/}
                {/*            // disablePast={true}*/}
                {/*            // variant="inline"*/}
                {/*            // format="dd/MM/yyyy"*/}
                {/*            // format="dd.MM.yyyy"*/}
                {/*            margin="normal"*/}
                {/*            onClose={() => {*/}
                {/*                this.showSelectDate(false);*/}
                {/*            }}*/}
                {/*            // id="endAt"*/}
                {/*            // label={intl.formatMessage({id: 'label.invite.endAt', defaultMessage: 'endAt'})}*/}
                {/*            // value={fields.get('endAt')}*/}
                {/*            // valueDefault={parameterState.updateDate ? getDateForDatePicker(parameterState.updateDate) : null}*/}
                {/*            // initialFocusedDate={parameterState.updateDate ? getDateForDatePicker(parameterState.updateDate) : undefined}*/}
                {/*            value={getDateForDatePicker(parameterState.updateDate)}*/}
                {/*            onChange={(date) => {*/}
                {/*                this.onUpdateDateChange(getDateFromDatePicker(date));*/}
                {/*                this.showSelectDate(false);*/}
                {/*            }}*/}
                {/*            // onChange={this.onEndAtValueChange}*/}
                {/*            // KeyboardButtonProps={{*/}
                {/*            //     'aria-label': 'change date',*/}
                {/*            // }}*/}
                {/*            // minDateMessage={intl.formatMessage({id: 'label.invite.endAt.pastDateError', defaultMessage: 'Past Date Error'})}*/}
                {/*        />*/}
                {/*    </MuiPickersUtilsProvider>*/}
                {/*}*/}
                {/* TODO: убрал, не нужно отображать */}
            </Box>
        );
    }
}

ParameterValueView.propTypes = {
    classes: PropTypes.object.isRequired,
    parameter: PropTypes.object.isRequired,
    showError: PropTypes.bool,
    required: PropTypes.bool,
    selected: PropTypes.bool,
    readonly: PropTypes.bool,
    onValueChange: PropTypes.func,
    onUpdateDateChange: PropTypes.func,
};

ParameterValueView.defaultProps = {
    // value: -1,
};

ParameterValueView.contextTypes = {
    locale: PropTypes.string,
};

export default compose(
    withStyles(styles),
    injectIntl
)(ParameterValueView);
