import React from 'react';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import type {ServiceMessage} from "../const";
import {getServiceMessages} from "../utils";
import {MessageType} from "../const";
import {InfoHelp} from "./common/InfoHelp";
import mainStyles from "../styles/mainStyles";

const DeviceDescrTypography = withStyles(_theme => ({
    root: {
        marginLeft: '0.5rem',
    },
    result: {
        display: 'flex',
        justifyContent: 'end',
        width: 'fit-content',
    },
}))(Typography);

const useStyles = makeStyles((theme) => ({
    result: {
        display: 'flex',
        justifyContent: 'end',
    },
}));
function DeviceView(props) {
    const {device, positionEnd} = props;
    const classes = useStyles();

    const serviceMessageDESCRIPTION: ServiceMessage = getServiceMessages(device.messages, MessageType.DESCRIPTION);
    return (
        <Box>
            {/*<Typography component={'span'} display="inline" {...props}>*/}
            {/*    {device.name}*/}
            {/*</Typography>*/}
            {/*{device.descr &&*/}
            {/*    <DeviceDescrTypography component={'span'} color={'textSecondary'} display="inline" {...props}>*/}
            {/*        ({device.descr})*/}
            {/*    </DeviceDescrTypography>*/}
            {/*}*/}

            <Grid container direction={"row"} alignItems={"center"} className={!!positionEnd ? classes.result : ''}>
                <Grid item>
                    <Typography component={'span'} display="inline" {...props}>
                        {device.name}
                    </Typography>
                </Grid>
                {serviceMessageDESCRIPTION?.showService &&
                    <Grid item style={{margin: '-4px 0 0 10px'}}>
                        <InfoHelp texts={serviceMessageDESCRIPTION.messages}/>
                    </Grid>
                }
            </Grid>
        </Box>
        // <DeviceTypography {...props}>
        //   {props.children}
        // </DeviceTypography>
    );
}

DeviceView.propTypes = {
    device: PropTypes.object.isRequired,
};

export default DeviceView;
