import { gql } from '@apollo/client';
import type {
    DrugsByDrugDosageNamesResult as DrugsByDrugDosageNamesResultModel,
    SearchResult as SearchResultModel,
    DrugCompositeId as DrugCompositeIdModel,
} from '../const';
import {getPromiseQueryResult} from "../utils";
import config from "../config";

const TradeItem = `
    dosage
    id
    innCount
    innName
    manufacturer
    manufacturerCountry
    name
    regCertOwner
    regCertOwnerCountry
`;

const DrugComposite = `
    dosage
    drugId
    drugName
    id
    name
`;

// const DrugSearch_OLD = `
//     id
//     name
//     drugId
//     drugName
//     dosage
// `;

const DrugSearch = `
    drugId
    drugName
    normalCompositeId
    availComposites {
        ${DrugComposite}
    }
`;

const SearchResult = `
    query
    drugs {
        ${DrugSearch}
    }
`;

const DrugsByDrugDosageNamesResult = `
    id,
    name,
    tradeItems {
        ${TradeItem}
    }
`;

const DrugCompositeId = `
    nosologyId,
    drugCompositeId,
    dateCreate
`;

export const search = gql`
    query ($query: String!) {
        search(query: $query) {
            ${SearchResult}
        }
    }
`;

export const drugCompositesByIds = gql`
    query ($compositeIds: [Long!]!) {
        drugCompositesByIds(compositeIds: $compositeIds) {
            ${DrugComposite}
        }
    }
`;

export const loadSearch = (client, signal, searchText): Promise<SearchResultModel> => {
    return client.query({
        query: search,
        variables: {
            query: searchText,
        },
        context: {
            uri: config.options.server.reference_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'search');
        });
}

export const loadDrugCompositesByIds = (client, signal, ids: number[]): Promise<DrugComposite[]> => {
    return client.query({
        query: drugCompositesByIds,
        variables: {
            compositeIds: ids,
        },
        context: {
            uri: config.options.server.reference_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'drugCompositesByIds');
        });
}

export const drugCompositesByNames = gql`
    query ($compositeNames: [String!]!) {
        drugCompositesByNames(compositeNames: $compositeNames) {
            ${DrugsByDrugDosageNamesResult}
        }
    }
`;

export const loadDrugsCompositesByNames = (client, signal, compositeNames: string[]): Promise<DrugsByDrugDosageNamesResultModel[]> => {
    return client.query({
        query: drugCompositesByNames,
        variables: {
            compositeNames: compositeNames,
        },
        context: {
            uri: config.options.server.reference_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'drugCompositesByNames', []);
        });
}

export const drugs = gql`
    query ($ids: [Long!]!) {
        drugs(ids: $ids) {
            ${DrugsByDrugDosageNamesResult}
        }
    }
`;

export const loadCommercialDrugs = (client, signal, ids: number[]): Promise<DrugsByDrugDosageNamesResultModel[]> => {
    return client.query({
        query: drugs,
        variables: {
            ids: ids,
        },
        context: {
            uri: config.options.server.reference_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'drugs', []);
        });
}

export const drugsByCompositeIds = gql`
    query ($compositeIds: [Long!]!) {
        drugsByCompositeIds(compositeIds: $compositeIds) {
            ${DrugsByDrugDosageNamesResult}
        }
    }
`;

export const loadCommercialDrugsByCompositeIds = (client, signal, compositeIds: number[]): Promise<DrugsByDrugDosageNamesResultModel[]> => {
    return client.query({
        query: drugsByCompositeIds,
        variables: {
            compositeIds: compositeIds,
        },
        context: {
            uri: config.options.server.reference_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'drugsByCompositeIds', []);
        });
}

export const getPatientDrugCompositeIds = gql`
    query ($patientId: ID!) {
        getPatientDrugCompositeIds(patientId: $patientId) {
            ${DrugCompositeId}
        }
    }
`;

export const savePatientDrugs = gql`
    mutation ($id: ID!, $drugCompositeIds: [ID]) {
        savePatientDrugs(id: $id, drugCompositeIds: $drugCompositeIds)
    }
`;

export const callSavePatientDrugs = (client, signal, patient, drugCompositeIds, isChangedDrugs): Promise<boolean> => {
    if (!!isChangedDrugs) {
        return client.mutate({
            mutation: savePatientDrugs,
            errorPolicy: 'all',
            context: {
                uri: config.options.server.patient_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
            variables: {
                id: patient,
                drugCompositeIds: drugCompositeIds,
            },
        }).then(({data, _}) => {
            return getPromiseQueryResult(data, 'savePatientDrugs');
        });
    } else {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }
}

export const loadPatientDrugCompositeIds = (client, signal, patient): Promise<DrugCompositeIdModel[]> => {
    return client.query({
        query: getPatientDrugCompositeIds,
        variables: {
            patientId: patient,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getPatientDrugCompositeIds');
        });
}
