import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import { withApollo } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import React from 'react';
import {Component} from 'react';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import PanelDivider from '../comp/PanelDivider';
import {FormattedMessage, injectIntl} from 'react-intl';
import {ThNames1, ThNames2} from "../comp/baseline/BaselineVars";
import {
    capitalizeFirstLetter,
    finalStepNosologyRedirect,
    getNextLabel,
    getNosologyDataHistory, getParametersForBaselineDto,
    getURLSearchParams,
    isBaselineReadonly,
    isPrescriptionReadonly, isShowComparisons, isTherapyPresumably, loadStaticBaseline, patientDrugs,
} from "../utils";
import SelTreatView from "../comp/baseline/SelTreatView";
import SelTextView from "../comp/SelTextView";
import {
    loadGetPatient,
    loadGetSelectedValuesNosology,
    savePatientNosology
} from "../query/patient";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import {
    NosologyType,
    PatientType,
    PrescriptionType,
} from "../const";
import type {
    ActivePatient, ParametersForBaselineDto,
    StatDataInInputDto,
    TherapyCompareDto, TherapyCompareStatisticsDto,
    TherapyRecommendIn
} from "../const";
import SelectTherapyPresumablyView from "../comp/baseline/SelectTherapyPresumablyView";
import {loadGetTherapyCompare, loadGetTherapyCompareStatistics} from "../query/therapy";
import BaselineDialog from "../comp/patient/BaselineDialog";
import SaveOrRestartButton from "../comp/button/SaveOrRestartButton";
import {loadGetSubgroup} from "../query/BreastCancerApollo";
import {
    BreastCancerResultView,
    BreastCancerSelectDrugsView,
    BreastCancerSelectSubgroupsView
} from "../comp/BreastCancer/BreastCancerComp";
import type {DrugGroup, Subgroup, Subtreatment, Treatment} from "../models/breast-cancer";
import StaticBaseline from "../comp/StaticBaseline";
import ExpandableBaselineParameterStrictContextPanel
    from "../comp/common/ExpandableBaselineParameterStrictContextPanel";

const styles = theme => ({
    ...mainStyles(theme),
    fieldVarNameDescr: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    returnPatientButton: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
});

const ThNames = [ThNames1, ThNames2];

class BreastCancer extends Component {

    abortController = new AbortController();
    activePatient: ActivePatient = {};

    constructor(props) {
        super(props);
        const {intl} = props;

        const steps = [
            {
                title: intl.formatMessage({
                    id: 'label.bc.select_subgroups',
                    defaultMessage: 'Select subgroups'
                }),
                descr: (<Typography>
                    <FormattedMessage
                        id={'label.bc.select_subgroups_subtreatments'}
                        defaultMessage={'Select line of therapy'}/>
                </Typography>),
                contentFunc: this.getTherapySelectSubgroupsView,
                isNextFunc: this.isTreatmentsSubtreatmentsSelected,
                nextFunc: this.onNextSelectSubgroupsStep,
                prevFunc: this.onSelectSubgroupsPrev,
            },
            {
                title: intl.formatMessage({
                    id: 'label.bc.therapy.drugs.sel',
                    defaultMessage: 'Drugs selection(according to ESC Guidelines)'
                }),
                descr: intl.formatMessage({
                    id: 'label.bc.therapy.type.sel.desc',
                    defaultMessage: 'Aim for BP control within 3 months'
                }),
                contentFunc: this.getSelectDrugsView,
                isNextFunc: this.isDrugGroupSelected,
                backFunc: this.onSelectDrugPrev,
            },
            {
                title: intl.formatMessage({id: 'label.bl.medical_report', defaultMessage: 'Report'}),
                contentFunc: this.getResultView,
                nextFunc: this.onNextResultStep,
                isNextFunc: () => true,
                nextLabel: intl.formatMessage({id: 'label.bl.new_patient', defaultMessage: 'New patient'}),
                backFunc: this.onResultPrev,
            },
        ];

        this.activePatient = getURLSearchParams(props.location.search);

        this.state = {
            nosologyData: {
                touched: true,
                selectedTreatments: {},
                lines: {},
                varLines: {},
                therapyGroups: this.initTherapyGroups(),
                compStat: this.getCompStat(),
            },
            activeStep: 0,
            steps: steps,
            // therapyGroups: this.initTherapyGroups(), // 2020.06.06
            // compStat: this.getCompStat(), // 2020.06.06

            baselineDialog: {
                open: false,
            },

            allDataLoaded: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.history.location.search !== prevProps.location.search) {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        this.setState({
            activeStep: 0,
            baseline: [],
        });
        this.setContextPanelView(null);
        this.activePatient = getURLSearchParams(this.props.location.search);
        this.loadPatientNosology(this.activePatient);
    }

    loadPatientNosology = (activePatient) => {
        const {patientDataContext, setPatientDataContext} = this.context;
        const {signal} = this.abortController;
        const {client} = this.props;
        if (activePatient.patient) {
            if (!(patientDataContext || {}).patientDataContext) {
                loadGetPatient(client, signal, activePatient.patient, activePatient.patientUuid, setPatientDataContext)
                    .then((patientData) => {
                        this.proceedPatientNosology(client, signal, activePatient.action, activePatient.patient, activePatient.nosology, activePatient.history);
                    });
            } else {
                this.proceedPatientNosology(client, signal, activePatient.action, patientDataContext.patientDataContext.id, activePatient.nosology, activePatient.history);
            }
        } else {
            // this.loadBaseline();
        }
    }

    proceedPatientNosology = (client, signal, action, patientId, nosologyId, patientHistory) => {
        // TODO: здесь вызов нового сервиса, историю не надо передавать, только пациента и нозологию
        const {patientDataContext, setPatientDataContext} = this.context;

        patientDataContext.patientDataContext.actualBaselineParameterStrict = patientDataContext.patientDataContext.baselineParameterStrict;

        loadStaticBaseline(client, signal, nosologyId, patientDataContext, setPatientDataContext, () => {
            const {nosologyDataContext} = this.context;
            const {nosologyData} = this.state;
            const _nosologyData = nosologyDataContext && nosologyDataContext.nosologyData ? nosologyDataContext.nosologyData : nosologyData;
            this.setState(prevState => ({
                nosologyData: _nosologyData,
            }), () => {
                if (!!patientHistory) {
                    const _nosologyData = getNosologyDataHistory(patientDataContext.patientDataContext, nosologyId);

                    this.setState(prevState => ({
                        nosologyData: _nosologyData,
                    }), () => {
                        const {nosologyData} = this.state;
                        this.onStep0(action, false, nosologyData.subgroups);
                    });
                } else {
                    loadGetSelectedValuesNosology(client, signal, patientId, nosologyId)
                        .then((selectedValuesNosology) => {
                            loadGetSubgroup(client, signal, selectedValuesNosology, patientId)
                                .then((subgroups) => {
                                    this.setState(prevState => ({
                                        nosologyData: {
                                            ...prevState.nosologyData,
                                            subgroups: subgroups,
                                            selectedValuesNosology: selectedValuesNosology,
                                        }
                                    }), () => {
                                        this.updateContextPanel();
                                        this.onStep0(action, false, subgroups);
                                    });
                                });
                        });
                }
            });
        });
    }

    componentWillUnmount() {
        this.abortController.abort();

        this.setContextPanelView(null);
    }

    initTherapyGroups = () => {
        return ThNames.map(it => ({
            thNames: it,
            thData: it.map(th => ({name: th.name})),
        }));
    };

    cleanTherapyGroup = (gId) => {
        const {nosologyData} = this.state;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyGroups: nosologyData.therapyGroups
                    .map((th, i) => {
                        if (i === gId) {
                            const thName = ThNames[gId];
                            return {
                                thNames: thName,
                                thData: thName.map(th => ({name: th.name})),
                            };
                        } else {
                            return th;
                        }
                    }),
            },
        }));
    };

    getSelVarIds = () => {
        const {nosologyData} = this.state;
        return nosologyData.fields
            .filter(f => Boolean(f.value))
            .map(f => f.value);
    };

    getThNames = (groupId) => {
        const {nosologyData} = this.state;

        switch (groupId) {
            case 0: {
                if (Boolean(nosologyData.selLineId) && Boolean(nosologyData.varLines)) {
                    return nosologyData.varLines[nosologyData.selLineId].therapies;
                } else {
                    return nosologyData.therapyGroups[groupId].thNames;
                }
            }
            case 1:
                return nosologyData.therapyGroups[groupId].thNames;
            default:
                return [];
        }
    };

    getTherapyIdWithName = (thName) => {
        const { sgTreatments } = this.getSelectLine();

        if (Boolean(sgTreatments)) {
            const sgTr = sgTreatments
                .find(tr => tr.active === 1 && tr.name === thName);
            return Boolean(sgTr) ? sgTr.therapyId : undefined;
        }

        return undefined;
    };

    getSelThId = (groupId) => {
        const {nosologyData} = this.state;

        if (groupId >= 0 && groupId < nosologyData.therapyGroups.length) {
            return nosologyData.therapyGroups[groupId].selThId;
        }

        return -1;
    };

    stepContextPanel = (stepContextView) => {
        if (Boolean(stepContextView)) {
            this.context.setContextContent({
                view: stepContextView,
            });
        } else {
            this.context.setContextContent(null);
        }
    };

    setContextPanelView = (view) => {
        const {setContextContent} = this.context;

        if (setContextContent !== undefined) {
            if (Boolean(view)) {
                setContextContent({view: view});
            } else {
                setContextContent(null);
            }
        }
    };

    getSelectLine = () => {
        const {nosologyData} = this.state;

        if ((nosologyData.selLineId || 1) !== undefined && nosologyData.lines !== undefined) { // протестировать (... || 1)
            return nosologyData.lines[nosologyData.selLineId];
        }

        return undefined;
    };

    setSelectedSubtreatments = (selectedTreatments: Treatment[]) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                selectedTreatments: selectedTreatments,
                touched: true,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    setSelDrugGroup = (selDrugGroup: DrugGroup) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                selDrugGroup: selDrugGroup,
                touched: true,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    getSelDrugGroup = (): DrugGroup => {
        return this.state.nosologyData.selDrugGroup;
    };

    setForestPlotView = (forestPlotView, callback) => {
        this.setState(prevState => ({
            forestPlotView: forestPlotView,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    forestPlotPanel = () => {
        const {classes} = this.props;
        const {forestPlotView, activeStep, steps} = this.state;
        if (forestPlotView && (activeStep + 1 < steps.length)) {
            return (
                <Box className={classes.contentBoxTopSpace}>
                    {forestPlotView}
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    getTherapyWithName = (therapyName) => {
        const selLine = this.getSelectLine();
        return Boolean(selLine) ? selLine.sgTreatments.find(it => it.name === therapyName) : undefined;
    };

    getTherapyFullname = (therapyName, defaultName) => {
        const therapy = this.getTherapyWithName(therapyName);

        if (Boolean(therapy) && Boolean(therapy.fullName)) {
            return therapy.fullName;
        }

        return defaultName;
    };

    getTherapyTreatments = (therapyName) => {
        const therapy = this.getTherapyWithName(therapyName);

        if (Boolean(therapy) && Boolean(therapy.treatments)) {
            return therapy.treatments;
        }

        return [];
    };

    getTherapyActiveTexts = (therapyName) => {
        const therapy = this.getTherapyWithName(therapyName);

        if (Boolean(therapy) && Boolean(therapy.activeTexts)) {
            return therapy.activeTexts;
        }

        return [];
    };

    isTherapyActive = (therapyName) => {
        const therapy = this.getTherapyWithName(therapyName);
        return Boolean(therapy) && therapy.active !== -1;
    };

    getLineNames = (_lines) => {
        const {intl} = this.props;
        /*
        const selLine = lines[1];

        if (Boolean(selLine)) {
          const therapy = selLine.sgTreatments.find(it => it.name === 'aad');
          if (Boolean(therapy)) {
            return therapy.lines.reduce((acc, cur) => {
              acc[cur.num] = cur.label;
              return acc;
            }, {});
          }
        }
        */
        return {
            1: intl.formatMessage({id: 'label.bl.first_therapy', defaultMessage: 'First therapy'}),
            2: intl.formatMessage({id: 'label.bl.second_therapy', defaultMessage: 'Second therapy'}),
        };
    };

    getLineLabel = (lineId) => {
        const names = this.getLineNames(this.state.nosologyData.lines);
        const label = names[lineId];

        return Boolean(label) ? label : lineId.toString();
    };

    getSubgroupsViewData = (): {treatment: Treatment; subtreatment: Subtreatment;}[] => {
        const {nosologyData} = this.state;

        let subgroupsViewData: {subgroupName: string; treatment: Treatment; subtreatment: Subtreatment;}[] = [];

        if (Boolean(nosologyData.subgroups)) {
            Object.keys(nosologyData.selectedTreatments).forEach((treatmentId) => {
                const subtreatmentId = nosologyData.selectedTreatments[treatmentId];
                nosologyData.subgroups.forEach((subgroup: Subgroup) => {
                    subgroup.treatments.forEach((treatment: Treatment) => {
                        if (treatment.id === treatmentId) {
                            let selectedSubtreatment;
                            treatment.subtreatments.forEach((subtreatment: Subtreatment) => {
                                if (subtreatment.id === subtreatmentId) {
                                    selectedSubtreatment = subtreatment;
                                }
                            });
                            if (selectedSubtreatment) {
                                subgroupsViewData.push({
                                    treatment: treatment,
                                    subtreatment: selectedSubtreatment,
                                    subgroupName: subgroup.name,
                                });
                            }
                        }
                    });
                });
            });
        }

        return subgroupsViewData;
    }

    updateContextPanel = () => {
        const {steps, activeStep} = this.state;
        if ((this.activePatient.patientType === PatientType.PUBLIC && activeStep === 0) || activeStep === (steps.length - 1)) {
            this.setContextPanelView(null);
            return;
        }

        const {classes} = this.props;
        const {patientDataContext} = this.context;

        this.getAllSelectDrugs();

        let subgroupsViewData: {subgroupName: string; treatment: Treatment; subtreatment: Subtreatment;}[] = [];
        const {nosologyData} = this.state;
        if (!Boolean(nosologyData.subgroups)) {
            this.setContextPanelView(null);
            return;
        } else {
            subgroupsViewData = this.getSubgroupsViewData();
        }

        const selDrugGroup = nosologyData.selDrugGroup;

        const scales = nosologyData?.subgroups[0]?.scales;

        this.setContextPanelView(
            <Box>
                <Paper className={classes.contentBoxPanel}>
                    {scales?.length > 0 &&
                    <Box>
                        <SelTextView align={"center"}>
                            <FormattedMessage id={'label.bl.baseline'} defaultMessage={'Baseline'}/>
                        </SelTextView>
                        <Box className={classes.contentBoxTopSpace}>
                            {scales.map((scale) => (
                                <Box key={scale.id}>
                                    <Typography component={'span'}>
                                        {/*{`${scale.name}: ${scale.value}`}*/}
                                        {scale.name}:
                                    </Typography>
                                    <Typography component={'span'} color='primary' style={{marginLeft: '8px'}}>{scale.value}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    }
                </Paper>
                {/*<PanelDivider/>*/}

                <StaticBaseline staticBaseline={patientDataContext.patientDataContext.staticBaseline} />
                <ExpandableBaselineParameterStrictContextPanel baselineParameterStrict={patientDataContext.patientDataContext.actualBaselineParameterStrict} />

                {subgroupsViewData.length > 0 &&
                    <Paper className={classes.contentBoxPanel}>
                        {subgroupsViewData.map((subgroupsViewDataItem, id) => (
                            <Box key={subgroupsViewDataItem.treatment.id}>
                                <SelTextView align={"center"}>
                                    {subgroupsViewDataItem.subgroupName}
                                    <br />
                                    {subgroupsViewDataItem.treatment.name}
                                    <br />
                                </SelTextView>
                                {Boolean(subgroupsViewDataItem.subtreatment) &&
                                <Box>
                                    <SelTreatView
                                        treatName={subgroupsViewDataItem.subtreatment.name}/>
                                </Box>
                                }
                            </Box>
                        ))}
                    </Paper>
                }
                <PanelDivider/>
                {Boolean(selDrugGroup) &&
                    <Paper className={classes.contentBoxPanel}>
                        <Box key={selDrugGroup.id}>
                            <SelTextView align={"center"}>
                                <FormattedMessage id={'label.bc.drug_group'} defaultMessage={'Drug group'}/>
                            </SelTextView>
                            <Box>
                                <SelTreatView
                                    treatName={selDrugGroup.name}/>
                            </Box>
                        </Box>
                    </Paper>
                }
            </Box>
        );
    };

    getSelectTherapyDrugs = () => {
        const {nosologyData} = this.state;
        const result = {};

        if (Boolean(nosologyData)) {
            nosologyData.therapyGroups
                .filter(th => th.selThId !== undefined)
                .forEach(({selThId, thData}, gId) => {
                    const { data: { refers } = {}, name } = thData[selThId];
                    const thNames = this.getThNames(gId);
                    const therapy = thNames.find(t => t.name === name);
                    const drugs = [];

                    if (therapy && therapy.isSimple && Boolean(therapy.treatments)) {
                        Object.keys(therapy.treatments)
                            .filter(t => therapy.treatments[t])
                            .map(tr => capitalizeFirstLetter(tr))
                            .forEach(treat => drugs.push(treat));
                    } else {
                        const ref = Boolean(refers) ? refers.find(ref => ref.isSel) : undefined;
                        if (Boolean(ref)) {
                            drugs.push(ref.name);
                        }
                    }

                    result[this.getTherapyIdWithName(name)] = drugs;
                });
        }

        return result;
    }

    getSelectSubtreatments = (): any[] => {
        const {nosologyData} = this.state;
        if (Boolean(nosologyData.selectedTreatments)) {
            return Object.values(nosologyData.selectedTreatments);
        }

        return [];
    }

    getAllSelectDrugs = () => {
        return Object.values(this.getSelectTherapyDrugs())
            .flatMap(it => it);
    };

    onValueChange = (fieldId, value) => {
        const {nosologyData} = this.state;
        const {contextStaff, patientDataContext} = this.context;

        // const readonly = isBaselineReadonly((contextStaff || {}).role, this.activePatient.patientType);
        const readonly = isBaselineReadonly(contextStaff, this.activePatient.patientType, (patientDataContext || {}).patientDataContext);

        if (readonly) {
            return;
        }

        let field = nosologyData.fields.find(f => f.id === fieldId);
        if (Boolean(field)) {
            field.value = value;
            delete field.error;

            // check parent
            nosologyData.fields
                .filter(f => f.id !== field.id && Boolean(f.value) && Boolean(f.parentId) && f.parentId === field.id)
                .forEach(f => {
                    delete f.value;
                });

            // check single group
            if (Boolean(field.fgroup)) {
                nosologyData.fields
                    .filter(f => f.id !== field.id && f.fgroup === field.fgroup && Boolean(f.value))
                    .forEach(f => {
                        if (Boolean(f.defaultVar)) {
                            f.value = f.defaultVar;
                        } else {
                            delete f.value;
                        }
                    });
            }

            nosologyData.touched = true;
            nosologyData.selFields = undefined;
            nosologyData.selLineId = undefined;
            nosologyData.varLines = {};

            this.forceUpdate();
        }
    };

    getStepContent = () => {
        const {steps, activeStep} = this.state;

        if (activeStep < steps.length && Boolean(steps[activeStep].contentFunc)) {
            return steps[activeStep].contentFunc();
        } else {
            return (<Box/>);
        }
    };

    onFieldNameClick = (fieldId) => (e) => {
        e.persist();
        if (this.isFieldDescr(fieldId)) {
            this.setState(prevState => ({
                nosologyData: {
                    ...prevState.nosologyData,
                    selFieldId: fieldId,
                    anchorFieldDescr: e.target,
                },
            }));
        }

        e.preventDefault();
    };

    getFieldWithId = (fieldId) => {
        const {nosologyData} = this.state;

        if (!Boolean(nosologyData.fields)) {
            return null;
        }

        const indx = nosologyData.fields.findIndex(f => f.id === fieldId)
        if (indx > -1) {
            return nosologyData.fields[indx];
        }

        return null;
    };

    isFieldDescr = (fieldId) => {
        const field = this.getFieldWithId(fieldId);
        return Boolean(field) && Boolean(field.vars) && field.vars.findIndex(v => Boolean(v.descr)) !== -1;
    };

    getThRecommend = (): TherapyRecommendIn[] => {
        const selectTherapyDrugs = this.getSelectTherapyDrugs();
        const thRecommend: TherapyRecommendIn[] = [];

        Object.keys(selectTherapyDrugs)
            .forEach(therapyDrugKey => thRecommend.push({
                therapyId: therapyDrugKey,
                selectDrugs: selectTherapyDrugs[therapyDrugKey],
            }));

        return thRecommend;
    };

    loadTherapyCompare = (signal): Promise<TherapyCompareDto[]> => {
        const {client} = this.props;
        const therapyPresumably = this.getTherapyPresumably();
        if (therapyPresumably && therapyPresumably.thData) {
            return loadGetTherapyCompare(client, signal, NosologyType.ATRIAL_FIBRILLATION, this.getSelVarIds(), therapyPresumably.thData, this.getThRecommend())
                .then((data) => {
                    return new Promise((resolve, reject) => {
                        resolve(data);
                    });
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve([]);
            });
        }
    };

    loadTherapyCompareStatistics = (signal): Promise<TherapyCompareStatisticsDto[]> => {
        const {client} = this.props;
        const {contextStaff} = this.context;

        const statDataInInput: StatDataInInputDto = {
            excludePatientIds: [],
            excludeStaffIds: [],
            includePatientIds: [Number(this.activePatient.patient)],
            includeStaffIds: [Number(contextStaff.id)],
        }

        return loadGetTherapyCompareStatistics(client, signal, NosologyType.ATRIAL_FIBRILLATION, statDataInInput)
            .then((data) => {
                return new Promise((resolve, reject) => {
                    resolve(data);
                });
            });
    };

    getTouched = () => {
        return this.state.nosologyData.touched;
    };

    setTouched = (touched) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                touched: touched,
            },
        }), () => {
        });
    };

    getTherapyCompare = () => {
        const {nosologyData} = this.state;
        return nosologyData.therapyCompare;
    };

    setTherapyCompare = (therapyCompare, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyCompare: therapyCompare,
                touched: true,
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    getTherapyPresumably = () => {
        const {nosologyData} = this.state;
        return nosologyData.therapyPresumably;
    };

    getRecomendTexts = () => {
        const { nosologyData } = this.state;
        return ((((nosologyData || {}).subgroups || [])[0] || {}).recommends || []).sort((item1, item2) => item1.ord - item2.ord);
    };

    setTherapyPresumably = (therapyPresumably, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyPresumably: therapyPresumably,
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    isTherapyPresumably = () => {
        const {nosologyData} = this.state;
        // return (Boolean(nosologyData.therapyPresumably) && nosologyData.therapyPresumably.length  > 0);
        return isTherapyPresumably((nosologyData.therapyPresumably || {}).thData);
    };

    backSelectTherapyPresumably = () => {
        const {nosologyData, activeStep} = this.state;

        delete nosologyData.therapyPresumably;

        if (activeStep > 0) {
            this.setState(prevState => ({
                activeStep: activeStep - 1,
            }), () => {
                this.updateContextPanel();
            });
        }
    };

    autoSave = (): Promise<any> => {
        const {
            nosologyData,
        } = this.state;
        const {patientDataContext} = this.context;
        if (this.activePatient.action !== 'RESULT' || nosologyData.touched) {
            const {client} = this.props;
            const {signal} = this.abortController;
            const prescriptions: [] = [];
            prescriptions.push({
                type: PrescriptionType.SUBTREATMENT,
                ids: this.getSelectSubtreatments(),
            });
            // prescriptions.push({
            //     type: PrescriptionType.DEVICES,
            //     ids: [],
            // });
            nosologyData.prescriptions = prescriptions;
            const parameters: ParametersForBaselineDto[] = getParametersForBaselineDto(patientDataContext.patientDataContext.parameters);

            return savePatientNosology(client, signal, nosologyData, parameters, this.activePatient.patient, this.activePatient.nosology)
                .then(saveResponse => {
                    this.setDisableResultNext(false);
                    return saveResponse;
                });
        } else {
            return new Promise((resolve, reject) => {
                this.setDisableResultNext(false);
                resolve();
            });
        }
    };

    setDisableResultNext = (disableResultNext: boolean = false) => {
        this.setState({disableResultNext: disableResultNext});
    }

    getSelectTherapyPresumablyView = () => {
        const {contextStaff} = this.context;
        const {nosologyData} = this.state;

        return (<SelectTherapyPresumablyView
            nosologyData={nosologyData}
            nosology={NosologyType.ATRIAL_FIBRILLATION}
            setTherapyPresumably={this.setTherapyPresumably}
            getTherapyPresumably={this.getTherapyPresumably}
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
        />);
    };

    getTherapySelectSubgroupsView = () => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;

        return (
            <BreastCancerSelectSubgroupsView
                isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
                subgroups={nosologyData.subgroups}
                selectedTreatments={nosologyData.selectedTreatments}
                setSelectedSubtreatments={this.setSelectedSubtreatments}
                updateContextPanel={this.updateContextPanel}
                nosologyData={nosologyData}
                getRecomendTexts={this.getRecomendTexts}
            />
        );
    };

    getSelectDrugsView = () => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;
        return (<BreastCancerSelectDrugsView
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
            nosologyData={nosologyData}
            getSteps={this.getSteps}
            getRecomendTexts={this.getRecomendTexts}
            setRecomendTexts={this.setRecomendTexts}
            getTouched={this.getTouched}
            getSelectTherapyType={this.getSelectTherapyType}
            getTherapyGroupDrugs={this.getTherapyGroupDrugs}
            setTherapyGroupDrugs={this.setTherapyGroupDrugs}
            setDrugForTherapyGroup={this.setDrugForTherapyGroup}
            setStatistics={this.setStatistics}
            getStatistics={this.getStatistics}
            getSelectVars={this.getSelectVars}
            getSelDrugGroup={this.getSelDrugGroup}
            setSelDrugGroup={this.setSelDrugGroup}
            patientId={Number(this.activePatient.patient)}
            setForestPlotView={this.setForestPlotView}
        />);
    };

    getResultView = () => {
        const {nosologyData} = this.state;

        return (
            <BreastCancerResultView
                nosologyData={nosologyData}
                getTherapyPresumably={this.getTherapyPresumably}
                loadTherapyCompare={this.loadTherapyCompare}
                loadTherapyCompareStatistics={this.loadTherapyCompareStatistics}
                getTherapyCompare={this.getTherapyCompare}
                setTherapyCompare={this.setTherapyCompare}
                getTouched={this.getTouched}
                getRecomendTexts={this.getRecomendTexts}
                showComparisons={isShowComparisons(this.activePatient.patientType)}
                autoSave={this.autoSave}
            />
        );
    };

    getCompStat = () => {
        return Math.round(Math.random() * 100000);
    };

    onStep0Proceed = (action, nosologyData, selFields, showNextPage: boolean = true, ids: number[] | string[]) => {
        const {client} = this.props;
        const {signal} = this.abortController;

        loadGetSubgroup(client, signal, ids)
            .then((subgroups) => {
            });
    }

    onStep0 = (action, showNextPage: boolean = true, subgroups: number[] | string[]) => {
        const {
            activeStep,
            steps,
        } = this.state;

        this.setState({
            activeStep: action === 'RESULT' ? steps.length - 1 : activeStep,
            // TODO: не из контекста nosologyData берем, т.к. ранее она была установлена не зависимо от action
            //  nosologyData: action === 'RESULT' ? nosologyDataContext && nosologyDataContext.nosologyData ? nosologyDataContext.nosologyData : nosologyData : nosologyData,
            allDataLoaded: true,
        }, () => {
        });

    };

    onNextSelectSubgroupsStep = () => {
        const {activeStep, steps} = this.state;
        const subgroupsViewData = this.getSubgroupsViewData();

        let step = 1;
        if (!subgroupsViewData.some((subgroup) => subgroup.treatment.medication)) {
            step = step + 1;
        }

        if (activeStep < steps.length - step) {
            this.setState({
                activeStep: activeStep + step,
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    onResultPrev = () => {
        const {activeStep} = this.state;
        const subgroupsViewData = this.getSubgroupsViewData();

        let step = 1;
        if (!subgroupsViewData.some((subgroup) => subgroup.treatment.medication)) {
            step = step + 1;
        }

        this.setState({
            activeStep: activeStep - step,
        }, () => {
            this.updateContextPanel();
        });
    };

    onNextResultStep = () => {
        this.onStepFinal();
    };

    onStepFinal = () => {
        const {contextStaff} = this.context;

        if (this.activePatient.patient && !isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)) {
            const {client} = this.props;
            const {signal} = this.abortController;
            const {nosologyData, parameters} = this.state;

            if (nosologyData.touched) {

                const prescriptions: [] = [];
                prescriptions.push({
                    type: PrescriptionType.DRUGS,
                    names: this.getAllSelectDrugs(),
                });
                prescriptions.push({
                    type: PrescriptionType.DEVICES,
                    ids: [],
                });
                nosologyData.prescriptions = prescriptions;

                savePatientNosology(client, signal, nosologyData, parameters, this.activePatient.patient, this.activePatient.nosology)
                    .then(saveResponse => {
                        this.finalStepRedirect(this.activePatient);
                    });
            } else {
                this.finalStepRedirect(this.activePatient);
            }
        } else {
            this.finalStepRedirect(this.activePatient);
        }
    };

    finalStepRedirect = (activePatient, needGoNextNosology = true) => {
        this.setContextPanelView(null);
        if (this.activePatient.patient) {
            const {signal} = this.abortController;
            const {client, history} = this.props;
            const {setContextPatient, setPatientDataContext} = this.context;
            finalStepNosologyRedirect(signal, client, setContextPatient, history, this.activePatient, setPatientDataContext, needGoNextNosology);
        } else {
            // const {steps} = this.state;
            // let newSteps = [];
            // newSteps.push(steps[0]);
            this.setState({
                nosologyData: {
                    touched: true,
                    selLineId: undefined,
                    therapyGroups: this.initTherapyGroups(),
                    compStat: this.getCompStat(),
                    lines: {},
                    varLines: {},
                    fields: undefined,
                },
                // steps: newSteps,
                activeStep: 0,
            }, () => {
                window.scrollTo(0, 0);
                // TODO: раньше загружали, а сейчас мы сюда вовсе не попадаем
                // this.loadBaseline();
            });
        }
    }

    isTreatmentsSubtreatmentsSelected = (): boolean => {
        const {nosologyData} = this.state;
        const result = Object.keys(nosologyData.selectedTreatments).length > 0 && Object.keys(nosologyData.selectedTreatments).every((treatmentId) => {
            return nosologyData.selectedTreatments[treatmentId] > 0
        });
        return result;
    };

    isDrugGroupSelected = (): boolean => {
        const {nosologyData} = this.state;
        return Boolean(nosologyData.selDrugGroup);
    };

    isTherapySelect = (groupId) => {
        const selThId = this.getSelThId(groupId);
        let result = selThId !== undefined && selThId >= 0;

        if (result) {
            const thNames = this.getThNames(groupId);
            const therapy = thNames[selThId];
            if (therapy !== undefined && therapy.isSimple && Object.keys(therapy.treatments).length > 0) {
                const selTreats = Object.keys(therapy.treatments)
                    .filter(tr => therapy.treatments[tr]);
                result = selTreats.length > 0;
            }
        }

        if (!result) {
            const {sgTreatments} = this.getSelectLine();
            const thNames = this.getThNames(groupId).map(th => th.name);

            if (Boolean(sgTreatments)) {
                const activeTreats = sgTreatments
                    .filter(tr => thNames.includes(tr.name))
                    .map(tr => tr.active);
                const activeSum = activeTreats
                    .reduce((acc, v) => acc + v);
                result = (activeTreats.length + activeSum) === 0;
            }
        }

        return result;
    };

    onNext = () => {
        const {activeStep, steps} = this.state;

        if (Boolean(steps[activeStep].nextFunc)) {
            steps[activeStep].nextFunc();
        } else if (activeStep < steps.length - 1) {
            this.setState({
                activeStep: activeStep + 1,
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    isNextAvailable = () => {
        const {steps, activeStep} = this.state;

        if (Boolean(steps[activeStep].isNextFunc)) {
            return steps[activeStep].isNextFunc();
        }

        if (activeStep === (steps.length - 1)) {
            return false;
        }

        return true;
    };

    getNextLabel = () => {
        const {steps, activeStep, nosologyData} = this.state;
        const {intl} = this.props;
        const {contextStaff} = this.context;

        return getNextLabel(steps, activeStep, intl, contextStaff, this.activePatient, nosologyData);
    };

    onRestart = () => {
        this.setState({
            allDataLoaded: false,
            activeStep: 0,
            baseline: [],

            nosologyData: {
                touched: true,
                lines: {},
                varLines: {},
                therapyGroups: this.initTherapyGroups(),
                compStat: this.getCompStat(),
            },
        }, () => {
            const {setNosologyDataContext} = this.context;
            const {nosologyData} = this.state;
            setNosologyDataContext({
                    nosologyData: nosologyData
                },
                () => {
                    const {history} = this.props;
                    const queryParams = new URLSearchParams(history.location.search);
                    if (queryParams.get('action')) {
                        queryParams.delete('action');
                        history.replace({
                            search: queryParams.toString()
                        });
                    } else {
                        this.componentDidMount();
                    }
                });
        });
    };

    onPrev = () => {
        const {activeStep, steps} = this.state;

        if (Boolean(steps[activeStep].prevFunc)) {
            steps[activeStep].prevFunc();
        } else if (activeStep > 0) {
            this.setState({
                activeStep: activeStep - 1,
                baselineTreat: undefined,
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    onSelectSubgroupsPrev = () => {
        const {activeStep} = this.state;

        if (activeStep > 0) {
            this.setState(prevState => ({
                activeStep: activeStep - 1,
                nosologyData: {
                    ...prevState.nosologyData,
                    selectedTreatments: {},
                },
            }), () => {
                this.updateContextPanel();
            });
        }
    };

    onSelectDrugPrev = () => {
        const {activeStep} = this.state;

        if (activeStep > 0) {
            this.setState(prevState => ({
                activeStep: activeStep - 1,
                nosologyData: {
                    ...prevState.nosologyData,
                    selDrugGroup: undefined,
                },
            }), () => {
                this.updateContextPanel();
            });
        }
    };

    onTherapyTreatsPrev = (gId) => () => {
        const {activeStep, nosologyData} = this.state;

        if (activeStep > 0) {
            // remove data for therapy groups
            if (nosologyData.thouched) {
                this.cleanTherapyGroup(gId);
            }

            this.setState({
                activeStep: activeStep - 1,
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    showError = (message) => {
        const {showNotify} = this.context;
        if (showNotify) {
            showNotify(message, 'error');
        }
    };

    setBaselineDialogOpen = (open: boolean) => {
        this.setState({
            baselineDialog: {
                open: open,
            },
        });
    }

    getSelectVars = () => {
        const {baseline} = this.state;

        if (!Boolean(baseline)) {
            return [];
        }

        return baseline.flatMap(bl => bl.fields)
            .filter(f => Boolean(f.value))
            .map(f => f.value);
    };

    isFieldShow = (field) => {
        const selVars = this.getSelectVars();

        if (Boolean(field.opt) && Boolean(field.opt.isShow) && Boolean(field.opt.isShow.selVars)) {
            const intr = selVars.filter(v => field.opt.isShow.selVars.includes(v));
            return intr.length === field.opt.isShow.selVars.length;
        }

        return true;
    };

    render() {
        const {classes, intl} = this.props;
        const {allDataLoaded, activeStep, steps, nosologyData, baselineDialog} = this.state;
        const {
            patientDataContext,
            contextContent,
            contextStaff,
        } = this.context;
        const step = steps[activeStep];

        if (!allDataLoaded) {
            return <Box />;
        }

        return (
            <Box>

                <Paper className={classes.contentBox}>
                    {Boolean(this.activePatient.patientName) &&
                    <React.Fragment>
                        <Button color={"primary"} variant={"outlined"} className={classes.returnPatientButton}
                                // onClick={() => returnToPatient(this.activePatient, this.props.history)}>
                                onClick={() => this.finalStepRedirect(this.activePatient, false)}>
                            <KeyboardBackspaceRoundedIcon className={this.props.classes.iconButton}/>
                            {this.activePatient.patientName}
                        </Button>
                        <Button color={"primary"} variant={"outlined"} className={classes.returnPatientButton}
                                onClick={() => this.setBaselineDialogOpen(true)}>
                            {intl.formatMessage({
                                id: 'label.patient.baseline',
                                defaultMessage: 'Baseline data'
                            })}
                        </Button>
                        {contextContent &&
                        <Button color={"primary"} variant={"outlined"} className={`${classes.returnPatientButton} ${classes.contextBarButtonHide}`}
                                onClick={() => this.context.setContextContentShow(true)}>
                            {intl.formatMessage({
                                id: 'label.nosology.context_panel',
                                defaultMessage: 'Информационная панель'
                            })}
                        </Button>
                        }
                    </React.Fragment>
                    }
                    <Typography variant='h6'>
                        {`${intl.formatMessage({
                            id: 'label.step',
                            defaultMessage: 'Step'
                        })} ${activeStep + 1}/${steps.length}: ${step.title}`}
                    </Typography>
                    {Boolean(step.descr) && step.descr}
                </Paper>

                <PanelDivider/>

                {this.getStepContent()}

                <PanelDivider/>

                <Paper>
                    <Grid container className={classes.contentBox}>
                        <Grid item xs={12}>
                            <Grid container spacing={3} justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        onClick={this.onPrev}
                                        disabled={activeStep === 0}>
                                        <FormattedMessage id={'label.back'} defaultMessage={'Back'}/>
                                    </Button>
                                </Grid>
                                {activeStep === (steps.length - 1) && this.activePatient.patient &&
                                <Grid item>
                                    <SaveOrRestartButton
                                        onSave={this.onNext}
                                        saveLabel={this.getNextLabel()}
                                        onRestart={this.onRestart}
                                        intl={intl}
                                    />
                                </Grid>
                                }
                                {(activeStep !== (steps.length - 1) || !this.activePatient.patient) &&
                                <Grid item>
                                    <Button
                                        onClick={this.onNext}
                                        disabled={!this.isNextAvailable()}
                                        color='primary'
                                        variant='contained'>
                                        {this.getNextLabel()}
                                    </Button>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                {
                    baselineDialog.open &&
                    <BaselineDialog
                        open={baselineDialog.open}
                        setOpen={this.setBaselineDialogOpen}
                        classes={classes}
                        intl={intl}
                        patientData={patientDataContext}
                        contextStaff={contextStaff}
                        baseline={patientDataContext.baseline}
                        nosologyData={nosologyData}
                        isFieldShow={this.isFieldShow}
                        patientDrugs={patientDrugs}
                    />
                }

            </Box>
        );
    }
}

BreastCancer.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

BreastCancer.contextTypes = {
    setContextContent: PropTypes.func,
    showNotify: PropTypes.func,
    nosologyDataContext: PropTypes.object,
    patientDataContext: PropTypes.object,
    setPatientDataContext: PropTypes.func,
    setNosologyDataContext: PropTypes.func,
    setContextPatient: PropTypes.func,
    contextStaff: PropTypes.object,
    contextContent: PropTypes.object,
    setContextContentShow: PropTypes.func,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl
)(BreastCancer);
