import React from 'react';
import PropTypes from 'prop-types';
import compose from "recompose/compose";
import MUIDataTable, {MUIDataTableOptions} from "mui-datatables";
import {getCookies, newAnyDataRef, setCookies} from "../../utils";
import {createTheme, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import mainStyles from "../../styles/mainStyles";
import {globalTheme} from "../../const";
import CustomFooterDatatable from "./CustomFooterDatatable";
import CustomSearchDatatable from "./CustomSearchDatatable";
import {withCookies} from "react-cookie";

const styles = theme => ({
    ...mainStyles(theme),

    // 'MUIDataTable-responsiveScroll': { 'max-height': 'none'},
    MuiTableCell: {
        root: {  //This can be referred from Material UI API documentation.
            padding: '4px',
            // backgroundColor: "#eaeaea",
        },
    },
});

class DatatableComponent extends React.Component {
    _searchText: string;

    state = {
        options: undefined,
    };

    // options = undefined;
    searchOpen = false;


    theme = createTheme({
        ...globalTheme,

        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 500,
                lg: 1280,
                xl: 1920,
            },
        },

        overrides: {
            // MuiIconButton: {
            //   sizeSmall: {
            //     padding: 0,
            //   }
            // },

            MuiPaper: {
                elevation4: {
                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                    // boxShadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
                }
            },

            MuiToolbar: {
                // root: {
                gutters: {
                    paddingLeft: "16px !important",
                    paddingRight: "16px !important",
                }
                // }
            },

            MUIDataTablePagination: {
                tableCellContainer: {
                    borderBottom: this.props.disableShadow ? '1px solid rgba(224, 224, 224, 1)' : 0,
                }
            },

            MUIDataTable: {
                paper:
                    {
                        boxShadow: this.props.disableShadow ? 'none !important' : "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                        borderRadius: this.props.disableShadow ? '0 !important' : '4px !important',
                    },
            },
            MuiTableSortLabel: {
                icon: {
                    top: "6px",
                    position: "relative",
                }
            },
            MUIDataTableToolbarSelect: {
                root:
                    {
                        boxShadow: 'none !important',
                        borderRadius: 0,
                    },
            },
            MuiTableCell: {
                root: {
                    // '@media (max-width:959.95px)': {
                    '@media (max-width:499.95px)': {
                        borderBottom: "none",
                        padding: "1px",
                    },
                }
            },
            MUIDataTableBodyCell: {
                stackedCommon: {
                    // '@media (max-width:959.95px)': {
                    '@media (max-width:499.95px)': {
                        whiteSpace: "normal",
                        height: "100%",
                        paddingLeft: "5px",
                        // display: "inherit",
                        verticalAlign: 'middle'
                    },
                },
                cellHide: {
                    // TODO: +++2020.12.23+++ убрал, чтобы в свернутом состоянии хидер был не болдом
                    //  fontWeight: "bold",
                    // paddingTop: "15px",
                }
            },
            MuiTableRow: {
                root: {
                    '&$selected': {
                        backgroundColor: grey[300],
                        '&:hover': {
                            backgroundColor: grey[300]
                        }
                    }
                }
            },
            MUIDataTableSelectCell: {
                root: {
                    display: 'none'
                }
            },
        }
    });

    constructor(props) {
        super(props);

        if (props.cellPadding) {
            this.theme.overrides.MuiTableCell.root.padding = props.cellPadding;
        }
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        return (
            nextProps.rerender !== this.props.rerender
            ||
            (nextProps.tableName && nextProps.tableName !== this.props.tableName)
            // ||
            // (nextProps.pagingData && JSON.stringify(nextProps.pagingData) !== JSON.stringify(this.props.pagingData))
            // ||
            // this.searchOpen !== nextProps.searchOpen
            // ||
            // this.options.searchOpen !== nextProps.options.searchOpen
            // ||
            // !this.state.options && this.state.options !== nextState.options
            // ||
            // !this.state.options && this.state.options !== nextState.options
        );
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        // if (prevProps.tableName !== this.props.tableName) {
        //     this.getTableStateFromCookies();
        // }
    }

    componentDidMount() {
        this.getTableStateFromCookies(() => {
            this.forceUpdate();
        });
    }

    getTableStateFromCookies = (callback) => {
        const {cookies} = this.props;
        const tableState = getCookies(cookies, 'table-state');
        // console.error('+++ getTableStateFromCookies() +++ tableState:', newAnyDataRef(tableState));
        if (tableState) {
            const {
                setTableStateContext,
            } = this.context;
            if (setTableStateContext !== undefined) {
                setTableStateContext(
                    {
                        tableStateContext: tableState,
                    }, () => {
                        if (callback) {
                            callback();
                        }
                    });
            }
        }
    }

    extendOptions = () => {
        const {
            options,
            intl,
            data,
            pagingData,
            noDataText,
            onChangePage,
            onChangeRowsPerPage,
            onColumnSortChange,
        } = this.props;

        const extOptions: MUIDataTableOptions = options;

        extOptions.onTableChange = (action, tableState) => {
            // console.error('+++ extOptions.onTableChange() +++ action:', action);
            // console.error('+++ extOptions.onTableChange() +++ tableState:', tableState);
            if (
                action === 'sort'
                || action === 'changePage'
                || action === 'changeRowsPerPage'
                || action === 'search'
                || action === 'propsUpdate'
            ) {
                const state = {
                    // activeColumn: tableState.activeColumn,
                    // announceText: tableState.announceText,
                    // columnOrder: tableState.columnOrder,
                    // columns: tableState.columns,
                    // count: tableState.count,
                    // expandedRows: tableState.expandedRows,
                    // filterData: tableState.filterData,
                    // filterList: tableState.filterList,
                    // grouping: tableState.grouping,
                    // groupingData: tableState.groupingData,
                    // +++ 2022.11.14 +++
                    // page: (data || []).length > 0 ? tableState.page : 0,
                    page: tableState.page,
                    // previousSelectedRow: tableState.previousSelectedRow,
                    rowsPerPage: tableState.rowsPerPage,
                    // rowsPerPageOptions: tableState.rowsPerPageOptions,
                    // searchProps: tableState.searchProps,
                    searchText: tableState.searchText,
                    searchOpen: (Boolean(tableState.searchText) && tableState.searchText !== ''),
                    // searchOpen: tableState.searchOpen,
                    // selectedRows: tableState.selectedRows,
                    // showResponsive: tableState.showResponsive,
                    sortOrder: tableState.sortOrder,
                };
                this.onChangeState(state);
            }
        };

        // console.error('+++ extendOptions() +++ extOptions.serverSide:', extOptions.serverSide);
        if (extOptions.serverSide) {
            extOptions.count = pagingData?.totalCount | 0;
            extOptions.page = pagingData?.page | 0;

            extOptions.onChangePage = (currentPage) => {
                if (!!onChangePage) {
                    onChangePage(currentPage, extOptions.rowsPerPage, extOptions.sortOrder?.name, extOptions.sortOrder?.direction, this._searchText);
                }
            };
            extOptions.onChangeRowsPerPage = (rowsPerPage) => {
                if (!!onChangeRowsPerPage) {
                    extOptions.page = pagingData?.page | 0;
                    onChangeRowsPerPage(rowsPerPage, extOptions.sortOrder?.name, extOptions.sortOrder?.direction, this._searchText);
                }
            };
            extOptions.onColumnSortChange = (changedColumn, direction) => {
                if (!!onColumnSortChange) {
                    extOptions.page = pagingData?.page | 0;
                    onColumnSortChange(changedColumn, direction, extOptions.rowsPerPage, this._searchText);
                }
            }
            extOptions.onSearchChange = (searchText) => {
                // console.error('+++ onSearchChange() +++ searchText:', searchText);
            }

            extOptions.customSearchRender = (searchText, handleSearch, hideSearch, options) => {
                return <CustomSearchDatatable onSearch={(searchString) => this.onServersideSearch(searchString, options)} />
            }
        }

        extOptions.searchText = undefined;
        // extOptions.searchText = this.getSearchText();
        // extOptions.searchOpen = this.getSearchOpen();
        // extOptions.searchOpen = Boolean(this.getSearchText());
        if (!extOptions.serverSide) {
            extOptions.sortOrder = this.getSortOrder();
        }
        // console.error('+++ extendOptions() +++ extOptions.sortOrder:', extOptions.sortOrder);
        if (extOptions.pagination) {
            extOptions.page = this.getPage();
            extOptions.rowsPerPage = this.getRowsPerPage(extOptions.rowsPerPageOptions);
        }

        extOptions.textLabels = {
            body: {
                noMatch: !!noDataText ? noDataText : intl.formatMessage({
                    id: 'message.datatable.no_records',
                    defaultMessage: 'No records.',
                }),
                // noMatch: <a href="http://www.aport.ru">ASDF</a>,
                toolTip: intl.formatMessage({
                    id: 'message.datatable.sort',
                    defaultMessage: 'Sort',
                }),
                columnHeaderTooltip: column => `${intl.formatMessage({
                    id: 'message.datatable.columnHeaderTooltip',
                    defaultMessage: 'Sort for',
                })} ${column.label}`
            },
            toolbar: {
                search: intl.formatMessage({
                    id: 'message.datatable.search',
                    defaultMessage: 'Search',
                }),
            },
            pagination: {
                next: intl.formatMessage({
                    id: 'message.datatable.next_page',
                    defaultMessage: 'Next page',
                }),
                previous: intl.formatMessage({
                    id: 'message.datatable.previous_page',
                    defaultMessage: 'Previous page',
                }),
                jumpToPage: intl.formatMessage({
                    id: 'message.datatable.jump_to_page',
                    defaultMessage: 'Jump to page',
                }),
                rowsPerPage: intl.formatMessage({
                    id: 'message.datatable.rows_per_page',
                    defaultMessage: 'Rows per page:',
                }),
                displayRows: intl.formatMessage({
                    id: 'message.datatable.of',
                    defaultMessage: 'of',
                }),
            },
        };

        // this.setState({
        //     options: extOptions,
        // });

        // extOptions.customFooter = (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
        //     return (
        //         <FooterDatatable
        //             count={count}
        //             page={page}
        //             rowsPerPage={rowsPerPage}
        //             changeRowsPerPage={changeRowsPerPage}
        //             changePage={changePage}
        //             textLabels={textLabels}
        //         />
        //     );
        // };

        if (options.pagination !== false) {
            extOptions.customFooter = (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
                // console.error('+++ extOptions.customFooter() +++ count:', count);
                // console.error('+++ extOptions.customFooter() +++ ===> page:', page);
                // console.error('+++ extOptions.customFooter() +++ pagingData:', pagingData);
                // console.error('+++ extOptions.customFooter() +++ pagingData?.page:', pagingData?.page);
                // console.error('+++ extOptions.customFooter() +++ extOptions.serverSide:', extOptions.serverSide);
                // console.error('+++ extOptions.customFooter() +++ extOptions.page:', extOptions.page);
                // console.error('+++ extOptions.customFooter() +++ rowsPerPage:', rowsPerPage);
                if (extOptions.serverSide) {
                    page = pagingData?.page;
                }
                // console.error('+++ extOptions.customFooter() +++ ===> FINAL page:', page);
                // console.error('+++ extOptions.customFooter() +++ ===> FINAL (data || []).length:', (data || []).length);
                // console.error('+++ extOptions.customFooter() +++ ===> FINAL Math.ceil(count / rowsPerPage):', Math.ceil(count / rowsPerPage));
                // console.error('+++ extOptions.customFooter() +++ (data || []).length && count > 0 && (page <= Math.ceil(count / rowsPerPage)):', (data || []).length && count > 0 && (page <= Math.ceil(count / rowsPerPage)));

                return (
                    <CustomFooterDatatable
                        count={count}
                        // page={(data || []).length && count > 0 && (page <= Math.ceil(count / rowsPerPage)) ? page : 0}
                        page={page || 0}
                        rowsPerPage={rowsPerPage}
                        changeRowsPerPage={changeRowsPerPage}
                        changePage={changePage}
                        textLabels={textLabels}
                    />
                );
            };
        }

        return extOptions;
    }

    onChangeState = (tableState) => {
        const {tableName, cookies} = this.props;
        const {
            setTableStateContext,
            tableStateContext,
        } = this.context;
        if (setTableStateContext !== undefined) {
            const newTableStateContext = tableStateContext.tableStateContext || {};
            newTableStateContext[tableName] = tableState;
            setTableStateContext(
                {
                    tableStateContext: newTableStateContext,
                }
            );
            setCookies(cookies, 'table-state', JSON.stringify(newTableStateContext));
        }
    }

    getSortOrder = (): { name: string, direction: 'asc' | 'desc' } => {
        return this.getTableState().sortOrder || undefined;
    }

    getSearchText = (): string => {
        return this.getTableState().searchText || '';
    }

    getSearchOpen = (): boolean => {
        const searchText = this.getTableState().searchText;
        return (Boolean(searchText) && searchText !== '');
    }

    onServersideSearch = (searchText: string, options: MUIDataTableOptions): void => {
        const {
            onServersideSearch,
        } = this.props;
        // console.error('+++ onServersideSearch() +++ searchText:', searchText);
        // console.error('+++ onServersideSearch() +++ options.rowsPerPage:', options.rowsPerPage);
        // console.error('+++ onServersideSearch() +++ options:', options);
        this._searchText = searchText;
        if (!!onServersideSearch) {
            onServersideSearch(0/*options.page*/, options.rowsPerPage, options.sortOrder?.name, options.sortOrder?.direction, searchText);
        }
    }

    getPage = (): number => {
        return this.getTableState().page || 0;
    }

    getRowsPerPage = (rowsPerPageOptions: []): number => {
        let rowsPerPage = this.getTableState().rowsPerPage;
        if (!rowsPerPage) {
            rowsPerPage = (rowsPerPageOptions || [])[0];
            if (!rowsPerPage) {
                rowsPerPage = 10;
            }
        }
        return rowsPerPage;
    }

    getTableState = () => {
        const {tableStateContext} = this.context;
        const {tableName} = this.props;
        return (((tableStateContext || {}).tableStateContext || {})[tableName] || {});
    }


    render() {
        const {
            data,
            pagingData,
            columns,
            title,
            elevation,
            style,
        } = this.props;
        // const {options} = this.state;
        // const {tableStateContext} = this.context;
        const options = this.extendOptions();
        // console.error('+++ render() +++ options:', options);

        // this.searchOpen = this.getSearchOpen();
        // options.searchOpen = Boolean(options.searchText);
        // setTimeout(() => {
        //     this.options.searchOpen = true;
        // });

        return (options ?
                <MuiThemeProvider theme={this.theme}>
                    <MUIDataTable
                        title={title || ""}
                        data={data || []}
                        columns={columns}
                        options={options}
                        style={style}
                        elevation={elevation}
                    />
                </MuiThemeProvider>
                : <Box/>
        );
    }
}

DatatableComponent.propTypes = {
    tableName: PropTypes.string.isRequired,
    cellPadding: PropTypes.string,
    noDataText: PropTypes.object,
    title: PropTypes.any.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    pagingData: PropTypes.object.isRequired,
    rerender: PropTypes.number,
    elevation: PropTypes.number,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    options: PropTypes.object.isRequired,
    style: PropTypes.object,
    intl: PropTypes.object.isRequired,
    disableShadow: PropTypes.bool,
    disableBorderRadius: PropTypes.bool,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    onColumnSortChange: PropTypes.func,
    onServersideSearch: PropTypes.func,
};

DatatableComponent.defaultProps = {
    disableShadow: true,
    disableBorderRadius: true,
};

DatatableComponent.contextTypes = {
    tableStateContext: PropTypes.object,
    setTableStateContext: PropTypes.func,
};


export default compose(
    // injectIntl,
    withStyles(styles),
    withCookies,
)(DatatableComponent);
