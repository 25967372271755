import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import mainStyles from "../../styles/mainStyles";
import {getContStatusStyle, getRandomKey} from "../../utils";
import {NosologyType} from "../../const";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    priority: {
        fontWeight: 500,
    },
}));

const IbsDrugGroupCombinationView = (props) => {
    const classes = useStyles();
    const drugGroupCombination = props.drugGroupCombination;

    return drugGroupCombination.drugGroups.map((drugGroup, index) =>
        Array(drugGroup.multiselectMin).fill(null).map((v, index) =>
            <Typography key={getRandomKey()} component={'span'}
                        className={`${getContStatusStyle(drugGroup.compStatus, classes, NosologyType.IBS)}`}>
                {drugGroup.shortName}
            </Typography>
        ))
        .flatMap((value, index) => {
            return value;
        })
        .reduce((prev, curr) => [
            prev,
            <Typography key={getRandomKey()} component={'span'} className={classes.plus}>
                {' + '}
            </Typography>,
            curr
        ]);
}

export default IbsDrugGroupCombinationView;
