import React from 'react';
// import ReactMarkdown from 'markdown-to-jsx';
import ReactMarkdown from "react-markdown";
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import mainStyles from "../../styles/mainStyles";
import {getDevicePixelRatio} from "../../utils";

/*
const styles = (theme) => ({
    listItem: {
        marginTop: theme.spacing(1),
    },
});
*/

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    listItem: {
        marginTop: theme.spacing(1),
    },
}));

/* options for markdaun-jsx *
const options = {
    overrides: {
        h1: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h5',
            },
        },
        h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
        h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
        h4: {
            component: Typography,
            props: { gutterBottom: true, variant: 'caption', paragraph: true },
        },
        p: { component: Typography, props: { paragraph: true } },
        a: { component: Link },
        li: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <li className={classes.listItem}>
                    <Typography component="span" {...props} />
                </li>
            )),
        },
        grid: {
            component: Grid,
        },
    },
};
/* options for markdaun-jsx */

export default function Markdown(props) {
    const classes = useStyles();

    const components = {
        h1: ({node, ...props}) => {
            // console.error('+++ node:', node);
            // console.error('+++ props:', props);
            return <Typography variant={'h5'} gutterBottom={true} color={"primary"} {...props} />
        },
        img: ({node, ...props}) => {
            // console.error('+++ node:', node);
            // console.error('+++ props:', props);
            // console.error('+++ props.class:', props.class);

            const scale = getDevicePixelRatio();
            // console.error('+++ SCALE:', scale);

            props.src = '/instructions/images/' + (scale > 1 ? 'hi_res' : 'low_res') + '/' + props.src;
            return <img className={classes.helpImage} {...props} alt={''} />
        },
        div: ({node, ...props}) => {
            // console.error('+++ div +++ node:', node);
            // console.error('+++ div +++ props:', props);
            // console.error('+++ div +++ className:', className);
            return <div className={classes.helpItem} {...props} />
        },
    };

    return <ReactMarkdown
        // options={options}
        components={components}
        {...props}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
    />;
}
