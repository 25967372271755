
export const ThNames1 = [
  {
    title: 'AAD',
    name: 'aad',
    isSimple: false,
  },
  {
    title: 'PVI',
    name: 'pvi',
    isSimple: false,
  },
  {
    title: 'BBLOCK',
    name: 'bblock',
    isSimple: false,
  },
  {
    title: 'ABLAV',
    name: 'ablav',
    isSimple: true,
  },
];

export const ThNames2 = [
  {
    title: 'NOAC',
    name: 'noac',
    isSimple: false,
  },
  {
    title: 'LAA',
    name: 'laa',
    isSimple: false,
  },
];

export const FeatureNames = {
  'chadsVasc': 'CHA₂DS₂-VASc',
  'chads': 'CHADS2',
  'hasBled': 'HAS-BLED',
};

export const TypeNames = {
  1: 'Efficacy',
  2: 'Safety',
  3: 'Mortality',
};

