import NumberFormat from 'react-number-format';
import green from "@material-ui/core/colors/green";
import {loadGetTherapy} from "./query/common";
// import red from "@material-ui/core/colors/red";

export const globalTheme = {
    palette: {
        primary: {
            // light: indigo[300],
            // main: indigo[500],
            main: '#0f7fa5',
            // dark: indigo[700],
        },
        secondary: {
            light: green[300],
            main: green[500],
            dark: green[700],
        },
        background: {
            default: '#eee'
        },
    },
    typography: {
        useNextVariants: true,
    },
};

export const ChartMarkerColors = [
    '#AD1457',
    '#6A1B9A',
    '#827717',
    '#795548',
    '#616161',
    '#880E4F',
    '#4A148C',
    '#006064',
    '#6D4C41',
    '#C51162',
    '#00695C',
    '#AA00FF',
    '#004D40',
    '#9E9D24',
    '#00796B',
    '#00838F',

/*
    'rgb(241, 92, 34)',
    'rgb(253, 186, 49)',
    'rgb(159, 163, 116)',
    // 'rgb(0, 124, 133)',
    'rgb(0, 35, 93)',
    'rgb(156, 96, 14)',
    'rgb(0, 159, 148)',
    'rgb(74, 99, 174)',
    'rgb(78, 38, 131)',
    'rgb(89, 89, 44)',
    'rgb(0, 104, 87)',
    'rgb(37, 8, 88)',
    'rgb(149, 156, 161)',
    'rgb(96, 96, 96)',
    'rgb(176, 0, 110)',
    'rgb(100, 34, 0)',
    'rgb(226, 230, 91)',
*/
];

export const UserPermissions = {
    PATIENT_SUMMARY_R: 'PATIENT_SUMMARY_R',
    PATIENT_EPICRISIS_R: 'PATIENT_EPICRISIS_R',
    PATIENT_EPICRISIS_W: 'PATIENT_EPICRISIS_W',
};

export const GroupedBarChartColors = {
    BLUE: '#0f7fa5',
    // DARK_BLUE: '#0f4164',
    // DARK_BLUE: '#00bcd4',
    DARK_BLUE: '#e1bee7',
    GREEN: 'rgb(76, 175, 80)',
    // DARK_GREEN: 'rgb(30, 90, 40)',
    DARK_GREEN: '#f48fb1',
    RED: 'rgb(244, 67, 54)',
};

export const GroupedBarChartColorsBySelectType = {
    1: GroupedBarChartColors.BLUE,
    2: GroupedBarChartColors.GREEN,
};

export const BarChartOrientation = {
    HORIZONTAL: 'h',
    VERTICAL: 'v',
};

export const ChartMarkers = {
    0: "circle",
    1: "square",
    2: "triangle-up",
    3: "diamond",
};

export interface GroupedBarChartConfig {
    title: string;
    data: [
        {
            x: number[];
            y: number[];
            name: string;
            orientation: 'h',
            type: 'bar',
            hoverinfo: 'none',
            textposition: 'auto',
            textfont: {
                size: 16,
                family: 'Roboto, Helvetica, Arial, sans-serif',
            },
            insidetextfont: {
                color: 'rgb(255, 255, 255)',
            },
            marker: {
                // color: 'rgb(63, 81, 181)',
                color: string;
            },
        },
        {
            x: number[];
            y: number[];
            name: string;
            orientation: 'h',
            type: 'bar',
            hoverinfo: 'none',
            textposition: 'auto',
            textfont: {
                size: 16,
                family: 'Roboto, Helvetica, Arial, sans-serif',
            },
            insidetextfont: {
                color: 'rgb(255, 255, 255)',
            },
            marker: {
                // color: 'rgb(76, 175, 80)',
                color: string;
            },
        },
    ],
}

export interface PolarData {
    type: string; // 'scatterpolar';
    r: number[];
    theta: string[];
    // fill: 'toself',
    fill: string; // 'none';
    line: {
        color: string; // 'black'
    };
    name: string; // 'Group A',
    meta: string; // 'Group A',
    // hoverinfo: 'r+theta+meta',
    hovertemplate: string;
}

export interface PolarChartConfig {
    title: string;
    data: PolarData[];
}

export const Role = {
    USER: "USER",
    ADMIN: "ADMIN",
    ROOT: "ROOT",
    EXPERT: "EXPERT",
    DEMO: "DEMO",
    ANONYMOUS: "ANONYMOUS",
    EDUCATION: "EDUCATION",
    RKO: "RKO",
    RESEARCH_SAMARA: "RESEARCH_SAMARA",
};

export const ProfileType = {
    LEADER: 10,
    EXPERT: 20,
    BUSINESS: 30,
    STUDENT: 40,
};

export const ProfileTypeNames = [
    [ProfileType.LEADER, 'Лидер мнений'],
    [ProfileType.EXPERT, 'Врач-эксперт'],
    [ProfileType.BUSINESS, 'Представитель бизнеса'],
    [ProfileType.STUDENT, 'Студент'],
];

export const GRIG_TWO_COL = {
    XL: 6,
    LG: 6,
    XS: 12,
    SM: 6,
    MD: 6,
};

export const PScoreChartType = {
    GENERAL: 'GENERAL',
    COMPARISONS: 'COMPARISONS',
    COMPARISONS_STATISTICS: 'COMPARISONS_STATISTICS',
    STATISTICS: 'STATISTICS',
};

export const ChartType = {
    FOREST_GROUP: 'FOREST_GROUP',
};

export const NosologyType = {
    // HYPERTENSION: 'HYPERTENSION',
    // ATRIAL_FIBRILLATION: 'ATRIAL_FIBRILLATION',
    // HEART_FAILURE: 'HEART_FAILURE',
    // BREAST_CANCER: 'BREAST_CANCER',
    // DYSLIPIDEMIA: 'DYSLIPIDEMIA',
    // IBS: 'IBS',
    HYPERTENSION: '1',
    ATRIAL_FIBRILLATION: '2',
    HEART_FAILURE: '3',
    BREAST_CANCER: '4',
    DYSLIPIDEMIA: '5',
    IBS: '6',
};

export const NosologyAPIUrl = {
    [NosologyType.HYPERTENSION]: undefined,
    [NosologyType.ATRIAL_FIBRILLATION]: undefined,
    [NosologyType.HEART_FAILURE]: undefined,
    [NosologyType.DYSLIPIDEMIA]: undefined,
};

export const SelectNosologyByField = {
    HYPERTENSION: {
        field: 15,
        values: [35],
    },
    ATRIAL_FIBRILLATION: {
        field: 60,
        values: [134],
    },
    HEART_FAILURE: {
        field: 12,
        values: [27, 383],
    },
};

export const TableNameType = {
    ADDED_DRUGS: 'ADDED_DRUGS',
    ADDING_DRUGS: 'ADDING_DRUGS',
    ADDING_PATIENT_NOSOLOGIES: 'ADDING_PATIENT_NOSOLOGIES',
    PATIENTS: 'PATIENTS',
    PATIENTS_PRIVATE: 'PATIENTS_PRIVATE',
    PATIENTS_PUBLIC: 'PATIENTS_PUBLIC',
    PATIENT_BASELINE: 'PATIENT_BASELINE',
    PATIENT_NOSOLOGIES: 'PATIENT_NOSOLOGIES',
};

export const PrescriptionType = {
    DRUGS: 'DRUGS',
    DEVICES: 'DEVICES',
    STYLE_LIFE: 'STYLE_LIFE',
    DRUGS_NAME: 'DRUGS_NAME',
    THERAPY_DRUGS: 'THERAPY_DRUGS',
    THERAPY_SIMPLE: 'THERAPY_SIMPLE',
    SUBTREATMENT: 'SUBTREATMENT',
    DRUG_COMPOSITE: 'DRUG_COMPOSITE',
    REGIMEN: 'REGIMEN',
    THERAPY: 'THERAPY',
    SET: 'SET',
};

export const PatientType = {
    PRIVATE: 'PRIVATE',
    PUBLIC: 'PUBLIC',
    EDUCATION: 'EDUCATION',
};

export const TherapyType = {
    DRUGS: 'DRUGS',
    SIMPLE: 'SIMPLE',
};

export const OpenType = {
    PATIENT: 'PATIENT',
    NOSOLOGY: 'NOSOLOGY',
};

export const RoleType = {
    SUPERVISOR: 'SUPERVISOR',
    ALL: 'ALL',
    PRIVATE: 'PRIVATE',
    PUBLIC: 'PUBLIC',
    NOT: 'NOT',
};

export const DrugCompStatus = {
    NORMAL: 0,
    RECOMMEND: 1,
    WARNING: 2,
    DANGER: 3,
    DRUG_CURRENTLY: 4,
};

export const DrugAvailStatus = {
    NORMAL: 0,
    NOT_ON_SALE: 1,
    NOT_RECOMMENDED: 2,
}

export const ParameterType = {
    OPTION: 'OPTION',
    INT: 'INT',
    DECIMAL: 'DECIMAL',
    BOOLEAN: 'BOOLEAN',
    DATE: 'DATE',
};

export const NosologyStatus = {
    AVAILABLE: 'AVAILABLE',
    NOT_AVAILABLE: 'NOT_AVAILABLE',
};

export const NosologyInputSourceEnum = {
    TEXT: 'TEXT',
    ALGORITHM: 'ALGORITHM',
    ICD: 'ICD',
    MANUAL: 'MANUAL',
};

export const PurchaseStatus = {
    WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const ProfileTariffsTabEnum = {
    TARIFFS: 'TARIFFS',
    PURCHASES: 'PURCHASES',
}

export const PaymentStatus = {
    PENDING: 'PENDING',
    WAITING_FOR_CAPTURE: 'WAITING_FOR_CAPTURE',
    SUCCEEDED: 'SUCCEEDED',
    CANCELED: 'CANCELED',
};

export const SummaryType = {
    REGISTER_PREV: 'REGISTER_PREV',
    REGISTER_NEXT: 'REGISTER_NEXT',
    MEDICBK: 'MEDICBK',
};

export const SummaryContentType = {
    XML: 'XML',
    JSON: 'JSON',
    HTML: 'HTML',
};

// export const PatientSummaryStatus = {
//     WAITING_FOR_RESULT: 'WAITING_FOR_RESULT',
//     READY: 'READY',
// };

export const ProcessingStatus = {
    NOT_PROCESSING: 'NOT_PROCESSING',
    PROCESSING: 'PROCESSING',
    READY: 'READY',
    ERROR: 'ERROR',
};

export const ProcessPatientByNotesStatus = {
    ERROR: 'ERROR',
    PROCESSING: 'PROCESSING',
    COMPLETE: 'COMPLETE',
};

export const PrescriptionTypeByTherapyType = {
    [TherapyType.DRUGS]: PrescriptionType.THERAPY_DRUGS,
    [TherapyType.SIMPLE]: PrescriptionType.THERAPY_SIMPLE,
};

export const ProfileModeEnum = {
    PROFILE: 'PROFILE',
    PATIENTS_PRIVATE: 'PATIENTS_PRIVATE',
    PATIENTS_PUBLIC: 'PATIENTS_PUBLIC',
    PATIENTS_EDUCATION: 'PATIENTS_EDUCATION',
}

export const ProfileProfileModeEnum = {
    PROFILE_INFO: 'PROFILE_INFO',
    PROFILE_TARIFFS: 'PROFILE_TARIFFS',
}

export const PatientTypeToProfileModeEnum = {
    [PatientType.PUBLIC]: ProfileModeEnum.PATIENTS_PUBLIC,
    [PatientType.EDUCATION]: ProfileModeEnum.PATIENTS_EDUCATION,
}

export const ProfileModeEnumToPatientType = {
    [ProfileModeEnum.PATIENTS_PUBLIC]: PatientType.PUBLIC,
    [ProfileModeEnum.PATIENTS_EDUCATION]: PatientType.EDUCATION,
}

export const ParameterInputSourceEnum = {
    TEXT: 'TEXT',
    STRUCTURED: 'STRUCTURED',
    MANUAL: 'MANUAL',
}

export interface Agreement {
    content: string;
    accept: string;
}

export interface Product {
    id: number;
    name: string;
    price: number;
    oldPrice: number;
    descr: string;
    ord: number;
    agreements: Agreement[];
}

export interface Payment {
    id: number;
    providerId: string;
    idempotenceKey: string;
    status: PaymentStatus;
    confirmationUrl: string;
    createAt: string;
    updateAt: string;
}

export interface Purchase {
    id: number;
    username: string;
    product: Product;
    payment: Payment;
    status: PurchaseStatus;
    createAt: string;
    updateAt: string;
}

export interface ValueNumberType {
    // name: string;
    value: number;
    // description: string;
    max: number;
    min: number;
    step?: number;
    required: boolean;
    isDefault: boolean;
}

export const MessageType = {
    INFO: 'INFO',
    WARNING: 'WARNING',
    DANGER: 'DANGER',
    SUBTITLE: 'SUBTITLE',
    DESCRIPTION: 'DESCRIPTION',
}

export const RegType = {
    DIRECT_REG: 10,
    REQ_LINK: 11,
    INVITE_LINK: 20,
    RESET_PASSWORD: 30,
    INVITE_PUBLIC: 40,
}

export const riskTextColors = {
    1: 'green',
    2: 'orange',
    3: 'red',
    4: 'darkred',
}

export interface Message {
    type: MessageType;
    payload: string;
}

export interface ValueOptionType {
    name: string;
    valueId: string;
    description: string;
    selected: boolean;
    ord: number;
    required: boolean;
    isDefault: boolean;
    messages: Message[];
}

export interface StaffRole {
    access_baseline: RoleType;
    access_prescription: RoleType;
    access_patient: RoleType;
}

export interface FieldAndValueDto {
    idField: number;
    idValue: number;
    updateDate: string;
}

export interface BaselineFieldValuesDto {
    id: number;
    name: string;
    descr: string;
    selected: boolean;
}

export interface BaselineParameterDto {
    id: number;
    name: string;
    sectionId: number;
    updateDate: string;
    // value: number;
    type: ParameterType;
    // values: BaselineFieldValuesDto[];
    values: string[];
    ord: number;
    modify: string;

    opt: string;
    enable: boolean;
    influence: boolean;
    visible: boolean;

    modified: boolean;

    noData: boolean;

    main: boolean;

    units: string;
    messages: Message[];
    sourceDetection: ParameterInputSourceEnum;
}

export interface BaselineParameterLiteDto {
    id: number;
    value: string;
    enable: boolean;
    visible: boolean;
    messages: Message[];
    modified: boolean;
    noData: boolean;
}

export interface SectionListDto {
    id: number;
    value: string;
    enable: boolean;
    visible: boolean;
    messages: Message[];
    modified: boolean;
}

export interface BaselineLiteAndSectionsDto {
    baselineParameterLiteDtoList: BaselineParameterLiteDto[];
    sectionDtoList: SectionListDto[];
}

export interface BaselineParameterStrictDto {
    id: number;
    name: string;
    type: ParameterType;
    value: string;
    ord: number;
    updateDate: string;
    opt: string;
    units: string;
    messages: Message[];
}

export interface ParameterOptDto {
    min: number;
    max: number;
    units: string;
    required: boolean;
    def: any;
    allowedNoData: boolean;
    defNoData: boolean;
}

export interface BaselineDetect {
    id: number;
    type: string;
    defaultValue: string;
    minValue: string;
    maxValue: string;
}

export interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    field: BaselineParameterDto;
}

export interface BaselineParametrSectionDto {
    id: number;
    name: string;
    ord: number;
    expanded: boolean;
    parameters: BaselineParameterDto[];
}

export interface CompanyDto {
    id: number;
    name: string;
    modify: string;
}

export interface RoleDto {
    id: number;
    name: string;
    access_baseline: RoleType;
    access_prescription: RoleType;
    access_patient: RoleType;
}

export interface TranslateInfo {
    key: string;
    value: string;
}

export interface HistoryData {
    therapyCompare: string;
}

export interface PatientHistoryDto {
    id: number;
    stepNum: number;
    patientId: number;
    nosologyId: number;
    staffId: number;
    response: string;
    modify: string;
    data: HistoryData;

}

export interface NosologyDto {
    id: number;
    ord: number;
    name: string;
    descr: string;
}

export interface ParametersForBaselineDto {
    fieldId: number;
    value: string;
    modified: boolean;
    updateDate: string;
}

export interface ParameterDto {
    fieldId: number;
    type: ParameterType;
    value: string;
    valueDefault: boolean;
    modified: boolean;
    noData: boolean;
    updateDate: string;
    sourceDetection: ParameterInputSourceEnum;
}

export interface PatientNosologyState {
    id: number;
    history: PatientHistoryDto;

    status: NosologyStatus;
    messages: Message[];

    // nosology: NosologyDto;
    // outdated: boolean;
    // parameters: ParameterDto[];
    // status: NosologyStatus;
    // statusText: string;
}

export interface Nosology {
    id: number;
    updateDate: number;
    sourceDetection: NosologyInputSourceEnum[];
}

export interface PrescriptionInputDto {
    type: PrescriptionType;
    ids: number[];
    names?: string[];
}

export interface PatientHistoryInputDto {
    stepNum: number;
    patientId: number;
    nosologyId: number;
    response: string;
    prescriptions: PrescriptionInputDto[];
    parameters: ParameterDto[];
    data: HistoryData;
}

export interface NosologyExtDto {
    id: number;
    name: TranslateInfo[];
    type: string;
    passed: boolean;
    historyId: number;
    updateDate: string;
}

export interface NosologyStatusDto {
    id: number;
    status: NosologyStatus;
    messages: Message[];
}

export interface StaffDto {
    id: number;
    firstName: string;
    lastName: string;
    middleName: string;
    role: RoleDto;
    companies: CompanyDto[];
    nosologies: NosologyDto[];
    specialty: string;
    education: string;
    training: boolean;
    modify: string;
}

export interface PatientExtDto {
    id: number;
    uuid: string;
    name: string;
    visualName: string;
    companies: CompanyDto[];
    create: string;
    modify: string;
    updateNosology: string;
    // fieldValue: FieldAndValueDto[];
    // idsValue: number[];
    // baseline: BaselineFieldDto[];
    baseline: BaselineParametrSectionDto[];
    baselineParameterStrict: BaselineParameterStrictDto[];
    staticBaseline: BaselineParametrSectionDto[];
    type: PatientType;
    staff: StaffDto;
    nosologies: Nosology[];
    nosologyStates: PatientNosologyState[];
    parameters: ParameterDto[];
    notesDateList: string[];
    notes: Note[];
    tags: Tag[];
    compositeIds: number[];
    drugs: any[];
}

export interface PatientDto {
    id: number;
    uuid: string;
    name: string;
    visualName: string;
    create: string;
    modify: string;
    updateNosology: string;
    type: PatientType;
    staff: StaffDto;
    nosologies: PatientNosologyState[];
    notes: string;
}

export interface PagePatientDto {
    payload: PatientDto[];
    pages: number;
    totalCount: number;
}

export interface PatientAccess {
    owner: boolean;
    patient: {
        create: boolean,
        update: boolean,
    };
    baseline: {
        update: boolean,
    };
    prescription: {
        update: boolean,
        view: boolean,
    };
}

export interface ActivePatient {
    patient: string;
    patientUuid: string;
    group: string;
    patientType: PatientType;
    patientName: string;
    nosology: string;
    history: string;
    action: string;
    getData: string;
    activeTab: string;
    mode: string;
    node: string;
}

export interface DrugComposite {
    id: number;
    name: string;
    drugId: number;
    drugName: string;
    dosage: string;
}

export interface DrugSearch {
    drugId: number;
    drugName: string;
    normalCompositeId: number;
    availComposites: DrugComposite[];
}

export interface SearchResult {
    query: string;
    drugs: DrugSearch[];
}

export interface TradeItem {
    dosage: string;
    id: number;
    innName: string;
    manufacturer: string;
    manufacturerCountry: string;
    name: string;
    innCount: number;
    regCertOwner: string;
    regCertOwnerCountry: string;
}

export interface DrugCompositeId {
    nosologyId: number;
    drugCompositeId: number;
    dateCreate: string;
}

export interface SearchDrugsResponse {
    drugs: DrugComposite[];
    query: string;
    tradeItems: TradeItem[];
}

export interface DrugWrap {
    alterNames: string[];
    dosage: string[];
    id: number;
    name: string;
    tradeItems: TradeItem[];
}

export interface ItemsDto {
    id: number;
    name: string;
    shortName: string;
}

export interface SkipVarsDto {
    id: number;
    name: string;
    shortName: string;
}

export interface GetTherapyAvailableResult {
    thType: TherapyType;
    subTherapyId: number;
    name: string;
    skipVars: SkipVarsDto[];
    items: ItemsDto[]; // список элементов для выбора терапии для типа SIMPLE
}

export interface TherapyDataIn {
    thType: TherapyType;
    subTherapyId?: number;
    selectIds: number[];
}

export interface TherapyRecommendIn {
    therapyId: number;
    selectDrugs: string[];
}

export interface TherapyRecommendInHypertension {
    combId: number;
    groupDrugs: string[];
}

export interface PScoreDataItem {
    name: string;
    value: number;
    isSelect: boolean;
    statistic?: boolean;
    selectType: number;
}

export interface PScoreData {
    efficacy: PScoreDataItem[];
    safety: PScoreDataItem[];
}

export interface PScoreDto {
    data: PScoreData;
}

export interface ForestPlotsDto {
    title: string;
    url: string;
}

export interface CompsDto {
    id: number;
    name: string;
    riskTh: number;
    riskPl?: number;
}

export interface CombRiskDto {
    id: number;
    name: string;
    selectType: number;
    statistic?: boolean;
    comps: CompsDto[];
    message?: Message;
}

export interface TherapyCompareDto {
    id: number;
    name: string;
    shortName: string;
    score: number;
    scoreDescr?: string;
    descr: string;
    compDrugs?: string[];
    compGroups?: ItemsDto[];
    pscore?: PScoreDto;
    forestPlots?: ForestPlotsDto[];
    combRisk?: CombRiskDto[];
    combRiskChartData?: GroupedBarChartConfig;
    comp_cont?: string[] | ItemsDto[];
}

export interface StatDataInInputDto {
    excludePatientIds: number[];
    excludeStaffIds: number[];
    includePatientIds: number[];
    includeStaffIds: number[];
}

export interface TherapyCompareStatisticsDto {
    id: number;
    name: string;
    shortName?: string;
    pscore?: PScoreDto;
    combRisk?: CombRiskDto[];
}

export interface DrugsByDrugDosageNamesResult {
    id: number;
    name: string;
    tradeItems: TradeItem[];
}

export interface DrugsWithTradeItem {
    drugName: string;
    tradeItems: TradeItem[];
    drugId: number;
}

export interface ParamsDrugs {
    groupName: string;
    drugs: DrugsWithTradeItem[];
}

export interface GroupedBarChartInitParams {
    name: string;
    color: string;
}

export const GroupType = {
    TRAINING: 'TRAINING',
    CME: 'CME',
}

export const PatientTypeToGroupType = {
    [PatientType.PUBLIC]: GroupType.TRAINING,
    [PatientType.EDUCATION]: GroupType.CME,
}

export interface GroupDto {
    id: number;
    name: string;
    active: boolean;
    modify: string;
    children: GroupDto[];
    ord: number;
    type: GroupType;
    mode?: ProfileProfileModeEnum;
}

export interface SubgroupDto {
    id: number;
    name: string;
    riskPl: number;
    riskTh: number;
}

export interface OutcomeRiskForTherapyDto {
    riskPlName: string;
    riskThName: string;
    data: {
            id: number;
            name: string;
            subgroups: SubgroupDto[];
        }[];
}

export interface PatientNotesInput {
    id: number;
    type: number;
    payload: string;
}

export interface PatientNotes {
    id: number;
    payload: string;
    patientId: number;
    create: string;
    modify: string;
}

export interface PatientSummary {
    patientId: number;
    url: string;
    status: ProcessingStatus;
    description: string;
    msgUUID: string;
}

export interface ParameterInput {
    fieldId: string;
    value: string;
    type: ParameterType;
    noData: boolean;
    valueDefault: boolean;
    modified: boolean;
    updateDate: string;
}

export interface NosologyInput {
    id: number;
    updateDate: number;
    sourceDetection: NosologyInputSourceEnum[];
}

export interface PatientInputDto {
    uuid: string;
    name: string;
    type: PatientType;
    parameters: ParameterInput[];
    nosologies: NosologyInput[];
    tag: string;
    notes: PatientNotesInput[];
    compositeIds: number[];
}

export const RecommendType = {
    GUIDELINES: 'GUIDELINES',
    CONCLUSION: 'CONCLUSION',
}

export interface ServiceMessage {
    showService: boolean;
    messages: Message[];
    type: MessageType;
    // payload: string;
}

export const ServiceMessageColors = {
    [MessageType.INFO]: 'primary',
    [MessageType.WARNING]: 'error',
};

export interface ExpandCharts {
    url: string;
    title: string;
}

export interface Characteristic {
    fieldId: number;
    value: string;
    modified: boolean;
}

export interface DetectData {
    characteristics: Characteristic[];
    prescribedCompositeIds: number[];
}

export interface ProcessPatientByNotes {
    status: ProcessPatientByNotesStatus;
    errorDescr: string;
}

export interface NotesTypes {
    type: number;
    order: number;
    name: string;
    description: string;
    disabled: boolean;
}

export interface Note {
    id: string;
    type: number;
    order: number;
    payload: string;
}

export interface NotesDateList {
    visitDate: string;
}

export interface Tag {
    id: number;
    name: string;
}

export interface TagInput {
    name: string;
}

export interface RecommendGuidelinesTextsInputDto {
    nosologyId: number;
    patientId: number;
    // type: RecommendType;
    // prescriptions: PrescriptionInputDto[];
}

export interface RecommendConclusionTextsInputDto {
    nosologyId: number;
    patientId: number;
    setId: number;
    deviceIds: number[];
    // type: RecommendType;
    // prescriptions: PrescriptionInputDto[];
}

export interface StrictIndicatorsInputDto {
    nosologyId: number;
    patientId: number;
}

export interface StrictIndicators {
    id: number;
    name: string;
    value: string;
    messages: Message[];
    colorValue: number;
}

export interface ChartsInputDto {
    ids: number[];
    nosologyId: number;
    patientId: number;
    type: ChartType;
}

export interface Chart {
    title?: string;
    url: string;
}

export interface BarChartResponse {
    order: number;
    outcomeName: string;
    riskReferent: number;
    risk: number;
    riskReferentName: string;
    riskName: string;
}

export interface GetTherapyResponse {
    therapyPools: any[];
    absoluteCond: [];
    relativeCond: [];
}

export const SortTypeEnum = {
    DATA: 'DATA',
    DATA_DESC: 'DATA_DESC',
    NAME: 'NAME',
    NAME_DESC: 'NAME_DESC',
}

export interface PatientListOptions {
    size: number;
    page: number;
    sort: typeof SortTypeEnum;
    search: string;
}
