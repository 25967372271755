import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import RootContainer from '../comp/RootContainer';
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {Button, Typography, ListItemSecondaryAction} from "@material-ui/core";
import mainStyles from '../styles/mainStyles';
import {withApollo} from '@apollo/client/react/hoc';
import ProfileViewDlg from '../comp/profile/ProfileViewDlg';
import {FormattedMessage, injectIntl} from 'react-intl';

const styles = theme => ({
    ...mainStyles(theme),
    hideTextButton: {
        [theme.breakpoints.down(500)]: {
            display: 'none',
        },
    },
    hideIconButton: {
        [theme.breakpoints.up(500)]: {
            display: 'none',
        },
    }
});

class RegRequestionsA extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            isProfileShow: false,
            profileSel: undefined,
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    onIsAcceptedChange = (event, req) => {
        req.isAccepted = event.target.checked;
        this.forceUpdate();
    };

    showProfile = (request) => {
        const {getProfile, intl} = this.props;
        const {showNotify} = this.context;

        getProfile(request)
            .then(detail => {
                if (!(detail || {}).profile) {
                    showNotify(intl.formatMessage({
                        id: 'label.error.noProfile',
                        defaultMessage: 'No Profile'
                    }), 'error');
                }
                this.setState({
                    profileSel: (detail || {}).profile,
                    isProfileShow: !!(detail || {}).profile,
                });
            });
    };

    closeProfile = () => {
        this.setState({
            isProfileShow: false,
            profileSel: undefined,
        })
    };

    render() {
        const {classes, requests, noDataView, statistics} = this.props;
        const {profileSel, isProfileShow} = this.state;

        return (
            <RootContainer>
                <Paper>
                    {requests && requests.length > 0
                        ?
                        <List>
                            {requests.map(req => (
                                <ListItem key={req.id} aria-multiline={true}>
                                    {!statistics &&
                                        <Checkbox
                                            checked={req.isAccepted}
                                            onChange={event => this.onIsAcceptedChange(event, req)}
                                            color={"primary"}
                                        />
                                    }
                                    <ListItemText
                                        primary={<Typography component="span"
                                                             variant="h6">{req.username}</Typography>}
                                        secondary={
                                            <React.Fragment>
                                                {/* <Typography component="span" variant="body1">{req.role.name}</Typography> */}
                                                <Typography component="span" variant="body2"
                                                            color="textSecondary">{req.createAt}</Typography>
                                            </React.Fragment>
                                        }
                                    />
                                    {/*{req.profile &&*/}
                                    <ListItemSecondaryAction>
                                        <Button
                                            onClick={e => this.showProfile(req)}
                                            className={classes.button + ' ' + classes.hideTextButton}
                                            color="secondary"
                                            variant="outlined"
                                            size="small">
                                            <FormattedMessage id='label.information' defaultMessage='Information'/>
                                        </Button>
                                        {/*<IconButton*/}
                                        {/*    className={classes.hideIconButton}*/}
                                        {/*    aria-label="info"*/}
                                        {/*    size="small"*/}
                                        {/*    onClick={e => this.showProfile(req.profile)}*/}
                                        {/*>*/}
                                        {/*    <InfoIcon/>*/}
                                        {/*</IconButton>*/}
                                    </ListItemSecondaryAction>
                                    {/*}*/}
                                </ListItem>
                            ))}
                        </List>
                        :
                        noDataView
                    }
                </Paper>
                {profileSel &&
                <ProfileViewDlg isShow={isProfileShow} profile={profileSel} onDialogClose={this.closeProfile}/>}
            </RootContainer>
        );
    }
}

RegRequestionsA.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    requests: PropTypes.array.isRequired,
    noDataView: PropTypes.any.isRequired,
    getProfile: PropTypes.func.isRequired,
    statistics: PropTypes.bool,
};

RegRequestionsA.contextTypes = {
    showNotify: PropTypes.func,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(RegRequestionsA);
