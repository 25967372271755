import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {
    Box, Card,
} from '@material-ui/core';
import mainStyles from '../styles/mainStyles';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import CardContent from "@material-ui/core/CardContent";
import {injectIntl} from "react-intl";
import Tariffs from "./Tariffs";
import Purchases from "./Purchases";
import type {ActivePatient} from "../const";
import {getActiveTab, getURLSearchParams} from "../utils";
import {useHistory, useLocation} from "react-router";
import {ProfileTariffsTabEnum} from "../const";

const styles = theme => ({
    ...mainStyles(theme),
    contentBox: {
        // paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    content: {
        width: '250px',
        maxWidth: '250px',
        marginRight: '20px',
        height: '100%',
    },
    tariff: {
        display: 'flex',
    },
    tariffsHead: {
        fontWeight: '600',
        marginBottom: '30px'
    },
    tariffIcon: {
        width: 'auto',
        height: '40px',
        color: '#459220',
        fill: '#459220',
        fillColor: '#459220',
    },
    name: {
        fontWeight: '600',
        color: theme.palette.primary.main,
    },
    period: {
        fontWeight: '600',
    },
    tabsCard: {
        marginBottom: '12px',
    },
    tabsCardContent: {
        padding: '0 !important',
    },
});

const ProfileTariffs = (props) => {

    const location = useLocation();
    const history = useHistory();
    const [profileTariffsTab, setProfileTariffsTab] = React.useState(getActiveTab(location));

    const {
        classes,
        showNotify,
        intl,
        patientsGroups,
    } = props;

    const changeAddress = (tab: ProfileTariffsTabEnum) => {
        const params = new URLSearchParams(location.search);
        const activePatient: ActivePatient = getURLSearchParams(params);
        const {pathname} = location;
        const newRoute = `${pathname}?mode=${activePatient.mode}&node=${activePatient.node}&group=${activePatient.group}&activeTab=${tab}`;
        history.replace(newRoute);
    }

    const changeTab = (tab: ProfileTariffsTabEnum) => {
        setProfileTariffsTab(tab);
        changeAddress(tab);
    }

    useEffect(() => {
        // changeTab(ProfileTariffsTabEnum.TARIFFS);
        // setProfileTariffsTab(ProfileTariffsTabEnum.TARIFFS);
    }, []);

    const getContent = (tab: ProfileTariffsTabEnum) => {
        switch (tab) {
            case ProfileTariffsTabEnum.TARIFFS:
                return (
                    <Card>
                        <CardContent>
                            <Tariffs
                                // classes={classes}
                                showNotify={showNotify}
                                patientsGroups={patientsGroups}
                            />
                        </CardContent>
                    </Card>
                );
            case ProfileTariffsTabEnum.PURCHASES:
                return (<Purchases
                    // classes={classes}
                />);
        }
    }

    return (
        <Box>
            <TabContext value={profileTariffsTab}>
                <Card className={classes.tabsCard}>
                    <CardContent className={classes.tabsCardContent}>
                        <TabList
                            onChange={(event, tab) => {
                                changeTab(tab);
                            }}
                            aria-label="simple tabs example"
                            centered
                            indicatorColor={"primary"}
                            textColor='primary'
                            style={{paddingBottom: '16px'}}
                        >
                            <Tab label={intl.formatMessage({id: 'label.tariffs', defaultMessage: 'Tariffs'})}
                                 value={ProfileTariffsTabEnum.TARIFFS} style={{padding: 0}}/>
                            <Tab label={intl.formatMessage({id: 'label.purchases', defaultMessage: 'Purchases'})}
                                 value={ProfileTariffsTabEnum.PURCHASES}/>
                        </TabList>
                    </CardContent>
                </Card>
                {getContent(profileTariffsTab)}
            </TabContext>
        </Box>
    );
}

ProfileTariffs.propTypes = {
    classes: PropTypes.object.isRequired,
    showNotify: PropTypes.func,
    patientsGroups: PropTypes.object,
};

export default compose(
    injectIntl,
    withStyles(styles),
)(ProfileTariffs);
