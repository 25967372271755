import React from 'react';
import mainStyles from "../../styles/mainStyles";
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Box from "@material-ui/core/Box";
import {Card, CardActions, ClickAwayListener, Fade} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {FormattedMessage} from "react-intl";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import CardContent from "@material-ui/core/CardContent";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import {MessageType} from "../../const";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    root: {
        maxWidth: '380px',
        minWidth: '300px',
        // backgroundColor: '#f6fcff',
    },
    content: {
        overflow: 'auto',
        maxHeight: '240px',
        paddingBottom: 0,
    },
    markdown: {
        '& *:first-child': {
            marginTop: 0,
        }
    },
    actions: {
        padding: theme.spacing(1),
    },
    typography: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        maxWidth: '380px',
        minWidth: '300px',
        maxHeight: '280px',
        overflow: 'auto',
        boxShadow: '3px 3px 5px rgb(0 0 0 / 0.5)',
        // backgroundColor: '#f6fcff',
    },
    buttons: {
        marginTop: '10px'
    },
    buttonCancel: {
        color: grey[700]
    },
    iconINFO: {
        color: theme.palette.primary.main
    },
    iconWARNING: {
        color: red[500]
    },

    wrapper: {
        alignItems: 'center',
        display: 'inline-flex',
    },
    infoText: {
        '& p': {
            margin: 0,
        }
    },
    helpIcon: {
        cursor: 'pointer',
        marginTop: '10px'
    },
}));

export const InfoHelp = (props) => {
    const {
        texts,
        type,
    } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleClick = (event) => {
        event.preventDefault();

        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };

/*
    const handleClose = () => {
        setAnchorEl(null);
    };
*/

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box component={'span'} className={classes.wrapper}>
                {(!type || type === MessageType.DESCRIPTION) &&
                    <HelpIcon
                        className={classes.helpIcon}
                        fontSize="small"
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    />
                }
                {type === MessageType.INFO &&
                    <InfoIcon
                        className={classes.helpIcon}
                        fontSize="small"
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    />
                }
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={'bottom-start'}
                    transition
                    style={{zIndex: '9999'}}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Card elevation={6} className={classes.root}>
                                <CardContent className={classes.content}>
                                    <Grid container direction={'row'}>
                                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                            <InfoOutlinedIcon fontSize="large"
                                                // style={{margin: '0 16px 0 0'}}
                                                              className={classes.iconINFO} />
                                        </Grid>

                                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                            <Grid container direction={'column'}>
                                                <Grid item className={classes.markdown}>
                                                    {(texts || []).map((text, index) =>
                                                        <ReactMarkdown key={index} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} children={text.payload}/>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions className={classes.cardActions + ' ' + classes.actions}
                                    // disableSpacing
                                >
                                    <Button color={"primary"} onClick={handleClick}>
                                        <FormattedMessage id='label.close' defaultMessage='Close' />
                                    </Button>
                                </CardActions>
                            </Card>

{/*
                            <Paper
                                className={classes.typography}
                            >
                                <Grid container direction={'row'}>
                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                        <InfoOutlinedIcon fontSize="large"
                                                          // style={{margin: '0 16px 0 0'}}
                                                          className={classes.iconINFO} />
                                    </Grid>

                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                        <Grid container direction={'column'}>
                                            <Grid item>
                                                <Typography component={"span"}>
                                                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} children={text} className={classes.infoText} />
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.buttons}>
                                                <Grid container direction={'row'} spacing={2} justifyContent={"flex-end"}>
                                                    <Grid item>
                                                        <Button color={"primary"} onClick={handleClick}>
                                                            <FormattedMessage id='label.close' defaultMessage='Close' />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
*/}
                        </Fade>
                    )}
                </Popper>
            </Box>
        </ClickAwayListener>
    );
}
