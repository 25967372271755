import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const SelTypography = withStyles(_theme => ({
  root: {
    fontWeight: 500,
  },
}))(Typography);

function SelTextView(props) {
  return (
    <SelTypography {...props}>
      {props.children}
    </SelTypography>
  );
}

export default SelTextView;
