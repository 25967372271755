import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {RadioGroup} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import ServiceConfirmPopper from "./ServiceConfirmPopper";
import {MessageType} from "../../const";
import type {ServiceMessage} from "../../const";
import {joinerBR} from "../../utils";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    wrapper: {
        alignItems: 'center',
        display: 'inline-flex',
    },
}));

export default function ServiceRadioGroup(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [eventValue, setEventValue] = React.useState(false);
    // const anchorRef = React.useRef(null);
    const classes = useStyles();
    const {
        onChange,
        getServiceMessage,
        title,
        text,
        value,
        showService,
        children,
    } = props;
    const [serviceMessage, setServiceMessage] = React.useState({
            showService: showService,
            type: MessageType.WARNING,
            payload: text,
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setEventValue(event);

        setOpen(false);

        let sm: ServiceMessage;
        if (!!getServiceMessage) {
            sm = getServiceMessage(event);
            if (sm) {
                setServiceMessage(sm);
            }
        }
        if ((sm || {}).showService || showService) {
            setOpen(true);
        } else {
            onChange(event);
        }
    };

    const handleClickAway = (event) => {
        // if (anchorRef.current && (event || {}).target && anchorRef.current.contains(event.target)) {
        //     return;
        // }
        setOpen(false);
        // setOpenWithMessage((prev) => {
        //     prev.open = false;
        //     // prev.serviceMessage.showService = false;
        //     return prev;
        // });
    };

    const handleConfirm = (confirm: boolean) => (e) => {
        if (confirm) {
            onChange(eventValue);
        }
        handleClickAway();
    };

    const getServiceText = (): string => {
        const resultServiceText = [];
        if (showService) {
            if (text) {
                resultServiceText.push(text);
            }
        }
        if (serviceMessage.showService) {
            serviceMessage.messages.forEach((sm) => {
                resultServiceText.push(sm.payload);
            });
        }

        return joinerBR(resultServiceText);
    };

    const getServiceType = (): MessageType => {
        return serviceMessage.type;
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box component={'span'} className={classes.wrapper}>
                <RadioGroup
                    onChange={
                        e => {
                            if (value !== e.target.value) {
                                handleClick(e);
                            } else {
                                e.preventDefault();
                            }
                        }
                    }
                    value={value}>
                    {children}
                </RadioGroup>
                <ServiceConfirmPopper
                    open={open}
                    anchorEl={anchorEl}
                    title={title}
                    text={getServiceText()}
                    type={getServiceType()}
                    handleConfirm={handleConfirm}
                />
            </Box>
        </ClickAwayListener>
    );
}
