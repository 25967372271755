import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import orange from "@material-ui/core/colors/orange";
import {blue, yellow} from "@material-ui/core/colors";

const mainStyles = theme => ({
  grow: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(2)
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
  textButton: {
    textTransform: 'none',
  },

  articleClassA: {
    color: '#448C54'
  },
  articleClassB: {
    color: '#F2B134'
  },
  articleClassC: {
    color: '#2571A5'
  },
  loading: {
    textAlign: 'center',
    padding: '16px',
    paddingBottom: 0,
  },

  positiveButton: {
    color: green[500],
    borderColor: green[500],
    '&:hover': {
      borderColor: green[700],
      backgroundColor: green[50],
    },
  },
  negativeButton: {
    color: red[400],
    borderColor: red[400],
    '&:hover': {
      borderColor: red[700],
      backgroundColor: red[50],
    },
  },
  inactiveButton: {
    color: grey[500],
    borderColor: grey[500],
    '&:hover': {
      borderColor: grey[700],
      backgroundColor: grey[50],
    },
  },

  reduceTopMargin2: {
    marginTop: -theme.spacing(2),
  },
  contentBoxPanel: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      borderColor: grey[300],
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  },
  contentBoxProfilePanel: {
    padding: 0,
  },
  contentBoxProfileText: {
    '& > span': {
      // fontWeight: 500,
    },
    textTransform: 'none !important',
  },
  overflowWrapBreakWord: {
    overflowWrap: 'break-word',
  },
  contentBox: {
    padding: theme.spacing(2),
  },
  contentBoxCard: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cardContent: {
    padding: '16px !important',
  },
  cardContentAccordion: {
    paddingLeft: '16px !important',
    paddingRight: '16px !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  drugBox: {
    padding: theme.spacing(1),
    margin: '0px 4px 8px 0px', // theme.spacing(1), //
    backgroundColor: grey[100],
  },
  contentBoxTopSpace: {
    paddingTop: theme.spacing(2),
  },
  contentBoxBottomSpace: {
    marginBottom: theme.spacing(2),
  },
  contentBoxLargePadding: {
    padding: theme.spacing(4),
  },
  contentBoxCenter: {
    padding: theme.spacing(2),
    textAlign: "center",
    width: '100%',
  },

  contentVerticalPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  listContent: {
    width: '100%',
    padding: '0px',
  },
  listItem: {
    marginRight: theme.spacing(4),
  },
  listItemAvatarRed: {
    minWidth: 'unset',
    marginRight: '8px',
    color: red[500],
  },
  listItemAvatarOrange: {
    minWidth: 'unset',
    marginRight: '8px',
    color: orange[500],
  },
  listItemTextRed: {
    color: red[500],
  },
  listItemTextOrange: {
    color: orange[500],
  },
  listItemSecondaryAction: {
    right: 0,
    '& > span > svg': {
      marginTop: '4px !important'
    },
  },

  iconGreen: {
    color: 'green'
  },
  iconRed: {
    color: 'red'
  },
  iconSilver: {
    color: 'silver'
  },
  iconOrange: {
    color: 'orange'
  },

  reviewIconPositive: {
    color: 'green'
  },
  reviewIconNegative: {
    color: 'red'
  },
  dlgRating: {
    padding: theme.spacing(2),
    textAlign: "center",
    width: '100%',
  },

  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },

  primaryText: {
    color: theme.palette.primary.main,
  },

  textSize14px: {
    '& > *' : {
      fontSize: '14px !important',
    },
  },

  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkHead: {
    textDecoration: 'none',
    textTransform: 'uppercase',
  },

  cardActions: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },

  cardActionsCard: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },

  imageAutoSize: {
    maxWidth: '90%',
    maxHeight: 'auto',
  },

  imgAutoWidth: {
    // width: '100%', // TODO: prevent enlarging
    maxWidth: '90%',
  },

  disabled: {
    pointerEvents: "none",
    opacity: .6,
  },

  drugNormal: {
    color: 'black'
  },
  drugNotAvailable: {
    color: 'silver'
  },
  drugWarning: {
    color: 'orange'
  },
  drugRecommend: {
    // color: 'green'
    color: theme.palette.primary.main
  },
  drugDanger: {
    color: 'red'
  },

  drugCurrently: {
    color: blue[200],
  },

  evidenceLevelColorType0: {
    backgroundColor: green[200],
  },

  evidenceLevelColorType1: {
    backgroundColor: yellow[200],
  },

  evidenceLevelColorType2: {
    backgroundColor: orange[200],
  },

  cellNoBottomBorder: {
    borderBottom: 0,
  },

  pr8px: {
    paddingRight: '8px !important',
  },

  pb0: {
    paddingBottom: '0 !important',
  },

  pb2: {
    paddingBottom: theme.spacing(2),
  },

  pl0: {
    paddingLeft: 0,
  },

  pl8px: {
    paddingLeft: '8px',
  },

  pr0px: {
    paddingRight: '0px',
  },

  alignItemsCenter: {
    alignItems: 'center',
  },

  stackedCell: {
    '@media (max-width:499.95px)': {
      fontWeight: 'bold',
    },
  },

  stackedCellTextLeft: {
    textAlign: 'left',
  },

  groupedBarChartExt: {
    // width: '100%',
    // overflowX: 'auto',
  },

  resultParam: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(224, 224, 224, 1);',
  },
  resultParamName: {
    overflowWrap: 'break-word',
    fontWeight: 500,

    [theme.breakpoints.down('xs')]: {
      // fontWeight: 'bold',
    },
  },
  resultParamValue: {
    textAlign: 'right',
    overflowWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },


  // Manual
  helpContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 0,
  },
  helpImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  helpItem: {
    flexGrow: 1,
    // flexGrow: 0,
    // flexShrink: 0,
    '& *': {
      flexGrow: 0,
      flexShrink: 0,
    }
  },

  contextBarButtonHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  // Tabs center
  root: {
    justifyContent: 'center'
  },
  scroller: {
    flexGrow: '0'
  },

  staticBaselineContextPanel: {
    fontSize: '0.8rem'
  },
  baselineParameterStrictContextPanel: {
    marginBottom: '8px',
  },
  baselineParameterStrictNameContextPanel: {
    // fontSize: '0.8rem',
  },
  baselineParameterStrictValueContextPanel: {
    // fontSize: '0.8rem',
  },
  staticBaselineWrapperValueContextPanel: {
    alignItems: 'flex-end',
    alignContent: 'end',
    display: 'flex',
  },
  staticBaselineValueContextPanel: {
    width: '100%',
    textAlign: 'right',
  },

  contStatusStyle1: {
    color: theme.palette.primary.main,
  },
  contStatusStyle2: {
    color: orange[500],
  },
  contStatusStyle3: {
    color: red[500],
  },
  contStatusStyle4: {
    color: red[500],
  },
  plus: {
    color: grey[500],
    fontSize: '0.9rem',
  },
  noRecommendSet: {
    color: red[700],
  },

});

export default mainStyles;
