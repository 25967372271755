import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import {Box, Grid, Typography,} from "@material-ui/core";
import {calcFLPScore} from '../../query/research';
import config from "../../config";

import Plot from 'react-plotly.js';
import {getQueryResult, getRandomChartMarkerColors, isNumber, newAnyDataRef, toFixed} from "../../utils";
import {ChartMarkers, GroupedBarChartColors, PScoreChartType} from "../../const";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    ...mainStyles(theme),
    content: {
        padding: theme.spacing(2),
    },
});

const HasBledPer = {
    // 1: [1.02, 1.88],
    // 2: [1.88, 3.72],
    3: [3.72, 8.7],
    4: [8.7, 12.5],
    5: [8.7, 12.5],
    6: [8.7, 12.5],
    7: [8.7, 12.5],
    8: [8.7, 12.5],
};

class FBPScoreChart extends React.Component {

    abortController = new AbortController();

    state = {
        revision: 1,
        loading: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            revision: 1,
            loading: false,
        };
    }

    componentDidMount() {
        this.loadPScore();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.compStat !== this.props.compStat
        ) {
            this.loadPScore();
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadPScore = () => {
        const {client, treatments, subgroups, therapyName, pscoreData, getFeatures, patientId} = this.props;
        const {signal} = this.abortController;

        if (pscoreData) {
            this.calcPScore(pscoreData);
        } else {
            this.setState({
                loading: true
            });

            client.query({
                query: calcFLPScore,
                variables: {
                    patientId: patientId,
                    pScoreIn: {
                        treatments: treatments,
                        subgroups: subgroups,
                        therapyName: therapyName,
                        features: getFeatures ? getFeatures() : [],
                    },
                },
                context: {
                    uri: config.options.server.fl_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                // .then(result => (result.data || {}).calcFLPScore)
                // .then(result => JSON.parse(JSON.stringify(result)))
                .then(result => getQueryResult(result?.data, 'calcFLPScore'))
                .then(pScore => {
                    this.calcPScore(pScore);
                });
        }
    };

    getMarker = (point, index = 0, key) => {
        // return ChartMarkers[selectType];
        const {chartType, theme} = this.props;

        const marker = {
            size: isNumber(point.scale) ? (11 * point.scale) : 11, // point.statistic ? 30 : (point.isSelect ? 24 : 14),
            color: getRandomChartMarkerColors(index, point.selectType, point.statistic),
            symbol: ChartMarkers[point.selectType],
        };
        marker.line = {
            color: newAnyDataRef(marker.color),
            width: 3,
        };

        switch (chartType) {
            case PScoreChartType.STATISTICS:
                marker.symbol = point.statistic ? point.selectType === 1 || point.selectType === 2 ? ChartMarkers[3] : ChartMarkers[0] : ChartMarkers[0];
                // marker.color = point.statistic ? point.selectType === 1 ? GroupedBarChartColors.GREEN : point.selectType === 2 ? GroupedBarChartColors.BLUE : marker.color : marker.color;
                marker.color = point.statistic ? point.selectType === 1 ? GroupedBarChartColors.GREEN : point.selectType === 2 ? theme.palette.primary.main : marker.color : marker.color;
                // marker.line.color = point.statistic ? point.selectType === 1 ? GroupedBarChartColors.GREEN : point.selectType === 2 ? theme.palette.primary.main : marker.color : marker.color;
                marker.line.color = marker.color;
                break;
            case PScoreChartType.COMPARISONS:
                marker.symbol = point.selectType === 1 || point.selectType === 2  || point.selectType === 3 ? ChartMarkers[0] : ChartMarkers[point.selectType];
                if (point.selectType === 0) {
                    // marker.line = {
                    //     color: newAnyDataRef(marker.color),
                    //     width: 4,
                    // };
                    marker.color =  'transparent'; // 'white'; //
                }
                break;
            case PScoreChartType.COMPARISONS_STATISTICS:
                marker.symbol = point.statistic ? ChartMarkers[3] : ChartMarkers[0];
                // marker.color = point.statistic ? point.selectType === 1 ? GroupedBarChartColors.GREEN : point.selectType === 2 ? GroupedBarChartColors.BLUE : marker.color : marker.color;
                marker.color = point.statistic ? point.selectType === 1 ? GroupedBarChartColors.GREEN : point.selectType === 2 ? theme.palette.primary.main : marker.color : marker.color;
                marker.line.color = marker.color;
                if (point.selectType === 0) {
                    // marker.line = {
                    //     color: newAnyDataRef(marker.color),
                    //     width: 4,
                    // };
                    marker.color = 'transparent'; // 'white'; //
                }
                break;
            case PScoreChartType.GENERAL:
            default:
                // if (false) {
                //
                // } else {
                //     marker.line = {
                //         color: newAnyDataRef(marker.color),
                //         width: 4,
                //     };
                //     marker.color = 'transparent';
                // }
                break;
        }

        return marker;
    }

    sortData = (e1, e2): number => {
        const name = e1.name.localeCompare(e2.name);
        // const isSelect = e1.isSelect || e2.isSelect;
        const isSelectCompare = Number(e1.isSelect || 0) - Number(e2.isSelect || 0);
        const statistic = Number(e1.statistic || 0) - Number(e2.statistic || 0);
        let selectType = (e1.selectType || 0) - (e2.selectType || 0);

        return -isSelectCompare || statistic || selectType || name;
    }

    calcPScore = (pScore) => {
        const {hasBled, isCalcLevel, intl} = this.props;
        const {revision} = this.state;

        if (Boolean((pScore || {}).data) && !!(pScore.data.efficacy || []).length && !!(pScore.data.safety || []).length) {
            const {efficacy, safety, treatsRate} = pScore.data;
            const ef = new Map(efficacy.sort((e1, e2) => this.sortData(e1, e2)).map(e => [e.name, { value: Math.round(e.value * 100) / 100, isSelect: e.isSelect, selectType: e.selectType, statistic: e.statistic}]));
            const sf = new Map(safety.sort((e1, e2) => this.sortData(e1, e2)).map(e => [e.name, { value: Math.round(e.value * 100) / 100, isSelect: e.isSelect, selectType: e.selectType, statistic: e.statistic}]));

            let data = [];

            if (isCalcLevel && hasBled > 0 && treatsRate.length > 0) {
                let bledPer = HasBledPer[hasBled];
                let rates = treatsRate.map(r => r.rate).sort();
                let startRateIndx;

                if (typeof bledPer === 'undefined') {
                    startRateIndx = 0;
                } else {
                    let maxBledRate = bledPer[1];

                    if (rates[0] >= maxBledRate) {
                        startRateIndx = 1;
                    } else {
                        startRateIndx = rates.findIndex(r => r > maxBledRate) - 1;
                        if (startRateIndx < 0) {
                            startRateIndx = 1;
                        }
                    }
                }

                if (startRateIndx > 0) {
                    let startRate;
                    startRate = rates[startRateIndx];
                    let startTreat = treatsRate.find(tr => tr.rate === startRate);
                    let safetyTreat = safety.find(s => s.name === startTreat.name);
                    const y = safetyTreat.value + 0.05;

                    data.push({
                        x: [0, 1.2],
                        // y: [y, y],
                        y: [Number(toFixed(y) || 0), Number(toFixed(y) || 0)],
                        mode: 'lines',
                        hoverlabel: {namelength: -1},
                        type: 'scatter',
                        name: intl.formatMessage({ id: "label.risk.high", defaultMessage: "High risk"}),
                        legendgroup: intl.formatMessage({ id: "label.risk.high", defaultMessage: "High risk"}),
                        stackgroup: 'one',
                        line: {
                            color: '#ffcccc',
                            width: 3,
                        },
                    });

                    data.push({
                        x: [0, 1.2],
                        y: [1, 1],
                        mode: 'lines',
                        hoverlabel: {namelength: -1},
                        type: 'scatter',
                        name: intl.formatMessage({ id: "label.risk.low", defaultMessage: "Low risk"}),
                        legendgroup: intl.formatMessage({ id: "label.risk.low", defaultMessage: "Low risk"}),
                        stackgroup: 'one',
                        line: {
                            color: '#eeeeee',
                            width: 3,
                        },
                    });
                }
            }

            let index = 0;
            for (const key of ef.keys()) {
                if (sf.has(key)) {
                    data.push({
                        x: [Number(toFixed(ef.get(key).value) || 0)],
                        y: [Number(toFixed(sf.get(key).value) || 0)],
                        type: 'scatter',
                        // mode: 'markers+text',
                        mode: 'markers',
                        hoverlabel: {namelength: -1},
                        // marker: {
                        //     size: (ef.get(key).statistic || sf.get(key).statistic) ? 30 : ((ef.get(key).isSelect || sf.get(key).isSelect) ? 24 : 14),
                        //     color: getRandomChartMarkerColors(index, ef.get(key).selectType, ef.get(key).statistic),
                        //     symbol: ChartMarkers[ef.get(key).selectType || 0],
                        // },
                        marker: this.getMarker(ef.get(key), index, key),
                        // text: [key],
                        name: key,
                        legendgroup: key,
                        // textposition: 'bottom center',
                    });
                    index++;
                }
            }

            this.setState({
                loading: false,
                data: data,
                revision: revision + 1,
            });
        } else {
            this.setState({
                loading: false,
                data: null,
                revision: revision + 1,
            });
        }
    };

    render() {
        const {
            classes,
            useResizeHandler,
            responsive,
            title,
            intl,
        } = this.props;
        const {data, revision, loading} = this.state;

        return (
            <Box className={classes.content}
                 style={{
                     width: "100%",
                 }}
            >
                <Typography variant='h6' align='center'>
                    {title !== null && title !== undefined ? title : intl.formatMessage({ id: "label.effsaf", defaultMessage: "Efficacy/Safety"})}
                </Typography>
                <Grid container
                      style={{width: "100%", height: "100%"}}
                >
                    <Grid item xs={12}
                          style={{width: "100%", height: "100%"}}
                    >
                        <Grid container justifyContent='center'
                              style={{width: "100%", height: "100%"}}
                        >
                            <Grid item
                                  style={{width: "100%", height: "100%"}}
                            >
                                {loading && <Box className={classes.loading}><CircularProgress/></Box>}
                                {(!loading && !!data) &&
                                    <Plot
                                        // ref={(el) => { this.plotContainer = el; }}
                                        useResizeHandler={useResizeHandler || false}
                                        style={{width: "100%", height: "100%"}}
                                        config={{
                                            // responsive: true, // TODO: removed because buggy
                                            responsive: responsive || false,
                                            displayModeBar: false,
                                            editable: false,
                                            scrollZoom: false,
                                        }}
                                        data={data}
                                        revision={revision}
                                        graphDiv={'graph'}
                                        layout={{
                                            // width: 600,
                                            // height: 600,
                                            // autosize: false,
                                            autosize: true,
                                            xaxis: {
                                                automargin: true, // TODO: добавил для теста
                                                range: [-0.1, 1.1],
                                                fixedrange: true,
                                                title: {
                                                    // text: 'Efficacy', // TDOD: заменить из локализации
                                                    text: intl.formatMessage({
                                                        id: 'label.efficacy',
                                                        defaultMessage: 'Efficacy'
                                                    }),
                                                },
                                            },
                                            yaxis: {
                                                automargin: true, // TODO: добавил для теста
                                                range: [-0.1, 1.1],
                                                fixedrange: true,
                                                title: {
                                                    // text: 'Safety',
                                                    text: intl.formatMessage({
                                                        id: 'label.safety',
                                                        defaultMessage: 'Safety'
                                                    }),
                                                },
                                            },
                                            showlegend: true,
                                            legend: {
                                                orientation: 'h',
                                                y: -0.3,
                                            },
                                            margin: {
                                                t: 0,
                                                b: 0,
                                                pad: 8,
                                            },
                                            // transition: {
                                            //     duration: 2000,
                                            //     easing: 'circle-in-out'
                                            // },
                                        }}
                                    />
                                }
                                {(!loading && !data) &&
                                    <Box textAlign={'center'}>
                                        <FormattedMessage id={'message.data.no_data'} defaultMessage={'No data'}/>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

FBPScoreChart.propTypes = {
    classes: PropTypes.object.isRequired,
    compStat: PropTypes.number,
    treatments: PropTypes.arrayOf(PropTypes.object).isRequired,
    subgroups: PropTypes.arrayOf(PropTypes.string).isRequired,
    therapyName: PropTypes.string.isRequired,
    title: PropTypes.string,
    hasBled: PropTypes.number.isRequired,
    isCalcLevel: PropTypes.bool.isRequired,
    pscoreData: PropTypes.object,
    useResizeHandler: PropTypes.bool,
    responsive: PropTypes.bool,
    chartType: PropTypes.string,
    getFeatures: PropTypes.func,
    patientId: PropTypes.number,
};

FBPScoreChart.defaultProps = {
    hasBled: 0,
    isCalcLevel: false,
};

export default compose(
    withStyles(styles, { withTheme: true }),
    injectIntl,
    withApollo,
)(FBPScoreChart);
