import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import compose from 'recompose/compose';
import {Box, Grid, Typography,} from "@material-ui/core";

import Plot from 'react-plotly.js';
import {getRandomChartMarkerColors, isNumber, toFixed} from "../../utils";

const styles = theme => ({
  ...mainStyles(theme),
});

class EffSafChart extends React.Component{

  constructor(props) {
    super(props);

    this.state = { };
  }

  render() {
    const { classes, points, title, xaxis, yaxis } = this.props;

    const data = points.map((p, index) => ({
      x: [Number(toFixed(p.x) || 0)],
      y: [Number(toFixed(p.y) || 0)],
      type: 'scatter',
      mode: 'markers',
      hoverlabel: {namelength: -1},
      marker: {
        size: isNumber(p.scale) ? (14 * p.scale) : 14,
        color: getRandomChartMarkerColors(index),
      },
      name: p.name,
      legendgroup: p.name,
    }));

    return (
      <Box className={classes.contentBox}
           style={{
             width: "100%",
           }}
      >
        <Typography variant='h6' align='center'>
          {title}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Grid item
                    style={{width: "100%", height: "100%"}}
              >
                <Plot
                  useResizeHandler
                  style={{width: "100%", height: "100%"}}
                  config={{
                    // responsive: true, // TODO: removed because buggy
                    displayModeBar: false,
                    editable: false,
                    scrollZoom: false,
                  }}
                  data={data}
                  layout={{
                    // width: 600,
                    // height: 600,
                    // autosize: false,
                    autosize: true,
                    xaxis: {
                      fixedrange: true,
                      title: {
                        text: xaxis,
                      },
                    },
                    yaxis: {
                      fixedrange: true,
                      title: {
                        text: yaxis,
                      },
                    },
                    showlegend: true,
                    legend: {
                      orientation: 'h',
                      y: -0.3,
                    },
                    margin: {
                      t: 0,
                      b: 0,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

EffSafChart.propTypes = {
  classes: PropTypes.object.isRequired,
  points: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  xaxis: PropTypes.string,
  yaxis: PropTypes.string,
};

EffSafChart.defaultProps = {
  points: [],
  title: 'Efficacy/Safety',
  xaxis: 'Efficacy',
  yaxis: 'Safety',
};

export default compose(
  withStyles(styles),
  injectIntl,
)(EffSafChart);

