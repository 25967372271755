import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Grid, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {ProfileType} from '../../const';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Autocomplete} from "@material-ui/lab";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from "@apollo/client/react/hoc";
import mainStyles from "../../styles/mainStyles";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {getNextDateToStringFormat, isPastDate} from "../../utils";

const styles = theme => ({
    ...mainStyles(theme),
    formItem: {
        marginBottom: '24px',
    },
    marginNone: {
        margin: 0,
        width: '100%',
    },
    gridItem: {
        flexGrow: 1,
    },
});

class InvitePublicAddDialog extends React.Component {

    constructor(props) {
        super(props);
        const {intl} = props;

        this.state = {
            fields: new Map([
                ['endAt', null],
                ['availRegs', ''],
                // ['role', undefined],
                ['descr', ''],
                ['groupId', null],
                ['regProfileId', null],
            ]),
            errors: new Map(),
        };

        this.validate = {
            'endAt': (value) => {
                let errors = this.state.errors;

                if (!value || isPastDate(value)) {
                    errors.set('endAt', intl.formatMessage({
                        id: 'err.form.endAt.invalid',
                        defaultMessage: 'Invalid endAt'
                    }));
                } else {
                    errors.delete('endAt');
                }

                return errors;
            },
            'availRegs': (value) => {
                let errors = this.state.errors;

                if (!value) {
                    errors.set('availRegs', intl.formatMessage({
                        id: 'err.form.availRegs.invalid',
                        defaultMessage: 'Invalid availRegs'
                    }));
                } else {
                    errors.delete('availRegs');
                }

                return errors;
            },
            // 'role': (value) => {
            //     let errors = this.state.errors;
            //
            //     if (!value) {
            //         errors.set('role', intl.formatMessage({
            //             id: 'err.form.role.invalid',
            //             defaultMessage: 'Invalid role'
            //         }));
            //     } else {
            //         errors.delete('role');
            //     }
            //
            //     return errors;
            // },
            'descr': (value) => {
                let errors = this.state.errors;

                if (!value || value.length < 8) {
                    errors.set('descr', intl.formatMessage({
                        id: 'err.form.descr.invalid',
                        defaultMessage: 'Invalid descr'
                    }));
                } else {
                    errors.delete('descr');
                }

                return errors;
            },
            'groupId': (value) => {
                let errors = this.state.errors;

                if (!value) {
                    errors.set('groupId', intl.formatMessage({
                        id: 'err.form.groupId.invalid',
                        defaultMessage: 'Invalid groupId'
                    }));
                } else {
                    errors.delete('groupId');
                }

                return errors;
            },
            'regProfileId': (value) => {
                let errors = this.state.errors;

                if (!value) {
                    errors.set('regProfileId', intl.formatMessage({
                        id: 'err.form.regProfileId.invalid',
                        defaultMessage: 'Invalid reg Profile'
                    }));
                } else {
                    errors.delete('regProfileId');
                }

                return errors;
            },
        };
    }

    componentDidMount() {
        this.setStateInvite();
    }

    onValueChange = (event) => {
        let target = event.target;

        const value = target.value;

        this.setState({
            fields: this.state.fields.set(target.name, value),
            errors: this.validate[target.name](value),
        }, () => {
        });
    };

    onEndAtValueChange = (value) => {
        this.setState({
            fields: this.state.fields.set('endAt', value),
            errors: this.validate['endAt'](value),
        });
    };

    onFormSubmit = (event) => {
        const {fields, errors} = this.state;
        const {onSubmit, invite} = this.props;

        for (let [name, value] of fields) {
            this.validate[name](value);
        }

        if (errors.size === 0) {
            const params = {
                endAt: getNextDateToStringFormat(fields.get('endAt')),
                // role: ProfileType.EXPERT, // fields.get('role'),
                availRegs: fields.get('availRegs'),
                descr: fields.get('descr'),
                groupId: fields.get('groupId').id,
                regProfileId: fields.get('regProfileId').id,
            };

            if (invite) {
                params.id = invite.id;
                params.regCodeId = invite.regCode.id;
            } else {
                params.role = ProfileType.EXPERT;
            }

            onSubmit(params);
            this.clearState();
        } else {
            this.forceUpdate();
        }

        event.preventDefault();
    };

    onClose = () => {
        const {onDialogClose} = this.props;
        this.clearState();
        onDialogClose();
    };

    clearState = () => {
        this.setState({
            fields: new Map([
                ['endAt', null],
                ['availRegs', ''],
                // ['role', undefined],
                ['descr', ''],
                ['groupId', null],
                ['regProfileId', null],
            ]),
            errors: new Map(),
        });
    };

    setStateInvite = () => {
        const {
            regCodeGroups,
            regProfiles,
            invite
        } = this.props;

        if (invite) {
            this.setState({
                fields: new Map([
                    ['endAt', new Date(invite.regCode.endAt)],
                    ['availRegs', invite.availRegs],
                    ['descr', invite.regCode.descr],
                    ['groupId', regCodeGroups.find((regCodeGroup) => regCodeGroup.id === invite.groupId)],
                    ['regProfileId', regProfiles.find((regProfile) => regProfile.id === invite.regCode.regProfile.id)],
                ]),
            }, () => {
                const {fields} = this.state
                let errors = new Map();
                for (let [name, value] of fields) {
                    errors = this.validate[name](value);
                }

                this.setState({errors: errors});
            });
        }
    };

    render() {
        const {isShow, intl, regCodeGroups, classes, invite, regProfiles} = this.props;
        const {fields, errors} = this.state;

        return (
            <Dialog
                fullWidth
                onClose={this.onClose}
                open={isShow}
                aria-labelledby="param-dialog-title">
                <DialogTitle id="param-dialog-title">
                    <FormattedMessage id='label.invite.public' defaultMessage='Invite public'/>
                </DialogTitle>
                <form onSubmit={this.onFormSubmit}>
                    <DialogContent>
                        <TextField
                            autoFocus
                            // required
                            fullWidth
                            className={classes.formItem}
                            // margin="dense"
                            id="descr"
                            name="descr"
                            label={intl.formatMessage({id: 'label.invite.descr', defaultMessage: 'descr'})}
                            value={fields.get('descr')}
                            onChange={this.onValueChange}
                            error={errors.get('descr') != null}
                            helperText={errors.get('descr')}
                        />
                        <Autocomplete
                            className={classes.formItem}
                            // id="combo-box-demo"
                            options={regCodeGroups}
                            getOptionLabel={(option) => option.name}
                            // style={{ width: 300 }}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label={intl.formatMessage({id: 'label.invite.groupId', defaultMessage: 'groupId'})}
                                           variant="standard"
                                           error={errors.get('groupId') != null}
                                           helperText={errors.get('groupId')}
                                />}
                            onChange={(event, value) => this.onValueChange({target: {name: 'groupId', value: value}})}
                            value={fields.get('groupId')}
                        />
                        <Grid container direction={'row'} justifyContent={'space-between'}>
                            <Grid item className={classes.gridItem} style={{marginRight: '12px'}}>
                                <TextField
                                    // required
                                    fullWidth
                                    className={classes.formItem}
                                    id="availRegs"
                                    name="availRegs"
                                    type={'number'}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label={intl.formatMessage({id: 'label.invite.availRegs', defaultMessage: 'availRegs'})}
                                    value={fields.get('availRegs')}
                                    onChange={this.onValueChange}
                                    error={errors.get('availRegs') != null}
                                    helperText={errors.get('availRegs')}
                                />
                            </Grid>
                            <Grid item>
                                <Box style={{width: '48px'}}></Box>
                            </Grid>
                            <Grid item className={classes.gridItem}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={classes.marginNone}
                                        disableToolbar
                                        autoOk={true}
                                        disablePast={true}
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="endAt"
                                        label={intl.formatMessage({id: 'label.invite.endAt', defaultMessage: 'endAt'})}
                                        value={fields.get('endAt')}
                                        onChange={this.onEndAtValueChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        minDateMessage={intl.formatMessage({id: 'label.invite.endAt.pastDateError', defaultMessage: 'Past Date Error'})}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Autocomplete
                            className={classes.formItem}
                            options={regProfiles}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) =>
                                <TextField {...params}
                                           label={intl.formatMessage({id: 'label.invite.regProfileId', defaultMessage: 'regProfileId'})}
                                           variant="standard"
                                           error={errors.get('regProfileId') != null}
                                           helperText={errors.get('regProfileId')}
                                />}
                            onChange={(event, value) => this.onValueChange({target: {name: 'regProfileId', value: value}})}
                            value={fields.get('regProfileId')}
                        />
                        {!!fields.get('regProfileId') &&
                            <Typography variant={"body2"}>
                                {fields.get('regProfileId').descr}
                            </Typography>
                        }
                        {/* <TextField
                          id="role"
                          name="role"
                          value={fields.get('role')}
                          onChange={this.onValueChange}
                          margin="normal"
                          required
                          fullWidth
                          select
                          label="Статус"
                          error={this.state.errors.get('role') != null}
                          helperText={this.state.errors.get('role')}>
                          {ProfileTypeNames.map(([roleId, roleName]) => (
                            <MenuItem key={roleId} value={roleId}>
                              {roleName}
                            </MenuItem>
                          ))}
                        </TextField> */}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.onClose}>
                            <FormattedMessage id='label.cancel' defaultMessage='Cancel'/>
                        </Button>
                        <Button color="primary" type="submit" disabled={!!errors.size}>
                            {!invite &&
                            <FormattedMessage id='label.create' defaultMessage='Create'/>
                            }
                            {invite &&
                            <FormattedMessage id='label.save' defaultMessage='Save'/>
                            }
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

InvitePublicAddDialog.propTypes = {
    intl: PropTypes.object.isRequired,
    onDialogClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isShow: PropTypes.bool.isRequired,
    invite: PropTypes.object,
    regCodeGroups: PropTypes.array.isRequired,
    regProfiles: PropTypes.array.isRequired,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(InvitePublicAddDialog);
