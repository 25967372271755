import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
    Box,
    Button, Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { withApollo } from '@apollo/client/react/hoc';
import {TherapyType} from "../../const";
import {MUIDataTableOptions} from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import InviteIcon from "@material-ui/icons/PersonAdd";
import {FormattedMessage, injectIntl} from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {DrugIcon} from "../../icons";
import grey from "@material-ui/core/colors/grey";
import {loadGetTherapyAvailable} from "../../query/therapy";
import PanelDivider from "../PanelDivider";
import Typography from "@material-ui/core/Typography";
import type {GetTherapyAvailableResult, TherapyDataIn} from "../../const";
import Autocomplete2 from "../drug/Autocomplete2";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";

const styles = theme => ({
    ...mainStyles(theme),
    drugIcon: {
        verticalAlign: 'middle',
        color: grey[500],
    },
    drugName: {
        marginLeft: theme.spacing(2),
    },
    therapyWrapper: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    },
});

class SelectTherapyPresumablyView extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            therapyAvailableData: [],
            selectedSkipVar: {},
            selectedSimpleData: {},
            addedDrugsData: {},

            openAddDrugDialog: {},

            openConfirmDialog: false,
            titleConfirmDialog: 'Заголовок диалога',
            textConfirmDialog: 'Текст диалога',
            buttonYes: 'Да',
            buttonNo: 'Нет',
            onConfirmDialog: () => {
                console.error('onConfirmDialog')
            },
        };
    }

    componentDidMount() {
        updateShowPanel(null);
        setLoadChartsMethod(null);
        const {getTherapyPresumably} = this.props;
        if (getTherapyPresumably) {
            const therapyPresumably = getTherapyPresumably();

            if (
                !therapyPresumably
                ||
                !therapyPresumably.therapyAvailableData
            ) {
                const {client, nosology} = this.props;
                const {signal} = this.abortController;

                loadGetTherapyAvailable(client, signal, nosology)
                    .then((therapyAvailable) => {
                        this.setState({
                            therapyAvailableData: therapyAvailable || [],
                        });
                    });
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    ...therapyPresumably,
                }));
            }
        }

    }

    setOpenAddDrug = (therapyAvailable: GetTherapyAvailableResult, open: boolean) => {
        // setTimeout(() => {
        this.setState({
            openAddDrugDialog: {
                [therapyAvailable.subTherapyId]: open,
            }
        });
        // });
    }

    onAddDrug = (therapyAvailable: GetTherapyAvailableResult) => () => {
        this.setOpenAddDrug(therapyAvailable, true);
    }

    addedDrugsOptions = (therapyAvailable: GetTherapyAvailableResult): MUIDataTableOptions => {
        const {intl} = this.props;

        const options: MUIDataTableOptions = {
            usePropAsKey: 'id',
            // filterType: 'checkbox',
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            pagination: false,
            search: false,
            selectableRowsHeader: false,
            selectableRows: 'single',
            selectToolbarPlacement: 'none',
            selectableRowsOnClick: false,
            // responsive: 'scroll',
            customToolbar: () => {
                return (
                    <React.Fragment>
                        <React.Fragment>
                            <Tooltip title={intl.formatMessage({id: 'label.add', defaultMessage: 'Add'})}>
                                <Button color={"primary"} onClick={this.onAddDrug(therapyAvailable)}>
                                    <InviteIcon className={this.props.classes.iconButton}/>
                                    <FormattedMessage id='label.add' defaultMessage='Add'/>
                                </Button>
                            </Tooltip>
                        </React.Fragment>
                    </React.Fragment>
                );
            },
            // setRowProps: (item: any, rowIndex: number) => ({
            //     onDoubleClick: (row, dataIndex) => {
            //         const {patientsData} = this.state;
            //         this.onOpenPatient(patientsData[this.patientsParams.patientType][rowIndex], undefined);
            //     }
            // }),
        };

        return options;
    }

    confirmDeleteDrug = (drugId) => {
        const {addedDrugsData} = this.state;
        const drugForDeleteIndex = addedDrugsData.findIndex((drug) => drug.id === drugId);
        addedDrugsData.splice(drugForDeleteIndex, 1);
        this.setState({
            addedDrugsData: addedDrugsData,
            rerender: new Date().getTime(),
        });
        this.setTherapyPresumably();
    }

    setConfirmOpen = (open: boolean, titleConfirmDialog: string, textConfirmDialog: string, onConfirmDialog, drug) => {
        const {intl} = this.props;
        let sTitleConfirmDialog = '';
        let sTextConfirmDialog = '';
        if (open) {
            sTitleConfirmDialog = intl.formatMessage({id: titleConfirmDialog, defaultMessage: 'Title'});
            sTextConfirmDialog = intl.formatMessage({id: textConfirmDialog}, {drugName: drug.name + ' ' + drug.dosage}, {defaultMessage: 'Text'});
        }
        this.setState({
            openConfirmDialog: open,
            titleConfirmDialog: sTitleConfirmDialog,
            textConfirmDialog: sTextConfirmDialog,
            buttonYes: intl.formatMessage({id: 'label.dialog.confirm.button.yes', defaultMessage: 'Yes'}),
            buttonNo: intl.formatMessage({id: 'label.dialog.confirm.button.no', defaultMessage: 'No'}),
            onConfirmDialog: onConfirmDialog,
        });
    }

    onDeleteDrug = (value, tableMeta) => {
        const {addedDrugsData} = this.state;
        const drug = tableMeta ? addedDrugsData.find((drug) => drug.id === tableMeta.rowData[0]) : value;

        this.setConfirmOpen(
            true,
            'label.dialog.confirm.drug.delete.title',
            'label.dialog.confirm.drug.delete.text',
            () => {
                this.confirmDeleteDrug(drug.id);
            },
            drug
        );
    }

    drugActionRenderer = (value, tableMeta, updateValue) => {
        const {intl} = this.props;

        return (
            <React.Fragment>
                <Grid container direction={"row"} spacing={2}>
                    <Grid item>
                        <Tooltip title={intl.formatMessage({id: 'label.delete', defaultMessage: 'Delete'})}>
                            <IconButton style={{padding: 0}} size="small" color="primary"
                                        aria-label="delete drug"
                                        component="span" onClick={() => this.onDeleteDrug(value, tableMeta)}>
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    drugNameRenderer = (value, tableMeta, updateValue) => {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Box>
                    <DrugIcon className={classes.drugIcon}/>
                    <Box component={'span'} className={classes.drugName}>
                        {value}
                    </Box>
                </Box>
            </React.Fragment>
        );
    }

    addedDrugsColumns = () => {
        const {intl} = this.props;
        const columns = [
            {
                name: "id",
                label: "ID",
                options: {
                    display: 'false',
                }
            },
            {
                name: "name",
                label: intl.formatMessage({id: 'label.drugs.table.drug', defaultMessage: 'Drug'}),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: this.drugNameRenderer,
                }
            },
            {
                name: "dosage",
                label: intl.formatMessage({id: 'label.drugs.table.dosage', defaultMessage: 'Dosage'}),
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "",
                label: "",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: this.drugActionRenderer,
                    setCellHeaderProps: (value) => ({style: {textDecoration: 'underline', width: '100px'}}),
                }
            },
        ];

        return columns;
    }

    onAddDrugSubmit = (therapyAvailable: GetTherapyAvailableResult) => (drugs) => {
        const {addedDrugsData, selectedSkipVar} = this.state;
        // selectedSkipVar[therapyAvailable.thType] = undefined; // {}
        selectedSkipVar[therapyAvailable.subTherapyId] = undefined; // {}
        if (!addedDrugsData[therapyAvailable.subTherapyId]) {
            addedDrugsData[therapyAvailable.subTherapyId] = [];
        }
        addedDrugsData[therapyAvailable.subTherapyId] = drugs;
        this.setState({
            selectedSkipVar: selectedSkipVar,
            addedDrugsData: addedDrugsData,
            rerender: new Date().getTime(),
        });
        this.setTherapyPresumably();
    }

    onSkipVarChange = (therapyAvailable: GetTherapyAvailableResult) => (event) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            event.preventDefault();
            return;
        }

        const {addedDrugsData, selectedSimpleData, selectedSkipVar, rerender} = this.state;
        selectedSkipVar[therapyAvailable.subTherapyId] = Number(event.target.value);
        const datas = {
            addedDrugsData: JSON.parse(JSON.stringify(addedDrugsData)),
            selectedSimpleData: JSON.parse(JSON.stringify(selectedSimpleData)),
            selectedSkipVar: JSON.parse(JSON.stringify(selectedSkipVar)),
        };
        switch (therapyAvailable.thType) {
            case TherapyType.DRUGS:
                datas.addedDrugsData[therapyAvailable.subTherapyId] = [];
                datas.rerender = (addedDrugsData[therapyAvailable.subTherapyId] || []).length > 0 ? new Date().getTime() : rerender;
                break;
            case TherapyType.SIMPLE:
                datas.selectedSimpleData[therapyAvailable.subTherapyId] = [];
                break;
            default:
                break;
        }

        this.setState({
            ...datas,
            selectedSkipVar: selectedSkipVar,
        }, () => {
            this.setTherapyPresumably();
        });
    };

    onSimpleSelectChange = (id: number, therapyAvailable: GetTherapyAvailableResult) => (event) => {
        const {selectedSimpleData, selectedSkipVar} = this.state;
        let _selectedSkipVar = selectedSkipVar;
        let _selectedSimpleData = selectedSimpleData;
        if (event.target.checked) {
            if (!_selectedSimpleData[therapyAvailable.subTherapyId]) {
                _selectedSimpleData[therapyAvailable.subTherapyId] = [];
            }
            _selectedSimpleData[therapyAvailable.subTherapyId].push(id);
            _selectedSkipVar[therapyAvailable.subTherapyId] = undefined; // {}
        } else {
            _selectedSimpleData = selectedSimpleData[therapyAvailable.subTherapyId].filter(item => item !== id);
        }
        this.setState({
            selectedSkipVar: _selectedSkipVar,
            selectedSimpleData: _selectedSimpleData,
        }, () => {
            this.setTherapyPresumably();
        });
    }

    setTherapyPresumably = () => {
        const {therapyAvailableData, addedDrugsData, selectedSimpleData, selectedSkipVar} = this.state;

        let thData: TherapyDataIn[] = [];
        therapyAvailableData.forEach((therapyAvailable) => {
            let thDataForType: TherapyDataIn = {
                thType: therapyAvailable.thType,
                subTherapyId: therapyAvailable.subTherapyId,
                selectIds: [],
            };

            if (selectedSkipVar[therapyAvailable.subTherapyId]) {
                thDataForType.selectIds = [selectedSkipVar[therapyAvailable.subTherapyId]];
            } else {
                switch (therapyAvailable.thType) {
                    case TherapyType.DRUGS:
                        if ((addedDrugsData[therapyAvailable.subTherapyId] || []).length > 0) {
                            const drugsIds = [];
                            (addedDrugsData[therapyAvailable.subTherapyId] || []).forEach((addedDrug) => {
                                drugsIds.push(addedDrug.id);
                            });
                            thDataForType.selectIds = drugsIds;
                        }
                        break;
                    case TherapyType.SIMPLE:
                        if ((selectedSimpleData[therapyAvailable.subTherapyId] || []).length > 0) {
                            thDataForType.selectIds = selectedSimpleData[therapyAvailable.subTherapyId];
                        }
                        break;
                    default:
                        break;
                }
            }

            thData.push(thDataForType);
        });

        const {setTherapyPresumably} = this.props;
        if (setTherapyPresumably) {

            const therapyPresumably = {
                thData: thData,
                therapyAvailableData: therapyAvailableData,
                selectedSkipVar: selectedSkipVar,
                selectedSimpleData: selectedSimpleData,
                addedDrugsData: addedDrugsData,
            };
            setTherapyPresumably(therapyPresumably);
        }
    };

    render() {
        const {
            addedDrugsData,
            therapyAvailableData,
            selectedSkipVar,
            selectedSimpleData
        } = this.state;

        const {classes, isPrescriptionReadonly} = this.props;
        return (
            <React.Fragment>
                {(therapyAvailableData || []).map((therapyAvailable, index) => (
                    <Paper
                        className={ `${classes.contentBox} ${classes.therapyWrapper} ${isPrescriptionReadonly ? classes.disabled : ''}` }
                        key={index}
                        // variant={"elevation"}
                    >
                        <Typography variant={"h6"}>
                            {therapyAvailable.name}
                        </Typography>
                        {therapyAvailable.thType === TherapyType.DRUGS &&
                        <React.Fragment>

                            <Autocomplete2
                                values={addedDrugsData[therapyAvailable.subTherapyId] || []}
                                onChange={this.onAddDrugSubmit(therapyAvailable)}
                                isPrescriptionReadonly={isPrescriptionReadonly}
                            />

{/*
                            <DatatableComponent
                                intl={intl}
                                tableName={TableNameType.ADDED_DRUGS}
                                title={''}
                                data={addedDrugsData[therapyAvailable.subTherapyId] || []}
                                columns={this.addedDrugsColumns()}
                                options={this.addedDrugsOptions(therapyAvailable)}
                                rerender={rerender}
                            />
                            <AddDrugDialog
                                onDialogClose={() => this.setOpenAddDrug(therapyAvailable.subTherapyId, false)}
                                onSubmit={this.onAddDrugSubmit(therapyAvailable)}
                                addedDrugsData={addedDrugsData[therapyAvailable.subTherapyId] || []}
                                isShow={openAddDrugDialog[therapyAvailable.subTherapyId] || false}
                                submitButtonLabel={'label.add'}
                            />
                            <ConfirmDialog
                                title={titleConfirmDialog}
                                text={textConfirmDialog}
                                buttonYes={buttonYes}
                                buttonNo={buttonNo}
                                open={openConfirmDialog}
                                setOpen={this.setConfirmOpen}
                                onConfirm={onConfirmDialog}
                            />
*/}
                        </React.Fragment>
                        }
                        {therapyAvailable.thType === TherapyType.SIMPLE &&
                        <React.Fragment>
                            <PanelDivider/>
                            <FormControl component='fieldset'>
                                {
                                    therapyAvailable.items
                                        .map((item) =>
                                            <FormControlLabel
                                                key={Number(item.id)}
                                                label={item.name}
                                                onChange={(e) => {
                                                    if (isPrescriptionReadonly) {
                                                        e.preventDefault();
                                                        return;
                                                    }

                                                    this.onSimpleSelectChange(Number(item.id), therapyAvailable)(e);
                                                }}
                                                control={
                                                    <Checkbox
                                                        color={"primary"}
                                                        checked={!((selectedSimpleData[therapyAvailable.subTherapyId] || []).indexOf(Number(item.id)) < 0)}
                                                        value={Number(item.id)}
                                                    />
                                                }
                                            />
                                        )
                                }
                            </FormControl>
                        </React.Fragment>
                        }
                        <PanelDivider/>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl component='fieldset'>
                                    <RadioGroup
                                        onChange={this.onSkipVarChange(therapyAvailable)}
                                        value={selectedSkipVar[therapyAvailable.subTherapyId] ? Number(selectedSkipVar[therapyAvailable.subTherapyId]) : 0}
                                    >
                                        {(therapyAvailable.skipVars || [])
                                            .map(skipVar => (
                                                <FormControlLabel
                                                    key={skipVar.id}
                                                    value={Number(skipVar.id)}
                                                    label={`${skipVar.name}`}
                                                    control={<Radio color={'primary'} />}
                                                />
                                            ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </React.Fragment>
        );
    }
}

SelectTherapyPresumablyView.propTypes = {
    intl: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    nosologyData: PropTypes.object.isRequired,
    nosology: PropTypes.string.isRequired,
    setTherapyPresumably: PropTypes.func.isRequired,
    getTherapyPresumably: PropTypes.func.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
};

SelectTherapyPresumablyView.defaultProps = {
    // baseline: [],
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(SelectTherapyPresumablyView);
