import PropTypes from 'prop-types';
import React from 'react';
import {
    Box,
    Paper,
} from '@material-ui/core';
import mainStyles from '../styles/mainStyles';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {getStaticBaselineView} from '../utils';
import PanelDivider from "./PanelDivider";

const styles = theme => ({
    ...mainStyles(theme),
});

const StaticBaseline = (props) => {
    const {classes, staticBaseline, hideDivider} = props;
    const staticBaselineView = getStaticBaselineView(staticBaseline, classes);

    return (
        staticBaselineView && staticBaselineView.length ?
        <Box>
            {!hideDivider && <PanelDivider/>}
            <Paper className={classes.contentBoxPanel}>
                {staticBaselineView}
            </Paper>
        </Box>
        : <Box></Box>
    );
}

StaticBaseline.propTypes = {
    staticBaseline: PropTypes.array,
    hideDivider: PropTypes.bool,
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
)(StaticBaseline);
