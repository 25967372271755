import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    FormControlLabel,
    Typography,
    Grid,
    Checkbox,
    CardHeader,
    Card
} from "@material-ui/core";
import SelTextView from "../SelTextView";
import {FormattedMessage, injectIntl} from "react-intl";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import config from "../../config";
import {getChartA} from "../../query/HeartFailureBaseline";
import {
    getDrugStyleObject, getEvidenceLevelClassName, getHyphenedLang,
    getServiceMessages, isMultiselect,
    sortByOrder
} from "../../utils";
import {MessageType} from "../../const";
import type {ServiceMessage} from "../../const";
import {InfoHelp} from "../common/InfoHelp";
import ServiceCheckbox from "../button/ServiceCheckbox";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import {blue, grey, red} from "@material-ui/core/colors";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import {PaperColorSelect, SelectType} from "../PaperColor";
import Hyphenated from 'react-hyphen';

const styles = theme => ({
    ...mainStyles(theme),
    containerFlexWrap: {
        flexWrap: 'unset',
    },
    contentBoxDivider: {
        '&:not(:first-child)': {
            marginTop: theme.spacing(2),
        }
    },
    therapyCardContent: {
        paddingTop: 0,
    },
    therapySpace: {
        '&:not(:first-child)': {
            marginTop: theme.spacing(2),
        },
        paddingTop: theme.spacing(0.5),
    },
    therapyName: {
        textAlign: 'center',
        backgroundColor: grey[200],
        padding: '10px',
    },
    drugGroupName: {
        // backgroundColor: blue[100],
        display: 'flex',
        alignItems: 'center',
        // textAlign: 'center',
        // justifyContent: 'center',
        // paddingBottom: theme.spacing(2),
        cursor: 'pointer',
        // marginLeft: -theme.spacing(1),
    },
    evidenceLevelInfoHelp: {
        marginTop: '-6px',
        marginLeft: '4px',
    },
    cellItem: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '10px',
    },
    cellItemDrugs: {
        display: 'flex',
        alignItems: 'center',
    },
    allDrugs: {
        display: 'flex',
        backgroundColor: grey[200],
    },
    heightFitContent: {
        height: 'fit-content',
    },
    flexGrow1: {
        flexGrow: 1,
    },
    rowDrug: {
        // marginBottom: theme.spacing(2),
    },
    wrapAnywhere: {
        overflowWrap: 'anywhere',
    },
    drugName: {
        fontWeight: 600,
    },
    cellItemDrug: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '10px',
    },
    additionalInfo: {
        backgroundColor: red[200],
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '10px',
    },
    fullHeight: {
        height: '100%',
    },
    fullWidthHeight: {
        width: '100%',
        height: '100%',
    },
    drugLabel: {
        display: 'flex',
        flexDirection: 'row',
    },
    drugRegimens: {
        marginLeft: '4px',
    },

    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

class IbsSelectTherapyView extends React.Component {

    abortController = new AbortController();
    abortChartController = new AbortController();

    steps;
    selGroup;

    constructor(props) {
        super(props);

        this.state = {
            expanded: !!props?.therapyPool?.therapies?.length, // true,
            selGroupId: 0,
            charts: {},
            statistics: [],
            selectDrugRegimenDialog: undefined,
        };
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        this.setState({
            selTherapyId: (nextProps.selectedTherapy2 || {}).id,
        });
    }

    componentDidMount() {
        // this.loadDrugs();
        this.setVars();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.abortChartController.abort();
    }

    setVars = () => {
        const {
            getTherapyGroupDrugs,
        } = this.props;
        const {selGroupId} = this.state;

        this.drugGroups = getTherapyGroupDrugs();
        this.selGroup = (this.drugGroups || {})[selGroupId];

        this.setSelected();
        // this.loadStatistics();
        // this.forestPlotView();
    }

    setSelected = () => {
        const {
            nosologyData,
            updateContextPanel,
        } = this.props;

        if (
            !nosologyData.touched
        ) {
            let selDrugs = {};

            (this.drugGroups || []).forEach((drugGroup) => {
                // console.error('+++ drugGroup:', drugGroup);
                drugGroup.drugs.forEach((drug) => {
                    if (drug.selected) {
                        drug.groupName = drugGroup.name;
                        drug.groupShortName = drugGroup.shortName;

                        drug.selectedRegimen = drug.regimens.filter((regimen) => regimen.selected)[0];

                        if (drug.id === -1) {
                            selDrugs[drugGroup.id] = [];
                        }

                        selDrugs[drugGroup.id] = selDrugs[drugGroup.id] ? (isMultiselect(drugGroup) ? [...selDrugs[drugGroup.id], ...[drug]] : [drug]) : [drug];
                    }
                });
            });

            nosologyData.selDrugs = selDrugs;

            this.setState(prevState => ({
                nosologyData: {
                    ...prevState.nosologyData,
                    selDrugs: nosologyData.selDrugs,
                },
            }), () => {
                updateContextPanel();
            });
        }
    }

    loadChart = (callback) => {
        const {client} = this.props;
        const {charts} = this.state;

        // if (expandedCharts && !Boolean(charts[(this.selGroup || {}).id])) {
        if (!Boolean(charts[(this.selGroup || {}).id])) {
            const {signal} = this.abortChartController;
            return client.query({
                query: getChartA,
                variables: {
                    id: (this.selGroup || {}).id,
                },
                context: {
                    uri: config.options.server.heart_failure_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                .then(result => result.data)
                .then(data => {
                    if (Boolean(data) && data.getChartA) {
                        // const chart = data.getChartA;
                        let chart = JSON.parse(JSON.stringify(data.getChartA));

                        // chart = (chart || []).map((_chart) => {
                        //     // _chart.path = config.options.server.heart_failure_url + '/chart/' + _chart.path;
                        //     _chart.path = _chart.url;
                        //     return _chart;
                        // });

                        charts[(this.selGroup || {}).id] = chart;

                        /*
                                                charts[this.selGroup.id] = (charts[this.selGroup.id] || []).map((chart) => {
                                                    chart.path = config.options.server.heart_failure_url + '/chart/' + chart.path;
                                                    return chart;
                                                });
                        */

                        // console.error('+++ HF +++ loadChart() this.selGroup:', this.selGroup);
                        // console.error('+++ HF +++ loadChart() charts[this.selGroup.id]:', charts[this.selGroup.id]);
                        // console.error('+++ HF +++ loadChart() charts:', charts);
                        // console.error('+++ HF +++ loadChart() _charts:', _charts);

                        this.setState({
                            charts: charts,
                        }, () => {
                            if (!!callback) {
                                callback();
                                // TODO: +++2021.12.03+++
                                //  this.forestPlotView();
                            } else {
                                // TODO: +++2021.12.03+++
                                //  this.forestPlotView();
                            }
                        });
                        return new Promise((resolve, reject) => {
                            resolve(chart);
                        });
                    } else {
                        return new Promise((resolve, reject) => {
                            resolve(null);
                        });
                    }
                });
        } else {
            // TODO: +++2021.12.03+++
            //  this.forestPlotView();
            if (!!callback) {
                callback();
            }
            return new Promise((resolve, reject) => {
                resolve(charts[(this.selGroup || {}).id]);
            });
        }
    };

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    forestPlotView = () => {
        updateShowPanel(true);
        setLoadChartsMethod(this.loadChart);
    };

    handleExpandClick = () => {
        this.setState(prevState => ({
            expanded: !prevState.expanded,
        }));
    };

    onDrugGroupSelect = (therapy, drugGroupCombination, drugGroup) => {
        const {setSelectTherapy2, therapyPool} = this.props;
        // this.selTherapyId = therapy.id;
        // this.selDrugGroupId = drugGroup.id;
        // console.error('+++ therapy:', therapy);
        // console.error('+++ drugGroup:', drugGroup);

        // console.error('+++ therapy:', therapy);
        setSelectTherapy2(therapyPool, therapy, drugGroupCombination, drugGroup);
    };

    isTherapySelected = (therapy) => {
        return therapy.selected
    }

    render() {
        const {
            classes,
            therapyPool
        } = this.props;
        const {
            expanded,
        } = this.state;
        const { locale } = this.context;

        const therapyPoolMessagesWARNING: ServiceMessage = getServiceMessages(therapyPool.messages, MessageType.WARNING);

        return (
            <Box className={classes.contentBoxDivider}>
                <Card variant={"outlined"}
                    // className={classes.contentBoxBottomSpace}
                >
                    <CardHeader
                        title={
                            <Typography variant={"h6"}>
                                {therapyPool.name}
                            </Typography>
                        }

                        action={
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={this.handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon/>
                            </IconButton>
                        }
                    />
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent className={classes.therapyCardContent}>
                                {!therapyPool.therapies.length &&
                                    <Grid container direction={"column"}
                                          className={classes.containerFlexWrap}>
                                        <Grid item className={classes.drugGroupName} xl={12}
                                              lg={12} md={12} sm={12} xs={12}
                                        >
                                            {therapyPoolMessagesWARNING.showService && therapyPoolMessagesWARNING.messages.map((therapyPoolMessage, index) => {
                                                return (
                                                    <Typography key={index} variant={"body1"}>
                                                        {therapyPoolMessage.payload}
                                                    </Typography>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                }
                                {!!therapyPool.therapies.length &&
                                    therapyPool.therapies.sort(sortByOrder).map((therapy) => {
                                        const thesis: ServiceMessage = getServiceMessages(therapy.messages, MessageType.INFO);
                                        const therapyMessagesWARNING: ServiceMessage = getServiceMessages(therapy.messages, MessageType.WARNING);

                                        return (
                                            <PaperColorSelect
                                                key={therapy.id}
                                                className={classes.contentBox + ' ' + classes.therapySpace}
                                                seltype={this.isTherapySelected(therapy) ? SelectType.POSITIVE : SelectType.NONE}
                                            >
                                                <Grid container direction={"column"} className={classes.containerFlexWrap}>
                                                    {therapy.drugGroupCombinations.sort(sortByOrder).map((drugGroupCombination) => (
                                                        drugGroupCombination.drugGroups.sort(sortByOrder).map((drugGroup) => {

                                                            const drugsMessagesWARNING: ServiceMessage = {
                                                                type: MessageType.WARNING,
                                                                messages: [],
                                                            };
                                                            drugGroup.drugs.sort(sortByOrder).forEach((drug) => {
                                                                const drugMessagesWARNING = getServiceMessages(drug.messages, MessageType.WARNING);
                                                                // console.error('+++---+++ 000 drugMessagesWARNING:', drugMessagesWARNING);
                                                                if (drugMessagesWARNING.showService) {
                                                                    drugsMessagesWARNING.messages = [...drugsMessagesWARNING.messages, ...drugMessagesWARNING.messages];
                                                                }
                                                                // if (!drugsMessagesWARNING) {
                                                                //     drugsMessagesWARNING = newAnyDataRef(drugMessagesWARNING);
                                                                // } else {
                                                                //     if (drugMessagesWARNING.showService) {
                                                                //         drugsMessagesWARNING.messages = [...drugsMessagesWARNING.messages, ...drugMessagesWARNING.messages];
                                                                //     }
                                                                // }
                                                            });
                                                            drugsMessagesWARNING.showService = drugsMessagesWARNING.messages.length > 0;
                                                            // console.error('+++---+++ drugsMessagesWARNING:', drugsMessagesWARNING);

                                                            return (<Grid key={drugGroup.id} item xl={12} lg={12} md={12}
                                                                  sm={12} xs={12} className={classes.flexGrow1}>
                                                                <Grid container direction={"column"}
                                                                      className={classes.containerFlexWrap}>
                                                                    <Grid item className={classes.drugGroupName} xl={12}
                                                                          lg={12} md={12} sm={12} xs={12}
                                                                    >
                                                                        <FormControlLabel
                                                                            value={therapy}
                                                                            disabled={false}
                                                                            label={<SelTextView>{drugGroup.name}</SelTextView>}
                                                                            control={
                                                                                <ServiceCheckbox
                                                                                    color={"primary"}
                                                                                    checked={this.isTherapySelected(therapy)}
                                                                                    value={therapy}
                                                                                    onChange={() => this.onDrugGroupSelect(therapy, drugGroupCombination, drugGroup)}
                                                                                    showService={drugsMessagesWARNING.showService}
                                                                                    text={drugsMessagesWARNING.messages}
                                                                                    type={drugsMessagesWARNING.type}
                                                                                />
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                                                          className={classes.allDrugs}>
                                                                        <Grid container direction={"row"}
                                                                              alignItems={"center"}
                                                                              className={classes.heightFitContent + ' ' + classes.containerFlexWrap}>
                                                                            <Grid item xl={5} lg={5} md={5} sm={5}
                                                                                  xs={12}>
                                                                                {drugGroup.drugs.sort(sortByOrder).map((drug) =>
                                                                                    <Grid key={drug.id} container
                                                                                          direction={"row"}
                                                                                          alignItems={"center"}
                                                                                          justifyContent={"center"}
                                                                                          className={classes.rowDrug + ' ' + classes.containerFlexWrap}>
                                                                                        <Grid item xl={5} lg={5} md={5}
                                                                                              sm={5} xs={12}
                                                                                              className={classes.cellItemDrug}
                                                                                        >
                                                                                            <Typography key={drug.id}
                                                                                                        variant={"body2"}
                                                                                                        className={classes.wrapAnywhere + ' ' + classes.drugName + ' ' + getDrugStyleObject(drug, classes).drugStyle}>
                                                                                                <Hyphenated language={getHyphenedLang(locale)}>
                                                                                                    {drug.name || ''}
                                                                                                </Hyphenated>
                                                                                            </Typography>
                                                                                            {drug.regimens.sort(sortByOrder).map((regimen) =>
                                                                                                <Typography
                                                                                                    key={regimen.id}
                                                                                                    variant={"subtitle2"}>
                                                                                                    <Hyphenated language={getHyphenedLang(locale)}>
                                                                                                        {regimen.name || ''}
                                                                                                    </Hyphenated>
                                                                                                </Typography>
                                                                                            )}
                                                                                        </Grid>
                                                                                        <Grid item xl={7} lg={7} md={7}
                                                                                              sm={7} xs={12}
                                                                                              className={classes.cellItem}
                                                                                        >
                                                                                            {drug.regimens.sort(sortByOrder).map((regimen) =>
                                                                                                <Typography
                                                                                                    key={regimen.id}
                                                                                                    variant={"body2"}>
                                                                                                    <Hyphenated language={getHyphenedLang(locale)}>
                                                                                                        {regimen.duration || ''}
                                                                                                    </Hyphenated>
                                                                                                </Typography>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )}
                                                                            </Grid>
                                                                            <Grid item xl={7} lg={7} md={7} sm={7}
                                                                                  xs={12}
                                                                                // className={classes.cellItem}
                                                                            >
                                                                                {thesis.messages.map((thesisMessage, index) =>
                                                                                    <Grid key={index} container
                                                                                          direction={"row"}
                                                                                          alignItems={"center"}
                                                                                          justifyContent={"center"}
                                                                                          className={classes.rowDrug + ' ' + classes.containerFlexWrap}>
                                                                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                                                                              className={classes.cellItemDrug}
                                                                                        >
                                                                                            {!!thesisMessage.payload &&
                                                                                                <Hyphenated
                                                                                                    language={getHyphenedLang(locale)}>
                                                                                                    <ReactMarkdown
                                                                                                        key={index}
                                                                                                        remarkPlugins={[remarkGfm]}
                                                                                                        rehypePlugins={[rehypeRaw]}
                                                                                                        children={thesisMessage.payload}/>
                                                                                                </Hyphenated>
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>)
                                                        })
                                                    ))
                                                    }
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                                          className={classes.additionalInfo}>
                                                        {therapy.additionalInfo.sort(sortByOrder).map((additionalInfo) =>
                                                            <Typography key={additionalInfo.id} variant={"body2"}>
                                                                <Hyphenated language={getHyphenedLang(locale)}>
                                                                    {additionalInfo.name || ''}
                                                                </Hyphenated>
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        {therapy.evidenceLevels.sort(sortByOrder).map((evidenceLevel) =>
                                                                // <Box key={evidenceLevel.id} className={getEvidenceLevelClassName(evidenceLevel.colorType, classes) + ' ' + classes.fullWidthHeight + ' ' + classes.cellItem} alignItems={"center"}>
                                                                <Grid container direction={"row"} key={evidenceLevel.id}
                                                                      className={getEvidenceLevelClassName(evidenceLevel.colorType, classes) + ' ' + classes.fullWidthHeight + ' ' + classes.cellItem}
                                                                      alignItems={"center"}
                                                                >
                                                                    <Grid item>
                                                                        <Typography variant={"body2"}>
                                                                            <Hyphenated language={getHyphenedLang(locale)}>
                                                                                {evidenceLevel.name || ''}
                                                                            </Hyphenated>
                                                                        </Typography>
                                                                    </Grid>
                                                                    {therapyMessagesWARNING &&
                                                                        <Grid item className={classes.evidenceLevelInfoHelp}>
                                                                            <InfoHelp
                                                                                texts={therapyMessagesWARNING.messages}
                                                                                type={MessageType.INFO}/>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            // </Box>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </PaperColorSelect>
                                        )
                                    })}
                            </CardContent>
                    </Collapse>
                </Card>

                {/*<PanelDivider/>*/}
            </Box>
        );
    }
}

IbsSelectTherapyView.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,

    getTouched: PropTypes.func.isRequired,
    getRecomentTextsView: PropTypes.func,
    getTherapyGroupDrugs: PropTypes.func.isRequired,
    setTherapyGroupDrugs: PropTypes.func.isRequired,
    getStatistics: PropTypes.func.isRequired,
    setStatistics: PropTypes.func.isRequired,
    setDrugForTherapyGroup: PropTypes.func.isRequired,
    getSelectTherapyComb: PropTypes.func.isRequired,
    // getSelectTherapyType: PropTypes.func.isRequired,
    setForestPlotView: PropTypes.func.isRequired,

    therapyPool: PropTypes.object.isRequired,
    setSelectTherapy2: PropTypes.func.isRequired,
    selectedTherapy2: PropTypes.object,
};

IbsSelectTherapyView.contextTypes = {
    locale: PropTypes.string,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(IbsSelectTherapyView);
