import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const styles = theme => ({
  panel: {
    padding: theme.spacing(2),
  },
});

function ContentPanel(props) {
  const { classes, children } = props;

  return (
    <Paper className={classes.panel}>
      {children}
    </Paper>
  );
}

ContentPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContentPanel);