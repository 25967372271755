import { gql } from '@apollo/client';

export const getFullTableRsHr = gql`
  query ($aqUid: Int!) {
    getFullTableRsHr(aqUid: $aqUid) {
      rsHrId
      outcome
      subgroup
      intervention
      comparison
      intN
      hrIntN
      compN
      hrCompN
      hr
      lbCi,
      ubCi,
      p,
      rsOcId
      rsIntId
      rsCompId
    }
  }
`;

export const getAQUserRsOutcomes = gql`
  query ($aqUid: Int!) {
    getAQUserRsOutcomes(aqUid: $aqUid) {
      id
      aqUid
      ocId
      name
    }
  }
`;

export const getAQUserRsSubgroups = gql`
  query ($aqUid: Int!) {
    getAQUserRsSubgroups(aqUid: $aqUid) {
      id
      aqUid
      subgroup
    }
  }
`;

export const getAQUserRsInts = gql`
  query ($aqUid: Int!) {
    getAQUserRsInts(aqUid: $aqUid) {
      id
      sgrId
      intervention
      n
      subgroup
    }
  }
`;

export const getAQUserRsComps = gql`
  query ($aqUid: Int!) {
    getAQUserRsComps(aqUid: $aqUid) {
      id
      sgrId
      comparison
      n
      subgroup
    }
  }
`;

export const getRsDesign = gql`
  {
    getRsDesign {
      id
      name
    }
  }
`;

export const getRsFollowupLen = gql`
  {
    getRsFollowupLen {
      id
      name
    }
  }
`;

export const getRsTime = gql`
  {
    getRsTime {
      id
      name
    }
  }
`;

export const getAQUserRsOpt = gql`
  query ($aqUid: Int!) {
    getAQUserRsOpt(aqUid: $aqUid) {
      designId
      designDesc
      isHr
    }
  }
`;

export const getAQUserRsPico = gql`
  query ($aqUid: Int!) {
    getAQUserRsPico(aqUid: $aqUid)
  }
`;

export const getAQUserRsFollowup = gql`
  query ($aqUid: Int!) {
    getAQUserRsFollowup(aqUid: $aqUid) {
      lenId
      value
      timeId
      timeOther
    }
  }
`;

export const getRSQuestionData = gql`
  query ($rsQId: Int!) {
    getRSQuestionData(rsQId: $rsQId) {
      id
      dtype
      name
      title
    }
  }
`;

export const buildFLForestPlot = gql`
  query ($patientId: Int!, $forestPlotIn: FLForestPlotIn!) {
    buildFLForestPlot(patientId: $patientId, forestPlotIn: $forestPlotIn) {
      data {
        url
        title
      }
      error
    }
  }
`;

export const calcFLPScore = gql`
  query ($patientId: Int!, $pScoreIn: FLPScoreIn!) {
    calcFLPScore (patientId: $patientId, pScoreIn: $pScoreIn) {
      data {
        efficacy {
          name
          value
        }
        safety {
          name
          value
        }
        treatsRate {
          name
          rate
        }
      }
      error
    }
  }
`;

export const getRSQuestion = gql`
  query ($id: Int!) {
    getRSQuestion(id: $id) {
      id
      questionId
    }
  }
`;

export const getRSQuestionWithQuestionid = gql`
  query ($questionId: Int!) {
    getRSQuestionWithQuestionid(questionId: $questionId) {
      id
      questionId
      ref {
        id
        name
      }
      treatments {
        id
        name
        num
        isSel
      }
      subgroups {
        id
        subgroup
        isSel
        mark
      }
      data {
        id
        dtype
        name
        title
      }
    }
  }
`;

export const updateAQUserRsHR = gql`
  mutation ($aqUserRsHR: AQUserRsHR!) {
    updateAQUserRsHR(aqUserRsHR: $aqUserRsHR)
  }
`;

export const addAQUserRsHR = gql`
  mutation ($aqUserRsHR: AQUserRsHR!) {
    addAQUserRsHR(aqUserRsHR: $aqUserRsHR)
  }
`;

export const addAQUserRsOutcome = gql`
  mutation ($aqUid: Int!, $outcomeName: String!) {
    addAQUserRsOutcome(aqUid: $aqUid, outcomeName: $outcomeName)
  }
`;

export const updateAQUserRsOutcome = gql`
  mutation ($id: Int!, $outcomeName: String!) {
    updateAQUserRsOutcome(id: $id, outcomeName: $outcomeName)
  }
`;

export const deleteAQUserRsOutcome = gql`
  mutation ($id: Int!) {
    deleteAQUserRsOutcome(id: $id)
  }
`;

export const addAQUserRsSubgroup = gql`
  mutation ($aqUid: Int!, $subgroup: String!) {
    addAQUserRsSubgroup(aqUid: $aqUid, subgroup: $subgroup)
  }
`;

export const updateAQUserRsSubgroup = gql`
  mutation ($id: Int!, $subgroup: String!) {
    updateAQUserRsSubgroup(id: $id, subgroup: $subgroup)
  }
`;

export const deleteAQUserRsSubgroup = gql`
  mutation ($id: Int!) {
    deleteAQUserRsSubgroup(id: $id)
  }
`;

export const addAQUserRsInt = gql`
  mutation ($sgrId: Int!, $intervention: String!, $n: Int!) {
    addAQUserRsInt(sgrId: $sgrId, intervention: $intervention, n: $n)
  }
`;

export const updateAQUserRsInt = gql`
  mutation ($id: Int!, $sgrId: Int!, $intervention: String!, $n: Int!) {
    updateAQUserRsInt(id: $id, sgrId: $sgrId, intervention: $intervention, n: $n)
  }
`;

export const deleteAQUserRsInt = gql`
  mutation ($id: Int!) {
    deleteAQUserRsInt(id: $id)
  }
`;

export const addAQUserRsComp = gql`
  mutation ($sgrId: Int!, $comparison: String!, $n: Int!) {
    addAQUserRsComp(sgrId: $sgrId, comparison: $comparison, n: $n)
  }
`;

export const updateAQUserRsComp = gql`
  mutation ($id: Int!, $sgrId: Int!, $comparison: String!, $n: Int!) {
    updateAQUserRsComp(id: $id, sgrId: $sgrId, comparison: $comparison, n: $n)
  }
`;

export const deleteAQUserRsComp = gql`
  mutation ($id: Int!) {
    deleteAQUserRsComp(id: $id)
  }
`;

export const updateAQUserRsOpt = gql`
  mutation ($aqUid: Int!, $newOpt: AQUserRsOptIn!) {
    updateAQUserRsOpt(aqUid: $aqUid, newOpt: $newOpt)
  }
`;

export const updateAQUserRsPico = gql`
  mutation ($aqUid: Int!, $newPico: String!) {
    updateAQUserRsPico(aqUid: $aqUid, newPico: $newPico)
  }
`;

export const updateAQUserRsFollowup = gql`
  mutation ($aqUid: Int!, $newFollowup: AQUserRsFollowupIn!) {
    updateAQUserRsFollowup(aqUid: $aqUid, newFollowup: $newFollowup)
  }
`;
