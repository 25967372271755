import TextField from "@material-ui/core/TextField";
import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import validator from 'validator';
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import compose from 'recompose/compose';
import config from '../../config';
import {RegType} from "../../const";

const styles = theme => ({
  ...mainStyles(theme),
  submit: {
    marginTop: theme.spacing(3),
  },
  info_head: {
    marginTop: theme.spacing(4),
  },
  info_head_reset: {
    marginTop: theme.spacing(1),
  },
  agreement: {
    marginTop: theme.spacing(2),
  },
  asDiv: {
    display: 'block',
  },
});

class RegForm extends React.Component{

  constructor(props) {
    super(props);
    const { intl } = props;

    this.state = {
      fields: new Map([
        ['username', props.username ? props.username : ''],
        ['firstName', ''],
        ['password', ''],
        ['passwordConfirm', ''],
        ['code', ''],
      ]),
      errors: new Map(),
      isAgreement: false,
    };

    this.validate = {
      'username': (value) => {
        let errors = this.state.errors;

        if (value.length < 5 || !validator.isEmail(value)) {
          errors.set('username', intl.formatMessage({id: 'err.login.invalid', defaultMessage: 'Ivalid login'}));
        } else {
          errors.delete('username');
        }

        return errors;
      },
      'firstName': () => {
        return this.state.errors;
      },
      'code': (value) => {
        let errors = this.state.errors;

        if (props.regType === RegType.INVITE_PUBLIC && value.length === 0) {
          errors.set('code', intl.formatMessage({id: 'err.code.empty', defaultMessage: 'Code not empty'}));
        } else {
          errors.delete('code');
        }

        return errors;
      },
      'password': (value) => {
        let errors = this.state.errors;

        if (value.length === 0) {
          errors.set('password', intl.formatMessage({id: 'err.password.empty', defaultMessage: 'Password not empty'}));
        } else {
          errors.delete('password');
        }

        return errors;
      },
      'passwordConfirm': (value) => {
        let errors = this.state.errors;

        if (value.length === 0) {
          errors.set('passwordConfirm', intl.formatMessage({id: 'err.password.empty', defaultMessage: 'Password not empty'}));
        } else if (value !== this.state.fields.get('password')) {
          errors.set('passwordConfirm', intl.formatMessage({id: 'err.passwords.match', defaultMessage: 'Passwords do not match'}));
        } else {
          errors.delete('passwordConfirm');
        }

        return errors;
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.username !== prevProps.username) {
      this.setState({
        fields: this.state.fields.set('username', this.props.username),
      });
    }
  }

  onValueChange = (event) => {
    let target = event.target;

    if (target.name === 'username' && this.props.userReadonly) {
      return;
    }

    this.setState({
      fields: this.state.fields.set(target.name, target.value),
      errors: this.validate[target.name](target.value),
    });
  };

  onSubmit = (event) => {
    const { fields } = this.state;
    const { hideRegCode } = this.props;

    const params = {
      username: fields.get('username'),
      password: fields.get('password'),
      first_name: fields.get('firstName'),
    };
    if (!hideRegCode) {
      params.reg_code = fields.get('code');
    }

    this.props.onSubmit(params);

    event.preventDefault();
  };

  agreementChange = (e) => {
    const { isAgreement } = this.state;
    this.setState({
      isAgreement: !isAgreement,
    });
  };

  getDisable= (): boolean => {
    const { errors, fields, isAgreement } = this.state;
    const { showReset, loading, regType, regCode } = this.props;
    // return !isAgreement || errors.size > 0;
    if (showReset) {
      return loading || !fields.get('username') || !fields.get('password') || !fields.get('passwordConfirm') || errors.size > 0;
    } else {
      return loading || !isAgreement || !fields.get('username') || (regType === RegType.INVITE_PUBLIC && !regCode && !fields.get('code')) || !fields.get('password') || !fields.get('passwordConfirm') || errors.size > 0;
    }
  }

  render() {
    const { classes, intl, showReset, regCode, hideRegCode } = this.props;
    const { fields, errors, isAgreement } = this.state;
    const { locale } = this.context;

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <TextField
          id="username"
          name="username"
          autoComplete="username"
          value={fields.get('username')}
          onChange={this.onValueChange}
          autoFocus
          margin="normal"
          required
          fullWidth
          label={intl.formatMessage({id: 'label.email', defaultMessage: 'Email'})}
          error={errors.get('username') != null}
          helperText={errors.get('username')}
        />
        {!showReset &&
        <TextField
            id="firstName"
            name="firstName"
            autoComplete="firstName"
            value={fields.get('firstName')}
            onChange={this.onValueChange}
            margin="normal"
            fullWidth
            label={intl.formatMessage({id: 'label.firstname', defaultMessage: 'Name'})}
            error={errors.get('firstName') != null}
            helperText={errors.get('firstName')}
        />
        }
        {/*{(!showReset && regType === RegType.INVITE_PUBLIC) &&*/}
        {(!showReset && !regCode && !hideRegCode) &&
        <TextField
            id="code"
            name="code"
            autoComplete="code"
            value={fields.get('code')}
            onChange={this.onValueChange}
            margin="normal"
            required
            fullWidth
            label={intl.formatMessage({id: 'label.code', defaultMessage: 'Code'})}
            error={errors.get('code') != null}
            helperText={errors.get('code')}
        />
        }
        <TextField
          id="password"
          name="password"
          type="password"
          autoComplete="passwordCurrent"
          value={fields.get('password')}
          onChange={this.onValueChange}
          margin="normal"
          required
          fullWidth
          label={intl.formatMessage({id: 'label.password', defaultMessage: 'Password'})}
          error={errors.get('password') != null}
          helperText={errors.get('password')}
        />
        <TextField
          id="passwordConfirm"
          name="passwordConfirm"
          type="password"
          autoComplete="passwordConfirm"
          value={fields.get('passwordConfirm')}
          onChange={this.onValueChange}
          margin="normal"
          required
          fullWidth
          label={intl.formatMessage({id: 'label.password.confirm', defaultMessage: 'Password confirm'})}
          error={errors.get('passwordConfirm') != null}
          helperText={errors.get('passwordConfirm')}
        />
        {!showReset &&
        <FormControlLabel
            className={classes.agreement}
            control={<Checkbox checked={isAgreement} onChange={this.agreementChange} color={"primary"}/>}
            label={<Button
                href={config.options['agreement'][locale]}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.textButton}
                color="primary"
                size="small">
              <FormattedMessage id='label.agreement' defaultMessage='I agree'/>
            </Button>}/>
        }
        <div className={classes.contentBoxCenter}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={this.getDisable()}
            className={classes.submit}>
            {!showReset ?
                <FormattedMessage id='label.register' defaultMessage='Register'/>
                :
                <FormattedMessage id='message.account.reset_password' defaultMessage='Reset password'/>
            }
          </Button>
        </div>
        <Typography className={classes.info_head} variant="body2">
          <FormattedMessage id='message.account.already' />
        </Typography>
        <Link className={classes.link} to="/signin">
          <FormattedMessage id='message.account.login' />
        </Link>

        <Link
            className={`${classes.link} ${classes.asDiv} ${classes.info_head_reset}`}
            to="/reset"
        >
          <FormattedMessage id='message.account.reset_password' defaultMessage='Reset password' />
        </Link>

      </form>
    );
  }
}

RegForm.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userReadonly: PropTypes.bool,
  showReset: PropTypes.bool,
  loading: PropTypes.bool,
  regType: PropTypes.number,
  regCode: PropTypes.string,
  username: PropTypes.string,
  hideRegCode: PropTypes.bool,
};

RegForm.contextTypes = {
  locale: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(RegForm);
