import { gql } from '@apollo/client';

export const getHPBaseline = gql`
  query ($patientId: Int!) {
    getHPBaseline (patientId: $patientId) {
      id
      name
      opt
      ord
      fields {
        id
        name
        ord
        isRequired
        opt
        value
        vars {
          id
          name
          descr
          ord
          isDefault
          opt
        }
      }
    }
  }
`;

export const calcHPBaseline = gql`
  query ($patientId: Int!, $inSubgroupId: Int) {
    calcHPBaseline (patientId: $patientId, inSubgroupId: $inSubgroupId) {
      grade
      score
      scRisk
      articleIds
      referents {
        id
        name
      }
      params {
        key
        value
      }
      subgroups {
        id
        name
        priority
      }
      thTypes {
        id
        name
        title
        comment
        combinations {
          id
          combination {
            id
            name
            shortName
            group
            contStatus
          }
          isPriority
          isAvailable
        }
        comps {
          id
          name
          riskPl
          riskTh
        }
        messages {
          type
          payload
        }
      }
      compGroups {
        id
        name
        shortName
        group
        messages {
          type
          payload
        }
      }
      possGroups {
        id
        name
        shortName
        group
        messages {
          type
          payload
        }
      }
    }
  }
`;

export const getRecommendTexts = gql`
  query ($patientId: Int!, $selThIds: [Int!]) {
    getRecommendTexts (patientId: $patientId, selThIds: $selThIds) {
      text
      label
    }
  }
`;

export const getTherapyCombinations = gql`
  query ($patientId: Int!, $thTypeId: Int!, $inSubgroupId: Int!) {
    getTherapyCombinations (patientId: $patientId, thTypeId: $thTypeId, inSubgroupId: $inSubgroupId) {
      params {
        key
        value
      }
      combinations {
        id
        combination {
          id
          name
          shortName
          group
          contStatus
        }
        isPriority
        isAvailable
        comps {
          id
          name
          riskPl
          riskTh
        }
        messages {
          type
          payload
        }
      }
    }
  }
`;

export const getDrugsWithTherapyGroups = gql`
  query ($patientId: Int!, $thTypeId: Int!, $thGIds: [Int!]!,$patientId: Int = 0) {
    getDrugsWithTherapyGroups (patientId: $patientId, thTypeId: $thTypeId, thGIds: $thGIds) {
      groupDrugs {
        id
        name
        shortName
        group
        isAvailable
        takeGroups {
          id
          name
          drugs {
            id
            drugId
            name
            dataAvailable
            compStatus
            isMatch
            availStatus
            messages {
              type
              payload
            }
          }
        }
      }
      combDrugs {
        combId,
        drugs {
          id
          name
        }
      }
    }
  }
`;

export const getHPResultTexts = gql`
  query ($patientId: Int!, $selThIds: [Int!]) {
    getHPResultTexts(patientId: $patientId, selThIds: $selThIds) {
      id
      name
      texts {
        text
        label
      }
    }
  }
`;

export const getHPDrugTexts = gql`
  query ($drugIds: [Int!]!) {
    getHPDrugTexts(drugIds: $drugIds) {
      id
      name
      texts {
        text
        label
      }
    }
  }
`;

export const getHPThTypeCharts = gql`
  query ($patientId: Int!, $refId: Int!, $inSubgroupId: Int) {
    getHPThTypeCharts(patientId: $patientId, refId: $refId, inSubgroupId: $inSubgroupId) {
      title
      url
    }
  }
`;

export const getHPDrugsChart = gql`
  query ($groupIds: [Int!]!, $refId: Int) {
    getHPDrugsChart(groupIds: $groupIds, refId: $refId) {
      title
      url
      drugs {
        id
        name
      }
    }
  }
`;

export const getHPTherapyPscore = gql`
  query ($patientId: Int!, $inSubgroupId: Int) {
    getHPTherapyPscore(patientId: $patientId, inSubgroupId: $inSubgroupId) {
      name
      efficacy
      safety
    }
  }
`;

export const getHPCombCharts = gql`
  query ($patientId: Int!, $combIds: [Int!]!) {
    getHPCombCharts(patientId: $patientId, combIds: $combIds) {
      title
      url
    }
  }
`;

export const getHPCombPscore = gql`
  query ($patientId: Int!, $combIds: [Int!]!) {
    getHPCombPscore(patientId: $patientId, combIds: $combIds) {
      name
      efficacy
      safety
    }
  }
`;
