
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContainerCenter from './ContainerCenter';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    background: '#aaa',
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  adsText: {
    color: 'white',
  },
});

function Ads(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <ContainerCenter>
        <Typography className={classes.adsText} variant="h5">Рекламный блок</Typography>
      </ContainerCenter>
    </div>
  );
}

export default withStyles(styles)(Ads);