import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import HomeIcon from '@material-ui/icons/Home';
import Grid from "@material-ui/core/Grid";
import sww from '../imgs/sww.gif';
import {makeStyles} from "@material-ui/core/styles";
import mainStyles from "../styles/mainStyles";
import AutorenewIcon from "@material-ui/icons/Autorenew";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    wrapper: {
        height: '100vh',
        margin: '-8px',
        backgroundColor: '#eee',
    },
}));

export const ErrorPage = (props) => {
    let imageWidth;
    let imageHeight;
    let variant;
    const classes = useStyles();

    const reloadPage = () => {
        window.location.reload();
    }

    const gotoHomePage = () => {
        window.location.replace('/');
    }

    const buttons = props.button || (
        <Grid container direction={'column'} spacing={2} style={{marginTop: '18px'}}>
            <Grid item>
                <Button color="primary" variant="contained" onClick={reloadPage} startIcon={<AutorenewIcon />}>
                    <FormattedMessage id="label.error.reload" defaultMessage="Reload page" />
                </Button>
            </Grid>
            <Grid item>
                <Button color="primary" variant="contained" onClick={gotoHomePage} startIcon={<HomeIcon />}>
                    <FormattedMessage id="label.error.goto_home_page" defaultMessage="Go to home page" />
                </Button>
            </Grid>
        </Grid>
    );

    switch (props.size) {
        case "small":
            imageWidth = 40;
            imageHeight = 40;
            variant = "h6";
            break;

        case "medium":
            imageWidth = 60;
            imageHeight = 60;
            variant = "h6";
            break;

        case "large":
            imageWidth = 100;
            imageHeight = 100;
            variant = "h4";
            break;

        default:
            imageWidth = 60;
            imageHeight = 60;
            variant = "h5";
            break;
    }

    if (props.type === "page") {
        return (
            <Box className={classes.wrapper}>
                <Box
                    style={{ transform: "translate(-50%, -50%)" }}
                    position="absolute"
                    top="50%"
                    left="50%"
                    textAlign="center"
                >
                    {props.image && (
                        <Box
                            clone
                            mb={props.title || props.description ? 2 : 0}
                            width={`${imageWidth}%`}
                            height={`${imageHeight}%`}
                        >
                            {props.image}
                        </Box>
                    )}

                    {props.title && (
                        <Box mb={!props.description && props.button ? 2 : 0.5}>
                            <Typography variant={variant}>{props.title}</Typography>
                        </Box>
                    )}

                    {props.description && (
                        <Box mb={props.button && 3}>
                            <Typography variant="body1">{props.description}</Typography>
                        </Box>
                    )}

                    <Box
                        clone
                        mb={props.title || props.description ? 2 : 0}
                        width={`${imageWidth}%`}
                        height={`${imageHeight}%`}
                    >
                        <img src={sww} alt={'sww'} />
                    </Box>

                    {/*{props.button && props.button}*/}
                    {buttons}
                    {/*<Button color="primary" onClick={reloadPage}>*/}
                    {/*    <FormattedMessage id="label.error.reload" defaultMessage="Reload page" />*/}
                    {/*</Button>*/}
                </Box>
            </Box>
        );
    }

    if (props.type === "card") {
        return (
            <Box padding={props.padding} textAlign="center">
                {props.image && (
                    <Box
                        clone
                        mb={props.title || props.description ? 2 : 0}
                        width={`${imageWidth}%`}
                        height={`${imageHeight}%`}
                    >
                        {props.image}
                    </Box>
                )}

                {props.title && (
                    <Box mb={!props.description && props.button ? 2 : 0}>
                        <Typography variant={variant}>{props.title}</Typography>
                    </Box>
                )}

                {props.description && (
                    <Box mb={props.button && 2}>
                        <Typography variant="body1">{props.description}</Typography>
                    </Box>
                )}

                {/*{props.button && props.button}*/}
                {buttons}
                {/*<Button color="primary" onClick={reloadPage}>*/}
                {/*    <FormattedMessage id="label.error.reload" defaultMessage="Reload page" />*/}
                {/*</Button>*/}
            </Box>
        );
    }

    return null;
}

ErrorPage.defaultProps = {
    type: "page",
    size: "medium",
    padding: 2,
};

ErrorPage.propTypes = {
    type: PropTypes.string,
    size: PropTypes.string,
    padding: PropTypes.number,

    image: PropTypes.element,
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.element,
};

export default ErrorPage;
