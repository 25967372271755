import React from 'react';
import { SvgIcon } from "@material-ui/core";

export function FibrillationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.9932 3.16368C6.7619 -1.41181 0.110422 2.60835 0.00135253 8.05832C-0.0264422 9.44726 0.374496 10.8002 1.17258 12.0475H6.34549L7.26558 10.514C7.53275 10.0687 8.17929 10.0544 8.4616 10.4983L10.4029 13.5488L13.2326 7.57499C13.4794 7.05373 14.2149 7.03718 14.4871 7.54264L16.9128 12.0475H22.8138C27.1851 5.21552 18.5459 -2.56752 11.9932 3.16368V3.16368Z" />
      <path d="M15.8738 13.0839L13.9082 9.43347L11.1287 15.3013C10.8922 15.8005 10.1967 15.8439 9.90013 15.3778L7.88607 12.2129L7.34644 13.1123C7.21937 13.324 6.99054 13.4536 6.74358 13.4536H2.28479C2.42456 13.5999 1.67963 12.8564 11.4973 22.6232C11.7715 22.8961 12.2147 22.8961 12.489 22.6232C22.1556 13.0067 21.562 13.5996 21.7015 13.4536H16.4928C16.2341 13.4536 15.9964 13.3116 15.8738 13.0839V13.0839Z" />
    </SvgIcon>
  );
}

export function HeartFailureIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M19.0781 12.7031C16.3645 12.7031 14.1562 14.9114 14.1562 17.625C14.1562 20.3386 16.3645 22.5469 19.0781 22.5469C21.7917 22.5469 24 20.3386 24 17.625C24 14.9114 21.7917 12.7031 19.0781 12.7031ZM18.375 20.0255L16.4716 18.1221L17.4659 17.1279L18.375 18.037L20.6904 15.7216L21.6846 16.7159L18.375 20.0255Z" />
      <path d="M19.0781 11.2969C19.9045 11.2969 20.6895 11.4666 21.4138 11.7559C22.1614 10.5361 22.5938 9.27374 22.5938 7.88086C22.5938 4.21619 20.0244 1.45312 16.6172 1.45312C13.9688 1.45312 12.2095 3.25964 11.2969 4.91455C10.3843 3.25964 8.67188 1.45312 6.02344 1.45312C2.61621 1.45312 0 4.21619 0 7.88086C0 12.6218 4.75397 15.6967 10.8362 20.9691L11.2969 21.3679L11.7576 20.9691C12.2179 20.5703 12.7158 20.1863 13.1618 19.8087C12.9086 19.1252 12.75 18.3955 12.75 17.625C12.75 14.1356 15.5887 11.2969 19.0781 11.2969V11.2969Z" />
    </SvgIcon>
  );
}

export function DyslipidemiaIcon(props) {
  return (
    <SvgIcon {...props}>
        <path d="M4.94,11.299c2.707,0,4.909-2.203,4.909-4.909S7.647,1.481,4.94,1.481S0.031,3.683,0.031,6.39S2.234,11.299,4.94,11.299z"/>
        <path d="M4.94,12.701c-0.824,0-1.607-0.169-2.33-0.458c-0.746,1.217-1.177,2.476-1.177,3.865c0,3.655,2.563,6.411,5.961,6.411
		c2.642,0,4.396-1.802,5.306-3.452c0.91,1.651,2.618,3.452,5.26,3.452c3.398,0,6.008-2.756,6.008-6.411
		c0-4.729-4.742-7.795-10.808-13.054l-0.46-0.398l-0.46,0.398c-0.459,0.398-0.956,0.781-1.401,1.157
		c0.253,0.682,0.411,1.41,0.411,2.178C11.252,9.87,8.421,12.701,4.94,12.701L4.94,12.701z"/>
    </SvgIcon>
  );
}

export function IbsIcon(props) {
  return (
    <SvgIcon {...props}>
        <path fill="#920413" d="M19.059,12.701c-2.707,0-4.909,2.203-4.909,4.909s2.202,4.909,4.909,4.909s4.909-2.202,4.909-4.909S21.765,12.701,19.059,12.701z"/>
        <path d="M19.059,11.299c0.824,0,1.607,0.169,2.33,0.458c0.746-1.217,1.177-2.476,1.177-3.865c0-3.655-2.563-6.411-5.961-6.411
		c-2.642,0-4.396,1.802-5.306,3.452c-0.91-1.651-2.618-3.452-5.26-3.452c-3.398,0-6.008,2.756-6.008,6.411
		c0,4.729,4.742,7.795,10.808,13.054l0.46,0.398l0.46-0.398c0.459-0.398,0.956-0.781,1.401-1.157
		c-0.253-0.682-0.411-1.41-0.411-2.178C12.747,14.13,15.578,11.299,19.059,11.299L19.059,11.299z"/>
    </SvgIcon>
  );
}

export function DrugIcon(props) {
  return (
    <SvgIcon {...props}>
        <path d="M22.38,1.87c-2.498-2.493-6.558-2.493-9.055,0L8.047,7.147l5.995,5.985c1.232-1.028,2.799-1.669,4.525-1.669c0.99,0,1.935,0.203,2.789,0.572l1.024-1.121C24.873,8.422,24.873,4.367,22.38,1.87z"/>
        <path d="M13.043,14.145L7.039,8.15l-5.166,5.165c-2.498,2.493-2.498,6.553,0,9.046s6.606,2.542,9.104,0.048l1.048-1.052c-0.369-0.858-0.577-1.799-0.577-2.789C11.447,16.891,12.059,15.363,13.043,14.145z"/>
        <path d="M12.873,18.569c0,2.896,2.178,5.258,4.981,5.612V12.957C15.046,13.311,12.873,15.673,12.873,18.569z"/>
        <path d="M19.275,12.957v11.228c2.803-0.354,4.976-2.721,4.976-5.616S22.079,13.311,19.275,12.957z"/>
    </SvgIcon>
  );
}

export function PriceIcon(props) {
  return (
    <SvgIcon {...props}>
        <path
            d="M23.983,4.603c0,3.646-1.59,6.295-1.656,6.406c-0.135,0.22-0.368,0.341-0.608,0.341c-0.126,0-0.253-0.033-0.369-0.103c-0.336-0.204-0.444-0.642-0.24-0.978c0.015-0.024,1.449-2.435,1.449-5.666c0-1.842-1.198-3.179-2.849-3.179c-1.571,0-2.849,1.278-2.849,2.85c0,0.669,0.324,2.37,0.448,2.969c0.081,0.385-0.167,0.762-0.552,0.843c-0.385,0.079-0.762-0.167-0.842-0.552c-0.049-0.235-0.48-2.323-0.48-3.261C15.436,1.917,17.353,0,19.71,0C22.146,0,23.983,1.979,23.983,4.603z M19.831,5.724c0.283,2.367,0.694,6.979,0.222,7.448L9.704,23.522c-0.636,0.637-1.669,0.637-2.306-0.001l-6.92-6.919c-0.637-0.636-0.637-1.669,0-2.306l10.35-10.349c0.164-0.164,1.954-0.172,3.967-0.067c-0.011,0.133-0.04,0.258-0.04,0.394c0,0.516,0.114,1.317,0.234,2.02c-0.211,0.313-0.334,0.689-0.334,1.095c0,1.081,0.876,1.957,1.957,1.957s1.957-0.876,1.957-1.957c0-0.666-0.333-1.253-0.841-1.605c-0.104-0.619-0.188-1.217-0.188-1.51c0-0.063,0.013-0.122,0.019-0.184c0.248,0.026,0.492,0.053,0.723,0.082C19.65,4.34,19.664,4.337,19.831,5.724z M14.315,13.47c-0.161-0.447-0.428-0.982-1.008-1.599l0.786-0.786l-0.875-0.874l-0.848,0.849c-1.135-0.777-2.305-0.714-3.098,0.08c-0.865,0.865-0.661,1.964-0.026,3.24c0.436,0.884,0.516,1.393,0.177,1.732c-0.348,0.348-0.91,0.197-1.42-0.312c-0.579-0.579-0.919-1.295-1.088-1.876l-1.313,0.778c0.152,0.526,0.563,1.275,1.142,1.891L5.888,17.45l0.874,0.875l0.92-0.919c1.214,0.856,2.437,0.741,3.231-0.053c0.812-0.814,0.884-1.724,0.188-3.17c-0.483-1.036-0.599-1.545-0.313-1.83c0.251-0.251,0.696-0.322,1.277,0.258c0.643,0.643,0.857,1.268,0.973,1.599L14.315,13.47z"/>
    </SvgIcon>
  );
}
