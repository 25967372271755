import {gql} from '@apollo/client';
import type {
    GetTherapyAvailableResult as GetTherapyAvailableResultModel, StatDataInInputDto,
    TherapyCompareDto, TherapyCompareStatisticsDto,
    TherapyDataIn, TherapyRecommendIn, TherapyRecommendInHypertension
} from '../const';
import {NosologyAPIUrl, NosologyType} from "../const";
import {getPromiseQueryResult} from "../utils";
import config from "../config";

const RefItem = `
        id
        name
        shortName
`;

const TherapyAvailable = `
        thType
        name
        items {
            ${RefItem}
        }
        skipVars {
            ${RefItem}
        }
        subTherapyId
`;

const ItemsDto = `
        id
        name
        shortName
`;

const CompsDto = `
        id
        name
        riskTh
        riskPl
`;

const CombRiskDto = `
        id
        name
        selectType
        comps {
            ${CompsDto}
        }
`;

const PscoreDto = `
        data {
                efficacy {
                    name
                    value
                    isSelect
                    selectType
                }
                safety {
                    name
                    value
                    isSelect
                    selectType
                }
           }
`;

const TherapyCompareATRIAL_FIBRILLATION = `
        id
        name
        shortName
        score
        scoreDescr
        descr
        compDrugs
        pscore {
            ${PscoreDto}
       }
`;

const TherapyCompareHYPERTENSION = `
        id
        name
        shortName
        score
        scoreDescr
        descr
        compGroups {
            ${ItemsDto}
        }
        forestPlots {
            title
            url
       }
       combRisk {
           ${CombRiskDto}
           message {
               type
               payload
           }
       }
`;

const HpTherapyCompareDto = `
    id,
    name,
    combRisk {
        ${CombRiskDto}
    }
`;

const FlTherapyCompareDto = `
    id,
    name,
    shortName,
    pscore {
        ${PscoreDto}
    }
`;

const TherapyCompareHEART_FAILURE = `
        id
        name
        shortName
        score
        descr
`;

export const getTherapyAvailable = gql`
    query {
        getTherapyAvailable {
            ${TherapyAvailable}
        }
    }
`;

export const getTherapyCompareATRIAL_FIBRILLATION = gql`
    query ($patientId: Int!, $thData: [TherapyDataIn!]!, $thRecommend: [TherapyRecommendIn!]!) {
        getTherapyCompare(patientId: $patientId, thData: $thData, thRecommend: $thRecommend) {
            ${TherapyCompareATRIAL_FIBRILLATION}
        }
    }
`;

export const getTherapyCompareHYPERTENSION = gql`
    query ($patientId: Int!, $thData: [TherapyDataIn!]!, $thRecommend: TherapyRecommendIn!) {
        getTherapyCompare(patientId: $patientId, thData: $thData, thRecommend: $thRecommend) {
            ${TherapyCompareHYPERTENSION}
        }
    }
`;

export const getTherapyCompareHEART_FAILURE = gql`
    query ($patientId: ID!, $thData: [TherapyDataIn!]!) {
        getTherapyCompare(patientId: $patientId, thData: $thData) {
            ${TherapyCompareHEART_FAILURE}
        }
    }
`;

export const hpTherapyCompare = gql`
    query ($data: StatDataInInput){
        hpTherapyCompare(data: $data) {
            ${HpTherapyCompareDto}
        }
    }
`;

export const flTherapyCompare = gql`
    query ($data: StatDataInInput){
        flTherapyCompare(data: $data) {
            ${FlTherapyCompareDto}
        }
    }
`;

export const getHPFinalComb = gql`
    query ($patientId: Int!, $thRecommend: TherapyRecommendIn!){
        getHPFinalComb(patientId: $patientId, thRecommend: $thRecommend) {
            ${CombRiskDto}
            message {
                type
                payload
            }
        }
    }
`;


export const loadGetTherapyAvailable = (client, signal, nosology: NosologyType): Promise<GetTherapyAvailableResultModel[]> => {
    return client.query({
        query: getTherapyAvailable,
        context: {
            uri: NosologyAPIUrl[nosology] + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getTherapyAvailable', []);
        });
}

export const loadGetTherapyCompare = (client, signal, nosology: NosologyType, patientId, thData: TherapyDataIn[], thRecommend: TherapyRecommendIn[] | TherapyRecommendInHypertension[]): Promise<TherapyCompareDto[]> => {
    const query = {
        context: {
            uri: NosologyAPIUrl[nosology] + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        }
    };
    switch (nosology) {
        case NosologyType.ATRIAL_FIBRILLATION:
        default:
            query.query = getTherapyCompareATRIAL_FIBRILLATION;
            query.variables = {
                patientId: patientId,
                thData: thData,
                thRecommend: thRecommend,
            };
            break;
        case NosologyType.HYPERTENSION:
            query.query = getTherapyCompareHYPERTENSION;
            query.variables = {
                patientId: patientId,
                thData: thData,
                thRecommend: thRecommend,
            };
            break;
        case NosologyType.HEART_FAILURE:
            query.query = getTherapyCompareHEART_FAILURE;
            query.variables = {
                patientId: patientId,
                thData: thData,
            };
            break;
    }

    return client.query(query)
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getTherapyCompare', []);
        });
}

export const loadGetTherapyCompareStatistics = (client, signal, nosology: NosologyType, statDataInInput: StatDataInInputDto): Promise<TherapyCompareStatisticsDto[]> => {
    const query = {
        context: {
            uri: config.options.server.statistics_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            data: undefined, // statDataInInput,
        },
    };

    let queryName;
    switch (nosology) {
        case NosologyType.ATRIAL_FIBRILLATION:
        default:
            queryName = 'flTherapyCompare';
            query.query = flTherapyCompare;
            break;
        case NosologyType.HYPERTENSION:
            queryName = 'hpTherapyCompare';
            query.query = hpTherapyCompare;
            break;
    }


    return client.query(query)
        .then(({data, _}) => {
            return getPromiseQueryResult(data, queryName, []);
        });
}

