import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import RootContainer from '../comp/RootContainer';
import mainStyles from '../styles/mainStyles';
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListEmpty from "../comp/ListEmpty";
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withApollo } from '@apollo/client/react/hoc';
import {
    addPubInvite,
    deleteInvite,
    getPubInvites,
    getRegCodeGroups, getRegCodeUsers, getUserProfileByUsername, loadGetRegProfiles,
    resendInvite,
    updatePubInvite,
} from '../query/registration';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FormattedMessage, injectIntl } from 'react-intl';
import {getNoDataView, getQueryResult, getStringDateFromString} from "../utils";
import {Grid, IconButton, Tooltip} from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import red from "@material-ui/core/colors/red";
import ConfirmDialog from "../comp/patient/ConfirmDialog";
import InvitePublicAddDialog from "../comp/invite/InvitePublicAddDialog";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import RegRequestionsA from "./RegRequestionsA";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import config from "../config";

const styles = theme => ({
    ...mainStyles(theme),
    deleteButton: {
        color: red[700],
        borderColor: red[700],
        marginLeft: '8px',

        '&:hover': {
            backgroundColor: red[50],
        }
    },
    statisticsButton: {
        marginLeft: '8px',
    },
    actionsRow: {
        marginBottom: '8px'
    },
    actionRowItem: {
        marginRight: '8px',
    },
});

class InvitePublic extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            invites: [],
            isShowDialogInvite: false,
            inviteDialogInvite: undefined,

            regCodeUsers: [],
            showStatisctics: false,
            selectedInvite: null,

            openConfirmDialog: false,
            titleConfirmDialog: 'Заголовок диалога',
            textConfirmDialog: 'Текст диалога',
            buttonYes: 'Да',
            buttonNo: 'Нет',
            onConfirmDialog: () => {
                console.error('onConfirmDialog')
            },
        };
    }

    componentDidMount() {
        this.loadInvites();
        this.loadGetRegCodeGroups();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadInvites = () => {
        const { client } = this.props;
        const { signal } = this.abortController;

        client.query({
            query: getPubInvites,
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'getPubInvites'))
            .then(invites => {
                (invites || []).forEach((invite) => {
                    invite.deleteInvite = true;
                });

                loadGetRegProfiles(client, signal)
                    .then((regProfiles) => {
                        this.setState({
                            invites: invites,
                            regProfiles: regProfiles || [],
                        });
                    });
            });
    };

    loadGetRegCodeGroups = () => {
        const { client } = this.props;
        const { signal } = this.abortController;

        client.query({
            query: getRegCodeGroups,
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'getRegCodeGroups'))
            .then(regCodeGroups => {
                this.setState({
                    regCodeGroups: regCodeGroups,
                });
            });
    };

    onDialogClose = () => {
        this.setState({
            isShowDialogInvite: false,
            inviteDialogInvite: undefined,
        });
    };

    onInviteAdd = (invite) => {
        this.setState({
            isShowDialogInvite: true,
            inviteDialogInvite: invite,
        });
    };

    copyToClipboard = (link) => {
        const { intl } = this.props;
        const tempInput = document.createElement('input');
        tempInput.value = link;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.showNotify(intl.formatMessage({id: 'message.invite.coped_to_clipboard', defaultMessage: 'Coped to clipboard'}, 'success'));
    };

    updateInvite = (invite) => {
        const { client } = this.props;
        const { signal } = this.abortController;

        invite.resendInvite = false;

        client.query({
            query: resendInvite,
            variables: {
                regCodeId: invite.regCode.id,
            },
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'resendInvite'))
            .then(resendInviteResponse => {
                invite.resendInvite = resendInviteResponse;
            });

    };

    setConfirmOpen = (open: boolean, titleConfirmDialog: string, textConfirmDialog: string, onConfirmDialog, invite) => {
        const {intl} = this.props;
        let sTitleConfirmDialog = '';
        let sTextConfirmDialog = '';
        if (open) {
            sTitleConfirmDialog = intl.formatMessage({id: titleConfirmDialog, defaultMessage: 'Title'});
            sTextConfirmDialog = intl.formatMessage({id: textConfirmDialog}, {invite: invite.regCode.descr}, {defaultMessage: 'Text'});
        }
        this.setState({
            openConfirmDialog: open,
            titleConfirmDialog: sTitleConfirmDialog,
            textConfirmDialog: sTextConfirmDialog,
            buttonYes: intl.formatMessage({id: 'label.dialog.confirm.button.yes', defaultMessage: 'Yes'}),
            buttonNo: intl.formatMessage({id: 'label.dialog.confirm.button.no', defaultMessage: 'No'}),
            onConfirmDialog: onConfirmDialog,
        });
    }

    deleteInvite = (invite) => {
        const { client, intl } = this.props;
        const { signal } = this.abortController;

        invite.deleteInvite = false;

        client.mutate({
            mutation: deleteInvite,
            errorPolicy: 'all',
            variables: {
                id: invite.id,
            },
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        }).then(({ data, _ }) => {
            if (!!(data || {}).deleteInvite) {
                this.showNotify(intl.formatMessage({id: 'message.invite.public.deleted'}), 'success');
                this.loadInvites();
            } else {
                this.showNotify(intl.formatMessage({id: 'message.invite.public.deleting_error'}), 'error');
                invite.deleteInvite = true;
            }
        });
    };

    onDeleteInvite = (invite) => {
        this.setConfirmOpen(
            true,
            'label.dialog.confirm.invite_public.delete.title',
            'label.dialog.confirm.invite_public.delete.text',
            () => {this.deleteInvite(invite);},
            invite
        );
    }

    onStatisticsInvite = (invite) => {
        const { client } = this.props;
        const { signal } = this.abortController;

        client.query({
            query: getRegCodeUsers,
            variables: {
                regCodeId: invite.regCode.id,
            },
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'getRegCodeUsers'))
            .then(regCodeUsers => {

                this.setState({
                    regCodeUsers: regCodeUsers,
                    showStatisctics: true,
                    selectedInvite: invite,
                });
            });
    }

    onReturn = () => {
        this.setState({
            regCodeUsers: [],
            showStatisctics: false,
            selectedInvite: null,
        });
    }

    onInviteSubmit = (invite) => {
        const { client, intl } = this.props;
        const { signal } = this.abortController;

        client.mutate({
            mutation: invite.id ? updatePubInvite : addPubInvite,
            errorPolicy: 'all',
            variables: {
                invite: invite
            },
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        }).then(({ data, _ }) => {
            if (data && data[invite.id ? 'updatePubInvite' : 'addPubInvite']) {
                this.showNotify(intl.formatMessage({id: 'message.invite.public_added'}), 'success');
                this.loadInvites();
            }
        });

        this.setState({
            isShowDialogInvite: false,
            inviteDialogInvite: undefined,
        });
    };

    invitePublicIcon = () => {
        return <GroupAddIcon color="primary" />;
    };

    getDate = (date: string) => {
        return getStringDateFromString(date);
    };

    showNotify = (message, type) => {
        const { showNotify } = this.context;
        if (showNotify) {
            showNotify(message, type);
        }
    };

    getProfile = (request) => {
        const {client} = this.props;
        const {signal} = this.abortController;
        return client.query({
            query: getUserProfileByUsername,
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
            variables: {
                username: request.username
            }
        })
            .then(result => getQueryResult(result?.data, 'getUserProfileByUsername'));
    };

    render() {
        const { classes, intl } = this.props;
        const {
            invites,
            isShowDialogInvite,
            inviteDialogInvite,
            regProfiles,

            openConfirmDialog,
            titleConfirmDialog,
            textConfirmDialog,
            buttonYes,
            buttonNo,
            onConfirmDialog,

            regCodeGroups,

            regCodeUsers,
            showStatisctics,
            selectedInvite,
        } = this.state;

        return (
            <RootContainer>
                <Paper>
                    <Toolbar>
                        {!showStatisctics &&
                            <React.Fragment>
                                <Typography variant={"h6"} color={"inherit"}>
                                    <FormattedMessage id='label.public_invites' defaultMessage='Public invites'/>
                                </Typography>
                                <div className={classes.grow}/>
                                <Button color={"primary"} onClick={this.loadInvites}>
                                    <RefreshIcon className={classes.iconButton}/>
                                    <FormattedMessage id='label.refresh' defaultMessage='Refresh'/>
                                </Button>
                                <Button color={"primary"} onClick={() => this.onInviteAdd()}
                                        disabled={!(regCodeGroups || []).length}>
                                    <AddIcon className={classes.iconButton}/>
                                    <FormattedMessage id='label.add' defaultMessage='Add'/>
                                </Button>
                            </React.Fragment>
                        }
                        {showStatisctics &&
                            <React.Fragment>
                                <IconButton
                                    style={{padding: '12px', marginRight: '4px'}}
                                    size="small"
                                    color="primary"
                                    aria-label="close patient"
                                    // component="span"
                                    onClick={() => this.onReturn()}
                                >
                                    <KeyboardBackspaceRoundedIcon/>
                                </IconButton>

                                <Typography variant={"h6"} color={"inherit"}>
                                    {selectedInvite.regCode.descr}
                                </Typography>
                            </React.Fragment>
                        }
                    </Toolbar>
                    {!showStatisctics ?
                        ((invites && invites.length > 0)
                        ?
                        <List>
                            {invites.map(invite => (
                                <ListItem key={invite.id}>
                                    <ListItemIcon>
                                        {this.invitePublicIcon()}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant="h6">{invite.regCode.descr}</Typography>}
                                        secondary={
                                            <React.Fragment>
                                                <Grid component={'span'} container direction={"row"} alignItems={"center"}>
                                                    <Grid component={'span'} item>
                                                        <Typography component="span" variant="body1">
                                                            <FormattedMessage id='message.invite.public.date' defaultMessage='Date' />&nbsp;{this.getDate(invite.regCode.createAt)}&nbsp;&ndash;&nbsp;{this.getDate(invite.regCode.endAt)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid component={'span'} container direction={"row"} alignItems={"center"}>
                                                    <Grid component={'span'} item>
                                                        <Typography component="span" variant="body1">
                                                            <FormattedMessage id='message.invite.public.availRegs' defaultMessage='Available regs' />&nbsp;{invite.availRegs}/{invite.usedRegs}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid component={'span'} container direction={"row"} alignItems={"center"} className={classes.actionsRow}>
                                                    <Grid component={'span'} item className={classes.actionRowItem}>
                                                        <Typography component="span" variant="body1" color="textSecondary">{invite.regCode.regLink || ''}</Typography>
                                                    </Grid>
                                                    <Grid component={'span'} item className={classes.actionRowItem}>
                                                        <Tooltip title={intl.formatMessage({id: 'message.invite.copy_to_clipboard', defaultMessage: 'Copy to clipboard'})}>
                                                            <IconButton aria-label="copy" color={"primary"} onClick={() => this.copyToClipboard(invite.regCode.regLink)} disableRipple={true} style={{padding: 0}}>
                                                                <FileCopyIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                <Grid component={'span'} container direction={"row"} alignItems={"center"} className={classes.actionsRow}>
                                                    <Grid component={'span'} item>
                                                        <Button size={"small"} color={"primary"} onClick={() => this.onInviteAdd(invite)} disabled={!invite.isEditable} variant={"outlined"}>
                                                            <FormattedMessage id='label.edit' defaultMessage='Edit' />
                                                        </Button>
                                                    </Grid>
                                                    <Grid component={'span'} item>
                                                        <Button size={"small"} className={classes.deleteButton} onClick={() => this.onDeleteInvite(invite)} disabled={!invite.isRemovable} variant={"outlined"}>
                                                            <FormattedMessage id='label.delete' defaultMessage='Delete' />
                                                        </Button>
                                                    </Grid>
                                                    <Grid component={'span'} item>
                                                        <Button size={"small"} color={"primary"} className={classes.statisticsButton} onClick={() => this.onStatisticsInvite(invite)} disabled={false} variant={"outlined"}>
                                                            <FormattedMessage id='label.invite.statistics' defaultMessage='Statistics' />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                        :
                        <ListEmpty text={intl.formatMessage({id: 'label.invites.empty', defaultMessage: 'Not invites'})} />)
                    :
                        <RegRequestionsA
                            requests={regCodeUsers}
                            noDataView={getNoDataView()}
                            getProfile={this.getProfile}
                            statistics={true}
                        />
                    }
                </Paper>
                {isShowDialogInvite &&
                <InvitePublicAddDialog
                    onDialogClose={this.onDialogClose}
                    onSubmit={this.onInviteSubmit}
                    isShow={isShowDialogInvite}
                    invite={inviteDialogInvite}
                    regCodeGroups={regCodeGroups || []}
                    regProfiles={regProfiles}
                />
                }
                <ConfirmDialog
                    title={titleConfirmDialog}
                    text={textConfirmDialog}
                    buttonYes={buttonYes}
                    buttonNo={buttonNo}
                    open={openConfirmDialog}
                    setOpen={this.setConfirmOpen}
                    onConfirm={onConfirmDialog}
                />
            </RootContainer>
        );
    }
}

InvitePublic.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

InvitePublic.contextTypes = {
    showNotify: PropTypes.func,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(InvitePublic);
