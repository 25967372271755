import TextField from "@material-ui/core/TextField";
import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import {Grid, MenuItem} from "@material-ui/core";
import AutocompleteDownshift from "../AutocompleteDownshift";
import config from '../../config';
import {injectIntl} from "react-intl";
import compose from 'recompose/compose';

const styles = theme => ({
  ...mainStyles(theme),
});

class ProfileEdit extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      specialities: [],
      skills: [],
    };
  }

  componentDidMount() {
    const { fields } = this.props;

    this.loadCountries();
    if (Boolean(fields.country)) {
      this.loadCities(fields.country);
    }
    this.loadSpecialities();
    this.loadSkills();
  }

  loadCountries = () => {
    // const $this = this;

    fetch(`${config.options.server.api_url}${config.options.server.get_countries}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState({
            countries: response.data,
          });
        } else {
          this.showNotify(response.data, 'success');
        }
      }).catch((err) => {
        this.showNotify(err, 'success');
      });
  };

  loadCities = (country) => {
    // const $this = this;

    fetch(`${config.options.server.api_url}${config.options.server.get_cities}?country=${country}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState({
            cities: response.data.map((it) => ({label: it.name})),
          });
        } else {
          this.showNotify(response.data, 'success');
        }
      }).catch((err) => {
        this.showNotify(err, 'success');
      });
  };

  loadSpecialities = () => {
    // const $this = this;

    fetch(`${config.options.server.api_url}${config.options.server.get_specialities}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState({
            specialities: response.data.map((it) => ({label: it.name})),
          });
        } else {
          this.showNotify(response.data, 'success');
        }
      }).catch((err) => {
        this.showNotify(err, 'success');
      });
  };

  loadSkills = () => {
    // const $this = this;

    fetch(`${config.options.server.api_url}${config.options.server.get_skills}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => response.json())
      .then((response) => {
        if (response.status === 'ok') {
          this.setState({
            skills: response.data.map((it) => ({label: it.name})),
          });
        } else {
          this.showNotify(response.data, 'success');
        }
      }).catch((err) => {
        this.showNotify(err, 'success');
      });
  };

  onChange = (id, value) => {
    if (this.props.onFieldChange) {
      this.props.onFieldChange(id, value);
    }

    if (id === 'country') {
      this.loadCities(value);
    }
  };

  showNotify = (message, type) => {
    const { showNotify } = this.context;
    if (showNotify) {
      showNotify(message, type);
    }
  };

  render() {
    const { fields, intl } = this.props;
    const { countries, cities, specialities, skills } = this.state;
    const xs = 12;
    const sm = 12;
    const md = 6;
    let countryItems = countries;

    if (!Boolean(countryItems)) {
      countryItems = [{id: 0, name: ''}];
    }

    return (
      <Grid container spacing={4}>
        <Grid item xs={xs} sm={sm} md={md}>
          <AutocompleteDownshift
            id='spec'
            value={fields.spec}
            suggestions={specialities}
            onChange={this.onChange}
            inputProps={{
              fullWidth: true,
              margin: "normal",
              label: intl.formatMessage({id: 'label.speciality', defaultMessage: 'Speciality'}),
            }}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md}>
          <TextField
            id="country"
            name="country"
            value={fields.country || ''}
            margin="normal"
            fullWidth
            select
            onChange={e => this.onChange('country', e.target.value)}
            label={intl.formatMessage({id: 'label.country', defaultMessage: 'Country'})}>
            {countryItems.map(c => (
              <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={xs} sm={sm} md={md}>
          <AutocompleteDownshift
            id='city'
            value={fields.city}
            suggestions={cities}
            onChange={this.onChange}
            inputProps={{
              fullWidth: true,
              margin: "normal",
              label: intl.formatMessage({id: 'label.city',defaultMessage: 'City'}),
            }}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md}>
          <TextField
            id="job"
            value={fields.job}
            onChange={e => this.onChange('job', e.target.value)}
            margin="normal"
            fullWidth
            label={intl.formatMessage({id: 'label.job', defaultMessage: 'Job'})}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md}>
          <TextField
            id="degree"
            value={fields.degree}
            onChange={e => this.onChange('degree', e.target.value)}
            margin="normal"
            fullWidth
            label={intl.formatMessage({id: 'label.degree', defaultMessage: 'Degree'})}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md}>
          <AutocompleteDownshift
            id='skills'
            value={fields.skills}
            suggestions={skills}
            onChange={this.onChange}
            inputProps={{
              fullWidth: true,
              margin: "normal",
              label: intl.formatMessage({id: 'label.skills', defaultMessage: 'Skills'}),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="experience"
            value={fields.experience}
            onChange={e => this.onChange('experience', e.target.value)}
            margin="normal"
            fullWidth
            multiline
            maxRows="10"
            minRows="5"
            label={intl.formatMessage({id: 'label.experience', defaultMessage: 'Experience'})}
          />
        </Grid>
      </Grid>
    );
  }
}

ProfileEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  fields: PropTypes.shape({
    spec: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    job: PropTypes.string,
    degree: PropTypes.string,
    skills: PropTypes.string,
    experience: PropTypes.string,
  }),
  onFieldChange: PropTypes.func,
};

ProfileEdit.defaultProps = {
  fields: {
    spec: '',
    country: '',
    city: '',
    job: '',
    degree: '',
    skills: '',
    experience: '',
  },
};

ProfileEdit.contextTypes = {
  // intl: PropTypes.object.isRequired, // Cause Warning!
  showNotify: PropTypes.func,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(ProfileEdit);
