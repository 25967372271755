import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    Typography,
} from "@material-ui/core";
import PanelDivider from "../PanelDivider";
import {PaperColorSelect, SelectType} from "../PaperColor";
import SelTextView from "../SelTextView";
import {Treatment} from "../../models/breast-cancer";
import {FormattedMessage, injectIntl} from "react-intl";
import {getServiceMessages} from "../../utils";
import ServiceCheckbox from "../button/ServiceCheckbox";
import type {ServiceMessage} from "../../const";
import ServiceRadioGroup from "../button/ServiceRadioGroup";
import {MessageType, RecommendType} from "../../const";
import {TypographySubtitle} from "../../pages/CommonElements";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from "@apollo/client/react/hoc";
import {InfoHelp} from "../common/InfoHelp";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import ReactMarkdown from "react-markdown";

const styles = theme => ({
    ...mainStyles(theme),
    spaceSubtreatments: {
        marginTop: '12px',
    }
});


class BreastCancerSelectSubgroups extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    isCheckedTreatment = (treatment: Treatment): boolean => {
        const {
            selectedTreatments,
        } = this.props;
        return Boolean(selectedTreatments[treatment.id]);
    }

    isCheckedSubtreatment = (treatment: Treatment, subtreatmentId): boolean => {
        const {
            selectedTreatments,
        } = this.props;
        return selectedTreatments[treatment.id] === subtreatmentId;
    }

    selectTreatment = (treatmentId, selected: boolean) => {
        const {
            selectedTreatments,
            setSelectedSubtreatments,
        } = this.props;

        if (selected && !selectedTreatments.hasOwnProperty(treatmentId)) {
            selectedTreatments[treatmentId] = -1;
        } else {
            delete selectedTreatments[treatmentId];
        }

        if (setSelectedSubtreatments) {
            setSelectedSubtreatments(selectedTreatments);
        }

    };

    getSelectedSubtreatment = (treatment: Treatment) => {
        const {
            selectedTreatments,
        } = this.props;

        return selectedTreatments[treatment.id] || -1;
    }

    onSelectSubtreatment = (treatment: Treatment) => (e) => {
        const {
            isPrescriptionReadonly,
            selectedTreatments,
            setSelectedSubtreatments,
        } = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        const subtreatmentId = e.target.value;

        selectedTreatments[treatment.id] = subtreatmentId;
        if (setSelectedSubtreatments) {
            setSelectedSubtreatments(selectedTreatments);
        }
    }

    getMessageSubtreatment = (treatment: Treatment) => (e): ServiceMessage => {
        const subtreatmentId = e.target.value;

        const subtreatment = treatment.subtreatments.find((subtreatment) => subtreatment.id === subtreatmentId);
        return getServiceMessages(subtreatment.messages, MessageType.WARNING);
    }

    getTreatmentView = (treatment: Treatment) => {
        const {
            classes,
            isPrescriptionReadonly
        } = this.props;

        const serviceWarningCheckBox: ServiceMessage = getServiceMessages(treatment.messages, MessageType.WARNING);
        const serviceSubtitleCheckBox: ServiceMessage = getServiceMessages(treatment.messages, MessageType.SUBTITLE);

        return (
            <Grid item key={treatment.id}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
            >
                <PaperColorSelect
                    className={classes.contentBox}
                    seltype={this.isCheckedTreatment(treatment) ? SelectType.POSITIVE : SelectType.NONE}
                >
                    <Box>
                        <FormControlLabel control={
                            // <Checkbox
                            //     color={"primary"}
                            //     checked={this.isCheckedTreatment(treatment)}
                            //     value={treatment.id}
                            //     onChange={e => {
                            //         if (isPrescriptionReadonly) {
                            //             e.preventDefault();
                            //             return;
                            //         }
                            //         this.selectTreatment(treatment.id, e.target.checked);
                            //     }}
                            // />
                            <ServiceCheckbox
                                color={"primary"}
                                disabled={!treatment.enable}
                                checked={this.isCheckedTreatment(treatment)}
                                value={treatment.id}
                                onChange={e => {
                                    if (isPrescriptionReadonly) {
                                        e.preventDefault();
                                        return;
                                    }
                                    this.selectTreatment(treatment.id, e.target.checked);
                                }}
                                showService={serviceWarningCheckBox.showService}
                                title={'Заголовок'}
                                text={serviceWarningCheckBox.messages}
                                type={serviceWarningCheckBox.type}
                            />
                        } label={
                            <SelTextView>{treatment.name}</SelTextView>
                        }/>
                        {serviceSubtitleCheckBox.showService &&
                            <TypographySubtitle>
                                {serviceSubtitleCheckBox.messages.map((message, index) =>
                                    <ReactMarkdown key={index} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={message.payload}/>
                                )}
                            </TypographySubtitle>
                        }
                    </Box>

                    {treatment?.subtreatments?.length > 0 &&
                    <FormControl component='fieldset'>
                        <ServiceRadioGroup
                            value={this.getSelectedSubtreatment(treatment)}
                            onChange={this.onSelectSubtreatment(treatment)}
                            getServiceMessage={this.getMessageSubtreatment(treatment)}
                            showService={false}
                            title={'ServiceRadioGroup: Заголовок'}
                            text={'ServiceRadioGroup: Текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст'}
                        >
                            {treatment.subtreatments
                                .sort((item1, item2) => item1.ord - item2.ord)
                                .map((subtreatment, i) => {
                                        const serviceInfoHelp: ServiceMessage = getServiceMessages(subtreatment.messages, MessageType.INFO);
                                        return (
                                            <Grid container direction={"row"} key={subtreatment.id}
                                                  className={classes.spaceSubtreatments}>
                                                <Grid item>
                                                    <FormControlLabel
                                                        disabled={!treatment.enable}
                                                        value={subtreatment.id}
                                                        label={
                                                            <Typography color={'textPrimary'}>
                                                                {subtreatment.name}
                                                            </Typography>
                                                        }
                                                        control={
                                                            <Radio color={'primary'}/>
                                                        }
                                                    />
                                                </Grid>
                                                {serviceInfoHelp.showService &&
                                                <Grid item>
                                                    <InfoHelp texts={serviceInfoHelp.messages}/>
                                                </Grid>
                                                }
                                            </Grid>
                                        );
                                    }
                                )}
                        </ServiceRadioGroup>
                    </FormControl>
                    }
                </PaperColorSelect>
            </Grid>
        );
    }

    render() {
        const {
            classes,
            getRecomentTextsView,
            subgroups,
            isPrescriptionReadonly,
        } = this.props;

        if ((subgroups || []).length !== 0) {
            const treatments: Treatment[] = subgroups[0].treatments
                    // .filter((treatment) => treatment.medication)
                    .sort((item1, item2) => item1.ord - item2.ord)
                ;
            // const nonMedicationTreatments: Treatment[] = subgroups[0].treatments
            //         .filter((treatment) => !treatment.medication)
            //         .sort((item1, item2) => item1.ord - item2.ord)
            //     ;
            return (
                <Paper className={isPrescriptionReadonly ? classes.disabled : ''}>
                    {getRecomentTextsView && getRecomentTextsView(RecommendType.GUIDELINES)}
                    <PanelDivider/>

                    <Grid container className={classes.contentBox}>
                        <Grid item xs={12}>
                            {/*<Typography variant={"h6"}>*/}
                            {/*    <FormattedMessage id={'label.bc.medication_treatments'}*/}
                            {/*                      defaultMessage={'Medication treatments'}/>*/}
                            {/*</Typography>*/}
                            <Grid container justifyContent='flex-start' spacing={2}>

                                {treatments
                                    .map(treatment => {
                                        return this.getTreatmentView(treatment);
                                    })
                                }
                            </Grid>
                            {/*<PanelDivider/>*/}
                            {/*<PanelDivider/>*/}
                            {/*<Typography variant={"h6"}>*/}
                            {/*    <FormattedMessage id={'label.bc.non_medication_treatments'}*/}
                            {/*                      defaultMessage={'Non medication treatments'}/>*/}
                            {/*</Typography>*/}
                            {/*<Grid container justifyContent='flex-start' spacing={2}>*/}

                            {/*    {nonMedicationTreatments*/}
                            {/*        .map(nonMedicationTreatment => {*/}
                            {/*            return this.getTreatmentView(nonMedicationTreatment);*/}
                            {/*        })*/}
                            {/*    }*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>
                    <PanelDivider/>
                </Paper>
            );
        } else {
            return (
                <Paper className={isPrescriptionReadonly ? classes.disabled : ''}>
                    <Box className={classes.contentBox}>
                        <Typography>
                            <FormattedMessage id={'label.comparisons.no_data'}
                                              defaultMessage={'No data to display'}/>
                        </Typography>
                    </Box>
                </Paper>
            );
        }
    }
}

BreastCancerSelectSubgroups.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    subgroups: PropTypes.array.isRequired,
    selectedTreatments: PropTypes.object.isRequired,
    setSelectedSubtreatments: PropTypes.func,
    getRecomentTextsView: PropTypes.func,
};

BreastCancerSelectSubgroups.defaultProps = {};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(BreastCancerSelectSubgroups);
