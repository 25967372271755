import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core/styles";
import {Button, Container, makeStyles, useTheme} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import * as PropTypes from "prop-types";

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),

        display: 'flex',
        alignItems: 'center',
    },
}));
function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    function handleFirstPageButtonClick(event) {
        if (!!onPageChange) {
            onPageChange(event, 0);
        }
    }

    function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }


    function getPageButton(selectedPage: number, page: number) {
        return <Button
            key={page}
            // size="small"
            color={page === selectedPage ? "primary" : "default"}
            disabled={page === selectedPage}
            onClick={(event) => onPageChange(event, page)}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                minWidth: '24px',
                color: page === selectedPage ? theme.palette.primary.main + '' : 'unset',
            }}
        >
            {page + 1}
        </Button>
    }

    function getPageEllipsis(page: number) {
        return <span
            key={page}
            style={{
                paddingLeft: 0,
                paddingRight: '4px',
            }}
        >
            ...
        </span>
    }

    function getPages() {
        const pages: [] = [];
        const pagesCount = Math.ceil(count / rowsPerPage);
        const selectedPage = page;
        const pageBreakIndex = 4;
        const manyPages = pagesCount > 7;
        // const needEllipsisStart = selectedPage >= pageBreakIndex;
        // const needEllipsisEnd = selectedPage < pagesCount - pageBreakIndex;
        // console.error('+++ pagesCount:', pagesCount);
        // console.error('+++ selectedPage:', selectedPage);
        // console.error('+++ manyPages:', manyPages);
        // console.error('+++ needEllipsisStart:', needEllipsisStart);
        // console.error('+++ needEllipsisEnd:', needEllipsisEnd);

        if (pagesCount > 0) {
            if (manyPages) {
                if (selectedPage < pageBreakIndex) {
                    for (let i = 0; i <= pageBreakIndex; i++) {
                        pages.push(getPageButton(selectedPage, i));
                    }

                    pages.push(getPageEllipsis(pagesCount - 2));

                    pages.push(getPageButton(selectedPage, pagesCount - 1));
                }
                if (selectedPage >= pageBreakIndex) {
                    pages.push(getPageButton(selectedPage, 0));

                    pages.push(getPageEllipsis(1));

                    if (selectedPage < pagesCount - pageBreakIndex) {
                        for (let i = selectedPage - 2; i <= selectedPage + 2; i++) {
                            pages.push(getPageButton(selectedPage, i));
                        }
                    } else {
                        for (let i = pagesCount - pageBreakIndex - 1; i < pagesCount - 1; i++) {
                            pages.push(getPageButton(selectedPage, i));
                        }
                    }

                    if (selectedPage < pagesCount - pageBreakIndex) {
                        pages.push(getPageEllipsis(pagesCount - 2));
                    }

                    pages.push(getPageButton(selectedPage, pagesCount - 1));
                }
            } else {
                for (let i = 0; i < pagesCount; i++) {
                    pages.push(getPageButton(selectedPage, i));
                }
            }
        }

        return <Container style={{padding: '4px 0 0 0'}}>
            {pages}
        </Container>
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            {getPages()}
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const defaultFooterStyles = {};

class CustomFooterDatatable extends React.Component {

    handleRowChange = (event) => {
        this.props.changeRowsPerPage(event.target.value);
    };

    handlePageChange = (_, page) => {
        this.props.changePage(page);
    };

    render() {
        const { count, textLabels, rowsPerPage, page } = this.props;

        const footerStyle = {
            display: "flex",
            justifyContent: "flex-end",
            padding: "0px 24px 0px 24px",
            borderBottom: 'none',
        };

        return (
            <TableFooter>
                <TableRow>
                    <TableCell style={footerStyle} colSpan={1000}>
                        <MuiTablePagination
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            // page={!!count ? page : 0}
                            page={page || 0}
                            labelRowsPerPage={textLabels.rowsPerPage}
                            labelDisplayedRows={({ from, to, count }) =>
                                `${from}-${to} ${textLabels.displayRows} ${count}`
                            }
                            backIconButtonProps={{
                                "aria-label": textLabels.previous
                            }}
                            nextIconButtonProps={{
                                "aria-label": textLabels.next
                            }}
                            rowsPerPageOptions={[10, 20, 100]}
                            onPageChange={this.handlePageChange}
                            onRowsPerPageChange={this.handleRowChange}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableCell>
                </TableRow>
            </TableFooter>
        );
    }
}

export default withStyles(defaultFooterStyles, { name: "CustomFooterDatatable" })(
    CustomFooterDatatable
);
