import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import {
    Paper,
    Box,
    ListItem,
    ListItemIcon,
    ListItemText,
    List, ListItemSecondaryAction,
} from "@material-ui/core";
import {getUserProfile, updateUserProfile} from "../query/user";
import {withApollo} from '@apollo/client/react/hoc';
import {injectIntl} from 'react-intl';
import PatientsSharedIcon from '@material-ui/icons/FolderShared';
import Patients from "./Patients";
import ProfileInformation from "./ProfileInformation";
import {PatientType, PatientTypeToProfileModeEnum, ProfileModeEnum, ProfileProfileModeEnum, ProfileModeEnumToPatientType} from "../const";
import {
    clearContextPatient, closePublicPatientsGroups,
    getQueryResult,
    getURLSearchParams,
    isShowGroups,
    patientsGroupsOpen, patientsRouteCompare, setContextPanelView
} from "../utils";
import IconButton from "@material-ui/core/IconButton";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import type {ActivePatient} from "../const";
import Collapse from "@material-ui/core/Collapse";
import RecursiveTreeView from "../comp/treeview/RecursiveTreeView";
import ConfirmDialog from "../comp/patient/ConfirmDialog";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ProfileTariffs from "../comp/ProfileTariffs";
import {withCookies} from "react-cookie";
import config from "../config";

const styles = theme => ({
    ...mainStyles(theme),
});

class Profile extends React.Component {

    abortController = new AbortController();
    // activePatient = {};
    nextContextIsLoading;
    resultShouldComponentUpdate;

    constructor(props) {
        super(props);
        this.state = {
            profileFields: {},
            profileMode: undefined,
            profileNode: undefined,
/* +++2021.01.08+++
            patientsGroups: {
                [PatientType.PRIVATE]: {
                    open: false,
                    data: [],
                },
                [PatientType.PUBLIC]: {
                    open: false,
                    data: [],
                },
            },
*/

            // TODO: for ConfirmDialog
            openConfirmDialog: false,
            titleConfirmDialog: 'Заголовок диалога',
            textConfirmDialog: 'Текст диалога',
            buttonYes: undefined,
            buttonNo: 'Нет',
            onConfirmDialog: () => {
                console.error('onConfirmDialog')
            },
        };
    }

    getProfileMode = (patientType: PatientType): ProfileModeEnum => {
        switch (patientType) {
            case PatientType.PRIVATE:
                return ProfileModeEnum.PATIENTS_PRIVATE;
            case PatientType.PUBLIC:
                return ProfileModeEnum.PATIENTS_PUBLIC;
            case PatientType.EDUCATION:
                return ProfileModeEnum.PATIENTS_EDUCATION;

            default:
                return ProfileModeEnum.PATIENTS_PRIVATE;
        }
    }

    parseRoute = (route: string) => {
        const {history} = this.props;
        if (route) {
            history.push(route);
        }
        // const {pathname} = this.props.location;
        // const tested = route ? route : pathname;
        // const params = new URLSearchParams(route ? (tested === '/profile' ? route : route.split('?')[1]) : this.props.location.search);
        const params = new URLSearchParams(route ? route.split('?')[1] : this.props.location.search);
        const activePatient: ActivePatient = getURLSearchParams(params);

        this.setState(prevState => ({
            // profileMode: tested === '/profile' ? ProfileModeEnum.PROFILE : this.getProfileMode(activePatient.patientType),
            profileMode: activePatient.mode === ProfileModeEnum.PROFILE ? ProfileModeEnum.PROFILE : this.getProfileMode(activePatient.patientType),
            profileNode: activePatient.mode === ProfileModeEnum.PROFILE ? activePatient.node : undefined,
        }), () => {
            this.setContextPanelView(this.getContextPanel(), () => {
                if (activePatient.mode === ProfileModeEnum.PROFILE || activePatient.patientType === PatientType.PUBLIC || activePatient.patientType === PatientType.EDUCATION) {
                    this.patientsGroupsOpen(
                        route,
                        false
                    );
                }

                this.forceUpdate();
            });
        });
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        const {isLoading} = this.context;
        // console.error('+++ shouldComponentUpdate() +++ nextContext.isLoading:', nextContext.isLoading);
        // console.error('+++ shouldComponentUpdate() +++ isLoading:', isLoading);
        this.nextContextIsLoading = nextContext.isLoading;

        const resultShouldComponentUpdate = (
            nextContext.isLoading !== isLoading
            ||
            nextState.profileMode !== (this.state || {}).profileMode
            ||
            (
                this.props.location.pathname !== nextProps.history.location.pathname
                &&
                (
                    (this.props.location.pathname === '/profile' && nextProps.history.location.pathname === '/patients')
                    ||
                    (this.props.location.pathname === '/patients' && nextProps.history.location.pathname === '/profile')
                )
            )
            ||
            (
                this.props.location.pathname === '/patients' && nextProps.history.location.pathname === '/patients'
                &&
                this.props.location.search !== nextProps.history.location.search
            )
            ||
            (
                nextState.profileMode === (this.state || {}).profileMode
                &&
                (
                    nextState.profileNode !== (this.state || {}).profileNode
                    ||
                    // this.props.location.pathname === '/profile' && nextProps.history.location.pathname === '/profile'
                    // &&
                    this.props.location.search !== nextProps.history.location.search
                )
            )
        );

        this.resultShouldComponentUpdate = resultShouldComponentUpdate;
        // console.error('+++ shouldComponentUpdate() +++ resultShouldComponentUpdate:', resultShouldComponentUpdate);
        return resultShouldComponentUpdate;
    }

    componentDidMount() {
        const {location} = this.props;
        this.parseRoute(location.pathname + location.search);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        // TODO: +++2021.12.10+++
        this.setContextPanelView(this.getContextPanel());

        // console.error('+++ componentDidUpdate() +++ this.nextContextIsLoading:', this.nextContextIsLoading);
        if (
            (prevProps.location.pathname + prevProps.location.search) !== (prevProps.history.location.pathname + prevProps.history.location.search)
            &&
            (prevProps.history.location.pathname === '/profile' || prevProps.history.location.pathname === '/patients')
            // &&
            // this.resultShouldComponentUpdate
        ) {
            this.componentDidMount();
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
        // TODO: +++2021.12.10+++
        //  this.setContextPanelView(null, () => this.forceUpdate());
        this.setContextPanelView(null);
    }

    loadUserProfile = () => {
        const {client} = this.props;
        const {signal} = this.abortController;

        client.query({
            query: getUserProfile,
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            // .then(result => (result.data || {}).getUserProfile)
            // .then(result => JSON.parse(JSON.stringify(result)))
            .then(result => getQueryResult(result?.data, 'getUserProfile'))
            .then(profile => {
                if (profile?.profile) {
                    let fields = {};

                    Object.keys(profile.profile)
                        .filter(key => key !== 'id' && key !== '__typename')
                        .filter(key => profile.profile[key])
                        .forEach(key => {
                            switch (key) {
                                case 'spec':
                                case 'city':
                                    fields[key] = profile.profile[key].name;
                                    break;
                                case 'skills':
                                    let skills = profile.profile[key].map(it => it.name).join(', ');
                                    if (skills.length > 0) {
                                        fields[key] = skills;
                                    }
                                    break;
                                default:
                                    fields[key] = profile.profile[key];
                            }
                        });

                    this.setState({
                        userProfile: profile,
                        profileFields: fields,
                    });

                    this.setContextPanelView(this.getContextPanel(), () => this.forceUpdate());
                } else {
                    this.setState({
                        userProfile: profile,
                    });
                }
            });
    };

    isPatientsGroupsOpen = (routePath: string): boolean => {
        const activePatient: ActivePatient = getURLSearchParams(routePath);
        // const {patientsGroups} = this.state;
        const {patientsGroups} = this.context;
        // console.error('+++ isPatientsGroupsOpen() +++ activePatient:', activePatient);
        // console.error('+++ isPatientsGroupsOpen() +++ routePath:', routePath);
        // console.error('+++ isPatientsGroupsOpen() +++ patientsGroups:', patientsGroups);
        // console.error('+++ isPatientsGroupsOpen() +++ patientsGroups[activePatient.patientType || activePatient.mode].open:', patientsGroups[activePatient.patientType || activePatient.mode].open);
        return patientsGroups[activePatient.patientType || activePatient.mode].open;
    }

    patientsGroupsOpen = (routePath: string, same: boolean) => {
        const {signal} = this.abortController;
        const {client} = this.props;
        const {setContextContent} = this.context;

        patientsGroupsOpen(
            client,
            signal,
            routePath,
            this,
            same,
            this.props.history,
            () => setContextPanelView(setContextContent, this.getContextPanel())
        );
    }

    openConfirmDialog = (open: boolean) => {
        this.setState({
            openConfirmDialog: open,
        }, () => {
            this.setContextPanelView(this.getContextPanel(), () => this.forceUpdate());
        });
    }

    blockPrivatePatients = () => {
        const {intl} = this.props;
        const sTitleConfirmDialog = intl.formatMessage({id: 'label.dialog.prevent.private.title', defaultMessage: 'Title'});
        // const sTextConfirmDialog = intl.formatMessage({id: 'label.dialog.prevent.private.text', defaultMessage: 'Text',}, {link: '<a href="www.aport.ru">aport.ru</a>'});
        const sTextConfirmDialog = intl.formatMessage({id: 'label.dialog.prevent.private.text'}, {telegram: `<a href="${config.options.telegram.ru}">Telegram</a>`, mailto: `<a href="mailto:partner@medicbk.com">partner@medicbk.com</a>`}, {defaultMessage: 'Text'});
        // sTextConfirmDialog = intl.formatMessage({id: textConfirmDialog}, {drugName: drug.name + ' ' + drug.dosage}, {defaultMessage: 'Text'});
        this.setState({
            openConfirmDialog: true,
            titleConfirmDialog: sTitleConfirmDialog,
            textConfirmDialog: sTextConfirmDialog,
            buttonYes: undefined,
            buttonNo: intl.formatMessage({id: 'label.close', defaultMessage: 'Сlose'}),
            onConfirmDialog: this.openConfirmDialog(false),
        }, () => {
            this.setContextPanelView(this.getContextPanel(), () => this.forceUpdate());
        });
    }

    getGroupsView = () => {
        const {
            classes,
            history,
            intl,
            location,
        } = this.props;
        const {
            setContextPatient,
            patientsGroups,
            setPatientsGroups,
            recursiveTreeViewSelected,
            isLoading,
        } = this.context;
        const {
            profileMode,
        } = this.state;

        const pType = ProfileModeEnumToPatientType[profileMode];

        if (!Boolean(pType)) {
            return [];
        }

        return [pType].map((patientType) => {
           return (
               <React.Fragment key={patientType}>
                   <ListItem
                       button
                       disabled={isLoading}
                       component="a"
                       selected={
                           profileMode === PatientTypeToProfileModeEnum[patientType]
                           // &&
                           // !activePatientCurrent.group
                       }
                       onClick={event => {
                           clearContextPatient(setContextPatient, () => {
                               closePublicPatientsGroups(patientType, patientsGroups, setPatientsGroups, () => {
                                   this.patientsGroupsOpen(`/patients?patientType=${patientType}${recursiveTreeViewSelected[patientType] ? `&group=${recursiveTreeViewSelected[patientType]}` : ``}`, patientsRouteCompare(`/patients?patientType=${patientType}`, location.pathname, location.search));
                               });
                           });
                       }}
                   >
                       <ListItemIcon>
                           <PatientsSharedIcon color="action"/>
                       </ListItemIcon>
                       <ListItemText
                           className={classes.linkHead + ' ' + classes.contentBoxProfileText}
                           primary={
                               patientType === PatientType.PUBLIC
                               ?
                               intl.formatMessage({
                               id: 'page.patients.public',
                               defaultMessage: 'Public patients'})
                               :
                               intl.formatMessage({
                               id: 'page.patients.education',
                               defaultMessage: 'Education'})
                           }
                       />
                       {isShowGroups(`patientType=${patientType}`) &&
                       <ListItemSecondaryAction>
                           <IconButton
                               aria-label="expand group"
                               size="small"
                               color="default"
                               disabled={isLoading}
                               onClick={() => {
                                   closePublicPatientsGroups(patientType, patientsGroups, setPatientsGroups, () => {
                                       this.patientsGroupsOpen(`/patients?patientType=${patientType}${recursiveTreeViewSelected[patientType] ? `&group=${recursiveTreeViewSelected[patientType]}` : ``}`, patientsRouteCompare(`/patients?patientType=${patientType}`, location.pathname, location.search))
                                   });
                               }}
                           >
                               {this.isPatientsGroupsOpen(`patientType=${patientType}`) ?
                                   <ExpandLess
                                       edge="end"
                                   />
                                   :
                                   <ExpandMore
                                       edge="end"
                                   />
                               }
                           </IconButton>
                       </ListItemSecondaryAction>
                       }
                   </ListItem>
                   {isShowGroups(`patientType=${patientType}`) &&
                       <Collapse in={this.isPatientsGroupsOpen(`patientType=${patientType}`)}
                                 timeout="auto"
                                 unmountOnExit
                       >
                           <RecursiveTreeView
                               patientsGroups={patientsGroups}
                               history={history}
                               setContextPatient={setContextPatient}
                               locationSearch={this.props.location.search}
                               // routePath={`/patients?patientType=${PatientType.PUBLIC}${activePatientCurrent.group ? `&group=${activePatientCurrent.group}` : `` }`}
                               routePath={`/patients?patientType=${patientType}`}
                               context={this.context}
                               _this={this}
                           />
                       </Collapse>
                   }
               </React.Fragment>
           );
        });
    }

    getProfileGroupsView = () => {
        const {
            classes,
            history,
            intl,
            location,
        } = this.props;
        const {
            setContextPatient,
            patientsGroups,
            setPatientsGroups,
            recursiveTreeViewSelected,
            isLoading,
        } = this.context;
        const {
            profileMode,
        } = this.state;

        const params = new URLSearchParams(location.search);
        const activePatient: ActivePatient = getURLSearchParams(params);
        // console.error('+++ getProfileGroupsView() +++ this.nextContextIsLoading:', this.nextContextIsLoading);
        // console.error('+++ getProfileGroupsView() +++ isLoading:', isLoading);

        return (
            <React.Fragment key={ProfileModeEnum.PROFILE}>
                <ListItem
                    button
                    disabled={isLoading}
                    component="a"
                    selected={profileMode === ProfileModeEnum.PROFILE}
                    onClick={event => {
                        clearContextPatient(setContextPatient, () => {
                            closePublicPatientsGroups(null, patientsGroups, setPatientsGroups, () => {
                                this.patientsGroupsOpen(`/profile?mode=${ProfileModeEnum.PROFILE}${recursiveTreeViewSelected[ProfileModeEnum.PROFILE] ? `&group=${recursiveTreeViewSelected[ProfileModeEnum.PROFILE]}` : ``}${activePatient.activeTab ? `&activePatient.activeTab=${activePatient.activeTab}` : ``}`, patientsRouteCompare(`/profile?mode=${ProfileModeEnum.PROFILE}`, location.pathname, location.search))
                                // this.parseRoute(`/profile?mode=${ProfileModeEnum.PROFILE}`);
                            });
                        });
                    }}
                >
                    <ListItemIcon>
                        <AccountBoxIcon color="action"/>
                    </ListItemIcon>
                    <ListItemText
                        className={classes.linkHead + ' ' + classes.contentBoxProfileText}
                        primary={
                            intl.formatMessage({
                                id: 'label.user.profile.sidebar.header',
                                defaultMessage: 'Profile'
                            })
                        }
                    />
                    {/*{profileMode === ProfileModeEnum.PROFILE &&*/}
                    <ListItemSecondaryAction>
                        <IconButton
                            aria-label="expand group"
                            size="small"
                            color="default"
                            disabled={isLoading}
                            onClick={() => {
                                closePublicPatientsGroups(null, patientsGroups, setPatientsGroups, () => {
                                    this.patientsGroupsOpen(`/profile?mode=${ProfileModeEnum.PROFILE}${recursiveTreeViewSelected[ProfileModeEnum.PROFILE] ? `&group=${recursiveTreeViewSelected[ProfileModeEnum.PROFILE]}` : ``}${activePatient.activeTab ? `&activePatient.activeTab=${activePatient.activeTab}` : ``}`, patientsRouteCompare(`/profile?mode=${ProfileModeEnum.PROFILE}`, location.pathname, location.search))
                                    // this.parseRoute(`/profile?mode=${ProfileModeEnum.PROFILE}`);
                                });
                            }}
                        >
                            {this.isPatientsGroupsOpen(`mode=${ProfileModeEnum.PROFILE}`) ?
                                <ExpandLess
                                    edge="end"
                                />
                                :
                                <ExpandMore
                                    edge="end"
                                />
                            }
                        </IconButton>
                    </ListItemSecondaryAction>
                    {/*}*/}
                </ListItem>
                {profileMode === ProfileModeEnum.PROFILE &&
                    <Collapse in={
                        // this.isPatientsGroupsOpen(`patientType=${patientType}`)
                        true
                    }
                              timeout="auto"
                              unmountOnExit
                    >
                        <RecursiveTreeView
                            patientsGroups={patientsGroups}
                            history={history}
                            setContextPatient={setContextPatient}
                            locationSearch={this.props.location.search}
                            // routePath={`/patients?patientType=${PatientType.PUBLIC}${activePatientCurrent.group ? `&group=${activePatientCurrent.group}` : `` }`}
                            routePath={`/profile?mode=${profileMode}`}
                            context={this.context}
                            _this={this}
                        />
                    </Collapse>
                }
            </React.Fragment>
        );
    }

    getContextPanel = () => {
        const {classes} = this.props;
        const {
            // TODO: for ConfirmDialog
            openConfirmDialog,
            titleConfirmDialog,
            textConfirmDialog,
            buttonYes,
            buttonNo,
            onConfirmDialog,
        } = this.state;

        return (
            <Box>
                <Paper className={classes.contentBoxPanel + ' ' + classes.contentBoxProfilePanel}>
                    <List>
{/*
                        <ListItem
                            button
                            component="a"
                            selected={profileMode === ProfileModeEnum.PROFILE}
                            onClick={event => {
                                this.parseRoute('/profile');
                            }}
                        >
                            <ListItemIcon>
                                <InfoIcon color="action"/>
                            </ListItemIcon>
                            <ListItemText
                                className={`${classes.linkHead} ${classes.contentBoxProfileText}`}
                                primary={intl.formatMessage({
                                    id: 'label.user.profile.sidebar.header',
                                    defaultMessage: 'Profile'
                                })}
                            />
                        </ListItem>
*/}
                        {this.getProfileGroupsView()}
                        {this.getGroupsView()}
{/*
                        <ListItem
                            button
                            component="a"
                            selected={
                                profileMode === ProfileModeEnum.PATIENTS_PUBLIC
                                // &&
                                // !activePatientCurrent.group
                            }
                            onClick={event => {
                                // this.parseRoute(`/patients?patientType=${this.activePatient.patientType}`);
                                clearContextPatient(setContextPatient, () => {
                                    // +++ this.parseRoute(`/patients?patientType=${PatientType.PUBLIC}`);
                                    this.patientsGroupsOpen(`/patients?patientType=${PatientType.PUBLIC}${recursiveTreeViewSelected ? `&group=${recursiveTreeViewSelected}` : ``}`, patientsRouteCompare(`/patients?patientType=${PatientType.PUBLIC}`, location.pathname, location.search));
                                });
                                // TODO: +++ 2020.09.29 +++ this.parseRoute(`/patients?patientType=${PatientType.PUBLIC}`);
                                // history.push(`/patients`);
                                // this.setState({profileMode: ProfileModeEnum.PATIENTS}, () => {
                                //   this.setContextPanelView(this.getContextPanel());
                                // });
                            }}
                        >
                            <ListItemIcon>
                                <PatientsSharedIcon color="action"/>
                            </ListItemIcon>
                            <ListItemText
                                className={classes.linkHead + ' ' + classes.contentBoxProfileText}
                                primary={intl.formatMessage({
                                    id: 'page.patients.public',
                                    defaultMessage: 'Public patients'
                                })}
                            />
                            {isShowGroups(`patientType=${PatientType.PUBLIC}`) &&
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label="expand group"
                                        size="small"
                                        color="default"
                                        onClick={() => this.patientsGroupsOpen(`/patients?patientType=${PatientType.PUBLIC}${recursiveTreeViewSelected ? `&group=${recursiveTreeViewSelected}` : ``}`, patientsRouteCompare(`/patients?patientType=${PatientType.PUBLIC}`, location.pathname, location.search))}
                                    >
                                        {this.isPatientsGroupsOpen(`patientType=${PatientType.PUBLIC}`) ?
                                            <ExpandLess
                                                edge="end"
                                            />
                                            :
                                            <ExpandMore
                                                edge="end"
                                            />
                                        }
                                    </IconButton>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                        {isShowGroups(`patientType=${PatientType.PUBLIC}`) &&
                            <Collapse in={this.isPatientsGroupsOpen(`patientType=${PatientType.PUBLIC}`)}
                                      timeout="auto"
                                      unmountOnExit
                            >
                                <RecursiveTreeView
                                    patientsGroups={patientsGroups}
                                    history={history}
                                    setContextPatient={setContextPatient}
                                    locationSearch={this.props.location.search}
                                    // routePath={`/patients?patientType=${PatientType.PUBLIC}${activePatientCurrent.group ? `&group=${activePatientCurrent.group}` : `` }`}
                                    routePath={`/patients?patientType=${PatientType.PUBLIC}`}
                                    context={this.context}
                                    _this={this}
                                />
                            </Collapse>
                        }
*/}
                        {/* {isAuth(cookies) &&
                            <ListItem
                                button
                                component="a"
                                selected={profileMode === ProfileModeEnum.PATIENTS_PRIVATE}
                                onClick={event => {
                                    clearContextPatient(setContextPatient, () => {
                                        if (isPrivatePatientsEnable()) {
                                            closePublicPatientsGroups(PatientType.PRIVATE, patientsGroups, setPatientsGroups, () => {
                                                this.parseRoute(`/patients?patientType=${PatientType.PRIVATE}`);
                                            });
                                        } else {
                                            this.blockPrivatePatients();
                                        }
                                    });
                                }}
                            >
                                <ListItemIcon>
                                    <PatientsIcon />
                                </ListItemIcon>
                                <ListItemText
                                    className={classes.linkHead + ' ' + classes.contentBoxProfileText}
                                    primary={intl.formatMessage({ id: 'page.patients.private', defaultMessage: 'Patients' })}
                                />
                            </ListItem>
                        } */}
                    </List>
                </Paper>
                {openConfirmDialog &&
                <ConfirmDialog
                    title={titleConfirmDialog}
                    text={textConfirmDialog}
                    buttonYes={buttonYes}
                    buttonNo={buttonNo}
                    open={openConfirmDialog}
                    setOpen={this.openConfirmDialog}
                    onConfirm={onConfirmDialog}
                >
                </ConfirmDialog>
                }
            </Box>
        );
    }

    setContextPanelView = (view, callback) => {
        const {setContextContent} = this.context;

        // console.error('+++ setContextPanelView() +++ !!view:', !!view);
        // console.error('+++ setContextPanelView() +++ setContextContent !== undefined:', setContextContent !== undefined);
        if (setContextContent !== undefined) {
            if (Boolean(view)) {
                setContextContent({view: view}, callback);
            } else {
                setContextContent(null, callback);
            }
        }
    };

    onProfileChange = (name, value) => {
        const {profileFields} = this.state;

        if (value && value.length > 0) {
            profileFields[name] = value;
        } else {
            delete profileFields[name];
        }

        this.setState({
            profileFields: profileFields,
        })
    };

    onProfileSave = (e) => {
        const {profileFields} = this.state;
        const {client, intl} = this.props;

        if (Object.keys(profileFields).length === 0) {
            return;
        }

        client.mutate({
            mutation: updateUserProfile,
            errorPolicy: 'all',
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
            },
            variables: {
                profile: profileFields,
            },
        }).then(({data, _}) => {
            if (data && data.updateUserProfile) {
                this.showNotify(intl.formatMessage({
                    id: 'message.data.save.success',
                    defaultMessage: 'Save success.',
                }), 'success');
                this.loadUserProfile();
            }
        });
    };

    showNotify = (message, type) => {
        const {showNotify} = this.context;
        if (showNotify) {
            showNotify(message, type);
        }
    };

    render() {
        const {profileMode, profileNode} = this.state;
        const {classes} = this.props;
        const {showNotify, patientsGroups} = this.context;

        // TODO: +++2021.12.10+++
        //  this.setContextPanelView(this.getContextPanel());

        return (
            (profileMode === ProfileModeEnum.PROFILE) ? ((!profileNode || profileNode === ProfileProfileModeEnum.PROFILE_INFO) ? <ProfileInformation/> : <ProfileTariffs classes={classes} showNotify={showNotify} patientsGroups={patientsGroups}/>)
                :
                (profileMode === ProfileModeEnum.PATIENTS_PRIVATE || profileMode === ProfileModeEnum.PATIENTS_PUBLIC || profileMode === ProfileModeEnum.PATIENTS_EDUCATION) ?
                    <Patients location={this.props.location} history={this.props.history} profileState={this.state}/>
                    :
                    <Box/>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

Profile.contextTypes = {
    setContextPatient: PropTypes.func,
    setContextContent: PropTypes.func,
    showNotify: PropTypes.func,
    setRecursiveTreeViewExpanded: PropTypes.func,
    recursiveTreeViewExpanded: PropTypes.object,
    setRecursiveTreeViewSelected: PropTypes.func,
    recursiveTreeViewSelected: PropTypes.object,
    patientsGroups: PropTypes.object,
    userProfile: PropTypes.object,
    setPatientsGroups: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
    withCookies,
)(Profile);
