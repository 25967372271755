import { gql } from '@apollo/client';
import type {
    GroupDto,
    ParametersForBaselineDto,
    PatientDto as PatientDtoModel,
    PagePatientDto as PagePatientDtoModel,
    PatientExtDto as PatientExtDtoModel,
    PatientHistoryInputDto,
    StaffDto as StaffDtoModel,
    BaselineLiteAndSectionsDto as BaselineLiteAndSectionsDtoModel,
    BaselineParameterStrictDto as BaselineParameterStrictDtoModel,
    NosologyStatusDto as NosologyStatusDtoModel,
    BaselineParametrSectionDto as BaselineParametrSectionDtoModel,
    Characteristic, Note, Tag, ProcessPatientByNotes,
} from "../const";
import {
    BaselineDetect,
    DetectData, NosologyType, NotesDateList, PagePatientDto, PatientInputDto, PatientListOptions,
    PatientNotes,
    PatientSummary, PatientType,
    PrescriptionTypeByTherapyType,
    ProcessingStatus, TagInput
} from "../const";
import {
    getParametersFromBaseline,
    getPromiseQueryResult, getPromiseResult,
    getQueryResult,
    newAnyDataRef,
    recursiveSortGroups,
    setPatientDataContextHelper, updatePatientDataBaselineAndParameters
} from "../utils";
import {loadDrugCompositesByIds, loadPatientDrugCompositeIds} from "./drugs";
import config from "../config";
import userStore from "../service/user-service";

const Company = `
            id
            name
            modify
`;

const Message = `
            type
            payload
`;

const BaselineParameterDto = `
            id
            name
            sectionId
            type
            values
            ord
            updateDate
            opt
            enable
            influence
            visible
            units
            main
            modified
            messages {
                ${Message}
            }
            noData
`;

const BaselineParameterStrictDto = `
            id
            name
            type
            value
            ord
            updateDate
            opt
            units
            messages {
                ${Message}
            }
`;

const BaselineParameterLiteDto = `
            id
            value
            enable
            visible
            messages {
                ${Message}
            }
            modified
            noData
`;

const SectionListDto = `
            id
            name
            ord
            expanded
`;

const BaselineLiteAndSectionsDto = `
            baselineParameterLiteDtoList {
                ${BaselineParameterLiteDto}
            }
            sectionDtoList {
                ${SectionListDto}
            }
`;

const Parameter = `
            fieldId
            value
            type
            valueDefault
            modified
            updateDate
            noData
            sourceDetection
`;

const ParametersDto = `
            fieldId
            value
`;

const BaselineParametrSectionDto = `
            id
            name
            ord
            expanded
            parameters{
                ${BaselineParameterDto}
            }
`;

const PatientSummaryDto = `
            patientId
            url
            status
            description
`;

const CharacteristicDto = `
            fieldId
            value
            modified
            noData
`;

const DetectDataDto = `
            prescribedCompositeIds
            characteristics {
                ${CharacteristicDto}
            }
`;

const ProcessPatientByNotesDto = `
            status
            errorDescr
`;

const GetListNosologyDto = `
            id
            name
            descr
            ord
`;

const GetNosologyStatusDto = `
            id
            status
            messages {
                ${Message}
            }
`;

const HistoryDataDto = `
            therapyCompare
`;

const Group = `
            id
            name
            active
            modify
            ord
            children {
                id
                name
                active
                modify
                ord
                type
                children {
                    id
                    name
                    active
                    modify
                    ord
                    type
                }
            }
            type
`;

const Role = `
            id
            name
            access_baseline
            access_prescription
            access_patient
`;

const StaffDto = `
            id
            firstName
            lastName
            middleName
            role {
                ${Role}
            }
            companies {
                ${Company}
            }
            nosologies
            specialty
            education
            modify
`;

const PatientHistoryDto = `
            id
            stepNum
            patientId
            nosologyId
            staffId
            modify
            data {
                ${HistoryDataDto}
            }
`;

const PatientHistoryListDto = `
            id
            stepNum
            patientId
            nosologyId
            staffId
            modify
            data {
                ${HistoryDataDto}
            }
`;

const PatientNotesDto = `
            id
            payload
            patientId
            create
            modify
`;

const PatientExtNotesDto = `
            id
            payload
            type
`;

const Nosology = `
            id
            updateDate
            sourceDetection
`;

const NosologyOfPatient = `
            id
            history{
                ${PatientHistoryDto}
            }
`;


const PatientNosologyState = `
            id
            history{
                ${PatientHistoryDto}
            }
`;

const NosologyOfPatientList = `
            id
            history{
                ${PatientHistoryListDto}
            }
`;

const TagDto = `
    id
    name
`;

const PatientExtDto = `
        id
        uuid
        name
        visualName
        companies {
            ${Company}
        }
        create
        modify
        type
        staff{
            ${StaffDto}
        }
        nosologies{
            ${Nosology}
        }
        nosologyStates{
            ${PatientNosologyState}
        }
        notes{
            ${PatientExtNotesDto}
        }
        parameters{
            ${Parameter}
        }
        tags{
            ${TagDto}
        }
        compositeIds
`;

// const PatientDto = `
//         id
//         name
//         visualName
//         updateNosology
//         create
//         modify
//         type
//         nosologies{
//             ${NosologyOfPatient}
//         }
//         notes
// `;
//
const PatientListDto = `
        id
        uuid
        name
        visualName
        updateNosology
        create
        modify
        type
        nosologies{
            ${NosologyOfPatientList}
        }
        notes{
            ${PatientExtNotesDto}
        }
`;

const PagePatientListDto = `
        payload{
            ${PatientListDto}
        }
        pages
        totalCount
`;

const SaveHistoryResponse = `
            patientId
            nosologiesInfo {
                ${NosologyOfPatient}
            }
`;

export const getListPatient = gql`
    query ($type: PatientType, $pageable: PageableInput) {
        getListPatient(type: $type, pageable: $pageable) {
            ${PagePatientListDto}
        }
    }
`;

export const getListPatientByGroup = gql`
    query ($groupId: ID!, $pageable: PageableInput) {
        getListPatientByGroup(groupId: $groupId, pageable: $pageable) {
            ${PagePatientListDto}
        }
    }
`;

export const savePatient = gql`
    mutation ($patient: PatientInputDto!) {
        savePatient(patient: $patient)
        {
            ${PatientExtDto}
        }
    }
`;

export const deletePatient = gql`
    mutation ($id: ID!) {
        deletePatient(id: $id)
    }
`;

export const removePatientFromStaff = gql`
    mutation ($patientId: ID!) {
        removePatientFromStaff(patientId: $patientId)
    }
`;

export const savePatientHistory = gql`
    mutation ($nosologyHistory: PatientHistoryInput!) {
        savePatientHistory(nosologyHistory: $nosologyHistory)
        {
            ${SaveHistoryResponse}
        }
    }
`;

export const getPatient = gql`
    query ($patientUuid: UUID!) {
        getPatient(patientUuid: $patientUuid)
        {
            ${PatientExtDto}
        }
    }
`;

export const getIdsValues = gql`
    query ($patientId: ID!, $nosologyId: ID!) {
        getIdsValues(patientId: $patientId, nosologyId: $nosologyId)
    }
`;

export const getParametersForBaseline = gql`
    query ($patientId: ID!, $nosologyId: ID!) {
        getParametersForBaseline(patientId: $patientId, nosologyId: $nosologyId)
        {
            ${ParametersDto}
        }
    }
`;

export const getBaseline = gql`
    query ($nosologyIds: [ID!]!, $parameters: [ParameterInput]) {
        getBaseline(nosologyIds: $nosologyIds, parameters: $parameters)
        {
            ${BaselineParametrSectionDto}
        }
    }
`;

export const getBaselineStrict = gql`
    query ($parameters: [ParameterInput], $prevCompositeIds: [Int], $nosologyIds: [ID!]!, $userName: String) {
        getBaselineStrict(parameters: $parameters, prevCompositeIds: $prevCompositeIds, nosologyIds: $nosologyIds, userName: $userName)
        {
            ${BaselineParameterStrictDto}
        }
    }
`;

export const getNosologyStatus = gql`
    query ($nosologyIds: [ID!]!, $parameters: [ParameterInput]) {
        getNosologyStatus(nosologyIds: $nosologyIds, parameters: $parameters)
        {
            ${GetNosologyStatusDto}
        }
    }
`;

export const getListNosology = gql`
    {
        getListNosology
        {
            ${GetListNosologyDto}
        }
    }
`;

export const refreshBaseline = gql`
    query ($parameters: [ParameterInput], $initParameterId: ID!) {
        refreshBaseline(parameters: $parameters, initParameterId: $initParameterId)
        {
            ${BaselineLiteAndSectionsDto}
        }
    }
`;

export const getListPatientHistoryByPatient = gql`
    query ($patientId: ID!) {
        getListPatientHistoryByPatient(id: $patientId)
        {
            ${PatientHistoryDto}
        }
    }
`;

export const getPatientHistoryById = gql`
    query ($id: ID!) {
        getPatientHistoryById(id: $id)
        {
            ${PatientHistoryDto}
        }
    }
`;

export const getListHiLevelGroup = gql`
    query {
        getListHiLevelGroup
        {
            ${Group}
        }
    }
`;

export const getListGroupByParent = gql`
    query ($id: ID!) {
        getListGroupByParent(id: $id)
        {
            ${Group}
        }
    }
`;

export const getPatientCascade = gql`
    query ($groupId: ID!, $pageable: PageableInput) {
        getPatientCascade(groupId: $groupId, pageable: $pageable)
        {
            ${PagePatientListDto}
        }
    }
`;

export const getStaff = gql`
    #    query ($id: ID!) {
    query {
        getStaff
        {
            ${StaffDto}
        }
    }
`;

export const getLastPatientHistory = gql`
    query ($patientId: ID!, $nosologyId: ID!) {
        getLastPatientHistory(patientId: $patientId, nosologyId: $nosologyId)
        {
            ${PatientHistoryDto}
        }
    }
`;

export const deleteNosologyHistoryOfPatient = gql`
    mutation ($patientHistoryId: ID!) {
        deleteNosologyHistoryOfPatient(patientHistoryId: $patientHistoryId)
    }
`;

export const deleteAllPatientHistory = gql`
    mutation ($patientId: ID!, $nosologyId: ID!) {
        deleteAllPatientHistory(patientId: $patientId, nosologyId: $nosologyId)
    }
`;

export const addNosologyToPatient = gql`
    mutation ($id: ID!, $nosologyId: ID!) {
        addNosologyToPatient(id: $id, nosologyId: $nosologyId)
    }
`;

export const removeNosologyFromPatient = gql`
    mutation ($id: ID!, $nosologyId: ID!) {
        removeNosologyFromPatient(id: $id, nosologyId: $nosologyId)
    }
`;

export const getSelectedValuesNosology = gql`
    query ($patientId: ID!, $nosologyId: ID!) {
        getSelectedValuesNosology(patientId: $patientId, nosologyId: $nosologyId)
    }
`;

export const getPatientNotes = gql`
    query ($patientId: ID!) {
        getPatientNotes(patientId: $patientId) {
            ${PatientNotesDto}
        }
    }
`;

export const savePatientNotes  = gql`
    mutation ($patientId: ID!, $notes: [PatientNotesInput]) {
        savePatientNotes(patientId: $patientId, notes: $notes)
    }
`;

export const removePatientNotes  = gql`
    mutation ($notesId: ID!) {
        removePatientNotes(notesId: $notesId)
    }
`;

export const getStaticBaseline = gql`
    query ($nosologyIds: [ID!]!, $parameters: [ParameterInput]) {
        getStaticBaseline(nosologyIds: $nosologyIds, parameters: $parameters)
        {
            ${BaselineParametrSectionDto}
        }
    }
`;

export const getPatientSummary = gql`
    query ($request: PatientReportRequest!) {
        getPatientSummary(request: $request)
        {
            ${PatientSummaryDto}
        }
    }
`;

export const getPatientSingleHtmlNosologySummaryProcessingStatus = gql`
    query ($patientId: ID!, $nosologyId: ID!, $userId: String!) {
        getPatientSingleHtmlNosologySummaryProcessingStatus(patientId: $patientId, nosologyId: $nosologyId, userId: $userId)
    }
`;

export const detectData = gql`
    query ($detectionRequest: DetectionRequest!) {
        detectData(detectionRequest: $detectionRequest)
        {
            ${DetectDataDto}
        }
    }
`;

export const processPatientByNotes = gql`
    query ($patientUuid: String!) {
        processPatientByNotes(patientUuid: $patientUuid)
        {
            ${ProcessPatientByNotesDto}
        }
    }
`;

export const callDetectData = (client, signal, baselines: BaselineDetect[], notes: Note[], notesDateSelected: string): Promise<DetectData> => {
    return client.query({
        query: detectData,
        variables: {
            detectionRequest: {
                baselines: baselines,
                sections: notes.map((note) => {
                    return {
                        id: note.type,
                        text: note.payload,
                    }
                }),
                visitDate: (notesDateSelected || '').split(' ')[0] || null,
            }
        },
        context: {
            uri: config.options.server.gql_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'detectData');
    });
}

export const callProcessPatientByNotes = (client, signal, patientUuid): Promise<ProcessPatientByNotes> => {
    return client.query({
        query: processPatientByNotes,
        variables: {
            patientUuid: patientUuid
        },
        context: {
            uri: config.options.server.gql_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'processPatientByNotes');
    });
}

export const callGetBaseline = (client, signal, parameters, nosologyIds): Promise<BaselineParametrSectionDtoModel[]> => {
    return client.query({
        query: getBaseline,
        variables: {
            parameters: parameters,
            nosologyIds: nosologyIds,
        },
        context: {
            uri: config.options.server.parameters_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getBaseline');
    });
}

export const callAddNosologyToPatient = (client, signal, data): Promise<boolean> => {
    return client.mutate({
        mutation: addNosologyToPatient,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: data,
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'addNosologyToPatient');
    });
}

export const callRemoveNosologyFromPatient = (client, signal, data): Promise<boolean> => {
    return client.mutate({
        mutation: removeNosologyFromPatient,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: data,
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'removeNosologyFromPatient');
    });
}

export const savePatientNosology = (client, signal, nosologyData, parameters: ParametersForBaselineDto[], patient, nosology): Promise<any> => {
    delete nosologyData.touched;

    if (nosologyData.therapyPresumably) {
        nosologyData.therapyPresumably.thData.forEach((thData) => {
            nosologyData.prescriptions.push({
                type: PrescriptionTypeByTherapyType[thData.thType],
                ids: thData.selectIds,
            });

        });
    }

    const nosologyHistory: PatientHistoryInputDto = {
        stepNum: 0,
        patientId: Number(patient),
        nosologyId: Number(nosology),
        response: nosology === NosologyType.HEART_FAILURE || nosology === NosologyType.DYSLIPIDEMIA ? null : JSON.stringify(nosologyData),
        prescriptions: nosologyData.prescriptions,
        parameters: parameters,
    };
    if (nosologyData.therapyCompare && nosologyData.therapyCompare.length > 0) {
        nosologyHistory.data = {
            therapyCompare: JSON.stringify(nosologyData.therapyCompare),
        }
    }

    return client.mutate({
        mutation: savePatientHistory,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            nosologyHistory: nosologyHistory,
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'savePatientHistory');
    });
}

export const callSavePatientParameters = (client, signal, parameters, patient, notes: Note[], tag: string, isChangedParameters): Promise<any> => {
    const patientInputDto: PatientInputDto = {
        name: patient.name,
        uuid: patient.uuid,
        type: patient.type,
        parameters: parameters,
        nosologies: patient.nosologies,
        tag: tag,
        notes: patient.notes.map((note) => {
            return {
                id: note.id,
                type: note.type,
                payload: note.payload,
            };
        }),
        compositeIds: patient.compositeIds,
    };

    if (!!isChangedParameters) {
        return callSavePatient(client, signal, patientInputDto);
    } else {
        return new Promise((resolve, reject) => {
            resolve({id: patient.id});
        });
    }
}

/* unused method */
export const callSavePatient = (client, signal, patientInputDto: PatientInputDto): Promise<PatientExtDtoModel> => {
    return client.mutate({
        mutation: savePatient,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.gql_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            patient: patientInputDto,
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'savePatient');
    });
}
/**/

// export const loadGetPatientHistoryById = (client, signal, patient, nosology, history): Promise<FetchResult<{nosologyData: any; idsValues: ParametersForBaselineDto[];}>> => {
export const loadGetPatientHistoryById = (client, signal, patient, nosology, history): Promise<{nosologyData: any; idsValues: ParametersForBaselineDto[];}> => {
    const result = {
        nosologyData: null,
        idsValues: null,
    };
    return client.query({
        query: getPatientHistoryById,
        variables: {
            id: history,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return new Promise((resolve, reject) => {
                let nosologyData;
                const response = (getQueryResult(data, 'getPatientHistoryById') || {}).response;
                const therapyCompare = ((getQueryResult(data, 'getPatientHistoryById') || {}).data || {}).therapyCompare;

                if (response) {
                    try {
                        nosologyData = JSON.parse(response);
                        if (therapyCompare) {
                            nosologyData.therapyCompare = JSON.parse(therapyCompare);
                        }
                    } catch (error) {
                        console.error('+++ Не удалось распарсить историю +++ error:', error);
                    }
                }
                result.nosologyData = nosologyData;
                loadGetParametersForBaseline(client, signal, patient, nosology)
                    .then((idsValues) => {
                        result.idsValues = idsValues;

                        // TODO: здесь вызвать getSelectedValuesNosology
                        // 1. аккаут getParametersForBaseline(patientId: ID!, nosologyId: ID!): [ParametersDto]!
                        // 2. полученное отправляешь в гейт сервис getSelectedValuesNosology
                        // 3. полученное отправляешь в нозологию в метод getTherapy
                        resolve(result);
                    });
            });
        });
}

export const loadGetPatient = (client, signal, patientId, patientUuid, setPatientDataContext): Promise<PatientExtDtoModel> => {
        let nosologyIds: number[];
        return client.query({
            query: getPatient,
            variables: {
                patientUuid: patientUuid,
            },
            context: {
                uri: config.options.server.gql_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(({data, _}) => {
                return new Promise((resolve, reject) => {
                    const patientData: PatientExtDtoModel = getQueryResult(data, 'getPatient');
                    // console.error('+++ loadGetPatient() +++ userStore.user:', userStore.user);
                    // console.error('+++ 1 patientData.parameters:', newAnyDataRef(patientData.parameters));
                    // console.error('+++ 1 patientData:', newAnyDataRef(patientData));
/*
                    patientData.nosologies.push({ // FIXME: +++2022.04.29+++ убрать
                        id: NosologyType.DYSLIPIDEMIA,
                        history: null,
                    });
*/
                    patientData.nosologyStates = (patientData.nosologyStates || []).filter((patientDataNosology) => userStore.user.nosologies.some((userNosology) => userNosology.id === patientDataNosology.id));
                    patientData.nosologies = patientData.nosologies.filter((patientDataNosology) => (((userStore || {}).user || {}).nosologies || []).some((userNosology) => userNosology.id === patientDataNosology.id));
                    // console.error('+++ ((userStore || {}).user || {}).nosologies || []:', ((userStore || {}).user || {}).nosologies || []);
                    // console.error('+++ (patientData || {}).parameters || []:', (patientData || {}).parameters || []);
                    // if (((patientData || {}).nosologyStates || []).length > 0) {
                    if (
                        (((userStore || {}).user || {}).nosologies || []).length > 0
                        &&
                        (
                            ((patientData || {}).parameters || []).length > 0
                            ||
                            ((patientData || {}).nosologyStates || []).length > 0
                        )
                    ) {
                        nosologyIds = (patientData.nosologyStates || []).map((nosology) => Number(nosology.id));

                        const parameters = patientData.parameters.filter((parameter) => !!parameter).map((parameter) => (
                            {
                                value: parameter.value,
                                fieldId: Number(parameter.fieldId),
                                modified: parameter.modified,
                                noData: parameter.noData,
                            }
                        ));
                        client.query({
                            query: getBaseline,
                            variables: {
                                parameters: parameters,
                                nosologyIds: nosologyIds,
                            },
                            context: {
                                uri: config.options.server.parameters_url + config.options.server.api_uri,
                                fetchOptions: {
                                    signal,
                                },
                            },
                        })
                            .then(({data, _}) => {
                                const baseline = getQueryResult(data, 'getBaseline');

                                // updatePatientDataBaselineAndParameters(baseline, patientData);
                                // console.error('+++ 4 patientData.parameters:', newAnyDataRef(patientData.parameters));
                                // const testParametersFromBaseline = getParametersFromBaseline(baseline);
                                // console.error('+++ testParametersFromBaseline:', newAnyDataRef(testParametersFromBaseline));

                                // TODO: for test
                                // parameters.forEach((parametersItem) => {
                                //     const findedTestParametersFromBaseline = testParametersFromBaseline.find((testParametersFromBaselineItem) => testParametersFromBaselineItem.fieldId === '' + parametersItem.fieldId);
                                //     if (!findedTestParametersFromBaseline) {
                                //         console.error('+++ Параметра нет в baseline +++ parameter:', parametersItem);
                                //     }
                                // });
                                // testParametersFromBaseline.forEach((testParametersFromBaselineItem) => {
                                //     const findedParameter = parameters.find((parametersItem) => testParametersFromBaselineItem.fieldId === '' + parametersItem.fieldId);
                                //     if (!findedParameter) {
                                //         console.error('+++ baseline параметра нет в parameters +++ baseline parameter:', testParametersFromBaselineItem);
                                //     }
                                // });

                                patientData.baseline = baseline;

                                loadGetBaselineStrict(client, signal, parameters, nosologyIds, patientData.compositeIds, patientData.name)
                                    .then((baselineParameterStrict) => {
                                        patientData.baselineParameterStrict = baselineParameterStrict.sort((bps1, bps2) => bps1.ord - bps2.ord);

                                        client.query({
                                            query: getNosologyStatus,
                                            variables: {
                                                parameters: parameters,
                                                nosologyIds: nosologyIds,
                                            },
                                            context: {
                                                uri: config.options.server.parameters_url + config.options.server.api_uri,
                                                fetchOptions: {
                                                    signal,
                                                },
                                            },
                                        })
                                            .then(({data, _}) => {
                                                const nosologyStatuses: NosologyStatusDtoModel[] = getQueryResult(data, 'getNosologyStatus');
                                                patientData.nosologyStates = (patientData.nosologyStates || []).map((patientDataNosology) => {
                                                    for (let nosologyStatus of nosologyStatuses || []) {
                                                        if (patientDataNosology.id === nosologyStatus.id) {
                                                            patientDataNosology.status = nosologyStatus.status;
                                                            patientDataNosology.messages = nosologyStatus.messages;
                                                            break;
                                                        }
                                                    }
                                                    return patientDataNosology;
                                                });

                                                // loadPatientDrugCompositeIds(client, signal, patientId)
                                                //     .then((drugsCompsiteIds) => {
                                                //         loadDrugCompositesByIds(client, signal, (drugsCompsiteIds || []).filter((drugsCompsiteId) => !!drugsCompsiteId.drugCompositeId).map((drugsCompsiteId) => Number(drugsCompsiteId.drugCompositeId)))
                                                        loadDrugCompositesByIds(client, signal, patientData.compositeIds)
                                                            .then((drugs) => {
                                                                patientData.drugs = drugs || [];

                                                                // TODO: тут загружаем список дат notes и по последней дате запрашиваем нотесы
                                                                loadGetListVisitDateOfNotes(client, signal, patientId)
                                                                    .then((notesDateList) => {
                                                                        patientData.notesDateList = (notesDateList || []).map((notesDate) => notesDate.visitDate);
                                                                        const lastVisitDate = patientData.notesDateList[patientData.notesDateList.length - 1];
                                                                        loadListNotesByPatientIdVisitDate(client, signal, patientId, lastVisitDate)
                                                                            .then((notes) => {
                                                                                patientData.notes = notes || [];

                                                                                return setPatientDataContextHelper(setPatientDataContext, resolve, patientData);
                                                                            });
                                                                    });

                                                                // return setPatientDataContextHelper(setPatientDataContext, resolve, patientData);
                                                            });
                                                    // });
                                            });
                                    });
                            });
                    } else {
                        return setPatientDataContextHelper(setPatientDataContext, resolve, patientData);
                    }
                });
            });
}

export const loadGetRefreshBaseline = (client, signal, parameters, initParameterId): Promise<BaselineLiteAndSectionsDtoModel> => {
    return client.query({
        query: refreshBaseline,
        variables: {
            parameters: parameters,
            initParameterId: initParameterId,
        },
        context: {
            uri: config.options.server.parameters_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'refreshBaseline');
        });
}

export const loadGetBaselineStrict = (client, signal, parameters, nosologyIds, prevCompositeIds, userName): Promise<BaselineParameterStrictDtoModel[]> => {
    return client.query({
        query: getBaselineStrict,
        variables: {
            parameters: parameters,
            nosologyIds: nosologyIds,
            prevCompositeIds: prevCompositeIds,
            userName: userName,
        },
        context: {
            uri: config.options.server.parameters_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getBaselineStrict');
        });
}

export const loadGetSelectedValuesNosology = (client, signal, patientId, nosologyId): Promise<number[] | string[]> => {
    return client.query({
        query: getSelectedValuesNosology,
        variables: {
            patientId: patientId,
            nosologyId: nosologyId,
        },
        context: {
            uri: config.options.server.parameters_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getSelectedValuesNosology');
        });
}

export const loadGetIdsValues = (client, signal, patientId, nosologyId): Promise<number[]> => {
    return client.query({
        query: getIdsValues,
        variables: {
            patientId: patientId,
            nosologyId: nosologyId,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            // return new Promise((resolve, reject) => {
            //     const idsValues = (data || {}).getIdsValues;
            //     resolve(JSON.parse(JSON.stringify(idsValues)));
            // });
            return getPromiseQueryResult(data, 'getIdsValues');
        });
}

export const loadGetParametersForBaseline = (client, signal, patientId, nosologyId): Promise<ParametersForBaselineDto[]> => {
    return client.query({
        query: getParametersForBaseline,
        variables: {
            patientId: patientId,
            nosologyId: nosologyId,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getParametersForBaseline');
        });
}

export const loadGetStaff = (client, signal, contextStaff, setContextStaff): Promise<StaffDtoModel> => {
    if (true || !contextStaff) {
        return client.query({
            query: getStaff,
            context: {
                uri: config.options.server.patient_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(({data, _}) => {
                return new Promise((resolve, reject) => {
                    const staff: StaffDtoModel = getQueryResult(data, 'getStaff') || {};
                    // staff.nosologies.push(NosologyType.DYSLIPIDEMIA); // FIXME: +++2022.04.29+++ убрать
                    client.query({
                        query: getListNosology,
                        context: {
                            uri: config.options.server.parameters_url + config.options.server.api_uri,
                            fetchOptions: {
                                signal,
                            },
                        },
                    }).then(({data, _}) => {
                        const listNosology = getQueryResult(data, 'getListNosology');
                        staff.nosologies = (listNosology || []).filter((nosology) => !!staff.nosologies.find((staffNosology) => staffNosology === nosology.id));
                        // staff.nosologies = ([]).filter((nosology) => !!staff.nosologies.find((staffNosology) => staffNosology === nosology.id));
                        userStore.user = staff;
                        // console.error('+++ userStore.user:', userStore.user);

                        if (setContextStaff !== undefined) {
                            setContextStaff({
                                contextStaff: staff
                            }, () => {
                                resolve(staff);
                            });
                        } else {
                            resolve(staff);
                        }
                    });
                });
            });
    } else {
        return new Promise((resolve, reject) => {
            resolve(contextStaff);
        });
    }
}

export const loadGetListHiLevelGroup = (client, signal): Promise<GroupDto[]> => {
    return client.query({
        query: getListHiLevelGroup,
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            // return getPromiseQueryResult(data, 'getListHiLevelGroup');
            let groups: GroupDto[] = getQueryResult(data, 'getListHiLevelGroup');
            groups = recursiveSortGroups(groups);
            return new Promise((resolve, reject) => {
                    resolve(groups);
                });
        });
}

export const loadGetListGroupByParent = (client, signal, patientId): Promise<GroupDto[]> => {
    return client.query({
        query: getListGroupByParent,
        variables: {
            patientId: patientId,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getListGroupByParent');
        });
}

export const loadGetListPatientByGroup = (client, signal, groupId, patientListOptions: PatientListOptions): Promise<PagePatientDtoModel[]> => {
    return client.query({
        query: getListPatientByGroup,
        variables: {
            groupId: groupId,
            pageable: patientListOptions,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getListPatientByGroup');
        });
}

export const loadGetPatientCascade = (client, signal, groupId, patientListOptions: PatientListOptions): Promise<PagePatientDtoModel[]> => {
    return client.query({
        query: getPatientCascade,
        variables: {
            groupId: groupId,
            pageable: patientListOptions,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getPatientCascade');
        });
}

export const loadGetListPatient = (client, signal, type, patientListOptions: PatientListOptions): Promise<PagePatientDtoModel[]> => {
    return client.query({
        query: getListPatient,
        variables: {
            type: type,
            pageable: patientListOptions,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getListPatient');
        });
}

export const loadGetStaticBaseline = (client, signal, nosologyIds: [], parameters): Promise<BaselineParametrSectionDtoModel[]> => {
    return client.query({
        query: getStaticBaseline,
        variables: {
            nosologyIds: nosologyIds,
            parameters: parameters,
        },
        context: {
            uri: config.options.server.parameters_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getStaticBaseline');
        });
}

export const loadGetPatientNotes = (client, signal, patientId: number): Promise<PatientNotes> => {
    return client.query({
        query: getPatientNotes,
        variables: {
            patientId: Number(patientId) || 0,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getPatientNotes');
        });
}

export const callSavePatientNotes = (client, signal, patientId, notes: Note[], isChangedNotes): Promise<boolean> => {
    if (!!isChangedNotes) {
        return client.mutate({
            mutation: savePatientNotes,
            errorPolicy: 'all',
            context: {
                uri: config.options.server.patient_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
            variables: {
                patientId: patientId,
                notes: notes.map((note) => {
                    return {
                        id: note.id.startsWith('new:') ? 0 : note.id,
                        type: note.type,
                        payload: note.payload,
                    };
                })
            },
        }).then(({data, _}) => {
            return getPromiseQueryResult(data, 'savePatientNotes');
        });
    } else {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }
}

export const callRemovePatientNotes = (client, signal, note: Note): Promise<boolean> => {
    return client.mutate({
        mutation: removePatientNotes,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            notesId: note.id
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'removePatientNotes');
    });
}

export const loadGetPatientSummary = (client, signal, request): Promise<PatientSummary> => {
        return client.query({
            query: getPatientSummary,
            variables: {
                request: request,
            },
            context: {
                uri: config.options.server.report_manager + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(({data, _}) => {
                return getPromiseQueryResult(data, 'getPatientSummary');
            });
}

export const loadGetPatientSingleHtmlNosologySummaryProcessingStatus = (client, signal, patientId: number, nosologyId: number, userId: number): Promise<ProcessingStatus> => {
    if (nosologyId !== undefined) {
        return client.query({
            query: getPatientSingleHtmlNosologySummaryProcessingStatus,
            variables: {
                patientId: '' + patientId,
                nosologyId: '' + nosologyId,
                userId: '' + userId,
            },
            context: {
                uri: config.options.server.patient_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(({data, _}) => {
                return getPromiseQueryResult(data, 'getPatientSingleHtmlNosologySummaryProcessingStatus');
            });

        // return new Promise((resolve, reject) => {
        //     // resolve({path: null});
        //     resolve(ProcessingStatus.NOT_PROCESSING);
        // });
    } else {
        return new Promise((resolve, reject) => {
            resolve(ProcessingStatus.NOT_PROCESSING);
        });
    }
}

export const getListPatientByTags = gql`
    query ($type: PatientType, $tagIds: [ID]!) {
        getListPatientByTags(type: $type, tagIds: $tagIds) {
            ${PatientListDto}
        }
    }
`;

export const callGetListPatientByTags = (client, signal, patientType: PatientType, tagIds: number[]): Promise<PatientDtoModel[]> => {
    return client.query({
        query: getListPatientByTags,
        context: {
            uri: config.options.server.reference_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
            variables: {
                patientType: patientType,
                tagIds: tagIds,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getListPatientByTags');
        });
}

export const getTags = gql`
    query {
        getTags {
            ${TagDto}
        }
    }
`;

export const callGetTags = (client, signal): Promise<Tag[]> => {
    return client.query({
        query: getTags,
        context: {
            uri: config.options.server.reference_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getTags');
        });
}

export const saveTags = gql`
    mutation ($tags: [TagInput]!) {
        saveTags(tags: $tags) {
            ${TagDto}
        }
    }
`;

export const callSaveTags = (client, signal, tags: TagInput[]): Promise<Tag[]> => {
    return client.mutate({
        mutation: saveTags,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            tags: tags,
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'saveTags');
    });
}

export const deleteTags = gql`
    mutation ($ids: [ID]!) {
        deleteTags(ids: $ids)
    }
`;

export const callDeleteTags = (client, signal, ids: number[]): Promise<boolean> => {
    return client.mutate({
        mutation: deleteTags,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            ids: ids,
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'deleteTags');
    });
}

export const savePatientTags = gql`
    mutation ($patientId: ID!, $tags: [TagInput]!) {
        savePatientTags(patientId: $patientId, tags: $tags)
    }
`;

export const callSavePatientTags = (client, signal, patientId: number, tags: TagInput[]): Promise<boolean> => {
    return client.mutate({
        mutation: savePatientTags,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            patientId: patientId,
            tags: tags,
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'savePatientTags');
    });
}

export const removePatientTags = gql`
    mutation ($patientId: ID!, $tagIds: [ID]!) {
        removePatientTags(patientId: $patientId, tagIds: $tagIds)
    }
`;

export const callRemovePatientTags = (client, signal, patientId: number, tagIds: number[]): Promise<boolean> => {
    return client.mutate({
        mutation: removePatientTags,
        errorPolicy: 'all',
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            patientId: patientId,
            tagIds: tagIds,
        },
    }).then(({data, _}) => {
        return getPromiseQueryResult(data, 'removePatientTags');
    });
}

export const getListVisitDateOfNotes = gql`
    query ($patientId: ID!) {
        getListVisitDateOfNotes(patientId: $patientId) {
            visitDate
        }
    }
`;

export const getListNotesByPatientIdVisitDate = gql`
    query ($patientId: ID!, $visitDate: String) {
        getListNotesByPatientIdVisitDate(patientId: $patientId, visitDate: $visitDate) {
            ${PatientExtNotesDto}
        }
    }
`;

export const loadGetListVisitDateOfNotes = (client, signal, patientId): Promise<NotesDateList[]> => {
    return client.query({
        query: getListVisitDateOfNotes,
        variables: {
            patientId: Number(patientId) || 0,
        },
        context: {
            uri: config.options.server.patient_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getListVisitDateOfNotes');
        });
}

export const loadListNotesByPatientIdVisitDate = (client, signal, patientId, visitDate): Promise<Note[]> => {
    if (!!visitDate) {
        return client.query({
            query: getListNotesByPatientIdVisitDate,
            variables: {
                patientId: Number(patientId) || 0,
                visitDate: visitDate,
            },
            context: {
                uri: config.options.server.patient_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(({data, _}) => {
                return getPromiseQueryResult(data, 'getListNotesByPatientIdVisitDate');
            });
    } else {
        return getPromiseResult([]);
    }
}
