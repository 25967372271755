import {BehaviorSubject, map, Observable, of, ReplaySubject, take} from "rxjs";
import {filter, mergeMap} from "rxjs/operators";
import {ExpandCharts} from "../const";

export const expanded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
export const loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
export const showPanel$: BehaviorSubject<boolean> = new BehaviorSubject(false);
export const startLoadCharts$: BehaviorSubject<boolean> = new BehaviorSubject(null);
export const clearCharts$: BehaviorSubject<boolean> = new BehaviorSubject(null);
export const loadChartsMethod$: ReplaySubject<any> = new ReplaySubject(1);
export const charts$: BehaviorSubject<ExpandCharts[]> = new BehaviorSubject(null);

export const updateShowPanel = (expanded: boolean) => {
    // updateStartLoadCharts(null);
    showPanel$.next(expanded);
};

export const getShowPanel = () => {
    return showPanel$
        .pipe(filter((value) => value !== null));
};

export const updateExpanded = (expanded: boolean) => {
    expanded$.next(expanded);
};

export const getExpanded = () => {
    return expanded$
        .pipe(filter((value) => value !== null));
};

export const setLoadChartsMethod = (loadChartsMethod: any) => {
    // console.error('+++ setLoadChartsMethod() +++ loadChartsMethod:', loadChartsMethod);
    loadChartsMethod$.next(loadChartsMethod);
};

export const updateStartLoadCharts = (load: boolean) => {
    // console.error('+++ updateStartLoadCharts() +++ load:', load);
    startLoadCharts$.next(load);
};

export const getStartLoadCharts = () => {
    return startLoadCharts$;
};

export const updateClearCharts = (load: boolean) => {
    // console.error('+++ updateClearCharts() +++ load:', load);
    clearCharts$.next(load);
};

export const getClearCharts = () => {
    return clearCharts$;
};

export const getLoadCharts = (): Observable<ExpandCharts[]> => {
    return startLoadCharts$
        .pipe(map((value) => {
            // console.error('+++ getLoadCharts() +++ 1 startLoadCharts$ value:', value);
            return value;
        }))
        .pipe(filter((value) => value === true))
        .pipe(mergeMap((value) => {
            // console.error(moment().format('DD.MM.YYYY HH:mm:SS.SSS'), '+++ before start load charts +++');
            // console.error('+++ getLoadCharts() +++ 2 startLoadCharts$ value:', value);
            // startLoadCharts$.next(false);
            return loadChartsMethod$.pipe(take(1))
                .pipe(map((loadChartsMethod) => {
                // console.error('+++ getLoadCharts() +++ 1 loadChartsMethod:', loadChartsMethod);
                return loadChartsMethod;
            }))
        }))
        .pipe(mergeMap((loadChartsMethod) => {
            // console.error('+++ getLoadCharts() +++ 2 loadChartsMethod:', loadChartsMethod);
            // console.error('+++ getLoadCharts() +++ 2 !!loadChartsMethod:', !!loadChartsMethod);
            if (!!loadChartsMethod) {
                return loadChartsMethod()
                    .then((charts) => {
                        // console.error('+++ SERVICE +++ getLoadCharts() +++ charts:', charts);
                        return charts;
                    });
            } else {
                return of(null);
            }
        }));
};

