import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import RootContainer from '../comp/RootContainer';
import Paper from "@material-ui/core/Paper";
import {Button, Toolbar, Typography, Grid} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Done';
import RefreshIcon from '@material-ui/icons/Refresh';
import mainStyles from '../styles/mainStyles';
import {
    getUserRegRequestDetail,
    getUserRegRequests,
    updateUserRegRequests,
} from '../query/registration';
import {withApollo} from '@apollo/client/react/hoc';
import ProfileViewDlg from '../comp/profile/ProfileViewDlg';
import {FormattedMessage, injectIntl} from 'react-intl';
import {getNoDataView, getQueryResult} from "../utils";
import RegRequestionsA from "./RegRequestionsA";
import config from "../config";

const styles = theme => ({
    ...mainStyles(theme),
    hideTextButton: {
        [theme.breakpoints.down(500)]: {
            display: 'none',
        },
    },
    hideIconButton: {
        [theme.breakpoints.up(500)]: {
            display: 'none',
        },
    }
});

class RegRequestions extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            isProfileShow: false,
            profileSel: undefined,
        };
    }

    componentDidMount() {
        this.loadRequests();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadRequests = () => {
        const {client} = this.props;
        const {signal} = this.abortController;
        client.query({
            query: getUserRegRequests,
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'getUserRegRequests'))
            .then(getRegistrationRequests => {
                this.setState({
                    requests: getRegistrationRequests,
                });
            });
    };

    getProfile = (request) => {
        const {client} = this.props;
        const {signal} = this.abortController;
        return client.query({
            query: getUserRegRequestDetail,
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
            variables: {
                id: request.id
            }
        })
            .then(result => getQueryResult(result?.data, 'getUserRegRequestDetail'));
    };

    onIsAcceptedChange = (event, req) => {
        req.isAccepted = event.target.checked;
        this.forceUpdate();
    };

    onSave = () => {
        const {requests} = this.state;
        const {client, intl} = this.props;
        const {signal} = this.abortController;

        client.mutate({
            mutation: updateUserRegRequests,
            errorPolicy: 'all',
            variables: {
                requests: requests.map(req => ({
                    regCodeId: req.regCodeId,
                    username: req.username,
                    isAccepted: req.isAccepted,
                })),
            },
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        }).then(({data, _}) => {
            if (!!(data || {}).updateUserRegRequests) {
                this.showNotify(
                    intl.formatMessage({
                        id: 'message.invite.addresses',
                        defaultMessage: 'Invite sended to all selected addresses'
                    }),
                    'success');
            }
        });
    };

    showNotify = (message, type) => {
        const {showNotify} = this.context;
        if (showNotify) {
            showNotify(message, type);
        }
    };

    showProfile = (request) => {
        const {client} = this.props;
        const {signal} = this.abortController;
        client.query({
            query: getUserRegRequestDetail,
            context: {
                uri: config.options.server.api_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
            variables: {
                id: request.id
            }
        })
            .then(result => getQueryResult(result?.data, 'getUserRegRequestDetail'))
            .then(detail => {
                this.setState({
                    profileSel: (detail || {}).profile,
                    isProfileShow: !!(detail || {}).profile,
                });
            });
    };

    closeProfile = () => {
        this.setState({
            isProfileShow: false,
            profileSel: undefined,
        })
    };

    render() {
        const {classes, intl} = this.props;
        const {requests, profileSel, isProfileShow} = this.state;

        return (
            <RootContainer>
                <Paper>
                    <Toolbar>
                        <Grid container direction={'row'} justifyContent={"space-between"}>
                            <Grid item>
                                <Typography variant={"h6"} color={"inherit"}>
                                    <FormattedMessage id='label.reg.request.list'
                                                      defaultMessage='Registration list of requests'/>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button color={"primary"} onClick={this.loadRequests}>
                                    <RefreshIcon className={classes.iconButton}/>
                                    <FormattedMessage id='label.refresh' defaultMessage='Refresh'/>
                                </Button>
                                {requests && requests.length > 0 &&
                                <Button color={"primary"} onClick={this.onSave}>
                                    <SaveIcon className={classes.iconButton}/>
                                    <FormattedMessage id='label.invite' defaultMessage='Invite'/>
                                </Button>
                                }
                            </Grid>
                        </Grid>
                    </Toolbar>
                    <RegRequestionsA
                        requests={requests}
                        noDataView={getNoDataView(intl.formatMessage({id: 'label.reg.request.list.empty', defaultMessage: 'Not requests'}))}
                        getProfile={this.getProfile}
                    />
                </Paper>
                {profileSel &&
                <ProfileViewDlg isShow={isProfileShow} profile={profileSel} onDialogClose={this.closeProfile}/>}
            </RootContainer>
        );
    }
}

RegRequestions.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

RegRequestions.contextTypes = {
    showNotify: PropTypes.func,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(RegRequestions);
