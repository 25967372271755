import PropTypes from 'prop-types';
import React from 'react';
import {
    Grid,
} from '@material-ui/core';
import mainStyles from '../styles/mainStyles';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
    getColorByPurchaseStatus,
    getLocalStringDateFromString,
    getTextByPurchaseStatus
} from "../utils";
import {injectIntl} from "react-intl";

const styles = theme => ({
    ...mainStyles(theme),
    content: {
        width: '250px',
        maxWidth: '250px',
    },
    purchaseIconWrapper: {
        height: 'fitContent',
        display: 'flex',
    },
    purchaseIcon: {
        color: '#459220',
        fill: '#459220',
        fillColor: '#459220',
    },
    purchaseInfo: {
        color: theme.palette.grey[500],
    },
});

const PurchaseView = (props) => {
    const {
        classes,
        purchase,
        intl,
    } = props;

    let iconStyle: any = {
        color: getColorByPurchaseStatus(purchase.status),
        fill: getColorByPurchaseStatus(purchase.status),
        fillColor: getColorByPurchaseStatus(purchase.status),
    };

    return (
                <Grid container direction={"row"}
                      alignItems={"center"}
                      // justifyContent={"center"}
                      spacing={5}>
                    <Grid item
                          // alignItems={"center"}
                          className={classes.purchaseIconWrapper}
                    >
                        <CheckCircleIcon className={classes.purchaseIcon} style={iconStyle} />
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"}
                              // alignItems={"center"}
                              // justifyContent={"center"}
                              spacing={2}
                        >
                            <Typography variant={"h6"}>
                                {purchase.product.name}
                            </Typography>
                            <Typography variant={"body2"}>
                                {purchase.product.descr}
                            </Typography>
                            <Typography variant={"body2"} className={classes.purchaseInfo}>
                                {getLocalStringDateFromString(purchase.createAt)}
                            </Typography>
                            {/*<Typography variant={"body2"} className={classes.purchaseInfo}>*/}
                            {/*    {purchase.payment.status}*/}
                            {/*</Typography>*/}
                            <Typography variant={"body2"} className={classes.purchaseInfo}>
                                {getTextByPurchaseStatus(purchase.status, intl)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
    );
}

PurchaseView.propTypes = {
    // classes: PropTypes.object.isRequired,
    purchase: PropTypes.object,
};

export default compose(
    withStyles(styles),
    injectIntl,
)(PurchaseView);
