import { gql } from '@apollo/client';
import {UserProfile} from "../models/user";

export const getRolesAvailable = gql`
  {
    getRolesAvailable {
      id
      name
    }
  }
`;

export const getUser = gql`
  {
    getUserProfile {
      id
      user {
        id
        username
        firstname
        lastname
        enabled
        authorities
      }
      role {
        id
        name
      }
    }
  }
`;

export const getUserProfile = gql`
  {
    getUserProfile {
      ${UserProfile}
    }
  }
`;

export const updateUserProfile = gql`
  mutation ($profile: ProfileUpdate!) {
    updateUserProfile(profile: $profile)
  }
`;

export const getUserPermissions = gql`
  {
    getUserPermissions
  }
`;
