import React from 'react';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import {
    Box,
    Button,
    Grid,
    Paper,
    AccordionDetails,
} from "@material-ui/core";
import PanelDivider from "../comp/PanelDivider";
import { withApollo } from '@apollo/client/react/hoc';
import {FormattedMessage, injectIntl} from "react-intl";
import {
    HeartFailureResultView,
    HeartFailureSelectCombView,
    HeartFailureSelectDrugsView,
    HeartFailureSelectTherapyView,
} from "../comp/heartFailure/HeartFailureBComp";
import SelTreatView from "../comp/baseline/SelTreatView";
import SelTextView from "../comp/SelTextView";
import ArticlesListEx from "../comp/article/ArticlesListEx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    // callSavePatient,
    loadGetPatient,
    loadGetSelectedValuesNosology,
    savePatientNosology
} from "../query/patient";
import config from "../config";
import {
    getURLSearchParams,
    getAccess,
    isPrescriptionReadonly,
    onlySaveBaseline,
    finalStepNosologyRedirect,
    isTherapyPresumably,
    isShowComparisons,
    getQueryResult,
    isBackDisable,
    isDisableResultNext,
    getNextLabel,
    loadStaticBaseline,
    getContStatusStyle,
    getAbsoluteRelativeCondsView,
    populateHPChart,
    patientDrugs,
    sortByOrder,
    filterDrugsNotSkiped,
    filterDrugSelectedRegimen,
    enableAllStepsFromActiveStep,
    disableAllStepsFromActiveStepToResult,
    getNextStepNumber,
    getPrevStepNumber,
    getParametersForBaselineDto,
    staticBaselineHasMain,
    getStepsLength,
    getStepNumberToShow,
    isMultiselect,
} from "../utils";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import {getTherapy} from "../query/HeartFailureBaseline";
import {GroupedBarChartColors, NosologyType, PatientType, PrescriptionType, riskTextColors} from "../const";
import SelectNosologyDialog from "../comp/heartFailure/SelectNosologyDialog";
import DeviceView from "../comp/DeviceView";
import TherapyCombView from "../comp/TherapyCombView";
import type {ActivePatient, ParametersForBaselineDto, TherapyCompareDto, TherapyRecommendIn} from "../const";
import SelectTherapyPresumablyView from "../comp/baseline/SelectTherapyPresumablyView";
import {ArticlesPanel, ArticlesPanelSummary} from "./CommonElements";
import {loadGetTherapyCompare} from "../query/therapy";
import BaselineDialog from "../comp/patient/BaselineDialog";
import SaveOrRestartButton from "../comp/button/SaveOrRestartButton";
import StaticBaseline from "../comp/StaticBaseline";
import GroupedBarChart from "../comp/charts/GroupedBarChart";
import ExpandableBaselineParameterStrictContextPanel
    from "../comp/common/ExpandableBaselineParameterStrictContextPanel";
import {AddFeedback} from "../comp/common/AddFeedback";
import ExpandChartsPanel from "../comp/baseline/ExpandChartsPanel";
import {
    getShowPanel,
    updateExpanded,
    updateShowPanel
} from "../service/expand-charts-panel-service";
import {Subscription} from "rxjs";

const styles = theme => ({
    ...mainStyles(theme),
    riskShortDescr: {
        marginTop: theme.spacing(1),
    },
    returnPatientButton: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
});

class HeartFailure extends Component {

    abortController = new AbortController();
    activePatient: ActivePatient = {};
    subscriptions = new Subscription();

    constructor(props) {
        super(props);
        const {intl} = props;

        const steps = [
            {
                title: intl.formatMessage({
                    id: 'label.hp.therapy.type.sel',
                    defaultMessage: 'Choosing a therapy(according to ESC Guidelines)'
                }),
                contentFunc: this.getSelectTherapyView,
                isNextFunc: this.isTherapyTypeSelect,
                backFunc: this.backTherapyTypeSelect,
                enabled: true,
            },
            {
                title: intl.formatMessage({
                    id: 'label.hp.therapy.combination.sel',
                    defaultMessage: 'Choosing combination therapy(according to ESC Guidelines'
                }),
                contentFunc: this.getSelectCombView,
                isNextFunc: this.isTherapyCombSelect,
                backFunc: this.backTherapyCombSelect,
                enabled: true,
            },
            {
                title: intl.formatMessage({
                    id: 'label.hp.therapy.drugs.sel',
                    defaultMessage: 'Drugs selection(according to ESC Guidelines)'
                }),
                contentFunc: this.getSelectDrugsView,
                isNextFunc: this.isTherapyDrugsSelect,
                backFunc: this.backTherapyDrugsSelect,
                enabled: true,
            },
            {
                title: intl.formatMessage({
                    id: 'label.hp.conclusion',
                    defaultMessage: 'Conclusion(according to ESC Guidelines)'
                }),
                contentFunc: this.getResultView,
                nextFunc: this.onStepFinal,
                isNextFunc: () => true,
                nextLabel: intl.formatMessage({id: 'label.bl.new_patient', defaultMessage: 'New patient'}),
                enabled: true,
            },
        ];
        this.activePatient = getURLSearchParams(props.location.search);
        if (this.activePatient.patientType === PatientType.PUBLIC || this.activePatient.patientType === PatientType.EDUCATION) {
            const stepSelectTherapyPresumably = {
                title: intl.formatMessage({
                    id: 'label.nosology.add_drugs',
                    defaultMessage: 'Adding drugs'
                }),
                contentFunc: this.getSelectTherapyPresumablyView,
                isNextFunc: this.isTherapyPresumably,
                backFunc: this.backSelectTherapyPresumably,
                enabled: true,
            };
            steps.unshift(stepSelectTherapyPresumably);
        }

/*
        if (!this.activePatient.patient) {
            steps.unshift(
                {
                    title: intl.formatMessage({id: 'label.bl.baseline', defaultMessage: 'Baseline'}),
                    nextFunc: this.onStep0,
                    contentFunc: this.getBaselineView,
                }
            );
        }
*/

        this.state = {
            disableResultNext: true,
            selectNosologyDialog: {
                open: false,
                nosologies: undefined,
            },
            baselineDialog: {
                open: false,
            },
            nosologyData: {
                // touched: true, // FIXME: почему начальное значение TRUE?
                touched: false,
                articleIds: config.params.heart_failure_articleIds,
            },
            activeStep: 0,
            steps: steps,
            feedbacks: {},

            allDataLoaded: false,
        };
    }

    loadPatientNosology = (activePatient) => {
        const {patientDataContext, setPatientDataContext} = this.context;
        const {signal} = this.abortController;
        const {client} = this.props;

        // TODO: переходим на новую логику
        if (activePatient.patient) {
            if (!(patientDataContext || {}).patientDataContext) {
                loadGetPatient(client, signal, activePatient.patient, activePatient.patientUuid, setPatientDataContext)
                    .then((patientData) => {
                        this.proceedPatientNosology(client, signal, activePatient.action, activePatient.patient, activePatient.nosology, activePatient.history);
                    });
            } else {
                this.proceedPatientNosology(client, signal, activePatient.action, activePatient.patient, activePatient.nosology, activePatient.history);
            }
        } else {
            // this.loadBaseline();
        }
    }

    proceedPatientNosology = (client, signal, action, patientId, nosologyId, patientHistory) => {
        // TODO: здесь вызов нового сервиса, историю не надо передавать, только пациента и нозологию
        const {patientDataContext, setPatientDataContext} = this.context;

        patientDataContext.patientDataContext.actualBaselineParameterStrict = patientDataContext.patientDataContext.baselineParameterStrict;

        loadStaticBaseline(client, signal, nosologyId, patientDataContext, setPatientDataContext, () => {
/* +++2022.06.02+++ отказываемся от парсинга полей в истории response
            if (!!patientHistory) {
                const _nosologyData = getNosologyDataHistory(patientDataContext.patientDataContext, nosologyId);

                this.setState(prevState => ({
                    nosologyData: _nosologyData,
                }), () => {
                    const {nosologyData} = this.state;
                    this.onStep0Proceed(nosologyData, patientId, nosologyId, action, false);
                });
            } else {
*/
                // console.error('+++ nosologyData:', this.state.nosologyData);
                loadGetSelectedValuesNosology(client, signal, patientId, nosologyId)
                    .then((selectedValuesNosology) => {
                        this.setState(prevState => ({
                            nosologyData: {
                                ...prevState.nosologyData,
                                selectedValuesNosology: selectedValuesNosology,
                            }
                        }), () => {
                            const {nosologyData} = this.state;
                            this.onStep0Proceed(nosologyData, patientId, nosologyId, action, false);
                        });
                    });
/* +++2022.06.02+++ отказываемся от парсинга полей в истории response
            }
*/
        });
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.subscriptions.unsubscribe();
        updateShowPanel(null);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.history.location.search !== prevProps.location.search) {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        this.subscriptions.add(getShowPanel()
            .subscribe((showPanel) => {
                this.setState({
                    forestPlotView: showPanel,
                });
            }));

        this.setState({
            activeStep: 0,
            baseline: [],
        });

        this.setContextPanelView(null);
        this.activePatient = getURLSearchParams(this.props.location.search);
        this.loadPatientNosology(this.activePatient);
    }

    updateContextPanel = () => {
        const {steps, activeStep, nosologyData} = this.state;
        const {intl} = this.props;

        if ((this.activePatient.patientType === PatientType.PUBLIC && activeStep === 0) || activeStep === (steps.length - 1)) {
            this.setContextPanelView(null);
            return;
        }

        const {classes} = this.props;

        const {patientDataContext} = this.context;

        const nosologyDataSteps = nosologyData.steps;
        const selStep = nosologyDataSteps.items.filter(step => nosologyDataSteps.selThId === step.id)[0];
        const selType = selStep ? selStep.therapies.filter(type => nosologyDataSteps.selTypeId === type.id)[0] : undefined;
        const selComb = selType ? selType.sets.filter(set => nosologyDataSteps.selCombId === set.id)[0] : undefined;
        // const selDeviceType = selStep ? selStep.therapies.filter(type => nosologyDataSteps.selDeviceTypeId === type.id)[0] : undefined;
        const selDeviceType = selStep ? selStep.supportTherapies.filter(supportTherapy => nosologyDataSteps.selDeviceTypeId === supportTherapy.id)[0] : undefined;
        const selDevice = selDeviceType ? selDeviceType.devices.filter(device => nosologyDataSteps.selDeviceId === device.id)[0] : undefined;

        // console.error('+++ nosologyDataSteps:', nosologyDataSteps);
        // console.error('+++ selStep:', selStep);
        // console.error('+++ selType:', selType);
        // console.error('+++ selComb:', selComb);
        // console.error('+++ selDeviceType:', selDeviceType);
        // console.error('+++ selDevice:', selDevice);
        // console.error('+++ patientDataContext:', patientDataContext);

        // const drugs = Object.values(nosologyDataSteps.selDrugs || {}).filter((drug) => drug.id !== -1);
        const drugs = Object.values(nosologyDataSteps.selDrugs || {}).flatMap((drugs) => drugs.map((drug) => drug)).filter((drug) => drug.id !== -1);
        let selDrugs = Object.values(nosologyDataSteps.selDrugs || {});
        selDrugs = selDrugs.filter((selDrug) => (selDrug[0] || {}).id !== -1);

        const absoluteCond = nosologyData.absoluteCond;
        const relativeCond = nosologyData.relativeCond;

        const scRiskColor: string = riskTextColors[(((selComb || {}).comps || [])[0] || {}).scRisk];
        const selThCombChartData = populateHPChart((selComb || {}).comps, intl, scRiskColor, GroupedBarChartColors.BLUE);

        // const therapyInfo = nosologyData.therapyInfo;

        if (
            (absoluteCond && absoluteCond.length > 0)
            ||
            (relativeCond && relativeCond.length > 0)
            // ||
            // (therapyInfo && therapyInfo.length > 0)
            ||
            selStep
            ||
            (drugs && drugs.length > 0)
        ) {
            this.setContextPanelView(
                <Box>
                    <StaticBaseline staticBaseline={patientDataContext.patientDataContext.staticBaseline} hideDivider={true} />
                    <ExpandableBaselineParameterStrictContextPanel needTopSpace={staticBaselineHasMain(patientDataContext.patientDataContext.staticBaseline)} baselineParameterStrict={patientDataContext.patientDataContext.actualBaselineParameterStrict} />

                    {/*TODO: старая логика вывода, теперь выводится StaticBaseline*/}
                    {/*{therapyInfo && therapyInfo.length > 0 &&*/}
                    {/*therapyInfo.map((ti, index) => (*/}
                    {/*    <Box key={index}>*/}
                    {/*        {index > 0 && <PanelDivider/>}*/}
                    {/*        <Paper className={classes.contentBoxPanel}>*/}
                    {/*            <SelTextView align={"center"}>*/}
                    {/*                {ti.name}*/}
                    {/*            </SelTextView>*/}
                    {/*            {ti.info.map((tiInfo, index) => (*/}
                    {/*                <SelTreatView key={index} treatName={tiInfo}/>*/}
                    {/*            ))}*/}
                    {/*        </Paper>*/}
                    {/*    </Box>*/}
                    {/*))*/}
                    {/*}*/}

                    {absoluteCond && absoluteCond.groups && absoluteCond.groups.length > 0 && getAbsoluteRelativeCondsView(absoluteCond.title, absoluteCond.groups, (patientDataContext.patientDataContext.staticBaseline || []).length, 'RED', classes)}

                    {relativeCond && relativeCond.groups && relativeCond.groups.length > 0 && getAbsoluteRelativeCondsView(relativeCond.title, relativeCond.groups, (absoluteCond && absoluteCond.groups && absoluteCond.groups.length > 0), 'ORANGE', classes)}

{/* TODO: закоментировал, будет другой заголовок
                    {selStep && (
                        <Box>
                            {(
                                (absoluteCond && absoluteCond.groups && absoluteCond.groups.length > 0)
                                ||
                                (relativeCond && relativeCond.groups && relativeCond.groups.length > 0)
                                // ||
                                // (therapyInfo && therapyInfo.length > 0)
                            ) &&
                            <PanelDivider/>
                            }
                            <Paper className={classes.contentBoxPanel}>
                                <SelTextView align={"center"}>
                                    <FormattedMessage id='label.hp.therapy.sel' defaultMessage='Select therapy'/>
                                </SelTextView>

                                <SelTreatView treatName={selStep.name}/>
                            </Paper>
                        </Box>
                    )}
*/}

                    {selType && (
                        <Box>
                            {(
                                    (absoluteCond && absoluteCond.groups && absoluteCond.groups.length > 0)
                                    ||
                                    (relativeCond && relativeCond.groups && relativeCond.groups.length > 0)
                                    // ||
                                    // (therapyInfo && therapyInfo.length > 0)
                                ) &&
                                <PanelDivider/>
                            }
                            {/*<PanelDivider/>*/}
                            <Paper className={classes.contentBoxPanel}>
                                <SelTextView align={"center"}>
                                    {selType.name}
                                </SelTextView>

                                {selComb && (
                                    <Box>
                                        <SelTreatView treatName={<TherapyCombView set={selComb}
                                                                                  therapy={this.getTherapyCombNamesWithItem(selComb)}/>}/>

                                        {selThCombChartData &&
                                        <Grid item xs={12}>
                                            <Box>
                                                <PanelDivider/>
                                                <GroupedBarChart chartData={selThCombChartData}/>
                                            </Box>
                                        </Grid>
                                        }
                                    </Box>
                                )}
                            </Paper>
                        </Box>
                    )}


                    {selDeviceType && selDevice && (
                        <Box>
                            <PanelDivider/>
                            <Paper className={classes.contentBoxPanel}>
                                <SelTextView align={"center"}>
                                    {selDeviceType.name}
                                </SelTextView>
                                {/*<SelTreatView treatName={this.getTherapyDeviceNameWithDescr(selDevice)}/>*/}
                                <SelTreatView treatName={<DeviceView device={selDevice}/>}/>
                            </Paper>
                        </Box>
                    )}

{/*
                    {drugs && drugs.length > 0 &&
                    <Box>
                        <PanelDivider/>
                        <Paper className={classes.contentBoxPanel}>
                            <SelTextView align={"center"}>
                                <FormattedMessage id='label.hp.drugs.sel' defaultMessage='Select drugs'/>
                            </SelTextView>
                            {drugs.map((drug) => (
                                <SelTreatView key={drug.id}
                                              treatName={drug.groupShortName + ': ' + drug.name}/>
                            ))}
                        </Paper>
                    </Box>
                    }
*/}

                    {selDrugs && selDrugs.length > 0 &&
                    <Box>
                        <PanelDivider/>
                        <Paper className={classes.contentBoxPanel}>
                            <SelTextView align={"center"}>
                                <FormattedMessage id='label.hp.drugs.sel' defaultMessage='Select drugs'/>
                            </SelTextView>
                            {
                                selDrugs.flatMap((drugs, index) => {
                                    let groupShortName = '';
                                    let groupDrugsNames = [];
                                    drugs.forEach((drug) => {
                                        groupShortName = drug.groupShortName;
                                        groupDrugsNames.push(drug.name + (!!drug.selectedRegimen ? (' - ' + drug.selectedRegimen.name) : ''));
                                    });
                                    if (groupShortName && !!groupDrugsNames.length) {
                                        return (<SelTreatView key={index}
                                                              treatName={groupShortName + ': ' + groupDrugsNames.join(', ')}/>);
                                    } else {
                                        return undefined;
                                    }
                                })
                            }
                        </Paper>
                    </Box>
                    }
                </Box>
            );
        } else {
            this.setContextPanelView(null);
        }
    };

    setContextPanelView = (view) => {
        const {setContextContent} = this.context;

        if (setContextContent !== undefined) {
            if (Boolean(view)) {
                setContextContent({view: view});
            } else {
                setContextContent(null);
            }
        }
    };

/*
    getBaselineView = () => {
        const {baseline, nosologyData} = this.state;
        const {contextStaff, patientDataContext} = this.context;

        if (Boolean(baseline)) {
            return (<BaselineView
                baseline={baseline}
                nosologyData={nosologyData}
                getSelectVars={this.getSelectVars}
                isFieldShow={this.isFieldShow}
                // readonly={isBaselineReadonly((contextStaff || {}).role, this.activePatient.patientType)}
                readonly={isBaselineReadonly(contextStaff, this.activePatient.patientType, (patientDataContext || {}).patientDataContext)}
            />);
        } else {
            return (<Box/>);
        }
    };
*/

    getResultView = () => {
        const {nosologyData} = this.state;
        return (<HeartFailureResultView
            nosologyData={nosologyData}
            getSteps={this.getSteps}
            getAbsoluteContrs={this.getAbsoluteContrs}
            getRelativeContrs={this.getRelativeContrs}
            getTherapyInfo={this.getTherapyInfo}
            getConclusionTexts={this.getConclusionTexts}
            setConclusionTexts={this.setConclusionTexts}
            getSelectTherapyType={this.getSelectTherapyType}
            getSelectTherapyComb={this.getSelectTherapyComb}
            getTherapyGroupDrugs={this.getTherapyGroupDrugs}
            getSelectVars={this.getSelectVars}
            getSelectFields={this.getSelectFields}
            getGrade={this.getGrade}
            getScore={this.getScore}
            getSubgroups={this.getSubgroups}
            getCompGroups={this.getCompGroups}
            getPossGroups={this.getPossGroups}
            getTherapyDeviceNameWithDescr={this.getTherapyDeviceNameWithDescr}
            getTherapyPresumably={this.getTherapyPresumably}
            loadTherapyCompare={this.loadTherapyCompare}
            getTherapyCompare={this.getTherapyCompare}
            setTherapyCompare={this.setTherapyCompare}
            getTouched={this.getTouched}
            getThRecommend={this.getThRecommend}
            setDisableResultNext={this.setDisableResultNext}
            showComparisons={isShowComparisons(this.activePatient.patientType)}
            autoSave={this.autoSave}
            patientId={this.activePatient.patient}
            activePatient={this.activePatient}
        />);
    };

    getSelectCombView = () => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;
        return (<HeartFailureSelectCombView
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
            nosologyData={nosologyData}
            getSteps={this.getSteps}
            updateContextPanel={this.updateContextPanel}
            getRecomendTexts={this.getRecomendTexts}
            setRecomendTexts={this.setRecomendTexts}
            getTouched={this.getTouched}
            getParams={this.getParams}
            getTherapyComb={this.getTherapyComb}
            setTherapyComb={this.setTherapyComb}
            setSelectTherapyComb={this.setSelectTherapyComb}
            setSelectTherapyDevice={this.setSelectTherapyDevice}
            getSelectVars={this.getSelectVars}
            getSelectFields={this.getSelectFields}
            getSelectTherapyType={this.getSelectTherapyType}
            getTherapyCombNamesWithItem={this.getTherapyCombNamesWithItem}
            getTherapyDeviceNameWithDescr={this.getTherapyDeviceNameWithDescr}
            getSubgroupSelect={this.getSubgroupSelect}
            setForestPlotView={this.setForestPlotView}
            patientId={this.activePatient.patient}
        />);
    };

    getSelectDrugsView = () => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;
        return (<HeartFailureSelectDrugsView
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
            nosologyData={nosologyData}
            updateContextPanel={this.updateContextPanel}
            getSteps={this.getSteps}
            getRecomendTexts={this.getRecomendTexts}
            setRecomendTexts={this.setRecomendTexts}
            getTouched={this.getTouched}
            getSelectTherapyType={this.getSelectTherapyType}
            getTherapyGroupDrugs={this.getTherapyGroupDrugs}
            setTherapyGroupDrugs={this.setTherapyGroupDrugs}
            setDrugForTherapyGroup={this.setDrugForTherapyGroup}
            setStatistics={this.setStatistics}
            getStatistics={this.getStatistics}
            getSelectVars={this.getSelectVars}
            getSelectFields={this.getSelectFields}
            getSelectTherapyComb={this.getSelectTherapyComb}
            setForestPlotView={this.setForestPlotView}
            patientId={this.activePatient.patient}
        />);
    };

    getSelectTherapyPresumablyView = () => {
        const {contextStaff} = this.context;
        const {nosologyData} = this.state;

        return (<SelectTherapyPresumablyView
            nosologyData={nosologyData}
            nosology={NosologyType.HEART_FAILURE}
            setTherapyPresumably={this.setTherapyPresumably}
            getTherapyPresumably={this.getTherapyPresumably}
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
        />);
    };

    getSelectTherapyView = () => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;
        return (<HeartFailureSelectTherapyView
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
            nosologyData={nosologyData}
            updateContextPanel={this.updateContextPanel}
            getSteps={this.getSteps}
            getRecomendTexts={this.getRecomendTexts}
            setRecomendTexts={this.setRecomendTexts}
            getAbsoluteContrs={this.getAbsoluteContrs}
            setAbsoluteContrs={this.setAbsoluteContrs}
            getTherapyInfo={this.getTherapyInfo}
            setTherapyInfo={this.setTherapyInfo}
            getRelativeContrs={this.getRelativeContrs}
            setRelativeContrs={this.setRelativeContrs}
            getSelectTherapyType={this.getSelectTherapyType}
            setTherapyType={this.setTherapyType}
            getSelectVars={this.getSelectVars}
            getSelectFields={this.getSelectFields}
            getTherapyCombNamesWithItem={this.getTherapyCombNamesWithItem}
            getTherapyDeviceNameWithDescr={this.getTherapyDeviceNameWithDescr}
            setForestPlotView={this.setForestPlotView}
            patientId={this.activePatient.patient}
            isNoStepTherapies={this.isNoStepTherapies}
        />);
    };

    isTherapyTypeSelect = () => {
        const {nosologyData} = this.state;
        return (Boolean(nosologyData.steps) && nosologyData.steps.selThId !== undefined) || this.isNoTherapies();
    };

    isTherapyCombSelect = () => {
        // const { thComb } = this.state; // 2020.06.03
        const {nosologyData} = this.state;
        return Boolean(nosologyData.steps) && nosologyData.steps.selCombId !== undefined;
    };

    isTherapyDrugsSelect = () => {
        const {nosologyData} = this.state;

        const steps = nosologyData.steps;
        const selStep = steps.items.filter(step => steps.selThId === step.id)[0];
        const selType = selStep.therapies.filter(type => steps.selTypeId === type.id)[0];
        const selComb = ((selType || {}).sets || []).filter(set => steps.selCombId === set.id)[0];

        // console.error('+++ isTherapyDrugsSelect() +++ nosologyData.steps.selDrugs:', nosologyData.steps.selDrugs);
        // if (nosologyData.steps.selDrugs) {
            // console.error('+++ isTherapyDrugsSelect() +++ Object.keys(nosologyData.steps.selDrugs).length === selComb.drugGroups.filter((drugGroup) => drugGroup.drugs && drugGroup.drugs.length > 0).length:', Object.keys(nosologyData.steps.selDrugs).length === selComb.drugGroups.filter((drugGroup) => drugGroup.drugs && drugGroup.drugs.length > 0).length);
            // console.error('+++ isTherapyDrugsSelect() +++ Object.keys(nosologyData.steps.selDrugs).every((key) => nosologyData.steps.selDrugs[key].length > 0):', Object.keys(nosologyData.steps.selDrugs).every((key) => nosologyData.steps.selDrugs[key].length > 0));
        // }

        return (
            !!nosologyData.steps.selDrugs
            &&
            !!selComb
            &&
            Object.keys(nosologyData.steps.selDrugs).length === selComb.drugGroups.filter((drugGroup) => drugGroup.drugs && drugGroup.drugs.length > 0).length
            &&
            Object.keys(nosologyData.steps.selDrugs).every((key) => nosologyData.steps.selDrugs[key].length > 0)
        );
    };

    isTherapyPresumably = () => {
        const {nosologyData} = this.state;
        // return (Boolean(nosologyData.therapyPresumably) && nosologyData.therapyPresumably.length  > 0);
        return isTherapyPresumably((nosologyData.therapyPresumably || {}).thData);
    };

    backTherapyTypeSelect = () => {
        const {nosologyData} = this.state;
        if (Boolean(nosologyData.steps)) {
            if (nosologyData.touched) {
                delete nosologyData.steps.selThId;
            }
        }

        this.showPrevPage();
        /*
        +++ MERGE 2020.06.05 +++
        this.setState({
          grade: undefined,
          score: undefined,
          subgroups: undefined,
          compGroups: undefined,
          possGroups: undefined,
          params: undefined,
          articleIds: undefined,
          thTypes: undefined,
          referents: undefined,
          oldSelVarIds: undefined,
        }, () => {
          this.showPrevPage();
        });
         */
    };

    backTherapyCombSelect = () => {
        // const { thComb } = this.state; // 2020.06.03
        const {nosologyData} = this.state;
        if (Boolean(nosologyData.thComb)) {
            delete nosologyData.thComb.selCombId;
        }

        this.showPrevPage();
    };

    backTherapyDrugsSelect = () => {
        // const { thGroupDrugs } = this.state; // 2020.06.03
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.thGroupDrugs) && Boolean(nosologyData.thGroupDrugs.items)) {
            nosologyData.thGroupDrugs.items.forEach(it => {
                delete it.selDrugId;
            });
        }

        this.showPrevPage();
    };

    backSelectTherapyPresumably = () => {
        const {nosologyData} = this.state;

        delete nosologyData.therapyPresumably;

        this.showPrevPage();
    };

    getStepContent = () => {
        const {steps, activeStep} = this.state;

        if (activeStep < steps.length && Boolean(steps[activeStep].contentFunc)) {
            return steps[activeStep].contentFunc();
        } else {
            return (<Box/>);
        }
    };

    getSteps = () => {
        return this.state.nosologyData.steps;
    };

    getRecomendTexts = () => {
        return this.state.nosologyData.recomendTexts;
    };

    setRecomendTexts = (recomendTexts, callback) => {
        const {nosologyData} = this.state;

        nosologyData.recomendTexts = recomendTexts;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                recomendTexts: nosologyData.recomendTexts,
            },
        }), () => {
            if (callback) {
                callback();
            }
        });
    };

    getConclusionTexts = () => {
        return this.state.nosologyData.conclusionTexts;
    };

    setConclusionTexts = (conclusionTexts, callback) => {
        const {nosologyData} = this.state;

        nosologyData.conclusionTexts = conclusionTexts;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                conclusionTexts: nosologyData.conclusionTexts,
            },
        }), () => {
            if (callback) {
                callback();
            }
        });
    };

    getAbsoluteContrs = () => {
        return this.state.nosologyData.absoluteContrs;
    };

    setAbsoluteContrs = (absoluteContrs, callback) => {
        const {nosologyData} = this.state;

        nosologyData.absoluteContrs = absoluteContrs;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                absoluteContrs: nosologyData.absoluteContrs,
            },
        }), () => {
            if (callback) {
                callback();
            }
            this.updateContextPanel();
        });
    };

    getRelativeContrs = () => {
        return this.state.nosologyData.relativeContrs;
    };

    setRelativeContrs = (relativeContrs, callback) => {
        const {nosologyData} = this.state;

        nosologyData.relativeContrs = relativeContrs;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                relativeContrs: nosologyData.relativeContrs,
            },
        }), () => {
            if (callback) {
                callback();
            }
            this.updateContextPanel();
        });
    };

    getTherapyInfo = () => {
        return this.state.nosologyData.therapyInfo;
    };

    setTherapyInfo = (therapyInfo, callback) => {
        const {nosologyData} = this.state;

        nosologyData.therapyInfo = therapyInfo;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyInfo: nosologyData.therapyInfo,
            },
        }), () => {
            if (callback) {
                callback();
            }
            this.updateContextPanel();
        });
    };

    getTherapyTypes = () => {
        return this.state.nosologyData.steps;
    };

    getTherapyParams = () => {
        return this.state.nosologyData.params;
    };

    getSelectTherapyType = () => {
        const steps = this.getTherapyTypes();

        if (Boolean(steps) && Boolean(steps.selThId)) {
            return steps.items.find(step => step.id === steps.selThId);
        }

        return undefined;
    };

    getStatistics = () => {
        const {nosologyData} = this.state;
        return nosologyData.statistics;
    };

    setStatistics = (statistics, callback) => {
        const {nosologyData} = this.state;

        nosologyData.statistics = statistics;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                statistics: nosologyData.statistics,
            },
        }), () => {
            if (callback) {
                callback();
            }
        });
    };

    setForestPlotView = (forestPlotView, callback) => {
        updateExpanded(false);

/*
        this.setState(prevState => ({
            forestPlotView: forestPlotView,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
*/
    };

    setTherapyType = (thTypeId) => {
        const {nosologyData} = this.state;

        nosologyData.steps.selThId = thTypeId;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                steps: nosologyData.steps,
                touched: true,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    getReferents = () => {
        return this.state.nosologyData.referents;
    };

    setSelectReferent = (refId, callback) => {
        const {nosologyData} = this.state;

        nosologyData.referents.selId = refId;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                referents: nosologyData.referents,
            },
        }), () => {
            if (callback) {
                callback();
            }
        });
    };

    getTouched = () => {
        return this.state.nosologyData.touched;
    };

    setTouched = (touched) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                touched: touched,
            },
        }), () => {
        });
    };

    getParams = () => {
        return this.state.nosologyData.params;
    };

    getTherapyComb = () => {
        return this.state.nosologyData.thComb;
    };

    getTherapyCombNamesWithItem = (set) => {
        const {classes} = this.props;
        return set.drugGroups
            .sort(sortByOrder)
            .map((drugGroup, index) => {
                return (
                    <Typography key={index} component={'span'}
                                className={`${set.isNoRecommend ? classes.noRecommendSet : ''} ${getContStatusStyle(drugGroup.contStatus, classes, NosologyType.HEART_FAILURE)}`}>
                        {drugGroup.shortName}
                    </Typography>
                )
                // drugGroup.shortName
            })
            .reduce((prev, curr) => [
                prev,
                <Typography key={new Date().getTime()} component={'span'} className={classes.plus}>
                    {' + '}
                </Typography>,
                curr
            ]);
            // .join(' + ');



        // let result = set.drugGroups.map(drugGroup => drugGroup.shortName).join(' + ');
        // if (set.descr) {
        //   result = result + ' (' + set.descr + ')';
        // }
        // return result;

        // let groups = {};
        // const gIds = new Set(item.sets.map(c => c.group)).values();
        // for (let gId of gIds) {
        //   groups[gId] = item.combination.filter(c => c.group === gId);
        // }
        //
        // let items = [];
        // Object.keys(groups)
        //   .forEach(gId => {
        //     if (Number(gId) === 0) {
        //       groups[gId].forEach(g => {
        //         items.push(g.shortName);
        //       });
        //     } else {
        //       let cNames = item.combination.filter(c => c.group === Number(gId)).map(c => c.shortName).join(', ');
        //       items.push(`(${cNames})`);
        //     }
        //   });
        //
        // return items.join(' + ');
    };

    getTherapyDeviceNameWithDescr = (device) => {
        // return device ? (device.name ? device.name : '' + device.descr ? (' ' + device.descr) : '') : '';
        return device ? [device.name, device.descr].join(' ') : '';
    };

    setTherapyComb = (thComb, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                thComb: {
                    items: thComb,
                }
            },
        }), () => {
            if (callback) {
                callback();
            }
        });
    };

    getSelectTherapyComb = () => {
        const thComb = this.getTherapyComb();

        if (Boolean(thComb) && thComb.selCombId !== undefined) {
            return thComb.items[thComb.selCombId];
        }

        return undefined;
    };

    setSelectTherapyComb = (thCombId, thTypeId) => {
        const {nosologyData} = this.state;

        nosologyData.steps.selCombId = thCombId;
        nosologyData.steps.selTypeId = thTypeId;

        delete nosologyData.steps.selDrugs;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                steps: nosologyData.steps,
                touched: true,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    setSelectTherapyDevice = (thDeviceId, thTypeId) => {
        const {nosologyData} = this.state;

        if (nosologyData.steps.selDeviceId !== thDeviceId) {
            nosologyData.steps.selDeviceId = thDeviceId;
            nosologyData.steps.selDeviceTypeId = thTypeId;
        } else {
            nosologyData.steps.selDeviceId = undefined;
            nosologyData.steps.selDeviceTypeId = undefined;
        }

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                steps: nosologyData.steps,
                touched: true,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    getTherapyGroupDrugs = () => {
        return (this.state.nosologyData.thGroupDrugs || []).sort(sortByOrder);
    };

    setTherapyGroupDrugs = (thGroupDrugs, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                thGroupDrugs: {
                    items: thGroupDrugs,
                },
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    getThRecommend = (): TherapyRecommendIn[] => {
        const thRecommend: TherapyRecommendIn[] = [];

        return thRecommend;
    };

    loadTherapyCompare = (signal): Promise<TherapyCompareDto[]> => {
        const {client} = this.props;
        const therapyPresumably = this.getTherapyPresumably();
        const patientId = Number(this.activePatient.patient) || 0;

        if (therapyPresumably && therapyPresumably.thData) {
            therapyPresumably.thData.forEach((thd) => {
                delete thd.subTherapyId;
            });
            return loadGetTherapyCompare(client, signal, NosologyType.HEART_FAILURE, patientId, therapyPresumably.thData, this.getThRecommend())
                .then((data) => {
                    return new Promise((resolve, reject) => {
                        resolve(data);
                    });
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve([]);
            });
        }
    };

    getTherapyCompare = () => {
        const {nosologyData} = this.state;
        return nosologyData.therapyCompare;
    };

    setTherapyCompare = (therapyCompare, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyCompare: therapyCompare,
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    getTherapyPresumably = () => {
        const {nosologyData} = this.state;
        return nosologyData.therapyPresumably;
    };

    setTherapyPresumably = (therapyPresumably, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyPresumably: therapyPresumably,
                touched: true,
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    setDrugForTherapyGroup = (group, drug, regimen) => {
        const {nosologyData} = this.state;
        let selDrugs = {};
        if (nosologyData.steps.selDrugs) {
            selDrugs = nosologyData.steps.selDrugs;
        }
        drug.groupName = group.name;
        drug.groupShortName = group.shortName;
        drug.selectedRegimen = regimen;
        let indexForDelete = -1;

        if (drug.id === -1) {
            selDrugs[group.id] = [];
        }

        if (!!(selDrugs[group.id] || []).length && isMultiselect(group) && !regimen) {
            if (selDrugs[group.id][0].id === -1) {
                selDrugs[group.id] = [];
            }
            indexForDelete = selDrugs[group.id].findIndex((selectedDrug) => selectedDrug.id === drug.id);
        }

        if (indexForDelete >= 0) {
            selDrugs[group.id].splice(indexForDelete, 1);
        } else {
            selDrugs[group.id] = selDrugs[group.id] ? (isMultiselect(group) ? [...selDrugs[group.id], ...[drug]] : [drug]) : [drug];
        }

        nosologyData.steps.selDrugs = selDrugs;
        // console.error('+++ setDrugForTherapyGroup() +++ nosologyData:', nosologyData);
        // console.error('+++ setDrugForTherapyGroup() +++ nosologyData.steps:', nosologyData.steps);
        // console.error('+++ setDrugForTherapyGroup() +++ nosologyData.steps.selDrugs:', nosologyData.steps.selDrugs);

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                steps: nosologyData.steps,
                touched: true,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    getSelectVars = () => {
        const {
            nosologyData,
        } = this.state;

        return nosologyData.selectedValuesNosology;
    };

    getSelectFields = () => {
        const {baseline} = this.state;

        if (!Boolean(baseline)) {
            return [];
        }

        return baseline.flatMap(bl => bl.fields)
            .filter(f => Boolean(f.value))
            .map(f => f.id);
    };

    isFieldShow = (field) => {
        const selVars = this.getSelectVars();

        if (Boolean(field.opt) && Boolean(field.opt.isShow) && Boolean(field.opt.isShow.selVars)) {
            const intr = selVars.filter(v => field.opt.isShow.selVars.includes(v));
            return intr.length === field.opt.isShow.selVars.length;
        }

        return true;
    };

    getGrade = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.grade)) {
            return nosologyData.grade;
        } else {
            return 0;
        }
    };

    getScore = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.score)) {
            return nosologyData.score;
        } else {
            return 0;
        }
    };

    getSubgroups = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.subgroups) && Boolean(nosologyData.subgroups.items)) {
            return nosologyData.subgroups;
        } else {
            return {
                items: [],
            };
        }
    };

    setSubgroupSelect = (e, callback) => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.subgroups)) {
            // if (nosologyData.touched) {
            nosologyData.subgroups.selId = Number(e.target.value);
            // }
            // nosologyData.subgroups.selId = Number(e.target.value); // 2020.06.04
        }

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                subgroups: nosologyData.subgroups,
                touched: true,
            },
        }), () => {
            this.getTherapyGetTherapySupport(undefined, undefined, () => {
                this.updateContextPanel();
                if (Boolean(callback)) {
                    callback();
                }
            });
        });
    };

    getSubgroupSelect = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.subgroups) && Boolean(nosologyData.subgroups.selId)) {
            return nosologyData.subgroups.items.find(sg => sg.id === nosologyData.subgroups.selId);
        }

        return undefined;
    };

    getCompGroups = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.compGroups) && nosologyData.compGroups.length > 0) {
            return nosologyData.compGroups;
        } else {
            return [];
        }
    };

    getPossGroups = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.possGroups) && nosologyData.possGroups.length > 0) {
            return nosologyData.possGroups;
        } else {
            return [];
        }
    };

    showPrevPage = () => {
        const {activeStep, steps} = this.state;

        this.setForestPlotView();

        if (activeStep > 0) {
            this.setState({
                // activeStep: activeStep - 1,
                activeStep: getPrevStepNumber(activeStep, steps),
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    onRestart = () => {
        this.setState({
            allDataLoaded: false,
            activeStep: 0,
            baseline: [],

            selectNosologyDialog: {
                open: false,
                nosologies: undefined,
            },
            baselineDialog: {
                open: false,
            },
            nosologyData: {
                touched: true,
                articleIds: config.params.heart_failure_articleIds,
            },
        }, () => {
            const {setNosologyDataContext} = this.context;
            const {nosologyData} = this.state;
            setNosologyDataContext({
                    nosologyData: nosologyData
                },
                () => {
                    const {history} = this.props;
                    const queryParams = new URLSearchParams(history.location.search);
                    if (queryParams.get('action')) {
                        queryParams.delete('action');
                        history.replace({
                            search: queryParams.toString()
                        });
                    } else {
                        this.componentDidMount();
                    }
                });
        });
    };

    onBack = () => {
        const {activeStep, steps} = this.state;

        // TODO: +++2021.12.03+++
        //  this.setForestPlotView();

        if (Boolean(steps[activeStep].backFunc)) {
            this.setForestPlotView();
            steps[activeStep].backFunc();
        } else if (activeStep > 0) {
            this.showPrevPage();
        }
    };

    showNextPage = () => {
        const {activeStep, steps} = this.state;

        this.setForestPlotView();

        if (activeStep < steps.length - 1) {
            this.setState({
                // activeStep: activeStep + 1,
                activeStep: getNextStepNumber(activeStep, steps),
                allDataLoaded: true,
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    onNext = () => {
        const {activeStep, steps} = this.state;

        // TODO: +++2021.12.03+++
        //  this.setForestPlotView();

        if (Boolean(steps[activeStep].nextFunc)) {
            this.setForestPlotView();
            steps[activeStep].nextFunc();
        } else {
            this.showNextPage();
        }
    };

    isNextAvailable = () => {
        const {steps, activeStep} = this.state;

        if (Boolean(steps[activeStep].isNextFunc)) {
            return steps[activeStep].isNextFunc();
        }

        return activeStep < (steps.length - 1);
    };

    getNextLabel = () => {
        const {steps, activeStep, nosologyData} = this.state;
        const {intl} = this.props;
        const {contextStaff} = this.context;

        return getNextLabel(steps, activeStep, intl, contextStaff, this.activePatient, nosologyData);
    };

    getTherapyGetTherapySupport = (ids, patientId: number, callback) => {
        const {client} = this.props;
        const {signal} = this.abortController;

        client.query({
            query: getTherapy,
            variables: {
                patientId: patientId,
            },
            context: {
                uri: config.options.server.heart_failure_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'getTherapy'))
            .then(result => {

                const getTherapy = result || {};
                const getTherapySteps = (getTherapy.steps || []).sort(sortByOrder);
                getTherapy.steps = getTherapySteps;
                getTherapy.steps.map((step) => (step.therapies || []).sort(sortByOrder));
                getTherapy.steps.map((step) => (step.supportTherapies || []).sort(sortByOrder));

                // getTherapy.steps.map((step) => step.therapies = []);
                // getTherapy.steps.map((step) => step.supportTherapies = []);

                // client.query({
                //     query: getTherapySupport,
                //     variables: {
                //         patientId: patientId,
                //     },
                //     context: {
                //         uri: options.server.heart_failure_url + options.server.api_uri,
                //         fetchOptions: {
                //             signal,
                //         },
                //     },
                // })
                //     .then(result => getQueryResult(result?.data, 'getTherapySupport'))
                //     .then(result => {
                //         const getTherapySupport = result || {};
                //         getTherapySupport.steps.forEach((stepTherapySupport) => {
                //             const stepTherapy = getTherapy.steps.filter((stepTherapy) => stepTherapy.id === stepTherapySupport.id)[0];
                //             if (stepTherapy) {
                //                 stepTherapySupport.therapies.forEach((typeStepTherapySupport) => {
                //                     const types = stepTherapy.therapies.filter((typeStepTherapy) => typeStepTherapy.id === typeStepTherapySupport.id);
                //                     if (types.length === 0) {
                //                         // stepTherapy.therapies = stepTherapy.therapies.concat(stepTherapySupport.therapies);
                //                         const stepTherapySupportTypes = stepTherapySupport.therapies.filter((type) => type.devices.length > 0);
                //                         stepTherapy.therapies = stepTherapy.therapies.concat(stepTherapySupportTypes);
                //                     }
                //                 });
                //             } else {
                //                 // getTherapy.steps.push(stepTherapySupport);
                //                 const stepTherapySupportTypes = stepTherapySupport.therapies.filter((type) => type.devices.length > 0);
                //                 stepTherapySupport.therapies = stepTherapySupportTypes;
                //                 getTherapy.steps.push(stepTherapySupport);
                //             }
                //         });

                        this.setState(prevState => ({
                            nosologyData: {
                                ...prevState.nosologyData,
                                steps: {
                                    items: getTherapy.steps,
                                },
                                absoluteCond: getTherapy.absoluteCond,
                                relativeCond: getTherapy.relativeCond,
                            },

                        }), () => {
                            if (Boolean(callback)) {
                                callback();
                            }
                        });
            });
    };

    onStep0Proceed = (nosologyData, patientId, nosologyId, action, showNextPage: boolean = true) => {
        // TODO: переделать логику определения нозологии для перехода
        //  const nosologies = [
        //      this.isNosology(nosologyData.selectedValuesNosology, SelectNosologyByField.HYPERTENSION) ? NosologyType.HYPERTENSION : null,
        //      this.isNosology(nosologyData.selectedValuesNosology, SelectNosologyByField.ATRIAL_FIBRILLATION) ? NosologyType.ATRIAL_FIBRILLATION : null,
        //  ].filter(nosology => nosology);
        const nosologies = [];
        if (
            // TODO: переделать логику определения нозологии для перехода
            //  this.isNosology(nosologyData.selectedValuesNosology, SelectNosologyByField.HEART_FAILURE) // LVEF <=35 || 36-40 -> HEART_FAILURE
            //  ||
            nosologies.length === 0
            ||
            !this.activePatient.patient
            // this.isNosology(selFields, SelectNosologyByField.HEART_FAILURE)
            // ||
            // (
            //     !this.isNosology(selFields, SelectNosologyByField.HEART_FAILURE)
            //     &&
            //     nosologies.length === 0
            // )
        ) {
            // console.error('+++ onStep0Proceed() +++ nosologyData.touched:', (nosologyData || {}).touched)

            // if (nosologyData && !nosologyData.touched) {
            if (false && !!this.activePatient.history) {
                this.removeFirstStep(() => {
                    const {
                        steps,
                        activeStep,
                    } = this.state;
                    if (showNextPage) {
                        this.showNextPage();
                    } else {
                        this.setState({
                            activeStep: action === 'RESULT' ? steps.length - 1 : activeStep,
                            allDataLoaded: true,
                        });
                    }
                });
            } else {
                this.getTherapyGetTherapySupport(nosologyData.selectedValuesNosology, patientId, () => {
                        this.removeFirstStep(() => {
                            const {
                                steps,
                                activeStep,
                            } = this.state;
                            if (showNextPage) {
                                this.showNextPage();
                            } else {
                                this.setState({
                                    activeStep: action === 'RESULT' ? steps.length - 1 : activeStep,
                                    allDataLoaded: true,
                                });
                            }
                        });
                });
            }
        } else {
            this.removeFirstStep(() => {
                const {
                    steps,
                    activeStep,
                } = this.state;
                this.setState({
                    activeStep: action === 'RESULT' ? steps.length - 1 : activeStep,
                    allDataLoaded: true,
                });
                if (this.activePatient.patient && nosologies.length > 0) {
                    // TODO: переделать логику определения нозологии для перехода
                    //  this.setSelectNosologyDialogOpen(true, selFields, nosologies.length > 0 ? nosologies : null);
                }
            });
        }
    }

    isNoTherapies = (): boolean => {
        const therapySteps = this.getSteps();
        return !((therapySteps || {}).items || []).some((step) => !!(step.therapies || []).length || !!(step.supportTherapies || []).length);
    }

    isNoStepTherapies = (step): boolean => {
        return !(!!(step.therapies || []).length || !!(step.supportTherapies || []).length);
    }

    removeFirstStep = (callback) => {
        const {activeStep} = this.state;

        // FIXME: +++2021.11.22+++
        if (this.isNoTherapies()) {
            const {steps} = this.state;
            enableAllStepsFromActiveStep(activeStep, steps);
            disableAllStepsFromActiveStepToResult(activeStep, steps);

            this.setState(prevState => ({
                steps: steps,
            }), () => {
                callback();
            });
        } else {
            callback();
        }
/*
        if (false && therapySteps && therapySteps.items && therapySteps.items.length === 1) {
            const {nosologyData} = this.state;
            nosologyData.steps.selThId = therapySteps.items[0].id;
            this.setState(prevState => ({
                nosologyData: {
                    ...prevState.nosologyData,
                    steps: nosologyData.steps,
                },
            }), () => {
                const {steps} = this.state;
                let indexToRemove = 0;
                if (this.activePatient.patientType === PatientType.PUBLIC || this.activePatient.patientType === PatientType.EDUCATION) {
                    indexToRemove = 1;
                }
                steps.splice(indexToRemove, 1);
                this.setState({steps: steps}, () => {
                    callback();
                });
            });
        } else {
            callback();
        }
*/
    }

/* unused method
    onStep0 = () => {
        const {
            nosologyData,
        } = this.state;
        const {
            contextStaff,
        } = this.context;

            const access = getAccess((contextStaff || {}).role, this.activePatient.patientType);
            if (onlySaveBaseline(this.activePatient, access)) {
                const {client} = this.props;
                const {signal} = this.abortController;
                callSavePatient(client, signal, this.getSelectVars(), this.activePatient.patient, this.activePatient.patientName, this.activePatient.patientType)
                    .then(saveResponse => {
                        this.finalStepRedirect(this.activePatient);
                    });
            } else {
                this.onStep0Proceed(nosologyData, this.activePatient.patient, this.activePatient.nosology, this.activePatient.action);
            }
    };
*/

    isNosology = (fields, nosology): boolean => {
        return nosology.values.filter(value => value === fields[nosology.field]).length > 0;
    }

    onStepFinal = () => {
        const {contextStaff} = this.context;

        if (this.activePatient.patient && !isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)) {
            const {nosologyData} = this.state;

            if (nosologyData.touched) {
                this.autoSave()
                    .then(saveResponse => {
                        this.finalStepRedirect(this.activePatient);
                    });
            } else {
                this.finalStepRedirect(this.activePatient);
            }
        } else {
            this.finalStepRedirect(this.activePatient);
        }
    };

    autoSave = (): Promise<any> => {
        const {nosologyData} = this.state;
        const {patientDataContext} = this.context;
        if (this.activePatient.action !== 'RESULT' || nosologyData.touched) {
            const {client} = this.props;
            const {signal} = this.abortController;
            const prescriptions: [] = [];

            // console.error('+++++++++ autoSave()  nosologyData.steps.selDrugs:', nosologyData.steps.selDrugs);
            const drug_composite = Object.values(nosologyData.steps.selDrugs || {}).flatMap((drugs) => drugs.filter(filterDrugsNotSkiped).filter(filterDrugSelectedRegimen).map((drug) => drug.selectedRegimen.drugCompositeId));
            const regimen = Object.values(nosologyData.steps.selDrugs || {}).flatMap((drugs) => drugs.filter(filterDrugsNotSkiped).filter(filterDrugSelectedRegimen).map((drug) => drug.selectedRegimen.id));

            // console.error('+++++++++ DRUGS:', drugs);
            // const onlyDrugs = Object.values(nosologyData.steps.selDrugs || {}).flatMap((drugs) => drugs.map((drug) => drug.id)).filter((id) => id !== -1);
            // console.error('+++++++++ onlyDrugs:', onlyDrugs);

            prescriptions.push({
                type: PrescriptionType.DRUG_COMPOSITE,
                ids: drug_composite,
            });
            prescriptions.push({
                type: PrescriptionType.REGIMEN,
                ids: regimen,
            });
            prescriptions.push({
                type: PrescriptionType.DEVICES,
                ids: nosologyData.steps.selDeviceId ? [nosologyData.steps.selDeviceId] : [],
            });
            prescriptions.push({
                type: PrescriptionType.THERAPY,
                ids: nosologyData.steps.selTypeId ? [nosologyData.steps.selTypeId] : [],
            });
            prescriptions.push({
                type: PrescriptionType.SET,
                ids: nosologyData.steps.selCombId ? [nosologyData.steps.selCombId] : [],
            });

            nosologyData.prescriptions = prescriptions;
            const parameters: ParametersForBaselineDto[] = getParametersForBaselineDto(patientDataContext.patientDataContext.parameters);

            return savePatientNosology(client, signal, nosologyData, parameters, this.activePatient.patient, this.activePatient.nosology)
                .then(saveResponse => {
                    this.setDisableResultNext(false);
                    return saveResponse;
                });
        } else {
            return new Promise((resolve, reject) => {
                this.setDisableResultNext(false);
                resolve();
            });
        }
    };

    setDisableResultNext = (disableResultNext: boolean = false) => {
        this.setState({disableResultNext: disableResultNext});
    }

    finalStepRedirect = (activePatient, needGoNextNosology = true) => {
        this.setContextPanelView(null);
        if (this.activePatient.patient) {
            const {signal} = this.abortController;
            const {client, history} = this.props;
            const {setContextPatient, setPatientDataContext} = this.context;
            finalStepNosologyRedirect(signal, client, setContextPatient, history, this.activePatient, setPatientDataContext, needGoNextNosology);
        } else {
            this.setState({
                activeStep: 0,
                baseline: undefined,
                nosologyData: {
                    touched: true,
                }
            }, () => {
                window.scrollTo(0, 0);
                // TODO: раньше загружали, а сейчас мы сюда вовсе не попадаем
                // this.loadBaseline();
            });
        }
    }

    showError = (message) => {
        const {showNotify} = this.context;
        if (showNotify) {
            showNotify(message, 'error');
        }
    };

    articlesPanel = () => {
        const {classes} = this.props;
        const {activeStep, nosologyData} = this.state;

        if (activeStep >= 0 && Boolean(nosologyData.articleIds) && nosologyData.articleIds.length > 0) {
            return (
                <Box className={classes.contentBoxTopSpace}>
                    <ArticlesPanel>
                        <ArticlesPanelSummary
                            expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h6"}>
                                <FormattedMessage id={'label.articles'} defaultMessage={'Articles'}/>
                            </Typography>
                        </ArticlesPanelSummary>
                        <AccordionDetails>
                            <Box>
                                <ArticlesListEx articleIds={nosologyData.articleIds}/>
                            </Box>
                        </AccordionDetails>
                    </ArticlesPanel>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    getForestPlotView = () => {
        const {intl} = this.props;

        const forestPlotView = (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                setExpandedCharts={() => {}}
            >
            </ExpandChartsPanel>
        );

        return forestPlotView;
    };

    forestPlotPanel = () => {
        const {classes} = this.props;
        const {forestPlotView, activeStep, steps} = this.state;
        if (forestPlotView && (activeStep + 1 < steps.length)) {
            return (
                <Box className={classes.contentBoxTopSpace}>
                    {/*{forestPlotView}*/}
                    {this.getForestPlotView()}
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    setSelectNosologyDialogOpen = (open: boolean, selFields, nosologies) => {
        this.setState({
            selectNosologyDialog: {
                selFields: selFields,
                open: open,
                nosologies: nosologies,
            },
        });
    }

    setBaselineDialogOpen = (open: boolean) => {
        this.setState({
            baselineDialog: {
                open: open,
            },
        });
    }

    onSelectNosology = (nosology) => {
        const {history} = this.props;
        const {setNosologyDataContext} = this.context;
        const {selectNosologyDialog} = this.state;

        if (!nosology) {
            if (this.activePatient.patient) {
                this.finalStepRedirect(this.activePatient);
            } else {
                this.setSelectNosologyDialogOpen(false);
            }
        }

        if (nosology && setNosologyDataContext !== undefined) {
            if (Boolean(selectNosologyDialog) && Boolean(selectNosologyDialog.selFields)) {
                // console.log(selectNosologyDialog.selFields);
                setNosologyDataContext(
                    {
                        nosologyData: {
                            selFields: selectNosologyDialog.selFields
                        }
                    },
                    // history.push(nosology.path + '?getData=true')
                    history.push(nosology.path)
                );
            } else {
                setNosologyDataContext(
                    null,
                    history.push(nosology.path)
                );
            }
        }
    }

    render() {
        const {
            classes,
            intl,
        } = this.props;
        const {
            allDataLoaded,
            activeStep,
            steps,
            selectNosologyDialog,
            nosologyData,
            baselineDialog,
            disableResultNext,
            feedbacks,
        } = this.state;
        const {
            patientDataContext,
            contextContent,
            contextStaff,
        } = this.context;
        const step = steps[activeStep];

        if (!allDataLoaded) {
            return <Box />;
        }

        return (
            <Box>

                <Paper className={classes.contentBox}>
                    {Boolean(this.activePatient.patientName) &&
                    <React.Fragment>
                        <Button color={"primary"} variant={"outlined"} className={classes.returnPatientButton}
                                // onClick={() => returnToPatient(this.activePatient, this.props.history)}>
                                onClick={() => this.finalStepRedirect(this.activePatient, false)}>
                            <KeyboardBackspaceRoundedIcon className={this.props.classes.iconButton}/>
                            {this.activePatient.patientName}
                        </Button>
                        <Button color={"primary"} variant={"outlined"} className={classes.returnPatientButton}
                                onClick={() => this.setBaselineDialogOpen(true)}>
                            {intl.formatMessage({
                                id: 'label.patient.baseline',
                                defaultMessage: 'Baseline data'
                            })}
                        </Button>
                        {contextContent &&
                        <Button color={"primary"} variant={"outlined"} className={`${classes.returnPatientButton} ${classes.contextBarButtonHide}`}
                                onClick={() => this.context.setContextContentShow(true)}>
                            {intl.formatMessage({
                                id: 'label.nosology.context_panel',
                                defaultMessage: 'Информационная панель'
                            })}
                        </Button>
                        }
                        {(config.options.FEEDBACK || {}).enable &&
                            <AddFeedback
                                activeStep={activeStep}
                                steps={steps}
                                nosologyData={nosologyData}
                                patientDataContext={patientDataContext}
                                feedbacks={feedbacks}
                            />
                        }
                    </React.Fragment>
                    }

                    <Typography variant='h6'>
                        {`${intl.formatMessage({
                            id: 'label.step',
                            defaultMessage: 'Step'
                        })} ${getStepNumberToShow(activeStep, steps)}/${getStepsLength(steps)}: ${step.title}`}
                    </Typography>
                    {Boolean(step.descr) && <Typography>{step.descr}</Typography>}
                </Paper>

                <PanelDivider/>

                {this.activePatient.patientType === PatientType.PUBLIC && activeStep === 0 ?
                    this.getStepContent()
                    :
                    <Paper>
                        {this.getStepContent()}
                    </Paper>
                }

                {this.forestPlotPanel()}

                {this.articlesPanel()}

                <PanelDivider/>

                <Paper>
                    <Grid container className={classes.contentBox}>
                        <Grid item xs={12}>
                            <Grid container spacing={3} justifyContent='flex-end'>
                                {activeStep > 0 &&
                                <Grid item>
                                    <Button
                                        onClick={this.onBack}
                                        disabled={isBackDisable(activeStep, steps, disableResultNext)}>
                                        <FormattedMessage id={'label.back'} defaultMessage={'Back'}/>
                                    </Button>
                                </Grid>
                                }
                                {activeStep === (steps.length - 1) && this.activePatient.patient &&
                                <Grid item>
                                    <SaveOrRestartButton
                                        onSave={this.onNext}
                                        saveLabel={this.getNextLabel()}
                                        onRestart={this.onRestart}
                                        intl={intl}
                                        disableResultNext={isDisableResultNext(this.activePatient.action, nosologyData.touched, disableResultNext)}
                                    />
                                </Grid>
                                }
                                {(activeStep !== (steps.length - 1) || !this.activePatient.patient) &&
                                <Grid item>
                                    <Button
                                        onClick={this.onNext}
                                        disabled={!this.isNextAvailable()}
                                        color='primary'
                                        variant='contained'>
                                        {this.getNextLabel()}
                                    </Button>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <SelectNosologyDialog
                    title={intl.formatMessage({
                        id: 'label.dialog.select.nosology.title',
                        defaultMessage: 'Select nosology'
                    })}
                    text={intl.formatMessage({
                        id: 'label.dialog.select.nosology.therapy_not_recoment',
                        defaultMessage: 'Therapy is not required'
                    })}
                    open={selectNosologyDialog.open}
                    setOpen={this.setSelectNosologyDialogOpen}
                    onSelect={this.onSelectNosology}
                    finalStepRedirect={this.finalStepRedirect}
                    buttonCancel={intl.formatMessage({id: 'label.cancel', defaultMessage: 'Cancel'})}
                    nosologies={selectNosologyDialog.nosologies}
                    classes={classes}
                    // history={history}
                />
                {
                    baselineDialog.open &&
                    <BaselineDialog
                        open={baselineDialog.open}
                        setOpen={this.setBaselineDialogOpen}
                        classes={classes}
                        intl={intl}
                        patientData={patientDataContext.patientDataContext}
                        contextStaff={contextStaff}
                        baseline={patientDataContext.patientDataContext.baseline}
                        nosologyData={nosologyData}
                        isFieldShow={this.isFieldShow}
                        patientDrugs={patientDrugs}
                    />
                }
            </Box>
        );
    }
}

HeartFailure.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

HeartFailure.contextTypes = {
    setContextContent: PropTypes.func,
    showNotify: PropTypes.func,
    nosologyDataContext: PropTypes.object,
    setNosologyDataContext: PropTypes.func,
    patientDataContext: PropTypes.object,
    setPatientDataContext: PropTypes.func,
    setContextPatient: PropTypes.func,
    contextStaff: PropTypes.object,
    contextContent: PropTypes.object,
    setContextContentShow: PropTypes.func,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl
)(HeartFailure);
