import { gql } from '@apollo/client';

export const qArticlesWithRecommendId = gql`
  query ($recommendId: Int!) {
    searchArticlesWithRecommendId(recommendId: $recommendId) {
      id 
      title 
      authors
      abstract {
        category
        text
      } 
    }
  }
`;

export const qArticles = gql`
  { 
    articles { 
      id 
      title 
      authors
      abstract {
        category
        text
      } 
    } 
  }
`;

export const qArticleWithId = gql`
  query ($articleId: Int!) {
    getArticleWithId(articleId: $articleId) {
      id 
      title 
      authors
      doi
      pdfUrl
      rate
      pubDate
      pubTypes {
        name
      }
      abstract {
        category
        text
      } 
    }
  }
`;

export const qArticleWithAQId = gql`
  query ($aqId: Int!) {
    getArticleWithAQId(aqId: $aqId) {
      id 
      title 
      authors
      doi
      pdfUrl
      rate
      pubDate
      pubTypes {
        name
      }
      abstract {
        category
        text
      } 
    }
  }
`;

export const qArticlesWithQuestion = gql`
  query ($questionId: Int!, $userId: Int) {
    getArticlesWithQuestion(questionId: $questionId, userId: $userId) {
      id 
      title 
      authors
      isSelect
      rate
      pubDate
      doi
      pdfUrl
      pubTypes {
        name
      }
      abstract {
        category
        text
        translate {
          lang
          text
        }
      } 
    }
  }
`;

export const getArticlesWithQuestionAndTag = gql`
  query ($questionId: Int!, $tagId: Int!, $userId: Int) {
    getArticlesWithQuestionAndTag (questionId: $questionId, tagId: $tagId, userId: $userId) {
      id,
      authors,
      title
    }
  }
`;

export const getArticlesWithQuestionAndTagAndCls = gql`
  query ($questionId: Int!, $tagId: Int!, $articleCls: String!, $userId: Int) {
    getArticlesWithQuestionAndTagAndCls(questionId: $questionId, tagId: $tagId, 
    articleCls: $articleCls, userId: $userId) {
      id,
      authors,
      title
    }
  }
`;

export const getArticlesWithIds = gql`
  query ($ids: [Int!]!) {
    getArticlesWithIds(ids: $ids) {
      id 
      title
      authors
      doi
      pdfUrl
      rate
      pubDate
      pubTypes {
        name
      }
      abstract {
        category
        text
        translate {
          lang
          text
        }
      } 
    }
  }
`;

export const getArticleIdsWithQuestinAndMeta = gql`
  query ($questionId: Int!, $isMeta: Boolean!) {
    getArticleIdsWithQuestinAndMeta(questionId: $questionId, isMeta: $isMeta)
  }
`;
