import {gql} from '@apollo/client';
import type {OutcomeRiskForTherapyDto} from "../const";
import config from "../config";
import {getPromiseQueryResult} from "../utils";

export const getFLSubgroupsRate = gql`
  query ($therapyName: String!, $featuresIn: [FLSGFeatureIn!]!, $groups: [String!]!, $inTreats: [String!]) {
    getFLSubgroupsRate(therapyName: $therapyName, featuresIn: $featuresIn, groups: $groups, inTreats: $inTreats) {
      efficacyName
      safetyName
      subgroups {
        name,
        isSel,
        rate,
        selTypes
      }
    }
  }
`;

export const getFLBaseline = gql`
  query ($patientId: Int!) {
    getFLBaseline (patientId: $patientId) {
      id
      name
      num
      isRequired
      isMain
      fgroup
      defaultVar
      parentId
      value
      vars {
        id
        name
        descr
        ord
        parentId
     }
    }
  }
`;

export const calcFLBaseline = gql`
  query ($patientId: Int!, $line: Int!) {
    calcFLBaseline (patientId: $patientId, line: $line) {
      sgTreatments {
        thGroup
        required
        name
        fullName
        referent
        isCalcLevel
        datasetAvailable
        therapyId
        sgNames
        active
        activeTexts
        texts
        treatments
        lines {
          num
          label
        }
        articles
        messages {
          type
          payload
        }
      }
      features {
        name
        value
      }
    }
  }
`;

export const getFLBaselineTreat = gql`
  query ($patientId: Int!, $therapyName: String!, $features: [FLSGFeatureIn!]!, $groups: [String!]!, $inTreats: [String!], $inRefer: String) {
    getFLBaselineTreat(patientId: $patientId, therapyName: $therapyName, features: $features, groups: $groups, inTreats: $inTreats, inRefer: $inRefer) {
      id
      groups {
        id
        name
        ord
        treatments {
          id
          name
          drugCompositeId
          isSel
          dataAvailable
          visible
          compStatus
          availableStatus
          messages {
            type
            payload
          }
        }
      }
      subgroups {
        efficacyName
        safetyName
        subgroups {
          name
          isSel
          rate
          selTypes
        }
      }
      excluded
      params {
        key
        value
      }
    }
  }
`;

export const getFLResultTexts = gql`
  query ($secNum: Int!, $features: [FLSGFeatureIn!]!, $therapy: [String!]!, $drugs: [FLCompositeTreatment!]!, $patientId: Int!) {
    getFLResultTexts(secNum: $secNum, features: $features, therapy: $therapy, drugs: $drugs, patientId: $patientId) {
      text
      label
    }
  }
`;

export const getOutcomeRiskForTherapy = gql`
  query ($patientId: Int!, $therapy: String!, $treatments: [Int!]!) {
    getOutcomeRiskForTherapy (patientId: $patientId, therapy: $therapy, treatments: $treatments) {
      riskPlName
      riskThName
      data {
        id
        name
        subgroups {
          id
          name
          riskPl
          riskTh
        }
      }
    }
  }
`;

export const loadGetOutcomeRiskForTherapy = (client, signal, therapy: string, treatments: number[], patientId: number): Promise<OutcomeRiskForTherapyDto> => {
    if (therapy && treatments) {
        return client.query({
            query: getOutcomeRiskForTherapy,
            variables: {
                patientId: patientId,
                therapy: therapy,
                treatments: treatments,
            },
            context: {
                uri: config.options.server.fl_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(({data, _}) => {
                return getPromiseQueryResult(data, 'getOutcomeRiskForTherapy');
            });
    } else {
        return getPromiseQueryResult({});
    }
}
