import {gql} from '@apollo/client';
import type {
    Subgroup as SubgroupModel,
    DrugGroup as DrugGroupModel,
} from '../models/breast-cancer';
import {getPromiseQueryResult} from "../utils";
import {PScore, PScoreDto} from "../models/pscore";
import {RChart, RChartDto} from "../models/forest-plot";
import config from "../config";

const Message = `
        type
        payload
`;

const Subtreatment = `
        id
        name
        ord
        messages  {
            ${Message}        
        }
`;

const Treatment = `
        id
        name
        ord
        medication
        enable
        messages  {
            ${Message}        
        }
        subtreatments {
            ${Subtreatment}        
        }
`;

const RecommendDescr = `
        ord
        label
        text
        type
`;

const Scale = `
        id
        name
        value
        ord
        messages  {
            ${Message}        
        }
`;

const Subgroup = `
        id
        name
        ord
        messages  {
            ${Message}        
        }
        treatments {
            ${Treatment}        
        }
        recommends {
            ${RecommendDescr}        
        }
        scales {
            ${Scale}        
        }
`;

const Reception = `
        number
        descr
`;

const Drug = `
        id
        name
        available
        messages  {
            ${Message}        
        }
`;

const DrugGroup = `
        id
        name
        shortName
        isBlocked
        messages  {
            ${Message}        
        }
        receptions  {
            ${Reception}        
        }
        drugs {
            ${Drug}        
        }
`;

export const getSubgroup = gql`
    query ($ids: [Int!]!, $patientId: ID!){
        getSubgroup(ids: $ids, patientId: $patientId) {
            ${Subgroup}
        }
    }
`;

export const getDrugs = gql`
    query ($subtritmantIds: [ID!]!, $parameterIds: [ID!]!){
        getDrugs(subtritmantIds: $subtritmantIds, parameterIds: $parameterIds) {
            ${DrugGroup}
        }
    }
`;

export const getPScore = gql`
    query ($drugGroupIds: [Int!]) {
        getPScore (drugGroupIds: $drugGroupIds) {
            ${PScore}
        }
    }
`;

export const getChartPcr = gql`
    query ($drugGroupIds: [Int!], $patientId: Int) {
        getChartPcr (drugGroupIds: $drugGroupIds, patientId: $patientId) {
            ${RChart}
        }
    }
`;

export const loadGetPScore = (client, signal, drugGroupIds: number[]): Promise<PScoreDto> => {
    return client.query({
        query: getPScore,
        variables: {
            drugGroupIds: drugGroupIds,
        },
        context: {
            uri: config.options.server.breast_cancer_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getPScore');
        });
}

export const loadGetForestPlotCharts = (client, signal, drugGroupIds: number[], patientId: number): Promise<RChartDto> => {
    return client.query({
        query: getChartPcr,
        variables: {
            drugGroupIds: drugGroupIds,
            patientId: patientId,
        },
        context: {
            uri: config.options.server.breast_cancer_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getChartPcr');
        });
}

export const loadGetSubgroup = (client, signal, ids: (number | string)[], patientId: number): Promise<SubgroupModel[]> => {
    return client.query({
        query: getSubgroup,
        context: {
            uri: config.options.server.breast_cancer_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            ids: ids,
            patientId: patientId,
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getSubgroup', []);
        });
}

export const loadGetDrugs = (client, signal, subtritmantIds: (number | string)[], parameterIds: (number | string)[]): Promise<DrugGroupModel[]> => {
    return client.query({
        query: getDrugs,
        context: {
            uri: config.options.server.breast_cancer_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
        variables: {
            subtritmantIds: subtritmantIds,
            parameterIds: parameterIds,
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getDrugs', []);
        });
}
