import React from 'react';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import {
    Box,
    Button,
    Grid,
    Paper,
    AccordionDetails,
    Typography,
} from "@material-ui/core";
import PanelDivider from "../comp/PanelDivider";
import { withApollo } from '@apollo/client/react/hoc';
import {FormattedMessage, injectIntl} from "react-intl";
import {HPResultView, HPSelectCombView, HPSelectDrugsView, HPSelectTherapyView,} from "../comp/hp/HPBComp";
import SelTreatView from "../comp/baseline/SelTreatView";
import SelTextView from "../comp/SelTextView";
import {calcHPBaseline} from "../query/HPBaseline";
import ArticlesListEx from "../comp/article/ArticlesListEx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    // callSavePatient,
    loadGetPatient,
    loadGetSelectedValuesNosology,
    savePatientNosology
} from "../query/patient";
import {
    getURLSearchParams,
    getAccess,
    isPrescriptionReadonly,
    onlySaveBaseline,
    finalStepNosologyRedirect,
    isTherapyPresumably,
    isShowComparisons,
    populateHPChart,
    getQueryResult,
    isBackDisable,
    isDisableResultNext,
    getNextLabel,
    getNosologyDataHistory,
    loadStaticBaseline,
    getAbsoluteRelativeCondsView,
    patientDrugs,
    getNextStepNumber,
    enableAllStepsFromActiveStep,
    disableAllStepsFromActiveStepToResult,
    getPrevStepNumber,
    getParametersForBaselineDto, staticBaselineHasMain,
} from "../utils";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import {GroupedBarChartColors, NosologyType, PatientType, PrescriptionType, riskTextColors} from "../const";
import type {
    ActivePatient, ParametersForBaselineDto,
    StatDataInInputDto,
    TherapyCompareDto,
    TherapyCompareStatisticsDto,
    TherapyRecommendInHypertension
} from "../const";
import config from "../config";
import SelectTherapyPresumablyView from "../comp/baseline/SelectTherapyPresumablyView";
import {ArticlesPanel, ArticlesPanelSummary, TypographySubtitle} from "./CommonElements";
import {getHPFinalComb, loadGetTherapyCompare, loadGetTherapyCompareStatistics} from "../query/therapy";
import GroupedBarChart from "../comp/charts/GroupedBarChart";
import BaselineDialog from "../comp/patient/BaselineDialog";
import SaveOrRestartButton from "../comp/button/SaveOrRestartButton";
// import red from "@material-ui/core/colors/red";
// import orange from "@material-ui/core/colors/orange";
import StaticBaseline from "../comp/StaticBaseline";
import ExpandableBaselineParameterStrictContextPanel
    from "../comp/common/ExpandableBaselineParameterStrictContextPanel";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import ExpandChartsPanel from "../comp/baseline/ExpandChartsPanel";
import {
    getShowPanel,
    updateExpanded,
    updateShowPanel
} from "../service/expand-charts-panel-service";
import {Subscription} from "rxjs";

const styles = theme => ({
    ...mainStyles(theme),
    riskShortDescr: {
        marginTop: theme.spacing(1),
    },
    returnPatientButton: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    lowRiskText: {
        color: 'green',
    },
    highRiskText: {
        color: 'orange',
    },
    vhighRiskText: {
        color: 'red',
    },
    vvhighRiskText: {
        color: 'darkred',
    },
    labelGradeScore: {
        fontWeight: 500,
        color: 'black',
    },
    grade1: {
        color: 'green',
    },
    grade2: {
        color: 'orange',
    },
    grade3: {
        color: 'red',
    },
    listItem: {
        padding: '0',
    },
});

class Hyper extends Component {

    abortController = new AbortController();
    activePatient: ActivePatient = {};
    subscriptions = new Subscription();

    constructor(props) {
        super(props);
        const {intl} = props;

        const steps = [
            {
                title: intl.formatMessage({
                    id: 'label.hp.therapy.type.sel',
                    defaultMessage: 'Choosing a therapy(according to ESC Guidelines)'
                }),
                descr: intl.formatMessage({
                    id: 'label.hp.therapy.type.sel.desc',
                    defaultMessage: 'Aim for BP control within 3 months'
                }),
                contentFunc: this.getSelectTherapyView,
                isNextFunc: this.isTherapyTypeSelect,
                backFunc: this.backTherapyTypeSelect,
                enabled: true,
            },
            {
                title: intl.formatMessage({
                    id: 'label.hp.therapy.combination.sel',
                    defaultMessage: 'Choosing combination therapy(according to ESC Guidelines'
                }),
                descr: intl.formatMessage({
                    id: 'label.hp.therapy.type.sel.desc',
                    defaultMessage: 'Aim for BP control within 3 months'
                }),
                contentFunc: this.getSelectCombView,
                isNextFunc: this.isTherapyCombSelect,
                backFunc: this.backTherapyCombSelect,
                enabled: true,
            },
            {
                title: intl.formatMessage({
                    id: 'label.hp.therapy.drugs.sel',
                    defaultMessage: 'Drugs selection(according to ESC Guidelines)'
                }),
                descr: intl.formatMessage({
                    id: 'label.hp.therapy.type.sel.desc',
                    defaultMessage: 'Aim for BP control within 3 months'
                }),
                contentFunc: this.getSelectDrugsView,
                isNextFunc: this.isTherapyDrugsSelect,
                backFunc: this.backTherapyDrugsSelect,
                enabled: true,
            },
            {
                title: intl.formatMessage({
                    id: 'label.hp.conclusion',
                    defaultMessage: 'Conclusion(according to ESC Guidelines)'
                }),
                contentFunc: this.getResultView,
                nextFunc: this.onStepFinal,
                isNextFunc: () => true,
                nextLabel: intl.formatMessage({id: 'label.bl.new_patient', defaultMessage: 'New patient'}),
                enabled: true,
            },
        ];
        this.activePatient = getURLSearchParams(props.location.search);
        if (this.activePatient.patientType === PatientType.PUBLIC || this.activePatient.patientType === PatientType.EDUCATION) {
            const stepSelectTherapyPresumably = {
                title: intl.formatMessage({
                    id: 'label.nosology.add_drugs',
                    defaultMessage: 'Adding drugs'
                }),
                // descr: intl.formatMessage({
                //   id: 'label.hp.therapy.type.sel.desc',
                //   defaultMessage: 'Aim for BP control within 3 months'
                // }),
                contentFunc: this.getSelectTherapyPresumablyView,
                isNextFunc: this.isTherapyPresumably,
                backFunc: this.backSelectTherapyPresumably,
                enabled: true,
            };
            steps.unshift(stepSelectTherapyPresumably);
        }

        // if (!this.activePatient.patient) {
        //     steps.unshift(
        //         {
        //             title: intl.formatMessage({id: 'label.bl.baseline', defaultMessage: 'Baseline'}),
        //             descr: intl.formatMessage({
        //                 id: 'label.bl.baseline.descr',
        //             }),
        //             nextFunc: this.onStep0,
        //             contentFunc: this.getBaselineView,
        //         }
        //     );
        // }

        this.state = {
            disableResultNext: true,
            nosologyData: {
                touched: true,
            },
            activeStep: 0,
            steps: steps,

            baselineDialog: {
                open: false,
            },

            allDataLoaded: false,
        };
    }

    loadPatientNosology = (activePatient) => {
        // console.error('+++ HYPER +++ loadPatientNosology() +++ activePatient:', activePatient);
        const {patientDataContext, setPatientDataContext} = this.context;
        const {signal} = this.abortController;
        const {client} = this.props;

        // TODO: переходим на новую логику
        if (activePatient.patient) {
            if (!(patientDataContext || {}).patientDataContext) {
                loadGetPatient(client, signal, activePatient.patient, activePatient.patientUuid, setPatientDataContext)
                    .then((patientData) => {
                        this.proceedPatientNosology(client, signal, activePatient.action, activePatient.patient, activePatient.nosology, activePatient.history);
                    });
            } else {
                this.proceedPatientNosology(client, signal, activePatient.action, activePatient.patient, activePatient.nosology, activePatient.history);
            }
        } else {
            // this.loadBaseline();
        }
    }

    proceedPatientNosology = (client, signal, action, patientId, nosologyId, patientHistory) => {
        // TODO: здесь вызов нового сервиса, историю не надо передавать, только пациента и нозологию
        const {patientDataContext, setPatientDataContext} = this.context;

        // console.error('+++ HYPER +++ proceedPatientNosology() +++');

        patientDataContext.patientDataContext.actualBaselineParameterStrict = patientDataContext.patientDataContext.baselineParameterStrict;

        loadStaticBaseline(client, signal, nosologyId, patientDataContext, setPatientDataContext, () => {
            // console.error('+++ HYPER +++ proceedPatientNosology() +++ patientHistory:', patientHistory);
            if (!!patientHistory) {
                const _nosologyData = getNosologyDataHistory(patientDataContext.patientDataContext, nosologyId);

                this.setState(prevState => ({
                    nosologyData: _nosologyData,
                }), () => {
                    const {nosologyData} = this.state;
                    this.onStep0Proceed(nosologyData, patientId, nosologyId, action, false);
                });
            } else {
                loadGetSelectedValuesNosology(client, signal, patientId, nosologyId)
                    .then((selectedValuesNosology) => {
                        this.setState(prevState => ({
                            nosologyData: {
                                ...prevState.nosologyData,
                                selectedValuesNosology: selectedValuesNosology,
                            }
                        }), () => {
                            const {nosologyData} = this.state;
                            this.onStep0Proceed(nosologyData, patientId, nosologyId, action, false);
                        });
                    });
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        // console.error('+++ HYPER +++ componentDidUpdate() +++ prevProps:', prevProps);
        // console.error('+++ HYPER +++ componentDidUpdate() +++ this.props:', this.props);
        // console.error('+++ HYPER +++ componentDidUpdate() +++ prevState:', prevState);
        // console.error('+++ HYPER +++ componentDidUpdate() +++ this.state:', this.state);
        if (prevProps.history.location.search !== prevProps.location.search) {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        // console.error('+++ HYPER +++ componentDidMount() +++');
        this.subscriptions.add(getShowPanel()
            .subscribe((showPanel) => {
                this.setState({
                    forestPlotView: showPanel,
                });
            }));

        this.setState({
            activeStep: 0,
            baseline: [],
        });
        this.setContextPanelView(null);
        this.activePatient = getURLSearchParams(this.props.location.search);
        this.loadPatientNosology(this.activePatient);
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.subscriptions.unsubscribe();
        updateShowPanel(null);
    }

    updateContextPanel = () => {
        const {steps, activeStep} = this.state;
        const {intl} = this.props;
        const {patientDataContext} = this.context;

        if ((this.activePatient.patientType === PatientType.PUBLIC && activeStep === 0) || activeStep === (steps.length - 1)) {
            this.setContextPanelView(null);
            return;
        }

        const {classes} = this.props;
        const selThType = this.getSelectTherapyType();
        const selThComb = this.getSelectTherapyComb();
        const thGroups = this.getTherapyGroupDrugs();

        let selDrugs;
        if (thGroups && thGroups.items.length > 0) {
            selDrugs = thGroups.items
                .filter(group => Boolean(group.selDrugId) && group.selDrugId > 0)
                .map(group => {
                    let selDrug = group.takeGroups
                        .flatMap(tg => tg)
                        .flatMap(tg => tg.drugs)
                        .find(d => d.id === group.selDrugId);
                    return `${group.shortName}: ${selDrug.name}`;
                });
        } else {
            selDrugs = [];
        }

        const subgroups = this.getSubgroups();
        const compGroups = this.getCompGroups();
        const possGroups = this.getPossGroups();

        // const scoreColor: string = this.getScoreColor();
        const scRiskColor: string = this.getScRiskColor();

        const selThTypeChartData = populateHPChart((selThType || {}).comps, intl, scRiskColor, GroupedBarChartColors.BLUE);
        const selThCombChartData = populateHPChart((selThComb || {}).comps, intl, scRiskColor, GroupedBarChartColors.BLUE);

        this.setContextPanelView(
            <Box>
                <Paper className={classes.contentBoxPanel}>
                    {this.getGradeView()}
                    {/* {this.getScoreView()} */}
                    {this.getScRiskView()}
                </Paper>

                <StaticBaseline staticBaseline={patientDataContext.patientDataContext.staticBaseline} />
                <ExpandableBaselineParameterStrictContextPanel needTopSpace={true || staticBaselineHasMain(patientDataContext.patientDataContext.staticBaseline)} baselineParameterStrict={patientDataContext.patientDataContext.actualBaselineParameterStrict} />

                {subgroups.items.length > 0 &&
                <Box>
                    {/*<PanelDivider/>*/}
                    <Paper className={classes.contentBoxPanel}>
                        <SelTextView align={"center"}>
                            <FormattedMessage id='label.hp.risc_factors' defaultMessage='Risc factors'/>
                        </SelTextView>
                        {subgroups.items
                            .map(it => (<SelTreatView key={it.id} treatName={it.name}/>))
                        }
                    </Paper>
                    <PanelDivider/>
                    <Paper className={classes.contentBoxPanel}>
                        <SelTextView align={"center"}>
                            <FormattedMessage id='label.hp.subgroup_sel'
                                              defaultMessage='Underlying concomitant disease'/>
                        </SelTextView>
                        {subgroups.items
                            .filter(it => it.id === subgroups.selId)
                            .map(it => (<SelTreatView key={it.id} treatName={it.name}/>))
                        }
                    </Paper>
                </Box>
                }

                {compGroups.length > 0 && getAbsoluteRelativeCondsView(intl.formatMessage({id: 'label.hp.comp_cont', defaultMessage: 'Compelling Contraindications'}), compGroups, true, 'RED', classes)}

                {possGroups.length > 0 && getAbsoluteRelativeCondsView(intl.formatMessage({id: 'label.hp.poss_cont', defaultMessage: 'Possible Contraindications'}), possGroups, true, 'ORANGE', classes)}

                {(selThType && selThTypeChartData) && (
                    <Box>
                        <PanelDivider/>
                        <Paper className={classes.contentBoxPanel}>
                            <SelTextView align={"center"}>
                                <FormattedMessage id='label.hp.therapy.sel_grade_score' defaultMessage='Select therapy'/>
                            </SelTextView>

                            <SelTreatView treatName={selThType.name}/>
                            {selThTypeChartData && !selThComb &&
                            <Grid item xs={12}>
                                <Box>
                                    <PanelDivider/>
                                    <GroupedBarChart chartData={selThTypeChartData}/>
                                </Box>
                            </Grid>
                            }
                            {/*
                            {selThType.comps.length > 0
                                ? (
                                    <Box>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell/>
                                                    <TableCell align="right">
                                                        <FormattedMessage id="label.risk.short" defaultMessage="Pl."/>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <FormattedMessage id="label.risk.therapy.short"
                                                                          defaultMessage="Th."/>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selThType.comps.map((comp, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell>{comp.name}</TableCell>
                                                        <TableCell align="right">{comp.riskPl}%</TableCell>
                                                        <TableCell align="right">{comp.riskTh}%</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Typography className={classes.riskShortDescr} color="textSecondary"
                                                    variant="body2">
                                            <FormattedMessage id="label.risk.short.descr"
                                                              defaultMessage="Pl. - initial risk, Th. - risk after therapy"/>
                                        </Typography>
                                        <PanelDivider/>
                                    </Box>
                                )
                                : (
                                    <Box className={classes.contentBoxCenter}>
                                        <Typography>
                                            <FormattedMessage id='message.data.empty'
                                                              defaultMessage='Data not available'/>
                                        </Typography>
                                    </Box>
                                )
                            }
*/}

                            {selThComb && (
                                <Box>
                                    <SelTreatView treatName={this.getTherapyCombNamesWithItem(selThComb)}/>
                                    {selThCombChartData &&
                                    <Grid item xs={12}>
                                        <Box>
                                            <PanelDivider/>
                                            <GroupedBarChart chartData={selThCombChartData}/>
                                        </Box>
                                    </Grid>
                                    }
                                    {/*
                                    {selThComb.comps.length > 0
                                        ? (
                                            <Box>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell/>
                                                            <TableCell align="right">
                                                                <FormattedMessage id="label.risk.short"
                                                                                  defaultMessage="Pl."/>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <FormattedMessage id="label.risk.therapy.short"
                                                                                  defaultMessage="Th."/>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {selThComb.comps.map((comp, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell>{comp.name}</TableCell>
                                                                <TableCell align="right">{comp.riskPl}%</TableCell>
                                                                <TableCell align="right">{comp.riskTh}%</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <Typography className={classes.riskShortDescr} color="textSecondary"
                                                            variant="body2">
                                                    <FormattedMessage id="label.risk.short.descr"
                                                                      defaultMessage="Pl. - initial risk, Th. - risk after therapy"/>
                                                </Typography>
                                            </Box>
                                        )
                                        : (
                                            <Box className={classes.contentBoxCenter}>
                                                <Typography>
                                                    <FormattedMessage id='message.data.empty'
                                                                      defaultMessage='Data not available'/>
                                                </Typography>
                                            </Box>
                                        )
                                    }
*/}
                                </Box>
                            )}
                        </Paper>
                    </Box>
                )}
                {selDrugs && selDrugs.length > 0 &&
                <Box>
                    <PanelDivider/>
                    <Paper className={classes.contentBoxPanel}>
                        <SelTextView align={"center"}>
                            <FormattedMessage id='label.hp.drugs.sel' defaultMessage='Select drugs'/>
                        </SelTextView>
                        {selDrugs.map((d, id) => (
                            <SelTreatView key={id}
                                          treatName={d}/>
                        ))}
                    </Paper>
                </Box>
                }
            </Box>
        );
    };

    setContextPanelView = (view) => {
        const {setContextContent} = this.context;

        if (setContextContent !== undefined) {
            if (Boolean(view)) {
                setContextContent({view: view});
            } else {
                setContextContent(null);
            }
        }
    };

    getAllSelectDrugs = () => {
        const thGroups = this.getTherapyGroupDrugs();

        if (thGroups && thGroups.items.length > 0) {
            return thGroups.items
                .filter(group => Boolean(group.selDrugId) && group.selDrugId > 0)
                .map(group => {
                    let selDrug = group.takeGroups
                        .flatMap(tg => tg)
                        .flatMap(tg => tg.drugs)
                        .find(d => d.id === group.selDrugId);
                    return selDrug.name;
                });
        }

        return [];
    };

/*
    getBaselineView = () => {
        const {baseline, nosologyData} = this.state;
        const {contextStaff, patientDataContext} = this.context;

        if (Boolean(baseline)) {
            return (<BaselineView
                baseline={baseline}
                nosologyData={nosologyData}
                getSelectVars={this.getSelectVars}
                isFieldShow={this.isFieldShow}
                readonly={isBaselineReadonly(contextStaff, this.activePatient.patientType, (patientDataContext || {}).patientDataContext)}
            />);
        } else {
            return (<Box/>);
        }
    };
*/

    getResultView = () => {
        return (<HPResultView
            getSelectTherapyType={this.getSelectTherapyType}
            getSelectTherapyComb={this.getSelectTherapyComb}
            getTherapyGroupDrugs={this.getTherapyGroupDrugs}
            getSelectVars={this.getSelectVars}
            getGrade={this.getGrade}
            getScore={this.getScore}
            getScRisk={this.getScRisk}
            getScRiskColor={this.getScRiskColor}
            getSubgroups={this.getSubgroups}
            getCompGroups={this.getCompGroups}
            getPossGroups={this.getPossGroups}
            getScoreView={this.getScoreView}
            getScoreColor={this.getScoreColor}
            getGradeView={this.getGradeView}
            getScRiskView={this.getScRiskView}
            getCombDrugs={this.getCombDrugs}
            getTherapyPresumably={this.getTherapyPresumably}
            loadTherapyCompare={this.loadTherapyCompare}
            // loadTherapyCompareStatistics={this.loadTherapyCompareStatistics}
            getTherapyCompare={this.getTherapyCompare}
            setTherapyCompare={this.setTherapyCompare}
            getTouched={this.getTouched}
            getThRecommend={this.getThRecommend}
            getFinalComb={this.getFinalComb}
            setDisableResultNext={this.setDisableResultNext}
            showComparisons={isShowComparisons(this.activePatient.patientType)}
            autoSave={this.autoSave}
            activePatient={this.activePatient}
        />);
    };

    getSelectCombView = () => {
        const {contextStaff} = this.context;
        return (<HPSelectCombView
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
            getTouched={this.getTouched}
            getParams={this.getParams}
            getTherapyComb={this.getTherapyComb}
            setTherapyComb={this.setTherapyComb}
            setSelectTherapyComb={this.setSelectTherapyComb}
            getSelectVars={this.getSelectVars}
            getSelectTherapyType={this.getSelectTherapyType}
            getTherapyCombNamesWithItem={this.getTherapyCombNamesWithItem}
            getSubgroupSelect={this.getSubgroupSelect}
            setForestPlotView={this.setForestPlotView}
            activePatient={this.activePatient}
        />);
    };

    getThRecommend = (): TherapyRecommendInHypertension => {
        const {nosologyData} = this.state;

        const bugDrug = { groupId: -1, drugId: -1 };
        const selDrugs = ((nosologyData.thGroupDrugs || {}).items || []).map(item => {
            let selDrugId = item.selDrugId;
            if (selDrugId === -1) return { groupId: item.id, drugId: -1 };
            let drugId = item.takeGroups.flatMap(takeGroup => takeGroup.drugs)
                .find(drug => drug.id === selDrugId)?.drugId;
            return drugId ? { groupId: item.id, drugId: drugId } : bugDrug;
        });

        const thRecommend: TherapyRecommendInHypertension = {
            groupDrugs: selDrugs,
            combId: (this.getSelectTherapyComb() || {}).id,
        };

        // Object.keys(nosologyData.steps.selDrugs)
        //     .forEach((selDrugKey) => {
        //         thRecommend.push(
        //             {
        //                 subTherapyId: nosologyData.steps.selThId,
        //                 selectDrugs: [nosologyData.steps.selDrugs[selDrugKey].id],
        //             }
        //         );
        //     });
        //
        // const thGroups = this.getTherapyGroupDrugs();
        //
        // let selDrugs = [];
        // if (thGroups && thGroups.items.length > 0) {
        //     thGroups.items
        //         .filter(group => Boolean(group.selDrugId) && group.selDrugId > 0)
        //         .map(group => {
        //             let selDrug = group.takeGroups
        //                 .flatMap(tg => tg)
        //                 .flatMap(tg => tg.drugs)
        //                 .find(d => d.id === group.selDrugId);
        //             return {[group.id]: selDrug};
        //         });
        // }

        // Object.keys(selectTherapyDrugs)
        //     .forEach(therapyDrugKey => thRecommend.push({
        //         subTherapyId: therapyDrugKey,
        //         selectDrugs: selectTherapyDrugs[therapyDrugKey],
        //     }));

        return thRecommend;
    };

    getFinalComb = (thRecommend, callback) => {
        const {client} = this.props;

        client.query({
            query: getHPFinalComb,
            variables: {
                patientId: Number(this.activePatient.patient) || 0,
                thRecommend: thRecommend,
            },
            context: {
                uri: config.options.server.hp_url + config.options.server.api_uri,
            },
        })
            .then(result => getQueryResult(result?.data, 'getHPFinalComb'))
            .then(result => {
                if (Boolean(callback)) {
                    callback(result);
                }
            });
    };

    loadTherapyCompare = (signal): Promise<TherapyCompareDto[]> => {
        const {client} = this.props;
        const therapyPresumably = this.getTherapyPresumably();
        const patientId = Number(this.activePatient.patient) || 0;

        if (therapyPresumably && therapyPresumably.thData) {
            return loadGetTherapyCompare(client, signal, NosologyType.HYPERTENSION, patientId, therapyPresumably.thData, this.getThRecommend())
                .then((data) => {
                    return new Promise((resolve, reject) => {
                        resolve(data);
                    });
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve([]);
            });
        }
    };

    loadTherapyCompareStatistics = (signal): Promise<TherapyCompareStatisticsDto[]> => {
        const {client} = this.props;
        const {contextStaff} = this.context;

        const statDataInInput: StatDataInInputDto = {
            excludePatientIds: [],
            excludeStaffIds: [],
            includePatientIds: [this.activePatient.patient],
            includeStaffIds: [contextStaff.id],
        }

        return loadGetTherapyCompareStatistics(client, signal, NosologyType.HYPERTENSION, statDataInInput)
            .then((data) => {
                return new Promise((resolve, reject) => {
                    resolve(data);
                });
            });
    };

    getTherapyCompare = () => {
        const {nosologyData} = this.state;
        return nosologyData.therapyCompare;
    };

    setTherapyCompare = (therapyCompare, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyCompare: therapyCompare,
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    getTherapyPresumably = () => {
        const {nosologyData} = this.state;
        return nosologyData.therapyPresumably;
    };

    setTherapyPresumably = (therapyPresumably, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                therapyPresumably: therapyPresumably,
                touched: true,
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    isTherapyPresumably = () => {
        const {nosologyData} = this.state;
        return isTherapyPresumably((nosologyData.therapyPresumably || {}).thData);
    };

    backSelectTherapyPresumably = () => {
        const {nosologyData} = this.state;

        delete nosologyData.therapyPresumably;

        this.showPrevPage();
    };

    getSelectTherapyPresumablyView = () => {
        const {contextStaff} = this.context;
        const {nosologyData} = this.state;

        return (<SelectTherapyPresumablyView
            nosologyData={nosologyData}
            nosology={NosologyType.HYPERTENSION}
            setTherapyPresumably={this.setTherapyPresumably}
            getTherapyPresumably={this.getTherapyPresumably}
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
        />);
    };

    getSelectDrugsView = () => {
        const {contextStaff} = this.context;
        return (<HPSelectDrugsView
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
            getTouched={this.getTouched}
            getSelectTherapyType={this.getSelectTherapyType}
            getTherapyGroupDrugs={this.getTherapyGroupDrugs}
            setTherapyGroupDrugs={this.setTherapyGroupDrugs}
            setDrugForTherapyGroup={this.setDrugForTherapyGroup}
            getSelectVars={this.getSelectVars}
            getSelectTherapyComb={this.getSelectTherapyComb}
            setCombDrugs={this.setCombDrugs}
            getCombDrugs={this.getCombDrugs}
            setForestPlotView={this.setForestPlotView}
            activePatient={this.activePatient}
        />);
    };

    getSelectTherapyView = () => {
        const {contextStaff} = this.context;
        return (<HPSelectTherapyView
            isPrescriptionReadonly={isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)}
            getSelectTherapyType={this.getSelectTherapyType}
            getTherapyTypes={this.getTherapyTypes}
            setTherapyType={this.setTherapyType}
            getSelectVars={this.getSelectVars}
            getReferents={this.getReferents}
            setSelectReferent={this.setSelectReferent}
            getTherapyCombNamesWithItem={this.getTherapyCombNamesWithItem}
            getSubgroups={this.getSubgroups}
            setSubgroupSelect={this.setSubgroupSelect}
            getTherapyParams={this.getTherapyParams}
            setForestPlotView={this.setForestPlotView}
            loadDataForestPlot={this.loadDataForestPlot}
            activePatient={this.activePatient}
        />);
    };

    isTherapyTypeSelect = () => {
        const {nosologyData} = this.state;
        return (Boolean(nosologyData.thTypes) && nosologyData.thTypes.selThId !== undefined) || this.needSkipToResultStep((nosologyData.thTypes || {}).selThId);
    };

    isTherapyCombSelect = () => {
        const {nosologyData} = this.state;
        return Boolean(nosologyData.thComb) && nosologyData.thComb.selCombId !== undefined;
    };

    isTherapyDrugsSelect = () => {
        const {nosologyData} = this.state;

        if (!Boolean(nosologyData.thGroupDrugs)) {
            return false;
        }

        const availableGroups = nosologyData.thGroupDrugs.items
            .filter(it => it.isAvailable === undefined || it.isAvailable);

        return availableGroups.filter(it => Boolean(it.selDrugId)).length === availableGroups.length;
    };

    backTherapyTypeSelect = () => {
        const {nosologyData} = this.state;
        if (Boolean(nosologyData.thTypes)) {
            if (nosologyData.touched) { // 2020.06.04
                delete nosologyData.thTypes.selThId;
            }
        }

        this.showPrevPage();
    };

    backTherapyCombSelect = () => {
        const {nosologyData} = this.state;
        if (Boolean(nosologyData.thComb)) {
            delete nosologyData.thComb.selCombId;
        }

        this.showPrevPage();
    };

    backTherapyDrugsSelect = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.thGroupDrugs) && Boolean(nosologyData.thGroupDrugs.items)) {
            nosologyData.thGroupDrugs.items.forEach(it => {
                delete it.selDrugId;
            });
        }

        this.showPrevPage();
    };

    getStepContent = () => {
        const {steps, activeStep} = this.state;

        if (activeStep < steps.length && Boolean(steps[activeStep].contentFunc)) {
            return steps[activeStep].contentFunc();
        } else {
            return (<Box/>);
        }
    };

    getTherapyTypes = () => {
        return this.state.nosologyData.thTypes; // 2020.06.04
    };

    getTherapyParams = () => {
        return this.state.nosologyData.params;
    };

    getSelectTherapyType = () => {
        const thTypes = this.getTherapyTypes();

        if (Boolean(thTypes) && Boolean(thTypes.selThId)) {
            return thTypes.items.find(th => th.id === thTypes.selThId);
        }

        return undefined;
    };

    needSkipToResultStep = (thTypeId) => {
        const {nosologyData} = this.state;
        const needSkipToResultStep = !!thTypeId && ((((nosologyData.thTypes || {}).items || []).find((thType) => thType.id === thTypeId) || {}).combinations || []).length === 0;
        return needSkipToResultStep;
    }

    setTherapyType = (thTypeId) => {
        const {nosologyData, activeStep, steps} = this.state;

        nosologyData.thTypes.selThId = thTypeId;

        enableAllStepsFromActiveStep(activeStep, steps);
        if (this.needSkipToResultStep(thTypeId)) {
            disableAllStepsFromActiveStepToResult(activeStep, steps);
        }

        this.setState(prevState => ({
            steps: steps,
            nosologyData: {
                ...prevState.nosologyData,
                thTypes: nosologyData.thTypes,
                touched: true,

                // TODO: resetting
                thGroups: undefined,
                thGroupDrugs: undefined,
                thComb: undefined,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    getReferents = () => {
        return this.state.nosologyData.referents;
    };

    setSelectReferent = (refId, callback) => {
        const {nosologyData} = this.state;

        nosologyData.referents.selId = refId;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                referents: nosologyData.referents,
            },
        }), () => {
            if (callback) {
                callback();
            }
        });
    };

    getTouched = () => {
        return this.state.nosologyData.touched;
    };

    setTouched = (touched) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                touched: touched,
            },
        }), () => {
        });
    };

    getParams = () => {
        return this.state.nosologyData.params;
    };

    getTherapyComb = () => {
        return this.state.nosologyData.thComb;
    };

    getTherapyCombNamesWithItem = (item) => {
        let groups = {};
        const gIds = new Set(item.combination.map(c => c.group)).values();
        for (let gId of gIds) {
            groups[gId] = item.combination.filter(c => c.group === gId);
        }

        let items = [];
        Object.keys(groups)
            .forEach(gId => {
                if (Number(gId) === 0) {
                    groups[gId].forEach(g => {
                        items.push(g.shortName);
                    });
                } else {
                    let cNames = item.combination.filter(c => c.group === Number(gId)).map(c => c.shortName).join(', ');
                    items.push(`(${cNames})`);
                }
            });

        return items.join(' + ');
    };

    setTherapyComb = (thComb, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                thComb: {
                    items: thComb,
                }
            },

        }), () => {
            if (callback) {
                callback();
            }
        });
    };

    getSelectTherapyComb = () => {
        const thComb = this.getTherapyComb();

        if (Boolean(thComb) && thComb.selCombId !== undefined) {
            return thComb.items[thComb.selCombId];
        }

        return undefined;
    };

    setSelectTherapyComb = (thCombId) => {
        const {nosologyData} = this.state;

        nosologyData.thComb.selCombId = thCombId;

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                thComb: nosologyData.thComb,
                touched: true,
            },
        }), () => {
            this.updateContextPanel();
        });
    };

    getTherapyGroupDrugs = () => {
        return this.state.nosologyData.thGroupDrugs;
    };

    setTherapyGroupDrugs = (thGroupDrugs, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                thGroupDrugs: {
                    items: thGroupDrugs,
                },
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    setCombDrugs = (combDrugs, callback) => {
        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                combDrugs: combDrugs,
            },
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    setForestPlotView = (forestPlotView, callback) => {
        updateExpanded(false);

        this.setState(prevState => ({
            forestPlotView: forestPlotView,
        }), () => {
            this.forceUpdate(() => {
                if (!!callback) {
                    callback();
                }
            });
        });
    };

    getCombDrugs = () => {
        return this.state.nosologyData.combDrugs;
    };

    setDrugForTherapyGroup = (groupId, drugId) => {
        const thGroups = this.getTherapyGroupDrugs();
        const selGroup = thGroups.items.find(thg => thg.id === groupId);

        if (selGroup) {
            selGroup.selDrugId = drugId;

            this.setState(prevState => ({
                nosologyData: {
                    ...prevState.nosologyData,
                    thGroups: thGroups,
                    touched: true,
                },
            }), () => {
                this.updateContextPanel();
            });
        }
    };

    getSelectVars = () => {
        const {
            nosologyData,
        } = this.state;

        return nosologyData.selectedValuesNosology;
    };

    isFieldShow = (field) => {
        const selVars = this.getSelectVars();

        if (Boolean(field.opt) && Boolean(field.opt.isShow) && Boolean(field.opt.isShow.selVars)) {
            const intr = selVars.filter(v => field.opt.isShow.selVars.includes(v));
            return intr.length === field.opt.isShow.selVars.length;
        }

        return true;
    };

    getGrade = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.grade)) {
            return nosologyData.grade;
        } else {
            return 0;
        }
    };

    getScore = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.score)) {
            return nosologyData.score;
        } else {
            return 0;
        }
    };

    getScRisk = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.scRisk)) {
            return nosologyData.scRisk;
        } else {
            return 0;
        }
    };

    getScoreView = (isLabel = true) => {
        const risk = this.getScRisk();
        const score = this.getScore();

        if ((risk === 3 && score < 5)
            || (risk === 4 && score < 10)
            || score === 0) {
            return (<Box />);
        }

        const {classes, intl} = this.props;
        const scoreLabel =
            (<Typography className={classes.labelGradeScore}
                         component={'span'}>
                {intl.formatMessage({id: 'label.hp.score', defaultMessage: 'Score'})}:
            </Typography>);

        let scoreText = score + '%';
        let scoreTextColor;

        if (score >= 0 && score < 2) {
            scoreTextColor = classes.lowRiskText;
        } else if (score >= 2 && score < 5) {
            scoreTextColor = classes.highRiskText;
        } else if (score >= 5 && score < 15) {
            scoreTextColor = classes.vhighRiskText;
        } else {
            scoreTextColor = classes.vvhighRiskText;
        }

        if (isLabel) {
            return (
                <Typography className={scoreTextColor}>
                    {scoreLabel} {scoreText}
                </Typography>
            );
        } else {
            return (
                <Typography className={scoreTextColor}>
                    {scoreText}
                </Typography>
            );
        }
    };

    getGradeView = (isLabel = true) => {
        const {classes, intl} = this.props;
        const grade = this.getGrade();
        const gradeClass = grade === 1 ? classes.grade1 : grade === 2 ? classes.grade2 : classes.grade3;
        const gradeLabel =
            (<Typography className={classes.labelGradeScore}
                // variant="body2"
                         component={'span'}
            >
                {intl.formatMessage({id: 'label.hp.grade', defaultMessage: 'Grade'})}:
            </Typography>);

        return (
            <Typography className={gradeClass}>
                {isLabel ? gradeLabel : ''} {`${grade}`}
            </Typography>
        );
    };

    getScRiskView = (isLabel = true) => {
        const {classes, intl} = this.props;
        const scRisk = this.getScRisk();
        const scoreLabel =
            (<Typography className={classes.labelGradeScore}
                         component={'span'}
            >
                {intl.formatMessage({id: 'label.hp.risk', defaultMessage: 'Systematic coronary risk'})}:
            </Typography>);

        let riskText;
        let riskTextColor;

        switch (scRisk) {
            case 0:
                return null;
            case 1:
                riskText = intl.formatMessage({id: 'label.hp.risk.low', defaultMessage: 'Low risk'});
                riskTextColor = classes.lowRiskText;
                break;
            case 2:
                riskText = intl.formatMessage({id: 'label.hp.risk.moderate', defaultMessage: 'Moderate risk'});
                riskTextColor = classes.highRiskText;
                break;
            case 3:
                riskText = intl.formatMessage({id: 'label.hp.risk.high', defaultMessage: 'High risk'});
                riskTextColor = classes.vhighRiskText;
                break;
            case 4:
                riskText = intl.formatMessage({id: 'label.hp.risk.vhigh', defaultMessage: 'V-High risk'});
                riskTextColor = classes.vvhighRiskText;
                break;
            case 5:
                riskText = intl.formatMessage({id: 'label.hp.risk.extreme', defaultMessage: 'Extreme risk'});
                riskTextColor = classes.vvhighRiskText;
                break;
            default:
                return null;
        }

        if (isLabel) {
            return (
                <Typography className={riskTextColor}>
                    {scoreLabel} {riskText}
                </Typography>
            );
        } else {
            return (
                <Typography className={riskTextColor}>
                    {/* {`${score} (${riskText})`} */}
                    {riskText}
                </Typography>
            );
        }
    };

    getFinalCombView = (finalComb) => {
        const {classes, intl} = this.props;
        const label = intl.formatMessage({id: 'label.hp.therapy.combination.final', defaultMessage: 'Combiantion by drugs'});
        const isFinalCombNotEmpty = finalComb && (finalComb.id !== -1);

        if (finalComb) {
            return (
                <>{isFinalCombNotEmpty
                    ?
                    (<Grid container direction={"row"} className={classes.resultParam}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.resultParamName}>{label}</Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                              className={classes.resultParamValue}>{finalComb.name}</Grid>
                    </Grid>)
                    :
                    (<Grid container direction={"row"} className={classes.resultParam}>
                        <TypographySubtitle component={'span'}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={((finalComb || {}).message || {}).payload}/>
                        </TypographySubtitle>
                    </Grid>)
                }</>);
        } else {
            return (<></>);
        }
    };

    getScRiskColor = () => {
        const scRisk = this.getScRisk();

        return riskTextColors[scRisk];
    };

    getScoreColor = () => {
        const score = this.getScore();
        let scoreColor;

        if (score >= 0 && score < 5) {
            scoreColor = 'green';
        } else if (score >= 5 && score < 11) {
            scoreColor = 'orange';
        } else if (score >= 11 && score < 15) {
            scoreColor = 'red';
        } else {
            scoreColor = 'darkred';
        }

        return scoreColor;
    };

    getSubgroups = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.subgroups) && Boolean(nosologyData.subgroups.items)) {
            return nosologyData.subgroups;
        } else {
            return {
                items: [],
            };
        }
    };

    setSubgroupSelect = (e, callback) => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.subgroups)) {
            // if (nosologyData.touched) {
            nosologyData.subgroups.selId = Number(e.target.value);
            // }
            // nosologyData.subgroups.selId = Number(e.target.value); // 2020.06.04
        }

        this.setState(prevState => ({
            nosologyData: {
                ...prevState.nosologyData,
                subgroups: nosologyData.subgroups,
                thGroups: undefined,
                thGroupDrugs: undefined,
                touched: true,
            },
        }), () => {
            this.getCalcHPBaseline(Number(this.activePatient.patient) || 0, undefined, () => {
                this.updateContextPanel();
                if (Boolean(callback)) {
                    callback();
                }
            });
        });
    };

    getSubgroupSelect = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.subgroups) && Boolean(nosologyData.subgroups.selId)) {
            return nosologyData.subgroups.items.find(sg => sg.id === nosologyData.subgroups.selId);
        }

        return undefined;
    };

    getCompGroups = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.compGroups) && nosologyData.compGroups.length > 0) {
            return nosologyData.compGroups;
        } else {
            return [];
        }
    };

    getPossGroups = () => {
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.possGroups) && nosologyData.possGroups.length > 0) {
            return nosologyData.possGroups;
        } else {
            return [];
        }
    };

    showPrevPage = () => {
        const {activeStep, steps} = this.state;

        this.setForestPlotView();

        if (activeStep > 0) {
            this.setState({
                // activeStep: activeStep - 1,
                activeStep: getPrevStepNumber(activeStep, steps),
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    onRestart = () => {
        this.setState({
            allDataLoaded: false,
            activeStep: 0,
            baseline: [],

            baselineDialog: {
                open: false,
            },
            nosologyData: {
                touched: true,
            },
        }, () => {
            const {setNosologyDataContext} = this.context;
            const {nosologyData} = this.state;
            setNosologyDataContext({
                    nosologyData: nosologyData
                },
                () => {
                    const {history} = this.props;
                    const queryParams = new URLSearchParams(history.location.search);
                    if (queryParams.get('action')) {
                        queryParams.delete('action');
                        history.replace({
                            search: queryParams.toString()
                        });
                    } else {
                        this.componentDidMount();
                    }
                });
        });
    };

    onBack = () => {
        const {activeStep, steps} = this.state;

        // TODO: +++2021.12.03+++
        //  this.setForestPlotView();

        if (Boolean(steps[activeStep].backFunc)) {
            this.setForestPlotView();
            steps[activeStep].backFunc();
        } else if (activeStep > 0) {
            this.showPrevPage();
        }
    };

    showNextPage = () => {
        const {activeStep, steps} = this.state;

        this.setForestPlotView();

        if (activeStep < steps.length - 1) {
            this.setState({
                // activeStep: activeStep + 1,
                activeStep: getNextStepNumber(activeStep, steps),
                allDataLoaded: true,
            }, () => {
                this.updateContextPanel();
            });
        }
    };

    onNext = () => {
        const {activeStep, steps} = this.state;

        // TODO: +++2021.12.03+++
        //  this.setForestPlotView();

        if (Boolean(steps[activeStep].nextFunc)) {
            this.setForestPlotView();
            steps[activeStep].nextFunc();
        } else {
            this.showNextPage();
        }
    };

    isNextAvailable = () => {
        const {steps, activeStep} = this.state;

        if (Boolean(steps[activeStep].isNextFunc)) {
            return steps[activeStep].isNextFunc();
        }

        return activeStep < (steps.length - 1);
    };

    getNextLabel = () => {
        const {steps, activeStep, nosologyData} = this.state;
        const {intl} = this.props;
        const {contextStaff} = this.context;

        return getNextLabel(steps, activeStep, intl, contextStaff, this.activePatient, nosologyData);
    };

    getCalcHPBaseline = (patientId, nosologyId, callback) => {
        const {client} = this.props;
        const {signal} = this.abortController;
        const {nosologyData} = this.state;

        client.query({
            query: calcHPBaseline,
            variables: {
                patientId: patientId, // || Number(this.activePatient.patient) || 0,
                inSubgroupId: nosologyData?.subgroups?.selId || undefined,
            },
            context: {
                uri: config.options.server.hp_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'calcHPBaseline'))
            .then(result => {
                const newSubgroups = {
                    items: ((result || {}).subgroups || []).sort((s1, s2) => s1.priority - s2.priority),
                };

                if (newSubgroups.items.length > 0) {
                    newSubgroups.selId = nosologyData?.subgroups?.selId || newSubgroups.items[0].id;
                }

                this.setState(prevState => ({
                    nosologyData: {
                        ...prevState.nosologyData,
                        grade: (result || {}).grade,
                        score: (result || {}).score,
                        scRisk: (result || {}).scRisk,
                        subgroups: newSubgroups,
                        compGroups: (result || {}).compGroups,
                        possGroups: (result || {}).possGroups,
                        params: (result || {}).params,
                        articleIds: (result || {}).articleIds,
                        thTypes: {
                            items: (result || {}).thTypes,
                        },
                        referents: {
                            selId: (result || {}).referents?.length > 0 ? (result || {}).referents[0]?.id : 0,
                            items: (result || {}).referents,
                        },
                    },
                }), () => {
                    if (Boolean(callback)) {
                        callback();
                    }
                });
            });
    };

    onStep0Proceed = (nosologyData, patientId, nosologyId, action, showNextPage: boolean = true) => {
        if (nosologyData && !nosologyData.touched) {
            this.onStep0ProceedSetState(showNextPage, action);
        } else {
            this.getCalcHPBaseline(patientId, nosologyId, () => {
                this.onStep0ProceedSetState(showNextPage, action);
            });
        }
    }

    onStep0ProceedSetState = (showNextPage, action) => {
        const {
            steps,
            activeStep,
        } = this.state;

        if (showNextPage) {
            this.showNextPage();
        } else {
            this.setState({
                activeStep: action === 'RESULT' ? steps.length - 1 : activeStep,
                allDataLoaded: true,
            }, () => {
                this.updateContextPanel();
            });
        }
    }

/* unused method
    onStep0 = () => {
        const {nosologyData} = this.state;
        const {contextStaff} = this.context;

        const access = getAccess((contextStaff || {}).role, this.activePatient.patientType);
        if (onlySaveBaseline(this.activePatient, access)) {
            const {client} = this.props;
            const {signal} = this.abortController;
            callSavePatient(client, signal, this.getSelectVars(), this.activePatient.patient, this.activePatient.patientName, this.activePatient.patientType)
                .then(saveResponse => {
                    this.finalStepRedirect(this.activePatient);
                });
        } else {
            this.onStep0Proceed(nosologyData, this.activePatient.patient, this.activePatient.nosology, this.activePatient.action);
        }
    };
*/

    onStepFinal = () => {
        const {contextStaff} = this.context;

        if (this.activePatient.patient && !isPrescriptionReadonly((contextStaff || {}).role, this.activePatient.patientType)) {
            const {nosologyData} = this.state;

            if (nosologyData.touched) {
                this.autoSave()
                    .then(saveResponse => {
                        this.finalStepRedirect(this.activePatient);
                    });
            } else {
                this.finalStepRedirect(this.activePatient);
            }
        } else {
            this.finalStepRedirect(this.activePatient);
        }
    };

    autoSave = (): Promise<any> => {
        const {nosologyData} = this.state;
        const {patientDataContext} = this.context;
        if (this.activePatient.action !== 'RESULT' || nosologyData.touched) {
            const {client} = this.props;
            const {signal} = this.abortController;
            const prescriptions: [] = [];
            prescriptions.push({
                type: PrescriptionType.DRUGS,
                // ids: thGroups.items.map((item) => item.selDrugId),
                names: this.getAllSelectDrugs(),
            });
            prescriptions.push({
                type: PrescriptionType.DEVICES,
                ids: [],
            });
            nosologyData.prescriptions = prescriptions;
            const parameters: ParametersForBaselineDto[] = getParametersForBaselineDto(patientDataContext.patientDataContext.parameters);

            return savePatientNosology(client, signal, nosologyData, parameters, this.activePatient.patient, this.activePatient.nosology)
                .then(saveResponse => {
                    this.setDisableResultNext(false);
                    return saveResponse;
                });
        } else {
            return new Promise((resolve, reject) => {
                this.setDisableResultNext(false);
                resolve();
            });
        }
    };

    setDisableResultNext = (disableResultNext: boolean = false) => {
        this.setState({disableResultNext: disableResultNext});
    }

    finalStepRedirect = (activePatient, needGoNextNosology = true) => {
        this.setContextPanelView(null);
        if (this.activePatient.patient) {
            const {signal} = this.abortController;
            const {client, history} = this.props;
            const {setContextPatient, setPatientDataContext} = this.context;
            finalStepNosologyRedirect(signal, client, setContextPatient, history, this.activePatient, setPatientDataContext, needGoNextNosology);
        } else {
            this.setState({
                activeStep: 0,
                baseline: undefined,
                nosologyData: {
                    touched: true,
                }
            }, () => {
                window.scrollTo(0, 0);
                // TODO: раньше загружали, а сейчас мы сюда вовсе не попадаем
                // this.loadBaseline();
            });
        }
    }

    showError = (message) => {
        const {showNotify} = this.context;
        if (showNotify) {
            showNotify(message, 'error');
        }
    };

    articlesPanel = () => {
        const {classes} = this.props;
        const {nosologyData} = this.state;

        if (Boolean(nosologyData.articleIds) && nosologyData.articleIds.length > 0) {
            return (
                <Box className={classes.contentBoxTopSpace}>
                    <ArticlesPanel>
                        <ArticlesPanelSummary
                            expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h6"}>
                                <FormattedMessage id={'label.articles'} defaultMessage={'Articles'}/>
                            </Typography>
                        </ArticlesPanelSummary>
                        <AccordionDetails>
                            <Box>
                                <ArticlesListEx articleIds={nosologyData.articleIds}/>
                            </Box>
                        </AccordionDetails>
                    </ArticlesPanel>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    getForestPlotView = () => {
        const {intl} = this.props;

        const forestPlotView = (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                setExpandedCharts={() => {}}
            >
            </ExpandChartsPanel>
        );

        return forestPlotView;
    };

    forestPlotPanel = () => {
        const {classes} = this.props;
        const {forestPlotView, activeStep, steps} = this.state;
        // console.error('+++ Hyper +++ forestPlotPanel() +++ forestPlotView:', forestPlotView);
        if (forestPlotView && (activeStep + 1 < steps.length)) {
        // if ((activeStep + 1 < steps.length)) {
            return (
                <Box className={classes.contentBoxTopSpace}>
                    {/*{forestPlotView}*/}
                    {this.getForestPlotView()}
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    setBaselineDialogOpen = (open: boolean) => {
        this.setState({
            baselineDialog: {
                open: open,
            },
        });
    }

    render() {
        const {classes, intl} = this.props;
        const {allDataLoaded, activeStep, steps, baseline, nosologyData, baselineDialog, disableResultNext} = this.state;
        const {
            patientDataContext,
            contextContent,
            contextStaff,
        } = this.context;
        const step = steps[activeStep];
        // console.error('+++ render() +++ activeStep:', activeStep);
        // console.error('+++ render() +++ steps:', steps);
        // console.error('+++ render() +++ steps[activeStep]:', steps[activeStep]);

        if (!allDataLoaded) {
            return <Box />;
        }

        return (
            <Box>

                <Paper className={classes.contentBox}>
                    {Boolean(this.activePatient.patientName) &&
                    <React.Fragment>
                        <Button color={"primary"} variant={"outlined"} className={classes.returnPatientButton}
                                // onClick={() => returnToPatient(this.activePatient, this.props.history)}>
                                onClick={() => this.finalStepRedirect(this.activePatient, false)}>
                            <KeyboardBackspaceRoundedIcon className={this.props.classes.iconButton}/>
                            {this.activePatient.patientName}
                        </Button>
                        <Button color={"primary"} variant={"outlined"} className={classes.returnPatientButton}
                                onClick={() => this.setBaselineDialogOpen(true)}>
                            {intl.formatMessage({
                                id: 'label.patient.baseline',
                                defaultMessage: 'Baseline data'
                            })}
                        </Button>
                        {contextContent &&
                        <Button color={"primary"} variant={"outlined"}
                                className={`${classes.returnPatientButton} ${classes.contextBarButtonHide}`}
                                onClick={() => this.context.setContextContentShow(true)}>
                            {intl.formatMessage({
                                id: 'label.nosology.context_panel',
                                defaultMessage: 'Информационная панель'
                            })}
                        </Button>
                        }
                    </React.Fragment>
                    }

                    <Typography variant='h6'>
                        {`${intl.formatMessage({
                            id: 'label.step',
                            defaultMessage: 'Step'
                        })} ${activeStep + 1}/${steps.length}: ${step.title}`}
                    </Typography>
                    {Boolean(step.descr) && <Typography>{step.descr}</Typography>}
                </Paper>

                <PanelDivider/>

                <Paper>
                    {this.getStepContent()}
                </Paper>

                {this.forestPlotPanel()}

                {this.articlesPanel()}

                <PanelDivider/>

                <Paper>
                    <Grid container className={classes.contentBox}>
                        <Grid item xs={12}>
                            <Grid container spacing={3} justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        onClick={this.onBack}
                                        disabled={isBackDisable(activeStep, steps, disableResultNext)}>
                                        <FormattedMessage id={'label.back'} defaultMessage={'Back'}/>
                                    </Button>
                                </Grid>
                                {activeStep === (steps.length - 1) && this.activePatient.patient &&
                                <Grid item>
                                    <SaveOrRestartButton
                                        onSave={this.onNext}
                                        saveLabel={this.getNextLabel()}
                                        onRestart={this.onRestart}
                                        disableResultNext={isDisableResultNext(this.activePatient.action, nosologyData.touched, disableResultNext)}
                                        intl={intl}
                                    />
                                </Grid>
                                }
                                {(activeStep !== (steps.length - 1) || !this.activePatient.patient) &&
                                <Grid item>
                                    <Button
                                        onClick={this.onNext}
                                        disabled={!this.isNextAvailable()}
                                        color='primary'
                                        variant='contained'>
                                        {this.getNextLabel()}
                                    </Button>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                {
                    baselineDialog.open &&
                    <BaselineDialog
                        open={baselineDialog.open}
                        setOpen={this.setBaselineDialogOpen}
                        classes={classes}
                        intl={intl}
                        patientData={patientDataContext.patientDataContext}
                        contextStaff={contextStaff}
                        baseline={baseline}
                        nosologyData={nosologyData}
                        isFieldShow={this.isFieldShow}
                        patientDrugs={patientDrugs}
                    />
                }
            </Box>
        );
    }
}

Hyper.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

Hyper.contextTypes = {
    setContextContent: PropTypes.func,
    showNotify: PropTypes.func,
    nosologyDataContext: PropTypes.object,
    setNosologyDataContext: PropTypes.func,
    patientDataContext: PropTypes.object,
    setPatientDataContext: PropTypes.func,
    setContextPatient: PropTypes.func,
    contextStaff: PropTypes.object,
    contextContent: PropTypes.object,
    setContextContentShow: PropTypes.func,
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withApollo,
    injectIntl
)(Hyper);
