import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ProfileView from "./ProfileView";
import { FormattedMessage } from "react-intl";

const styles = theme => ({
  ...mainStyles(theme),
});

class ProfileViewDlg extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onClose = () => {
    this.props.onDialogClose();
  };

  render() {
    const { isShow, profile } = this.props;

    return (
      <Dialog
        fullWidth
        // onEscapeKeyDown={this.onClose}
        // onBackdropClick={this.onClose}
        onClose={this.onClose}
        open={isShow}
        aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title">
          <FormattedMessage id='label.information' defaultMessage='Information' />
        </DialogTitle>
        <DialogContent>
          <ProfileView profile={profile} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.onClose}>
            <FormattedMessage id='label.close' defaultMessage='Close' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ProfileViewDlg.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isShow: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProfileViewDlg);
