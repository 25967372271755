import React from "react";
import PropTypes from 'prop-types';
import {
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    Typography,
    Grid, Box,
} from "@material-ui/core";
import SelTextView from "../SelTextView";
import PanelDivider from "../PanelDivider";
import {FormattedMessage, injectIntl} from "react-intl";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import {loadGetDrugs, loadGetForestPlotCharts, loadGetPScore} from "../../query/BreastCancerApollo";
import type {DrugGroup} from "../../models/breast-cancer";
import type {ServiceMessage} from "../../const";
import {getServiceMessages} from "../../utils";
import {MessageType, RecommendType} from "../../const";
import {InfoHelp} from "../common/InfoHelp";
import EffSafChart from "../hp/EffSafChart";
import ExpandChartsPanel from "../baseline/ExpandChartsPanel";
import config from "../../config";

const styles = theme => ({
    ...mainStyles(theme),
});

class BreastCancerSelectDrugs extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        this.loadDrugs();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadDrugs = () => {
        const {client, nosologyData} = this.props;
        const {signal} = this.abortController;

        const subtritmantIds: [] = Object.values(nosologyData.selectedTreatments) || [];
        const parameterIds: [] = nosologyData.selectedValuesNosology || [];

        loadGetDrugs(client, signal, subtritmantIds, parameterIds)
            .then((drugGroups) => {
                const drugGroupsIds = drugGroups.map((drugGroup) => drugGroup.id);
                this.setState({
                    drugGroups: drugGroups,
                    drugGroupsIds: drugGroupsIds,
                });
                this.loadAllCharts();
            });
    }

    loadAllCharts = () => {
        this.loadGetPScore();
        this.loadForestPlotCharts();
    }

    loadGetPScore = () => {
        const {drugGroupsIds} = this.state;
        const {client} = this.props;
        const {signal} = this.abortController;
        loadGetPScore(client, signal, drugGroupsIds)
            .then((pscore) => {
                this.setState({
                    pscore: pscore,
                });
            });
    }

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    setForestPlotView = (forestPlotView, callback) => {
        this.setState(prevState => ({
            forestPlotView: forestPlotView,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    forestPlotView = () => {
        const {
            intl,
        } = this.props;
        const { charts } = this.state;

        const _charts = charts.map((chart) => {
            chart.path = config.options.server.breast_cancer_url + '/chart/' + chart.path;
            return chart;
        });

        const forestPlotView = (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                charts={_charts}
                loadCharts={this.loadForestPlotCharts}
                needUpdate={new Date().getTime()}
                setExpandedCharts={this.setExpandedCharts}
            >
            </ExpandChartsPanel>
        );

        this.setForestPlotView(forestPlotView);
    };

    loadForestPlotCharts = (callback) => {
        const {signal} = this.abortController;
        const {drugGroupsIds} = this.state;

        const {
            client,
            patientId,
        } = this.props;

        loadGetForestPlotCharts(client, signal, drugGroupsIds, patientId)
            .then((charts) => {
                this.setState({
                    charts: charts || [],
                }, () => {
                    this.forestPlotView();
                    if (!!callback) {
                        callback();
                    }
                });
            });
    };

    onDrugGroupChange = (drugGroup: DrugGroup) => (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        this.setSelDrugGroup(drugGroup);
    };

    setSelDrugGroup = (drugGroup: DrugGroup) => {
        const {setSelDrugGroup} = this.props;

        setSelDrugGroup(drugGroup);
    }

    drugsView = () => {
        const {
            classes,
            isPrescriptionReadonly,
            nosologyData,
        } = this.props;
        const {
            drugGroups,
        } = this.state;

        return (
            <Paper className={classes.contentBox + (isPrescriptionReadonly ? (' ' + classes.disabled) : '')}
                   variant={"outlined"}>
                <SelTextView>
                    <FormattedMessage id='label.bc.drug_group.select' defaultMessage='Select drug group:'/>
                </SelTextView>
                <PanelDivider/>
                {drugGroups &&
                <Grid container>
                    <Grid item xs={6}>
                        {/*{tg.name && <SelTextView>{tg.name}</SelTextView>}*/}
                        <FormControl component='fieldset'>
                            {
                                drugGroups
                                    .map((drugGroup) => {
                                        const serviceInfoText: ServiceMessage = getServiceMessages(drugGroup.messages, MessageType.INFO);
                                        return (
                                        <Grid container direction={"row"} key={drugGroup.id}>
                                            <Grid item>
                                                <FormControlLabel
                                                    key={drugGroup.id}
                                                    label={
                                                        <Typography
                                                        color={drugGroup.isBlocked ? 'textSecondary' : 'textPrimary'}>
                                                        {drugGroup.name}
                                                        </Typography>
                                                    }
                                                    onChange={this.onDrugGroupChange(drugGroup)}
                                                    control={<Radio checked={drugGroup.id === nosologyData?.selDrugGroup?.id}
                                                                       value={drugGroup.id} color={"primary"}/>}
                                                    />
                                            </Grid>
                                            {(serviceInfoText.showService) &&
                                            <Grid item>
                                                <InfoHelp texts={serviceInfoText.messages}/>
                                            </Grid>
                                            }
                                        </Grid>
                                        )
                                    })
                            }
                        </FormControl>
                    </Grid>
                </Grid>
                }
            </Paper>
        );
    };

    effSafView = () => {
        const { classes } = this.props;
        const { pscore } = this.state;

        if (pscore?.points?.length > 0) {
            return (
                <Box>
                    <PanelDivider />
                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                        <EffSafChart
                            points={pscore.points}
                            // title={intl.formatMessage({ id: "label.effsaf", defaultMessage: "Efficacy/Safety"})}
                            title={pscore.xName + '/' + pscore.yName}
                            xaxis={pscore.xName}
                            yaxis={pscore.yName}
                        />
                    </Paper>
                </Box>
            );
        } else {
            return (
                <Box>
                    <PanelDivider />
                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                        <Typography>
                            <FormattedMessage id="message.data.empty" defaultMessage="Data not available" />
                        </Typography>
                    </Paper>
                </Box>
            );
        }
    };

    forestPlotPanel = () => {
        const {classes} = this.props;
        const {forestPlotView} = this.state;

        return (
            <Box className={classes.contentBoxTopSpace}>
                {forestPlotView}
            </Box>
        );
    };

    render() {
        const {
            classes,
            isPrescriptionReadonly,
            getRecomentTextsView,
        } = this.props;

        return (
            <React.Fragment>
                <Paper className={isPrescriptionReadonly ? classes.disabled : ''}>
                    {getRecomentTextsView && getRecomentTextsView(RecommendType.GUIDELINES)}
                    <PanelDivider/>

                    <Grid container className={classes.contentBox}>
                        <Grid item xs={12}>
                    {/*<Box>*/}
                        {/*<Box className={classes.contentBox}>*/}
                            {this.drugsView()}
                            {this.effSafView()}
                        {/*</Box>*/}
                    {/*</Box>*/}
                        </Grid>
                    </Grid>
                </Paper>
                {this.forestPlotPanel()}
            </React.Fragment>
        );
    }
}

BreastCancerSelectDrugs.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    // getSteps: PropTypes.func.isRequired,
    // getTouched: PropTypes.func.isRequired,
    // getRecomentTextsView: PropTypes.func,
    // getTherapyGroupDrugs: PropTypes.func.isRequired,
    // setTherapyGroupDrugs: PropTypes.func.isRequired,
    // getStatistics: PropTypes.func.isRequired,
    // setStatistics: PropTypes.func.isRequired,
    // setDrugForTherapyGroup: PropTypes.func.isRequired,
    // getSelectTherapyComb: PropTypes.func.isRequired,
    getSelDrugGroup: PropTypes.func.isRequired,
    setSelDrugGroup: PropTypes.func.isRequired,
    setForestPlotView: PropTypes.func.isRequired,
    patientId: PropTypes.number.isRequired,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(BreastCancerSelectDrugs);
