import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import Box from "@material-ui/core/Box";
import Markdown from '../common/Markdown';
import {FormattedMessage} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    manualDialog: {
        // '$container': {
        //     '$root': {
        //         minHeight: 'calc(100% - 64px)',
        //     }
        // }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const StyledDialog = withStyles(theme => ({
    root: {
        '& > .MuiDialog-container > .MuiPaper-root': {
            minHeight: 'calc(100% - 48px)',
            height: 'calc(100% - 48px)',
            '& > .MuiDialogContent-root > .react-pdf__Document': {
                height: '100%',
                width: 'auto',
                '& > .react-pdf__Page': {
                    width: 'auto',
                    height: '100%',
                    '& > .react-pdf__Page__canvas, .react-pdf__Page__textContent': {
                        height: '100% !important',
                        width: 'auto !important',
                        margin: 'auto',
                    }
                }
            }
        },
    },
}))(Dialog);

const ManualDialog = (props) => {
    const classes = useStyles();
    const { open, setOpen, contents } = props;
    const [pageNumber, setPageNumber] = React.useState(1);
    const [
        numPages,
        // setNumPages
    ] = React.useState(contents ? contents.length : null);
    const [content, setContent] = React.useState(null);

    const loadContent = () => {
        if (!content) {
            fetch(`${contents[pageNumber - 1]}`, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
            })
                .then(response => response.text())
                .then((content) => {
                    setContent(content);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

/*
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }
*/

    const handleKeyPress = (event) => {
        if ( event.key === "ArrowRight" ) {
            next();
        }
        if ( event.key === "ArrowLeft" ) {
            prev();
        }
        if ( event.key === "Escape" ) {
            setOpen(false);
        }
        if ( event.key === "Home" ) {
            // setPageNumber(1);
            prev(1);
        }
        if ( event.key === "End" ) {
            // setPageNumber(numPages);
            next(numPages);
        }
    }

    const prev = (page: number) => {
        if (pageNumber !== 1) {
            setPageNumber(page ? page : pageNumber - 1);
            loadContent();
        }
    }

    const next = (page: number) => {
        if (pageNumber !== numPages) {
            setPageNumber(page ? page : pageNumber + 1);
            loadContent();
        }
    }

    loadContent();

    return (
        <StyledDialog
            fullWidth
            maxWidth={'xl'}
            // onEscapeKeyDown={() => setOpen(false)}
            // onBackdropClick={() => setOpen(false)}
            onKeyDown={handleKeyPress}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="manual-dialog"
            className={classes.manualDialog}
        >
            <DialogTitle id="manual-dialog">
                <FormattedMessage id={'label.manual.title'} defaultMessage={'Instruction'} />
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {/*<DialogContent>{children}</DialogContent>*/}
            <DialogContent
            >
                {/*{content && <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={content} />}*/}
                {content && <Markdown>{content}</Markdown>}
                {/*{content && <div dangerouslySetInnerHTML={{__html: content}}></div>}*/}

                {/*<Document*/}
                {/*    file={pdf}*/}
                {/*    onLoadSuccess={onDocumentLoadSuccess}*/}
                {/*    options={{*/}
                {/*        cMapUrl: 'cmaps/',*/}
                {/*        cMapPacked: true,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Page pageNumber={pageNumber} />*/}
                {/*</Document>*/}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    <FormattedMessage id={'label.close'} defaultMessage={'Close'} />
                </Button>
                <div className={classes.grow} />
                <Button
                    disabled={pageNumber === 1}
                    onClick={() => prev()}
                    color="primary"
                    // variant={'contained'}
                >
                    <FormattedMessage id={'label.manual.back'} defaultMessage={'Back'} />
                </Button>
                <Box>{pageNumber} / {numPages}</Box>
                <Button
                    disabled={pageNumber === numPages}
                    onClick={() => next()}
                    color="primary"
                    // variant={'contained'}
                >
                    <FormattedMessage id={'label.manual.next'} defaultMessage={'Next'} />
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default ManualDialog;
