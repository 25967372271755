import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ArticleItemIcon from '@material-ui/icons/InsertDriveFile';
import mainStyles from '../../styles/mainStyles';
import ArticleAbstract from './ArticleAbstract';
import ArticleTitle from './ArticleTitle';

const styles = theme => ({
  ...mainStyles(theme),
  doi: {
    marginTop: theme.spacing(1),
  },
  linkButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  articleIcon: {
    marginTop: theme.spacing(2),
  },
});

class ArticleItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { classes, article } = this.props;

    return (
      <ListItem key={article.id} alignItems="flex-start">
        <ListItemIcon className={classes.articleIcon}>
          <ArticleItemIcon color="primary" />
        </ListItemIcon>
        <ListItemText 
          secondaryTypographyProps={{
            component: 'div',
          }}
          primary={<ArticleTitle article={article} />} 
          secondary={<ArticleAbstract abstracts={article.abstract} />}>
        </ListItemText>
      </ListItem>
    );
  }
}

ArticleItem.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
)(ArticleItem);
