import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  center: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

function ContainerCenter(props) {
  const { classes, children } = props;

  return (
    <div className={classes.center}>
      {children}
    </div>
  );
}

ContainerCenter.propTypes = {
  children: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContainerCenter);