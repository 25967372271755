import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import type {ServiceMessage} from "../const";
import {getServiceMessages} from "../utils";
import {MessageType} from "../const";
import SelTextView from "./SelTextView";
import {InfoHelp} from "./common/InfoHelp";

function TherapyView(props) {
    const {
        therapy,
        textBold,
    } = props;

    const serviceMessageDESCRIPTION: ServiceMessage = getServiceMessages(therapy.messages, MessageType.DESCRIPTION);

    return (
        <Box>
            <Grid container direction={"row"} alignItems={"center"}>
                <Grid item>
                    {!!textBold &&
                        <SelTextView>{therapy.name}</SelTextView>
                    }
                    {!textBold &&
                        <Typography>{therapy.name}</Typography>
                    }
                </Grid>
                {serviceMessageDESCRIPTION?.showService &&
                    <Grid item style={{margin: '-4px 0 0 10px'}}>
                        <InfoHelp texts={serviceMessageDESCRIPTION.messages}/>
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

TherapyView.propTypes = {
    therapy: PropTypes.object.isRequired,
    textBold: PropTypes.bool,
};

export default TherapyView;
