import { gql } from '@apollo/client';
import {getPromiseQueryResult} from "../utils";
import {PScore, PScoreDto} from "../models/pscore";
import config from "../config";

const Message = `
        type
        payload
`;

const Regimen = `
        id
        name
        drugCompositeId
        selected
        descr
        order
        messages {
            ${Message}
        }
`;

const Drug = `
        id
        name
        dataAvailable
        compStatus
        availStatus
        messages {
            ${Message}
        }
        regimens {
            ${Regimen}
        }
        order
        selected
`;

const DrugGroup = `
        id
        name
        shortName
        contStatus
        isBlocked
        messages {
            ${Message}
        }
        order
        multiselectMin
        multiselectMax
        drugs {
            ${Drug}
        }
`;

const Comp = `
        id
        name
        riskPl
        riskTh
`;

const Device = `
        id
        name
        descr
        selected
        messages {
            ${Message}
        }
`;

const TherapySupportTherapy = `
        id
        name
        order
        devices{
            ${Device}
        }
        selected
`;

// const TherapySupportStep = `
//         id
//         name
//         order
//         therapies {
//             ${TherapySupportTherapy}
//         }
// `;

// const TherapySupportDto = `
//         steps  {
//             ${TherapySupportStep}
//         }
// `;

// const TherapyDataIn = `
//         thType
//         selectIds
// `;

// const TherapyCompare = `
//         id
//         name
//         shortName
//         score
//         descr
// `;

const TherapyInfo = `
        name
        info
`;

// const TherapyRecommendIn = `
//         selectDrugs
// `;

const TherapySet = `
        id
        name
        descr
        isNoRecommend
        drugGroups {
            ${DrugGroup}
        }
        order
        messages {
            ${Message}
        }
        comps {
            ${Comp}
        }
        selected
`;

const TherapyTherapy = `
        id
        name
        order
        sets {
            ${TherapySet}
        }
        messages {
            ${Message}
        }
        selected
`;

const ContrGroup = `
        name
        contStatus
        messages {
            ${Message}
        }
`;

const Scale = `
        title
        groups {
            ${ContrGroup}
        }
`;

const TherapyStep = `
        id
        name
        order
        therapies {
            ${TherapyTherapy}
        }
        supportTherapies {
            ${TherapySupportTherapy}
        }
        messages {
            ${Message}
        }
        selected
`;

const TherapyDto = `
        steps {
            ${TherapyStep}
        }
        absoluteCond {
            ${Scale}
        }
        relativeCond {
            ${Scale}
        }
`;

export const getTherapy = gql`
    query ($patientId: ID!) {
        getTherapy (patientId: $patientId) {
            ${TherapyDto}
        }
    }
`;

export const getTherapyInfo = gql`
    query ($patientId: ID!) {
        getTherapyInfo (patientId: $patientId) {
            ${TherapyInfo}
        }
    }
`;

export const getPScore = gql`
    query ($setIds: [Int!]) {
        getPScore (setIds: $setIds) {
            ${PScore}
        }
    }
`;

export const loadGetPScore = (client, signal, setIds: number[]): Promise<PScoreDto> => {
    return client.query({
        query: getPScore,
        variables: {
            setIds: setIds,
        },
        context: {
            uri: config.options.server.heart_failure_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getPScore');
        });
}

export const loadGetTherapyInfo = (options, client, signal, patientId): Promise<any> => {
    return client.query({
        query: getTherapyInfo,
        variables: {
            patientId: patientId,
        },
        context: {
            uri: options.server.heart_failure_url + options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getTherapyInfo');

        });
}

export const getChartA = gql`
    query ($id: Int!) {
        getChartA(id: $id) {
            title
            url
        }
    }
`;

export const getChartB = gql`
    query ($ids: [Int!]) {
        getChartB(ids: $ids) {
            title
            url
        }
    }
`;

export const getRecommendGuidelinesTexts = gql`
    query ($patientId: ID!) {
        getRecommendGuidelinesTexts (patientId: $patientId) {
            label
            text
        }
    }
`;

export const getRecommendConclusionTexts = gql`
    query ($patientId: ID!, $setId: ID, $deviceIds: [ID]) {
        getRecommendConclusionTexts (patientId: $patientId, setId: $setId, deviceIds: $deviceIds) {
            name
            descriptions {
                label
                text
            }
        }
    }
`;

export const loadGetRecommendTexts = (client, signal, patientId): Promise<any> => {
    return client.query({
        query: getRecommendGuidelinesTexts,
        variables: {
            patientId: patientId,
        },
        context: {
            uri: config.options.server.heart_failure_url + config.options.server.api_uri,
            fetchOptions: {
                signal,
            },
        },
    })
        .then(({data, _}) => {
            return getPromiseQueryResult(data, 'getRecommendGuidelinesTexts');
        });
}

export const getKeyValue = gql`
    query ($patientId: ID!) {
        getKeyValue (patientId: $patientId) {
            key
            value
            id
            groupId
        }
    }
`;


export const getHPDrugTexts = gql`
    query ($drugIds: [Int!]!) {
        getHPDrugTexts(drugIds: $drugIds) {
            id
            name
            texts {
                text
                label
            }
        }
    }
`;

export const getHPThTypeCharts = gql`
    query ($selVarIds: [Int!]!, $refId: Int!, $inSubgroupId: Int) {
        getHPThTypeCharts(selVarIds: $selVarIds, refId: $refId, inSubgroupId: $inSubgroupId) {
            title
            url
        }
    }
`;

export const getHPTherapyPscore = gql`
    query ($selVarIds: [Int!]!, $inSubgroupId: Int) {
        getHPTherapyPscore(selVarIds: $selVarIds, inSubgroupId: $inSubgroupId) {
            name
            efficacy
            safety
        }
    }
`;

