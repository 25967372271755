import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import config from "../../config";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {Box, Button, Card, Grid, Link, Popover, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withApollo } from '@apollo/client/react/hoc';
import VarButtonsEditor from "../baseline/VarButtonsEditor";
import {
    callDetectData, callGetBaseline, callProcessPatientByNotes, callSavePatient, callSavePatientNotes,
    callSavePatientParameters, loadGetPatientSummary,
    loadGetRefreshBaseline, loadListNotesByPatientIdVisitDate
} from "../../query/patient";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    ParameterType,
    BaselineParametrSectionDto,
    MessageType,
    // PatientSummaryStatus,
    NosologyStatus,
    ProcessingStatus,
    SummaryType,
    SummaryContentType,
    UserPermissions,
    DetectData,
    Characteristic,
    ProcessPatientByNotesStatus, PatientInputDto, ParameterInput
} from "../../const";
import type {
    BaselineParameterDto, BaselineParametrSectionDto as BaselineParametrSectionDtoModel, Note,
    ParameterOptDto,
    ServiceMessage,
    ValueOptionType
} from "../../const";
import ParameterView from "./ParameterView";
import {
    getAccessByUserPermission, getBaselinesForAnalyze, getCurrentUpdateDate, getDateFromDatePicker,
    getNextNosology, getNotesDateByIndex,
    getParameterOpt, getParametersFromBaseline,
    getParameterValid, getParameterValueAsString,
    getSelected,
    getServiceMessages,
    getTariffsLinkIntl,
    goNextNosology,
    inRange,
    isNumber, newAnyDataRef, notEmptyValue, patientDrugs, setPatientDataContextHelper,
    showNotifyGeneral, showShort, updatePatientDataBaselineAndParameters,
} from "../../utils";
import {TypographyFieldVarName} from "../../pages/CommonElements";
import debounce from "@material-ui/core/utils/debounce";
import RepeatVisitOrSaveButton from "../button/RepeatVisitOrSaveButton";
import {callSavePatientDrugs, loadDrugCompositesByIds} from "../../query/drugs";
import ConfirmDialog from "../patient/ConfirmDialog";
import PatientParameterNotesView from "./PatientParameterNotesView";
import CheckIcon from "@material-ui/icons/Check";
import UpdateIcon from '@material-ui/icons/Update';
import CardContent from "@material-ui/core/CardContent";
import md5 from "md5";
import userPermissionsStore from "../../service/user-permissons";
import SectionParametersView from "./SectionParametersView";
import PatientTags from "../tags/PatientTags";
import userStore from "../../service/user-service";

const styles = theme => ({
    ...mainStyles(theme),
    buttonsContainer: {
        justifyContent: "flex-end",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center"
        }
    },
    fieldVarNameDescr: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    summaryLink: {
        display: 'flex',
        alignItems: 'center',
    },
    disabled: {
        pointerEvents: "none",
        opacity: '1.0 !important',
    },
});

class BaselineEditorView extends React.Component {

    abortController = new AbortController();
    timeoutGetPatientSummary;
    timeoutProcessPatientByNotes;
    refSummaryLink;
    summaryLinkEvent;

    tags = [{id: 3, name: 'name-3'}];

    state = {};

    getHashParameters = (baseline: BaselineParametrSectionDtoModel[]): string => {
        const fields: string[] = [];

        (baseline || [])
            .sort((section1, section2) => section1.ord - section2.ord)
            .forEach(section => {
                section.parameters.forEach(parameter => {
                    const selectedItem = this.getSelected(parameter);

                    if (parameter.id === '295') {
                        // console.error('+++ parameter:', parameter);
                    }

                    if (true || selectedItem) {
                        fields.push(parameter.id + ':' + (parameter.type === ParameterType.OPTION ? (selectedItem || {}).valueId : (selectedItem || {}).value) + ':' + parameter.modified + ':' + parameter.updateDate + ':' + parameter.noData);
                    }
                });
            });

        const lastHash = md5(fields.join(':'));
        return lastHash;
    }

    isChangedParameters = (baseline: BaselineParametrSectionDtoModel[]): boolean => {
        const {originHashParameters} = this.state;
        const lastHash = this.getHashParameters(baseline);
        // console.error('+++ isChangedParameters() +++ baseline:', baseline);
        // console.error('+++ isChangedParameters() +++ originHashParameters:', originHashParameters);
        // console.error('+++ isChangedParameters() +++ lastHash:', lastHash);
        // console.error('+++ isChangedParameters() +++ originHashParameters !== lastHash:', originHashParameters !== lastHash);
        return originHashParameters !== lastHash;
    }

    getHashDrugs = (drugs: any[]): string => {
        const fields: number[] = (drugs || []).map((drug) => drug.id);

        const lastHash = md5(fields.join(':'));
        return lastHash;
    }

    isChangedDrugs = (drugs: any[]): boolean => {
        const {originHashDrugs} = this.state;
        const lastHash = this.getHashDrugs(drugs);
        return originHashDrugs !== lastHash;
    }

    getHashNotes = (notes: Note[]): string => {
        const fields: string[] = [];

        (notes || [])
            .forEach(note => {
                fields.push(note.type + ':' + note.payload);
            });

        const lastHash = md5(fields.join(':'));
        return lastHash;
    }

    isChangedNotes = (notes: Note[]): boolean => {
        const {originHashNotes} = this.state;
        const lastHash = this.getHashNotes(notes);
        return originHashNotes !== lastHash;
    }

    getPatientSummary = (patient): {patientSummaryStatus: ProcessingStatus; patientSummary: any} => {
        // get summary from config
        const patientSummaryUrl = showShort(patient);
        let patientSummaryStatus = null;
        let patientSummary = null;
        if (!!patientSummaryUrl) {
            patientSummaryStatus = ProcessingStatus.READY;
            patientSummary = {
                patientId: patient,
                url: patientSummaryUrl,
                status: ProcessingStatus.READY,
            };
        }

        return {
            patientSummaryStatus: patientSummaryStatus,
            patientSummary: patientSummary,
        };
    }

    constructor(props) {
        super(props);

        const {patientSummaryStatus, patientSummary} = this.getPatientSummary(props.patient);

        this.state = {
            showError: false,
            loading: false,
            notes: JSON.parse(JSON.stringify((props.patientData || {}).notes || [])),
            notesDateList: (props.patientData || {}).notesDateList || [],
            notesDateSelected: getNotesDateByIndex((props.patientData || {}).notesDateList),
            baseline: props.baseline,
            originHashParameters: this.getHashParameters(props.baseline || []),
            originHashNotes: this.getHashNotes((props.patientData || {}).notes || []),
            originHashDrugs: this.getHashDrugs((props.patientData || {}).drugs || []),
            // processingStatus: null,
            // patientSummaryStatus: null,
            // patientSummary: null,
            processPatientByNotesStatus: null,
            patientSummaryStatus: patientSummaryStatus,
            patientSummary: patientSummary,

            // TODO: for ConfirmDialog
            openConfirmDialog: false,
            openManualDialog: false,
            openGlossaryDialog: false,
            titleConfirmDialog: 'Заголовок диалога',
            textConfirmDialog: 'Текст диалога',
            buttonYes: 'Да',
            buttonNo: 'Нет',
            onConfirmDialog: () => {
                console.error('onConfirmDialog')
            },
            confirmDialogContent: undefined,
        };

        this.refSummaryLink = React.createRef();
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        const notesDateListChanged = JSON.stringify(this.state.notesDateList || []) !== JSON.stringify((nextProps.patientData || {}).notesDateList || []);
        const notesChanged = JSON.stringify(this.state.notes || []) !== JSON.stringify((nextProps.patientData || {}).notes || []);
        const baselineChanged = JSON.stringify(this.state.baseline || []) !== JSON.stringify(nextProps.baseline || []);
        if (
            baselineChanged
            ||
            notesChanged
            ||
            notesDateListChanged
        ) {
            this.setState(prevState => ({
                baseline: baselineChanged ? nextProps.baseline : prevState.baseline,
                originHashParameters: baselineChanged ? this.getHashParameters(nextProps.baseline || []) : prevState.originHashParameters,
                notes: notesChanged ? (nextProps.patientData || {}).notes || [] : prevState.notes,
                originHashNotes: notesChanged ? this.getHashNotes((nextProps.patientData || {}).notes || []) : prevState.originHashNotes,
                notesDateList: notesDateListChanged ? (nextProps.patientData || {}).notesDateList || [] : prevState.notesDateList,
                notesDateSelected: notesDateListChanged ? getNotesDateByIndex((nextProps.patientData || {}).notesDateList) : prevState.notesDateSelected,
            }));
        }
    }

    componentDidMount() {
        // this.getPatientSingleHtmlNosologySummaryProcessingStatus();
    }

    componentWillUnmount() {
        this.abortController.abort();
        if (!!this.timeoutGetPatientSummary) {
            clearTimeout(this.timeoutGetPatientSummary);
        }
        if (!!this.timeoutProcessPatientByNotes) {
            clearTimeout(this.timeoutProcessPatientByNotes);
        }
    }

    getAllFields = () => {
        const {baseline} = this.state;

        if (Boolean(baseline)) {
            return baseline.flatMap(b => b.fields);
        } else {
            return [];
        }
    };

    saveButtonViewOld = () => {
        const {classes, readonly, nextNosology} = this.props;
        return (
            <Box className={classes.cardActions} style={{paddingTop: 0, paddingBottom: 0}}>
                <Grid container spacing={2} justifyContent={"flex-end"}>
                    {nextNosology &&
                        <Grid item>
                            <Button color={"primary"} variant="outlined" onClick={() => this.onGoPatient(nextNosology)}>
                                <FormattedMessage id='label.patient.parameters.goPatient' defaultMessage='Go patient'/>
                            </Button>
                        </Grid>
                    }
                    {!readonly &&
                        <Grid item>
                            <Button color={"primary"} variant="outlined" onClick={() => this.onParametersSave()}>
                                <FormattedMessage id='label.patient.parameters.save' defaultMessage='Save data'/>
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Box>
        );
    }

    /*
        getPatientSingleHtmlNosologySummaryProcessingStatus = () => {
            const {
                readonly,
                patient,
                patientData,
                client,
            } = this.props;
            const {
                patientSummaryStatus,
            } = this.state;
            const {contextStaff} = this.context;

            console.error('+++ getPatientSingleHtmlNosologySummaryProcessingStatus() +++ patient:', patient);
            console.error('+++ getPatientSingleHtmlNosologySummaryProcessingStatus() +++ patientData:', patientData);
            console.error('+++ getPatientSingleHtmlNosologySummaryProcessingStatus() +++ contextStaff:', contextStaff);

            if (!readonly) {
                const {signal} = this.abortController;
                const nosology = patient.nosologies.filter((nosology) => nosology.status === NosologyStatus.AVAILABLE)[0];
                console.error('+++ startGetPatientSummary() +++ nosology:', nosology);
                loadGetPatientSingleHtmlNosologySummaryProcessingStatus(client, signal, patient.id, (nosology || {}).id, contextStaff.id)
                    .then((processingStatus) => {
                        console.error('+++ startGetPatientSummary() +++ processingStatus:', processingStatus);
                        if (processingStatus === ProcessingStatus.PROCESSING || processingStatus === ProcessingStatus.READY) {
                            this.startGetPatientSummary();
                        }
                        this.setState({
                            processingStatus: processingStatus,
                        });
                    });
            }
        }
    */

    openReadySummary = (event, url: string) => {
        // console.error('+++ this.refSummaryLink:', this.refSummaryLink);
        // console.error('+++ this.refSummaryLink.current:', this.refSummaryLink.current);
        // window.open(url, "_blank");
        // Object.assign(document.createElement('a'), {
        //     target: '_blank',
        //     href: url,
        // }).click();

        /**/
        // console.error('+++ this.refSummaryLink.current.click() +++');
        this.refSummaryLink.current.click();
        this.setState({
            patientSummaryStatus: null,
            patientSummary: null,
        });
        /**/
    }

    prepareStartGetPatientSummary = (event) => {
        this.setState({
            // patientSummaryStatus: null,
            patientSummaryStatus: ProcessingStatus.PROCESSING,
            patientSummary: null,
        }, () => {
            const {
                readonly,
            } = this.props;
            this.summaryLinkEvent = event;
            if (readonly) {
                this.startGetPatientSummary();
            } else {
                this.onParametersSave((patientData) => {
                    if (!!patientData) {
                        this.startGetPatientSummary();
                    }
                });
            }
        });
    }

    onPatientTagsChange = (tags) => {
        // console.error('+++ onPatientTagsChange() +++ tags:', tags);
        this.tags = tags;
    }

    confirmNotesAnalyze = (event) => {
        const {
            intl,
        } = this.props;

        const {
            baseline,
        } = this.state;

        if (!this.isBaselineEmpty(baseline)) {
            this.setConfirmOpen(
                true,
                'label.dialog.confirm.analyze.title',
                'label.dialog.confirm.analyze.text',
                this.notesAnalyze,
                intl.formatMessage({id: 'label.dialog.confirm.button.yes', defaultMessage: 'Yes'}),
                intl.formatMessage({id: 'label.dialog.confirm.button.no', defaultMessage: 'No'}),
            );
        } else {
            this.notesAnalyze();
        }
    }

    notesAnalyze = () => {
        const {
            patient,
            patientData,
            client,
        } = this.props;

        const {
            notes,
            notesDateSelected,
        } = this.state;

        this.setState({
            loading: true,
        });
        const {signal} = this.abortController;

        // const isChangedNotes = this.isChangedNotes(notes);
        const patientInputDto: PatientInputDto = {
            name: patient.name,
            uuid: patient.uuid,
            type: patient.type,
            parameters: patient.parameters.map((parameter) => {
                return {
                    fieldId: parameter.fieldId,
                    value: parameter.value,
                    type: parameter.type,
                    noData: parameter.noData,
                    valueDefault: parameter.valueDefault,
                    modified: parameter.modified,
                    updateDate: parameter.updateDate,
                };
            }),
            nosologies: patient.nosologies,
            tag: (patient.tags[patient.tags.length - 1] || {}).name || '',
            notes: notes.map((note) => {
                return {
                    id: note.id.startsWith('new:') ? 0 : note.id,
                    type: note.type,
                    payload: note.payload,
                };
            }),
            compositeIds: patient.compositeIds,
        };
        callSavePatient(client, signal, patientInputDto)
            .then((result) => {
                this.loadPatient(client, signal, result.id, result.uuid, () => {
                    this.setState({
                        processPatientByNotesStatus: ProcessPatientByNotesStatus.PROCESSING,
                        // processPatientByNotesStatusCount: 0,
                    });
                    this.startProcessPatientByNotes();
                });
            });
    }

    startGetPatientSummary = () => {
        const {
            // patient,
            patientData,
            client,
        } = this.props;
        const {
            patientSummaryStatus,
        } = this.state;
        const {signal} = this.abortController;

        // console.error('+++ startGetPatientSummary() +++ patient:', patient);
        // console.error('+++ startGetPatientSummary() +++ patientData:', patientData);
        // console.error('+++ startGetPatientSummary() +++ patientSummaryStatus:', patientSummaryStatus);

        /* +++2022.01.21+++
                if (patientSummaryStatus === ProcessingStatus.READY && !!(patientSummary || {}).url) {
                    this.openReadySummary(this.summaryLinkEvent, patientSummary.url);
                    return;
                }
        */

        if (
            // !readonly
            // &&
            patientSummaryStatus !== ProcessingStatus.READY
            &&
            patientSummaryStatus !== ProcessingStatus.ERROR
        ) {
            const nosologiesIds = (patientData.nosologyStates || []).filter((nosology) => nosology.status === NosologyStatus.AVAILABLE).map((nosology) => nosology.id);
            if (!!nosologiesIds.length) {

                const drugCompositeIds = ((patientData || {}).drugs || []).map((drug) => drug.id);

                const request = {
                    patientId: patientData.id,
                    nosologiesIds: nosologiesIds,
                    ignoreDataset: (config.options.SUMMARY || {}).ignoreDataset,

                    // summaryTypes: [SummaryType.MEDICBK, SummaryType.REGISTER_PREV],
                    // contentType: SummaryContentType.HTML,
                    // nosologiesData: nosologiesIds.map((nosologiesId) => ({
                    //     nosologyId: nosologiesId,
                    //     drugCompositeIds: [],
                    // })),
                };
                loadGetPatientSummary(client, signal, request)
                    .then((patientSummaryResponse) => {
                        if (!!patientSummaryResponse) {
                            this.setState({
                                patientSummaryStatus: patientSummaryResponse.status,
                                patientSummary: patientSummaryResponse,
                            }, () => {
                                const {patientSummaryStatus, patientSummary} = this.state;
                                switch (patientSummaryStatus) {
                                    case ProcessingStatus.READY:
                                        if (!!(patientSummary || {}).url) {
                                            // +++2022.01.21+++ this.openReadySummary(this.summaryLinkEvent, patientSummary.url);
                                            return;
                                        }
                                        break;
                                    case ProcessingStatus.PROCESSING:
                                    case ProcessingStatus.NOT_PROCESSING:
                                        this.timeoutGetPatientSummary = setTimeout(() => {
                                            this.startGetPatientSummary();
                                        }, config.options.GET_PATIENT_SUMMARY_TIMEOUT || 10000);
                                        break;
                                    case ProcessingStatus.ERROR:
                                        const {showNotify} = this.context;
                                        showNotifyGeneral(patientSummaryResponse.description, 'error', showNotify);
                                        this.setState({
                                            patientSummaryStatus: null,
                                            patientSummary: null,
                                        });
                                        break;
                                }
                            });
                        } else {
                            this.setState({
                                patientSummaryStatus: null,
                                patientSummary: null,
                            });
                        }
                    });
            }
        }
    }

    startProcessPatientByNotes = () => {
        const {
            patient,
            client,
        } = this.props;
        const {
            processPatientByNotesStatus,
        } = this.state;
        const {signal} = this.abortController;

        // console.error('+++ startProcessPatientByNotes() +++ patient:', patient);
        // console.error('+++ startProcessPatientByNotes() +++ patientData:', patientData);
        // console.error('+++ startProcessPatientByNotes() +++ patientSummaryStatus:', patientSummaryStatus);
        // console.error('+++ startProcessPatientByNotes() +++ processPatientByNotesStatus:', processPatientByNotesStatus);

        if (![ProcessPatientByNotesStatus.COMPLETE, ProcessPatientByNotesStatus.ERROR].includes(processPatientByNotesStatus)) {
            callProcessPatientByNotes(client, signal, patient.uuid)
                .then((processPatientByNotesResponse) => {
                    if (!!processPatientByNotesResponse) {
                        // FIXME +++2023.06.26+++ для тестирования, убрать
                        // console.error('+++ startProcessPatientByNotes() +++ this.state.processPatientByNotesStatusCount:', this.state.processPatientByNotesStatusCount);
                        // if (this.state.processPatientByNotesStatusCount > 11) {
                        //     processPatientByNotesResponse.status = ProcessPatientByNotesStatus.COMPLETE;
                        // }
                        // FIXME +++2023.06.26+++ для тестирования, убрать
                        this.setState(prevState => ({
                            processPatientByNotesStatus: processPatientByNotesResponse.status,
                            // processPatientByNotesStatusCount: prevState.processPatientByNotesStatusCount + 1
                        }), () => {
                            const {processPatientByNotesStatus} = this.state;
                            switch (processPatientByNotesStatus) {
                                case ProcessPatientByNotesStatus.COMPLETE:
                                    // TODO: детекция завершена, загрузка всего пациента
                                    this.loadPatient(client, signal, patient.id, patient.uuid);
                                    break;
                                case ProcessPatientByNotesStatus.PROCESSING:
                                    this.timeoutProcessPatientByNotes = setTimeout(() => {
                                        this.startProcessPatientByNotes();
                                    }, config.options.PROCESS_PATIENT_BY_NOTES_TIMEOUT || 10000);
                                    break;
                                case ProcessPatientByNotesStatus.ERROR:
                                    const {showNotify} = this.context;
                                    showNotifyGeneral(processPatientByNotesResponse.errorDescr, 'error', showNotify);
                                    this.setState({
                                        processPatientByNotesStatus: null,
                                    });
                                    break;
                            }
                        });
                    } else {
                        this.setState({
                            processPatientByNotesStatus: null,
                        });
                    }
                });
        }
    }

    saveButtonView = () => {
        const {
            classes,
            readonly,
            showButtons,
            nextNosology,
            intl,
        } = this.props;
        const {
            disableButtons,
            patientSummaryStatus,
            // processingStatus,
            patientSummary,
        } = this.state;
        // const showShortHref = showShort(patient);

        const disableButtonGetPatientSummary = (
            disableButtons
            ||
            [ProcessingStatus.PROCESSING, ProcessingStatus.NOT_PROCESSING].includes(patientSummaryStatus)
        );

        let labelButtonGetPatientSummary = intl.formatMessage({id: 'label.patient.parameters.goPatientSummary', defaultMessage: 'Summary'});
        // if (patientSummaryStatus === ProcessingStatus.PROCESSING) {
        //     labelButtonGetPatientSummary = labelButtonGetPatientSummary + ' ' + intl.formatMessage({id: 'label.patient.parameters.goPatientSummaryProcessing', defaultMessage: 'Processing'});
        // }

        // console.error('+++ patientSummaryStatus:', patientSummaryStatus);
        // console.error('+++ [ProcessingStatus.PROCESSING, ProcessingStatus.NOT_PROCESSING].includes(patientSummaryStatus):', [ProcessingStatus.PROCESSING, ProcessingStatus.NOT_PROCESSING].includes(patientSummaryStatus));

        return (
            showButtons ?
                <Box className={classes.cardActionsCard + ' ' + classes.pr8px}>
                    <Grid container spacing={2} justifyContent={"flex-end"}>
                        {getAccessByUserPermission(UserPermissions.PATIENT_SUMMARY_R) &&
                            <Grid item alignItems={"center"} className={classes.summaryLink}>
                                {patientSummaryStatus !== ProcessingStatus.READY &&
                                    <Button color="primary"
                                            variant="outlined"
                                            onClick={(event) => this.prepareStartGetPatientSummary(event)}
                                            disabled={disableButtonGetPatientSummary}
                                            startIcon={[ProcessingStatus.PROCESSING, ProcessingStatus.NOT_PROCESSING].includes(patientSummaryStatus) ? <UpdateIcon /> : undefined}
                                    >
                                        {labelButtonGetPatientSummary}
                                    </Button>
                                }
                                {(patientSummaryStatus === ProcessingStatus.READY && !!patientSummary?.url) &&
                                    <Button
                                        color="primary"
                                        variant={'outlined'}
                                        href={patientSummary?.url}
                                        target={'_blank'}
                                        startIcon={<CheckIcon />}
                                    >
                                        {labelButtonGetPatientSummary}
                                    </Button>
                                }
                            </Grid>
                        }
                        <Grid item>
                            <RepeatVisitOrSaveButton
                                onSave={this.onParametersSave}
                                saveLabel={intl.formatMessage({id: 'label.patient.parameters.goPatient', defaultMessage: 'Go patient'})}
                                onRestart={() => this.onGoPatient(nextNosology)}
                                readonly={readonly}
                                disableButtons={disableButtons}
                                intl={intl}
                            >
                            </RepeatVisitOrSaveButton>
                        </Grid>
                    </Grid>
                </Box>
                : undefined
        );
    }

    isBaselineEmpty = (baseline: BaselineParametrSectionDto[]): boolean => {
        if (!Boolean(baseline)) {
            return true;
        }

        return baseline.every((baselineSection) => {
            return baselineSection.parameters.length === 0;
        })
    }

    handleChangeNotes = (event: any) => {
        const {readonly} = this.props;
        if (!readonly) {
            this.setState({
                notes: event.target.value,
            });
        }
    };

    setConfirmOpen = (open: boolean, titleConfirmDialog: string, textConfirmDialog: string, onConfirmDialog, buttonYes, buttonNo, content = undefined, buttonYesDisabledDialog = false) => {
        const {intl} = this.props;
        let sTitleConfirmDialog = '';
        let sTextConfirmDialog = '';
        if (open) {
            sTitleConfirmDialog = intl.formatMessage({id: titleConfirmDialog, defaultMessage: 'Title'});
            sTextConfirmDialog = !!textConfirmDialog ? intl.formatMessage({id: textConfirmDialog}, {defaultMessage: 'Text'}) : '';
        }
        this.setState({
            openConfirmDialog: open,
            titleConfirmDialog: sTitleConfirmDialog,
            textConfirmDialog: sTextConfirmDialog,
            buttonYes: buttonYes,
            buttonNo: buttonNo,
            // buttonYes: intl.formatMessage({id: 'label.dialog.confirm.button.yes', defaultMessage: 'Yes'}),
            // buttonNo: intl.formatMessage({id: 'label.dialog.confirm.button.no', defaultMessage: 'No'}),
            onConfirmDialog: onConfirmDialog,
            confirmDialogContent: content,
            buttonYesDisabledDialog: buttonYesDisabledDialog,
        });
    }

    onNotesChange = (notes: Note[]) => {
        // console.error('+++ onNotesChange() +++ notes:', newAnyDataRef(notes || []));
/* +++2023.06.29+++ */
        this.setState({
            notes: notes,
        });
/**/
        this.props.patientData.notes = this.props.patient.notes = notes; //.filter((note) => !note.id.startsWith('new:'));
        // this.props.patient.notes = notes; //.filter((note) => !note.id.startsWith('new:'));
    }

    onVisitDateChange = (visitDate: string, callback) => {
        const {
            client,
            patient,
        } = this.props;
        const {signal} = this.abortController;
        loadListNotesByPatientIdVisitDate(client, signal, patient.id, visitDate).then((notes) => {
            this.props.patient.notes = notes || [];
            this.props.patientData.notes = notes || [];
            this.setState({
                notes: notes,
                notesDateSelected: visitDate,
                originHashNotes: this.getHashNotes(notes),
            }, () => {
                if (!!callback) {
                    callback();
                }
            });
        });
    }

    onNotesSave = (notes: Note[], isChangedNotes): Promise<boolean> => {
        const {
            client,
            patient,
        } = this.props;
        const {signal} = this.abortController;
        return callSavePatientNotes(client, signal, patient.id, notes, isChangedNotes)
            .then((removePatientNotesResponse) => {
                return new Promise((resolve, reject) => {
                    resolve(removePatientNotesResponse);
                });
            });
    }

    patientDrugs = (patientData, patient, contextStaff, readonly = false) => {
        const {patientDrugs} = this.props;
        patientDrugs(patientData, patient, contextStaff, readonly, false);
    }

    baselineEditorView = () => {
        const {
            classes,
            patientDrugs,
            intl,
            history,
            client,
            readonly,
            patientData
        } = this.props;
        const {
            anchorFieldDescr,
            selField,
            loading,
            notes,
            notesDateList,
            notesDateSelected,
            baseline,

            openConfirmDialog,
            titleConfirmDialog,
            textConfirmDialog,
            buttonYes,
            buttonNo,
            onConfirmDialog,
            confirmDialogContent,
            buttonYesDisabledDialog,
            processPatientByNotesStatus,
        } = this.state;

        const {signal} = this.abortController;

        // console.error('+++ baselineEditorView() +++ notes:', newAnyDataRef(notes || []));
        // console.error('+++ baselineEditorView() +++ baseline:', JSON.parse(JSON.stringify(baseline)));
        // console.error('+++ baselineEditorView() +++ this.isBaselineEmpty:', JSON.parse(JSON.stringify(this.isBaselineEmpty(baseline))));

        if (!(((userStore || {}).user || {}).nosologies || []).length) {
            return (
                <Card className={classes.contentBoxBottomSpace}>
                    <CardContent className={classes.cardContent}>
                        <Box className={classes.contentBoxCard} textAlign={'center'}>
                            {getTariffsLinkIntl(intl, history)}
                        </Box>
                    </CardContent>
                </Card>
            );
        }

/* TODO: пока убрал, т.к. всегда будут Нотесы с кнопкой Анализировать (Детекция)
        if (this.isBaselineEmpty(baseline)) {
            return (
                <Card className={classes.contentBoxBottomSpace}>
                    <CardContent className={classes.cardContent}>
                        <Box className={classes.contentBoxCard} textAlign={'center'}>
                            <FormattedMessage id={'label.patient.no_data'} defaultMessage={'No data'}/>
                            {/!*<FormattedMessage id='message.datatable.no_nosologies' defaultMessage='No nosologies'/>*!/}
                        </Box>
                    </CardContent>
                </Card>
            );
        }
*/

        return (
            <Box className={loading ? classes.disabled : ''}>
                {(!readonly && !this.isBaselineEmpty(baseline)) &&
                    <Card className={classes.contentBoxBottomSpace}>
                        <CardContent className={classes.cardContent}>
                            {this.saveButtonView()}
                        </CardContent>
                    </Card>
                }

                {(getAccessByUserPermission(UserPermissions.PATIENT_EPICRISIS_R)) &&
                    <Card className={classes.contentBoxBottomSpace}>
                        <CardContent className={classes.cardContentAccordion}>
                            <Box className={classes.contentBoxCard}>
                                <PatientParameterNotesView
                                    onNotesChange={this.onNotesChange}
                                    onNotesSave={this.onNotesSave}
                                    notes={notes}
                                    // notes={patientData.notes}
                                    notesDateList={notesDateList}
                                    notesDateSelected={notesDateSelected}
                                    onVisitDateChange={this.onVisitDateChange}
                                    readonly={readonly || !getAccessByUserPermission(UserPermissions.PATIENT_EPICRISIS_W)}
                                    client={client}
                                    signal={signal}
                                    processPatientByNotesStatus={processPatientByNotesStatus}
                                    notesAnalyze={this.confirmNotesAnalyze}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                }

                {/* FIXME: Tags
                <Card className={classes.contentBoxBottomSpace}>
                    <CardContent className={classes.cardContentAccordion}>
                        <Box className={classes.contentBoxCard}>
                            <PatientTags
                                tags={this.tags}
                                onChange={this.onPatientTagsChange}
                                isPrescriptionReadonly={false}
                            />
                        </Box>
                    </CardContent>
                </Card>
*/}

                {this.isBaselineEmpty(baseline) &&
                    <Card className={classes.contentBoxBottomSpace}>
                        <CardContent className={classes.cardContent}>
                            <Box className={classes.contentBoxCard} textAlign={'center'}>
                                <FormattedMessage id={'label.patient.no_data'} defaultMessage={'No data'}/>
                            </Box>
                        </CardContent>
                    </Card>
                }
                {!this.isBaselineEmpty(baseline) &&
                    <Card className={classes.contentBoxBottomSpace}>
                        <CardContent className={classes.cardContent}>
                            <Box className={classes.contentBoxCard}>
                                {baseline
                                    .sort((section1, section2) => section1.ord - section2.ord)
                                    .map(section => (
                                        (section.parameters || []).length > 0 &&
                                        <SectionParametersView
                                            key={section.id}
                                            section={section}
                                            parametersView={this.parametersView}
                                        />
                                    ))
                                }

                                {patientDrugs &&
                                    <Box style={{marginLeft: '-4px'}}>
                                        {patientDrugs()}
                                    </Box>
                                }

                                <Popover
                                    open={Boolean(anchorFieldDescr) && Boolean(selField)}
                                    anchorEl={anchorFieldDescr}
                                    onClose={this.onFieldDescrClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}>
                                    {this.buidViewFieldDescr(selField)}
                                </Popover>
                            </Box>
                        </CardContent>
                    </Card>
                }
                    {/*
                    <Card className={classes.contentBoxBottomSpace}>
                        <CardContent className={classes.cardContent}>
                            <Box className={classes.contentBoxCard}>
                                {baseline
                                    .sort((section1, section2) => section1.ord - section2.ord)
                                    .map(section => (
                                        (section.parameters || []).length > 0 &&
                                        <Box key={section.id}>
                                            <Typography className={classes.contentBoxTopSpace}
                                                        color={"primary"} variant={"h6"}>
                                                {section.name}
                                            </Typography>

                                            <Box key={section.id}>
                                                {section.parameters && this.fieldsView(section.id, section.parameters)}
                                                {section.parameters && this.parametersView(section.id, section.parameters)}
                                            </Box>
                                        </Box>
                                    ))}

                                {patientDrugs && patientDrugs()}

                                <Popover
                                    open={Boolean(anchorFieldDescr) && Boolean(selField)}
                                    anchorEl={anchorFieldDescr}
                                    onClose={this.onFieldDescrClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}>
                                    {this.buidViewFieldDescr(selField)}
                                </Popover>
                            </Box>
                        </CardContent>
                    </Card>
                    */}

                {(!readonly && !this.isBaselineEmpty(baseline)) &&
                    <Card className={classes.contentBoxBottomSpace}>
                        <CardContent className={classes.cardContent}>
                            {this.saveButtonView()}
                        </CardContent>
                    </Card>
                }

                {openConfirmDialog &&
                    <ConfirmDialog
                        title={titleConfirmDialog}
                        text={textConfirmDialog}
                        buttonYes={buttonYes}
                        buttonNo={buttonNo}
                        open={openConfirmDialog}
                        setOpen={this.setConfirmOpen}
                        onConfirm={onConfirmDialog}
                        buttonYesDisabled={false && buttonYesDisabledDialog}
                    >
                        {confirmDialogContent}
                    </ConfirmDialog>
                }
            </Box>
        );
    };

    isFieldDescr = (field: BaselineParameterDto) => {
        return Boolean(field)
            &&
            Boolean(field.values)
            &&
            field.values
                .filter(value => value)
                .findIndex(v => Boolean(JSON.parse(v).description)) !== -1;
    };

    onFieldNameClick = (field) => (e) => {
        e.persist();

        if (this.isFieldDescr(field)) {
            this.setState({
                selField: field,
                anchorFieldDescr: e.target,
            });
        }

        e.preventDefault();
    };

    buidViewFieldDescr = (field) => {
        if (!Boolean(field) || !Boolean(field.values)) {
            return <Box/>;
        }

        const {classes} = this.props;

        const values: ValueOptionType[] = field.values
            .filter(value => value)
            .map(value => {
                return JSON.parse(value);
            });

        return (
            <Box className={classes.contentBox}>
                {values.filter(v => Boolean(v.description)).map((v, id) => (
                    <Box key={id} className={classes.fieldVarNameDescr}>
                        <TypographyFieldVarName>{v.name}</TypographyFieldVarName>
                        <Typography>{v.description}</Typography>
                    </Box>
                ))}
            </Box>
        );
    };

    onFieldDescrClose = () => {
        this.setState({
            anchorFieldDescr: undefined,
            selField: undefined,
        });
    };

    fieldsView = (sectionId, fields) => {
        const {classes, readonly} = this.props;

        return (
            <Box className={classes.contentVerticalPadding}>
                {fields
                    .sort((f1, f2) => f1.ord - f2.ord)
                    // .filter(f => {
                    //   const isShow = isFieldShow(f);
                    //
                    //   if (!isShow) {
                    //     const defVal = f.vars.find(v => v.isDefault);
                    //     if (Boolean(defVal)) {
                    //       f.value = defVal.id;
                    //     }
                    //   }
                    //
                    //   return isShow;
                    // })
                    .map((field) => (
                        <Grid key={field.id} container
                              spacing={1}
                              direction={"row"}
                              alignItems={"center"}
                              className={classes.contentVerticalPadding}>
                            <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                                {
                                    this.isFieldDescr(field)
                                        ?
                                        <Link href="#"
                                              color={Boolean(field.error) ? "error" : "primary"}
                                              onClick={this.onFieldNameClick(field)}>
                                            <Typography>
                                                {field.name}
                                            </Typography>
                                        </Link>
                                        :
                                        <Typography color={"inherit"}>
                                            {field.name}
                                        </Typography>
                                }
                            </Grid>
                            <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                                <Grid container
                                      className={classes.buttonsContainer + (readonly ? (' ' + classes.disabled) : '')}>
                                    <VarButtonsEditor
                                        onValueChange={this.onValueChange(sectionId, field)}
                                        // value={f.value}
                                        // items={f.vars.sort((v1, v2) => v1.ord - v2.ord).map(v => ({
                                        //   value: v.id,
                                        //   title: v.name,
                                        // }))}
                                        items={field.values}
                                        field={field}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
            </Box>
        );
    };

    parametersView = (sectionId, parameters: BaselineParameterDto[]) => {
        const {classes, readonly} = this.props;
        const {showError, loading} = this.state;

        return (
            <Box className={classes.contentVerticalPadding}>
                {parameters
                    .filter((parameter) => parameter.visible)
                    .sort((parameter1, parameter2) => parameter1.ord - parameter2.ord)
                    // .filter(f => {
                    //   const isShow = isFieldShow(f);
                    //
                    //   if (!isShow) {
                    //     const defVal = f.vars.find(v => v.isDefault);
                    //     if (Boolean(defVal)) {
                    //       f.value = defVal.id;
                    //     }
                    //   }
                    //
                    //   return isShow;
                    // })
                    .map((parameter) => (
                        <ParameterView
                            key={parameter.id}
                            parameter={parameter}
                            readonly={loading || readonly || !parameter.enable}
                            showError={showError}
                            onValueChange={this.onValueChange(sectionId, parameter)}
                            onUpdateDateChange={this.onUpdateDateChange(sectionId, parameter)}
                        />
                    ))}
            </Box>
        );
    };

    onGoPatient = (nextNosology) => {
        // TODO: for test
        // const {
        //     patientData,
        // } = this.props;
        // const _nextNosology = getNextNosology(patientData, true);
        // console.error('+++ onGoPatient() +++ _nextNosology:', _nextNosology);
        // this.goNextNosology(_nextNosology);
        // return;
        // TODO: for test

        const {readonly} = this.props;
        if (!readonly) {
            this.onParametersSave((patientData) => {
                if (!!patientData) {
                    // console.error('+++ onGoPatient() +++ patientData:', patientData);
                    const _nextNosology = getNextNosology(patientData, true);
                    // console.error('+++ onGoPatient() +++ _nextNosology:', _nextNosology);
                    this.goNextNosology(_nextNosology);
                }
            });
        } else {
            this.goNextNosology(nextNosology);
        }
    }

    goNextNosology = (nextNosology) => {
        const {patient, activePatient, intl, openDialog} = this.props;
        // const {showNotify} = this.context;
        activePatient.patientName = patient.visualName;
        goNextNosology(nextNosology, this.props.history, activePatient, intl, openDialog);
    }

    getSelected = (parameter: BaselineParameterDto) => {
        return getSelected(parameter);
    }

    onTagUpdate = (event) => {
        // console.error('+++ onTagUpdate() +++ event:', event);
        // console.error('+++ onTagUpdate() +++ event.target.value:', event.target.value);
        this.setState({
            confirmDialogTag: event.target.value,
            buttonYesDisabledDialog: event.target.value.length < 3,
        });
    };

    getTagInput = () => {
        const {intl} = this.props;
        const {confirmDialogTag} = this.state;
        return <TextField
            autoFocus
            required
            margin="dense"
            label={intl.formatMessage({id: 'label.save_parameters.tag', defaultMessage: 'Tag'})}
            fullWidth
            value={confirmDialogTag}
            onChange={this.onTagUpdate}
        />
    }

    onParametersSave = (callback) => {
        const {
            // baseline,
            intl,
        } = this.props;
        // const {baseline} = this.state;
        // console.error('+++ onParametersSave() +++ baseline:', baseline);
        // console.error('+++ onParametersSave() +++ this.tags:', this.tags);

        const {notes, baseline} = this.state;
        const {client, patient, patientData, loadPatient} = this.props;

        const isChangedParameters = this.isChangedParameters(baseline);
        const isChangedNotes = this.isChangedNotes(notes);
        // const isChangedDrugs = this.isChangedDrugs(patient.drugs);
        const isChangedDrugs = this.isChangedDrugs(patientData.drugs);


        // const {originHashParameters} = this.state;
        // console.error('+++ onParametersSave() +++ originHashParameters:', originHashParameters);
        // console.error('+++ onParametersSave() +++ isChangedParameters:', isChangedParameters);
        // console.error('+++ onParametersSave() +++ isChangedNotes:', isChangedNotes);
        // console.error('+++ onParametersSave() +++ isChangedDrugs:', isChangedDrugs);

        let valid = true;
        const parametersInput: ParameterInput[] = [];

        if (true || isChangedParameters) {
            baseline
                .sort((section1, section2) => section1.ord - section2.ord)
                .forEach(section => {
                    section.parameters.forEach(parameter => {
                        // console.error('+++ onParametersSave() +++ section:', section);
                        // console.error('+++ onParametersSave() +++ section.parameters:', section.parameters);
                        const parameterOpt: ParameterOptDto = getParameterOpt(parameter);

                        // const required: boolean = parameterOpt.required;

                        const selectedItem = this.getSelected(parameter);

                        // const parameterValid = (!required || (required && ((!parameter.visible || !parameter.enable) || (parameter.type === ParameterType.OPTION ? Boolean((selectedItem || {}).valueId) : notEmptyValue((selectedItem || {}).value)))));
                        const parameterValid = getParameterValid(parameter);
                        // if (parameter.id === '96') {
                        //     console.error('+++ onParametersSave() +++ 2 parameterValid:', parameterValid);
                        //     console.error('+++ onParametersSave() +++ 2 parameter:', parameter);
                        //     console.error('+++ onParametersSave() +++ 2 selectedItem:', selectedItem);
                        //     console.error('+++ onParametersSave() +++ 2 parameterOpt:', parameterOpt);
                        // }
                        // console.error('+++ onParametersSave() +++ 1 parameterValid:', parameterValid);
                        // console.error('+++ onParametersSave() +++ 1 selectedItem:', selectedItem);
                        // if (!parameterValid) {
                        //     console.error('+++ onParametersSave() +++ 2 parameterValid:', parameterValid);
                        //     console.error('+++ onParametersSave() +++ 2 parameter:', parameter);
                        //     console.error('+++ onParametersSave() +++ 2 selectedItem:', selectedItem);
                        //     console.error('+++ onParametersSave() +++ 2 parameterOpt:', parameterOpt);
                        // }
                        valid = valid && parameterValid;
                        /*
                                                if (parameter.visible && selectedItem) {
                                                    if ([ParameterType.INT, ParameterType.DECIMAL, ParameterType.DATE].includes(parameter.type)) {
                                                        valid = valid && inRange(selectedItem, parameter);
                                                    }
                                                }
                        */

                        if (selectedItem || [ParameterType.INT, ParameterType.DECIMAL, ParameterType.DATE].includes(parameter.type)) {
                            // if (parameter.type === ParameterType.DATE) {
                            //     console.error('+++ onParametersSave() +++ parameter:', parameter);
                            //     console.error('+++ onParametersSave() +++ selectedItem:', selectedItem);
                            // }

                            // console.error('+++ onParametersSave() +++ parameter.noData:', parameter.noData);
                            // let value = ('' + (parameter.type === ParameterType.OPTION ? (selectedItem?.valueId || '') : (selectedItem?.value || ''))) || (parameter.type !== ParameterType.DATE ? null : '');
                            // let value = ('' + (parameter.type === ParameterType.OPTION ? (selectedItem?.valueId || '') : (selectedItem?.value)));
                            // if (value === '' || value === 'undefined') {
                            //     value = parameter.type !== ParameterType.DATE ? null : '';
                            // }
                            const value = getParameterValueAsString(selectedItem, parameter.type);

                            const findParameter = patientData.parameters.find((parametersItem) => !!parametersItem && Number(parameter.id) === Number(parametersItem.fieldId));
                            // console.error('+++ 0 findParameter:', findParameter);

                            parametersInput.push({
                                fieldId: Number(parameter.id),
                                value: value,
                                // value: (selectedItem) && ('' + (parameter.type === ParameterType.OPTION ? (selectedItem.valueId || '') : (selectedItem.value || ''))) || (parameter.type !== ParameterType.DATE ? null : ''),
                                type: parameter.type,
                                noData: !!parameter.noData,
                                valueDefault: !!(findParameter || {}).valueDefault || false,
                                modified: parameter.modified,
                                updateDate: !!parameter.updateDate ? ('' + parameter.updateDate) : parameter.updateDate,
                            });
                        }
                    });
                });

            patientData.parameters.forEach((parametersItem) => {
                const findParametersInput = parametersInput.find((parametersInputItem) => '' + parametersInputItem.fieldId === parametersItem.fieldId);
                if (!findParametersInput) {
                    // console.error('+++ onParametersSave() +++ 3 в бейзлайне не найден параметр parametersItem:', parametersItem);
                    parametersInput.push({
                        fieldId: parametersItem.fieldId,
                        value: parametersItem.value,
                        type: parametersItem.type,
                        noData: parametersItem.noData,
                        valueDefault: parametersItem.valueDefault,
                        modified: parametersItem.modified,
                        updateDate: parametersItem.updateDate,
                    });
                }
            });
        }

        // console.error('+++ onParametersSave() +++ 3 parametersInput:', parametersInput);
        // console.error('+++ onParametersSave() +++ 3 valid:', valid);
        this.setState({
            showError: ((config.options.PATIENT_DATA_FORM_VALIDATE || {}).enable && !valid),
        });

        const {showNotify} = this.context;

        if ((!(config.options.PATIENT_DATA_FORM_VALIDATE || {}).enable || valid)) {
            // const {patientDataContext} = this.context;
            const {signal} = this.abortController;

            // console.error('+++ onParametersSave() +++ patient?.drugs:', patient?.drugs);
            // console.error('+++ onParametersSave() +++ patientData?.drugs:', patientData?.drugs);
            // console.error('+++ onParametersSave() +++ patientDataContext?.drugs:', patientDataContext?.drugs);

            // const drugCompositeIds: number[] = patient.drugs.map((drug) => drug.id);
            const drugCompositeIds: number[] = patientData.drugs.map((drug) => drug.id);
            // console.error('+++ onParametersSave() +++ drugCompositeIds:', newAnyDataRef(drugCompositeIds));
            this.setState({
                loading: true,
                disableButtons: true,
                confirmDialogTag: undefined,
            }, () => {
                callSavePatientDrugs(client, signal, patient.id, drugCompositeIds, isChangedDrugs)
                    .then((result) => {
                        // callSavePatientNotes(client, signal, patient.id, notes, isChangedNotes)
                        //     .then((result) => {
                                // callSavePatientParameters(client, signal, parameterInput, patient, notes, isChangedParameters)
                                if (isChangedParameters || isChangedNotes) {
                                    this.setConfirmOpen(
                                        true,
                                        'label.dialog.confirm.tagging.title',
                                        undefined,
                                        () => {
                                            const {confirmDialogTag} = this.state;
                                            const patientInputDto: PatientInputDto = {
                                                name: patient.name,
                                                uuid: patient.uuid,
                                                type: patient.type,
                                                parameters: parametersInput, // .filter((parametersInputItem) => parametersInputItem.value !== null),
                                                nosologies: patient.nosologies,
                                                tag: confirmDialogTag,
                                                notes: notes.map((note) => {
                                                    return {
                                                        id: note.id.startsWith('new:') ? 0 : note.id,
                                                        type: note.type,
                                                        payload: note.payload,
                                                    };
                                                }),
                                                compositeIds: drugCompositeIds,
                                            };
                                            callSavePatient(client, signal, patientInputDto)
                                            // callSavePatientParameters(client, signal, fields, patientData, notes, confirmDialogTag, isChangedParameters)
                                                .then(saveResponse => {
                                                    this.onSaveParametersResult(client, signal, patientData, saveResponse, isChangedParameters, isChangedNotes, isChangedDrugs, callback);
                                                });
                                        },
                                        intl.formatMessage({id: 'label.continue', defaultMessage: 'Continue'}),
                                        undefined,
                                        // TODO: компонент ввода текста, confirmDialogContent
                                        this.getTagInput(),
                                        true
                                    );
                                } else {
                                    this.onSaveParametersResult(client, signal, patientData, {id: patient.id, uuid: patient.uuid}, isChangedParameters, isChangedNotes, isChangedDrugs, callback);
                                }
                            // });
                    });
            });
        } else {
            showNotifyGeneral(intl.formatMessage({
                id: 'err.fields.fill.is_required',
                defaultMessage: 'Fill all fields is required'
            }), 'error', showNotify);

            this.setState({
                loading: false,
                disableButtons: false,

                patientSummaryStatus: null,
            }, () => {
                if (callback) {
                    callback(null);
                }

                this.forceUpdate();
            });
        }
    };

    onSaveParametersResult = (client, signal, patientData, saveResponse, isChangedParameters, isChangedNotes, isChangedDrugs, callback) => {
        const {showNotify} = this.context;
        const {intl} = this.props;
        const {loadPatient, patient} = this.props;
        const {patientSummaryStatus, patientSummary} = this.getPatientSummary(patient);
        if (!!saveResponse) {
            // if (isChangedParameters || isChangedNotes || isChangedDrugs) {
                showNotifyGeneral(intl.formatMessage({
                    id: 'message.data.save.success',
                    defaultMessage: 'Save success.',
                }), 'success', showNotify);

                this.loadPatient(client, signal, saveResponse.id, saveResponse.uuid, callback);
            // } else {
            //     this.setState({
            //         loading: false,
            //         disableButtons: false,
            //     }, () => {
            //         if (!!callback) {
            //             callback(patientData);
            //         }
            //     });
            // }
        } else {
            showNotifyGeneral(intl.formatMessage({
                id: 'message.data.save.error',
                defaultMessage: 'Error',
            }), 'error', showNotify);

            this.setState({
                loading: false,
                disableButtons: false,

                patientSummaryStatus: patientSummaryStatus,
                patientSummary: patientSummary,
            }, () => {
                if (callback) {
                    callback(null);
                }
            });
        }
    }


    loadPatient = (client, signal, patientId, patientUuid, callback) => {
        const {loadPatient, patient} = this.props;
        const {patientSummaryStatus, patientSummary} = this.getPatientSummary(patient);

        loadPatient(client, signal, patientId, patientUuid, null, (response_patientData) => {
            // console.error('+++---+++ loadPatient().callback +++---+++ response_patientData:', response_patientData);
            this.setState({
                loading: false,
                disableButtons: false,

                originHashParameters: this.getHashParameters(response_patientData.baseline),
                originHashNotes: this.getHashNotes(response_patientData.notes),
                originHashDrugs: this.getHashDrugs(response_patientData.drugs),
                notes: response_patientData.notes,
                notesDateList: response_patientData.notesDateList,
                notesDateSelected: getNotesDateByIndex(response_patientData.notesDateList),

                // patientSummaryStatus: isChangedParameters || isChangedDrugs ? null : this.state.patientSummaryStatus,
                // patientSummary: isChangedParameters || isChangedDrugs ? null : this.state.patientSummary,
                processPatientByNotesStatus: null,
                patientSummaryStatus: patientSummaryStatus,
                patientSummary: patientSummary,
            }, () => {
                if (!!callback) {
                    callback(response_patientData);
                }
            });
        });
    }

    debounced = debounce((parameter) => {
        this.refresher(parameter);
    }, 1500);

    refresher = (parameter: BaselineParameterDto, parameters: any[]) => {
        const {baseline} = this.state;
        if (!parameter || parameter.influence) {
            this.setState({
                loading: true,
            });

            let fields = [];
            if (!!parameters) {
                fields = parameters;
                // fields = parameters.map((parameter) => {
                //     return {
                //         fieldId: Number(parameter.id),
                //         value: '' + (parameter.type === ParameterType.OPTION ? (selectedItem?.valueId || '') : (selectedItem?.value || '')),
                //         modified: parameter.modified,
                //         noData: !!parameter.noData,
                //     }
                // });
            } else {
                baseline
                    .filter((section) => (section.parameters || []).length > 0)
                    .forEach(section => {
                        section.parameters.forEach(parameter => {
                            const selectedItem = this.getSelected(parameter);

                            // if (selectedItem) {
                            fields.push({
                                fieldId: Number(parameter.id),
                                value: '' + (parameter.type === ParameterType.OPTION ? (selectedItem?.valueId || '') : (selectedItem?.value || '')),
                                modified: parameter.modified,
                                noData: !!parameter.noData,
                            });
                            // }
                        })
                    });
            }
            const {client} = this.props;
            const {signal} = this.abortController;
            loadGetRefreshBaseline(client, signal, fields, !!parameter ? parameter.id : 0)
                .then((baselineLiteAndSections) => {
                    // console.error('+++ baselineParametersLite:', baselineParametersLite);
                    // console.error('+++ baseline:', baseline);
                    baselineLiteAndSections.baselineParameterLiteDtoList.forEach((baselineParameterLite) => {
                        baseline
                            .map((section) => {
                                const findedSectionDto = baselineLiteAndSections.sectionDtoList.find((sectionDto) => sectionDto.id === section.id);
                                // if (section.expanded !== findedSectionDto.expanded) {
                                //     console.log('+++ section:', newAnyDataRef(section));
                                //     console.log('+++ findedSectionDto:', newAnyDataRef(findedSectionDto));
                                // }
                                if (!!findedSectionDto) {
                                    section.expanded = findedSectionDto.expanded;
                                }
                                return section;
                            })
                            .filter((section) => (section.parameters || []).length > 0)
                            .forEach((section) => section.parameters.forEach((parameter) => {
                                if (parameter.id === baselineParameterLite.id) {
                                    switch (parameter.type) {
                                        case ParameterType.OPTION:
                                            parameter.values = parameter.values
                                                .filter(value => value)
                                                .map(it => {
                                                    const item = JSON.parse(it);
                                                    item.selected = baselineParameterLite.value && item.valueId === baselineParameterLite.value;
                                                    return JSON.stringify(item);
                                                });
                                            break;
                                        case ParameterType.INT:
                                        case ParameterType.DECIMAL:
                                        case ParameterType.DATE:
                                            if (baselineParameterLite.value) {
                                                const itemValues = JSON.parse(parameter.values[0]);
                                                itemValues.value = baselineParameterLite.value;
                                                parameter.values[0] = JSON.stringify(itemValues);
                                            }
                                            // parameter.values[0].value = baselineParameterLite.value ? [baselineParameterLite.value] : parameter.values;
                                            break;
                                        default:
                                            break;
                                    }
                                    parameter.visible = baselineParameterLite.visible;
                                    parameter.enable = baselineParameterLite.enable;
                                    parameter.messages = baselineParameterLite.messages;
                                    parameter.modified = baselineParameterLite.modified;
                                    parameter.noData = baselineParameterLite.noData;
                                    // console.error('++++++ parameter:', parameter);
                                    // console.error('++++++ baselineParameterLite:', baselineParameterLite);
                                    // console.error('++++++ baselineParameterLite:', baselineParameterLite);
                                    // parameter.values = baselineParameterLite.values;
                                }
                                // parameter.messages = [
                                //     {
                                //         payload: "Внимание! Выполнение интервенционного вмешательства может привести к тромбоэмболии!",
                                //         type: "WARNING",
                                //     }
                                // ];
                            }));

                    });
                    this.forceUpdate(() => {
                        this.setState({
                            loading: false,
                            disableButtons: false,
                            baseline: baseline,
                        }, () => {
                            const {loading, baseline} = this.state;
                            // console.error('+++ refresher() +++ END loading:', loading);
                            // console.error('+++ refresher() +++ END baseline:', baseline);
                        });
                    });
                });
        } else {
            this.setState({
                baseline: baseline,
            });
            // TODO: +++ 2021.12.03 +++ this.forceUpdate();
        }
    }

    onUpdateDateChange = (sectionId, parameter: BaselineParameterDto) => (parameter2, updateDate: number) => {
        parameter.modified = true;
        parameter.updateDate = updateDate;
        this.updateBaseline(parameter);
    }

    onValueChange = (sectionId, parameter: BaselineParameterDto) => (value, onClear = false, changeModified = false) => {
        // console.error('+++ BaselineEditorView +++ onValueChange() +++changeModified:', changeModified);
        // console.error('+++ BaselineEditorView +++ onValueChange() +++value:', newAnyDataRef(value));
        // console.error('+++ BaselineEditorView +++ onValueChange() +++onClear:', newAnyDataRef(onClear));
        // console.error('+++ BaselineEditorView +++ onValueChange() +++parameter:', newAnyDataRef(parameter));
        // console.error('+++ BaselineEditorView +++ onValueChange() +++changeNoData:', newAnyDataRef(changeNoData));
        // console.error('+++ BaselineEditorView +++ onValueChange() +++parameter.modified:', newAnyDataRef(parameter).modified);
        // console.error('+++ BaselineEditorView +++ onValueChange() +++parameter.values:', newAnyDataRef(parameter.values));

        if (onClear && !value) {
            parameter.noData = true;
        }

        let preventChange = false;
        switch (parameter.type) {
            case ParameterType.OPTION:
                parameter.modified = true;
                parameter.updateDate = parameter.updateDate; // || getDateFromDatePicker();
                // parameter.updateDate = getCurrentUpdateDate();
                parameter.values = parameter.values
                    .filter(value => value)
                    .map(it => {
                        const item = JSON.parse(it);
                        if (item.selected && item.valueId === value) {
                            preventChange = false;
                        }
                        item.selected = item.valueId === value;
                        // TODO: убрал вывод уведомления сервисного сообщения выбранного значения
                        if (item.selected) {
                            const serviceMessages: ServiceMessage = getServiceMessages(item.messages, MessageType.WARNING);
                            if (serviceMessages.showService) {
                                const {showNotify} = this.context;
                                for (const serviceMessage of serviceMessages.messages) {
                                    showNotifyGeneral(serviceMessage.payload, 'error', showNotify);
                                }
                            }
                        }
                        return JSON.stringify(item);
                    });
                if (!preventChange) {
                    // parameter.modified = true;
                    // parameter.updateDate = parameter.updateDate || getDateFromDatePicker();
                    this.refresher(parameter);
                } else {
                    this.updateBaseline(parameter);
                }
                break;
            case ParameterType.INT:
            case ParameterType.DECIMAL:
            case ParameterType.DATE:
                const values = parameter.values
                    .filter(value => !!value)
                    .map(it => {
                        const item = JSON.parse(it);
                        if ((parameter.type !== ParameterType.DATE ? isNumber(item) : true) && !changeModified) {
                            // preventChange = true; // +++2022.08.04+++
                            preventChange = false; // +++2022.08.04+++
                            return it;
                        }
                        // console.error('+++ item.value:', item.value);
                        // console.error('+++ value:', value);
                        if (('' + item.value === '' + value || (!item.value && !value)) && !changeModified) {
                            preventChange = true;
                        }
                        // console.error('+++ preventChange:', preventChange);
                        // const decimals = value.split('.')[1];
                        // item.value = value ? (!decimals || !decimals.length ? Number(value) : Number(value).toFixed(decimals.length)) : null;
                        item.value = value ? (parameter.type !== ParameterType.DATE ? Number(value) : value) : null;
                        return JSON.stringify(item);
                    });
                if (values && values.length) {
                    parameter.values = values;
                }
                // console.error('+++ preventChange:', preventChange);
                if (!preventChange) {
                    parameter.modified = true;
                    parameter.updateDate = parameter.updateDate; // || getDateFromDatePicker();
                    // parameter.updateDate = getCurrentUpdateDate();
                    if (parameter.influence) {
                        this.setState({
                            disableButtons: true,
                        });
                        this.updateBaseline(parameter);
                        // TODO: +++ 2021.12.03 +++ this.forceUpdate();
                        // this.debounced(parameter); // TODO: +++ 2022.08.03 +++ убрал, т.к. всегда рефрешим, если были изменения в значении поля
                        this.refresher(parameter);
                    } else {
                        this.updateBaseline(parameter);
                        // TODO: +++ 2021.12.03 +++ this.forceUpdate();
                    }
                } else {
                    // parameter.modified = true;
                    this.updateBaseline(parameter);
                    // TODO: +++ 2021.12.03 +++ this.forceUpdate();
                }
                break;
            default:
                break;
        }
    };

    updateBaseline = (parameter) => {
        // console.error('+++ updateBaseline() +++ parameter:', parameter);
        const {baseline} = this.state;
        baseline
            .map(section =>
                (section.parameters || []).map((blParameter) => {
                    if (blParameter.id === parameter.id) {
                        blParameter = parameter;
                    }
                    return blParameter;
                })
            );

        this.setState({baseline: baseline});
    }

    checkSwitchGroupVal = (groupId, field) => {
        const {baseline} = this.state;

        const selGroup = baseline.find(bl => bl.id === groupId);
        if (Boolean(selGroup)) {
            selGroup.fields
                .filter(f => f.id !== field.id)
                .forEach(f => {
                    const defVar = f.vars.find(v => v.isDefault);
                    if (Boolean(defVar)) {
                        f.value = defVar.id;
                    } else if (Boolean(f.value)) {
                        delete f.value;
                    }
                });
        }
    };

    resetFields = (fIds) => {
        const {baseline} = this.state;

        baseline
            .flatMap(bl => bl.fields)
            .filter(f => fIds.includes(f.id))
            .forEach(f => {
                const defVar = f.vars.find(v => v.isDefault);
                if (Boolean(defVar)) {
                    f.value = defVar.id;
                } else if (Boolean(f.value)) {
                    delete f.value;
                }
            });
    };

    render() {
        return this.baselineEditorView();
    }
}

BaselineEditorView.propTypes = {
    classes: PropTypes.object.isRequired,
    baseline: PropTypes.arrayOf(PropTypes.object).isRequired,
    // nosologyData: PropTypes.object.isRequired,
    // getSelectVars: PropTypes.func.isRequired,
    // isFieldShow: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    showButtons: PropTypes.bool,
    nextNosology: PropTypes.object,
    getNextNosology: PropTypes.func.isRequired,
    patient: PropTypes.object,
    patientData: PropTypes.object,
    activePatient: PropTypes.object,
    history: PropTypes.object,
    loadPatient: PropTypes.func.isRequired,
    patientDrugs: PropTypes.func,
};

BaselineEditorView.defaultProps = {
    baseline: [],
};

BaselineEditorView.contextTypes = {
    showNotify: PropTypes.func,
    contextStaff: PropTypes.object,
    setPatientDataContext: PropTypes.func,
    patientDataContext: PropTypes.object,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(BaselineEditorView);
