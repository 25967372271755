import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
    Box,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ListItemText,
    Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import {FormattedMessage} from "react-intl";
import grey from "@material-ui/core/colors/grey";

const styles = theme => ({
    ...mainStyles(theme),
    headerText: {
        // color: "white",
        // color: theme.palette.text.primary,
        // color: theme.palette.primary.main,
    },
    headerIcon: {
        // color: "white",
        color: theme.palette.primary.main,
    },
    chartPaper: {
        padding: theme.spacing(2),
        paddingTop: 0,
        width: '100%',

        // display: 'flex',
        // flexDirection: 'column',
        // flex: 'auto',
        // alignItems: 'center',

        '&$expanded': {
            marginTop: theme.spacing(2),
            '&(:first-child)': {
                marginTop: theme.spacing(2),
            },
        },
    },
    loading: {
        textAlign: 'center',
        padding: '16px',
        paddingBottom: 0,
    },
    accordionBorder: {
        '&:not(:last-child)': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
        },
    }
});

const HelpTextPanel = withStyles(theme => ({
    root: {
        // marginTop: theme.spacing(2),
        // boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            // marginTop: theme.spacing(2),
            // '&(:first-child)': {
            //     marginTop: theme.spacing(2),
            // },
        },

        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none',
            borderColor: grey[300],
            borderWidth: '1px',
            borderStyle: 'solid',
        },
    },
    expanded: {},
}))(Accordion);

const HelpTextPanelSummary = withStyles(theme => ({
    root: {
        // backgroundColor: '#ff814c',
        // backgroundColor: blue[50],
        '&:hover': {
            // backgroundColor: '#FF5722',
            // backgroundColor: blue[70],
        },
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    expanded: {},
    content: {
        margin: '0',
        '&$expanded': {
            margin: '0',
        },
    },
}))(AccordionSummary);

function ExpandableContextPanel(props) {
    const {
        children,
        title,
        classes,
        variant,
        needTopSpace
    } = props;
    const [expanded, setExpanded] = React.useState(props.expanded);

    const handleChange = (expanded) => (event, isExpanded) => {
        setExpanded(expanded);
    };

    return (
        <Box className={needTopSpace ? classes.contentBoxTopSpace : ''}>
            <HelpTextPanel expanded={expanded} onChange={handleChange(!expanded)} variant={variant || 'elevation'} square={false} className={variant === 'outlined' ? classes.accordionBorder : ''}>
                <HelpTextPanelSummary expandIcon={<ExpandMoreIcon className={classes.headerIcon}/>}>
                    <ListItem disableGutters>
                        <ListItemText>
                            <Typography variant={"body1"} color={'primary'} className={classes.headerText}>{title}</Typography>
                        </ListItemText>
                    </ListItem>
                </HelpTextPanelSummary>
                <AccordionDetails style={{padding: '0'}}>
                    {expanded &&
                        <Box className={classes.chartPaper}>
                            {/*{children}*/}

                            {(false) &&
                            <Typography align={'center'}>
                                <FormattedMessage id={'label.patient.no_data'} defaultMessage={'No data'}/>
                            </Typography>
                            }

                            {children}
                        </Box>
                    }
                </AccordionDetails>
            </HelpTextPanel>
        </Box>
    );
}

ExpandableContextPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
};

export default compose(
    withStyles(styles),
)(ExpandableContextPanel);

