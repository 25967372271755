import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Glossary from "../pages/Glossary";

const GlossaryDialog = (props) => {
    const {intl, open, setOpen} = props;

    return (
        <Dialog
            fullWidth
            maxWidth={'lg'}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="glossary-dialog"
        >
            <DialogTitle id="glossary-dialog">{
                intl.formatMessage({
                    id: 'page.glossary',
                    defaultMessage: 'Glossary'
                })}
            </DialogTitle>
            <DialogContent>
                <Glossary />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    {
                        intl.formatMessage({
                            id: 'label.close',
                            defaultMessage: 'Close'
                        })
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GlossaryDialog;
