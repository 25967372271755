import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    Container,
    Paper,
    Typography, Grid,
} from "@material-ui/core";
import PanelDivider from "../PanelDivider";
import SelTextView from "../SelTextView";
import ReactMarkdown from "react-markdown";
import {getHPDrugTexts} from '../../query/HPBaseline';
import {FormattedMessage} from "react-intl";
import config from "../../config";
import {getRecommendConclusionTexts} from "../../query/HeartFailureBaseline";
import DeviceView from "../DeviceView";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {
    GroupedBarChartColors,
    MessageType,
    NosologyType,
    RecommendConclusionTextsInputDto,
    riskTextColors
} from "../../const";
import ComparisonsView from "../ComparisonsView";
import {DrugResultRow} from "../drug/DrugResultRow";
import SelTreatView from "../baseline/SelTreatView";
import PaperColor from "../PaperColor";
import {loadCommercialDrugs} from "../../query/drugs";
import {
    capitalizeFirstLetter,
    clearMarkDownText,
    extendParamsDrugByTradeItems,
    getQueryResult, getServiceMessages, getUuid, isEmpty, isMultiselect,
    populateHPChart, sortByOrder
} from "../../utils";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import GroupedBarChart from "../charts/GroupedBarChart";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";
import {loadGetRecommendConclusionTexts} from "../../query/common";
import type {BaselineParameterStrictDto, ServiceMessage} from "../../const";

const styles = theme => ({
    ...mainStyles(theme),
    device: {
        fontSize: '14px',
    },
});

class IbsResult extends React.Component {

    abortController = new AbortController();

    state = {
        selections: undefined,
    };

    constructor(props) {
        super(props);

        this.state = {
            selections: undefined,
        };
    }

    componentDidMount() {
        updateShowPanel(null);
        setLoadChartsMethod(null);

        const {getSelections} = this.props;
        const selections = getSelections();
        this.setState({
            selections: selections,
        }, () => {
            if (this.props.activePatient.action === 'RESULT') {
                this.setVars();
                this.setSelected();
            }
            this.loadAll();
        });
    }

    loadAll() {
        const {showComparisons, setDisableResultNext} = this.props;
        if (!showComparisons) {
            this.setTherapyCompare();
        } else {
            if (!!setDisableResultNext) {
                setDisableResultNext(true);
            }
        }
        this.loadResultTexts();
        this.loadDrugs();

        this.forestPlotView();
    }

    setVars = () => {
/*
        const {
            nosologyData,
            getTherapyGroupDrugs,
        } = this.props;

        // console.error('+++ nosologyData:', nosologyData);
        // console.error('+++ nosologyData.touched:', nosologyData.touched);
        this.drugGroups = getTherapyGroupDrugs();
*/
/*
        this.selStep = this.steps.items.filter(step => step.selected)[0];
        this.steps.selThId = (this.selStep || {}).id;

        this.selType = this.selStep.therapies.filter(type => type.selected)[0];
        this.steps.selTypeId = (this.selType || {}).id;

        this.selDeviceType = this.selStep.supportTherapies.filter((supportTherapy) => supportTherapy.selected)[0];
        this.selDevice = ((this.selDeviceType || {}).devices || []).filter((device) => device.selected)[0];
        this.steps.selDeviceTypeId = (this.selDeviceType || {}).id;
        this.steps.selDeviceId = (this.selDevice || {}).id;

        this.selComb = ((this.selType || {}).sets || []).filter(set => set.selected)[0];
        this.steps.selCombId = (this.selComb || {}).id;

        this.selGroup = ((this.selComb || {}).drugGroups || {}).filter(set => set.selected)[0];

        nosologyData.steps = this.steps;
*/
    }

    setSelected = () => {
        const {
            nosologyData,
        } = this.props;

        let selDrugs = {};

        (this.drugGroups || []).forEach((drugGroup) => {
            // console.error('+++ drugGroup:', drugGroup);
            drugGroup.drugs.forEach((drug) => {
                if (drug.selected) {
                    drug.groupName = drugGroup.name;
                    drug.groupShortName = drugGroup.shortName;

                    drug.selectedRegimen = drug.regimens.filter((regimen) => regimen.selected)[0];

                    if (drug.id === -1) {
                        selDrugs[drugGroup.id] = [];
                    }

                    selDrugs[drugGroup.id] = selDrugs[drugGroup.id] ? (isMultiselect(drugGroup) ? [...selDrugs[drugGroup.id], ...[drug]] : [drug]) : [drug];
                }
            });
        });

        nosologyData.selDrugs = selDrugs;
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadDrugs = () => {
        const {
            client,
            getSteps,
            nosologyData,
            getRevascularizationTherapy,
            getRevascularizationTherapyServiceMessageINFOSelected,
        } = this.props;
        const {selections} = this.state;
        const {signal} = this.abortController;

        const steps = getSteps();
        // const drugs = Object.values(nosologyData.selDrugs || {}).flatMap((drugs) => drugs.filter((drug) => drug.id !== -1)); // .map((drug) => drug.id));
        let drugs = [];

        if (!!selections) {
            drugs = [...selections.first.drugsSelected, ...selections.second.drugsSelected];
        }

        const drugsIds: number[] = [];
        const paramsDrugs = [];
        if (drugs && drugs.length > 0) {
            drugs
                .forEach(drug => {
                    drugsIds.push(drug.id);
                    paramsDrugs.push(
                        {
                            groupName: drug.groupName,
                            drugs: [
                                {
                                    drugName: drug.name,
                                    drugId: drug.id,
                                    drugRegimens: drug.second && drug.regimens,
                                }
                            ],
                        }
                    );
                });
        }

        // TODO: замоканная реваскуляризации
        const revascularizationTherapy = getRevascularizationTherapy() || {};
        const therapies = (revascularizationTherapy.therapyPools || []).flatMap((therapyPool) => therapyPool.therapies.map((therapy) => therapy));
        if (!isEmpty(revascularizationTherapy)) {
            paramsDrugs.push(
                {
                    groupName: revascularizationTherapy.name,

                    drugs: [
                        {
                            // drugName: getRevascularizationTherapyServiceMessageINFOSelected().map((serviceMessage) => serviceMessage.payload).join(', '),
                            drugName: getRevascularizationTherapyServiceMessageINFOSelected().map((serviceMessage) => serviceMessage.payload).map((payload, index) =>
                                <Box key={index}>{payload}</Box>
                            ),
                            drugId: undefined,
                            drugRegimens: undefined,
                            tradeItems: !!therapies.length ? [
                                {
                                    name:
                                        <Box>
                                            <Box>Тип реваскуляризации:</Box>
                                            {therapies.map((therapy) =>
                                                <Box key={therapy.id}>{therapy.name}</Box>
                                            )
                                            }
                                        </Box>,
                                }
                            ] : [],
                        }
                    ],
                }
            );
        }

        loadCommercialDrugs(client, signal, drugsIds)
            .then((drugsByDrugDosageNames) => {
                this.setState({
                    paramsDrugs: extendParamsDrugByTradeItems(paramsDrugs, drugsByDrugDosageNames),
                });
            });
    }

    loadResultTexts = () => {
        const {client, getConclusionTexts, setConclusionTexts, patientId, nosologyId, getSteps} = this.props;
        const {signal} = this.abortController;

        const conclusionTexts = getConclusionTexts();

        if (!conclusionTexts) {
            // const steps = getSteps();

            const recommendConclusionTextsInputDto: RecommendConclusionTextsInputDto = {
                nosologyId: nosologyId,
                patientId: patientId,
                setId: 1, // steps?.selCombId,
                deviceIds: [],
            };
            loadGetRecommendConclusionTexts(client, signal, recommendConclusionTextsInputDto)
                .then((conclusionTexts) => {
                    this.setState({
                        conclusionTexts: conclusionTexts,
                    });
                    setConclusionTexts(conclusionTexts);
                });
        } else {
            this.setState({
                conclusionTexts: conclusionTexts,
            });
        }
    };

    paramsView = () => {
        const {
            classes,
            intl,
            getSteps,
            getSelectTherapyComb,
            getAbsoluteContrs,
            getRelativeContrs,
            getTherapyInfo,
            nosologyData,
        } = this.props;
        const {paramsDrugs, selections} = this.state;
        // console.error('+++ paramsDrugs:', paramsDrugs);
        const {patientDataContext} = this.context;

/* +++2022.05.19+++ вернуть!
        const steps = getSteps();
        const selStep = steps.items.filter(step => steps.selThId === step.id)[0];
        const selType = selStep ? selStep.therapies.filter(type => steps.selTypeId === type.id)[0] : undefined;
        const selComb = selType ? selType.sets.filter(set => steps.selCombId === set.id)[0] : undefined;
        // const selDeviceType = selStep ? selStep.therapies.filter(type => steps.selDeviceTypeId === type.id)[0] : undefined;
        const selDeviceType = selStep ? selStep.supportTherapies.filter(supportTherapy => steps.selDeviceTypeId === supportTherapy.id)[0] : undefined;
        const selDevice = selDeviceType ? selDeviceType.devices.filter(device => steps.selDeviceId === device.id)[0] : undefined;

        const absoluteContrs = getAbsoluteContrs();
        const relativeContrs = getRelativeContrs();
        const therapyInfo = getTherapyInfo();
*/

        let params = [];

        const baselineParameterStrict: BaselineParameterStrictDto[] = patientDataContext.patientDataContext.actualBaselineParameterStrict || [];
        baselineParameterStrict.forEach((bms) => {
            params.push(
                {
                    name: bms.name,
                    value: bms.value + (bms.units ? (' ' + bms.units) : ''),
                }
            );
        });


/* +++2022.05.19+++ вернуть!
        if (Boolean(selStep)) {
            params.push(
                {
                    name: intl.formatMessage({id: 'label.hp.therapy.sel', defaultMessage: 'Select therapy'}),
                    value: selStep.name,
                });
        }
*/

/* +++2022.05.19+++ вернуть!
        if (Boolean(selDeviceType) && Boolean(selDevice)) {
            params.push(
                {
                    name: selDeviceType.name,
                    value: <DeviceView device={selDevice} className={classes.device} positionEnd={true}/>,
                });
        }
*/

/* +++2022.05.19+++ вернуть!
        if (therapyInfo && therapyInfo.length > 0) {
            therapyInfo
                .forEach(ti => {
                    params.push(
                        {
                            name: ti.name,
                            value: ti.info.join(', '),
                        });
                });
        }
*/


        const absoluteContrs = ((nosologyData || {}).getTherapy || []).sort(sortByOrder).flatMap((rootTherapy) => (rootTherapy.absoluteCond || {}).contrGroups) || [];
        if (absoluteContrs && absoluteContrs.length > 0) {
            absoluteContrs
                .forEach(absoluteContr => {
                    params.push(
                        {
                            name: intl.formatMessage({
                                id: 'label.hp.comp_cont',
                                defaultMessage: 'Compelling Contraindications'
                            }),
                            value: absoluteContr.name,
                        });
                });
        }
        // console.error('+++ paramsView() +++ absoluteContrs:', absoluteContrs);

        const relativeContrs = ((nosologyData || {}).getTherapy || []).sort(sortByOrder).flatMap((rootTherapy) => (rootTherapy.relativeCond || {}).contrGroups) || [];
        if (relativeContrs && relativeContrs.length > 0) {
            relativeContrs
                .forEach(relativeContr => {
                    params[intl.formatMessage({
                        id: 'label.hp.poss_cont',
                        defaultMessage: 'Possible Contraindications'
                    })] = relativeContr.drugGroupShortName;
                    params.push(
                        {
                            name: intl.formatMessage({
                                id: 'label.hp.poss_cont',
                                defaultMessage: 'Possible Contraindications'
                            }),
                            value: relativeContr.name,
                        });
                });
        }
        // console.error('+++ paramsView() +++ relativeContrs:', relativeContrs);

        // console.error('+++ paramsView() +++ selections:', selections);
        // console.error('+++ paramsView() +++ paramsDrugs:', paramsDrugs);


/*
        !!selections && params.push(
            {
                name: selections.first.therapySelected.name || 'Название выбранной терапии',
                value: selections.first.titleRootTherapy || 'Название выбранной комбинации',
            });
        !!selections && params.push(
            {
                name: selections.second.therapySelected.name || 'Название выбранной терапии',
                value: selections.second.titleRootTherapy || 'Название выбранной комбинации',
            });
*/

        // params.push(
        //     {
        //         name: 'Реваскуляризация',
        //         value: 'Показана для улучшения прогноза',
        //     }
        // );

        // const comps = (nosologyData.getTherapy.therapyPools[0].therapies[0].drugGroupCombinations[0].compCombinations || [])[0] || [];
        // const scRiskColor: string = riskTextColors[(comps[0] || {}).scRisk];
        // const selThCombChartData = populateHPChart(comps, intl, scRiskColor, GroupedBarChartColors.BLUE);


        return (
                (
                    (!!params && !!params.length)
                    ||
                    (!!paramsDrugs && paramsDrugs.length)
                    // ||
                    // (!!comps.length)
                    // ||
                    // !!selThCombChartData
                )
                &&
                    <Paper variant={"outlined"} className={classes.contentBox}>
                        <Container maxWidth={"sm"}>
                            {params.map((param, id) => (
                                <Grid container direction={"row"} key={id} className={classes.resultParam}>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamName}>{param.name}</Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.resultParamValue}>{param.value}</Grid>
                                </Grid>
                            ))}
                            {!!paramsDrugs && paramsDrugs.map((paramDrug, index1) => (
                                paramDrug.drugs.map((drug, index2) => (
                                    <DrugResultRow key={'' + index2 + '-' + index1} row={{groupName: paramDrug.groupName || paramDrug.groupShortName, drugName: drug.drugName, drugRegimens: drug.drugRegimens, tradeItems: drug.tradeItems}} classes={classes}>
                                    </DrugResultRow>
                                ))
                            ))}
                        </Container>
                    </Paper>
        );
    };

    textsView = () => {
        const {
            classes,
            getRevascularizationTherapy,
        } = this.props;
        const {conclusionTexts} = this.state;

        const revascularizationTherapy = getRevascularizationTherapy() || {};
        const revascularizationTherapyServiceMessageSUBTITLE: ServiceMessage = getServiceMessages(revascularizationTherapy.messages, MessageType.SUBTITLE) || {};
        const revascularizationTherapyServiceMessageSUBTITLEText = ((revascularizationTherapyServiceMessageSUBTITLE.messages || [])[0] || {}).payload;
        const revascularizationTherapyServiceMessageDESCRIPTION: ServiceMessage = getServiceMessages(revascularizationTherapy.messages, MessageType.DESCRIPTION) || {};
        const revascularizationTherapyServiceMessageDESCRIPTIONText = ((revascularizationTherapyServiceMessageDESCRIPTION.messages || [])[0] || {}).payload;



        return (
            <Box>
                {(revascularizationTherapyServiceMessageSUBTITLEText && revascularizationTherapyServiceMessageDESCRIPTIONText) &&
                    <Box>
                        <PanelDivider/>
                        <Paper className={classes.contentBox} variant={"outlined"}>
                            <Typography variant={"h6"} align={"center"}>
                                <ReactMarkdown remarkPlugins={[remarkGfm]}
                                               rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                               children={revascularizationTherapyServiceMessageSUBTITLEText}/>
                            </Typography>
                            <PanelDivider/>
                            {/*<SelTextView align={"center"}></SelTextView>*/}
                            {/*<PanelDivider/>*/}
                            <Box>
                                <Typography component={"span"}>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}
                                                   rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                   children={revascularizationTherapyServiceMessageDESCRIPTIONText}/>
                                </Typography>
                                {/*
                                <SelTextView variant={'body1'}>{'Тип ревасклуризации:'}</SelTextView>
                                <Typography component={"p"}>
                                    {'АКШ - ЕОК IA (УУР А, УДД 1; СИБС МЗ РФ 2020)'}
                                </Typography>
                                <Typography component={"p"}>
                                    {'ЧКВ - ЕОК IIb (УУР А, УДД 3; СИБС МЗ РФ 2020).'}
                                </Typography>
                                <PanelDivider/>
                                <SelTextView variant={'body1'}>{'Рекомендации МЗ РФ, HRS и ESC Guidelines:'}</SelTextView>
                                <Typography component={"p"}>
                                    {'Для улучшения прогноза рекомендуется проводить реваскуляризацию при проксимальном стенозе ПНА > 50% ЕОК I B (УУР В, УДД 1 МЗ РФ СИБС 2020)'}
                                </Typography>
                                <PanelDivider/>
                                <Typography component={"p"}>
                                    {'Вмешательство на коронарных артериях улучшает прогноз пациентов с выявленной большой зоной стресс-индуцированной ишемии миокарда. О большой зоне ишемии миокарда также можно судить по наличию гемодинамически значимого поражения крупной коронарной артерии: ствола ЛКА, проксимального отдела передней нисходящей артерии, двух- или трехсосудистого поражения со снижением функции левого желудочка, единственного сохранившегося коронарного сосуда. (МЗ РФ СИБС 2020)'}
                                </Typography>
                                */}
                            </Box>
                        </Paper>
                    </Box>
                }

                {!!conclusionTexts && conclusionTexts
                    .map((secTxt, cId) => (
                        (!!secTxt.descriptions.length && secTxt.descriptions.some((description) => description.text.length > 0)) &&
                        <Box key={cId}>
                            <PanelDivider/>
                            <Paper className={classes.contentBox} variant={"outlined"}>
                                <SelTextView variant={"h6"} align={"center"}>{secTxt.name}</SelTextView>
                                <PanelDivider/>
                                {secTxt.descriptions.map((description, dsId) => (
                                    <Box key={dsId}>
                                        {description.label && <SelTextView variant={'body1'}>{description.label}</SelTextView>}
                                        {(description.text || []).map((text, dId) => (
                                            <Typography component={"span"} key={dId}>
                                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={text}/>
                                            </Typography>
                                        ))}
                                    </Box>
                                ))}
                            </Paper>
                        </Box>
                    ))
                }
            </Box>
        );
    };

    setTherapyCompare = (therapyCompare) => {
        const {
            setTherapyCompare,
            autoSave,
        } = this.props;

        setTherapyCompare(therapyCompare, () => {
            if (!!autoSave) {
                autoSave();
            }
        });
    }

    comparisonsView = () => {
        const {
            showComparisons,
            getTherapyPresumably,
            getSelectVars,
            getThRecommend,
            loadTherapyCompare,
            getTherapyCompare,
            getTouched,
        } = this.props;
        const therapyPresumably = getTherapyPresumably();

        return (
            showComparisons && therapyPresumably && therapyPresumably.thData &&
            <ComparisonsView
                getTherapyPresumably={getTherapyPresumably}
                getSelectVars={getSelectVars}
                nosology={NosologyType.DYSLIPIDEMIA}
                getThRecommend={getThRecommend}
                loadTherapyCompare={loadTherapyCompare}
                getTherapyCompare={getTherapyCompare}
                setTherapyCompare={this.setTherapyCompare}
                getTouched={getTouched}
            />
        );
    };

    drugsView = () => {
        const {
            classes,
            // getSteps,
            nosologyData,
        } = this.props;

        const {selections} = this.state;

        // const drugs = Object.values(nosologyData.selDrugs || {}).flatMap((drugs) => drugs.filter((drug) => drug.id !== -1)); // .map((drug) => drug.id));
        let drugs = [];
        if (!!selections) {
            drugs = [...selections.first.drugsSelected, ...selections.second.drugsSelected];
        }

        // console.error('+++---+++ drugs:', drugs);
        // console.error('+++---+++ selections:', selections);

        // const therapy = this.getTherapyForThGroup(0);
        // const referent = this.getReferentForThGroup(0);
        let content;
        let contentFirst;
        let contentSecond;

        if (!!selections?.first?.drugsSelected?.length) {
            // const serviceMessageINFO: ServiceMessage = getServiceMessages(selections.first.therapySelected.messages, MessageType.INFO);
            // const contentTherapyText = (serviceMessageINFO?.messages[0] || {}).payload;
            const contentDrugs = selections.first.drugsSelected.map((drug) => {
                const drugText = (drug.messages.filter((message) => message.type === MessageType.DESCRIPTION)[0] || {}).payload;
                // if (!!drugText) {
                    return (
                        // <Box key={drug.id}>
                        //     <SelTreatView
                        //         treatName={drug.name}
                        //     />
                        //     <PanelDivider/>
                        // </Box>
                        <Box key={drug.id}>
                            {drug.name && <SelTreatView
                                treatName={capitalizeFirstLetter(drug.name) + (drug.selectedRegimen ? (' - ' + drug.selectedRegimen.name) : '')}/>}
                            <Box key={drug.id}>
                                {!!drugText && (
                                    <Typography component={'span'}>
                                        <ReactMarkdown remarkPlugins={[remarkGfm]}
                                                       rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                       children={clearMarkDownText(drugText)}/>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )
                // } else {
                //     return undefined;
                // }
            }).filter((_content) => !!_content);
            contentFirst = (
                <Box>
                    {/*<SelTextView variant={'body1'}>*/}
                    {/*    {selections.first.therapySelected.name}*/}
                    {/*</SelTextView>*/}
                    {contentDrugs}
                    <PanelDivider/>
{/*
                    {!!contentTherapyText &&
                        <Box>
                            <SelTextView variant={'body1'}>
                                <FormattedMessage id={'message.ibs.result.therapy.messages.title'}
                                                  defaultMessage={'Title'}/>
                            </SelTextView>
                            {contentTherapyText}
                        </Box>
                    }
*/}
                </Box>
            );
        } else {
            contentFirst = (
                <Box>
                    <Typography>
                        <FormattedMessage id={'label.bl.therapy_not_recoment'}
                                          defaultMessage={'Therapy is not recommend'}/>
                    </Typography>
                    <PanelDivider/>
                </Box>
            );
        }
        if (!!selections?.second?.drugsSelected?.length) {
            // const serviceMessageDESCRIPTION: ServiceMessage = getServiceMessages(selections.second.therapySelected.messages, MessageType.DESCRIPTION);
            // const contentTherapyText = (serviceMessageDESCRIPTION?.messages[0] || {}).payload;
            const contentDrugs = selections.second.drugsSelected.map((drug) => {
                const drugText = (drug.messages.filter((message) => message.type === MessageType.DESCRIPTION)[0] || {}).payload;
                // if (!!drugText) {
                    return (
                        // <Box key={drug.id}>
                        //     <SelTreatView
                        //         treatName={drug.name}
                        //     />
                        //     <PanelDivider/>
                        // </Box>
                        <Box key={drug.id}>
                            {drug.name && <SelTreatView
                                treatName={capitalizeFirstLetter(drug.name) + (drug.selectedRegimen ? (' - ' + drug.selectedRegimen.name) : '')}/>}
                            <Box key={drug.id}>
                                {!!drugText && (
                                    <Typography component={'span'}>
                                        <ReactMarkdown remarkPlugins={[remarkGfm]}
                                                       rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                       children={clearMarkDownText(drugText)}/>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )
                // } else {
                //     return undefined;
                // }
            }).filter((_content) => !!_content);
            contentSecond = (
                <Box>
                    {/*<SelTextView variant={'body1'}>*/}
                    {/*    {selections.second.therapySelected.name}*/}
                    {/*</SelTextView>*/}
                    {contentDrugs}
                    <PanelDivider/>
{/*
                    {!!contentTherapyText &&
                        <Box>
                            <SelTextView variant={'body1'}>
                                <FormattedMessage id={'message.ibs.result.therapy.messages.title'}
                                                  defaultMessage={'Title'}/>
                            </SelTextView>
                            {contentTherapyText}
                        </Box>
                    }
*/}
                </Box>
            );
        } else {
            contentSecond = (
                <Box>
                    <Typography>
                        <FormattedMessage id={'label.bl.therapy_not_recoment'}
                                          defaultMessage={'Therapy is not recommend'}/>
                    </Typography>
                    <PanelDivider/>
                </Box>
            );
        }
/*
        if (!!drugs && !!drugs.length) {
            content = drugs.map((drug) => {
                        const drugText = (drug.messages.filter((message) => message.type === MessageType.DESCRIPTION)[0] || {}).payload;
                        if (!!drugText) {
                            return (
                                // <Box key={drug.id}>
                                //     <SelTreatView
                                //         treatName={drug.name}
                                //     />
                                //     <PanelDivider/>
                                // </Box>
                                <Box key={drug.id}>
                                    {drug.name && <SelTreatView
                                        treatName={capitalizeFirstLetter(drug.name) + (drug.selectedRegimen ? (' - ' + drug.selectedRegimen.name) : '')}/>}
                                    <Box key={drug.id}>
                                        {drugText && (
                                            <Typography component={'span'}>
                                                <ReactMarkdown remarkPlugins={[remarkGfm]}
                                                               rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                                               children={clearMarkDownText(drugText)}/>
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            )
                        } else {
                            return undefined;
                        }
                    }).filter((_content) => !!_content);
                    {/!*{this.getTextsViewWithSection(2)}*!/}
        } else {
            content = (
                <Box>
                    <Typography>
                        <FormattedMessage id={'label.bl.therapy_not_recoment'}
                                          defaultMessage={'Therapy is not recommend'}/>
                    </Typography>
                    <PanelDivider/>
                </Box>
            );
        }
*/

        return (
/*
            (!!drugs && !!drugs.length) &&
            <Box>
                <PanelDivider/>
                <PaperColor className={classes.contentBox}>
                    <Typography variant={"h6"} align={"center"}>
                        <FormattedMessage id={'label.hp.drugs.sel'} defaultMessage={'Selected drugs'}/>
                    </Typography>
                    {!!content.length &&
                        <Box>
                            <PanelDivider/>
                            {content}
                        </Box>
                    }
                </PaperColor>
                {/!*<PanelDivider/>*!/}
            </Box>
*/
            <Box>
                {(!!selections?.first?.drugsSelected?.length) &&
                    <Box>
                        <PanelDivider/>
                        <PaperColor className={classes.contentBox}>
                            <Typography variant={"h6"} align={"center"}>
                                {selections.first.titleTherapyPool}
                            </Typography>
                            <Typography variant={"h6"} align={"center"}>
                                {selections.first.therapySelected.name}
                            </Typography>
                            {!!contentFirst &&
                                <Box>
                                    <PanelDivider/>
                                    {contentFirst}
                                </Box>
                            }
                        </PaperColor>
                        {/*<PanelDivider/>*/}
                    </Box>
                }
                {(!!selections?.second?.drugsSelected?.length) &&
                    <Box>
                        <PanelDivider/>
                        <PaperColor className={classes.contentBox}>
                            <Typography variant={"h6"} align={"center"}>
                                {selections.second.titleTherapyPool}
                            </Typography>
                            <Typography variant={"h6"} align={"center"}>
                                {selections.second.drugGroupsSelected.name}
                            </Typography>
                            {!!contentSecond &&
                                <Box>
                                    <PanelDivider/>
                                    {contentSecond}
                                </Box>
                            }
                        </PaperColor>
                        {/*<PanelDivider/>*/}
                    </Box>
                }
            </Box>
        );
    };

    loadCharts = () => (callback) => {
        // TODO: всегда нет графиков, пока метод не работает
        return new Promise((resolve, reject) => {
            resolve(null);
        });
    };

    forestPlotView = () => {
        updateShowPanel(true);
        setLoadChartsMethod(this.loadCharts());
    };

    render() {
        const {classes} = this.props;
        return (
            <Box className={classes.contentBox}>
                {this.paramsView()}
{/* +++2022.05.19+++ вернуть!
                {this.comparisonsView()}
*/}

                {this.drugsView()}

                {this.textsView()}
            </Box>
        );
    }
}

IbsResult.contextTypes = {
    patientDataContext: PropTypes.object,
};

IbsResult.propTypes = {
    classes: PropTypes.object.isRequired,
    getSteps: PropTypes.func.isRequired,
    getAbsoluteContrs: PropTypes.func.isRequired,
    getRelativeContrs: PropTypes.func.isRequired,
    getTherapyInfo: PropTypes.func.isRequired,
    getConclusionTexts: PropTypes.func.isRequired,
    setConclusionTexts: PropTypes.func.isRequired,
    getSelectTherapyType: PropTypes.func.isRequired,
    getSelectTherapyComb: PropTypes.func.isRequired,
    getTherapyGroupDrugs: PropTypes.func.isRequired,
    getSubgroups: PropTypes.func.isRequired,
    getCompGroups: PropTypes.func.isRequired,
    getPossGroups: PropTypes.func.isRequired,
    getSelectVars: PropTypes.func.isRequired,
    getTherapyDeviceNameWithDescr: PropTypes.func.isRequired,
    getTherapyPresumably: PropTypes.func.isRequired,
    getTherapyCompare: PropTypes.func.isRequired,
    setTherapyCompare: PropTypes.func.isRequired,
    getTouched: PropTypes.func.isRequired,
    loadTherapyCompare: PropTypes.func.isRequired,
    getThRecommend: PropTypes.func.isRequired,
    setDisableResultNext: PropTypes.func.isRequired,
    showComparisons: PropTypes.bool,
    autoSave: PropTypes.func.isRequired,
    activePatient: PropTypes.object.isRequired,
    getSelections: PropTypes.func.isRequired,
    getRevascularizationTherapy: PropTypes.func.isRequired,
    getRevascularizationTherapyServiceMessageINFOSelected: PropTypes.func.isRequired,

};

export default compose(
    withStyles(styles),
)(IbsResult);
