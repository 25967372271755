import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    FormControlLabel,
    Grid,
    Paper,
    Typography,
    FormControl,
    Checkbox,
} from "@material-ui/core";
import {PaperColorSelect, SelectType} from "../PaperColor";
import SelTextView from "../SelTextView";
import PanelDivider from "../PanelDivider";
import {injectIntl} from "react-intl";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import mainStyles from "../../styles/mainStyles";
import {getServiceMessages, sortByOrder} from "../../utils";
import IbsDrugGroupCombinationView from "./IbsDrugGroupCombinationView";
import {HelpDrugsTextsView} from "../common/HelpDrugsTextsView";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";
import type {ServiceMessage} from "../../const";
import {ChartsInputDto, ChartType, MessageType} from "../../const";
import {InfoHelp} from "../common/InfoHelp";
import {loadGetCharts} from "../../query/common";

const styles = theme => ({
    ...mainStyles(theme),
});

class IbsSelectTherapy extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);
        const {intl} = this.props;

        this.thHeads = [
            intl.formatMessage({id: 'label.hp.therapy.type.1', defaultMessage: 'Initial therapy'}),
            intl.formatMessage({id: 'label.hp.therapy.type.2', defaultMessage: 'Step 2'}),
            intl.formatMessage({id: 'label.hp.therapy.type.3', defaultMessage: 'Step 3'}),
            intl.formatMessage({id: 'label.hp.therapy.type.4', defaultMessage: 'Resistant hypertension'}),
        ];

        this.state = {};
    }

    componentDidMount() {
        this.forestPlotView();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadCharts = () => (callback) => {
        // TODO: всегда нет графиков, пока метод не работает
        return new Promise((resolve, reject) => {
            resolve(null);
        });

        const {client, activePatient, getReferents, getSubgroups} = this.props;
        const {charts} = this.state;
        const {signal} = this.abortController;
        const referents = getReferents();
        const subgroups = getSubgroups();

        if (Boolean(referents) && referents.items.length > 0 && referents.selId > 0) {
            const chartsInputDto: ChartsInputDto = {
                ids: [referents.selId],
                nosologyId: Number(activePatient.nosology) || 0,
                patientId: Number(activePatient.patient) || 0,
                type: ChartType.FOREST_GROUP,
            };
            return loadGetCharts(client, signal, chartsInputDto)
                .then((chartsResponse) => {
                    if (!!chartsResponse) {
                        charts[(subgroups || {}).selId] = chartsResponse;

                        this.setState({
                            charts: charts,
                        }, () => {
                            if (!!callback) {
                                callback();
                            } else {
                            }
                        });
                        return new Promise((resolve, reject) => {
                            resolve(chartsResponse);
                        });
                    } else {
                        return new Promise((resolve, reject) => {
                            resolve(null);
                        });
                    }
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
    };

    therapyView = () => {
        const {classes, nosologyData, getTherapyTypes, setTherapySelected, setDrugGroupCombinationSelected, isPrescriptionReadonly} = this.props;
        // const thTypes = getTherapyTypes();
        const therapyPool = ((nosologyData || {}).getTherapy || []).sort(sortByOrder)[0].therapyPools[0];

            return (
                <Grid container spacing={2} className={isPrescriptionReadonly ? classes.disabled : ''}>
                    {therapyPool.therapies
                        // .filter(thType => thType.combinations.length > 0 || thType.id === 1)
                        .map((therapy) => {
                            // console.error('+++ therapy:', therapy);
                            const serviceMessageINFO: ServiceMessage = getServiceMessages(therapy.messages, MessageType.INFO);
                            // const serviceMessageINFO: ServiceMessage = {
                            //     showService: true,
                            //     type: MessageType.INFO,
                            //     messages: [
                            //         {
                            //             type: MessageType.INFO,
                            //             payload: 'Препаратами первой линии терапии для контроля ЧСС и симптомов стенокардии являются ББ и/или БКК. IA<br/>Если симптомы стенокардии не удается успешно контролировать с помощью ББ или БКК, следует рассмотреть комбинацию ББ с ДП БКК. IIa C<br/>В качестве первой линии терапии должна быть рассмотрена комбинация ББ и ДП БКК. IIa B',
                            //         }
                            //     ]
                            // };
                            let therapyMessagesMap: Map<number, string> = new Map();
                            therapy.drugGroupCombinations.forEach((drugGroupCombination) => {
                                drugGroupCombination.drugGroups.forEach((drugGroup) => {
                                    const serviceMessageDESCRIPTIONText = (getServiceMessages(drugGroup.messages, MessageType.DESCRIPTION)?.messages || [])[0]?.payload;
                                    if (!!serviceMessageDESCRIPTIONText) {
                                        therapyMessagesMap.set(drugGroup.id, drugGroup.name + ': ' + serviceMessageDESCRIPTIONText);
                                    }
                                });
                            });
                            // const therapyMessagesArray = Array.from(therapyMessagesMap).;
                            const therapyMessagesArray: string[] = [];
                            therapyMessagesMap.forEach((therapyMessage, key) => {
                                therapyMessagesArray.push(therapyMessage.split('+').map((therapyMessage) => therapyMessage.trim()).join(', '));
                            });
                            // console.error('+++ therapyMessagesArray:', therapyMessagesArray);

                            return (<Grid key={therapy.id} item
                                      xl={6}
                                      lg={6}
                                      xs={12}
                                      sm={12}
                                      md={12}>
                                    <PaperColorSelect
                                        className={classes.contentBox}
                                        seltype={therapy.selected ? SelectType.POSITIVE : SelectType.NONE}>
    {/*
                                        <FormControlLabel control={
                                            <ServiceCheckbox
                                                color={"primary"}
                                                checked={therapy.selected}
                                                value={therapy.id}
                                                onChange={e => {
                                                    if (isPrescriptionReadonly) {
                                                        e.preventDefault();
                                                        return;
                                                    }

                                                    if (e.target.checked) {
                                                        setTherapySelected(therapy.id);
                                                    }
                                                }}
                                                showService={false}
                                            />
                                        } label={
                                            <SelTextView>{therapy.name}</SelTextView>}
                                        />
    */}

                                        {/*<SelTextView>{therapy.name}</SelTextView>*/}
                                        <Grid container direction={"row"} alignItems={"center"}>
                                            <Grid item>
                                                <SelTextView>{therapy.name}</SelTextView>
                                            </Grid>
                                            {serviceMessageINFO?.showService &&
                                                <Grid item style={{margin: '-4px 0 0 10px'}}>
                                                    <InfoHelp texts={serviceMessageINFO.messages} type={MessageType.INFO}/>
                                                </Grid>
                                            }
                                        </Grid>
                                        {/*<Typography>{thType.title !== thType.name && thType.name}</Typography>*/}
                                        <FormControl component='fieldset'>
                                            {therapy.drugGroupCombinations.map((drugGroupCombination) => (
                                                <FormControlLabel key={drugGroupCombination.id}
                                                                  label={<IbsDrugGroupCombinationView drugGroupCombination={drugGroupCombination}/>}
                                                                  onChange={() => setDrugGroupCombinationSelected(therapy.id, drugGroupCombination.id)}
                                                                  control={<Checkbox checked={drugGroupCombination.selected} value={drugGroupCombination.id} color={"primary"} />}
                                                />
                                            ))}
                                        </FormControl>
                                        {/*<ul>*/}
                                        {/*    {therapy.drugGroupCombinations.map((drugGroupCombination) => (*/}
                                        {/*        <li key={drugGroupCombination.id}>*/}
                                        {/*            <IbsDrugGroupCombinationView drugGroupCombination={drugGroupCombination}/>*/}
                                        {/*        </li>*/}
                                        {/*    ))}*/}
                                        {/*</ul>*/}
                                        {/*{thType.comment && (*/}
                                        {/*    <Typography>{thType.comment}</Typography>*/}
                                        {/*)}*/}
                                        {!!therapyMessagesArray.length &&
                                            <PanelDivider/>
                                        }
                                        {therapyMessagesArray.map((therapyMessage) =>
                                            <Typography>{therapyMessage}</Typography>
                                        )}
                                    </PaperColorSelect>
                                </Grid>
                            );
                        })
                    }
                </Grid>);
    };

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    forestPlotView = () => {
        updateShowPanel(true);
        setLoadChartsMethod(this.loadCharts());
    };

    render() {
        const {classes, getRecomentTextsView} = this.props;

        return (
            <React.Fragment>
            <Box>
                {getRecomentTextsView && getRecomentTextsView()}
                <Box className={classes.contentBox}>
                    {this.therapyView()}

                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                            <Paper className={classes.contentBox} variant={"outlined"}>
                                <HelpDrugsTextsView/>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/*{this.pscoreView()}*/}
                    {/*{this.forestPlotView()}*/}
                </Box>
            </Box>
            {/*{this.forestPlotView()}*/}
            </React.Fragment>
        );
    }
}

IbsSelectTherapy.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    getRecomentTextsView: PropTypes.func,
    getTherapyTypes: PropTypes.func.isRequired,
    setTherapySelected: PropTypes.func.isRequired,
    setDrugGroupCombinationSelected: PropTypes.func.isRequired,
    getSelectVars: PropTypes.func.isRequired,
    getReferents: PropTypes.func.isRequired,
    setSelectReferent: PropTypes.func.isRequired,
    getTherapyCombNamesWithItem: PropTypes.func.isRequired,
    getSubgroups: PropTypes.func.isRequired,
    setSubgroupSelect: PropTypes.func.isRequired,
    getTherapyParams: PropTypes.func.isRequired,
    setForestPlotView: PropTypes.func.isRequired,
    getHelpDrugsTextsView: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(IbsSelectTherapy);
