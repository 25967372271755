import React from 'react';
import PropTypes from 'prop-types';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function SnackbarNotify(props) {
  const { classes, message, variant, ...other } = props;

  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="notify-snackbar"
      message={
        <span id="notify-snackbar" className={classes.message}>
          {message}
        </span>
      }
      {...other}
    />
  );
}

SnackbarNotify.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default withStyles(styles)(SnackbarNotify);
