import React from "react";
import TherapyLine from "./TherapyLine";
import mainStyles from "../../styles/mainStyles";
import compose from 'recompose/compose';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ExpandTextPanel from "./ExpandTextPanel";
import {Box, Typography} from "@material-ui/core";
import PanelDivider from "../PanelDivider";
import TherapyTreats from "./TherapyTreats";
// import {withApollo} from "@apollo/react-hoc";
import {withApollo} from '@apollo/client/react/hoc';
import {injectIntl} from "react-intl";
import TherapyResult from "./TherapyResult";
import ForestPlot from "../research/ForestPlot";
import FBPScoreChart from "../research/FBPScoreChart";
import ReactMarkdown from "react-markdown";
import config from "../../config";
import {getFLSubgroupsRate} from "../../query/baseline";
import {getQueryResult, getSelectedTreatmentsList} from "../../utils";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

const styles = theme => ({
    ...mainStyles(theme),
});

function createBaselineComp(WrapComp) {
    return class extends React.Component {

        static propTypes = {
            lines: PropTypes.object.isRequired,
            selLineId: PropTypes.number,
            groupId: PropTypes.number,
            getThNames: PropTypes.func,
            getThData: PropTypes.func,
            setThData: PropTypes.func,
            getSelThId: PropTypes.func,
            setSelThId: PropTypes.func,
            updateContextPanel: PropTypes.func,
            getTherapyWithName: PropTypes.func.isRequired,
            getTherapyFullname: PropTypes.func.isRequired,
            isTherapyActive: PropTypes.func.isRequired,
            setTherapyTreatmentForGroupedBarChart: PropTypes.func,
            onSkipDrugSelect: PropTypes.func,
        };

        abortController = new AbortController();

        constructor(props) {
            super(props);

            this.state = {
                compStat: this.newCompStat(),
            };
        }

        componentDidMount() {
            window.scrollTo(0, 0);
        }

        getThNames = (gId) => {
            const {groupId, getThNames} = this.props;

            if (gId === undefined) {
                gId = groupId;
            }

            if (gId !== undefined && getThNames !== undefined) {
                return getThNames(gId);
            }

            return [];
        };

        getThTitle = (thName) => {
            const {groupId, getThNames} = this.props;

            if (groupId !== undefined && getThNames !== undefined) {
                const th = getThNames(groupId).find(it => it.name === thName);
                if (Boolean(th)) {
                    return th.title;
                }
            }

            return '';
        };

        getThData = (gId) => {
            const {groupId, getThData} = this.props;

            if (gId === undefined) {
                gId = groupId;
            }

            if (gId !== undefined && getThData !== undefined) {
                return getThData(gId);
            }

            return undefined;
        };

        setThData = (thData) => {
            const {groupId, setThData} = this.props;

            if (groupId !== undefined && setThData !== undefined) {
                setThData(groupId, thData);
            }
        };

        getIsSelThId = (thId, therapyName, therapyWithName) => {
            const {groupId, getIsSelThId} = this.props;

            // console.error('+++ BComp -> getIsSelThId() +++ groupId:', groupId);
            // console.error('+++ BComp -> getIsSelThId() +++ thId:', thId);
            // console.error('+++ BComp -> getIsSelThId() +++ therapyName:', therapyName);
            // console.error('+++ BComp -> getIsSelThId() +++ therapyWithName:', therapyWithName);
            if (groupId !== undefined && thId !== undefined && getIsSelThId !== undefined) {
                const result = getIsSelThId(groupId, thId, therapyName, therapyWithName);
                // console.error('+++ BComp -> getIsSelThId() +++ result:', result);
                return result;
            }

            return false;
        };

        getSelThId = (gId) => {
            const {groupId, getSelThId} = this.props;

            if (gId === undefined) {
                gId = groupId;
            }

            if (gId !== undefined && getSelThId !== undefined) {
                return getSelThId(gId);
            }

            return -1;
        };

        setSelThId = (selThId, therapyWithName, checked) => {
            const {groupId, setSelThId} = this.props;

            if (
                groupId !== undefined
                &&
                setSelThId !== undefined
            ) {
                return setSelThId(groupId, selThId, therapyWithName, checked);
            }
        };

        getRefersWithTherapy = (thName, gId) => {
            return [];
/*
            const {data: {treatments} = {}} = this.getTherapyData(thName, gId);
            return treatments;
*/
        };

        getRefersWithTherapy2 = (thName, gId, groupTreatments) => {
            // return [];
            // TODO: 2021.06.08
            // const {data: {treatments} = {}} = this.getTherapyData(thName, gId);
            // const {data: {groups} = {}} = this.getTherapyData(thName, gId);
            const therapyData = this.getTherapyData(thName, gId);

            if (therapyData && therapyData.data && therapyData.data.groups) {
                const treatments = therapyData.data.groups.find((group) => group.id === groupTreatments).treatments;
                return treatments;
            }

            return [];
        };

        getSelReferent = (thName, gId) => {
            const refers = this.getRefersWithTherapy(thName, gId);

            if (Boolean(refers)) {
                return refers.find(ref => ref.isSel);
            }

            return undefined;
        };

        getSelReferentId = (thName, gId, groupTreatments) => {
            // const refers = this.getRefersWithTherapy(thName, gId);
            const refers = this.getRefersWithTherapy2(thName, gId, groupTreatments);

            if (Boolean(refers)) {
                // return refers.findIndex(ref => ref.isSel);
                return (refers.find(ref => ref.isSel) || {}).id || -1;
            }

            return -1;
        };

        setSelReferentId = (thName, selRefId, gId, checked) => {
            // TODO: 2021.06.08
            // const refers = this.getRefersWithTherapy(thName, gId);
            const refers = this.getRefersWithTherapy2(thName, undefined, gId);
            if (refers) {
                // const oldSelId = refers.findIndex(it => it.isSel);
                const oldSelId = (refers.find(it => it.isSel) || {}).id || -1;

                if (oldSelId !== selRefId || checked !== undefined) {
                    refers.forEach((ref, i) => {
                        // ref.isSel = i === selRefId;
                        ref.isSel = ref.id === selRefId && !!checked;
                    });
                }

                const {getTherapyWithName, updateContextPanel} = this.props;
                // const {data} = this.getTherapyData(thName, gId);
                const therapyData = this.getTherapyData(thName, gId);

                // if (!Boolean(data) || (oldSelId === selRefId)) {
                if (!Boolean((therapyData || {}).data) || (oldSelId === selRefId)) {
                    updateContextPanel();
                    return;
                }

                const therapy = getTherapyWithName(thName);
                const features = this.getFeatures();

                if (Boolean(therapy) && therapy.sgNames.length > 0 && Boolean(features)) {
                    const selTreats = refers.filter(ref => ref.isSel).map(ref => ref.name);

                    const {client} = this.props;
                    const { signal } = this.abortController;

                    client.query({
                        query: getFLSubgroupsRate,
                        variables: {
                            therapyName: thName,
                            featuresIn: features,
                            groups: therapy.sgNames,
                            inTreats: selTreats,
                        },
                        context: {
                            uri: config.options.server.fl_url + config.options.server.api_uri,
                            fetchOptions: {
                              signal,
                            },
                        },
                    })
                        .then(result => getQueryResult(result?.data, 'getFLSubgroupsRate'))
                        .then(sgRate => {
                            if (Boolean((therapyData || {}).data)) {
                                (therapyData || {}).data['subgroups'] = sgRate;
                            }
                            updateContextPanel();
                        });
                } else {
                    updateContextPanel();
                }
            }
        };

        onSkipDrugSelect = (therapy, groupsTreatments) => {
            groupsTreatments.forEach((groupTreatments) => groupTreatments.treatments.forEach((treatment) => treatment.isSel = false));
            therapy.skipDrugSelect = true;
            const {updateContextPanel} = this.props;
            updateContextPanel();
        };

        getSelectLine = () => {
            const {selLineId = 1} = this.props;

            if (selLineId !== undefined) {
                return this.props.lines[selLineId];
            }

            return undefined;
        };

        isTherapyCalcLevel = (therapyName) => {
            const therapy = this.props.getTherapyWithName(therapyName);
            return Boolean(therapy) && therapy.isCalcLevel;
        };

        getTherapyData = (therapyName, id) => {
            const thData = this.getThData(id);
            if (Boolean(thData)) {
                return thData.find(th => th.name === therapyName);
            } else {
                return undefined;
            }
        };

        getTherapyCustomTreatments = (therapyName) => {
            const therapy = this.props.getTherapyWithName(therapyName);

            if (!Boolean(therapy) || !Boolean(therapy.treatments) || therapy.treatments.length === 0) {
                return [];
            }

            if (Boolean(therapy.customTreatments)) {
                return therapy.customTreatments;
            }

            if (Boolean(therapy.referent)) {
                therapy['customTreatments'] = [therapy.referent].concat(therapy.treatments);
            } else {
                therapy['customTreatments'] = [therapy.treatments];
            }

            return therapy.customTreatments;
        };

        getFeatures = () => {
            const selLine = this.getSelectLine();
            return Boolean(selLine) ? selLine.features.map(it => {
                delete it['__typename'];
                return it
            }) : [];
        };

        getRecomentTextsView = () => {
            const {lines, intl} = this.props;
            const thNames = this.getThNames().map(th => th.name);

            const treatTexts = Object.values(lines)
                .map(line => line.sgTreatments)
                .flatMap(tr => tr)
                .filter(tr => tr.active !== -1 && thNames.includes(tr.name))
                .reduce((acc, cur) => {
                    if (!(cur.fullName in acc)) {
                        acc[cur.fullName] = cur.texts;
                    }

                    return acc;
                }, {});

            let texts = [];
            Object.keys(treatTexts)
                .filter(key => treatTexts[key].length > 0)
                .forEach(key => {
                    texts.push(<Typography color={"primary"}>{key}</Typography>);
                    treatTexts[key]
                        .forEach(txt => {
                            texts.push(
                                <Typography component={"span"}>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={txt}/>
                                </Typography>
                            );
                        });
                });

            if (texts.length > 0) {
                return (<ExpandTextPanel
                    textSummary={intl.formatMessage({
                        id: 'label.bl.clinical_recommendations',
                        defaultMessage: 'Clinical recommendations(ACC/AHA/HRS и ESC Guidelines)'
                    })}
                    textSecondary={intl.formatMessage({
                        id: 'label.bl.origin',
                        defaultMessage: 'Origin: January 2019 update of the American College of Cardiology/American Heart Association (ACC/AHA) Task Force on Clinical Practice Guidelines and the Heart Rhythm Society (HRS) guidelines for the management of patients with atrial fibrillation	2016 ESC Guidelines for the management of atrial fibrillation developed in collaboration with EACTS'
                    })}>
                    {texts.map((txt, id) => (
                        <Box key={id}>
                            {txt}
                        </Box>
                    ))}
                </ExpandTextPanel>);
            } else {
                return (<Box/>);
            }
        };

        forestPlotCharts = (therapy, gId) => {
            const {classes, getTherapyFullname, activePatient} = this.props;
            const {data: {groups, subgroups} = {}} = this.getTherapyData(therapy.name, gId);

            const selectedTreatments = getSelectedTreatmentsList(groups).map((groupTreatment) => groupTreatment.treatment.name);
            const allTreatments = (groups || []).flatMap((group) => group.treatments.map((treatment) => {
                return {
                    name: treatment.name,
                    drugCompositeId: treatment.drugCompositeId,
                }
            }));

            if (!allTreatments.length) {
                return (<Box/>);
            }

            // const inTreatments = treatments
            //     .map(it => it.name);

            // const ref = this.getSelReferent(therapy.name, gId);
            // let referent = ref ? ref.name : undefined;
            // if (!Boolean(referent)) {
            //     referent = therapy.referent;
            // }
            if (!selectedTreatments.length) {
                selectedTreatments.push(therapy.referent);
            }

            const inSubgroups = ((subgroups || {}).subgroups || [])
                .filter(it => Boolean(it.selTypes) && it.selTypes.length > 0)
                .map(it => it.name);

            return (
                <Box>
                    <Typography variant={"h6"} className={classes.contentBox} align={"center"}>
                        {getTherapyFullname(therapy.name, therapy.title)}
                    </Typography>
                    <ForestPlot
                        treatments={allTreatments}
                        subgroups={inSubgroups}
                        referent={selectedTreatments}
                        therapyName={therapy.name}
                        compStat={this.getCompStat()}
                        activePatient={activePatient}
                        getSavedBuildFLForestPlotResponse={this.getSavedBuildFLForestPlotResponse}
                        setSavedBuildFLForestPlotResponse={this.setSavedBuildFLForestPlotResponse}
                    />
                </Box>
            );
        };

        pscoreChart = (therapy) => {
            const {classes, getTherapyFullname, activePatient} = this.props;
            const selLine = this.getSelectLine();
            const {data: {groups, subgroups} = {}} = this.getTherapyData(therapy.name);

            // if (!Boolean(treatments)) {
            //     return (<Box/>);
            // }

            if (!(groups || []).some((group) => (group.treatments || []).length > 0)) {
                return (<Box/>);
            }

            const hasBledIndx = selLine.features
                .findIndex(it => it.name === 'hasBled');

            return (
                <Box>
                    <Typography variant={"h6"} className={classes.contentBox} align={"center"}>
                        {getTherapyFullname(therapy.name, therapy.title)}
                    </Typography>
                    <FBPScoreChart
                        compStat={this.getCompStat()}
                        // treatments={treatments.map(it => it.name)}
                        treatments={[...new Set((groups || []).flatMap((group) => group.treatments.map((treatment) => {
                            return {
                                name: treatment.name,
                                drugCompositeId: treatment.drugCompositeId,
                            }
                        })))]}
                        patientId={Number(activePatient.patient)}
                        // treatments={treatments.filter(it => it.isSel).map(it => it.name)}
                        // subgroups={((subgroups || {}).subgroups || []).filter(it => Boolean(it.selTypes) && it.selTypes.length > 0).map(it => it.name)}
                        subgroups={((subgroups || {}).subgroups || []).map(it => it.name)}
                        therapyName={therapy.name}
                        hasBled={hasBledIndx >= 0 ? selLine.features[hasBledIndx].value : 0}
                        isCalcLevel={this.isTherapyCalcLevel(therapy.name)}
                        getFeatures={this.getFeatures}
                    />
                </Box>
            );
        };

        newCompStat = () => {
            return Math.round(Math.random() * 100000);
        };

        getCompStat = () => {
            const {compStat = 0} = this.state;
            return compStat;
        };

        updateCompStat = (callback) => {
            this.setState({
                compStat: this.newCompStat()
            }, () => {
                if (Boolean(callback)) {
                    callback();
                }
            });
        };

        getSavedBuildFLForestPlotResponse = (key) => {
            const {savedBuildFLForestPlotResponse} = this.state;
            return (savedBuildFLForestPlotResponse || {})[key];
        };

        setSavedBuildFLForestPlotResponse = (key, forestPlot, callback) => {
            this.setState(prevState => ({
                savedBuildFLForestPlotResponse: {
                    ...prevState.savedBuildFLForestPlotResponse,
                    [key]: forestPlot,
                },
            }), () => {
                if (Boolean(callback)) {
                    callback();
                }
            });
        };

        render() {
            // const $this = this;
            return (<WrapComp
                {...this.props}
                getRecomentTextsView={this.getRecomentTextsView}
                getSelectLine={this.getSelectLine}
                getThNames={this.getThNames}
                getThTitle={this.getThTitle}
                getThData={this.getThData}
                setThData={this.setThData}
                getSelThId={this.getSelThId}
                getIsSelThId={this.getIsSelThId}
                setSelThId={this.setSelThId}
                getSelRefId={this.getSelReferentId}
                getFeatures={this.getFeatures}
                getTherapyCustomTreatments={this.getTherapyCustomTreatments}
                getTherapyData={this.getTherapyData}
                forestPlotCharts={this.forestPlotCharts}
                pscoreChart={this.pscoreChart}
                updateCompStat={this.updateCompStat}
                setSelReferentId={this.setSelReferentId}
                getSelReferent={this.getSelReferent}
                getSelReferentId={this.getSelReferentId}
                onSkipDrugSelect={this.onSkipDrugSelect}
            />)
        }

    }
}

export const SelectTherapyLine = compose(
    withStyles(styles),
    injectIntl,
)(createBaselineComp(TherapyLine));

export const SelectTherapyTreats = compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(createBaselineComp(TherapyTreats));

export const TherapyResultView = compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(createBaselineComp(TherapyResult));

