import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import PropTypes from "prop-types";

const SelectTreatmentItem = withStyles(_theme => ({
  root: {
    padding: 0,
    // color: orange[700],
    // color: _theme.palette.primary.main,
    color: props => props.color || _theme.palette.primary.main,
  },
}))(ListItem);

const SelectTreatmentItemIcon = withStyles(_theme => ({
  root: {
    minWidth: 32,
    // color: orange[700],
    color: props => props.color || _theme.palette.primary.main,
  },
}))(ListItemIcon);

const StyledListItemText = withStyles(_theme => ({
  root: {
    color: props => props.color || _theme.palette.primary.main,
  },
}))(ListItemText);

function SelTreatView(props) {
  const { treatName, prefix, color } = props;

  return (
    <SelectTreatmentItem disableGutters>
      <SelectTreatmentItemIcon color={color}>
        <CheckIcon />
      </SelectTreatmentItemIcon>
      <StyledListItemText color={color}>
        {/*{`${prefix}${treatName}`}*/}
        {prefix}{treatName}
      </StyledListItemText>
    </SelectTreatmentItem>
  );
}

SelTreatView.propTypes = {
  // treatName: PropTypes.string.isRequired,
  treatName: PropTypes.any.isRequired,
  prefix: PropTypes.string,
};

SelTreatView.defaultProps = {
  prefix: '',
};

const selTreatViewStyles = {
    root: {
        color: props => props.color || 'green',
    }
};
export default withStyles(selTreatViewStyles)(SelTreatView);
