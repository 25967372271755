import React, {useEffect} from 'react';
import mainStyles from "../../styles/mainStyles";
import { makeStyles } from '@material-ui/core/styles';
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {FormattedMessage, useIntl} from "react-intl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    feedbackButton: {
        marginRight: '16px',
        marginBottom: '16px',
        verticalAlign: 'middle',
    },
    dialogTitle: {

    },
    dialogContent: {
        padding: '0 24px',
    },
    dialogActions: {
        padding: '8px 16px',
    },
}));

export const AddFeedback = (props) => {
    const intl = useIntl();

    const {
        activeStep,
        steps,
        nosologyData,
        patientDataContext,
        feedbacks,
    } = props;
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [feedback, setFeedback] = React.useState(feedbacks[activeStep] || '');

    useEffect(() => {
        setFeedback(feedbacks[activeStep] || '');
    }, [activeStep, feedbacks]);

    const handleClick = (event) => {
        setDialogOpen((prev) => !prev);
    };

    const onSave = (event) => {
        // console.error('+++ feedback:', feedback);
        // console.error('+++ feedbacks:', feedbacks);
        // console.error('+++ activeStep:', activeStep);
        // console.error('+++ steps:', steps);
        // console.error('+++ nosologyData:', nosologyData);
        // console.error('+++ patientDataContext:', patientDataContext);
        setDialogOpen(false);
    };

    const onChangeFeedback = (event: any) => {
        setFeedback(event.target.value);
        feedbacks[activeStep] = event.target.value;
    };

    return (
        <>
            <Button color={"primary"}
                    variant={"outlined"}
                    className={classes.feedbackButton}
                    onClick={handleClick}
            >
                <FormattedMessage id='label.feedback' defaultMessage='feedback' />
            </Button>

            <Dialog
                fullWidth
                maxWidth={'sm'}
                // fullScreen
                // onEscapeKeyDown={() => setDialogOpen(false)}
                // onBackdropClick={() => setDialogOpen(false)}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="feedback-dialog"
            >
                <DialogTitle id="feedback-dialog" className={classes.dialogTitle}>
                    <FormattedMessage id='label.feedback' defaultMessage='feedback' />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {/*TODO: закоментировал, т.к. не работает с новой моделью данных*/}
                    <TextField
                        // xl={12} lg={12} md={12} sm={12} xs={12}
                        className={classes.textarea}
                        style={{width: '100%'}}
                        multiline
                        minRows={10}
                        maxRows={20}
                        value={feedback}
                        onChange={onChangeFeedback}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        color="primary"
                    >
                        {
                            intl.formatMessage({
                                id: 'label.close',
                                defaultMessage: 'Close'
                            })
                        }
                    </Button>
                    <Button
                        onClick={() => onSave()}
                        color="primary"
                    >
                        {
                            intl.formatMessage({
                                id: 'label.save',
                                defaultMessage: 'Save'
                            })
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
