import React from "react";
import PropTypes from "prop-types";
import GroupedBarChart from "./GroupedBarChart";
import {Box, Grid} from "@material-ui/core";
import SelTextView from "../SelTextView";
import {FormattedMessage} from "react-intl";
import PanelDivider from "../PanelDivider";
import {TypographySubtitle} from "../../pages/CommonElements";
import {populateHPChart} from "../../utils";
import {GroupedBarChartColors} from "../../const";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

class GroupedBarChartExt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            finalComb: {}
        }
    }

    componentDidMount() {
        const {dataIn, getFinalComb} = this.props;
        getFinalComb(dataIn, this.callbackFn);
    }

    callbackFn = response => {
        this.setState({
            finalComb: response,
        });
    };

    render() {
        const {finalComb} = this.state;
        const {intl, getScRiskColor, classes} = this.props;
        let comps = (finalComb || {}).comps;
        const chartData = populateHPChart(comps, intl, getScRiskColor(), GroupedBarChartColors.BLUE);

        return (
            <Box className={classes.groupedBarChartExt}>
                {(comps && comps.length > 0 && ((finalComb || {}).id !== -1)) &&
                <Box>
                    <PanelDivider/>
                    {!chartData && (
                        <React.Fragment>
                            <Grid container direction={"row"}>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><FormattedMessage id="label.outcome" defaultMessage="Outcome"/></Grid>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><FormattedMessage id="label.risk" defaultMessage="Initial risk"/></Grid>
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><FormattedMessage id="label.risk.therapy" defaultMessage="Risk after therapy"/></Grid>
                            </Grid>
                            {comps && comps.map((comp, i) => (
                                <Grid container direction={"row"} key={i} className={classes.resultParam}>
                                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.resultParamName}>*** {comp.name}</Grid>
                                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.resultParamValue}>*** {comp.riskPl}%</Grid>
                                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.resultParamValue}>*** {comp.riskTh}%</Grid>
                                </Grid>
                            ))}
                        </React.Fragment>
                    )}
                    {chartData &&
                    // <Grid item xs={12}>
                    //     <SelTextView align={'center'}>
                    //         <FormattedMessage id="label.chart.chosen_therapy.header" defaultMessage="Forecast for the chosen therapy" />
                    //         <FormattedMessage id="label.chart.chosen_therapy.header" defaultMessage="Forecast for the chosen therapy" />
                    //     </SelTextView>
                    //     <Box>
                    //         <GroupedBarChart {...this.props} />
                    //     </Box>
                    // </Grid>
                    <Grid container direction={"column"}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <SelTextView align={'center'}>
                                <FormattedMessage id="label.chart.chosen_therapy.header" defaultMessage="Forecast for the chosen therapy" />
                            </SelTextView>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <SelTextView align={'center'}>
                                <Typography>{finalComb.name}</Typography>
                            </SelTextView>
                        </Grid>
                        <Box>
                            <GroupedBarChart useResizeHandler={true} responsive={true} chartData={chartData} {...this.props} />
                        </Box>
                    </Grid>
                    }
                </Box>
            }
                {(!finalComb || ((finalComb || {}).id === -1)) &&
                    <Grid container direction={"row"} className={classes.resultParam}>
                        <TypographySubtitle component={'span'}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={((finalComb || {}).message || {}).payload}/>
                        </TypographySubtitle>
                    </Grid>
                }
            </Box>
        );
    }
}

GroupedBarChartExt.propTypes = {
    dataIn: PropTypes.object.isRequired,
    getFinalComb: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    useResizeHandler: PropTypes.bool,
    responsive: PropTypes.bool,
};

export default GroupedBarChartExt;
