import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import mainStyles from '../styles/mainStyles';
import RootContainer from '../comp/RootContainer';
import {
  Avatar,
  Card,
  CardHeader,
  TableCell,
  TableRow,
  CardActions,
  Button,
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import PanelDivider from '../comp/PanelDivider';
import ProfileEdit from '../comp/profile/ProfileEdit';
import { getUserProfile, updateUserProfile } from "../query/user";
// import { withApollo } from '@apollo/react-hoc';
import { withApollo } from '@apollo/client/react/hoc';
import { FormattedMessage, injectIntl } from 'react-intl';
import {getQueryResult} from "../utils";
import config from "../config";

const styles = theme => ({
  ...mainStyles(theme),
});

class ProfileInformation extends React.Component {

  abortController = new AbortController();

  constructor(props) {
    super(props);
    this.state = {
      profileFields: {},
    };
  }

  componentDidMount() {
    this.loadUserProfile();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  loadUserProfile = () => {
    const { client } = this.props;
    const { signal } = this.abortController;

    client.query({
      query: getUserProfile,
      context: {
        uri: config.options.server.api_url + config.options.server.api_uri,
        fetchOptions: {
          signal,
        },
      },
    })
      // .then(result => (result.data || {}).getUserProfile)
      // .then(result => JSON.parse(JSON.stringify(result)))
      .then(result => getQueryResult(result?.data, 'getUserProfile'))
      .then(profile => {
        if (profile?.profile) {
          let fields = {};

          Object.keys(profile.profile)
            .filter(key => key !== 'id' && key !== '__typename')
            .filter(key => profile.profile[key])
            .forEach(key => {
              switch (key) {
                case 'spec':
                case 'city':
                  fields[key] = profile.profile[key].name;
                  break;
                case 'skills':
                  let skills = profile.profile[key].map(it => it.name).join(', ');
                  if (skills.length > 0) {
                    fields[key] = skills;
                  }
                  break;
                default:
                  fields[key] = profile.profile[key];
              }
            });

          this.setState({
            userProfile: profile,
            profileFields: fields,
          });

        } else {
          this.setState({
            userProfile: profile,
          });
        }
      });
  };

  onProfileChange = (name, value) => {
    const { profileFields } = this.state;

    if (value && value.length > 0) {
      profileFields[name] = value;
    } else {
      delete profileFields[name];
    }

    this.setState({
      profileFields: profileFields,
    })
  };

  onProfileSave = (e) => {
    const { profileFields } = this.state;
    const { client, intl } = this.props;

    if (Object.keys(profileFields).length === 0) {
      return;
    }

    client.mutate({
      mutation: updateUserProfile,
      errorPolicy: 'all',
      context: {
        uri: config.options.server.api_url + config.options.server.api_uri,
      },
      variables: {
        profile: profileFields,
      },
    }).then(({ data, _ }) => {
      if (data && data.updateUserProfile) {
        this.showNotify(intl.formatMessage({
          id: 'message.data.save.success',
          defaultMessage: 'Save success.',
        }), 'success');
        this.loadUserProfile();
      }
    });
  };

  showNotify = (message, type) => {
    const { showNotify } = this.context;
    if (showNotify) {
      showNotify(message, type);
    }
  };

  render() {
    const { classes, intl } = this.props;
    const { userProfile, profileFields } = this.state;

    return (
      <RootContainer>
        {(userProfile && userProfile.user) ?
          (<React.Fragment>
          <Card>
            <CardHeader
              avatar={
                <Avatar aria-label={"Recipe"}>R</Avatar>
              }
              title={intl.formatMessage({id: 'label.user.profile.header', defaultMessage: 'User profile'})}
              subheader={intl.formatMessage({id: 'label.user.profile.subheader', defaultMessage: 'User data'})}
            />
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id='label.user.login' defaultMessage='login' />
                    </TableCell>
                    <TableCell>{userProfile.user.username}</TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell>
                      <FormattedMessage id='label.user.role' defaultMessage='role' />
                    </TableCell>
                    <TableCell>{userProfile.role.name}</TableCell>
                  </TableRow> */}
                  {userProfile.user.firstname ?
                      (<TableRow>
                        <TableCell>
                          <FormattedMessage id='label.user.name' defaultMessage='name' />
                        </TableCell>
                        <TableCell>{userProfile.user.firstname}</TableCell>
                    </TableRow>) : null
                  }
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <PanelDivider />
          <Card>
            <CardHeader title={intl.formatMessage({id: 'label.information', defaultMessage: 'Information'})} />
            <CardContent>
              <ProfileEdit onFieldChange={this.onProfileChange} fields={profileFields} />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button onClick={this.onProfileSave} color="primary">
                <FormattedMessage id='label.save' defaultMessage='Save' />
              </Button>
            </CardActions>
          </Card>
          </React.Fragment>) : null
        }
      </RootContainer>
    );
  }
}

ProfileInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

ProfileInformation.contextTypes = {
  showNotify: PropTypes.func,
};

export default compose(
  withStyles(styles),
  withApollo,
  injectIntl,
)(ProfileInformation);
