import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import deburr from 'lodash/deburr';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

class AutocompleteDownshift extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      // inputValue: '',
    };
  }

  getSuggestions = (value) => {
    const { suggestions } = this.props;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
  };

  handleInputChange = event => {
    let value = event.target.value ? event.target.value : '';
    this.setState({ inputValue: value });

    if (this.props.onChange) {
      this.props.onChange(event.target.id, value);
    }
  };

  onChange = (id, value) => {
    /*
    this.setState({
      inputValue: value,
    });
    */
    if (this.props.onChange) {
      this.props.onChange(id, value);
    }
  };

  render() {
    const { classes, placeholder, inputProps, id, value } = this.props;
    // const { inputValue } = this.state;

    return (
      <Downshift
        inputValue={value}
        selectedItem=''
        inputId={id}
        onChange={val => this.onChange(id, val)}>
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => (
            <div className={classes.container}>
              {renderInput({
                classes,
                ...inputProps,
                InputProps: getInputProps({
                  placeholder: placeholder ? placeholder : '',
                  onChange: this.handleInputChange,
                }),
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {this.getSuggestions(inputValue).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.label }),
                        highlightedIndex,
                        selectedItem,
                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
      </Downshift>
    );
  }
}

AutocompleteDownshift.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  suggestions: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
};

AutocompleteDownshift.defaultProps = {
  value: '',
};

export default compose(
  withStyles(styles),
)(AutocompleteDownshift);
