import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import mainStyles from "../../styles/mainStyles";
import Box from "@material-ui/core/Box";
import Markdown from './Markdown';
import {FormattedMessage} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Card, CardActions, CardHeader, Paper} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {getContentsManual} from "../../utils";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
    manualDialog: {
        // '$container': {
        //     '$root': {
        //         minHeight: 'calc(100% - 64px)',
        //     }
        // }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        paddingTop: 0,
    },
    contentPaper: {
        padding: theme.spacing(2),
        border: 'solid 2px #0f7fa5',
    },
    // root: {
    //     backgroundColor: 'red',
    //     '& > .MuiBox-root': {
    //         '& > .MuiCard-container > .MuiPaper-root': {
    //             '& > .MuiCardContent-root': {
    //                 paddingTop: 0,
    //             }
    //         },
    //     },
    // },
}));

const ManualView = (props) => {
    const classes = useStyles();
    const {
        // contents,
        setOpen,
        showLoading,
        hideLoading,
    } = props;
    const contents = getContentsManual();

    const [pageNumber, setPageNumber] = React.useState(1);
    const [
        numPages,
        // setNumPages
    ] = React.useState(contents ? contents.length : null);
    const [content, setContent] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const updateLoading = (loading) => {
        if (loading) {
            setTimeout(() => showLoading());
        } else {
            setTimeout(() => hideLoading());
            // hideLoading();
        }

        setLoading(loading);
    }

    const loadContent = () => {
        if (!loading && contents && contents.length > 0 && !((content || {})[pageNumber])) {
            updateLoading(true);
            fetch(`${contents[pageNumber - 1]}`, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
            })
                .then(response => response.text())
                .then((loadedContent) => {
                    const contentWithPageNumber = {
                        ...content,
                        [pageNumber]: loadedContent,
                    };
                    setContent(contentWithPageNumber);
                    updateLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    updateLoading(false);
                });
        }
    }

/*
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }
*/

/*
    const handleKeyPress = (event) => {
        if ( event.key === "ArrowRight" ) {
            next();
        }
        if ( event.key === "ArrowLeft" ) {
            prev();
        }
        if ( event.key === "Escape" ) {
            setOpen(false);
        }
        if ( event.key === "Home" ) {
            // setPageNumber(1);
            prev(1);
        }
        if ( event.key === "End" ) {
            // setPageNumber(numPages);
            next(numPages);
        }
    }
*/

    const prev = (page: number) => {
        if (pageNumber !== 1) {
            setPageNumber(page ? page : pageNumber - 1);
            loadContent();
        }
    }

    const next = (page: number) => {
        if (pageNumber !== numPages) {
            setPageNumber(page ? page : pageNumber + 1);
            loadContent();
        }
    }

    loadContent();

    return (
        <Card className={classes.contentBoxBottomSpace}>
            <CardHeader
                // avatar={
                //     <Avatar aria-label="recipe" className={classes.avatar}>
                //         R
                //     </Avatar>
                // }
                action={
                    <IconButton aria-label="settings" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                }
                title="Инструкция"
                // subheader="Какой-то подзаголовок..."
            />

            <CardContent className={classes.content}>
                {content && content[pageNumber] &&
                    <Paper elevation={4} className={classes.contentPaper}>
                        <Markdown>{content[pageNumber]}</Markdown>
                    </Paper>
                }
                {/*<Typography variant="body2" color="textSecondary" component="p">*/}
                {/*    This impressive paella is a perfect party dish and a fun meal to cook together with your*/}
                {/*    guests. Add 1 cup of frozen peas along with the mussels, if you like.*/}
                {/*</Typography>*/}
            </CardContent>

            <CardActions>
                <div className={classes.grow} />
                <Button
                    disabled={pageNumber === 1}
                    onClick={() => prev()}
                    color="primary"
                    // variant={'contained'}
                >
                    <FormattedMessage id={'label.manual.back'} defaultMessage={'Back'} />
                </Button>
                <Box>{pageNumber} / {numPages}</Box>
                <Button
                    disabled={pageNumber === numPages}
                    onClick={() => next()}
                    color="primary"
                    // variant={'contained'}
                >
                    <FormattedMessage id={'label.manual.next'} defaultMessage={'Next'} />
                </Button>
                {/*<Button*/}
                {/*    onClick={() => setOpen(false)}*/}
                {/*    color="primary"*/}
                {/*>*/}
                {/*    <FormattedMessage id={'label.close'} defaultMessage={'Close'} />*/}
                {/*</Button>*/}
            </CardActions>
        </Card>
    );
};

export default ManualView;
