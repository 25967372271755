import React from "react";
import PropTypes from 'prop-types';
import {Box, Checkbox, FormControl, FormControlLabel, Grid, Paper, Typography,} from "@material-ui/core";
import SelTextView from "../SelTextView";
import PanelDivider from "../PanelDivider";
import {FormattedMessage, injectIntl} from "react-intl";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import { withApollo } from '@apollo/client/react/hoc';
import DeviceView from "../DeviceView";
import TherapyCombView from "../TherapyCombView";
import {getChartB, loadGetPScore} from "../../query/HeartFailureBaseline";
import red from "@material-ui/core/colors/red";
import {getQueryResult, getServiceMessages, sortByOrder} from "../../utils";
import {HelpDrugsTextsView} from "../common/HelpDrugsTextsView";
import ServiceCheckbox from "../button/ServiceCheckbox";
import {MessageType} from "../../const";
import EffSafChart from "../hp/EffSafChart";
import {setLoadChartsMethod, updateShowPanel} from "../../service/expand-charts-panel-service";
import config from "../../config";
import TherapyView from "../TherapyView";

const styles = theme => ({
    ...mainStyles(theme),
    noRecommendSet: {
        color: red[700],
    },
});

class HeartFailureSelectComb extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            charts: [],
        };
    }

    componentDidMount() {
        this.forestPlotView();
        this.loadAll();

        this.setSelected();
    }

    setSelected = () => {
        const {
            getSteps,
            nosologyData,
            updateContextPanel,
        } = this.props;
        const steps = getSteps();
        // console.error('+++ steps:', steps);
        // console.error('+++ nosologyData:', nosologyData);
        if (
            !nosologyData.touched
            &&
            !!steps.selThId
        ) {
            const selStep = steps.items.filter(step => steps.selThId === step.id)[0];
            // console.error('+++ selStep:', selStep);
            selStep.therapies.forEach((therapy) => {
                therapy.sets.forEach((set) => {
                    // console.error('+++---+++--- set:', set);
                    if (set.selected) {
                        // FIXME: раскомментировать -- сделано на время +++2021.11.10+++
                        nosologyData.steps.selCombId = set.id;
                        nosologyData.steps.selTypeId = therapy.id;
                        delete nosologyData.steps.selDrugs;
                    }
                });
            });
            selStep.supportTherapies.forEach((supportTherapy) => {
                supportTherapy.devices.forEach((device) => {
                    if (device.selected) {
                        nosologyData.steps.selDeviceId = device.id;
                        nosologyData.steps.selDeviceTypeId = supportTherapy.id;
                    }
                });
            });

            updateContextPanel();
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadAll = (comb) => {
        // if (Boolean(comb) && comb.combinations.length > 0) {
        // TODO: +++2021.12.03+++
        //     this.loadCharts();
            this.loadPscore();
        // }
    }

    loadCharts = (callback) => {
        const {signal} = this.abortController;

        const {
            client,
            // getTherapyComb,
            getSteps,
        } = this.props;
        // const thComb = getTherapyComb();

        const steps = getSteps();
        const selStep = steps.items.filter(step => steps.selThId === step.id)[0];
        const ids = [];
        ((selStep || {}).therapies || []).forEach((type) => {
            (type.sets || []).forEach((set) => {
                ids.push(set.id);
            });
        });

        if (ids && ids.length > 0) {
            return client.query({
                query: getChartB,
                variables: {
                    ids: ids,
                },
                context: {
                    uri: config.options.server.heart_failure_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                // .then(result => (result.data || {}).getChartB)
                // .then(result => JSON.parse(JSON.stringify(result)))
                .then(result => getQueryResult(result?.data, 'getChartB'))
                .then(charts => {
                    this.setState({
                        charts: charts,
                    }, () => {
                        // TODO: +++2021.12.03+++
                        //  this.forestPlotView();
                        if (!!callback) {
                            callback();
                        }
                    });
                    return new Promise((resolve, reject) => {
                        resolve(charts);
                    });
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
    };

    loadPscore = () => {
        const { client, getSteps } = this.props;
        const { signal } = this.abortController;

        const steps = getSteps();
        const selStep = steps.items.filter(step => steps.selThId === step.id)[0];
        const ids = [];
        ((selStep || {}).therapies || []).forEach((type) => {
            (type.sets || []).forEach((set) => {
                ids.push(set.id);
            });
        });

        loadGetPScore(client, signal, ids)
            .then((pscore) => {
                this.setState({
                    pscore: pscore,
                });
            });
    };

    onTherapyCombChange = (thCombId, thTypeId) => (e) => {
        const { isPrescriptionReadonly } = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        if (e.target.checked) {
            const {setSelectTherapyComb} = this.props;
            setSelectTherapyComb(thCombId, thTypeId);
        }
    };

    onTherapyDeviceChange = (thDeviceId, thTypeId) => (e) => {
        const { isPrescriptionReadonly } = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        const {setSelectTherapyDevice} = this.props;
        setSelectTherapyDevice(thDeviceId, thTypeId);
    };

    combView = () => {
        const {
            classes,
            // getTherapyComb,
            getTherapyCombNamesWithItem,
            getSteps,
            isPrescriptionReadonly,
        } = this.props;
        // const thComb = getTherapyComb();
        const steps = getSteps();
        // console.error('+++---+++ steps:', steps);

        if (!steps.selThId) {
            return (<Box/>);
        }

        // console.error('+++---+++ steps.selThId:', steps.selThId);
        // console.error('+++---+++ steps:', steps);
        const selStep = steps.items.filter(step => steps.selThId === step.id)[0];
        // const gNames = thComb.items.map(it => getTherapyCombNamesWithItem(it));
        // console.error('+++---+++ selStep:', selStep);

        return (
            <Grid container spacing={2} className={isPrescriptionReadonly ? classes.disabled : ''}>

                {
                    selStep.therapies
                        .sort(sortByOrder)
                        .map((therapy) =>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={therapy.id}>
                                <Paper className={classes.contentBox}
                                       variant={"outlined"}>
                                    <SelTextView>
                                        <TherapyView therapy={therapy} textBold={true} />
                                    </SelTextView>
                                    <PanelDivider/>
                                    {therapy.sets && this.hasCombs(therapy.sets)
                                        ?
                                        <FormControl component='fieldset'>
                                            {
                                                therapy.sets
                                                    .sort(sortByOrder)
                                                    .map((set) => {
                                                        const serviceWarningCheckBox = getServiceMessages(set.messages, MessageType.WARNING);

                                                        return (<FormControlLabel
                                                            key={set.id}
                                                            label={<TherapyCombView set={set}
                                                                                    therapy={getTherapyCombNamesWithItem(set)}
                                                                                    className={set.isNoRecommend ? classes.noRecommendSet : ''}/>}
                                                            onChange={this.onTherapyCombChange(set.id, therapy.id)}
                                                            control={<ServiceCheckbox
                                                                checked={/*type.id === steps.selTypeId && */set.id === steps.selCombId}
                                                                value={set.id}
                                                                color={"primary"}
                                                                showService={serviceWarningCheckBox.showService}
                                                                text={serviceWarningCheckBox.messages}
                                                                type={serviceWarningCheckBox.type}
                                                            />}
                                                        />)
                                                    })
                                            }
                                        </FormControl>
                                        :
                                        <Typography>
                                            <FormattedMessage id='label.bl.therapy_not_recoment'
                                                              defaultMessage='Therapy is not required'/>
                                        </Typography>
                                    }
                                </Paper>
                            </Grid>)
                }
                {
                    selStep.supportTherapies
                        .map((supportTherapy) =>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={supportTherapy.id}>
                                <Paper className={classes.contentBox}
                                       variant={"outlined"}>
                                    <SelTextView>
                                        {supportTherapy.name}
                                    </SelTextView>
                                    <PanelDivider/>
                                    <FormControl component='fieldset'>
                                        {
                                            supportTherapy.devices
                                                .map((device) =>
                                                    <FormControlLabel
                                                        key={device.id}
                                                        label={<DeviceView device={device}/>}
                                                        onChange={this.onTherapyDeviceChange(device.id, supportTherapy.id)}
                                                        control={<Checkbox checked={device.id === steps.selDeviceId} value={device.id} color={"primary"}/>}
                                                    />)
                                        }
                                    </FormControl>
                                </Paper>
                            </Grid>)
                }
            </Grid>
        );
    };

    hasCombs = (sets) => {
        return sets.some(set => set.drugGroups.length > 0);
    }

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    forestPlotView = () => {
/*
        const {
            intl,
            setForestPlotView,
        } = this.props;
        const { charts } = this.state;

        const _charts = charts.map((chart) => {
            chart.path = options.server.heart_failure_url + '/chart/' + chart.path;
            return chart;
        });
*/

        updateShowPanel(true);
        setLoadChartsMethod(this.loadCharts);

/*
        const forestPlotView = (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                charts={_charts}
                loadCharts={this.loadCharts}
                needUpdate={new Date().getTime()}
                setExpandedCharts={this.setExpandedCharts}
            >
            </ExpandChartsPanel>
        );

        // return forestPlotView;
        setForestPlotView(forestPlotView);
*/
    };

    effSafView = () => {
        const { classes } = this.props;
        const { pscore } = this.state;

        if (!!pscore) {
            // console.error('+++ pscore:', pscore);
            // console.error('+++ pscore.points:', pscore.points);
            // console.error('+++ pscore.points?.length:', pscore.points?.length);
            // console.error('+++ (!!pscore && pscore.points?.length > 0):', (!!pscore && pscore.points?.length > 0));
        }

        if (!!pscore && pscore.points?.length > 0) {
            return (
                <Box>
                    <PanelDivider />
                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                        <EffSafChart
                            points={pscore.points}
                            // title={intl.formatMessage({ id: "label.effsaf", defaultMessage: "Efficacy/Safety"})}
                            title={pscore.xName + '/' + pscore.yName}
                            xaxis={pscore.xName}
                            yaxis={pscore.yName}
                        />
                    </Paper>
                </Box>
            );
        } else {
            return (
                <Box>
                    <PanelDivider />
                    <Paper className={classes.contentBoxCenter} variant={"outlined"}>
                        <Typography>
                            <FormattedMessage id="message.data.empty" defaultMessage="Data not available" />
                        </Typography>
                    </Paper>
                </Box>
            );
        }
    };

    render() {
        const {classes, getRecomentTextsView} = this.props;

        return (
            <Box>
                {getRecomentTextsView && getRecomentTextsView()}
                <Box className={classes.contentBox}>
                    {this.combView()}

                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                            <Paper className={classes.contentBox} variant={"outlined"}>
                                {/*{getHelpDrugsTextsView()}*/}
                                <HelpDrugsTextsView />
                            </Paper>
                        </Grid>
                    </Grid>

                    {this.effSafView()}
                    {/*{this.forestPlotView()}*/}
                </Box>
            </Box>
        );
    }
}

HeartFailureSelectComb.propTypes = {
    isPrescriptionReadonly: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    getSteps: PropTypes.func.isRequired,
    getTouched: PropTypes.func.isRequired,
    getParams: PropTypes.func.isRequired,
    getRecomentTextsView: PropTypes.func,
    getSelectVars: PropTypes.func.isRequired,
    getTherapyComb: PropTypes.func.isRequired,
    setTherapyComb: PropTypes.func.isRequired,
    setSelectTherapyComb: PropTypes.func.isRequired,
    setSelectTherapyDevice: PropTypes.func.isRequired,
    getSelectTherapyType: PropTypes.func.isRequired,
    getTherapyCombNamesWithItem: PropTypes.func.isRequired,
    getTherapyDeviceNameWithDescr: PropTypes.func.isRequired,
    getSubgroupSelect: PropTypes.func.isRequired,
    setForestPlotView: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(HeartFailureSelectComb);
