import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import Typography from "@material-ui/core/Typography";
import {Checkbox, FormControlLabel, Paper} from "@material-ui/core";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import mainStyles from "../../styles/mainStyles";

interface AgreementsConfirms {
    [id: number]: boolean,
}

const styles = theme => ({
    ...mainStyles(theme),
    agreement: {
        borderBottom: '1px solid black',
    },
    contentWrapper: {
        paddingBottom: '0 !important',
    },
    contentWrapperMargin: {
        marginBottom: theme.spacing(2),
    },
    content: {
        paddingBottom: '0 !important',
        '& > p': {
            padding: '0 !important',
            margin: '0 !important',
            marginBlock: '0 !important',
        }
    },
});

const ConfirmProductPurchaseDialog = (props) => {
    const { open, setOpen, onConfirm, product, intl, classes } = props;

    const getResetAgreements = (): AgreementsConfirms => {
        const _agreementsConfirms: AgreementsConfirms = {};
        product.agreements.forEach((agreement, id) => {
            if (!!agreement.accept) {
                _agreementsConfirms[id] = false;
            }
        });

        return _agreementsConfirms;
    }

    const [agreementsConfirms, setAgreementsConfirms] = useState(getResetAgreements());

    const isAllAgreementsConfirmed = (): boolean => {
        return Object.keys(agreementsConfirms).every((id) => !!agreementsConfirms[Number(id)]);
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{product.name}</DialogTitle>
            {/*<DialogContent>{children}</DialogContent>*/}
            <DialogContent>
                {/*{ReactHtmlParser(text)}*/}
                {product.agreements.map((agreement, id) => (
                    // <Box className={classes.agreement} key={id}>
                    <Paper className={`${classes.contentBox} ${classes.contentWrapperMargin} ${!!agreement.accept ? classes.contentWrapper : ''}`} variant={"outlined"}>
                        <Typography variant="body1" component="span" className={classes.content}>
                            {agreement.content && <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={agreement.content} />}
                        </Typography>
                        {!!agreement.accept &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={agreementsConfirms[id]}
                                        value={id}
                                        onChange={e => {
                                            setAgreementsConfirms({...agreementsConfirms,  [id]: e.target.checked});
                                        }}
                                    />
                                }
                                label={agreement.accept}
                            />
                        }
                    </Paper>
                    )
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    {intl.formatMessage({id: 'label.cancel', defaultMessage: 'Cancel'})}
                </Button>
                <Button
                    onClick={() => {
                        setOpen(false);
                        if (!!onConfirm) {
                            onConfirm(product);
                        }
                    }}
                    color="primary"
                    disabled={!isAllAgreementsConfirmed()}
                >
                    {intl.formatMessage({id: 'label.continue', defaultMessage: 'Continue'})}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default compose(
    withStyles(styles),
    injectIntl,
)(ConfirmProductPurchaseDialog);
