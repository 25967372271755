import React from "react";
import {withStyles} from '@material-ui/core/styles';
import mainStyles from "../../styles/mainStyles";
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import {withApollo} from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import {Box, Grid, Typography,} from "@material-ui/core";
import {buildFLForestPlot,} from '../../query/research';
import config from "../../config";
import {getQueryResult} from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import PanelDivider from "../PanelDivider";

const styles = theme => ({
    ...mainStyles(theme),
    content: {
        padding: theme.spacing(2),
    },
});

class ForestPlot extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.getForestPlot();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.compStat !== this.props.compStat || prevProps.referent !== this.props.referent) {
            this.getForestPlot();
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    getForestPlot = () => {
        const {
            client,
            treatments,
            subgroups,
            therapyName,
            referent,
            activePatient,
            getSavedBuildFLForestPlotResponse
        } = this.props;
        const {signal} = this.abortController;

        const key = referent + '_' + therapyName;
        const savedBuildFLForestPlotResponse = !!getSavedBuildFLForestPlotResponse ? getSavedBuildFLForestPlotResponse(key) : undefined;

        if (!savedBuildFLForestPlotResponse) {
            this.setState({
                loading: true
            });

            client.query({
                query: buildFLForestPlot,
                variables: {
                    patientId: Number(activePatient.patient) || 0,
                    forestPlotIn: {
                        ref: referent,
                        treatments: treatments,
                        subgroups: subgroups,
                        therapyName: therapyName,
                    },
                },
                context: {
                    uri: config.options.server.fl_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                .then(result => getQueryResult(result?.data, 'buildFLForestPlot'))
                .then(forestPlots => {
                    this.setState({
                        loading: false
                    });
                    this.setStateForestPlotData(key, forestPlots);
                });
        } else {
            this.setStateForestPlotData(key, savedBuildFLForestPlotResponse);
        }
    };

    setStateForestPlotData = (key, forestPlots: any[]) => {
        if (!!forestPlots && forestPlots.some((item) => !!item?.data?.url)) {
            const {setSavedBuildFLForestPlotResponse} = this.props;
            if (!!setSavedBuildFLForestPlotResponse) {
                setSavedBuildFLForestPlotResponse(key, forestPlots);
            }

            this.setState({
                forestPlotsData: forestPlots,
            });
        }
    }

    render() {
        const {classes} = this.props;
        const {
            forestPlotsData,
            loading,
        } = this.state;

        return (
            <Box>
                {!!loading &&
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <Box className={classes.loading}><CircularProgress/></Box>
                        </Grid>
                    </Grid>
                }
                {!loading && forestPlotsData?.map((forestPlotsData, index) =>
                    <Box className={classes.content} key={index}>
                        <Typography variant='h6' align='center'>
                            {forestPlotsData?.data?.title}
                        </Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container justifyContent='center'>
                                    <Grid item>
                                        {!!forestPlotsData?.data?.url &&
                                            <Box textAlign={'center'}>
                                                <img src={forestPlotsData?.data?.url} alt='Forest Plot' className={classes.imgAutoWidth}/>
                                            </Box>
                                        }
                                        {!forestPlotsData?.data?.url &&
                                            <Box textAlign={'center'}>
                                                <FormattedMessage id={'message.data.no_data'} defaultMessage={'No data'}/>
                                            </Box>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {(forestPlotsData.length > 1) &&
                            <PanelDivider/>
                        }
                    </Box>
                )}
            </Box>
        );
    }
}

ForestPlot.propTypes = {
    classes: PropTypes.object.isRequired,
    activePatient: PropTypes.object.isRequired,
    treatments: PropTypes.arrayOf(PropTypes.object).isRequired,
    subgroups: PropTypes.arrayOf(PropTypes.string).isRequired,
    referent: PropTypes.arrayOf(PropTypes.string).isRequired,
    therapyName: PropTypes.string.isRequired,
    compStat: PropTypes.number,
    getSavedBuildFLForestPlotResponse: PropTypes.func,
    setSavedBuildFLForestPlotResponse: PropTypes.func,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(ForestPlot);
