import PropTypes from 'prop-types';
import React from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box,
} from '@material-ui/core';
import mainStyles from '../styles/mainStyles';
import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Typography from "@material-ui/core/Typography";
import {loadGetPurchases} from "../query/billing";
import type {Purchase} from "../const";
import {withApollo} from "@apollo/client/react/hoc";
import PurchaseView from "./PurchaseView";
import {dateCompare} from "../utils";
import {FormattedMessage, injectIntl} from "react-intl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

const styles = theme => ({
    ...mainStyles(theme),
    contentBox: {
        // paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    content: {
        width: '250px',
        maxWidth: '250px',
        marginRight: '20px',
        height: '100%',
    },
    nodata: {
        padding: '20px',
    },
    tariff: {
        display: 'flex',
    },
    tariffsHead: {
        fontWeight: '600',
        marginBottom: '30px'
    },
    tariffIcon: {
        width: 'auto',
        height: '40px',
        color: '#459220',
        fill: '#459220',
        fillColor: '#459220',
    },
    name: {
        fontWeight: '600',
        color: theme.palette.primary.main,
    },
    period: {
        fontWeight: '600',
    },
    infoWrapper: {
        flexDirection: 'column',
        paddingLeft: '72px',
        paddingRight: '44px',
    },
});

const Purchases = (props) => {

    const {
        classes,
        client,
        intl,
    } = props;

    const {signal} = new AbortController();
    const [purchases, setPurchases] = React.useState([]);

    React.useEffect(() => {
        loadGetPurchases(client, signal)
            .then((purchases) => {
                setPurchases(purchases.sort((purchase1, purchase2) => dateCompare(purchase1.createAt, purchase2.createAt, 'desc')));
            });
    }, []);

    return (
        <Box>
            {/*<Grid container direction={'column'}*/}
            {/*      // alignItems={"center"}*/}
            {/*      // justifyContent={"center"}*/}
            {/*      spacing={4}*/}
            {/*>*/}
                {!purchases.length &&
                    <Typography align={'center'} className={classes.nodata}>
                        <FormattedMessage id={'label.purchases.no_data'} defaultMessage={'No purchases'}/>
                    </Typography>
                }
                {!!purchases.length && purchases.map((purchase: Purchase, index) =>
                    <Accordion
                        key={purchase.id}
                        defaultExpanded={index === 0}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            // aria-controls="panel1a-content"
                            // id="panel1a-header"
                        >
                            <PurchaseView purchase={purchase} />
                        </AccordionSummary>
                        <AccordionDetails className={classes.infoWrapper}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={purchase.info || intl.formatMessage({id: 'label.purchases.purchase.no_info', defaultMessage: 'No info'})}/>
                        </AccordionDetails>
                    </Accordion>
                    // <Grid key={purchase.id} item className={classes.tariff}
                    // >
                    //     <PurchaseView purchase={purchase} />
                    // </Grid>
                )}
            {/*</Grid>*/}
        </Box>
    );
}

Purchases.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    withApollo,
    injectIntl,
)(Purchases);
