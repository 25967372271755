import React from 'react';
import mainStyles from "../../styles/mainStyles";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
    ...mainStyles(theme),
}));

export const HelpDrugsTextsView = (props) => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <div>
                <Typography className={classes.drugRecommend} variant='caption'>
                    <FormattedMessage id="label.drug.recommend_short" defaultMessage="Drug recommend"/>
                </Typography>
            </div>
            <div>
                <Typography className={classes.drugWarning} variant='caption'>
                    <FormattedMessage id="label.drug.warning" defaultMessage="Drug warning"/>
                </Typography>
            </div>
            <div>
                <Typography className={classes.drugDanger} variant='caption'>
                    <FormattedMessage id="label.drug.danger" defaultMessage="Drug danger"/>
                </Typography>
            </div>
        </Grid>
    );
}
