import React from "react";
import {Box, Grid, Hidden, makeStyles, Paper} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import {getRandomKey, getUuid} from "../../utils";

const useDrugResultRowStyles = makeStyles(theme => ({
    customIcoButton: {
        "&.MuiButtonBase-root": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            marginRight: '8px',
        }
    },
    wrapper: {
        padding: theme.spacing(2),
        // borderBottom: '1px solid rgba(224, 224, 224, 1);',
        alignItems: 'center',
        flexWrap: 'unset',

        [theme.breakpoints.down(360)]: {
            alignItems: 'flex-start',
        },
    },
    wrapperDuration: {
        padding: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
        alignItems: 'center',
        flexWrap: 'unset',
        marginTop: '-18px',

        [theme.breakpoints.down(360)]: {
            alignItems: 'flex-start',
        },
    },
    group: {
        alignItems: 'center',
    },
    groupName: {
        display: 'flex',
        flex: 1,
        columnGap: '8px',
        overflowWrap: 'break-word',
        width: 'max-content',
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            width: 'unset',
        },
    },
    drugWrapper: {
        borderBottom: '1px solid rgba(224, 224, 224, 1);',
    },
    drugWrapperDuration: {
        marginTop: '10px',
    },
    drug: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    value: {
        width: 'min-content',
        textAlign: 'right',
        flex: 'auto',
        overflowWrap: 'break-word',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            // textAlign: 'left',
            width: 'unset',
        },
    },
    duration: {
        textAlign: 'right',
        flex: 'auto',
        overflowWrap: 'anywhere',
        // color: theme.palette.primary.dark,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            marginLeft: '38px',
        },
    },
}));

export const DrugResultRow = (props) => {
    const {row, classes} = props;
    const [open, setOpen] = React.useState(false);
    const classesDrugResultRowStyles = useDrugResultRowStyles();

    return (
        <Box>
            <Grid container direction={"row"} className={classesDrugResultRowStyles.wrapper}>
                {/*<Grid item xl={6} lg={6} md={6} sm={6} xs={6}*/}
                {/*      // className={`${classes.pl8px}`}*/}
                {/*>*/}
                {/*    <Grid container className={classesDrugResultRowStyles.group}>*/}
                        <Grid item
                              // xs={3} sm={3}
                        >
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                color="default"
                                className={classesDrugResultRowStyles.customIcoButton}
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Grid>
                        <Grid item
                              // xs={9} sm={9}
                              className={classesDrugResultRowStyles.groupName}>
                            {row.groupName}
                            <Hidden smUp>
                                <Box
                                    // xl={6} lg={6} md={6} sm={6} xs={6}
                                      className={classesDrugResultRowStyles.value}>
                                    {row.drugName}
                                </Box>
                            </Hidden>
                        </Grid>
                {/*    </Grid>*/}
                {/*</Grid>*/}
                <Hidden xsDown>
                    <Grid item
                          // xl={6} lg={6} md={6} sm={6} xs={6}
                          className={classesDrugResultRowStyles.value}>
                        {row.drugName}
                    </Grid>
                </Hidden>
            </Grid>

            {!!row.drugRegimens &&
                <Grid container direction={"row"} className={classesDrugResultRowStyles.wrapperDuration}>
                    <Grid item
                        // xl={6} lg={6} md={6} sm={6} xs={6}
                          className={classesDrugResultRowStyles.duration}>
                        {row.drugRegimens[0].duration}
                    </Grid>
                </Grid>
            }

            <Grid container className={`${classesDrugResultRowStyles.drugWrapper} ${!!row.drugRegimens ? classesDrugResultRowStyles.drugWrapperDuration : ''}`}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classesDrugResultRowStyles.drug}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={0}
                             marginBottom={1}
                             marginTop={-1}
                        >
                            <Box style={{maxHeight: '248px', overflow: "auto"}}>
                                {row.tradeItems && row.tradeItems.map((tradeItem, index) => (
                                    <Paper key={index} variant={"outlined"} className={classes.drugBox}>
                                        <Typography variant="subtitle2" component="div">
                                            {tradeItem.name} {tradeItem.dosage && '('+tradeItem.dosage+')'}
                                        </Typography>
                                        <Typography variant="caption" component="div" color={'textSecondary'}>
                                            {tradeItem.innName}
                                        </Typography>
                                        <Grid container direction={'row'} justifyContent={"space-between"}>
                                            <Grid item>
                                                <Typography variant="caption" align={'left'} component="div">
                                                    {tradeItem.regCertOwner}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography align={'right'} variant="caption" component="div" color={'textSecondary'}>
                                                    {tradeItem.regCertOwnerCountry}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                ))}
                            </Box>
                        </Box>
                    </Collapse>
                </Grid>
            </Grid>
        </Box>
    );
};
